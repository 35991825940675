import { Grid } from '@mui/material'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  LieferantenListeDisplayJson,
  LieferantenListeSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useSearcherState } from '@utils/searcher'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { SelectButton } from '@utils/ui/Buttons/SelectButton'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { DialogEx } from '@utils/ui/DialogEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { arrayFromSet } from '@utils/utils'
import { useMemo, useState } from 'react'
import { LieferantenListeSuchForm } from './LieferantenListeSuchForm'

export interface LieferantenListeSuchDialogProps {
  onClose: (selected?: any) => void
  open?: boolean
}

export const LieferantenListeSuchDialog = ({
  onClose,
  open = false
}: LieferantenListeSuchDialogProps) => {
  const { et } = useEnums()

  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    LieferantenListeSearcherCriteriaJson,
    LieferantenListeDisplayJson
  >({
    api,
    url: '/lieferantenliste',
    initialParams: {
      name: undefined
    },
    valueDecorator: (path, value) => {
      return value && path === 'name' ? value.toUpperCase() : value
    }
  })

  const [selected, setSelected] = useState(new Set())

  const handleAuswahl = () => {
    onClose(arrayFromSet(selected))
  }

  const handleCancelFilter = () => {
    onClose()
  }

  const shortcuts = {
    Enter: search
  }

  const onRowDoubleClick = (row, e) => {
    e.preventDefault()
    e.stopPropagation()
    onClose(row)
  }

  const columns = useMemo(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: 'typ',
        header: 'Typ',
        sortable: true,
        body: (row: any) => et(HkmEnum.LieferantenListeTyp, row.typ)
      }
    ],
    [et]
  )

  const actions = (
    <>
      <SelectButton onClick={handleAuswahl} disabled={selected.size === 0} />
      <CancelButton onClick={handleCancelFilter} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={handleCancelFilter}
      maxWidth="md"
      fullWidth
      height="800px"
      title="Lieferantenliste auswählen"
      actions={actions}
    >
      <ShortCutHandler shortcuts={shortcuts}>
        <LieferantenListeSuchForm searchValues={criteria} onChange={onCriteriaChange} />
        <Grid container justifyContent="end" spacing={2}>
          <Grid item>
            <SearchButton onClick={search} />
          </Grid>
        </Grid>
      </ShortCutHandler>
      <DataTableCard
        title="Suchergebnis"
        name="LieferantenListeTable"
        borderless
        columns={columns}
        result={result}
        selectMode="multi"
        selectCol
        onSelect={setSelected}
        dense
        onRowDoubleClick={onRowDoubleClick}
      />
    </DialogEx>
  )
}
