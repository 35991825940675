import { BlurOn, Clear, Link, LinkOff, QuestionMark, Remove } from '@mui/icons-material'
import { Box } from '@mui/material'
import { blue, green, grey, orange, yellow } from '@mui/material/colors'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantAbonniertStatus } from '@one/typings/apiTypings'
import { EnumTextGetter } from '@utils/enums'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { memo } from 'react'

const iconStyle = { fontSize: '100%' }

export const getLieferantAbonniertStatusIcon = (status: LieferantAbonniertStatus) => {
  switch (status) {
    case LieferantAbonniertStatus.ABONNIERT:
      return <Link style={iconStyle} />
    case LieferantAbonniertStatus.UNERWUENSCHT:
      return <Clear style={iconStyle} />
    case LieferantAbonniertStatus.UNTERBROCHEN:
      return <LinkOff style={iconStyle} />
    case LieferantAbonniertStatus.UNBESTIMMT:
      return <QuestionMark style={iconStyle} />
    case null:
    case undefined:
      return <Remove style={iconStyle} />
    default:
      return <BlurOn style={iconStyle} />
  }
}

export const getLieferantAbonniertStatusText = (
  status: LieferantAbonniertStatus,
  et: EnumTextGetter
) => {
  switch (status) {
    case LieferantAbonniertStatus.ABONNIERT:
    case LieferantAbonniertStatus.UNERWUENSCHT:
    case LieferantAbonniertStatus.UNTERBROCHEN:
    case LieferantAbonniertStatus.UNBESTIMMT:
      return et(HkmEnum.LieferantAbonniertStatus, status)
    case undefined:
    case null:
    default:
      return 'Status unbekannt'
  }
}

export const LieferantAbonniertStatusRGB = {
  UNBESTIMMT: yellow,
  UNERWUENSCHT: blue,
  ABONNIERT: green,
  UNTERBROCHEN: orange,
  UNKNOWN: grey
}

export const getLieferantAbonniertStatusRGB = (status: LieferantAbonniertStatus) => {
  switch (status) {
    case LieferantAbonniertStatus.UNBESTIMMT:
      return LieferantAbonniertStatusRGB.UNBESTIMMT
    case LieferantAbonniertStatus.UNERWUENSCHT:
      return LieferantAbonniertStatusRGB.UNERWUENSCHT
    case LieferantAbonniertStatus.ABONNIERT:
      return LieferantAbonniertStatusRGB.ABONNIERT
    case LieferantAbonniertStatus.UNTERBROCHEN:
      return LieferantAbonniertStatusRGB.UNTERBROCHEN
    default:
      return LieferantAbonniertStatusRGB.UNKNOWN
  }
}

export const getLieferantAbonniertStatusColor = (
  status: LieferantAbonniertStatus,
  darkMode: boolean
) => getLieferantAbonniertStatusRGB(status)[darkMode ? 800 : 500]

export type LieferantAbonniertStatusSymbolProps = {
  value: LieferantAbonniertStatus
  et: EnumTextGetter
}

export const LieferantAbonniertStatusSymbol = memo(
  ({ value, et }: LieferantAbonniertStatusSymbolProps) => (
    <TooltipEx title={getLieferantAbonniertStatusText(value, et)}>
      <Box data-name={value}>{getLieferantAbonniertStatusIcon(value)}</Box>
    </TooltipEx>
  )
)
LieferantAbonniertStatusSymbol.displayName = 'LieferantAbonniertStatusSymbol'
