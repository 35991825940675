import { BusinessOutlined, LeaderboardOutlined } from '@mui/icons-material'
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { DragEvent, useCallback, useContext, useMemo, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { AppRoutes } from './AppRoutes'
import { MenuItemsView } from './MenuItemsView'
import { SyncMonitor } from './SyncMonitor'
import { UserRoles } from './UserRoles'
import { api } from './api'
import { useLocalState } from './utils/localState'
import { AppContext } from './utils/ui/App/AppContext'
import { AppHeader } from './utils/ui/App/AppHeader'
import { AppRoute } from './utils/ui/App/AppRoute'
import { useAppRouteMgr } from './utils/ui/App/AppRouteMgr'
import { AppSwitch } from './utils/ui/App/AppSwitch'
import { Frame, FrameBody, FrameRow } from './utils/ui/Frame'
import { MainContainer } from './utils/ui/MainContainer'
import { SideNavigation } from './utils/ui/Navigation/SideNavigation'
import { UnknownPage } from './utils/ui/Pages/UnknownPage'
import { AwesomeIcon } from './utils/ui/fields/AwesomeIcon'
import { HelpWrapper } from './utils/ui/help/HelpWrapper'
import { safeArray } from './utils/utils'

const useStyles = makeStyles()({
  companySelect: {
    '& label': {
      color: 'rgba(255, 255, 255, 0.83)  !important'
    },
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.43) !important'
    },
    '&>div': {
      color: 'white',
      fontWeight: 500,
      '&>div': {
        paddingTop: 6,
        paddingBottom: 6
      },
      '&>svg': {
        color: 'white'
      }
    },
    minWidth: '4em',
    '&:before': {
      borderBottom: 'none !important'
    }
  },
  allianz: {
    color: '#FFFFFF!important',
    padding: '4px 12px',
    textShadow: '#474747 2px 2px 0px, 2px 2px 2px rgb(41 131 70 / 0%)',
    borderRadius: 22,
    border: '1px solid #FFFFFF'
  }
})

const handleDragOver = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  e.dataTransfer.dropEffect = 'none'
}

export const AppView = () => {
  const { classes } = useStyles()

  const appContext = useContext(AppContext)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [helpVisible, setHelpVisible] = useState(false)
  const [appViewMode, , onViewModeChange] = useLocalState('viewMode', {
    menuHideMode: false
  })

  const [routes, menu] = useAppRouteMgr(AppRoutes, appContext, UserRoles.STAMMDATEN_EDITOR)

  const { session, isAllianz, isAllianzMitglied } = appContext
  const { companies, adminMode } = session || {}

  const handleDrawerToggle = useCallback(() => {
    setDrawerVisible(!drawerVisible)
  }, [drawerVisible])

  const helpToggle = () => {
    setHelpVisible(!helpVisible)
  }

  const onGlnChange = useCallback((e) => {
    const ngln = e.target.value
    api
      .post(`/session/switch?gln=${ngln}`)
      .then(() => {
        localStorage.setItem('lastgln', ngln)
        history.replaceState(null, null, '#')
        window.location.reload()
      })
      .catch(() => {
        history.replaceState(null, null, '#')
        window.location.reload()
      })
  }, [])

  const left = useMemo(() => {
    return (
      <span>
        {isAllianz && (
          <Typography className={classes.allianz}>
            <AwesomeIcon iconClass="fa-solid fa-layer-group" />
            &nbsp;Allianz
          </Typography>
        )}
        {isAllianzMitglied && (
          <Typography className={classes.allianz}>
            <AwesomeIcon iconClass="fa-solid fa-handshake-angle" />
            &nbsp;Allianz-Mitglied
          </Typography>
        )}
      </span>
    )
  }, [classes.allianz, isAllianz, isAllianzMitglied])

  const company = useMemo(() => {
    const companyItems = safeArray(companies)
      .sort((a, b) => a.gln.localeCompare(b.gln))
      .map((c) => (
        <MenuItem key={c.gln} value={c.gln}>
          <span title={c.gesellschafter ? 'Gesellschafter' : 'Firma'}>
            {c.gesellschafter ? (
              <LeaderboardOutlined style={{ verticalAlign: 'sub' }} fontSize="small" />
            ) : (
              <BusinessOutlined style={{ verticalAlign: 'sub' }} fontSize="small" />
            )}
            {`${c.gln} ${c.name}`}
          </span>
        </MenuItem>
      ))

    const current = companies && companies.find((c: any) => c.gln === session.gln)
    const body = adminMode ? (
      <Grid item>
        <Typography variant="h4" style={{ fontVariant: 'small-caps' }}>
          Administrator Modus
        </Typography>
      </Grid>
    ) : (
      <Grid item>
        <FormControl variant="outlined" className={classes.companySelect}>
          <InputLabel shrink>Gesellschafter</InputLabel>
          <Select
            label="Gesellschafter"
            title={current && current.name}
            renderValue={(v) => {
              const opt = v == null ? null : companies.find((x) => x.gln === v)
              return opt == null ? null : (
                <>
                  <Typography sx={{ display: { xs: 'block', md: 'none' } }}>{opt.name}</Typography>
                  <Typography sx={{ display: { xs: 'none', md: 'block' } }}>{opt.gln} {opt.name}</Typography>
                </>
              )
            }}
            name="companyGln"
            value={session.gln}
            onChange={onGlnChange}
            variant="outlined">
            {companyItems}
          </Select>
        </FormControl>
      </Grid>
    )

    return (
      <Grid container flexWrap="nowrap" alignItems="center" paddingTop="2px" spacing="4px">
        <SyncMonitor />
        {body}
      </Grid>
    )
  }, [adminMode, classes.companySelect, companies, onGlnChange, session.gln])

  return (
    <Frame onDragOver={handleDragOver}>
      <FrameRow>
        <AppHeader
          onDrawerToggle={handleDrawerToggle}
          keepMenuButton={appViewMode.menuHideMode}
          api={api}
          helpToggle={helpToggle}
          left={left}
        >
          {company}
        </AppHeader>
      </FrameRow>
      <FrameBody>
        <HelpWrapper
          visible={helpVisible}
          setVisible={setHelpVisible}
          editRoleName={UserRoles.BENUTZERHILFE_EDITOR}
          api={api}
        >
          <SideNavigation
            menuHideMode={appViewMode.menuHideMode}
            setMenuHideMode={(e, c) => onViewModeChange({ name: 'menuHideMode', value: c })}
            drawerVisible={drawerVisible}
            onDrawerToggle={handleDrawerToggle}
            menuItems={menu}
          >
            <MainContainer padding="8px">
              <AppSwitch appTitle="One Frontend">
                <Routes>
                  {routes}
                  {adminMode && (
                    <Route path="/menuitems" element={<AppRoute component={MenuItemsView} />} />
                  )}
                  <Route
                    path="/"
                    element={adminMode ? <Navigate to="/admin" /> : <Navigate to="/dashboard" />}
                  />
                  <Route path="*" element={<AppRoute component={UnknownPage} />} />
                </Routes>
              </AppSwitch>
            </MainContainer>
          </SideNavigation>
        </HelpWrapper>
      </FrameBody>
    </Frame>
  )
}
