import { api } from '@one/api'
import { ArtikelDisplayTinyJson, LabelJson, SuchArtikelTyp } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useGlobalDialogAnker } from '@utils/ui/DialogAnker'
import { useSnackbarEx } from '@utils/ui/snackbarex'
import { CallbackType } from '@utils/utils'
import { useCallback } from 'react'
import { ArtikelLabelDialog } from './ArtikelLabelDialog'

export interface LabelEditResponse {
  data: { artikelIds: number[]; labelIds: number[] }
  isAdded: boolean
}

export interface OpenLabelsEdit {
  artikel: ArtikelDisplayTinyJson[]
  callback?: CallbackType<LabelEditResponse>
}

export const useArtikelLabelEdit = () => {
  const [apiCall] = useApiCaller(api)
  const showDlg = useGlobalDialogAnker()
  const { enqueError } = useSnackbarEx()

  const addArtikelLabels = useCallback(
    (artikelIds, labelIds, callback: CallbackType<any> = null) => {
      if (labelIds) {
        const data = {
          artikelIds,
          labelIds
        }

        apiCall({
          method: 'POST',
          rest: 'artikel/label/add',
          data,
          onSuccess: () => {
            if (callback) {
              callback.then({ data, isAdded: true })
            }
          }
        })
      }
    },
    [apiCall]
  )

  const removeArtikelLabels = useCallback(
    (artikelIds, labelIds, callback: CallbackType<any> = null) => {
      const data = {
        artikelIds,
        labelIds
      }
      apiCall({
        method: 'POST',
        rest: 'artikel/label/remove',
        data,
        onSuccess: () => {
          if (callback) {
            callback.then({ data, isAdded: false })
          }
        }
      })
    },
    [apiCall]
  )

  const handleLabelEdit = useCallback(
    (artikelIds: number[], callback: CallbackType<any> = null) =>
      (labels: LabelJson[], addNewLabels: boolean) => {
        // cancel Dialog
        if (!labels) {
          return
        }
        const labelIds = labels.map((label) => label.id)
        if (addNewLabels) {
          addArtikelLabels(artikelIds, labelIds, callback)
        } else {
          removeArtikelLabels(artikelIds, labelIds, callback)
        }
      },
    [addArtikelLabels, removeArtikelLabels]
  )

  const openLabelsEdit = useCallback(
    ({ artikel, callback }: OpenLabelsEdit) => {
      if (artikel == null || artikel.length === 0) {
        enqueError('Es wurde kein Artikel ausgewaählt!')
        return
      }
      const artikelIds = artikel.filter((a) => a.typ === SuchArtikelTyp.DEZENTRAL).map((a) => a.id)
      if (artikelIds.length === 0) {
        enqueError('Labels können nur für ERP-Artikel genutzt werden')
        return
      }
      const hasIllegalArtikel = artikelIds.length !== artikel.length
      showDlg((visible, onClose) => (
        <ArtikelLabelDialog
          hasSelectedArtikel={artikel.length > 0}
          open={visible}
          hasIllegalArtikel={hasIllegalArtikel}
          onClose={onClose(handleLabelEdit(artikelIds, callback))}
        />
      ))
    },
    [handleLabelEdit, showDlg, enqueError]
  )

  return { openLabelsEdit, removeArtikelLabels }
}
