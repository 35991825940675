import { Grid2, Tooltip, Typography } from '@mui/material'
import { blueGrey, orange, red } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { LabelChipContainer } from '@one/components/Label/LabelChipContainer'
import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import {
  ArtikelBetriebstypCell,
  getArtikelBetriebstypLabel
} from '@one/components/common/ArtikelBetriebstypCell'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import { WarengruppeNameCell, WarengruppeNrCell } from '@one/components/common/WarengruppeCell'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { useArtikelLabelCache } from '@one/datacaches/useArtikelLabelCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  VkPreisBasisTyp,
  VkPreiseMasseBearbeitenEintragJson,
  VkPreiseMasseBearbeitenPreisGruppeJson,
  VkPreisGruppeJson,
  ZufuhrMultiDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Permission } from '@utils/ui/Permission'
import { ThemeContext } from '@utils/ui/Theme'
import { Medal } from '@utils/ui/fields/Medal'
import { CSSProperties, useCallback, useContext, useMemo } from 'react'
import { WerteCell } from '@one/components/common/DataPreisCell'
import { formatMoney2 } from '@utils/utils'

export interface VkPreisMassenPflegeTableProps {
  actions: any
  selected: Set<VkPreiseMasseBearbeitenEintragJson>
  preisgruppen: VkPreisGruppeJson[]
  reload: any
  preislisteId?: any
  onSelect: (selected: Set<VkPreiseMasseBearbeitenEintragJson>) => void
  data?: VkPreiseMasseBearbeitenEintragJson[]
  zufuhrMultis: ZufuhrMultiDisplayJson[]
  forEinzelhandel: boolean
}

export const VkPreisMassenPflegeTable = ({
  selected,
  onSelect,
  actions,
  reload,
  preislisteId,
  preisgruppen,
  data = [],
  zufuhrMultis,
  forEinzelhandel
}: VkPreisMassenPflegeTableProps) => {
  const { darkMode } = useContext(ThemeContext)
  const { openLabelsEdit } = useArtikelLabelEdit()
  const { get: getLabels } = useArtikelLabelCache()
  const { get: getBetriebstyp } = useArtikelBetriebstypCache()
  const { et } = useEnums()
  const { isPIMModel } = useContext(AppContext)

  const columns = useMemo<Column<VkPreiseMasseBearbeitenEintragJson>[]>(() => {
    const importFehlerFormatter = (row) => {
      const nas = []
      if (row?.importFehlerData) {
        nas.push('Daten')
      }
      if (row?.importFehlerPreiseEk) {
        nas.push('EK-Preise')
      }
      if (row?.importFehlerPreiseVk) {
        nas.push('VK-Preise')
      }
      return nas
    }

    const filteredPgs = (pgs: VkPreiseMasseBearbeitenPreisGruppeJson[]) =>
      forEinzelhandel
        ? pgs?.filter((w) => {
            const pg = preisgruppen.find((pgf) => pgf.id === w.id)
            return pg?.kasse !== true && pg?.brutto === true
          })
        : pgs

    const formatBasis = (
      row: VkPreiseMasseBearbeitenEintragJson,
      w: VkPreiseMasseBearbeitenPreisGruppeJson,
      s: VkPreisBasisTyp
    ) => {
      const pg = preisgruppen.find((pgf) => pgf.id === w.id)
      if (pg?.kasse) {
        const name = preisgruppen.find((pgf) => pgf.id === row.referenzPreisgruppeId)?.name
        if (name) {
          return name
        }
      }
      return et(HkmEnum.VkPreisBasisTyp, s)
    }
    const cols: Column<VkPreiseMasseBearbeitenEintragJson>[] = [
      {
        type: 'string',
        field: 'standort',
        header: 'Standort',
        align: 'right',
        body: (row) => {
          return row.neu ? (
            <Tooltip
              title={
                <span>
                  Zur Anlage vorbereitet.
                  <br />
                  Dieser Standort hat noch keinen Preis!
                </span>
              }
            >
              <span>*{row.standort}</span>
            </Tooltip>
          ) : (
            <span>{row.standort}</span>
          )
        }
      },
      {
        field: 'importFehlerData',
        header: 'Import-Fehler',
        hideIfEmpty: true,
        valueGetter: importFehlerFormatter,
        align: 'center',
        body: (row) => {
          return row?.artikel?.importFehlerData ||
            row?.artikel?.importFehlerPreiseEk ||
            row?.artikel?.importFehlerPreiseVk
            ? importFehlerFormatter(row).map((medal) => (
                <Medal
                  key={medal}
                  text={medal}
                  tooltip="Wegen Aktualisierungsfehler nicht aktuell"
                  backgroundColor={orange[500]}
                />
              ))
            : null
        }
      },
      {
        type: 'string',
        field: 'artikel.hageNummer',
        header: 'hage-Nr.'
      },
      {
        type: 'string',
        field: 'artikel.btNummer',
        header: 'Artikel-Nr.',
        off: isPIMModel
      },
      {
        type: 'string',
        field: 'artikel.bez1',
        header: 'Bezeichnung 1-4',
        width: '100%',
        valueGetter: (row) => [
          row.artikel?.bez1,
          row.artikel?.bez2,
          row.artikel?.bez3,
          row.artikel?.bez4
        ]
      },
      {
        type: 'string',
        field: 'kalkulationsvorschlag',
        header: 'Kalkulationsvorschlag'
      },
      {
        type: 'boolean',
        field: 'abgeschlossen',
        header: 'Abgeschlossen',
        align: 'center'
      },
      {
        type: 'money2',
        field: 'lp',
        header: 'Listenpreis'
      },
      {
        type: 'money2',
        field: 'kalkEK',
        header: 'kalk. EK'
      },
      {
        type: 'string',
        field: 'einh',
        header: 'Einheit'
      },
      {
        type: 'string',
        field: `pg`,
        header: 'Preisgruppe',
        width: '5 em',
        cellPadding: 0,
        valueGetter: (row) =>
          filteredPgs(row.pgs).map((pg) => preisgruppen.find((pgf) => pgf.id === pg.id)?.name),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="id"
            align="left"
            formatter={(x, pg) => {
              const pgname = preisgruppen.find((pgf) => pgf.id === pg.id)?.name
              if (pg.id == row.referenzPreisgruppeId && forEinzelhandel) {
                return (
                  <Grid2 container gap={1} direction="row" alignItems="center">
                    <Grid2>{pgname}</Grid2>
                    <Grid2>
                      <Medal text="ZKasse" />
                    </Grid2>
                  </Grid2>
                )
              }
              return pgname
            }}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: `preis`,
        header: 'Preis',
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.preis),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="preis"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: 'opreis',
        header: 'Alt.Preis',
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.opreis),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="opreis"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: 'delta',
        header: 'Delta %',
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.delta),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="delta"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: `spanne`,
        hideIfEmpty: true,
        header: 'Spanne',
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.spanne),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="spanne"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: `ospanne`,
        hideIfEmpty: true,
        header: 'Spanne alt',
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.ospanne),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="ospanne"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: 'aufschlag',
        header: 'Aufschlag',
        off: forEinzelhandel,
        hideIfEmpty: true,
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.aufschlag),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="aufschlag"
            formatter={(s) => s}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'money2',
        field: 'oaufschlag',
        header: 'Aufschlag alt',
        off: forEinzelhandel,
        hideIfEmpty: true,
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => pg.oaufschlag),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="oaufschlag"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'string',
        field: 'basis',
        header: 'Basis',
        off: forEinzelhandel,
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => formatBasis(row, pg, pg.basisTyp)),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenPreisGruppeJson>
            werte={filteredPgs(row.pgs)}
            field="basisTyp"
            align="left"
            formatter={(s, x) => formatBasis(row, x, s)}
            darkMode={darkMode}
          />
        )
      },
      {
        type: 'string',
        field: 'obasis',
        header: 'Basis alt',
        off: forEinzelhandel,
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => filteredPgs(row.pgs).map((pg) => formatBasis(row, pg, pg.obasisTyp)),
        body: (row) => (
          <WerteCell<VkPreiseMasseBearbeitenEintragJson>
            werte={filteredPgs(row.pgs)}
            field="obasisTyp"
            align="left"
            formatter={(s, x) => formatBasis(row, x, s)}
            darkMode={darkMode}
          />
        )
      },

      {
        field: 'artikel.labels',
        header: 'Labels',
        sortable: true,
        valueGetter: (row) => row.artikel?.labels?.map((label) => getLabels(label)?.kurz),
        body: (row) => <LabelChipContainer labels={row.artikel?.labels?.map(getLabels)} />
      },
      {
        field: 'artikel.warengruppe.nummer',
        header: 'WG-Nr',
        body: (row: any) => <WarengruppeNrCell warengruppe={row.artikel?.warengruppe} />
      },
      {
        field: 'artikel.warengruppe.name',
        header: 'WG-Name',
        body: (row: any) => <WarengruppeNameCell warengruppe={row.artikel?.warengruppe} />
      },
      {
        field: 'artikel.betriebsTyp',
        header: 'B-Typ',
        body: (row) => <ArtikelBetriebstypCell value={row.artikel?.betriebsTyp} />,
        valueGetter: (row) =>
          `${getArtikelBetriebstypLabel(getBetriebstyp(row.artikel?.betriebsTyp))}`
      },
      {
        field: 'error',
        header: 'Fehler',
        valueGetter: (row) => row.error && row.error.title + ': ' + row.error.details,
        body: (row) =>
          row.error && (
            <span>
              {row.error.title}: <Typography variant="caption">{row.error.details}</Typography>
            </span>
          ),
        span: (row) => row.error && 100
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.frachtArt',
        header: 'Frachtart',
        valueGetter: (row) => et(HkmEnum.VkFrachtArt, row.frachtkosten?.frachtArt)
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.frachtNichtSkonto',
        header: 'Nicht Skonto?',
        type: 'boolean'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.frachtNachRabatt',
        header: 'Nach Rabatt?',
        type: 'boolean'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.frachtProz',
        header: 'Fracht Proz.',
        type: 'money2'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.frachtAbs',
        header: 'Fracht Abs.',
        type: 'money2'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.enthalteneFrachtZufuhr',
        header: 'Ent. FZ',
        headerTip: 'Enthaltene FrachtZufuhr',
        type: 'money2'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.ekNichtSkonto',
        header: 'EK Nicht Skonto',
        type: 'money2'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.vkNichtSkonto',
        header: 'VK Nicht Skonto',
        type: 'money2'
      },
      {
        off: forEinzelhandel,
        field: 'frachtkosten.zufuhrMultiId',
        header: 'Zufuhrmulti',
        valueGetter: (row) => {
          const zfm =
            row.frachtkosten?.zufuhrMultiId &&
            zufuhrMultis?.find((zfm) => zfm.id === row.frachtkosten.zufuhrMultiId)
          return zfm && `${zfm.name}-${zfm.bez}`
        }
      }
    ]
    return cols
  }, [isPIMModel, preisgruppen, forEinzelhandel, getLabels, getBetriebstyp, et, zufuhrMultis])

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'info',
        tooltip: 'Archivierter Artikel',
        handler: () => {},
        check: (row) => (row.archiviert ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'edit',
        tooltip: 'Preispflege öffnen',
        getLink: (data: any) => {
          return AppPaths.VkPreisPflegeFn(preislisteId, data.vkPreisListeEintragId)
        }
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: any) => AppPaths.ArtikelFn(data.artikel?.id)
      }
    ],
    [preislisteId]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(selected).map((select) => select.artikel),
      callback: {
        then: () => reload()
      }
    })
  }, [openLabelsEdit, selected, reload])

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Labels bearbeiten',
        icon: 'label',
        onClick: openLabelDialog
      }
    ],
    [openLabelDialog]
  )

  const getRowStyle = useCallback(
    (row: any) => {
      if (row.archiviert) {
        return getArchiviertRowStyle(row?.archiviert)
      }
      const style: CSSProperties = {}
      if (row.error) style.backgroundColor = red[darkMode ? 800 : 100]
      if (row.neu) style.color = blueGrey[darkMode ? 400 : 600]
      return style
    },
    [darkMode]
  )

  return (
    <DataTableCard
      name="VkPreisMassenPflegeTable"
      filterMode="both"
      title="Verkaufspreise"
      columns={columns}
      actions={tableActions}
      topActions={topActions}
      value={data}
      dense
      paging
      selectCol
      selectMode="multi"
      selected={selected}
      onSelect={onSelect}
      bottomActions={actions}
      stickyColumsLeft={3}
      rowStyle={getRowStyle}
      localStateName="VkPreisMassenPflegeTable"
    />
  )
}
