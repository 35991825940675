export const formatNumber = (value: number | null, fraction: number = 0) => {
  return typeof value === 'number'
    ? value.toLocaleString('de', {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction
      })
    : null
}

export const roundNumber = (v, fraction = 2) => {
  if (v != null) {
      const factor = Math.pow(10, fraction)
      return Math.round(v * factor + 0.4) / factor
  }
  return v
}

// export const roundNearest = (value, nearest) =>
//   value && nearest ? Math.floor(Math.round(value / nearest)) * nearest : value

export const roundNearest = (value, nearest) =>
  value && nearest
    ? (Math.floor(Math.round((value * 100) / nearest + 0.00000001)) * nearest) / 100
    : value

// for (let i = 0; i < 100; ++i) {
//   const x = 1 + i / 100
//   debugLog(x, roundNearest(x, 100))
// }

// eslint-disable-next-line no-restricted-globals
export const isNumber = (n) => n != null && !isNaN(Number(n))

export const compareNumber = (a: number | null, b: number | null): number => {
  if (a == null || b == null) {
    return a == null ? (b == null ? 0 : -1) : 1
  }
  return a - b
}
