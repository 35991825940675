import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { resolveDateFnsLocal, setGlobalDateFnsLocale } from '@utils/dateutils'
import { DialogProvider } from '@utils/ui/DialogAnker'
import { Theme } from '@utils/ui/Theme'
import { setGlobalLocale } from '@utils/utils'
import { SnackbarProvider } from 'notistack'
import { useEffect } from 'react'
import { TssCacheProvider } from 'tss-react'
import { ErrorBoundary } from './ErrorBoundary'

const cache = createCache({
  key: 'css',
  prepend: true,
  speedy: true
})

const tssCache = createCache({
  key: 'tss'
})

export type AppRootProps = {
  themeAddOns?: any
  locale?: any
  children?: any
}

export const AppRoot = ({ themeAddOns, locale, children }: AppRootProps) => {
  const dateFnsLocale = resolveDateFnsLocal(locale)

  useEffect(() => {
    setGlobalDateFnsLocale(dateFnsLocale)
    setGlobalLocale(locale)
  }, [dateFnsLocale, locale])

  return (
    <ErrorBoundary>
      <CacheProvider value={cache}>
        <TssCacheProvider value={tssCache}>
          <Theme custom={themeAddOns}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale as any}>
              <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <ErrorBoundary>
                  <DialogProvider>{children}</DialogProvider>
                </ErrorBoundary>
              </SnackbarProvider>
            </LocalizationProvider>
          </Theme>
        </TssCacheProvider>
      </CacheProvider>
    </ErrorBoundary>
  )
}
