import { Box, Grid } from '@mui/material'
import { StaffelgruppeDisplayJson } from '@one/typings/apiTypings'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'

export interface StaffelgruppenFieldProps {
  label?: string | null
  options?: any[]
  value?: number
  onChange?: (e: any) => void
  readonly?: boolean
  name?: string
  noClear?: boolean
}
export const StaffelgruppenField = ({
  value,
  onChange,
  options,
  readonly = false,
  label = null,
  name,
  noClear
}: StaffelgruppenFieldProps) => {
  const optionsSafe = options || []

  const renderValue = (al) =>
    al === '' ? (
      <small>Keine</small>
    ) : (
      <span>{(optionsSafe.find((x) => x.id === al) || {}).name}</span>
    )

  const renderItem = (al: StaffelgruppeDisplayJson) => (
    <Grid container width="100%" minWidth="fit-content">
      <Grid item whiteSpace="nowrap">
        {al.name}
      </Grid>
      {al.flex ? (
        <Grid item flexGrow={1} paddingLeft={1} textAlign="right">
          <TooltipEx title="Staffelwere flexible erweiterbar">
            <Box style={{ fontSize: '80%', fontVariant: 'all-small-caps' }}>Flex</Box>
          </TooltipEx>
        </Grid>
      ) : null}
    </Grid>
  )

  if (readonly && label == null) {
    return renderValue(value)
  }

  return (
    <AutocompleteEx
      name={name}
      options={optionsSafe}
      value={value}
      onChange={onChange}
      disabled={readonly}
      renderItem={renderItem}
      optionLabel={(opt) => opt.name}
      optionValue="id"
      label={label}
      fullWidth
      noClear={noClear}
    />
  )
}
