import { api } from '@one/api'
import {
  ArtikelDatenpoolDisplayJson,
  ArtikelDatenpoolSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { nameOf } from '@utils/utils'
import { useCallback } from 'react'
import { ArtikelDatenPoolUebersichtParams } from './ArtikelDatenpoolUebersichtParams'
import { ArtikelDatenPoolUebersichtTable } from './ArtikelDatenpoolUebersichtTable'

export const ArtikelDatenPoolUebersichtView = () => {
  const handleExclusiveFields = useCallback(
    (
      name: string,
      state: ArtikelDatenpoolSearcherCriteriaJson
    ): ArtikelDatenpoolSearcherCriteriaJson => {
      if (state[name] == null) {
        return state
      }
      switch (name) {
        case nameOf<ArtikelDatenpoolSearcherCriteriaJson>('lan'):
        case nameOf<ArtikelDatenpoolSearcherCriteriaJson>('gtin'):
        case nameOf<ArtikelDatenpoolSearcherCriteriaJson>('lieferantArtikelBez'):
        case nameOf<ArtikelDatenpoolSearcherCriteriaJson>('lieferantWarenGruppe'):
          return {
            lan: nameOf<ArtikelDatenpoolSearcherCriteriaJson>('lan') === name ? state.lan : null,
            gtin: nameOf<ArtikelDatenpoolSearcherCriteriaJson>('gtin') === name ? state.gtin : null,
            lieferantArtikelBez:
              nameOf<ArtikelDatenpoolSearcherCriteriaJson>('lieferantArtikelBez') === name
                ? state.lieferantArtikelBez
                : null,
            lieferantWarenGruppe:
              nameOf<ArtikelDatenpoolSearcherCriteriaJson>('lieferantWarenGruppe') === name
                ? state.lieferantWarenGruppe
                : null
          }
      }
      return state
    },
    []
  )

  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    ArtikelDatenpoolSearcherCriteriaJson,
    ArtikelDatenpoolDisplayJson
  >({
    api,
    url: '/artikeldatenpool',
    initialParams: {},
    stateInterceptor: handleExclusiveFields
  })

  return (
    <Frame space>
      <FrameRow>
        <ArtikelDatenPoolUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <ArtikelDatenPoolUebersichtTable result={result} reload={search} />
      </FrameBody>
    </Frame>
  )
}
