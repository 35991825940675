export const debugLog = (message: any, ...optionalParams: any[]): void => {
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams)
  }
}

export const warnLog = (message: any, ...optionalParams: any[]): void => {
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(message, ...optionalParams)
  }
}

export const errorLog = (message: any, ...optionalParams: any[]): void => {
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.error(message, ...optionalParams)
  }
  // TODO zum Server senden??
}

export const protLog = (message: any, ...optionalParams: any[]): void => {
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams)
  }
  // TODO zum Server senden??
}
