import { api } from '@one/api'
import {
  EkPreisListeImportDefinitionBearbeitenJson,
  EkPreisListeImportDefinitionJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { EkPreisImportKonfigurationTable } from './EkPreisImportKonfigurationTable'

export const EkPreisImportKonfigurationView = () => {
  const { model, uiLock, updModel, isNew, isChanged, save, reload, envelope } = useModelMgr<
    EkPreisListeImportDefinitionJson,
    EkPreisListeImportDefinitionJson,
    EkPreisListeImportDefinitionBearbeitenJson
  >({
    noid: true,
    api,
    rest: 'ekpreislisteimport/definition',
    unwrapField: 'data'
  })

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <EkPreisImportKonfigurationTable
            title="Import Einkaufspreise - Konfiguration der zu durchlaufenden Validierungsschritte"
            backLink
            model={model}
            labels={envelope.allLabels}
            updModel={updModel}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton isNew={isNew} isChanged={isChanged} onClickVoid={save} />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClick={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
