import { api } from '@one/api'
import {
  EkPreisListeImportDefinitionMappingSearcherJson,
  EkPreisListeImportDefinitionMappingType,
  EkPreisListeImportDefinitionMappingUebersichtJson,
  LieferantDisplayJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { useQuery } from '@utils/routing'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useCallback, useEffect, useRef, useState } from 'react'
import { LieferantenMappingKopf } from './LieferantenMappingKopf'
import { LieferantenMappingTabelle } from './LieferantenMappingTabelle'

export interface EkPreisListeImportDefinitionMappingSearcherEx
  extends EkPreisListeImportDefinitionMappingSearcherJson {
  lieferant?: LieferantDisplayJson
}

export const LieferantenMappingUebersichtView = () => {
  const [{ lieferantenNr }, updateQuery] = useQuery()
  const [params, setParams] = useState({})
  const [model, setModel] = useState<EkPreisListeImportDefinitionMappingSearcherEx>({
    criteria: {
      mappingType: EkPreisListeImportDefinitionMappingType.MENGENEINHEIT
    }
  })

  const [apiCall, apiBusy] = useApiCaller(api)

  const fetchData = useCallback(
    (searchValues: any = null) => {
      let m = model
      searchValues = searchValues ?? params
      if (params) {
        m = {
          ...model,
          lieferant: searchValues.lieferant,
          criteria: {
            mappingType: EkPreisListeImportDefinitionMappingType.MENGENEINHEIT,
            suLieferantId: searchValues?.lieferant?.id
          }
        }
        setModel(m)
        updateQuery(m.lieferant ? { lieferantenNr: m.lieferant.nummer } : null)
      }
      apiCall<EkPreisListeImportDefinitionMappingUebersichtJson>({
        method: 'POST',
        exclusive: ApiExclusive.BLOCK,
        rest: '/ekpreislisteimport/definition/mapping/uebersichtAnzeigen/search',
        onErrorMsg: 'Mappings konnten nicht geladen werden.',
        data: m.criteria,
        onSuccess: (data) => {
          setModel(data.searcher)
        }
      })
    },
    [apiCall, model, params, updateQuery]
  )

  const onSearch = useCallback(
    (searchValues) => {
      setParams(searchValues)
      fetchData(searchValues)
    },
    [fetchData]
  )

  // Lädt Lieferant bei deep-link
  const lastNrRef = useRef()
  useEffect(() => {
    if (lieferantenNr && lastNrRef.current !== lieferantenNr) {
      lastNrRef.current = lieferantenNr
      apiCall<EkPreisListeImportDefinitionMappingUebersichtJson>({
        method: 'POST',
        rest: '/lieferant/uebersichtAnzeigen/search',
        exclusive: ApiExclusive.CANCEL,
        data: {
          nummer: lieferantenNr,
          zentral: false,
          dezentral: true
        },
        onSuccess: (data) => {
          if (data.searcher?.result[0] != null) {
            onSearch({ lieferant: data.searcher.result[0] })
          }
        }
      })
    }
  }, [apiCall, lieferantenNr, onSearch])

  return (
    <Frame space>
      <FrameRow>
        <LieferantenMappingKopf value={params} onSearch={onSearch} />
      </FrameRow>
      <FrameBody>
        <LieferantenMappingTabelle
          params={model.criteria}
          mappings={model.result}
          onComplete={() => fetchData(null)}
        />
      </FrameBody>
    </Frame>
  )
}
