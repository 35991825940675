import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { ExportErrorToErpArtikelJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useMemo } from 'react'
import { ExportFehlerView } from './ExportFehlerView'

export const ExportArtikelFehlerUebersichtView = () => {
  const { et } = useEnums()

  const columnsProps = useMemo<ArtikelDefaultColumnsProps<ExportErrorToErpArtikelJson>>(
    () => ({
      fieldWrapper: {
        wrapper: 'artikel'
      },
      removeDefaultsByField: ['minEinLager', 'lagerME', 'hauptlieferant', 'warengruppe.nummer'],
      addColumnToIndex: [
        {
          beforeField: 'industrieArtikelNummer',
          column: {
            field: 'artikel.aboStatus',
            header: 'Abostatus',
            align: 'center',
            body: (row) =>
              row.artikel && <ArtikelAbonniertStatusSymbol value={row.artikel.aboStatus} et={et} />,
            valueGetter: (row) =>
              row.artikel && getArtikelAbonniertStatusText(et, row.artikel.aboStatus)
          }
        }
      ]
    }),
    [et]
  )

  const columns = useArtikelColumns(columnsProps)

  return (
    <ExportFehlerView
      columns={columns}
      title="ERP-Artikel Exportfehler"
      rest="export/artikel"
      name="ArtikelExportFehlerTable"
    />
  )
}
