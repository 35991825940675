import { SyncEinstellungField } from '@one/components/common/SyncEinstellungField'
import { useArSSTCache } from '@one/datacaches/useArSSTCache'

export interface ArsstSelectFieldProps {
  arsstId: number
  onChange: any
  errors?: any
  disabled?: boolean
  required?: boolean
}

export const ArsstSelectField = ({
  arsstId,
  onChange,
  errors,
  disabled,
  required
}: ArsstSelectFieldProps) => {
  const arrstCache = useArSSTCache()

  // useEffect(() => {
  //   if (!arsstId) {
  //     const value = arrstCache.data?.find((arsst) => arsst.standard)?.id
  //     // defaut Einstellung direkt am Model setzen
  //     if (value) {
  //       onChange({
  //         target: { value, name: 'arsstId' }
  //       })
  //     }
  //   }
  // }, [arsstId, onChange, arrstCache.data])

  return (
    <SyncEinstellungField
      label="Artikelstammdaten-Synchronisationstemplate (ArSST)"
      errors={errors}
      options={arrstCache.data}
      name="arsstId"
      value={arsstId}
      onChange={onChange}
      fullWidth
      required={required}
      displayEmpty={false}
      disabled={disabled}
    />
  )
}
