import { AppPaths } from '@one/AppPaths'
import { EkKonditionenDefinitionEx } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseTypes'
import {
  EkKonditionenRabattgruppeDisplayJson,
  LieferantDisplayTinyJson
} from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { formatDate } from '@utils/dateutils'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useContext, useMemo } from 'react'

export interface EkRabattgruppenTableProps {
  kondiDefinition: EkKonditionenDefinitionEx
  result: SearcherResultType<EkKonditionenRabattgruppeDisplayJson>
  lieferant?: LieferantDisplayTinyJson
}

export const EkRabattgruppenTable = ({
  result,
  kondiDefinition,
  lieferant
}: EkRabattgruppenTableProps) => {
  const { isAllianz } = useContext(AppContext)

  const columns = useMemo<Column<EkKonditionenRabattgruppeDisplayJson>[]>(
    () =>
      [
        {
          off: isAllianz,
          field: 'name',
          header: 'Rabattgruppe'
        },
        {
          off: isAllianz,
          field: 'bezeichnung',
          header: 'Bezeichnung'
        },
        {
          field: 'gueltigVon',
          header: 'Gültig ab',
          valueGetter: (row) => formatDate(row.gueltigVon)
        },
        {
          field: 'gueltigBis',
          header: 'Gültig bis',
          valueGetter: (row) => formatDate(row.gueltigBis)
        },
        {
          off: !isAllianz,
          field: 'preisEbene',
          header: 'Preisebene',
          valueGetter: (row) => row?.preisEbene?.name
        },
        {
          field: 'staffelmenge',
          header: 'Staffelmenge',
          type: 'number'
        },
        {
          field: 'zuschlAbs1',
          header: kondiDefinition.zuschlAbs1Label,
          headerTip: kondiDefinition.zuschlAbs1Tip,
          align: 'center',
          type: 'money2',
          hidden: isAllianz
        },
        {
          field: 'zuschlAbs2',
          header: kondiDefinition.zuschlAbs2Label,
          headerTip: kondiDefinition.zuschlAbs2Tip,
          align: 'right',
          type: 'money2',
          hidden: isAllianz
        },
        {
          field: 'rabattProz1',
          header: kondiDefinition.rabattProz1Label,
          headerTip: kondiDefinition.rabattProz1Tip,
          type: 'money2'
        },
        {
          field: 'rabattProz2',
          header: kondiDefinition.rabattProz2Label,
          headerTip: kondiDefinition.rabattProz2Tip,
          type: 'money2'
        },
        {
          field: 'rabattProz3',
          header: kondiDefinition.rabattProz3Label,
          headerTip: kondiDefinition.rabattProz3Tip,
          type: 'money2'
        },
        {
          field: 'rabattProz4',
          header: kondiDefinition.rabattProz4Label,
          headerTip: kondiDefinition.rabattProz4Tip,
          type: 'money2'
        },
        {
          field: 'rabattProz5',
          header: kondiDefinition.rabattProz5Label,
          headerTip: kondiDefinition.rabattProz5Tip,
          type: 'money2'
        },
        {
          field: 'zuschlAbs3',
          header: kondiDefinition.zuschlAbs3Label,
          headerTip: kondiDefinition.zuschlAbs3Tip,
          type: 'money2',
          off: isAllianz
        },
        {
          field: 'zuschlAbs4',
          header: kondiDefinition.zuschlAbs4Label,
          headerTip: kondiDefinition.zuschlAbs4Tip,
          type: 'money2',
          off: isAllianz
        },
        {
          field: 'zuschlAbs5',
          header: kondiDefinition.zuschlAbs5Label,
          headerTip: kondiDefinition.zuschlAbs5Tip,
          type: 'money2',
          off: isAllianz
        }
      ] as Column<EkKonditionenRabattgruppeDisplayJson>[],
    [kondiDefinition, isAllianz]
  )

  const groupBy = useMemo(
    () => ({
      field: 'name',
      groupEmpty: false,
      body: (row) => `Rabattgruppe ${row.name}${row.bezeichnug ? ` - ${row.bezeichnung}` : ''}`
    }),
    []
  )

  const topActions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Rabattgruppe hinzufügen',
          navlink: lieferant
            ? AppPaths.EkRabattgruppenPflegeFn({ lieferantId: lieferant.id })
            : null,
          icon: 'add',
          enabled: Boolean(result.rows) && lieferant != null
        }
        // {
        //   role: UserRoles.STAMMDATEN_EDITOR,
        //   tooltip: 'Rabattgruppe Massenpflege',
        //   navlink: lieferantId ? AppPaths.EkRabattgruppenMassenPflegeFn({ lieferantId }) : null,
        //   icon: <TableView />,
        //   enabled: Boolean(values) && lieferantId != null
        // }
      ] as Action[],
    [lieferant, result.rows]
  )

  const tableAction = useMemo(
    (): DataTableAction<EkKonditionenRabattgruppeDisplayJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (data) =>
          AppPaths.EkRabattgruppenPflegeFn({ id: data.kopfId, versionId: data.versionId })
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="EkRabattgruppenTable"
      filterMode="both"
      selectMode="single"
      focustype="row"
      columns={columns}
      dense
      title="Rabattgruppen"
      topActions={topActions}
      actions={tableAction}
      paging
      localStateName="EkRabattgruppenTable"
      result={result}
    />
  )
}
