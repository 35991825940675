import { PimArtikelVkPreisJson } from '@one/typings/apiTypings'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'

export interface ArtikelPimPreiseProps {
  vkPreise: PimArtikelVkPreisJson[]
}

export const ArtikelPimVkPreise = ({ vkPreise }: ArtikelPimPreiseProps) => {
  if (!vkPreise?.length) {
    return null
  }

  const columns: Column<PimArtikelVkPreisJson>[] = [
    {
      field: 'gueltigAb',
      header: 'Gültig ab',
      type: 'date'
    },
    {
      field: 'gueltigBis',
      header: 'Gültig bis',
      type: 'date'
    },
    {
      field: 'waehrung.bez',
      header: 'Währung',
      tooltip: (row) => row.waehrung?.kurzBez
    },
    // TODO: Header an ONE-Frontend header anpassen, oder die aus PIM übernehmen?
    {
      field: 'preisBasis',
      header: 'Preis-Basis',
      type: 'money4'
    },
    {
      field: 'preisMenge',
      header: 'Preis-Menge',
      type: 'money4'
    },
    {
      field: 'preisMengeneinheit',
      header: 'Preismengeneinheit',
      valueGetter: (row) => `${row.preisMengeneinheit?.name ?? ''}`
    },
    {
      field: 'grundpreisMasseinheit',
      header: 'Grundpreis Masseinheit'
    },
    {
      field: 'grundpreisFaktor',
      header: 'Grundpreis Faktor',
      type: 'money4'
    },
    {
      field: 'nettoVk',
      header: 'Netto-VK',
      type: 'money4'
    },
    {
      field: 'bruttoNetto',
      header: 'Brutto/Netto',
      type: 'money4'
    },
    {
      field: 'festpreis',
      header: 'Festpreis'
      // valueGetter: (row) => (row.festpreis ? 'ja' : row.festpreis == null ? '' : 'nein')
    },
    {
      field: 'pg1',
      header: 'PG 1',
      type: 'money4'
    },
    {
      field: 'pg2',
      header: 'PG 2',
      type: 'money4'
    },
    {
      field: 'pg3',
      header: 'PG 3',
      type: 'money4'
    },
    {
      field: 'pg4',
      header: 'PG 4',
      type: 'money4'
    },
    {
      field: 'pg5',
      header: 'PG 5',
      type: 'money4'
    },
    {
      field: 'pg6',
      header: 'PG 6',
      type: 'money4'
    },
    {
      field: 'mengeStaffel1',
      header: 'Menge Staffel 1',
      type: 'money4'
    },
    {
      field: 'preisPg1Staffel1',
      header: 'Preis PG 1 Staffel 1',
      type: 'money4'
    },
    {
      field: 'preisPg2Staffel1',
      header: 'Preis Pg 2 Staffel 1',
      type: 'money4'
    },
    {
      field: 'preisPg3Staffel1',
      header: 'Preis Pg 3 Staffel 1',
      type: 'money4'
    },
    {
      field: 'preisPg4Staffel1',
      header: 'Preis Pg 4 Staffel 1',
      type: 'money4'
    },
    {
      field: 'preisPg5Staffel1',
      header: 'Preis Pg 5 Staffel 1',
      type: 'money4'
    },
    {
      field: 'mengeStaffel2',
      header: 'Menge Staffel 2',
      type: 'money4'
    },
    {
      field: 'preisPg1Staffel2',
      header: 'Preis PG 1 Staffel 2',
      type: 'money4'
    },
    {
      field: 'preisPg2Staffel2',
      header: 'Preis Pg 2 Staffel 2',
      type: 'money4'
    },
    {
      field: 'preisPg3Staffel2',
      header: 'Preis Pg 3 Staffel 2',
      type: 'money4'
    },
    {
      field: 'preisPg4Staffel2',
      header: 'Preis Pg 4 Staffel 2',
      type: 'money4'
    },
    {
      field: 'preisPg5Staffel2',
      header: 'Preis Pg 5 Staffel 2',
      type: 'money4'
    },
    {
      field: 'mengeStaffel3',
      header: 'Menge Staffel 3',
      type: 'money4'
    },
    {
      field: 'preisPg1Staffel3',
      header: 'Preis PG 1 Staffel 3',
      type: 'money4'
    },
    {
      field: 'preisPg2Staffel3',
      header: 'Preis Pg 2 Staffel 3',
      type: 'money4'
    },
    {
      field: 'preisPg3Staffel3',
      header: 'Preis Pg 3 Staffel 3',
      type: 'money4'
    },
    {
      field: 'preisPg4Staffel3',
      header: 'Preis Pg 4 Staffel 3',
      type: 'money4'
    },
    {
      field: 'preisPg5Staffel3',
      header: 'Preis Pg 5 Staffel 3',
      type: 'money4'
    },
    {
      field: 'mengeStaffel4',
      header: 'Menge Staffel 4',
      type: 'money4'
    },
    {
      field: 'preisPg1Staffel4',
      header: 'Preis PG 1 Staffel 4',
      type: 'money4'
    },
    {
      field: 'preisPg2Staffel4',
      header: 'Preis Pg 2 Staffel 4',
      type: 'money4'
    },
    {
      field: 'preisPg3Staffel4',
      header: 'Preis Pg 3 Staffel 4',
      type: 'money4'
    },
    {
      field: 'preisPg4Staffel4',
      header: 'Preis Pg 4 Staffel 4',
      type: 'money4'
    },
    {
      field: 'preisPg5Staffel4',
      header: 'Preis Pg 5 Staffel 4',
      type: 'money4'
    },
    {
      field: 'mengeStaffel5',
      header: 'Menge Staffel 5',
      type: 'money4'
    },
    {
      field: 'preisPg1Staffel5',
      header: 'Preis PG 1 Staffel 5',
      type: 'money4'
    },
    {
      field: 'preisPg2Staffel5',
      header: 'Preis Pg 2 Staffel 5',
      type: 'money4'
    },
    {
      field: 'preisPg3Staffel5',
      header: 'Preis Pg 3 Staffel 5',
      type: 'money4'
    },
    {
      field: 'preisPg4Staffel5',
      header: 'Preis Pg 4 Staffel 5',
      type: 'money4'
    },
    {
      field: 'preisPg5Staffel5',
      header: 'Preis Pg 5 Staffel 5',
      type: 'money4'
    }
  ]

  return (
    <DataTableCard
      title="VK-Preise"
      name="PimVkPreisTabelle"
      columns={columns}
      value={vkPreise}
      dense
      emptyMessage="Keine Daten"
      autoHeight
    />
  )
}
