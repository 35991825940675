import { Box, Tooltip, TooltipProps } from '@mui/material'
import { ReactNode } from 'react'

export interface TooltipExProps extends TooltipProps {
  help?: boolean
  popperClassName?: string
}

export const TooltipEx = ({ children, help, popperClassName, ...props }: TooltipExProps) => {
  return (
    <Tooltip
      {...props}
      PopperProps={{ container: document.body, className: popperClassName }}
      style={help ? { cursor: 'help' } : undefined}
    >
      {children}
    </Tooltip>
  )
}

export type TooltipWrapperProps = {
  title: ReactNode
  arrow?: boolean
  help?: boolean
  children: React.ReactNode
}

export const TooltipWrapper = ({ title, children, arrow, help }: TooltipWrapperProps) => {
  if (title) {
    return (
      <TooltipEx title={title} arrow={arrow} help={help}>
        <Box>{children}</Box>
      </TooltipEx>
    )
  }
  return children
}
