import { ArrowDropUp } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Box, IconButton, TableSortLabel } from '@mui/material'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { stopPropagation } from '@utils/ui/uiutils'
import clsx from 'clsx'
import { Column, ColumnFilterTrigger, DataTableOrderType } from './DataTable'

export interface DataTableColumnHeadProps {
  column: Column
  classes: any
  sortHandler: (column: Column) => (e: any) => void
  columnFilterTrigger?: ColumnFilterTrigger
  orderBy?: DataTableOrderType
  title?: string
  filterRows?: (mc: number) => any[]
  align?: 'left' | 'right' | 'center'
}

export const DataTableColumnHead = ({
  column,
  sortHandler,
  orderBy,
  classes,
  columnFilterTrigger,
  title,
  filterRows,
  align
}: DataTableColumnHeadProps) => {
  const isFilter = columnFilterTrigger?.isFilter(column)

  const left = align === 'right'
  const colFilterTrigger = left ? classes.colFilterLeft : classes.colFilterRight
  const colFilterOn = left ? classes.colFilterLeftOn : classes.colFilterRightOn

  const filter = columnFilterTrigger && (
    <div
      className={clsx(classes.colFilter, colFilterTrigger, isFilter ? colFilterOn : 'colFilterOff')}
    >
      <IconButton
        onClick={(e) => {
          stopPropagation(e)
          columnFilterTrigger.onFilter(column, filterRows)
        }}
      >
        <FilterListIcon />
      </IconButton>
      <small>{isFilter}</small>
    </div>
  )

  // className={clsx(classes.colHead, columnFilterTrigger && classes.colHeadFilterSpace)}

  return (
    <TooltipEx title={title} arrow>
      {column.sortable === false ? (
        <Box
          className={clsx(
            classes.colHead
            // columnFilterTrigger.isFilter(column) && classes.colHeadFilterSpace
          )}
          data-name={column.field}
        >
          {column.header}
          {filter}
        </Box>
      ) : (
        <TableSortLabel
          active={!!orderBy.dir}
          direction={orderBy.dir || 'asc'}
          onClick={sortHandler(column)}
          className={classes.sortLabel}
          IconComponent={ArrowDropUp}
        >
          <span
            className={clsx(
              classes.colHead
              // columnFilterTrigger?.isFilter(column) && classes.colHeadFilterSpace
            )}
            data-name={column.field}
          >
            {column.header}
            {filter}
          </span>
          {orderBy.dir ? (
            <>
              <span
                style={{
                  order: 1,
                  fontSize: '10px',
                  top: 10,
                  position: 'absolute',
                  right: 0
                }}
              >
                {orderBy.idx}
              </span>
              <span className={classes.visuallyHidden}>
                {orderBy.dir === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            </>
          ) : null}
        </TableSortLabel>
      )}
    </TooltipEx>
  )
}
