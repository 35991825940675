import { Refresh } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import {
  AllianzDatenValidierenType,
  MessageJson,
  MessageSeverity,
  UseCaseStateJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { flattenErrors } from '@utils/error'
import { useObserver } from '@utils/observer'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FehlermeldungenTable } from './FehlermeldungenTable'

export type AllianzDatenFehlerViewType = 'bereitstellung' | 'erpexport'

export const AllianzDatenFehlerView = () => {
  const { type } = useContext(RouteContext) as any

  const [fehlerliste, setFehlerliste] = useState<MessageJson[]>(null)
  const fehlerlisteRef = useRef<any>()
  fehlerlisteRef.current = fehlerliste

  const [apiCall, apiBusy] = useApiCaller(api)

  const onResponse = (response: UseCaseStateJson) => {
    const messages = flattenErrors(response.messages)
    setFehlerliste(response.mainMessage ? [response.mainMessage, ...messages] : messages)
  }

  const { eventName, title } = useMemo(() => {
    let title = 'Ungültiger Aufruf'
    switch (type) {
      case AllianzDatenValidierenType.Bereitstellung:
        title = 'Prüfungmeldungen für Bereitstellung der Allianzdaten an Allianzmitglieder'
        break
      case AllianzDatenValidierenType.Erpexport:
        title = 'Prüfungmeldungen für ERP-Export der Allianzdaten'
        break
    }
    return {
      title,
      eventName: AppPaths.AllianzDatenFehlerViewFn(type as AllianzDatenValidierenType)
    }
  }, [type])

  const onRefresh = useCallback(() => {
    apiCall({
      method: 'POST',
      rest: '/allianz/datenValidieren',
      params: { type },
      onSuccess: (data, response) => {
        onResponse({
          mainMessage: {
            message: 'Es wurden keine Fehler gefunden!',
            severity: MessageSeverity.INFO
          }
        })
        return true
      },
      onError: (error) => {
        const cleaned = {
          messages: error.messages?.length > 0 ? error.messages : [error.mainMessage]
        }
        onResponse(cleaned)
        return true
      }
    })
  }, [apiCall, type])

  useObserver(eventName, (event) => {
    onResponse(event.data)
  })

  useEffect(() => {
    setTimeout(() => {
      if (fehlerlisteRef.current == null) {
        onRefresh()
      }
    }, 100)
  }, [onRefresh])

  return (
    <Frame space>
      <FrameBody>
        <FehlermeldungenTable title={title} values={fehlerliste} loading={apiBusy} />
      </FrameBody>
      <FrameRow>
        <ButtonRow>
          <Button
            onClick={onRefresh}
            StartIcon={Refresh}
            label="Erneut prüfen"
            variant="contained"
          />
        </ButtonRow>
      </FrameRow>
    </Frame>
  )
}
