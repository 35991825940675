/**
 * Eventnames zur Benachrichtung anderer Komponenten über geänderte Daten
 */
export const EventNames = {
  PULSE_OFFLINE: 'PULSE_OFFLINE',
  PULSE_ERROR: 'PULSE_ERROR',
  PULSE_EXPORTSTATUS: 'PULSE_EXPORTSTATUS',
  PULSE_IMPORTSCHEDULERSTATUS: 'PULSE_IMPORTSCHEDULERSTATUS',
  //
  DASHBOARD: 'DASHBOARD',
  VKPREISLISTE: 'VKPREISLISTE',
  EKPREISLISTE: 'EKPREISLISTE',
  EKPREISIMPORT: 'EKPREISIMPORT',
  ARTIKELVERTEILER: 'ARTIKELVERTEILER',
  LIEFERANTENVERTEILER: 'LIEFERANTENVERTEILER',
  LIEFERANTENLISTE: 'LIEFERANTENLISTE',
  ARTIKELLISTE: 'ARTIKELLISTE',
  ARTIKELABO: 'ARTIKELABO',
  ARTIKELABOSELECTION: 'ARTIKELABOSELECTION',
  SESSION_CONNECT: 'SESSION_CONNECT',
  ARTIKELLABEL: 'ARTIKELLABEL',
  EKRABATTGRUPPEN: 'EKRABATTGRUPPEN',
  PREISEBENEN: 'PREISEBENEN',
  ALLIANZVERTEILER: 'ALLIANZVERTEILER',
  STANDORT: 'STANDORT',
  STANDORTGRUPPE: 'STANDORTGRUPPE',
  EXPORTINFO: 'EXPORTINFO',
  ALLIANZBEREITSTELLUNG: 'ALLIANZBEREITSTELLUNG',
  ARSST: 'ARSST',
  LISST: 'LISST',
  EIGENLISTUNGSLIEFERANT: 'EIGENLISTUNGSLIEFERANT',
  EIGENLISTUNGSARTIKEL: 'EIGENLISTUNGSARTIKEL',
  TAX: 'Tax'
}
