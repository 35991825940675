/* eslint-disable react/jsx-props-no-spreading */
import { debugLog } from '@utils/logging'
import { GlobalDialogContext, GlobalDialogContextType } from '@utils/ui/DialogAnker'
import { HelpContext } from '@utils/ui/help/HelpWrapper'
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation, useNavigationType, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { AppRouteCtxType } from './AppRouteCtx'
import { AppSwitchCtx } from './AppSwitchCtx'

const visible = {}
const invisible = { display: 'none' }

const compareRoute = (r1: any, r2: any) => {
  if (r1.key !== r2.key) {
    return false
  }
  if (r1.visible !== r2.visible) {
    return false
  }
  if (r1.url !== r2.url) {
    return false
  }
  if (r1.search !== r2.search) {
    return false
  }
  return true
}

const compareRoutes = (r1: any, r2: any) => {
  if (r1 == null || r2 == null) {
    return false
  }
  if (r1.length !== r2.length) {
    return false
  }
  for (let i = 0; i < r1.length; i += 1) {
    if (!compareRoute(r1[i], r2[i])) {
      return false
    }
  }
  return true
}

export interface AppRouteProps {
  component?: any
  render?: any
  title?: string
  nocache?: boolean
  path?: string | string[]
  exact?: boolean
}

interface AppRoutePropsInternal {
  path?: string
  exact?: boolean
  location?: any
  computedMatch?: any
}

export const RouteContext = React.createContext({})

export const AppRoute = ({ component, title, path, nocache = false }: AppRouteProps) => {
  const {
    getState,
    setRoutes,
    setWindowTitle,
    checkDirtyHook,
    replaceSearch,
    replaceHistory: replaceHistoryAppSwitch
  } = useContext(AppSwitchCtx)
  const params = useParams()
  const location = useLocation()
  const action = useNavigationType()
  const { setCurrentRouteKey } = useContext<GlobalDialogContextType>(GlobalDialogContext)
  const helpCtx = useContext(HelpContext)
  const Component = component

  const updateCache = useCallback(() => {
    // eslint-disable-next-line
    const applyTitle = (dynamicTitle: string) => {
      setWindowTitle(title && dynamicTitle ? `${title} - ${dynamicTitle}` : dynamicTitle || title)
    }

    const state = getState()
    if (state == null) {
      return null
    }

    // state.cache.forEach((it: AppRouteCtxType) => {
    //   if (state.current != it.key) {
    //     const diff = Math.abs(Date.now() - it?.lastVisited || 0)
    //     console.log('APPROUTE-STATEXXXXXXXXXXXX', diff)
    //     if ((nocache || false) && !checkDirtyHook(it.key)) {
    //       debugLog('APPROUTE-GC', it.url)
    //       state.cache.delete(it.url)
    //     }
    //   }
    // })

    let item = state.cache.get(location.pathname) as AppRouteCtxType
    if (item == null) {
      const uuid = uuidv4()
      item = {
        key: uuid,
        name: location.pathname,
        id: `__route_${uuid}`,
        pathKey: (Array.isArray(path) && path[0]) || (path as string),
        nocache
      }
    }

    const nextItem: AppRouteCtxType = {
      ...item,
      body: (
        <RouteContext.Provider value={params}>
          <Component {...params} />
        </RouteContext.Provider>
      ),
      componentName: Component.name,
      replaceSearch,
      replaceHistory: (replaceFn) => {
        if (item.key == getState().current) {
          replaceHistoryAppSwitch(replaceFn)
        }
      },
      setWindowTitle: (dynamicTitle: string) => {
        nextItem.dynamicTitle = dynamicTitle
        applyTitle(dynamicTitle)
      },
      invalidateRoute: () => {
        state.cache.delete(location.pathname)
      },
      visible: true,
      style: visible,
      url: window.location.href,
      path: location.pathname,
      name: location.pathname,
      search: location.search,
      action
    }

    state.current = nextItem.key

    if (compareRoute(item, nextItem)) {
      return
    }

    nextItem.lastNav = Date.now()

    state.cache.set(location.pathname, nextItem)

    let next = Array.from(state.cache.values())
      .map((c, index) => {
        if (c.key === nextItem.key) {
          if (c.visible !== true) {
            const x: AppRouteCtxType = {
              ...c,
              visible: true,
              style: visible
            }
            state.cache.set(x.path, x)
            applyTitle(c.dynamicTitle)
            return x
          }
        } else {
          let x = c
          if (c.visible !== false) {
            x = { ...x, visible: false, style: invisible }
            state.cache.set(x.path, x)
            return x
          }

          state.cache.set(x.path, x)
          return x
        }
        return c
      })
      .filter(Boolean)

    if (next.length > state.maxCacheKeepAliveCount) {
      const clean = [...next]
        .sort((a, b) => b.lastNav - a.lastNav)
        .filter((c, idx) => {
          if (
            !checkDirtyHook(c.key) /*c.nocache ||*/ &&
            ((idx > state.maxCacheKeepAliveCount &&
              Math.abs(Date.now() - c?.lastNav || 0) > state.minCacheAge) ||
              Math.abs(Date.now() - c?.lastNav || 0) > state.maxCacheAge)
          ) {
            debugLog('APPROUTE-GC', c.url)
            return false
          }
          return true
        })
      next = next.filter((c) => clean.includes(c))
    }

    debugLog('APPROUTE-RENDERHOOK', location.pathname)

    if (!compareRoutes(next, state.all)) {
      debugLog('APPROUTE-SHOW', nextItem.url)
      // setTimeout(() => {
      setRoutes(next)
      // }, 10)

      setTimeout(() => {
        setCurrentRouteKey(nextItem.key)
        if (helpCtx) {
          helpCtx.setHelpLocation(nextItem.pathKey, title)
        }
      }, 250)
    }
    applyTitle(item.dynamicTitle)
  }, [
    Component,
    action,
    checkDirtyHook,
    getState,
    helpCtx,
    location.pathname,
    location.search,
    nocache,
    params,
    path,
    replaceHistoryAppSwitch,
    replaceSearch,
    setCurrentRouteKey,
    setRoutes,
    setWindowTitle,
    title
  ])

  useEffect(() => {
    updateCache()
  }, [params, updateCache])

  return null
}
