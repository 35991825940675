import { Box } from '@mui/material'
import { api } from '@one/api'
import { useModelMgr } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { RadioGroup } from '@utils/ui/fields/RadioGroup'
import {
  EkPreiseMasseBearbeitenEintragJson,
  VkPreiseMasseBearbeitenEintragJson
} from '@one/typings/apiTypings'

export interface PreiseAbgeschlossenDialogProps {
  listeId: number
  preisEbeneId?: number
  standortId?: number
  visible: boolean
  onClose: (fn?: () => void) => () => void
  selected: EkPreiseMasseBearbeitenEintragJson[] | VkPreiseMasseBearbeitenEintragJson[]
  onComplete: (updated?: any) => void
  rest: string
  title: string
}

export const PreiseAbgeschlossenDialog = ({
  onClose,
  onComplete,
  listeId,
  preisEbeneId,
  standortId,
  visible = true,
  selected,
  rest,
  title
}: PreiseAbgeschlossenDialogProps) => {
  const { model, onValueChange, isChanged, save } = useModelMgr({
    id: 'neu',
    api,
    title,
    rest,
    restSave: 'abschliessen',
    restCreate: null,
    init: () => ({ abgeschlossen: null }),
    onSave: onClose(onComplete),
    saveMutator: (data) => {
      return {
        id: 4711, // ActionId
        listeId,
        preisEbeneId,
        standortId,
        eintraege: selected.map((s: Pick<EkPreiseMasseBearbeitenEintragJson, 'id'>) => s.id),
        abgeschlossen: data.abgeschlossen
      }
    }
  })

  const actions = (
    <>
      <Button
        label={`Für ${selected.length} Einträge anwenden`}
        onClickVoid={save}
        variant="contained"
        disabled={!isChanged}
      />
      <CancelButton onClick={onClose()} />
    </>
  )

  const radios = [
    {
      label: 'Unverändert',
      value: null
    },
    {
      label: 'Abgeschlossen',
      value: true
    },
    {
      label: 'Nicht abgeschlossen',
      value: false
    }
  ]

  return (
    <DialogEx
      open={visible}
      onClose={onClose()}
      maxWidth={false}
      actions={actions}
      title="Abgeschlossen ändern"
      disabledFullscreen
      smallHeader
      height="250px"
    >
      <Box padding={1}>
        <RadioGroup
          type="boolean"
          name="abgeschlossen"
          size="small"
          value={model.abgeschlossen}
          onChange={onValueChange}
          options={radios}
        />
      </Box>
    </DialogEx>
  )
}
