import { Download, EditNote, FolderOpen } from '@mui/icons-material'
import { Breadcrumbs, Link } from '@mui/material'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { FileJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { UploadButton } from '@utils/ui/Buttons/UploadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { PermissionUtil } from '@utils/ui/Permission'
import { useDownloader } from '@utils/ui/downloader'
import { stopPropagation } from '@utils/ui/uiutils'
import { formatBytes } from '@utils/utils'
import { useCallback, useContext, useEffect, useState } from 'react'
import { PIMJsonExportDialog } from './PIMJsonExportDialog'
import { ZARTXMLExportDialog } from './ZARTXMLExportDialog'

const rebuildStack = (stack: FileJson[], root: FileJson) => {
  if (root == null) {
    return null
  }
  const copy = [root]
  let folder = root
  for (let index = 1; index < stack?.length; index++) {
    const element = stack[index]
    const found = folder?.entries?.find((e) => e.name === element.name)
    if (found == null) {
      break
    }
    copy.push(found)
    folder = found
  }
  return copy
}

const countFiles = (file: FileJson) => {
  if (file == null) {
    return 0
  }
  if (!file.folder) {
    return 1
  }
  if (file.entries == null) {
    return null
  }
  return file.entries
    .map((e) => countFiles(e))
    .reduce((c, a) => {
      return a + c
    }, 0)
}

type StateType = {
  header?: string
  root?: FileJson
  folder?: FileJson
  stack?: FileJson[]
  entries?: FileJson[]
}

export const DataView = () => {
  const { checkUserRole } = useContext(AppContext)
  const isTester = checkUserRole(UserRoles.TESTER)

  const [Anker, show] = useDialogAnker()

  const [apiCall, apiBusy] = useApiCaller(api)

  const [download] = useDownloader()

  const [state, setState] = useState<StateType>({})

  const refresh = useCallback(
    () =>
      apiCall<any>({
        method: 'GET',
        rest: 'admin/dataFiles',
        onSuccess: (data) => {
          const next = rebuildStack(state.stack, data?.root)
          setState({
            root: data?.root,
            stack: next,
            folder: next && next[next.length - 1],
            header: `Anzahl Dateien: ${countFiles(data?.root)}`
          })
        }
      }),
    [apiCall, state.stack]
  )

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onShowZARTXMLExporter = () => {
    show((visible, onClose) => <ZARTXMLExportDialog open={visible} onClose={onClose} />)
  }

  const onShowPIMXMLExporter = () => {
    show((visible, onClose) => <PIMJsonExportDialog open={visible} onClose={onClose} />)
  }

  const columns = [
    {
      header: 'Name',
      field: 'name'
    },
    {
      header: 'Änderungsdatum',
      field: 'lastModified',
      type: 'datetimesecond'
    },
    {
      header: 'Größe',
      field: 'entryCount',
      align: 'right',
      valueGetter: (row) => {
        if (row.folder) {
          return row.entries?.length ? row.entries?.length + ' Einträge' : 'leer'
        } else {
          return formatBytes(row.fileSize)
        }
      }
    }
  ] as Column<FileJson>[]

  const actions = [
    <Button
      visible={isTester}
      key="pimi"
      StartIcon={EditNote}
      label="PIM-Datei"
      tooltip="PIM-Datei für Artikel erzeugen..."
      onClick={onShowPIMXMLExporter}
    />,
    <UploadButton
      visible={isTester}
      key="pim-upload"
      label="PIM-upload"
      tooltip="Eine Json-Datei mit PIM-Artikeln und/oder Preisen hochladen und einspielen"
      api={api}
      accept="application/json"
      path="pim/upload"
      onComplete={refresh}
    />,
    <Button
      visible={isTester}
      key="zarti"
      StartIcon={EditNote}
      label="ZART-Datei"
      tooltip="ZART-Datei für Artikel erzeugen..."
      onClick={onShowZARTXMLExporter}
    />,
    <UploadButton
      visible={isTester}
      key="upload"
      label="ZART-upload"
      tooltip="Eine XML-Datei mit ZART-Artikeln und/oder Preisen und Lieferanten hochladen und einspielen"
      api={api}
      accept="application/xml"
      path="admin/dataFile"
      onComplete={refresh}
    />
    // {
    //   icon: 'refresh',
    //   onClick: () => refresh(),
    //   tooltip: 'Aktualisieren'
    // }
  ] as any

  const bottomActions = [
    {
      // icon: 'refresh',
      onClick: () => refresh(),
      text: 'Suchen'
    }
  ]
  const tableActions = [
    {
      icon: <FolderOpen />,
      direct: true,
      tooltip: 'Öffnen',
      check: (row) => PermissionUtil.show(row.folder),
      handler: (row) => {
        setState({
          ...state,
          folder: row,
          stack: [...state.stack, row]
        })
      }
    },
    {
      icon: <Download />,
      direct: true,
      tooltip: 'Download',
      check: (row) => PermissionUtil.show(!row.folder),
      handler: (row) => {
        download(
          `rest/admin/dataFile?path=${encodeURIComponent(row.path)}&file=${encodeURIComponent(
            row.name
          )}`
        )
      }
    }
  ] as DataTableAction[]

  const breadcrumb = (
    <Breadcrumbs maxItems={100} aria-label="breadcrumb" style={{ paddingTop: 4 }}>
      {state?.stack?.map((folder, idx) => (
        <Link
          key={folder.name}
          underline="hover"
          href="#"
          onClick={(e) => {
            stopPropagation(e)
            setState({
              ...state,
              folder,
              stack: state?.stack.slice(0, idx + 1)
            })
          }}
        >
          {folder.name}
        </Link>
      ))}
    </Breadcrumbs>
  )

  return (
    <Frame space>
      <FrameRow>
        <CardEx
          title="Datenordner"
          backLink
          subheader={state.header}
          topActions={actions}
          bottomActions={bottomActions}
        />
      </FrameRow>
      <FrameBody>
        <DataTableCard
          name="DataView"
          title="Ordner"
          header={breadcrumb}
          filterMode="both"
          paging
          dense
          loading={apiBusy}
          columns={columns}
          value={state?.folder?.entries || []}
          actions={tableActions}
          localStateName="DataView"
          rowFiller
        />
      </FrameBody>
      <Anker />
    </Frame>
  )
}
