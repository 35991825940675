import { CheckCircle, NotInterested } from '@mui/icons-material'
import { green, grey, yellow } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { WerteCell } from '@one/components/common/DataPreisCell'
import { formatMengeneinheit } from '@one/components/common/formatters'
import { EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson } from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { FrameBody } from '@utils/ui/Frame'
import { ThemeContext } from '@utils/ui/Theme'
import { Medal } from '@utils/ui/fields/Medal'
import { formatMoney2, nameOf } from '@utils/utils'
import { useContext, useMemo } from 'react'
import { EkPreisImportUsecase } from './model/EkPreisImportUsecase'

export interface EkPreisImportPflegeStep5MePeUnterschiedeProps {
  peMeUnterschiede?: EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson[]
  peMeUnterschiedeChanges?: Map<number, boolean>
  dispatch: (action: ModelAction) => void
}

export const EkPreisImportPflegeStep5MePeUnterschiede = ({
  peMeUnterschiede,
  peMeUnterschiedeChanges,
  dispatch
}: EkPreisImportPflegeStep5MePeUnterschiedeProps) => {
  const appContext = useContext(AppContext)

  const { darkMode } = useContext(ThemeContext)

  const [selected, setSelected, getSelected] = useStateEx(new Set())

  const columnProps = useMemo<
    ArtikelDefaultColumnsProps<EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson>
  >(
    () => ({
      isGesellschafter: appContext.isGesellschafter,
      fieldWrapper: {
        wrapper: 'artikel'
      },
      addColumnToIndex: [
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: nameOf<EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson>('bepreisen'),
            header: 'Bepreisen?',
            align: 'center',
            body: (row: EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson) => {
              const val = peMeUnterschiedeChanges?.get(row.artikel.id) ?? row.bepreisen
              const computeText = () => {
                switch (val) {
                  case null:
                    return 'Zu Entscheiden'
                  case false:
                    return 'Nicht bepreisen'
                  case true:
                    return 'Bepreisen'
                }
              }
              const computeColor = () => {
                switch (val) {
                  case null:
                    return yellow[200]
                  case false:
                    return grey[200]
                  case true:
                    return green[200]
                }
              }
              const nextBepreisen = () => {
                if (row.konnteUmgerechnetWerden) {
                  switch (val) {
                    case null:
                      return true
                    case true:
                      return false
                    case false:
                      return null
                  }
                }
                switch (val) {
                  default:
                    return false
                  case false:
                    return null
                }
              }
              return (
                <Medal
                  text={computeText()}
                  backgroundColor={computeColor()}
                  variant="square"
                  onClick={() => {
                    dispatch({
                      type: EkPreisImportUsecase.SET_MEPE_BEPREISEN,
                      selected: new Set([row]),
                      bepreisen: nextBepreisen()
                    })
                  }}
                />
              )
            }
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'zeilenNr',
            header: 'Zeile',
            type: 'number'
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'staffelAb',
            header: 'Staffel ab',
            type: 'number',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.staffelAb),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="staffelAb"
                formatter={formatMoney2}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'fehlerTypMe',
            header: 'Fehlertyp',
            headerTip: 'Fehlertyp ME=Mengeneinheit, PE=Preiseinheit',
            align: 'center',
            cellPadding: 0,
            valueGetter: (row) =>
              row.werte
                .map((w) => [w.fehlerTypMe && 'ME', w.fehlerTypPe && 'PE'].filter(Boolean))
                .flatMap((e) => e),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="konnteUmgerechnetWerden"
                align="center"
                formatter={(_val, w) => (
                  <>
                    {w.fehlerTypMe && 'ME'}
                    {w.fehlerTypMe && w.fehlerTypPe && <>&nbsp;</>}
                    {w.fehlerTypPe && 'PE'}
                  </>
                )}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field:
              nameOf<EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson>(
                'konnteUmgerechnetWerden'
              ),
            header: 'umrechenbar?',
            headerTip: 'Vom Standard abweichende Angabe von PE/ME umrechenbar?',
            type: 'boolean',
            align: 'center',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => (w.konnteUmgerechnetWerden ? 'ja' : 'nein')),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="konnteUmgerechnetWerden"
                align="center"
                formatter={(_val, w) =>
                  w.konnteUmgerechnetWerden ? (
                    <CheckCircle style={{ fontSize: '1.02em' }} />
                  ) : (
                    <NotInterested style={{ fontSize: '1.02em' }} />
                  )
                }
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'umrechnungsfaktorME.faktor',
            header: 'Faktor',
            headerTip: 'Umrechnungsfaktor ME/PE',
            type: 'money2',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.umrechnungsfaktorME?.faktor),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="umrechnungsfaktorME.faktor"
                formatter={formatMoney2}
              />
            )
          }
        },

        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ekListenpreisUmgerechnet',
            header: 'LP umg.',
            headerTip: 'EK-Listenpreis umgerechnet',
            type: 'money2',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.ekListenpreisUmgerechnet),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="ekListenpreisUmgerechnet"
                formatter={formatMoney2}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ekListenpreis',
            header: 'LP aus Datei',
            headerTip: 'EK-Listenpreis aus Datei',
            type: 'money2',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.ekListenpreis),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="ekListenpreis"
                formatter={formatMoney2}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ekSonderpreisUmgerechnet',
            header: 'SP umgr.',
            headerTip: 'EK-Sonderpreis umgerechnet',
            type: 'money2',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.ekSonderpreisUmgerechnet),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="ekSonderpreisUmgerechnet"
                formatter={formatMoney2}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ekSonderpreis',
            header: 'SP aus Datei',
            headerTip: 'EK-Sonderpreis aus Datei',
            type: 'money2',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.ekSonderpreis),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="ekSonderpreis"
                formatter={formatMoney2}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ausDateiMe',
            header: 'ME aus Datei',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.ausDateiMe),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="ausDateiMe"
                formatter={(val) => val}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: nameOf<EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson>('meErp'),
            header: 'ME aus ERP',
            cellPadding: 0,
            valueGetter: (row) => formatMengeneinheit(row.meErp),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                formatter={() => formatMengeneinheit(row.meErp)}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ausDateiPe',
            header: 'PE aus Datei',
            type: 'money2',
            cellPadding: 0,
            valueGetter: (row) => row.werte.map((w) => w.ausDateiPe),
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                field="ausDateiPe"
                formatter={formatMoney2}
              />
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: nameOf<EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson>('peErp'),
            header: 'PE aus ERP',
            type: 'money2',
            cellPadding: 0,
            body: (row) => (
              <WerteCell
                darkMode={darkMode}
                werte={row.werte}
                formatter={() => formatMoney2(row.peErp)}
              />
            )
          }
        }
      ]
    }),
    [appContext.isGesellschafter, darkMode, dispatch, peMeUnterschiedeChanges]
  )

  const columns = useArtikelColumns(columnProps)

  const bottomActions = (
    <>
      <Button
        label="Bepreisen"
        disabled={selected.size === 0}
        onClick={() =>
          dispatch({
            type: EkPreisImportUsecase.SET_MEPE_BEPREISEN,
            selected,
            bepreisen: true
          })
        }
      />
      <Button
        label="Nicht bepreisen"
        disabled={selected.size === 0}
        onClick={() =>
          dispatch({
            type: EkPreisImportUsecase.SET_MEPE_BEPREISEN,
            selected,
            bepreisen: false
          })
        }
      />
    </>
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: any) => data.artikel && AppPaths.ArtikelFn(data.artikel?.id)
      }
    ],
    []
  )

  return (
    <FrameBody>
      <DataTableCard
        name="Step5MePeUnterschiede"
        localStateName="Step5MePeUnterschiede"
        title="Unterschiede Mengen- und Preiseinheiten zum ERP"
        paging
        initialOrderBy={[
          { key: 'zeilenNr', dir: 'asc' },
          { key: 'staffelAb', dir: 'asc' }
        ]}
        selectMode="multi"
        selectCol
        columns={columns}
        value={peMeUnterschiede}
        onSelect={setSelected}
        dense
        bottomActions={bottomActions}
        // groupBy={groupBy}
        actions={tableActions}
        filterMode="both"
      />
    </FrameBody>
  )
}
