import { PriceChange } from '@mui/icons-material'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import {
  EkKonditionenDefinitionJson,
  EkKonditionenWertJson,
  EkPreisDiffJson,
  LpPreisDiffJson
} from '@one/typings/apiTypings'
import { formatDate } from '@utils/dateutils'
import { AttributeTreeItem, ChangesTreeView, DiffTreeItem } from './ChangesTreeView'

interface EkKonditionenEbeneDiff {
  alt?: EkKonditionenWertJson
  neu?: EkKonditionenWertJson
}

interface LpPreisDiffJsonEx extends LpPreisDiffJson {
  werte: EkKonditionenEbeneDiff[]
}

const buildWerteDiff = (
  alt: EkKonditionenWertJson[] | null,
  neu: EkKonditionenWertJson[] | null
): EkKonditionenEbeneDiff[] => {
  const altEx = alt || []
  const neuEx = [...(neu || [])]

  const idx = altEx
    .map((a) => {
      const idx = neuEx.findIndex(
        (n) => n.standortId === a.standortId && n.staffelmenge === a.staffelmenge
      )
      if (idx > -1) {
        const n = neuEx[idx]
        neu.splice(idx, 1)
        return {
          alt: a,
          neu: n
        }
      }
      return { alt: a, neu: null }
    })
    .concat(neuEx.map((n) => ({ neu: n, alt: null })))

  idx.sort((a, b) => {
    const a1 = (a.alt || a.neu).staffelmenge || 0
    const b1 = (b.alt || b.neu).staffelmenge || 0
    if (a1 < b1) {
      return -1
    } else if (a1 > b1) {
      return 1
    }
    return 0
  })

  return idx
}

const buildIndex = (ekpreise: LpPreisDiffJson[]): LpPreisDiffJsonEx[] => {
  const idx = ekpreise.map(
    (ekpreis) =>
      ({
        ...ekpreis,
        werte: buildWerteDiff(ekpreis.alt?.konditionen?.werte, ekpreis.neu?.konditionen?.werte)
      }) as LpPreisDiffJsonEx
  )

  idx.sort((a, b) => {
    const a1 = (a.alt || a.neu).gueltigAb
    const b1 = (b.alt || b.neu).gueltigAb
    if (a1 < b1) {
      return -1
    } else if (a1 > b1) {
      return 1
    }
    return 0
  })

  return idx
}

const generateEkPreisChangeItems =
  (
    preisChanges: LpPreisDiffJson[] | EkPreisDiffJson[],
    konditionDefinition: EkKonditionenDefinitionJson
  ) =>
  (idgen, expandedIds, treeClasses, itemClasses) => {
    if (!(preisChanges?.length > 0)) {
      return []
    }

    const preisChangesEx = buildIndex(preisChanges)

    const kd = enrichKondiDefinition(konditionDefinition, false)

    const items = []
    preisChangesEx.forEach((preis) => {
      const id1 = idgen()
      expandedIds.push(id1)
      const id2 = idgen()
      expandedIds.push(id2)

      const { alt, neu } = preis
      const infoText = (alt == null && 'Neu') || (neu == null && 'Gelöscht') || 'Geändert'
      const base = neu || alt
      const path = `${infoText}/`

      const gueltigAb = formatDate(base.gueltigAb)

      items.push(
        <AttributeTreeItem
          key={id1}
          nodeId={id1}
          labelText={gueltigAb}
          name="gueltigAb"
          labelIcon={<PriceChange />}
          labelInfo={infoText}
          treeClasses={treeClasses}
          itemClasses={itemClasses}
        >
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText="EK-Preis"
            name="preis"
          />

          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText="Rabattgruppe"
            name="rabattgruppe?.name"
          />

          {preis.werte?.map((ww) => {
            const id3 = idgen()
            expandedIds.push(id3)
            const obj = ww.alt || ww.neu
            return (
              <AttributeTreeItem
                key={id3}
                nodeId={id3}
                labelText={`Staffelmenge ${obj.staffelmenge}`}
                labelIcon={<PriceChange />}
                labelInfo=""
                treeClasses={treeClasses}
                itemClasses={itemClasses}
                simple
              >
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText="Hauptstaffel"
                  name="hauptstaffel"
                  type="boolean"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText="Listenpreis"
                  name="listenpreis"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText="Sonderpreis"
                  name="sonderpreis"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.zuschlAbs1Label}
                  hidden={!kd.zuschlAbs1}
                  name="zuschlAbs1"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.zuschlAbs2Label}
                  hidden={!kd.zuschlAbs2}
                  name="zuschlAbs2"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.rabattProz1Label}
                  hidden={!kd.rabattProz1}
                  name="rabattProz1"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.rabattProz2Label}
                  hidden={!kd.rabattProz2}
                  name="rabattProz2"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.rabattProz3Label}
                  hidden={!kd.rabattProz3}
                  name="rabattProz3"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.rabattProz4Label}
                  hidden={!kd.rabattProz4}
                  name="rabattProz4"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.rabattProz5Label}
                  hidden={!kd.rabattProz5}
                  name="rabattProz5"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.zuschlAbs3Label}
                  hidden={!kd.zuschlAbs3}
                  name="zuschlAbs3"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.zuschlAbs4Label}
                  hidden={!kd.zuschlAbs4}
                  name="zuschlAbs4"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.zuschlAbs5Label}
                  hidden={!kd.zuschlAbs5}
                  name="zuschlAbs5"
                  type="money2"
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  alt={ww.alt}
                  neu={ww.neu}
                  labelText={kd.ekKondiFreiHausLabel}
                  name="ekFreiHaus"
                  type="money2"
                />
              </AttributeTreeItem>
            )
          })}
        </AttributeTreeItem>
      )
    })

    return items
  }

export type EkPreisChangeTreeViewProps = {
  konditionDefinition?: EkKonditionenDefinitionJson
  preisChanges?: LpPreisDiffJson[] | EkPreisDiffJson[]
}

export const EkPreisChangeTreeView = ({
  konditionDefinition,
  preisChanges
}: EkPreisChangeTreeViewProps) =>
  preisChanges ? (
    <ChangesTreeView
      generateItems={generateEkPreisChangeItems(preisChanges, konditionDefinition)}
    />
  ) : null
