import { CardEx } from '@utils/ui/CardEx'
import { StructPanel } from '@utils/ui/StructPanel'

export interface ProtokollDetailsSelectionProps {
  fragment: string | null
}

export const ProtokollDetailsSelection = ({ fragment }: ProtokollDetailsSelectionProps) =>
  fragment ? (
    <CardEx title="Fragment">
      <StructPanel value={fragment} />
    </CardEx>
  ) : null
