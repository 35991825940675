import { LabelJson } from '@one/typings/apiTypings'
import { ChipContainer } from '@utils/ui/fields/ChipContainer'

export const LabelChipContainer = ({ labels }: { labels: LabelJson[] }) => {
  return (
    <ChipContainer
      value={labels?.sort((a: LabelJson, b: LabelJson) => a.kurz.localeCompare(b.kurz))}
      getValueLabel={(label) => label?.kurz}
      getValueTip={(label) =>
        label?.system ? `Systemlabel: ${label?.bezeichnung}` : label?.bezeichnung
      }
      getChipColor={(label: LabelJson) => (label?.system ? 'info' : 'default')}
      compact
    />
  )
}
