import { Grid } from '@mui/material'
import { useMemo } from 'react'
import { HkmEnum } from '@one/enums/HkmEnum'
import { UmsatzsteuerSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { DateField } from '@utils/ui/fields/DateField'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'

export interface UmsatzsteuerViewParamsProps {
  value: UmsatzsteuerSearcherCriteriaJson | null
  onSearch: () => void
  onChange: ChangeFunction<UmsatzsteuerSearcherCriteriaJson>
}

export const UmsatzsteuerOverviewParams = ({
  value,
  onSearch,
  onChange
}: UmsatzsteuerViewParamsProps) => {
  const actions = useMemo(() => <SearchButton onClick={() => onSearch()} />, [onSearch])

  return (
    <ShortCutHandler shortcuts={{ Enter: onSearch }}>
      <CardEx backLink title="Umsatzsteuer" bottomActions={actions}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                name="land"
                label="Land"
                value={value.land}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                name="steuerkey"
                label="Steuerkey"
                value={value.steuerkey}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                name="steuersatz"
                label="Steuersatz"
                value={value.steuersatz}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <SelectEnumField
                label="Datenmodell"
                name="datenmodell"
                value={value.datenmodell}
                onChange={onChange}
                fullWidth
                enumType={HkmEnum.Datenmodell}
                emptyText="Alle"
              />
            </Grid>
            <Grid  item xs={12} sm={4} md={2}>
              <DateField
                label="gültig ab"
                disableToolbar
                value={value.gueltigAb}
                name="gueltigAb"
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
