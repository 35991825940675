import {
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  LinearProgress,
  Paper,
  Popper
} from '@mui/material'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { singleFromSet } from '@utils/utils'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface ArtikelPagerDisplayJson {
  id?: number
  artikelId?: number
  hageNummer?: string
  btNummer?: string
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
}

export interface ArtikelPagerProps {
  artikelList: ArtikelPagerDisplayJson[]
  artikelListLoading: boolean
  artikelPos: number
  onSwitchArtikel: (id: number) => void
  disabled?: boolean
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  popper: {
    zIndex: 1001
  },
  paper: {
    height: '30vh',
    minHeight: '480px',
    width: '60vw',
    minWidth: '800px',
    marginRight: '12px'
  }
}))

export const ArtikelPager = ({
  artikelList,
  artikelListLoading,
  artikelPos,
  onSwitchArtikel,
  disabled = false
}: ArtikelPagerProps) => {
  const { classes } = useStyles()
  const { isPIMModel } = useContext(AppContext)
  const divRef = useRef<any>()
  const menuRef = useRef<any>()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(new Set())

  const onPos = (idx) => {
    if (!disabled && idx >= 1 && idx <= artikelList.length) {
      setOpen(false)
      const eintragId = artikelList[idx - 1].id
      onSwitchArtikel(eintragId)
    }
  }

  const onNext = () => {
    setOpen(false)
    onPos(artikelPos + 1)
  }

  const onPrev = () => {
    setOpen(false)
    onPos(artikelPos - 1)
  }

  const onSelect = (row: any) => {
    setOpen(false)
    onPos(artikelList.indexOf(row) + 1)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleClose = (event) => {
    // if (menuRef.current && menuRef.current.contains(event.target)) {
    //   return
    // }
    setOpen(false)
  }

  useEffect(() => {
    const sel = new Set()
    sel.add(artikelList[artikelPos - 1])
    setSelected(sel)
  }, [artikelList, artikelPos])

  const columns = useMemo(
    () =>
      [
        {
          header: 'hage-Nr.',
          field: 'hageNummer'
        },
        {
          off: isPIMModel,
          header: 'Artikel-Nr.',
          field: 'btNummer'
        },
        {
          header: 'Bez1',
          field: 'bez1'
        },
        {
          header: 'Bez2',
          field: 'bez2'
        },
        {
          header: 'Bez3',
          field: 'bez3'
        },
        {
          header: 'Bez4',
          field: 'bez4'
        }
      ] as Column<ArtikelPagerDisplayJson>[],
    [isPIMModel]
  )

  if (artikelList?.length == null || artikelList.length <= 1) {
    return null
  }

  const actions = [
    <Button
      key="sel"
      onClick={() => onSelect(singleFromSet(selected))}
      color="primary"
      disabled={selected?.size !== 1}
    >
      Auswählen
    </Button>,
    <Button key="close" onClick={handleClose} color="secondary">
      Schließen
    </Button>
  ]

  const menu = ({ TransitionProps = {}, placement = '' }) => (
    <Grow
      {...TransitionProps}
      style={{
        transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
      }}
    >
      <Paper elevation={8} className={classes.paper}>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClose}
        >
          <span>
            <DataTableCard
              name="ArtikelPager"
              title="Artikel der Preisliste"
              columns={columns}
              value={artikelList}
              filterMode="both"
              dense
              selectCol
              selectMode="single"
              selected={selected}
              onSelect={setSelected}
              onRowDoubleClick={onSelect}
              paging={artikelList.length > 20}
              rowsPerPage={20}
              localStateName="ArtikelPager"
              bottomActions={actions}
            />
          </span>
        </ClickAwayListener>
      </Paper>
    </Grow>
  )

  return (
    <span>
      <ButtonGroup
        ref={divRef}
        orientation="horizontal"
        variant="text"
        size="small"
        color="inherit"
      >
        <Button
          endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={handleOpen}
          disabled={disabled || artikelListLoading}
        >
          <span
            ref={menuRef}
            style={{
              fontSize: '1.25em',
              fontWeight: '400',
              marginTop: -2,
              marginBottom: -2
            }}
            title="Click für Artikelauswahl..."
          >
            Artikel {artikelPos} von {artikelList.length}
          </span>
        </Button>
        <Button
          style={{ padding: 0 }}
          title="Zurückblättern"
          onClick={onPrev}
          disabled={artikelPos === 1 || disabled || artikelListLoading}
        >
          <KeyboardArrowLeft />
        </Button>
        <Button
          style={{ padding: 0 }}
          title="Vorblättern"
          onClick={onNext}
          disabled={artikelList.length == artikelPos || disabled || artikelListLoading}
        >
          <KeyboardArrowRight />
        </Button>
        <Popper
          open={open}
          anchorEl={menuRef.current}
          role={undefined}
          transition
          // disablePortal
          className={classes.popper}
        >
          {menu}
        </Popper>
      </ButtonGroup>
      {artikelListLoading ? <LinearProgress variant="indeterminate" /> : null}
    </span>
  )
}
