import { Box, LinearProgress, Typography } from '@mui/material'
import { formatDateTime } from '@utils/dateutils'
import { makeStyles } from 'tss-react/mui'
import { StatusItemWrapper, StatusItemWrapperProps } from './StatusItemWrapper'

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  progressBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  progress: {
    width: '100%',
    marginRight: '8px'
  },
  percentage: {
    minWidth: '35px'
  }
}))

export interface SyncStatusItemProps extends Omit<StatusItemWrapperProps, 'children'> {
  startTime: any
  isAktiv: boolean
  hasError?: boolean
  endTime?: any
  progress?: number
}

export const SyncStatusItem = ({
  startTime,
  endTime,
  progress,
  isAktiv,
  hasError = false,
  ...props
}: SyncStatusItemProps) => {
  const { classes } = useStyles()

  return startTime ? (
    <StatusItemWrapper {...props}>
      {isAktiv && (
        <Box className={classes.progressBox}>
          <Box className={classes.progress}>
            <LinearProgress
              variant="determinate"
              value={progress}
              color={hasError ? 'error' : 'primary'}
            />
          </Box>
          <Box className={classes.percentage}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              progress
            )}%`}</Typography>
          </Box>
        </Box>
      )}
      <>
        <Typography variant="body2">{`Start: ${formatDateTime(startTime)}`}</Typography>
        {!isAktiv && endTime && (
          <Typography variant="body2">{`Ende: ${formatDateTime(endTime)}`}</Typography>
        )}
      </>
    </StatusItemWrapper>
  ) : null
}
