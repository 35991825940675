import { api } from '@one/api'
import { EventNames } from '@one/EventNames'
import { DzMapperEinstellungTyp, MapperEinstellungJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<MapperEinstellungJson> = {
  api,
  refreshEvents: EventNames.ARSST,
  datakey: 'ArSST',
  rest: '/mapper/uebersichtAnzeigen/search',
  method: 'POST',
  data: {
    typ: DzMapperEinstellungTyp.ARSST
  },
  idField: 'id',
  field: 'searcher.result'
}

export const useArSSTCache = (flush: boolean = false) => useDataCache(config, flush)
