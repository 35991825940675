import { Grid } from '@mui/material'
import { api } from '@one/api'
import { GesellschafterJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useContext } from 'react'
import { OrganisationAnlageKopf } from './OrganisationAnlageKopf'
import { OrganisationAnlageTable } from './OrganisationAnlageTable'
import { OrganisationErpDetail } from './OrganisationErpDetail'

export const OrganisationAnlageView = () => {
  const { id } = useContext(RouteContext) as any

  const { model, errors, uiLock, onValueChange, isNew, isChanged, save, reload, updModel } =
    useModelMgr<GesellschafterJson>({
      id,
      api,
      unwrapField: 'gesellschafter',
      title: 'Gesellschafter',
      rest: 'gesellschafter/organisation'
    })

  const readonly = id !== 'neu'

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <OrganisationAnlageKopf
            model={model}
            isNew={isNew}
            errors={errors}
            onValueChange={onValueChange}
            readonly={readonly}
          />
        </FrameRow>
        {!isNew && (
          <FrameBody>
            <Grid container direction="row" height="100%" spacing={1}>
              <Grid item flexGrow={1}>
                <OrganisationAnlageTable firmen={model.firmen} id={model.id} />
              </Grid>
              <Grid item lg={5} md={12} sm={12} xs={12}>
                {model && (
                  <OrganisationErpDetail erpSysteme={model?.erpSysteme ?? []} updModel={updModel} />
                )}
              </Grid>
            </Grid>
          </FrameBody>
        )}
        <FrameRow>
          <ButtonRow>
            <SaveButton isChanged={isChanged} isNew={isNew} onClickVoid={save} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
