import { isNumber } from '@utils/numberutils'
import { Actions } from '@utils/ui/Action'
import { FieldCtrlType } from '@utils/ui/FieldCtrlType'
import { NumberFormat } from '@utils/ui/NumberFormat'
import { autoCompleteOff } from '@utils/ui/uiutils'
import { asNumber, ifString } from '@utils/utils'
import { TextField } from './TextField'

const shrink = { shrink: true }

export type NumberFieldProps = {
  id?: any
  name?: string
  label?: string
  value?: any
  onChange?: any
  actions?: Actions
  disabled?: boolean
  style?: React.CSSProperties
  autoComplete?: string
  error?: any
  helperText?: string
  required?: boolean
  fieldCtrl?: FieldCtrlType
  min?: number
  max?: number
  fraction: 0 | 1 | 2 | 3 | 4 | 5 | 6
  suffix?: string
  thousandSeparator?: boolean
  fullWidth?: boolean
  title?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  noClear?: boolean
  size?: 'small' | 'medium'
  compact?: boolean
}

export const NumberField = ({
  id,
  name,
  label,
  value,
  onChange,
  actions,
  disabled,
  style,
  autoComplete = autoCompleteOff,
  error,
  helperText,
  required,
  fieldCtrl,
  min,
  max,
  fraction,
  suffix,
  thousandSeparator,
  fullWidth,
  title,
  onKeyDown,
  placeholder,
  noClear,
  size,
  compact
}: NumberFieldProps) => {
  const onChangeInt = (e) => {
    if (e.target.value === '') {
      e.target.value = null
    } else if (e.target.value != null && !isNumber(e.target.value)) {
      return
    }
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <TextField
      autoComplete={autoComplete}
      name={name}
      label={label}
      InputLabelProps={shrink}
      InputProps={{
        style: { paddingLeft: 4 },
        inputComponent: NumberFormat,
        inputProps: {
          id,
          minValue: min,
          maxValue: max,
          fraction,
          suffix,
          thousandSeparator: thousandSeparator ? '.' : false,
          decimalSeparator: ',',
          variant: 'standard',
          autoComplete,
          dataName: label,
          placeholder: placeholder
        }
      }}
      value={asNumber(value)}
      onChange={onChangeInt}
      style={style}
      error={!!error || fieldCtrl?.error}
      helperText={ifString(error) || fieldCtrl?.helperText || helperText}
      required={required || fieldCtrl?.required}
      disabled={disabled || fieldCtrl?.disabled}
      fullWidth={fullWidth}
      actions={actions}
      title={title}
      onKeyDown={onKeyDown}
      actionsLeft
      noClear={noClear}
      size={size}
      compact={compact}
    />
  )
}
