import React, { useCallback, useContext, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { AppRouteCtx } from './ui/App/AppRouteCtx'
import { makeQueryParams } from './utils'

export const useQuery = <T = any>() => {
  const { search, replaceSearch } = useContext(AppRouteCtx)
  const lastRef = useRef({ s: null, q: [] })

  const setQuery = useCallback(
    (query: object) => {
      const searchparams = makeQueryParams(query)
      replaceSearch(searchparams)
    },
    [replaceSearch]
  )

  const query = React.useMemo(() => {
    if (!isEqual(lastRef.current.s, search)) {
      lastRef.current.s = search
      lastRef.current.q = [Object.fromEntries(new URLSearchParams(search || '')), setQuery]
    }
    return lastRef.current.q
  }, [search, setQuery])

  return query as [T, (query: T) => void]
}
