import { Typography } from '@mui/material'
import { notifyObservers } from '@utils/observer'
import { InfoPage } from '@utils/ui/Pages/InfoPage'
import { NeedLoginPage } from '@utils/ui/Pages/NeedLoginPage'
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Button } from '../Buttons/Button'
import { AppContext, AppContextState } from './AppContext'

export interface AppStateProps {
  api: any
  version: any
  title: string
  LoginDialog: any
  DefaultFrame?: any
  children: any
  extendAppContext?: any
  titleShort?: string
  connectEvent?: string
}

export interface UserRoleComplex {
  required?: string[]
  grantedBy?: string[]
}

export type UserRole = string | string[] | UserRoleComplex

export const AppState = ({
  api,
  version,
  title,
  titleShort,
  LoginDialog,
  DefaultFrame = ({ children }) => children,
  extendAppContext,
  connectEvent,
  children
}: AppStateProps) => {
  const [state, setState] = useState({ session: null, action: 'init', error: null })

  const meRef = useRef()

  const appContext = useMemo<AppContextState>(() => {
    //@ts-ignore
    const extension = extendAppContext == null ? null : extendAppContext(state.session)
    const userRoles =
      state.session && state.session.roles ? new Set(state.session.roles) : new Set<string>()
    return {
      ...extension,
      session: state.session,
      checkUserRole: (userRole?: UserRole) => {
        if (userRole == null) {
          return true
        }
        if (Array.isArray(userRole)) {
          return userRole.length === 0 || userRole.some((role) => userRoles.has(role))
        } else if (typeof userRole === 'object') {
          const allRequired = (userRole.required ?? []).every((role) => userRoles.has(role))
          const grantedBy = (userRole.grantedBy ?? []).some((role) => userRoles.has(role))
          return allRequired && grantedBy
        }
        return userRoles.has(userRole)
      },
      appTitle: title,
      appTitleShort: titleShort,
      appVersion: version
    }
  }, [extendAppContext, state.session, title, titleShort, version])

  const setConnect = useCallback(() => {
    setState({ session: null, action: 'connect', error: null })
  }, [])

  const onReload = useCallback(() => {
    window.location.reload()
  }, [])

  const onApiError = useCallback((error, action, all) => {
    if (error.response) {
      switch (error.response.status) {
        case 406:
          if (error.response.headers.responsehint === 'clientversion-outdated') {
            setState({ session: null, action: 'outdated', error: null })
            return
          }
      }

      if (action === 'loggedIn') {
        const url = error.response.request?.responseURL || ''
        switch (error.response.status) {
          case 401:
            switch (error.response.headers.unauthorizedhint) {
              case 'gln-changed':
                setState({ session: null, action: 'glnChanged', error: null })
                break
              case 'session-changed':
                setState({ session: null, action: 'sessionChanged', error: null })
                break
              case 'session-expired':
              case 'session-invalid':
              case 'session-failed':
                setState({ session: null, action: 'needLogin', error: null })
                break
              default:
                setState({ session: null, action: 'needLogin', error: null })
                break
            }
            return
          // Stört, wenn nur ein Service ein Timeout melden... evtl eher erkennen, ob server noch da per separatem service
          // case 504:
          //   if (url.endsWith('/poll') || url.endsWith('-poll')) {
          //     return
          //   }

          //   setState({ session: null, action: '504', error: null })
          //   return
          default:
            break
        }
      }
    }
    // @ts-ignore
    if (all) {
      if (error?.response?.status === 504) {
        setState({ session: null, action: '504', error: null })
      } else {
        setState({
          session: null,
          action: 'error',
          error: error?.response?.data?.message || 'Unbekannter Fehler'
        })
      }
    }
  }, [])

  const onApiRequest = useCallback(
    (config) => {
      if (state.session) {
        //@ts-ignore
        config.headers.sid = state.session.sid
      }
    },
    [state.session]
  )

  const onApiResponse = useCallback((response, action) => {
    if (action === '504') {
      //@ts-ignore
      setState({ action: 'connect' })
    }
  }, [])

  // @ts-ignore
  useImperativeHandle(meRef, () => ({
    action: state.action,
    onApiError,
    onApiRequest,
    onApiResponse
  }))

  useEffect(() => {
    //@ts-ignore
    const id1 = api.interceptors.request.use((config) => {
      // @ts-ignore
      meRef.current.onApiRequest(config)
      return config
    })

    const id2 = api.interceptors.response.use(
      (response: any) => {
        if (meRef.current) {
          // @ts-ignore
          meRef.current.onApiResponse(response, meRef.current.action)
        }
        return response
      },
      (error: any) => {
        if (meRef.current) {
          // @ts-ignore
          meRef.current.onApiError(error, meRef.current.action, false)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      api.interceptors.request.eject(id1)
      api.interceptors.request.eject(id2)
    }
  }, [api.interceptors.request, api.interceptors.response])

  switch (state.action) {
    case 'init':
      setState({ action: 'connect', session: null, error: null })
      return (
        <DefaultFrame>
          <div>Starte...</div>
        </DefaultFrame>
      )

    case 'connect':
      api
        .get('/session/connect')
        .then((response: any) => {
          setState({ session: response.data, action: 'loggedIn', error: null })
          if (connectEvent) {
            notifyObservers({ name: connectEvent, data: response.data })
          }
        })
        .catch((error: any) => {
          if (error?.response?.status === 401 || error?.response?.status === 403) {
            setState({ session: null, action: 'login', error: null })
          } else {
            onApiError(error, state.action, true)
          }
        })
      return (
        <DefaultFrame>
          <InfoPage title="Sitzung herstellen..." />
        </DefaultFrame>
      )

    case 'login':
      return <LoginDialog onLoginOk={setConnect} />

    case 'error':
      return (
        <DefaultFrame>
          <InfoPage
            title="Fehler"
            content={state.error?.split('\n').map((s, idx) => (
              <Typography variant="body1" key={idx}>
                {s}
              </Typography>
            ))}
          />
        </DefaultFrame>
      )

    case '504':
      return (
        <DefaultFrame>
          <InfoPage
            title="Der Server reagiert nicht"
            content="Eine Serveranfrage ist gescheitert. Prüfen Sie durch Aktualisieren, ob der Server wieder reagiert."
          />
        </DefaultFrame>
      )

    case 'glnChanged':
      return (
        <DefaultFrame>
          <InfoPage
            title="Die Gesellschaft wurde gewechselt"
            content="Die Gesellschaft wurde in einer anderen Browser-Ansicht gewechselt!"
            buttons={<Button label="Ansicht aktualisieren" onClick={onReload} />}
          />
        </DefaultFrame>
      )

    case 'sessionChanged':
      return (
        <DefaultFrame>
          <InfoPage
            title="Sitzung wurde aktualisiert"
            content="Die Sitzung wurde in einer anderen Browser-Ansicht verändert!"
            buttons={<Button label="Ansicht aktualisieren" onClick={onReload} />}
          />
        </DefaultFrame>
      )

    case 'needLogin':
      return (
        <DefaultFrame>
          <NeedLoginPage onLogin={onReload} />
        </DefaultFrame>
      )

    case 'outdated':
      return (
        <DefaultFrame>
          <InfoPage
            title="Die Client-Version ist inkompatibel!"
            content="Der Server wurde wahrscheinlich aktualisiert, bitte die Anwendung neu laden"
            buttons={<Button label="Neu Laden" onClick={onReload} />}
          />
        </DefaultFrame>
      )

    case 'loggedIn':
      return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>

    default:
      return (
        <DefaultFrame>
          <InfoPage title="Unerwarteter Fehler" />
        </DefaultFrame>
      )
  }
}
