import { api } from '@one/api'
import { MengeneinheitDisplayTinyJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<MengeneinheitDisplayTinyJson> = {
  api,
  datakey: 'Mengeneinheiten',
  rest: '/artikel/mengeneinheit/alle',
  method: 'GET',
  params: {},
  field: 'mengeneinheiten'
}

export const useMengeneinheitenCache = (flush: boolean = false) => useDataCache(config, flush)
