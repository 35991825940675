/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-one-expression-per-line */
import {Grid} from '@mui/material'
import {red} from '@mui/material/colors'
import {AppPaths} from '@one/AppPaths'
import {api} from '@one/api'
import {useArtikelColumns} from '@one/components/Artikel/ArtikelColumn'
import {ArtikelBetriebstyp, ArtikelCriteriaNummernJson, ArtikelDisplayJson, ArtikelNummernSucheResultJson, ArtikelNummerParams, LieferantDisplayJson} from '@one/typings/apiTypings'
import {useApiCaller} from '@utils/apicaller'
import {useFormState} from '@utils/formstate'
import {Button} from '@utils/ui/Buttons/Button'
import {DataTableAction} from '@utils/ui/DataTable/DataTableBody'
import {DataTableCard} from '@utils/ui/DataTable/DataTableCard'
import {Frame, FrameBody, FrameRow} from '@utils/ui/Frame'
import {TooltipWrapper} from '@utils/ui/TooltipWrapper'
import {TextField} from '@utils/ui/fields/TextField'
import {isStringBlank} from '@utils/utils'
import React, {useMemo, useState} from 'react'

// const transformArrToText = (arr) => {
//   if (arr == null) {
//     return null
//   }
//   return arr.map((a) => a.hageNummer).join(', ')
// }

type FitleredNumbersType = {
  filtered: string[]
  original: string[]
}
const splitString = (
  text: string,
  numode: boolean,
  filterFn: (s: string) => boolean
): FitleredNumbersType => {
  if (text == null || text.length === 0) {
    return { filtered: [], original: [] }
  }
  const original = text
    .split(', ')
    .map((s) => s.trim())
    .filter((s) => s.length > 0)

  const filtered = original
    .filter((s) => !numode || !Number.isNaN(parseInt(s, 10)))
    .filter((s) => s != null)
    .filter((n, i, a) => a.indexOf(n) === i)
    .filter(filterFn)

  return {
    original,
    filtered
  }
}

export type ArtikelSammlerFieldProps = {
  lieferant: LieferantDisplayJson
  betriebsTyp: ArtikelBetriebstyp
  fullWidth: boolean
  label: string
  error: any
  value: any
  name: string
  onChange: any
}
export const ArtikelSammlerField = ({
  fullWidth,
  label,
  error,
  lieferant,
  betriebsTyp,
  value,
  name,
  onChange
}: ArtikelSammlerFieldProps) => {
  const [edit, onValueChange] = useFormState({
    hageNr: '',
    ian: '',
    ean: ''
  })

  const [result, setResult] = useState<ArtikelNummernSucheResultJson>(null)
  const [validationError, setValidationError] = useState(null)
  const [illegalNumbers, setIllegalNumbers] = useState(null)

  const [apiCall] = useApiCaller(api)

  const validateField = (liste: FitleredNumbersType, name: string): any => {
    const diff = (liste.original?.length ?? 0) - (liste.filtered?.length ?? 0)
    return diff > 0 ? `${diff} ungültige ${name} wurden herrausgefiltered` : null
  }

  const extractInvalids = (liste: FitleredNumbersType): string[] => {
    if (liste?.original?.length > 0) {
      return liste.original.filter((s) => liste.filtered?.find((f) => s === f) == null)
    }
    return []
  }

  const onRequestArtikel = () => {
    const hageNrs = splitString(edit?.hageNr, true, (s) => s.length <= 8)
    const ians = splitString(edit?.ian, false, (s) => s.length <= 35)
    const eans = splitString(edit?.ean, true, (s) => s.length === 8 || s.length === 13)

    let validateError = {}
    validateError = { ...validateError, hageNr: validateField(hageNrs, 'Hage-Nr.') }
    validateError = { ...validateError, ian: validateField(ians, 'IAN') }
    validateError = { ...validateError, ean: validateField(eans, 'EAN') }
    setValidationError(validateError)

    let illegalNumbers = {}
    illegalNumbers = { ...illegalNumbers, hageNrs: extractInvalids(hageNrs) }
    illegalNumbers = { ...illegalNumbers, ians: extractInvalids(ians) }
    illegalNumbers = { ...illegalNumbers, eans: extractInvalids(eans) }
    setIllegalNumbers(illegalNumbers)

    const numbers = {
      eans: eans.filtered,
      ians: ians.filtered,
      hageNrs: hageNrs.filtered
    } as ArtikelNummerParams

    if (numbers.eans?.length > 0 || numbers.ians?.length > 0 || numbers.hageNrs?.length > 0) {
      const criteria = {
        lieferantId: lieferant.id,
        betriebsTyp,
        numbers
      } as ArtikelCriteriaNummernJson

      apiCall<ArtikelNummernSucheResultJson>({
        method: 'POST',
        rest: '/artikel/searchByNumbers',
        data: criteria,
        onSuccess: (data) => {
          setResult(data)
          const arrGefunden =
            data?.result.map((r) => ({ id: r.id, hageNummer: r.hageNummer })) || []
          onChange({ name, value: arrGefunden.length === 0 ? null : arrGefunden })
        }
      })
    } else {
      setResult(null)
      onChange({ name, value: null })
    }
  }

  // const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
  //   {
  //     e.stopPropagation()
  //     e.preventDefault()
  //     // @ts-ignore
  //     const name = e.target.name
  //     let value = e.clipboardData.getData('text')
  //     value = value?.replace(/[;:,\n\r\t\s]+/g, ', ') ?? ''
  //     onValueChange({ target: { name, value } })
  //   }
  // }

  const onSuchChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    {
      // @ts-ignore
      const name = e.target.name
      // @ts-ignore
      let value = e.target.value
      value = value?.replace(/[;:,\n\r\t\s]+/g, ', ') ?? ''
      onValueChange({ target: { name, value } })
    }
  }

  const getRowStyle = (row: ArtikelDisplayJson) => {
    return row?.lieferantAusgeschlossen ? { color: 'red', textDecoration: 'line-through' } : {}
  }

  const artikelColumnDecorator = (row: ArtikelDisplayJson, body: React.ReactNode) => {
    return (
      <TooltipWrapper
        arrow
        title={
          row?.lieferantAusgeschlossen && (
            <span>
              Artikel ausgeschlossen
              <br /> (ausgelistet, archiviert oder gelöscht)
            </span>
          )
        }
      >
        {body}
      </TooltipWrapper>
    )
  }

  const columns = useArtikelColumns<ArtikelDisplayJson>({ artikelColumnDecorator })

  const tableActions = [
    {
      icon: 'visibility',
      tooltip: 'Anzeigen',
      getLink: (artikel: ArtikelDisplayJson) => AppPaths.ArtikelFn(artikel.id)
    }
  ] as DataTableAction<ArtikelDisplayJson>[]

  const fehlerFields = useMemo(() => {
    const getNoResultField = (prefix: string, values1: string[], values2: string[]) => {
      let values = []
      if (values1?.length > 0) {
        values = values.concat(values1)
      }
      if (values2?.length > 0) {
        values = values.concat(values2)
      }
      return (
        values?.length > 0 && (
          <Grid item>
            <TextField
              label={`Für den Lieferanten sind Artikel zu folgenden ${prefix}-Nummern nicht bekannt, ungültig, gelöscht, ausgelistet oder archiviert`}
              value={values.join(', ')}
              error
              fullWidth
            />
          </Grid>
        )
      )
    }
    const warning = []
    warning.push(getNoResultField('hage-Nr.', illegalNumbers?.hageNrs, result?.noresult?.hageNrs))
    warning.push(getNoResultField('IAN', illegalNumbers?.ians, result?.noresult?.ians))
    warning.push(getNoResultField('EAN', illegalNumbers?.eans, result?.noresult?.eans))
    return warning.filter(Boolean)
  }, [illegalNumbers, result])

  return (
    <Frame space>
      <FrameRow>
        <Grid item md={12} container spacing={2} paddingBottom={2}>
          <Grid item flexGrow={1}>
            <TextField
              name="hageNr"
              fullWidth={fullWidth}
              style={{ color: value?.length === 0 ? red[200] : null }}
              label={`${label} hage-Nr.`}
              error={error || !!validationError?.hageNr}
              placeholder="hage-Nr. (eine oder mehrere)"
              // maxRows={5}
              // multiline
              value={edit.hageNr}
              onChange={onSuchChange}
              helperText={validationError?.hageNr || error?.hageNr}
              // onPaste={(e) => onPaste(e)}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <TextField
              name="ian"
              fullWidth={fullWidth}
              style={{ color: value?.length === 0 ? red[200] : null }}
              label={`${label} IAN`}
              error={error || !!validationError?.ian}
              placeholder="IAN (eine oder mehrere)"
              // maxRows={5}
              // multiline
              value={edit.ian}
              onChange={onSuchChange}
              helperText={validationError?.ian || error?.ian}
              // onPaste={(e) => onPaste(e)}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <TextField
              name="ean"
              fullWidth={fullWidth}
              style={{ color: value?.length === 0 ? red[200] : null }}
              label={`${label} EAN`}
              error={error || !!validationError?.ean}
              placeholder="EAN (eine oder mehrere)"
              // maxRows={5}
              // multiline
              value={edit.ean}
              onChange={onSuchChange}
              helperText={validationError?.ean || error?.ean}
              // onPaste={(e) => onPaste(e)}
            />
          </Grid>

          <Grid item alignSelf="start" marginTop={1}>
            <Button
              label="Artikel suchen"
              variant="contained"
              onClick={onRequestArtikel}
              disabled={
                isStringBlank(edit.ean) && isStringBlank(edit.hageNr) && isStringBlank(edit.ian)
              }
            />
          </Grid>
        </Grid>

        {fehlerFields.length ? (
          <Grid item container md={12} spacing={2} paddingBottom={2} direction="column">
            {fehlerFields}
          </Grid>
        ) : null}
      </FrameRow>

      {result?.result?.length > 0 ? (
        <FrameBody>
          <div
            // Workaround wg h-scroller...
            style={{ width: '99%', height: '100%' }}
          >
            <DataTableCard
              name="ArtikelSammler"
              title="Gefundene Artikel"
              filterMode="global"
              borderless
              nopadding
              // height="100%"
              columns={columns}
              value={result.result}
              rowStyle={getRowStyle}
              dense
              actions={tableActions}
            />
          </div>
        </FrameBody>
      ) : null}
    </Frame>
  )
}
