import Grid from '@mui/material/Grid2'
import { PimLandField } from '@one/components/common/PimLandField'
import {
  PimArtikelVersionComplianceLandJson,
  PimLandDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ChangeFn, ModelAction, onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RowForm } from '@utils/ui/fields/RowForm'
import { aidOf, resolveObjectField } from '@utils/utils'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelComplianceLaenderProps {
  laender: PimLandDisplayJson[]
  complianceLaender: PimArtikelVersionComplianceLandJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
  readonly?: boolean
}

export const PimArtikelComplianceLaender = ({
  laender,
  complianceLaender,
  onChange,
  dispatch,
  errors,
  readonly
}: PimArtikelComplianceLaenderProps) => {
  return (
    <RowForm
      title="Compliance-Länder"
      name="PimArtikelVersioncomplianceLaenderTabelle"
      rows={complianceLaender}
      onAdd={
        readonly ? null : () => dispatch({ type: EigenlistungsArtikelUseCase.ADDCOMPLIANCELAND })
      }
      addLabel="Land hinzufügen"
      onRemove={
        readonly
          ? null
          : (land) => dispatch({ type: EigenlistungsArtikelUseCase.REMOVECOMPLIANCELAND, land })
      }
      body={(complianceLand, idx) => (
        <PimArtikelComplianceLandMemo
          complianceLand={complianceLand}
          laender={laender}
          onChange={onChangeWrapper(
            onChange,
            `pimArtikel.version.compliance.laender.[__aid=${aidOf(complianceLand)}]`
          )}
          errors={errors}
          errorPath={`pimArtikel.version.compliance.laender.[${idx}]`}
          readonly={readonly}
        />
      )}
    />
  )
}

export const PimArtikelComplianceLaenderMemo = React.memo(PimArtikelComplianceLaender)

type PimArtikelComplianceLandProps = {
  complianceLand: PimArtikelVersionComplianceLandJson
  laender: PimLandDisplayJson[]
  onChange: ChangeFn
  errors: any
  errorPath: string
  readonly?: boolean
}

const PimArtikelComplianceLand = ({
  complianceLand,
  laender,
  onChange,
  errors,
  errorPath,
  readonly
}: PimArtikelComplianceLandProps) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs:12, md:6, lg:3, xl:1 }}>
        <PimLandField
          label="Landname"
          name="pimLand"
          value={complianceLand.pimLand}
          values={laender}
          onChange={onChange}
          error={resolveObjectField(errors, errorPath + '.pimLand')}
          long
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{ xs:12, md:6, lg:3, xl:2 }}>
        <NumberField
          label="Getränke Mehrweg Pfand"
          value={complianceLand.getraenkeMehrwegPfand}
          name="getraenkeMehrwegPfand"
          onChange={onChange}
          error={resolveObjectField(errors, errorPath + '.getraenkeMehrwegPfand')}
          fraction={4}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{ xs:12, md:6, lg:3, xl:2 }}>
        <NumberField
          label="Getränke Einweg Pfand"
          value={complianceLand.getraenkeEinwegPfand}
          name="getraenkeEinwegPfand"
          onChange={onChange}
          error={resolveObjectField(errors, errorPath + '.getraenkeEinwegPfand')}
          fraction={4}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{ xs:12, md:6, lg:3, xl:2 }}>
        <Checkbox
          label="Kaminöfen OE Norm"
          checked={complianceLand.relevantKaminoefenOeNorm}
          name="relevantKaminoefenOeNorm"
          onChange={onChange}
          size="small"
          disabled={readonly}
        />
      </Grid>
    </Grid>
  )
}

export const PimArtikelComplianceLandMemo = React.memo(PimArtikelComplianceLand)
