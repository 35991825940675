import { MapperEinstellungJson } from '@one/typings/apiTypings'
import { SelectField, SelectFieldProps } from '@utils/ui/fields/SelectField'
import { ifString } from '@utils/utils'

export interface SyncEinstellungFieldProps extends SelectFieldProps {
  errors?: any
  emptyText?: string
  optionDisabled?: any
  size?: any
}

export const SyncEinstellungField = ({
  label,
  value,
  name,
  onChange,
  options = null,
  errors = null,
  error = null,
  disabled = false,
  required = false,
  fullWidth = false,
  emptyText = null,
  optionDisabled = null,
  size = null
}: SyncEinstellungFieldProps) => {
  const renderItem = (item: MapperEinstellungJson) => {
    return (
      <>
        {item.name}
        {item.vorgabe && <i style={{ paddingLeft: '10px', fontSize: '80%' }}> (vordefiniert)</i>}
        {item.standardEH && <i style={{ paddingLeft: '10px', fontSize: '80%' }}> (Standard EH)</i>}
        {item.standardFH && <i style={{ paddingLeft: '10px', fontSize: '80%' }}> (Standard FH)</i>}
      </>
    )
  }
  return (
    <SelectField
      error={!!error || !!(errors && name && errors[name])}
      helperText={ifString(error || errors?.[name])}
      label={label}
      fullWidth={fullWidth}
      required={required}
      value={value}
      onChange={onChange}
      displayEmpty
      disabled={disabled}
      optionText="name"
      renderItem={renderItem}
      renderSelected={renderItem}
      options={options}
      name={name}
      emptyText={emptyText}
      optionDisabled={optionDisabled}
      size={size}
    />
  )
}
