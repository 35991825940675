import { Description } from '@mui/icons-material'
import { SeverityCell } from '@one/components/common/SeverityCell'
import { HkmEnum } from '@one/enums/HkmEnum'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { singleFromSet } from '@utils/utils'
import { useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ProtokollDetailsSelection } from './ProtokollDetailsSelection'

export interface ProtokollDetailstTableProps {
  values?: any[]
  loading?: boolean
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  masterdetail: {
    display: 'flex',
    height: '100%',
    width: '100%',
    '&>div:nth-of-type(2)': {
      marginLeft: '8px',
      minWidth: '40em',
      maxWidth: '30%'
    },
    '&>div:first-of-type': {
      flexGrow: '1'
    }
  }
}))

export const ProtokollDetailstTable = ({
  values,
  loading = false
}: ProtokollDetailstTableProps) => {
  const { classes } = useStyles()
  const { et } = useEnums()
  const [selected, setSelected] = useState<Set<any>>(new Set())

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'timestamp',
        header: 'Zeitstempel',
        sortable: true,
        width: '2em',
        wrapMode: 'nowrap',
        type: 'datetimesecond'
      },
      {
        field: 'severity',
        header: 'Schwere',
        width: '2em',
        valueGetter: (row) => et(HkmEnum.MessageSeverity, row.severity),
        body: (row) => <SeverityCell value={row.severity} />,
        sortable: true
      },
      {
        field: 'reference',
        header: 'Referenz',
        width: '2em',
        sortable: true
      },
      {
        field: 'fragment',
        header: 'Fragment',
        headerTip: 'Datenfragement zur Ansicht enthalten',
        sortable: true,
        width: '2em',
        align: 'center',
        body: (row: any) => (row.fragment ? <Description fontSize="small" /> : null)
      },
      {
        field: 'message',
        header: 'Nachricht',
        sortable: true
      },
      {
        field: 'error.message',
        header: 'Fehlermeldung',
        sortable: true
      }
    ],
    [et]
  )

  const sel = singleFromSet(selected)

  return (
    <div className={classes.masterdetail}>
      {values && values.length > 0 ? (
        <DataTableCard
          name="ProtokollDetailsTable"
          filterMode="global"
          title="Meldungen"
          columns={columns}
          value={values}
          loading={loading}
          selectMode="single"
          selectCol={false}
          onSelect={setSelected}
          wrapMode="normal"
          dense
          paging
          localStateName="ProtokollDetailstTable"
        />
      ) : null}
      {sel && sel.fragment ? <ProtokollDetailsSelection fragment={sel.fragment} /> : null}
    </div>
  )
}
