import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export interface LinkProps extends MuiLinkProps {
  to: string
}

export const Link = ({ to, children }: LinkProps) => (
  <MuiLink component={RouterLink} to={to} position="relative">
    {children}
  </MuiLink>
)
