import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { AllianzDatenBereitstellungJson } from '@one/typings/apiTypings'
import { ActionItem } from '@utils/ui/Action'
import { CardEx } from '@utils/ui/CardEx'

export interface AllianzDatenBereitstellungParamsProps {
  onRefresh: () => void
  data?: AllianzDatenBereitstellungJson
}

export const AllianzDatenBereitstellungParams = ({
  onRefresh,
  data
}: AllianzDatenBereitstellungParamsProps) => {
  const actions = [
    {
      role: UserRoles.STAMMDATEN_EDITOR,
      tooltip: 'Ausführungsprotokoll anzeigen',
      navlink: AppPaths.AllianzDatenBereitstellungUebersichtView,
      text: 'Protokoll',
      variant: 'outlined'
    }
  ] as ActionItem[]

  return (
    <CardEx
      title="Allianzdaten für Mitglieder bereitstellen"
      backLink
      collapsiable
      topActions={actions}
      // bottomActions={[
      //   <Button key="refresh" color="primary" label="Aktualisieren" onClick={onRefresh} />
      // ]}
    >
      <Grid container spacing={3}>
        <Grid item flexGrow={1}>
          {/* <StaticField fullWidth label="Exportdetails" value={data.bereistellung.status ? "" : ""} /> */}
        </Grid>
      </Grid>
    </CardEx>
  )
}
