import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '2px',
    gap: 8
  },
  bottom: {
    alignItems: 'end'
  }
}))

export const ButtonRow = ({
  children,
  bottom
}: {
  children: React.ReactNode
  bottom?: boolean
}) => {
  const { classes } = useStyles()
  return (
    children && <div className={clsx(classes.buttons, bottom && classes.bottom)}>{children}</div>
  )
}
