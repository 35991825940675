import { Grid } from '@mui/material'
import { useState } from 'react'
import { AVDatabase } from './AVDatabase'
import { AVHelp } from './AVHelp'
import { AVImportScheduler } from './AVImportScheduler'
import { AVPIMImportScheduler } from './AVPIMImportScheduler'
import { AVDataLakeImportScheduler } from './AVDataLakeImportScheduler'
import { AVStatistics } from './AVStatstics'

type AVItemProps = {
  name: string
  Component: React.FC<{ zoomed: boolean; setZoomed: () => void }>
  fullWidth?: boolean
}

export const AdminView = () => {
  const [zoomedKey, setZoomedKey] = useState<string | null>(null)

  const AVItem = ({ name, Component, fullWidth }: AVItemProps) => {
    const zoomed = name === zoomedKey
    const notme = zoomedKey && name !== zoomedKey
    return (
      <Grid
        key={name}
        item
        sm={12}
        md={fullWidth ? 12 : 6}
        lg={fullWidth ? 12 : 4}
        style={{
          display: notme ? 'none' : undefined,
          width: zoomed ? '100%' : undefined,
          maxWidth: zoomed ? '100%' : undefined,
          //   height: zoomed ? '100%' : undefined,
          flexGrow: zoomed ? 1 : undefined
        }}
      >
        <Component zoomed={zoomed} setZoomed={() => setZoomedKey(zoomed ? null : name)} />
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={zoomedKey == null ? 1 : 0}
      height={zoomedKey == null ? undefined : '100%'}
    >
      <AVItem name="statistics" Component={AVStatistics} fullWidth />
      <AVItem name="help" Component={AVHelp} />
      <AVItem name="import" Component={AVImportScheduler} />
      <AVItem name="importdtla" Component={AVDataLakeImportScheduler} />
      <AVItem name="pim" Component={AVPIMImportScheduler} />
      <AVItem name="db" Component={AVDatabase} />
    </Grid>
  )
}
