import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((_theme) => ({
  container: {
    height: '100%',
    width: '100%',
    '&>div': {
      position: 'relative',
      height: '100%',
      width: '100%',
      overflow: 'auto',
      '&>div': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%'
      }
    }
  }
}))

export interface MainContainerProps {
  topbaroffset?: any
  padding?: string
  children: React.ReactNode
}

export const MainContainer = ({ topbaroffset, padding, children }: MainContainerProps) => {
  const { classes } = useStyles()
  const style1 = useMemo(() => {
    return {
      paddingTop: topbaroffset
    }
  }, [topbaroffset])

  const style2 = useMemo(() => ({ padding }), [padding])

  return (
    <main className={classes.container} style={style1}>
      <div>
        <div style={style2}>{children}</div>
      </div>
    </main>
  )
}
