import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { InfoPage } from './InfoPage'

export interface NeedLoginPageProps {
  onLogin: ButtonProps['onClick']
}

export const NeedLoginPage = ({ onLogin }: NeedLoginPageProps) => {
  return (
    <InfoPage
      title="Die Anmeldung ist nicht (mehr) gültig"
      buttons={
        <Button variant="contained" size="medium" color="primary" onClick={onLogin}>
          Zur Anmeldung
        </Button>
      }
    />
  )
}
