import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  EkPreisListeImportDefinitionMappingEntryJson,
  EkPreisListeImportDefinitionMappingSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { useCallback, useMemo } from 'react'
import { LieferantenMappingDialog } from './LieferantenMappingDialog'

export interface LieferantenMappingTabelleProps {
  params: EkPreisListeImportDefinitionMappingSearcherCriteriaJson
  mappings: EkPreisListeImportDefinitionMappingEntryJson[]
  onComplete: () => void
}

export const LieferantenMappingTabelle = ({
  params,
  mappings,
  onComplete
}: LieferantenMappingTabelleProps) => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const { et } = useEnums()
  const [apiCall, apiBusy] = useApiCaller(api)
  const { askToDelete } = useMessageDialog()

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'defExtern',
        header: 'Lieferant-Bezeichnung'
      },
      {
        field: 'defIntern',
        header: 'Bezeichnung'
      }
    ],
    []
  )

  const onNewMapping = useCallback(() => {
    showDlg((visible, onClose) => (
      <LieferantenMappingDialog
        open={visible}
        onClose={onClose(onComplete)}
        type={params.mappingType}
        lieferantId={params.suLieferantId}
        mappingId={null}
      />
    ))
  }, [onComplete, params.mappingType, params.suLieferantId, showDlg])

  const editMapping = useCallback(
    (mapping) => () => {
      showDlg((visible, onClose) => (
        <LieferantenMappingDialog
          open={visible}
          onClose={onClose(onComplete)}
          type={params.mappingType}
          lieferantId={params.suLieferantId}
          mappingId={mapping.id}
        />
      ))
    },
    [onComplete, params.mappingType, params.suLieferantId, showDlg]
  )

  const deleteMapping = useCallback(
    (id) => {
      apiCall({
        method: 'POST',
        exclusive: ApiExclusive.BLOCK,
        rest: '/ekpreislisteimport/definition/mapping/delete',
        onErrorMsg: 'Mapping konnten nicht gelöscht werden.',
        params: {
          id
        },
        onSuccess: () => onComplete()
      })
    },
    [apiCall, onComplete]
  )

  const removeMapping = useCallback(
    (mapping) => () => {
      askToDelete({
        title: 'Soll das Mapping wirklich gelöscht werden?',
        onConfirm: () => {
          deleteMapping(mapping.id)
        }
      })
    },
    [askToDelete, deleteMapping]
  )

  const actions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        tooltip: 'Archivierter Artikel',
        handler: editMapping
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: removeMapping
      }
    ],
    [removeMapping, editMapping]
  )

  const topActions = useMemo(
    () => [
      {
        icon: 'add',
        text: 'Hinzufügen',
        tooltip: 'Neues Mapping hinzufügen',
        onClick: onNewMapping
      }
    ],
    [onNewMapping]
  )

  return (
    <>
      <DataTableCard
        title={`${et(
          HkmEnum.EkPreisListeImportDefinitionMappingType,
          params.mappingType
        )} Mappings`}
        name="LieferantenMengeneinheitenMapping"
        columns={columns}
        actions={actions}
        topActions={topActions}
        value={mappings}
        dense
        emptyMessage="Keine Mappings vorhanden"
      />
      <DlgAnker />
    </>
  )
}
