import { api } from '@one/api'
import { NeonKontextDisplayJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<NeonKontextDisplayJson> = {
  api,
  // refreshEvents: EventNames.ARSST_LOAD,
  datakey: 'neonkontexte',
  rest: 'neonkontexte',
  method: 'GET',
  idField: 'kontext',
  field: 'kontexte'
} as DataCacheConfig

export const useNeonKontextCache = () => useDataCache(config)
