import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  AllianzDatenBereitstellungCriteriaJson,
  AllianzDatenBereitstellungDisplayJson
} from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { AllianzDatenBereitstellungUebersichtParams } from './AllianzDatenBereitstellungUebersichtParams'
import { AllianzDatenBereitstellungUebersichtTable } from './AllianzDatenBereitstellungUebersichtTable'

export const AllianzDatenBereitstellungUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    AllianzDatenBereitstellungCriteriaJson,
    AllianzDatenBereitstellungDisplayJson
  >({
    api,
    url: '/allianz/datenBereitstellen',
    initialParams: {},
    doOpen: 'search'
  })

  useObserver(EventNames.ALLIANZBEREITSTELLUNG, () => search())

  return (
    <Frame space>
      <FrameRow>
        <AllianzDatenBereitstellungUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <AllianzDatenBereitstellungUebersichtTable result={result} />
      </FrameBody>
    </Frame>
  )
}
