import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { ImportErrorErpVkPreisJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { useContext, useMemo } from 'react'
import { ImportFehlerView } from './ImportFehlerView'

export const ImportVkPreisFehlerUebersichtView = () => {
  const { et } = useEnums()
  const { isPIMModel } = useContext(AppContext)

  const columns = useMemo(
    () =>
      [
        {
          field: 'gueltigVon',
          header: 'Gültig von',
          type: 'date'
        },
        {
          field: 'gueltigBis',
          header: 'Gültig bis',
          type: 'date'
        },
        {
          field: 'artikel.hageNummer',
          header: 'hage-Nr.'
        },
        {
          off: isPIMModel,
          field: 'artikel.btNummer',
          header: 'Artikel-Nr.'
        },
        {
          field: 'artikel.ean',
          header: 'EAN'
        },
        {
          field: 'artikel.aboStatus',
          header: 'Abostatus',
          align: 'center',
          body: (row) =>
            row.artikel && <ArtikelAbonniertStatusSymbol value={row.artikel.aboStatus} et={et} />,
          valueGetter: (row) =>
            row.artikel && getArtikelAbonniertStatusText(et, row.artikel.aboStatus)
        },
        {
          field: 'artikel.bez1',
          header: 'Bez1'
        },
        {
          field: 'artikel.bez2',
          header: 'Bez2'
        },
        {
          field: 'artikel.bez3',
          header: 'Bez3'
        },
        {
          field: 'artikel.bez4',
          header: 'Bez4'
        },
        {
          field: 'lieferant.name1',
          header: 'Name 1'
        },
        {
          field: 'lieferant.name2',
          header: 'Name 2'
        },
        {
          field: 'lieferant.nummer',
          header: 'Nummer'
        },
        {
          field: 'lieferant.iln',
          header: 'ILN'
        },
        {
          field: 'standort.nr',
          header: 'Standort-Nr.'
        }
      ] as Column<ImportErrorErpVkPreisJson>[],
    [et]
  )
  return (
    <ImportFehlerView
      rest="import/vkpreis"
      title="VK-Preis Importfehler"
      columns={columns}
      name="VKPreisImportFehlerView"
    />
  )
}
