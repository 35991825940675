import { HkmEnum } from '@one/enums/HkmEnum'
import { AllianzDatenBereitstellungDisplayJson, HKMAuditJson } from '@one/typings/apiTypings'
import { formatDuration } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { nameOf, pathOf } from '@utils/utils'
import { useMemo } from 'react'

export interface AllianzDatenBereitstellungUebersichtTableProps {
  result: SearcherResultType<AllianzDatenBereitstellungDisplayJson>
}

export const AllianzDatenBereitstellungUebersichtTable = ({
  result
}: AllianzDatenBereitstellungUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo(
    () =>
      [
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('start'),
          header: 'Gestartet',
          sortable: true,
          type: 'datetime'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('end'),
          header: 'Beendet',
          sortable: true,
          type: 'datetime',
          hideIfEmpty: true
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('status'),
          header: 'Status',
          valueGetter: (row) => et(HkmEnum.AsyncTaskStatus, row.status)
        },
        {
          field: 'dauer',
          header: 'Dauer',
          valueGetter: (row) => {
            if (!row.start || !row.end) {
              return null
            }
            return formatDuration(new Date(Date.parse(row.start)), new Date(Date.parse(row.end)))
          }
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('neuLieferantenCount'),
          header: 'Lieferanten neu',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('aenLieferantenCount'),
          header: 'Lieferanten geändert',
          headerTip: 'Stammdatenänderungen',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('qwLieferantenCount'),
          header: 'Lieferanten QW',
          headerTip: 'Lieferanten Quellenwechsel (Listung/Allianz)',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('delLieferantenCount'),
          header: 'Lieferanten entfernt',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('neuArtikelCount'),
          header: 'Artikel neu',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('aenArtikelCount'),
          header: 'Artikel geändert',
          headerTip: 'Stammdatenänderungen',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('qwArtikelCount'),
          header: 'Artikel QW',
          headerTip: 'Artikel Quellenwechsel (Listung/Allianz)',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('delArtikelCount'),
          header: 'Artikel entfernt',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('neuEkKonditionenRabattgruppeCount'),
          header: 'EK-Rabattgruppen neu',
          type: 'number'
        },
        {
          field: nameOf<AllianzDatenBereitstellungDisplayJson>('neuEkKonditionenListeCount'),
          header: 'EK-Preise neu',
          type: 'number'
        },
        {
          field: pathOf<AllianzDatenBereitstellungDisplayJson, HKMAuditJson>('audit', 'createdBy'),
          header: 'Auslöser'
        },
        {
          field: pathOf<AllianzDatenBereitstellungDisplayJson, HKMAuditJson>('audit', 'createdOn'),
          header: 'Ausgelöst am',
          type: 'datetime'
        }
      ] as Column<AllianzDatenBereitstellungDisplayJson>[],
    [et]
  )

  return (
    <DataTableCard
      name="AllianzDatenBereitstellungUebersicht"
      title="Protokolle"
      columns={columns}
      filterMode="both"
      dense
      localStateName="AllianzDatenBereitstellungUebersicht"
      result={result}
      rowFiller="100%"
      paging
    />
  )
}
