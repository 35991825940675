import { api } from '@one/api'
import { EventNames } from '@one/EventNames'
import { LabelJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<LabelJson> = {
  api,
  refreshEvents: EventNames.ARTIKELLABEL,
  datakey: 'ArtikelLabel',
  rest: `/artikel/labelgruppe/edit`,
  method: 'GET',
  params: {},
  idField: 'id',
  field: 'data.elemente'
}

export const useArtikelLabelCache = (flush: boolean = false) => useDataCache(config, flush)
