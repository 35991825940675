import { useCallback } from 'react'
import XLSX from 'xlsx'
import { useSnackbarEx } from './snackbarex'

export type ExportExcelProps = {
  data: any[][]
  title?: string
  subject?: string
  author?: string
  createdDate?: Date
  sheetName?: string
  fileName?: string
}

export const exportExcel = ({
  data,
  title,
  subject,
  author,
  createdDate,
  sheetName,
  fileName
}: ExportExcelProps) => {
  const wb = XLSX.utils.book_new()
  wb.Props = {
    Title: title,
    Subject: subject,
    Author: author,
    CreatedDate: createdDate || new Date()
  }
  const rsn = sheetName || 'export'
  wb.SheetNames.push(rsn)
  const ws = XLSX.utils.aoa_to_sheet(data)
  wb.Sheets[rsn] = ws
  XLSX.writeFile(wb, `${fileName || 'export'}.xlsx`, { type: 'binary' })
}

export const useExcelExporter = () => {
  const { enqueMsg } = useSnackbarEx()
  const exporter = useCallback(
    (expFn: () => ExportExcelProps) => {
      enqueMsg('Excel Export gestartet...', 'info')
      setTimeout(() => {
        try {
          exportExcel(expFn())
          enqueMsg('Excel Export erfolgreich', 'success')
        } catch (error) {
          console.error('excel export failed', error)
          enqueMsg('Excel Export gescheitert', 'error')
        }
      }, 100)
    },
    [enqueMsg]
  )
  return exporter
}
