/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from 'tss-react/mui'
import { Scrollbars } from './scrollbars'

const useStyles = makeStyles()({
  trackHorizontal: {
    position: 'absolute',
    height: '6px',
    right: 2,
    borderRadius: 3,
    bottom: 2,
    left: 2,
    zIndex: 1000,
    transition: 'height 0.8s',
    transitionTimingFunction: 'ease-out',
    '&:hover, &:active': {
      height: '12px !important',
      borderRadius: 6,
      transitionTimingFunction: 'ease-in',
      transition: '0.1s'
    }
  },
  trackVertical: {
    position: 'absolute',
    width: '6px',
    right: 2,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    zIndex: 1000,
    transition: 'width 0.8s',
    transitionTimingFunction: 'ease-out',
    '&:hover, &:active': {
      width: '12px !important',
      borderRadius: 6,
      transitionTimingFunction: 'ease-in',
      transition: '0.1s'
    }
  }
})

export type ScrollPanelProps = {
  scrollRef?: any
  autoHeight?: boolean
  autoHeightMax?: number | string
  children: any
  height?: any
  style?: any
  onScroll?: React.UIEventHandler<HTMLDivElement>
}

export const ScrollPanel = ({
  scrollRef,
  autoHeight,
  autoHeightMax,
  height,
  style,
  onScroll,
  children
}: ScrollPanelProps) => {
  const { classes } = useStyles()
  return (
    <Scrollbars
      ref={scrollRef}
      height={height}
      style={style}
      autoHeightMax={autoHeightMax}
      autoHeight={autoHeight}
      renderTrackVertical={(props) => <div {...props} className={classes.trackVertical} />}
      renderTrackHorizontal={(props) => <div {...props} className={classes.trackHorizontal} />}
      onScroll={onScroll}
      hideTracksWhenNotNeeded
    >
      {children}
    </Scrollbars>
  )
}
