import { DomainOutlined, StoreOutlined } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { AllianzAnlageDialog } from '@one/components/admin/Allianz/AllianzAnlageDialog'
import { GesellschafterBetriebstypenDialog } from '@one/components/admin/Gesellschafter/Betriebstypen/GesellschafterBetriebstypenDialog'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  GesellschafterDisplayJson,
  GesellschafterTyp,
  WarengruppeDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Permission } from '@utils/ui/Permission'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { Medal } from '@utils/ui/fields/Medal'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export interface WarengruppenUebersichtTableProps {
  result: SearcherResultType<GesellschafterDisplayJson>
  reload: () => void
}

export const WarengruppenUebersichtTable = ({
  result,
  reload
}: WarengruppenUebersichtTableProps) => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const { et } = useEnums()
  const navigate = useNavigate()

  const columns = useMemo<Column<WarengruppeDisplayJson>[]>(
    () => [
      {
        header: 'Nummer',
        field: 'nummer',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'Name',
        field: 'name',
        sortable: true
      },
      {
        header: 'Gelöscht',
        field: 'deleted',
        type: 'boolean',
        sortable: true,
        hideIfEmpty: true
      },
      {
        header: 'Typ',
        field: 'typ',
        width: '1em',
        sortable: true,
        valueGetter: (row) => (row.zeId ? 'Listung' : 'ERP')
      }
    ],
    []
  )

  const topActions = useMemo<Action[]>(
    () => [
      // {
      //   role: UserRoles.ADMIN,
      //   tooltip: 'Gesellschafter anlegen',
      //   icon: 'add',
      //   navlink: AppPaths.OrganisationViewFn('neu')
      // }
    ],
    []
  )

  const tableActions = useMemo(
    (): DataTableAction<GesellschafterDisplayJson>[] => [
      // {
      //   icon: 'edit',
      //   tooltip: 'Öffnen',
      //   getLink: (data: GesellschafterDisplayJson) =>
      //     data.typ === GesellschafterTyp.ORGANISATION
      //       ? AppPaths.OrganisationViewFn(data.id)
      //       : AppPaths.FirmaViewFn(data.id),
      //   role: UserRoles.ADMIN
      // },
    ],
    []
  )
  return (
    <>
      <DataTableCard
        title="Suchergebnis"
        name="GesellschafterTabelle"
        columns={columns}
        topActions={topActions}
        actions={tableActions}
        dense
        selectMode="none"
        rowFiller
        result={result}
        initialOrderBy={['nummer', 'typ']}
      />
      <DlgAnker />
    </>
  )
}
