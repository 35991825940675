import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  EkPreisListImportAssistentNextJson,
  EkPreisListImportStepId,
  EkPreisListeImportBearbeitenJson,
  EkPreisListeImportJson,
  EkPreisListeImportSaveJson,
  EkPreisListeImportStatus
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { UploadButton } from '@utils/ui/Buttons/UploadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameRow } from '@utils/ui/Frame'
import { HelpContext } from '@utils/ui/help/HelpWrapper'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { EkPreisImportPflegeKopf } from './EkPreisImportPflegeKopf'
import { EkPreisImportPflegeStep0Error } from './EkPreisImportPflegeStep0Error'
import { EkPreisImportPflegeStep2Validate } from './EkPreisImportPflegeStep2Validate'
import { EkPreisImportPflegeStep3NichtGetroffeneArtikel } from './EkPreisImportPflegeStep3NichtGetroffeneArtikel'
import { EkPreisImportPflegeStep4IANDuplikate } from './EkPreisImportPflegeStep4IANDuplikate'
import { EkPreisImportPflegeStep5MePeUnterschiede } from './EkPreisImportPflegeStep5MePeUnterschiede'
import { EkPreisImportPflegeStep6EkSimulation } from './EkPreisImportPflegeStep6EkSimulation'
import { EkPreisImportPflegeStep7Finish } from './EkPreisImportPflegeStep7Finish'
import { EkPreisImportStepper } from './fields/EkPreisImportStepper'
import { EkPreisImportUsecase, EkPreisListeImportEdit } from './model/EkPreisImportUsecase'

export const EkPreisImportPflegeView = () => {
  const { id } = useContext(RouteContext) as any
  const navigate = useNavigate()
  const { setHelpChapter } = useContext(HelpContext)

  const { et } = useEnums()

  const reloadHook = useRef<() => void>()
  reloadHook.current = null

  const {
    model,
    isNew,
    isChanged,
    save,
    reload,
    post,
    updModel,
    uiLock,
    remove,
    onValueChange,
    dispatch
  } = useModelMgr<
    EkPreisListeImportJson,
    EkPreisListeImportEdit,
    EkPreisListeImportBearbeitenJson,
    EkPreisListeImportSaveJson
  >({
    id,
    api,
    title: 'EK-Preis',
    rest: 'ekpreislisteimport',
    unwrapField: 'data',
    reducer: EkPreisImportUsecase.reducer,
    saveMutator: EkPreisImportUsecase.saveMutator,
    editMutator: EkPreisImportUsecase.editMutator,
    eventName: EventNames.EKPREISIMPORT
  })

  const onReload = useCallback(() => {
    reload()
    if (reloadHook.current) {
      reloadHook.current()
    }
  }, [reload])

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          navigate(AppPaths.EkPreisImportUbersicht)
        }
      }),
    [navigate, remove]
  )

  const handleValidate = useCallback(() => {
    post({
      srv: 'validate',
      params: {
        id
      },
      onPost: () => {
        reload()
        return false
      }
    })
  }, [id, post, reload])

  const handleNext = useCallback(() => {
    if (model.aktStep == null && isChanged) {
      save()
      return
    }
    const step = {
      uploadId: model.id,
      step: EkPreisImportUsecase.saveStepMutator(model)
    } as EkPreisListImportAssistentNextJson
    post({
      srv: 'assistentNext',
      data: step,
      onPost: () => {
        return false
      }
    })
  }, [isChanged, model, post, save])

  setHelpChapter(model.aktStep?.stepId, model.aktStep?.stepName)

  const stepView = useMemo(() => {
    const stepKey =
      (model?.status === EkPreisListeImportStatus.FEHLER && 'ERROR') ||
      (model?.status === EkPreisListeImportStatus.NEU && EkPreisListImportStepId.STEP2FORMAT) ||
      (model?.status === EkPreisListeImportStatus.INVALID && EkPreisListImportStepId.STEP2FORMAT) ||
      model?.aktStepId ||
      EkPreisListImportStepId.STEP2FORMAT

    switch (stepKey) {
      case 'ERROR':
        return (
          <EkPreisImportPflegeStep0Error
            status={model.status}
            globaleFehler={model.globaleFehler}
          />
        )

      case EkPreisListImportStepId.STEP1PARS:
      case EkPreisListImportStepId.STEP2FORMAT:
        return (
          <EkPreisImportPflegeStep2Validate
            status={model.status}
            lieferant={model.lieferant}
            updModel={updModel}
            fehlerZeilen={model.fehlerZeilen}
            anzahlFehler={model.anzahlFehler}
            anzahlZeilen={model.anzahlZeilen}
            globaleFehler={model.globaleFehler}
          />
        )

      case EkPreisListImportStepId.STEP3NTARTIKEL:
        return (
          <EkPreisImportPflegeStep3NichtGetroffeneArtikel
            dispatch={dispatch}
            nichtGetroffenenArtikel={model.aktStep.nichtGetroffenenArtikel}
            nichtGetroffenenArtikelChanges={model.nichtGetroffenenArtikelChanges}
            allePreisEbenen={model.aktStep.allePreisEbenen}
            allLabels={model.aktStep.allLabels}
            stepLabelIds={model.aktStep.stepLabelIds}
          />
        )

      case EkPreisListImportStepId.STEP4IAN:
        return (
          <EkPreisImportPflegeStep4IANDuplikate
            dispatch={dispatch}
            ianDuplikate={model.ianDuplikate}
            ianDuplikateChanges={model.ianDuplikateChanges}
          />
        )

      case EkPreisListImportStepId.STEP5EKMEDIFF:
        return (
          <EkPreisImportPflegeStep5MePeUnterschiede
            dispatch={dispatch}
            peMeUnterschiede={model.aktStep.peMeUnterschiede}
            peMeUnterschiedeChanges={model.peMeUnterschiedeChanges}
          />
        )

      case EkPreisListImportStepId.STEP6EKSIM:
        return (
          <EkPreisImportPflegeStep6EkSimulation
            preisliste={model.preisliste}
            reloadHook={reloadHook}
          />
        )

      case EkPreisListImportStepId.STEP7STATISTIKENDE:
        return <EkPreisImportPflegeStep7Finish model={model} onValueChange={onValueChange} />
    }
  }, [dispatch, model, onValueChange, updModel])

  const getWeiterLabel = () => {
    if (model.aktStepId === EkPreisListImportStepId.STEP7STATISTIKENDE) {
      return 'Preisliste erzeugen'
    } else if (model.aktStep == null && isChanged) {
      return 'Speichern und Validieren'
    } else if (model.aktStep && isChanged) {
      return 'Speichern und weiter'
    }
    return 'Weiter'
  }

  const onGotoStep = (stepId: EkPreisListImportStepId) => {
    post({
      srv: 'assistentGotoStep',
      params: {
        id: model.id,
        stepId
      },
      onPost: () => {
        return false
      }
    })
  }

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <EkPreisImportPflegeKopf model={model} onChange={onValueChange}></EkPreisImportPflegeKopf>
        </FrameRow>
        <FrameRow>
          {/* <FormField label="Import Assistent" fullWidth > */}
          <CardEx
            title="Fortschritt"
            titleSize="1.2em"
            contentStyle={{ paddingTop: 0, marginTop: -12, paddingBottom: 8 }}
          >
            <EkPreisImportStepper
              allSteps={model.allSteps}
              aktStepId={model.aktStepId}
              status={model.status}
              onClick={onGotoStep}
            />
          </CardEx>
          {/* </FormField> */}
        </FrameRow>
        {stepView}
        <FrameRow>
          <ButtonRow>
            <DeleteButton
              label="Löschen"
              onDelete={onDeleteClick}
              isNew={false}
              deleteMsg="Upload wirklich löschen"
              tooltip="Upload löschen"
            />
            {/* <SaveButton
              isNew={isNew}
              isChanged={isChanged}
              onSave={save}
              visible={model.aktStep == null}
            /> */}
            <UploadButton
              api={api}
              label="Datei erneut hochladen"
              variant="contained"
              color="warning"
              path={`ekpreislisteimport/assistentUpload?id=${id}`}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              visible={model?.status !== EkPreisListeImportStatus.ERLEDIGT}
              onComplete={reload}
              tooltip="Datei neu hochladen, der aktuelle Stand wird verworfen!"
            />
            <Button
              label="Validieren"
              variant="contained"
              onClick={handleValidate}
              visible={
                model.status === EkPreisListeImportStatus.INVALID || model.aktStepId === null
              }
              disabled={isChanged}
            />
            <Button
              label={getWeiterLabel()}
              variant="contained"
              onClick={handleNext}
              visible={
                model.status !== EkPreisListeImportStatus.FEHLER &&
                model.status !== EkPreisListeImportStatus.ERLEDIGT
              }
              disabled={
                model.status === EkPreisListeImportStatus.INVALID &&
                model.aktStepId == null &&
                !isChanged
              }
            />
            <ReloadButton isChanged={isChanged} isNew={isNew} onClick={onReload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
