import { LieferantAbonniertStatus } from '@one/typings/apiTypings'
import { EnumTextGetter } from '@utils/enums'
import { Medal } from '@utils/ui/fields/Medal'
import { memo } from 'react'
import {
  getLieferantAbonniertStatusColor,
  getLieferantAbonniertStatusIcon,
  getLieferantAbonniertStatusText
} from './LieferantAbonniertStatusSymbol'

export interface LieferantAbonniertStatusMedalProps {
  value: LieferantAbonniertStatus
  et: EnumTextGetter
  darkMode: boolean
  disabled?: boolean
  fullWidth?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const LieferantAbonniertStatusMedal = memo(
  ({ value, et, disabled, darkMode, fullWidth, onClick }: LieferantAbonniertStatusMedalProps) => {
    const backgroundColor = getLieferantAbonniertStatusColor(value, darkMode)

    return (
      <Medal
        variant="square"
        text={getLieferantAbonniertStatusText(value, et)}
        icon={getLieferantAbonniertStatusIcon(value)}
        backgroundColor={backgroundColor}
        onClick={!onClick || disabled ? null : onClick}
        placement="right-end"
        fullWidth={fullWidth}
      />
    )
  }
)

LieferantAbonniertStatusMedal.displayName = 'LieferantAbonniertStatusMedal'
