import { useLocalState } from '@utils/localState'
import { useGlobalDialogAnker } from '@utils/ui/DialogAnker'
import { calcHash, safeArray } from '@utils/utils'
import { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ActionsPos, Column, useDataTableGlobals } from './DataTable'
import { DataTableConfigDialog } from './DataTableConfigDialog'
import { buildColumnKey } from './DataTableUtils'

const useStyles = makeStyles()((theme: any) => ({
  sticky: {
    position: 'sticky',
    top: 0,
    right: 0
  }
}))

export type DataTableConfig = {
  columnOrder?: string[] | null
  stickyColumsLeft?: number | null
  striped?: boolean | null
  borders?: boolean | null
  actionsPos?: ActionsPos
  hash?: number | null
}

export type DataTableConfigProps<T> = {
  columns: Column<T>[]
  stickyColumsLeft?: number
}

const validate = (props: any, hash: number, init: any): any => {
  if (props.hash === hash) {
    return {
      columnOrder: safeArray(props.columnOrder),
      stickyColumsLeft: props.stickyColumsLeft == null ? null : Number(props.stickyColumsLeft),
      striped: props.striped == null ? null : Boolean(props.striped),
      borders: props.borders == null ? null : Boolean(props.borders),
      hash
    }
  }
  return {
    ...init,
    hash
  }
}

export const useDataTableConfig = <T extends unknown>(
  localStateName: string,
  { columns, stickyColumsLeft }: DataTableConfigProps<T>
): [DataTableConfig, () => void] => {
  const hash = useMemo(
    () =>
      calcHash(
        columns
          .filter(Boolean)
          // .filter((col) => !col.off)
          .map((col, idx) => buildColumnKey(col, idx))
          .join('.') +
          '.' +
          stickyColumsLeft
      ),
    [columns, stickyColumsLeft]
  )

  const [config, setConfig] = useLocalState<DataTableConfig>(
    localStateName ? 'table.' + localStateName : null,
    { stickyColumsLeft },
    validate,
    hash
  )

  const { dataTableGlobalConfig } = useDataTableGlobals()

  const showDlg = useGlobalDialogAnker()

  const showConfigDialog = useCallback(() => {
    showDlg((open, onClose) => (
      <DataTableConfigDialog
        open={open}
        onClose={onClose()}
        stickyColumsLeftDefault={stickyColumsLeft}
        stripedDefault={dataTableGlobalConfig.striped}
        bordersDefault={dataTableGlobalConfig.borders}
        actionsPosDefault={dataTableGlobalConfig.actionsPos}
        setConfig={setConfig}
        hash={hash}
        config={config}
        columns={columns}
        hasState={localStateName != null}
      />
    ))
  }, [
    columns,
    config,
    hash,
    localStateName,
    setConfig,
    showDlg,
    stickyColumsLeft,
    dataTableGlobalConfig
  ])

  return [config, showConfigDialog]
}
