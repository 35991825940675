import { restoreReferences } from '@utils/utils'
import axios from 'axios'
import packageJson from '../package.json'

export const api = axios.create({
  baseURL: 'rest',
  headers: {
    clientvers: packageJson.version,
    jsonrefs: true
  }
})

api.interceptors.response.use((response) => {
  // transformDates(response.data);
  restoreReferences(response.data)
  return response
})
