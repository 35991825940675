import { Alert } from '@mui/material'
import { useArtikelLabelCache } from '@one/datacaches/useArtikelLabelCache'
import { LabelJson } from '@one/typings/apiTypings'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMemo, useState } from 'react'

export interface ArtikelLabelDialogProps {
  open: boolean
  hasSelectedArtikel: boolean
  hasIllegalArtikel?: boolean
  onClose: (selected?: any, addLabels?: boolean) => () => void
}

export const ArtikelLabelDialog = ({
  open,
  onClose,
  hasSelectedArtikel,
  hasIllegalArtikel = false
}: ArtikelLabelDialogProps) => {
  const labelCache = useArtikelLabelCache(true)
  const [selected, setSelected] = useState(new Set<LabelJson>())

  // Systemlabels filtern
  const labelList = useMemo(() => labelCache.data.filter((label) => !label.system), [labelCache])

  const columns: Column<LabelJson>[] = useMemo(
    () => [
      {
        field: 'kurz',
        header: 'Kurzbezeichnung',
        sortable: true
      },
      {
        width: '100%',
        field: 'bezeichnung',
        header: 'Bezeichnung',
        sortable: true
      }
    ],
    []
  )

  const onCancel = () => {
    onClose()
  }

  const onAdd = () => {
    onClose(Array.from(selected), true)
  }

  const onRemove = () => {
    onClose(Array.from(selected), false)
  }

  const actions = (
    <ButtonRow>
      <Button
        variant="contained"
        onClick={onAdd}
        label="Hinzufügen"
        tooltip={!hasSelectedArtikel ? 'Keine Artikel ausgewählt' : ''}
        disabled={!hasSelectedArtikel || selected.size === 0}
      />
      <Button
        variant="contained"
        color="error"
        onClick={onRemove}
        label="Entfernen"
        tooltip={!hasSelectedArtikel ? 'Keine Artikel ausgewählt' : ''}
        disabled={!hasSelectedArtikel || selected.size === 0}
      />
      <CancelButton onClick={onCancel} />
    </ButtonRow>
  )

  return (
    <DialogEx
      open={open}
      onClose={onCancel}
      title="Artikel-Label"
      actions={actions}
      fullWidth
      height="400px"
    >
      <Frame space>
        {hasIllegalArtikel && (
          <FrameRow>
            <Alert severity="warning">
              Es können nur ERP-Artikel mit Labels versehen werden, daher wurden einige Artikel aus
              der Selektion für die Label-Verwaltung ignoriert.
            </Alert>
          </FrameRow>
        )}
        <FrameBody>
          <DataTable
            name="artikelLabel"
            initialOrderBy="kurz"
            value={labelList}
            columns={columns}
            selectCol
            selectMode="multi"
            selected={selected}
            onSelect={setSelected}
            dense
            emptyMessage={
              labelList.length === 0
                ? 'Keine Labels vorhanden, Legen Sie im Artikel-Label Dialog welche an'
                : undefined
            }
          />
        </FrameBody>
      </Frame>
    </DialogEx>
  )
}
