import { Add } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { LieferantSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantenUebersichtParamsProps {
  value: LieferantSearcherCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<LieferantSearcherCriteriaJson>
  borderless?: boolean
  zentralName: 'forAbo' | 'zentral'
  disableZentral?: boolean
  disableDezentral?: boolean
  onCreateEigenlistungsLieferant?: VoidFunction
}

export const LieferantenUebersichtParams = ({
  value,
  onSearch,
  onChange,
  borderless,
  zentralName,
  disableZentral,
  disableDezentral,
  onCreateEigenlistungsLieferant
}: LieferantenUebersichtParamsProps) => {
  const shortcuts = {
    Enter: onSearch
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        collapsiable={!borderless}
        title={borderless ? null : 'Lieferantenrecherche'}
        backLink={!borderless}
        bottomActions={
          <>
            <Button
              label="Eigenlistungslieferant anlegen"
              StartIcon={Add}
              onClick={onCreateEigenlistungsLieferant}
              visible={!!onCreateEigenlistungsLieferant}
              color="info"
            />
            <SearchButton onClick={onSearch} />
          </>
        }
        borderless={borderless}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {(!disableZentral || !!value[zentralName]) && (
                <Grid item>
                  <Checkbox
                    label="Listung"
                    size="small"
                    name={zentralName}
                    checked={value[zentralName]}
                    onChange={onChange}
                    color="default"
                    disabled={disableZentral}
                  />
                </Grid>
              )}
              {(!disableDezentral || !!value.dezentral) && (
                <Grid item flexGrow={1}>
                  <Checkbox
                    label="ERP"
                    size="small"
                    name="dezentral"
                    checked={value.dezentral}
                    onChange={onChange}
                    color="default"
                    disabled={disableDezentral}
                  />
                </Grid>
              )}
              <Grid item>
                <Checkbox
                  label="Auch gesperrte"
                  size="small"
                  name="auchGeloeschte"
                  checked={value.auchGeloeschte}
                  onChange={onChange}
                  color="default"
                />
                {onCreateEigenlistungsLieferant && (
                  <Checkbox
                    label="Eigenlistung"
                    size="small"
                    name="eigenlistung"
                    checked={value.eigenlistung}
                    onChange={onChange}
                    color="default"
                    indeterminate
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <TextField
              fullWidth
              onChange={onChange}
              name="nummer"
              label="Lieferantennummer"
              value={value.nummer}
            />
          </Grid>
          <Grid item xs={6} md={5} lg={3}>
            <TextField
              fullWidth
              onChange={onChange}
              name="name"
              label="Lieferantenname"
              value={value.name}
            />
          </Grid>
          <Grid item xs={6} md={5} lg={3}>
            <TextField fullWidth onChange={onChange} name="iln" label="ILN" value={value.iln} />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
