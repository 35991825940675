import { Grid } from '@mui/material'
import { LieferantAboField } from '@one/components/LieferantAbo/LieferantAboField'
import { ArtikelListenField } from '@one/components/Listen/ArtikelListeSuchDialog/ArtikelListenField'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import { NeonKontextField } from '@one/components/common/NeonKontextField'
import { PreisEbeneField } from '@one/components/common/PreisEbeneField'
import { ArtikelBearbeitungsListeJson } from '@one/typings/apiTypings'
import { toDate } from '@utils/dateutils'
import { ErrorsType } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { DateField } from '@utils/ui/fields/DateField'
import { TextField } from '@utils/ui/fields/TextField'
import { useContext } from 'react'

export interface PreisAnlageFormProps {
  model: ArtikelBearbeitungsListeJson
  errors: ErrorsType
  onValueChange: (e: any) => void
  handleSelect: (e: any) => void
  title: string
  overviewLink: string
  mode: 'ek' | 'vk'
}

export const PreisAnlageForm = ({
  errors,
  model,
  onValueChange,
  handleSelect,
  title,
  overviewLink,
  mode
}: PreisAnlageFormProps) => {
  const { isPIMModel } = useContext(AppContext)
  return (
    <CardEx backLink overviewLink={overviewLink} collapsiable title={title}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <LieferantAboField
            value={model.lieferant}
            label="Lieferant"
            name="lieferant"
            onChange={handleSelect}
            disabled={model.lieferantFix}
            required={mode === 'ek'}
            error={
              errors?.lieferant ||
              (mode === 'ek' && model.lieferant == null && 'Lieferant muss gewählt sein')
            }
          />
        </Grid>

        {isPIMModel ? (
          <>
            <Grid item xs={4} md={4} lg={2}>
              <NeonKontextField
                label="Anlagekontext"
                name="kontext"
                value={model.kontext}
                onChange={handleSelect}
                required
                error={errors?.kontext}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={2}>
              <PreisEbeneField
                label="Preisebene"
                name="preisEbeneId"
                value={model.preisEbeneId}
                onChange={onValueChange}
                kontext={model.kontext}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4} md={4} lg={2}>
            <ArtikelBetriebstypField
              label="Anlagebetriebstyp"
              name="bt"
              value={model.bt}
              onChange={handleSelect}
              required
              showArtikelzahlVonLieferantId={model.lieferant?.id}
              filter={model.lieferant ? 'mitArtikeln' : 'zugeordnete'}
              error={errors?.bt}
            />
          </Grid>
        )}

        <Grid item xs={6} md={4}>
          <ArtikelListenField
            value={model.artikelListe}
            error={errors.artikelListe}
            label="Artikelliste"
            name="artikelListe"
            mitStatistik
            onChange={handleSelect}
            disabled={model.artikelListeFix}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="name"
                label="Name"
                value={model.name}
                error={errors.name}
                onChange={onValueChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <DateField
                name="gueltigAb"
                label="Gültig ab"
                disableToolbar
                value={model.gueltigAb}
                error={errors?.gueltigAb}
                minDate={toDate(model.gueltigAbMin)}
                maxDate={toDate(model.gueltigAbMax)}
                onChange={onValueChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                name="bemerkung"
                label="Bemerkung"
                value={model.bemerkung}
                error={errors.bemerkung}
                onChange={onValueChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
