import { Visibility } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { ProtokollEintragJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useQuery } from '@utils/routing'
import { Button } from '@utils/ui/Buttons/Button'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { Medal } from '@utils/ui/fields/Medal'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { arrayFromSet, pathOf } from '@utils/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface ImportErrorJson {
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  protokollEintrag?: ProtokollEintragJson
  /** @format date-time */
  lastReImportTime?: string
  /** @format int32 */
  reImportCount?: number
}

export interface ImportFehlerViewProps {
  name: string
  title: string
  rest: string
  columns: Column[]
}

export const ImportFehlerView = ({ name, title, rest, columns }: ImportFehlerViewProps) => {
  const [{ [AppPaths.ImportFehlerQuery.showIgnored]: showIgnored }] = useQuery()

  const [apiCall, apiBusy] = useApiCaller(api)

  const [importErrors, setImportErrors] = useState<ImportErrorJson[]>([])
  const [selected, setSelected] = useState(new Set<ImportErrorJson>())
  const [isIgnored, setIsIgnored] = useState(Boolean(showIgnored || false))

  const refresh = () => {
    setImportErrors([])
    apiCall({
      method: 'GET',
      rest: rest + '/errors/load',
      onSuccess: (data: any) => {
        setImportErrors(data.importErrors)
      }
    })
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const perform = (restname: string) => {
    apiCall({
      method: 'POST',
      rest: rest + '/errors/' + restname,
      data: arrayFromSet(selected).map((s) => s.id),
      onSuccess: (data: any) => {
        refresh()
      }
    })
  }

  const onFilterChange = useCallback(() => {
    setIsIgnored(!isIgnored)
  }, [setIsIgnored, isIgnored])

  const tableItems = useMemo(
    () => (isIgnored ? importErrors : importErrors?.filter((item) => !item.ignoriert)),
    [isIgnored, importErrors]
  )

  const reimportable = useMemo(() => selected.size > 0, [selected])

  const ignorable = useMemo(
    () => selected.size > 0 && arrayFromSet(selected).find((r) => r.ignoriert) == null,
    [selected]
  )

  const exportErrorColumns = useMemo(
    () =>
      [
        {
          field: 'protokollEintrag.timestamp',
          header: 'Zeitstempel',
          type: 'datetimesecond',
          width: '160px'
        },
        {
          field: 'status',
          header: 'Status',
          align: 'center',
          valueGetter: (row) => (row.ignoriert && 'ignoriert') || 'offen',
          body: (row) => (row.ignoriert && <Medal text="ignoriert" />) || <Medal text="offen" />
        },
        {
          field: 'lastReImportTime',
          header: 'Re-Import ZP',
          type: 'datetimesecond'
        },
        {
          field: 'reImportCount',
          header: 'Re-Import Anz.',
          type: 'number'
        },
        {
          field: 'protokollEintrag.message',
          header: 'Fehlermeldung'
        },
        ...columns
      ] as Column<ImportErrorJson>[],
    [columns]
  )

  const checkbox = (
    <Grid container justifyContent="center">
      <Grid item>
        <Checkbox
          label="Ignorierte Importfehler anzeigen"
          size="small"
          name="ignored"
          paddingTop={0}
          checked={isIgnored}
          onChange={onFilterChange}
          color="default"
        />
      </Grid>
    </Grid>
  )

  const topActions = [
    {
      tooltip: 'Aktualisieren',
      icon: 'refresh',
      onClick: () => refresh()
    }
  ]

  const bottomActions = [
    <Button
      key="rei"
      size="small"
      label="Re-Import triggern"
      variant="contained"
      disabled={!reimportable}
      onClick={() => perform('reimport')}
    />,
    <Button
      key="ign"
      size="small"
      label="Ignorieren"
      variant="contained"
      disabled={!ignorable}
      onClick={() => perform('ignorieren')}
    />
  ]

  const rowActions = [
    {
      icon: <Visibility />,
      tooltip: 'Job-Protokoll zeigen',
      getLink: (data) =>
        data.protokollEintrag && AppPaths.ProtokollDetailsViewFn(data.protokollEintrag.protokollId)
    }
  ] as DataTableAction[]

  return (
    <StatePlane wait={apiBusy}>
      <Frame space>
        <FrameBody>
          <DataTableCard
            title={title}
            backLink
            name={name}
            selectMode="multi"
            selectCol
            selected={selected}
            onSelect={setSelected}
            columns={exportErrorColumns}
            value={tableItems}
            paging
            filterMode="both"
            dense
            wrapMode="normal"
            size="slim"
            localStateName={name}
            header={checkbox}
            topActions={topActions}
            bottomActions={bottomActions}
            actions={rowActions}
            emptyMessage="Keine Meldungen vorhanden"
            initialOrderBy={[
              {
                key: pathOf<ImportErrorJson, ProtokollEintragJson>('protokollEintrag', 'timestamp'),
                dir: 'desc'
              }
            ]}
          />
        </FrameBody>
      </Frame>
    </StatePlane>
  )
}
