import {
  ArtikelDisplayJson,
  ArtikelDisplaySmallJson,
  ArtikelDisplayTinyJson
} from '@one/typings/apiTypings'

export const getArchiviertRowStyle = (archiviert: boolean) => {
  if (archiviert) {
    return {
      fontStyle: 'italic',
      textDecorationLine: 'line-through',
      backgroundColor: '#9090902b'
    }
  }
  return null
}

export const getRowStyleArtikel = (
  row: ArtikelDisplayJson | ArtikelDisplaySmallJson | ArtikelDisplayTinyJson | null
) => getArchiviertRowStyle(row?.archiviert)

export interface WithArtikel {
  artikel?: ArtikelDisplayJson | ArtikelDisplaySmallJson | ArtikelDisplayTinyJson | null
}

export const getRowStyleSubArtikel = (row: Pick<WithArtikel, 'artikel'>) =>
  getArchiviertRowStyle(row?.artikel?.archiviert)
