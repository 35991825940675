import { FolderCopy, Loop } from '@mui/icons-material'
import { Paper } from '@mui/material'
import { api } from '@one/api'
import {
  AllianzDatenUmschaltungDisplayJson,
  AllianzDatenUmschaltungJson,
  MessageJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { isAsyncTaskActive, useAsyncTaskPoller } from '@utils/asynctask'
import { formatDateTime } from '@utils/dateutils'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Rotate } from '@utils/ui/Rotate'
import { TextField } from '@utils/ui/fields/TextField'
import { useEffect, useState } from 'react'
import { AllianzPreisEbeneDialog } from './AllianzPreisEbeneDialog'

export type AllianzPreisEbeneFieldProps = {
  onChange?: () => void
}

export const AllianzPreisEbeneField = ({ onChange }: AllianzPreisEbeneFieldProps) => {
  const [running, setRunning] = useState(true)
  const [umschaltInfo, setUmschaltInfo] = useState<AllianzDatenUmschaltungDisplayJson>(null)
  const [taskMessages, setTaskMessages] = useState<MessageJson[]>(null)

  const [apiCall, apiBusy] = useApiCaller(api)

  const [DlgAnker, showDialog] = useDialogAnker()

  const [asyncPoller] = useAsyncTaskPoller()

  const allianzUmschalten = (preisEbeneId) => {
    setRunning(true)
    apiCall<AllianzDatenUmschaltungJson>({
      method: 'POST',
      rest: '/allianz/datenUmschalten',
      data: {
        preisEbeneId
      },
      onSuccessMsg: 'Erfolgreich gestartet.',
      onSuccess: (data) => {
        const asyncTask = data.state?.asyncTask
        if (isAsyncTaskActive(asyncTask)) {
          setTaskMessages(null)
          asyncPoller({
            asyncTask,
            apiCall,
            onReady: (at) => {
              setRunning(false)
              setTaskMessages(at?.error ? at.messages : null)
              onChange && onChange()
            },
            onError: () => {
              setRunning(false)
              setTaskMessages([{ message: 'Task unerwartet beendet' }])
            }
          })
        } else {
          setRunning(false)
          setTaskMessages(asyncTask?.error ? asyncTask.messages : null)
          onChange && onChange()
        }
        setUmschaltInfo(data.umschaltung)
      }
    })
  }

  useEffect(() => {
    apiCall<AllianzDatenUmschaltungJson>({
      method: 'GET',
      rest: '/allianz/loadDatenUmschaltung',
      onSuccess: (data) => {
        const asyncTask = data?.state?.asyncTask
        if (isAsyncTaskActive(asyncTask)) {
          setRunning(true)
          asyncPoller({
            asyncTask,
            apiCall,
            onReady: (data) => {
              setRunning(false)
              setTaskMessages(data?.error ? data.messages : null)
            },
            onError: () => {
              setRunning(false)
              setTaskMessages([{ message: 'Task unerwartet beendet' }])
            }
          })
        } else {
          setRunning(false)
          setTaskMessages(asyncTask?.error ? asyncTask.messages : null)
        }
        setUmschaltInfo(data.umschaltung)
      }
    })
  }, [apiCall, asyncPoller])

  const onComplete = (preisEbeneId) => {
    if (preisEbeneId) {
      allianzUmschalten(preisEbeneId)
    }
  }

  const currentPreisEbene = umschaltInfo?.preisEbene

  const onOpenDialog = () => {
    showDialog((visible, onClose) => (
      <AllianzPreisEbeneDialog
        visible={visible}
        onClose={onClose(onComplete)}
        preiskategorieId={currentPreisEbene?.id}
      />
    ))
  }

  const helperText =
    'Allianz-Preisebene für ERP-Export ' +
    (taskMessages?.map((m) => m.message).join(', ') ||
      (running && 'Bereitstellung läuft...') ||
      (apiBusy && 'Prüfe...') ||
      (umschaltInfo?.audit?.createdOn &&
        `Letzte Umschaltung: ${formatDateTime(umschaltInfo?.audit?.createdOn)}`) ||
      '')

  return (
    <Paper
      style={{
        alignSelf: 'start',
        width: '500px',
        padding: '6px 12px',
        borderRadius: 4
      }}
    >
      <TextField
        style={{ padding: 4, height: 22 }}
        label={helperText}
        value={currentPreisEbene?.alias || currentPreisEbene?.name}
        placeholder="Keine Preisebene ausgewählt"
        fullWidth
        actions={{
          icon:
            running || apiBusy ? (
              <Rotate>
                <Loop fontSize="small" />
              </Rotate>
            ) : (
              <FolderCopy fontSize="small" />
            ),
          tooltip: 'Preiseben zum Bereitstellen auswählen...',
          onClick: onOpenDialog,
          disabled: running || apiBusy
        }}
        error={taskMessages != null}
        // helperText={
        //   taskMessages?.map((m) => m.message).join(', ') ||
        //   (running && 'Bereitstellung läuft...') ||
        //   (apiBusy && 'Prüfe...') ||
        //   `Letzte Bereitstellung: ${formatDateTime(umschaltInfo?.audit?.createdOn) || 'Keine'}`
        // }
      />
      <DlgAnker />
    </Paper>
  )
}
