import {
  EkPreisListeImportJson,
  EkPreisListImportAssistentStepIANDuplikateArtikelJson,
  EkPreisListImportAssistentStepJson,
  EkPreisListImportAssistentStepNichtGetroffeneArtikelJson,
  EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson,
  EkPreisListImportStepId
} from '@one/typings/apiTypings'

export interface EkPreisListImportAssistentStepIANDuplikateArtikelEdit {
  ianAusDatei?: string
  eanAusDatei?: string
  artikelText?: string
  duplikat: EkPreisListImportAssistentStepIANDuplikateArtikelJson
  zuschnittIAN?: string
}

export interface EkPreisListeImportEdit extends EkPreisListeImportJson {
  nichtGetroffenenArtikelChanges?: Map<number, boolean>
  ianDuplikate?: EkPreisListImportAssistentStepIANDuplikateArtikelEdit[]
  ianDuplikateChanges?: Map<number, boolean>
  peMeUnterschiedeChanges?: Map<number, boolean>
  kondiListeFreigeben?: boolean
  kondiListeCreateVkPreisvorgang?: boolean
}

const setNichtGetroffenOk = (
  state: EkPreisListeImportEdit,
  id: number,
  checked: boolean,
  selected: Set<EkPreisListImportAssistentStepNichtGetroffeneArtikelJson>
) => {
  const { aktStep } = state
  if (aktStep?.stepId === EkPreisListImportStepId.STEP3NTARTIKEL) {
    const nichtGetroffenenArtikelChanges = new Map(state.nichtGetroffenenArtikelChanges)
    selected.forEach((sel) => nichtGetroffenenArtikelChanges.set(sel.id, checked))
    nichtGetroffenenArtikelChanges.set(id, checked)
    return {
      ...state,
      nichtGetroffenenArtikelChanges
    }
  }
  return state
}

const setIanDuplikatBepreisen = (
  state: EkPreisListeImportEdit,
  id: number,
  checked: boolean,
  selected: Set<EkPreisListImportAssistentStepIANDuplikateArtikelEdit>
) => {
  const { aktStep } = state
  if (aktStep?.stepId === EkPreisListImportStepId.STEP4IAN) {
    const ianDuplikateChanges = new Map(state.ianDuplikateChanges)
    selected.forEach((sel) => ianDuplikateChanges.set(sel.duplikat.id, checked))
    ianDuplikateChanges.set(id, checked)
    return {
      ...state,
      ianDuplikateChanges
    }
  }
  return state
}

const setMePeBepreisen = (
  state: EkPreisListeImportEdit,
  bepreisen: boolean,
  selected: Set<EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson>
) => {
  const { aktStep } = state
  if (aktStep?.stepId === EkPreisListImportStepId.STEP5EKMEDIFF) {
    const peMeUnterschiedeChanges = new Map(state.peMeUnterschiedeChanges)
    selected.forEach((sel) => {
      if (sel.konnteUmgerechnetWerden || bepreisen == null || bepreisen == false) {
        peMeUnterschiedeChanges.set(sel.artikel.id, bepreisen)
      }
    })
    return {
      ...state,
      peMeUnterschiedeChanges
    }
  }
  return state
}
/**
 * Usecase/Reducer
 */
export const EkPreisImportUsecase = {
  SET_NICHTGETROFFEN_OK: 'SET_NICHTGETROFFEN_OK',
  SET_IANDUPLIKAT_BEPREISEN: 'SET_IANDUPLIKAT_BEPREISEN',
  SET_MEPE_BEPREISEN: 'SET_MEPE_BEPREISEN',

  reducer: (state: EkPreisListeImportEdit, action: any) => {
    switch (action.type) {
      case EkPreisImportUsecase.SET_NICHTGETROFFEN_OK:
        return setNichtGetroffenOk(state, action.id, action.checked, action.selected)
      case EkPreisImportUsecase.SET_IANDUPLIKAT_BEPREISEN:
        return setIanDuplikatBepreisen(state, action.id, action.checked, action.selected)
      case EkPreisImportUsecase.SET_MEPE_BEPREISEN:
        return setMePeBepreisen(state, action.bepreisen, action.selected)
      default:
        console.error('Unexpected action', action)
        return state
    }
  },

  editMutator: (state: EkPreisListeImportJson): EkPreisListeImportEdit => {
    const edit = {
      ...state,
      fehlerZeilen: state.fehlerZeilen?.map((zeile) => ({
        ...zeile,
        unbekannteME: zeile.messages
          ?.map((msg) => {
            const match = msg.message.match(/Mengeneinheit '(.+)' ist nicht im ERP vorhanden./)
            return match && match[1]
          })
          .find((s) => s != null)
      })),
      ianDuplikateChanges: new Map(),
      nichtGetroffenenArtikelChanges: new Map()
    } as EkPreisListeImportEdit

    if (state.aktStep?.stepId === EkPreisListImportStepId.STEP4IAN) {
      edit.ianDuplikate = state.aktStep.ianDuplikate?.flatMap((it, idx) => {
        const zuschnittIAN =
          it.artikel
            .map((art) => art.artikel.industrieArtikelNummer)
            .filter((v, i, a) => a.indexOf(v) === i).length === 1
            ? it.artikel[0].ian
            : null
        return it.artikel.map(
          (duplikat) =>
            ({
              ...it,
              grouperKey: idx,
              zuschnittIAN,
              duplikat
            }) as EkPreisListImportAssistentStepIANDuplikateArtikelEdit
        )
      })
    }

    if (state.aktStep?.stepId === EkPreisListImportStepId.STEP7STATISTIKENDE) {
      edit.kondiListeFreigeben = state.aktStep?.kondiListeFreigeben || false
      edit.kondiListeCreateVkPreisvorgang = state.aktStep?.kondiListeCreateVkPreisvorgang || false
    }

    return edit
  },

  saveMutator: (state: EkPreisListeImportEdit) => {
    return {
      id: state.id,
      version: state.version,
      name: state.name,
      ignorierteZeilen: state.fehlerZeilen
        .filter((fehlerZeile) => fehlerZeile.ignoriert)
        .map((fehlerZeile) => fehlerZeile.zeile)
    }
  },

  saveStepMutator: (state: EkPreisListeImportEdit) => {
    if (state.aktStep == null) {
      return null
    }

    const data = {
      stepId: state.aktStepId
    } as EkPreisListImportAssistentStepJson

    switch (state.aktStepId) {
      case EkPreisListImportStepId.STEP3NTARTIKEL:
        if (state.nichtGetroffenenArtikelChanges?.size > 0) {
          data.nichtGetroffenenArtikelChanges = []
          state.nichtGetroffenenArtikelChanges.forEach((val, key) =>
            data.nichtGetroffenenArtikelChanges.push({ id: key, ok: val })
          )
        }
        break

      case EkPreisListImportStepId.STEP4IAN:
        if (state.ianDuplikateChanges?.size > 0) {
          data.ianDuplikateChanges = []
          state.ianDuplikateChanges.forEach((val, key) =>
            data.ianDuplikateChanges.push({ id: key, bepreisen: val })
          )
        }
        break

      case EkPreisListImportStepId.STEP5EKMEDIFF:
        if (state.peMeUnterschiedeChanges?.size > 0) {
          data.peMeUnterschiedeChanges = []
          state.peMeUnterschiedeChanges.forEach((val, key) =>
            data.peMeUnterschiedeChanges.push({ suArtikelId: key, bepreisen: val })
          )
        }
        break

      case EkPreisListImportStepId.STEP7STATISTIKENDE:
        data.kondiListeFreigeben = state.kondiListeFreigeben
        data.kondiListeCreateVkPreisvorgang = state.kondiListeCreateVkPreisvorgang
        break
    }
    return data
  }
}
