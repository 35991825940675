/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
import { Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { EkPreiseBearbeitenJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { notifyObservers } from '@utils/observer'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useContext, useState } from 'react'
import { NavigateFunction } from 'react-router'

export interface EkPreisListeFreigabeDialogProps {
  id: number
  open: boolean
  onClose: (fn?: () => any) => () => any
  onCancel?: () => void
  onSuccess?: (data: EkPreiseBearbeitenJson) => void
  navigate: NavigateFunction
}

export const EkPreisListeFreigabeDialog = ({
  id,
  navigate,
  open,
  onClose,
  onCancel,
  onSuccess = () => {}
}: EkPreisListeFreigabeDialogProps) => {
  const { isAllianz, isWithVkPreis } = useContext(AppContext)

  const [createVkPreisliste, setCreateVkPreisliste] = useState(false)
  const [data, setData] = useState<EkPreiseBearbeitenJson | null>(null)
  const [navigateToVk, setNavigateToVk] = useState(false)

  const [apiCall, apiBusy] = useApiCaller(api)

  const onCheckboxClick = () => {
    setCreateVkPreisliste(!createVkPreisliste)
  }

  const onFinalSuccess = (data: EkPreiseBearbeitenJson, navigateToVk: boolean) => {
    notifyObservers({ name: EventNames.DASHBOARD })
    onSuccess(data)
    if (navigateToVk && data.artikelListeRef) {
      navigate(
        AppPaths.VkPreisAnlageFn({
          artikelListeRef: data.artikelListeRef,
          bt: data.betriebstyp,
          kontext: data.kontext
        })
      )
    }
    onClose()()
  }

  const onConfirm = (navigateToVk: boolean) => {
    apiCall<EkPreiseBearbeitenJson>({
      method: 'POST',
      rest: '/ekpreisliste/freigeben',
      params: { id, createVkPV: Boolean(createVkPreisliste) },
      onErrorMsg: 'Die Preisliste konnte nicht freigegeben werden',
      onSuccessMsg: 'Die Preisliste wurde erfolgreich freigegeben.',
      onSuccess: (data) => {
        if (createVkPreisliste && data.artikelMitAnderemHauptlieferanten) {
          setData(data)
        } else {
          onFinalSuccess(data, navigateToVk)
        }
      }
    })
  }

  const onConfirmAndNavigate = () => {
    setNavigateToVk(true)
    onConfirm(true)
  }

  const actions = data ? (
    <>
      {data.artikelListeRef && (
        <Button
          label="VK-Preisanlage"
          variant="contained"
          onClick={() => onFinalSuccess(data, navigateToVk)}
        />
      )}
      <Button label="Schließen" variant="contained" onClick={onClose(() => onSuccess(data))} />
    </>
  ) : (
    <>
      {!isAllianz && (
        <Checkbox
          label="VK Preisvorgang anlegen"
          size="small"
          name="VkPreisvorgang"
          checked={createVkPreisliste}
          onChange={onCheckboxClick}
          color="default"
          paddingTop={0}
        />
      )}
      <Button label="Freigeben" variant="contained" onClick={() => onConfirm(navigateToVk)} />
      {!isAllianz && isWithVkPreis && (
        <Button
          label="Freigeben und zum VK Vorgang"
          variant="contained"
          disabled={!createVkPreisliste}
          onClick={onConfirmAndNavigate}
        />
      )}
      <CancelButton autoFocus onClick={onClose(onCancel)} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={onClose(onCancel)}
      lockClose
      maxWidth="md"
      actions={actions}
      title="Preisliste freigeben"
      height="320px"
    >
      <StatePlane wait={apiBusy}>
        <Typography padding={2}>
          {data
            ? data.artikelListeRef == null
              ? 'Die EK-Preisliste wurde erfolgreich freigegeben, aber es wurden keine VK-Preisvorgänge angelegt, da alle Artikel einen anderen Hauptlieferanten haben.'
              : 'Die EK-Preisliste wurde erfolgreich freigegeben, aber es wurden nicht für alle Artikel VK-Preisvorgänge angelegt, da diese einen anderen Hauptlieferanten haben.'
            : 'Diese Preisliste freigeben. Eine Bearbeitung ist danach nicht mehr möglich'}
        </Typography>
      </StatePlane>
    </DialogEx>
  )
}
