import { Grid, Typography } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { DzMapperEinstellungTyp, MapperEinstellungJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import { useContext } from 'react'

export interface ArtikelLieferantSyncTemplatePflegeKopfViewProps {
  model: MapperEinstellungJson
  cloneId: string
  onValueChange: (event: any) => void
  readOnly: boolean
}

export const ArtikelLieferantSyncTemplatePflegeKopfView = ({
  model,
  cloneId,
  readOnly,
  onValueChange
}: ArtikelLieferantSyncTemplatePflegeKopfViewProps) => {
  const appContext = useContext(AppContext)

  const isAdminMode = appContext?.session?.adminMode

  const { et } = useEnums()

  return (
    <CardEx
      backLink
      collapsiable
      title={`${cloneId ? 'Kopie von' : 'Pflege'} ${
        model.typ === DzMapperEinstellungTyp.LISST
          ? 'Lieferantenstammdaten-Synchronisationstemplate (LiSST)'
          : 'Artikelstammdaten-Synchronisationstemplate (ArSST)'
      }`}
      subheader={
        isAdminMode && (
          <Typography color="error">
            Achtung: Zentral vordefinierte ArSST - Änderungen wirken sich auf alle Gesellschafter
            aus!
          </Typography>
        )
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} lg={3}>
          <StaticField
            fullWidth
            label="Type"
            value={`${et(HkmEnum.DzMapperEinstellungTyp, model.typ)}${
              isAdminMode && model.pimDatenmodell ? ' (PIM)' : ''
            }`}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <TextField
            fullWidth
            name="einstellung.name"
            label="Name"
            value={model.name}
            required
            onChange={onValueChange}
            disabled={readOnly}
          />
        </Grid>
        {model.standardEH && (
          <Grid item xs={12} sm={4} lg={3}>
            <Checkbox
              name="einstellung.standardeh"
              // autoOk
              label="Standard für EH"
              checked={model.standardEH}
              onChange={onValueChange}
              disabled={readOnly}
            />
          </Grid>
        )}
        {!isAdminMode && !model.standardEH && (
          <Grid item xs={12} sm={4} lg={3}>
            <Checkbox
              name="einstellung.standard"
              // autoOk
              label="Standard für Fachandel"
              checked={model.standardFH}
              onChange={onValueChange}
              disabled={readOnly}
            />
          </Grid>
        )}
        {isAdminMode && !model.standardEH && (
          <>
            <Grid item xs={12} sm={4} lg={3}>
              <NumberField
                name="einstellung.standardStufe"
                label="Standartstufe"
                value={model.standardStufe}
                fraction={0}
                onChange={onValueChange}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </CardEx>
  )
}
