import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { ArtikelListeDisplayJson, ArtikelListeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { ArtikelListeUebersichtParams } from './ArtikelListeUebersichtParams'
import { ArtikelListeUebersichtTable } from './ArtikelListeUebersichtTable'

export const ArtikelListeUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    ArtikelListeSearcherCriteriaJson,
    ArtikelListeDisplayJson
  >({
    api,
    url: '/artikelliste',
    initialParams: {
      name: undefined,
      typ: undefined
    }
  })

  useObserver(EventNames.ARTIKELLISTE, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <ArtikelListeUebersichtParams
          value={criteria}
          onChange={onCriteriaChange}
          onSearch={search}
        />
      </FrameRow>
      <FrameBody>
        <ArtikelListeUebersichtTable result={result} />
      </FrameBody>
    </Frame>
  )
}
