import { Typography } from '@mui/material'
import { formatNumber } from '@utils/numberutils'
import { makeStyles } from 'tss-react/mui'
import { StatusItemWrapper, StatusItemWrapperProps } from './StatusItemWrapper'

export interface StatusItemProps extends Omit<StatusItemWrapperProps, 'children'> {
  info?: string
}

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  count: {
    fontSize: '16px'
  }
}))

export const StatusItem = ({ title, count, icon, info, name }: StatusItemProps) => {
  const { classes } = useStyles()

  return (
    <StatusItemWrapper name={name} count={count} icon={icon} title={title}>
      <Typography>{info}</Typography>
      <Typography className={classes.count}>
        {typeof count === 'number' ? formatNumber(count, 0) : count}
      </Typography>
    </StatusItemWrapper>
  )
}
