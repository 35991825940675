import { api } from '@one/api'
import { PreisEbeneDisplayJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<PreisEbeneDisplayJson> = {
  api,
  // refreshEvents: EventNames.ARSST_LOAD,
  datakey: 'preisebenen',
  rest: 'preisebenen',
  method: 'GET',
  idField: 'id',
  field: 'items'
} as DataCacheConfig

export const usePreisEbenenCache = () => useDataCache(config)
