import { setNativeValue } from '@utils/ui/uiutils'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ColumnType } from './DataTable'

export type EditCellProps<T> = {
  id: string
  active: boolean
  render: (edit: boolean) => any
  setActive: (edit: boolean) => void
  type: ColumnType
}

const validNums = new RegExp('[0-9,\\.-]')
const validChars = new RegExp('[A-Z0-9 ,\\.-]', 'i')

export const EditCell = ({ id, active, render, setActive, type }: EditCellProps<any>) => {
  const [keyEvent, setKeyEvent] = useState(null)
  const domRef = useRef<HTMLElement>(null)
  const body = useMemo(() => render(active), [active, render])

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      const target = event.target as any
      if (!event.repeat && domRef.current?.parentElement?.contains(target)) {
        switch (event.code) {
          case 'F2':
            if (!active) {
              event.preventDefault()
              setActive(true)
            }
            break
          case 'Escape':
            if (active) {
              event.preventDefault()
              domRef.current.parentElement.focus()
              setActive(false)
            }
            break
        }
      }
    }
    const keypress = (event: KeyboardEvent) => {
      const target = event.target as any
      if (!event.repeat && domRef.current?.parentElement?.contains(target)) {
        if (!active) {
          switch (type) {
            case 'money2':
            case 'number':
              if (!validNums.test(event.key)) {
                return
              }
              break
            case 'string':
              if (!validChars.test(event.key)) {
                return
              }
              break
            // default:
            //   return
          }
          event.preventDefault()
          setActive(true)
          setKeyEvent(event.key)
        }
      }
    }
    const dblclick = (event: MouseEvent) => {
      const target = event.target as any
      if (!active && domRef.current?.parentElement?.contains(target)) {
        event.preventDefault()
        setActive(true)
      }
    }
    const focus = (event: FocusEvent) => {
      const target = event.target as any
      if (!active && domRef.current?.parentElement?.contains(target)) {
        event.preventDefault()
        setActive(true)
      }
    }

    window.document.addEventListener('keydown', keydown)
    window.document.addEventListener('keypress', keypress)
    window.document.addEventListener('dblclick', dblclick)
    window.document.addEventListener('focus', focus)
    return () => {
      window.document.removeEventListener('keydown', keydown)
      window.document.removeEventListener('keypress', keypress)
      window.document.removeEventListener('dblclick', dblclick)
      window.document.removeEventListener('focus', focus)
    }
  }, [active, setActive, type])

  useLayoutEffect(() => {
    if (active) {
      setTimeout(() => {
        const el = document.getElementById(id) as any
        if (el) {
          el.focus()
        }
      }, 50)
    }
  }, [body, id, active])

  useLayoutEffect(() => {
    if (active && keyEvent) {
      const el = document.getElementById(id) as HTMLInputElement
      if (el) {
        setKeyEvent(null)
        setTimeout(() => {
          setNativeValue(el, keyEvent)
        }, 250)
      }
    }
  }, [active, id, keyEvent])

  return <span ref={domRef}>{body}</span>
}
