import Grid from '@mui/material/Grid2'
import {
  PimArtikelVersionJson,
  PimArtikelVersionSpracheJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { NumberField } from '@utils/ui/fields/NumberField'

export interface PimArtikelBezeichungProps {
  pimArtikelVersionSprache: PimArtikelVersionSpracheJson
  pimArtikelVersion?: PimArtikelVersionJson
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelBezeichung wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikelVersionSprache
 * @param pimArtikelVersion
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelBezeichung = ({
  pimArtikelVersionSprache,
  pimArtikelVersion,
  onChange,
  errors,
  readonly
}: PimArtikelBezeichungProps) => {
  return (
    <CardEx title="Bezeichnungen">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Lieferschein 1"
            value={pimArtikelVersionSprache.lieferschein1}
            name="pimArtikel.version.sprache.lieferschein1"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Lieferschein 2"
            value={pimArtikelVersionSprache.lieferschein2}
            name="pimArtikel.version.sprache.lieferschein2"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Lieferschein 3"
            value={pimArtikelVersionSprache.lieferschein3}
            name="pimArtikel.version.sprache.lieferschein3"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Lieferschein 4"
            value={pimArtikelVersionSprache.lieferschein4}
            name="pimArtikel.version.sprache.lieferschein4"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingTop={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Etikett 1"
            value={pimArtikelVersionSprache.etikett1}
            name="pimArtikel.version.sprache.etikett1"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Etikett 2"
            value={pimArtikelVersionSprache.etikett2}
            name="pimArtikel.version.sprache.etikett2"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingTop={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Sellingpoint 1"
            name="pimArtikel.version.sprache.sellingpoint1"
            value={pimArtikelVersionSprache.sellingpoint1}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Sellingpoint 2"
            name="pimArtikel.version.sprache.sellingpoint2"
            value={pimArtikelVersionSprache.sellingpoint2}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Sellingpoint 3"
            name="pimArtikel.version.sprache.sellingpoint3"
            value={pimArtikelVersionSprache.sellingpoint3}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Sellingpoint 4"
            name="pimArtikel.version.sprache.sellingpoint4"
            value={pimArtikelVersionSprache.sellingpoint4}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Sellingpoint 5"
            name="pimArtikel.version.sprache.sellingpoint5"
            value={pimArtikelVersionSprache.sellingpoint5}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingTop={2}>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 1"
            value={pimArtikelVersionSprache.plakattext1}
            name="pimArtikel.version.sprache.plakattext1"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 2"
            value={pimArtikelVersionSprache.plakattext2}
            name="pimArtikel.version.sprache.plakattext2"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 3"
            value={pimArtikelVersionSprache.plakattext3}
            name="pimArtikel.version.sprache.plakattext3"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 4"
            value={pimArtikelVersionSprache.plakattext4}
            name="pimArtikel.version.sprache.plakattext4"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 5"
            value={pimArtikelVersionSprache.plakattext5}
            name="pimArtikel.version.sprache.plakattext5"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 6"
            value={pimArtikelVersionSprache.plakattext6}
            name="pimArtikel.version.sprache.plakattext6"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 7"
            value={pimArtikelVersionSprache.plakattext7}
            name="pimArtikel.version.sprache.plakattext7"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Plakattext 8"
            value={pimArtikelVersionSprache.plakattext8}
            name="pimArtikel.version.sprache.plakattext8"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <NumberField
            label="Plakatlayout"
            value={pimArtikelVersion.plakatlayout}
            name="pimArtikel.version.plakatlayout"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
          <TextField
            label="Produktreferenz"
            value={pimArtikelVersionSprache.produktreferenz}
            name="pimArtikel.version.sprache.produktreferenz"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} paddingTop={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Produktdimension 1"
            value={pimArtikelVersionSprache.produktDimension1}
            name="pimArtikel.version.sprache.produktDimension1"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Produktdimension 2"
            value={pimArtikelVersionSprache.produktDimension2}
            name="pimArtikel.version.sprache.produktDimension2"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Produktdimension 3"
            value={pimArtikelVersionSprache.produktDimension3}
            name="pimArtikel.version.sprache.produktDimension3"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} paddingTop={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Farbe"
            value={pimArtikelVersionSprache.farbe}
            name="pimArtikel.version.sprache.farbe"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Material"
            value={pimArtikelVersionSprache.material}
            name="pimArtikel.version.sprache.material"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Lieferumfang"
            value={pimArtikelVersionSprache.lieferumfang}
            name="pimArtikel.version.sprache.lieferumfang"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        {/*<Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
           <TextField
             label="Primärklassifikation"
             value={pimArtikelVersionSprache.primaerklassifikation}
             name="pimArtikel.version.sprache.primaerklassifikation"
             onChange={onChange}
             fullWidth
           />
        </Grid>*/}
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Produktreferenz"
            value={pimArtikelVersionSprache.produktreferenz}
            name="pimArtikel.version.sprache.produktreferenz"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelBezeichungMemo = React.memo(PimArtikelBezeichung)
