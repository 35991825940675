import { Grid } from '@mui/material'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantenListeSuchFormProps {
  searchValues: any
  onChange: (e: any) => void
}

export const LieferantenListeSuchForm = ({ searchValues, onChange }) => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          onChange={onChange}
          name="name"
          label="Name"
          value={searchValues.name}
        />
      </Grid>
    </Grid>
  </>
)
