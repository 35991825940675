import { Divider } from '@mui/material'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  left: {
    position: 'relative',
    minWidth: 'min-content'
  },
  right: {
    minWidth: 'min-content'
  },
  vsplitter: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: '2',
    '&:hover, &:active': {
      cursor: 'col-resize',
      paddingLeft: 2,
      paddingRight: 2
    }
  },
  fullHeight: {
    height: '100%'
  }
})

export type HorizSplitPanelProps = {
  style?: React.CSSProperties
  left?: React.ReactNode
  right?: React.ReactNode
  grow?: 'left' | 'right'
  leftStartWidth?: number
  fullHeight?: boolean
}

export const HorizSplitPanel = ({
  style,
  left,
  right,
  grow = 'right',
  leftStartWidth,
  fullHeight
}: HorizSplitPanelProps) => {
  const { classes } = useStyles()

  const [isDragging, setIsDragging] = useState(false)
  const [pageX, setPageX] = useState(0)
  const [th, setTH] = useState<any>()
  const [thw, setTHW] = useState(0)

  const leftRef = useRef()
  const rightRef = useRef()
  const myRef = useRef<any>()

  const rs = { width: leftStartWidth, flexGrow: grow === 'left' ? 1 : undefined }

  const onDown = (event) => {
    event.target.setPointerCapture(event.pointerId)
    event.stopPropagation()
    event.preventDefault()
  }

  const onUp = () => {}

  const onSetWidth = (diffX) => {
    let w = thw + diffX
    if (w < 10) {
      w = 10
    }
    const ws = `${w}px`
    if (th) {
      th.style.width = ws
    }
  }

  const onMove = (event) => {
    if (isDragging) {
      event.stopPropagation()
      event.preventDefault()
      if (th) {
        const diffX = event.pageX - pageX
        onSetWidth(diffX)
      }
    }
  }

  const onGotCapture = (event) => {
    document.body.style['pointer-events'] = 'ew-resize'

    setIsDragging(true)
    setPageX(event.pageX)

    const node = myRef.current
    const parent = node?.parentElement
    setTH(parent)
    setTHW(parent?.offsetWidth)
  }

  const onLostCapture = () => {
    document.body.style['pointer-events'] = 'auto'
    setIsDragging(false)
  }

  return (
    <div className={clsx(classes.root, fullHeight && classes.fullHeight)} style={style}>
      <div className={classes.left} ref={leftRef} style={rs}>
        {left}
        <Divider
          orientation="vertical"
          ref={myRef}
          className={classes.vsplitter}
          onPointerDown={onDown}
          onPointerMove={onMove}
          onPointerUp={onUp}
          onPointerCancel={onUp}
          onGotPointerCapture={onGotCapture}
          onLostPointerCapture={onLostCapture}
        />
      </div>
      <div
        className={classes.right}
        ref={rightRef}
        style={{ flexGrow: grow === 'right' ? 1 : undefined }}
      >
        {right}
      </div>
    </div>
  )
}
