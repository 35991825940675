import { api } from '@one/api'
import { LieferantSearchDialog } from '@one/components/Lieferant/LieferantSearchDialog'
import { EventNames } from '@one/EventNames'
import {
  AllianzVerteilerMatrixBearbeitenJson,
  AllianzVerteilerMatrixJson,
  AllianzVerteilerMatrixMitgliedJson,
  LieferantDisplayJson,
  PreisEbeneDisplayJson,
  PreisEbeneStufe
} from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useModelMgr } from '@utils/modelmgr'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { getValueComparator } from '@utils/ui/DataTable/DataTableUtils'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { sortArray, toString } from '@utils/utils'
import { useCallback, useContext, useMemo, useState } from 'react'
import { MitgliedLieferantMatrixCell } from './MitgliedLieferantMatrixCell'

export const AllianzVerteilerMatrixEditor = () => {
  const [addedLieferanten, setAddedLieferanten] = useState<LieferantDisplayJson[]>([])

  const {
    envelope,
    model,
    updModel,
    onValueChange,
    save,
    reload,
    errors,
    isChanged,
    isNew,
    uiLock
  } = useModelMgr<
    AllianzVerteilerMatrixJson,
    AllianzVerteilerMatrixJson,
    AllianzVerteilerMatrixBearbeitenJson
  >({
    noid: true,
    api,
    unwrapField: 'matrix',
    title: 'Allianzverteiler',
    rest: 'allianz/verteilermatrix',
    eventName: EventNames.ALLIANZVERTEILER,
    editMutator: (payload) => {
      setAddedLieferanten([])
      return payload
    }
  })

  const [DlgAnker, dlgShow] = useDialogAnker()

  const { askToConfirm } = useMessageDialog()

  const { checkUserRole } = useContext(AppContext)
  const readonly = !checkUserRole(UserRoles.GS_EIN_EDITOR)

  const lieferanten = useMemo(() => {
    const org = envelope.lieferanten || []
    const combined = [...org, ...addedLieferanten]
    sortArray(
      combined,
      getValueComparator('asc', (l) => l.name1)
    )
    return combined
  }, [addedLieferanten, envelope.lieferanten])

  const preisebenen = useMemo(() => {
    return envelope.preisebenen?.filter((pe) => pe.ausgeblendet !== true) || []
  }, [envelope.preisebenen])

  const hloIln = useMemo(() => {
    return envelope.hagebauLogistikILN
  }, [envelope])

  const onSetPreisEbene = useCallback(
    (mitgliedId: number, seLieferantId: number, preisEbeneId: number) => {
      if (mitgliedId == null || seLieferantId == null) {
        return
      }
      const updateModelFn = () =>
        updModel((old: AllianzVerteilerMatrixJson) => ({
          ...old,
          eintraege: old.eintraege.map((eintrag) => {
            const key = toString(seLieferantId)
            if (eintrag.mitglied.id === mitgliedId) {
              let cell = eintrag.lieferanten[key]
              if (cell == null) {
                cell = { seLieferantId, preisEbeneId }
              } else {
                cell = { ...cell, preisEbeneId }
              }
              return {
                ...eintrag,
                lieferanten: {
                  ...eintrag.lieferanten,
                  [key]: cell
                }
              }
            }
            return eintrag
          })
        }))
      const lieferant = lieferanten.find((item) => item.seLieferantId === seLieferantId)
      const hasEintrag = model.eintraege.find(
        (item) =>
          item.mitglied.id === mitgliedId && item.lieferanten[toString(seLieferantId)]?.preisEbeneId
      )

      if (lieferant.iln === hloIln && hasEintrag) {
        askToConfirm({
          title: 'Änderung der Preiskategorie',
          confirmLabel: 'Ja',
          message:
            'Achtung: Durch die Änderung der Preiskategorie, bzw. des Preisstandorts, ändern Sie das dieser Gesellschafterfirma vertraglich zugeordnete Zentrallager der hagebau-Logistics (hLo). Wollen Sie diese Änderung wirklich durchführen?',
          onConfirm: updateModelFn
        })
      } else {
        updateModelFn()
      }
    },
    [updModel, lieferanten, hloIln, askToConfirm, model]
  )

  const columns = useMemo<Column<AllianzVerteilerMatrixMitgliedJson>[]>(() => {
    return [
      {
        field: 'mitglied.firma.gln',
        header: 'Mitglied GLN',
        sortable: false
      },
      {
        field: 'mitglied.firma.name',
        header: 'Mitglied Name',
        sortable: false
      },
      ...lieferanten.map(
        (lieferant) =>
          ({
            field: 'lief-' + lieferant.id,
            header: lieferant.name1,
            sortable: false,
            body: (row) => {
              const preisebenenFilter =
                lieferant.iln === hloIln
                  ? (preisebene: PreisEbeneDisplayJson) =>
                      preisebene.stufe === PreisEbeneStufe.LOGISTIK
                  : (preisebenen: PreisEbeneDisplayJson) => preisebenen.stufe == null // alle anderen = Stufe B
              const lieferantPreisebenen = preisebenen.filter(preisebenenFilter)
              return (
                <MitgliedLieferantMatrixCell
                  node={row}
                  lieferant={lieferant}
                  preisebenen={lieferantPreisebenen}
                  onSetPreisEbene={onSetPreisEbene}
                  disabled={readonly}
                />
              )
            }
          }) as Column<AllianzVerteilerMatrixMitgliedJson>
      ),
      {
        field: 'dummy',
        width: '100%'
      }
    ]
  }, [preisebenen, lieferanten, onSetPreisEbene, readonly, hloIln])

  const topActions = useMemo<Action[]>(
    () => [
      {
        icon: 'add',
        text: 'Lieferant hinzufügen',
        role: UserRoles.GS_EIN_EDITOR,
        onClick: () => {
          dlgShow((open, onClose) => (
            <LieferantSearchDialog
              open={open}
              onClose={onClose((sel: LieferantDisplayJson[]) => {
                if (sel) {
                  const nondup = sel.filter((l) => lieferanten.find((ll) => ll.id === l.id) == null)
                  setAddedLieferanten([...addedLieferanten, ...nondup])
                }
              })}
              multiselect
              defaultDezentral
              disableZentral
              disableDezentral
            />
          ))
        }
      }
    ],
    [addedLieferanten, dlgShow, lieferanten]
  )

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <DataTableCard
            title="Allianzmitglied-Lieferanten-Verteiler"
            backLink
            name="AllianzverteilerMatrixTable"
            value={model.eintraege}
            columns={columns}
            filterMode="global"
            localStateName="AllianzverteilerMatrix"
            selectMode="single"
            stickyColumsLeft={2}
            dense
            topActions={topActions}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
      <DlgAnker />
    </StatePlane>
  )
}
