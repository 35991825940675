export type HeaderTitleCellProps = {
  title: string
  subtitle: string
}

export const HeaderTitleCell = ({ title, subtitle }: HeaderTitleCellProps) => {
  return (
    <span>
      {title}
      <span style={{ fontSize: '70%', paddingLeft: 8, textTransform: 'capitalize' }}>
        {subtitle}
      </span>
    </span>
  )
}
