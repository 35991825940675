import { Menu as MenuIcon } from '@mui/icons-material'
import { Divider, Menu, MenuItem } from '@mui/material'
import { Button, ButtonProps } from '@utils/ui/Buttons/Button'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { safeArray } from '@utils/utils'
import { MouseEvent, ReactElement, ReactNode, useMemo, useState } from 'react'

export type MenuButtonItem = {
  label: string | ReactNode
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  disabled?: boolean
  forceEnabled?: boolean
  id?: string | number
}

export type MenuButtonProps = {
  label?: string
  title?: string
  icon?: ReactElement
  size?: 'small' | 'large'
  disabled?: boolean
  keepOpen?: boolean
  items: MenuButtonItem[] | (() => MenuButtonItem[])
  variant?: ButtonProps['variant']
  hideIfEmpty?: boolean
}

export const MenuButton = ({
  label,
  title,
  icon,
  size,
  disabled,
  items,
  keepOpen,
  variant,
  hideIfEmpty
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const menuItems = useMemo(() => {
    return safeArray(typeof items === 'function' ? items() : items).filter(Boolean)
  }, [items])

  if (hideIfEmpty && (menuItems == null || menuItems.length === 0)) {
    return null
  }

  const onOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onMenuItem = (item: MenuButtonItem) => (e: MouseEvent<HTMLAnchorElement>) => {
    if (!keepOpen) {
      setAnchorEl(null)
    }
    if (item.onClick != null) {
      item.onClick(e)
    }
  }

  const button = label ? (
    <Button
      label={label}
      aria-controls="menubutton"
      aria-haspopup="true"
      size={size}
      onClick={onOpen}
      disabled={disabled}
      title={title}
      variant={variant}
      startIcon={icon}
    />
  ) : (
    <IconButton
      icon={icon || <MenuIcon />}
      aria-controls="menubutton"
      aria-haspopup="true"
      size={size}
      onClick={onOpen}
      disabled={disabled}
      title={title}
    />
  )

  return (
    <>
      {button}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
        {menuItems.map((item: MenuButtonItem, idx) =>
          item.label === 'divider' ? (
            idx + 1 < menuItems.length ? (
              <Divider key={item.id || `${item.label}`} />
            ) : null
          ) : (
            <MenuItem
              key={item.id || `${item.label}`}
              onClick={onMenuItem(item)}
              disabled={item.forceEnabled ? false : item.disabled || item.onClick == null}
              href="#"
            >
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  )
}
