import { MessageID } from '@one/MessageIDs'
import {
  EkKonditionenRabattgruppeKopfJson,
  EkKonditionenRabattgruppenListeChangeJson,
  EkKonditionenRabattgruppenListeEintragJson,
  EkKonditionenRabattgruppenListeJson,
  EkRabattgruppenListeBearbeitenJson,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { arrayFromSet } from '@utils/utils'

export interface EkKonditionenRabattgruppenListeEditJson
  extends EkKonditionenRabattgruppenListeJson {
  rabattgruppen?: EkKonditionenRabattgruppeKopfJson[]
  preisEbenen?: PreisEbeneDisplayJson[]
  staffelMengen?: number[]
}

const changeRabattValue = (
  model: EkKonditionenRabattgruppenListeEditJson,
  eintrag:
    | EkKonditionenRabattgruppenListeEintragJson
    | Set<EkKonditionenRabattgruppenListeEintragJson>,
  field: string,
  value: number
) => {
  const ids =
    eintrag instanceof Set
      ? new Set(arrayFromSet(eintrag).map((e) => e.id))
      : (eintrag && new Set([eintrag.id])) || new Set()
  return {
    ...model,
    eintraege: model.eintraege.map((e) =>
      ids.has(e.id)
        ? {
            ...e,
            [field]: value
          }
        : e
    )
  }
}

export const EKRabattgruppenMassenPflegeUsecase = {
  CHANGERABATTVALUE: 'CHANGERABATTVALUE',

  reducer: (model: EkKonditionenRabattgruppenListeEditJson, action: ModelAction) => {
    switch (action.type) {
      case EKRabattgruppenMassenPflegeUsecase.CHANGERABATTVALUE:
        return changeRabattValue(model, action.model, action.field, action.value)
    }
    return model
  },

  editMutator: (
    payload: EkKonditionenRabattgruppenListeJson,
    lastModel: EkKonditionenRabattgruppenListeJson,
    envelope: EkRabattgruppenListeBearbeitenJson
  ): EkKonditionenRabattgruppenListeEditJson => {
    let ret = payload
    if (lastModel != null && envelope?.state?.mainMessage?.messageId?.id === MessageID.ID_DELTA) {
      const changeMap =
        payload.eintraege?.reduce((eintraegeMap, eintrag) => {
          eintraegeMap[eintrag.id] = eintrag
          return eintraegeMap
        }, {}) ?? {}

      const eintraege: EkKonditionenRabattgruppenListeEintragJson[] =
        lastModel?.eintraege?.map((eintrag) => {
          const payloadEintrag = changeMap[eintrag.id]
          return payloadEintrag ?? eintrag
        }) ?? payload.eintraege

      ret = {
        ...lastModel,
        eintraege,
        version: payload.version
      }
    }

    const rabattgruppen = ret?.eintraege
      ?.map((e) => e.rabattgruppe)
      .filter((r, i, a) => a.indexOf(r) === i)

    const staffelMengen = () => {
      const _staffelMengen = new Set<number>()
      ret?.eintraege?.forEach((value) => {
        _staffelMengen.add(value.staffelmenge)
      })
      return Array.from(_staffelMengen).sort((a, b) => a - b)
    }

    const preisEbenen = () => {
      const _preisEbenen = new Set<PreisEbeneDisplayJson>()
      ret?.eintraege?.forEach((value) => {
        if (value.preisEbene) {
          _preisEbenen.add(value.preisEbene)
        }
      })
      return Array.from(_preisEbenen)
    }

    return { ...ret, rabattgruppen, preisEbenen: preisEbenen(), staffelMengen: staffelMengen() }
  },

  saveMutator: (
    model: EkKonditionenRabattgruppenListeEditJson,
    lastPayload: EkKonditionenRabattgruppenListeJson
  ): EkKonditionenRabattgruppenListeChangeJson => {
    const lastPayloadMap =
      lastPayload.eintraege?.reduce((eintraegeMap, eintrag) => {
        eintraegeMap[eintrag.id] = eintrag
        return eintraegeMap
      }, {}) ?? {}

    const changes =
      model.eintraege?.filter((eintrag) => {
        const lastEintrag = lastPayloadMap[eintrag.id]
        if (
          eintrag.rabattProz1 === lastEintrag.rabattProz1 &&
          eintrag.rabattProz2 === lastEintrag.rabattProz2 &&
          eintrag.rabattProz3 === lastEintrag.rabattProz3 &&
          eintrag.rabattProz4 === lastEintrag.rabattProz4 &&
          eintrag.rabattProz5 === lastEintrag.rabattProz5 &&
          eintrag.zuschlAbs1 === lastEintrag.zuschlAbs1 &&
          eintrag.zuschlAbs2 === lastEintrag.zuschlAbs2 &&
          eintrag.zuschlAbs3 === lastEintrag.zuschlAbs3 &&
          eintrag.zuschlAbs4 === lastEintrag.zuschlAbs4 &&
          eintrag.zuschlAbs1 === lastEintrag.zuschlAbs5
        ) {
          return false
        }
        return true
      }) ?? null

    return {
      id: model.id,
      gueltigVon: model.gueltigVon,
      lieferantId: model.lieferant.id,
      version: model.version,
      changes
    }
  }
}
