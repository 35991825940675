import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  LieferantenListeDisplayJson,
  LieferantenVerteilungDefinitionDisplayJson,
  LieferantenVerteilungDefinitionSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace } from '@utils/utils'
import { useCallback } from 'react'
import { LieferantenVerteilerUebersichtParams } from './LieferantenVerteilerUebersichtParams'
import { LieferantenVerteilerUebersichtTable } from './LieferantenVerteilerUebersichtTable'

export const LieferantenVerteilerUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    LieferantenVerteilungDefinitionSearcherCriteriaJson,
    LieferantenVerteilungDefinitionDisplayJson
  >({
    api,
    url: '/lieferantenverteiler',
    initialParams: {
      name: ''
    }
  })

  const { askToDelete } = useMessageDialog()

  const [apiCall, apiBusy] = useApiCaller(api)

  const handleDeleteClick = useCallback(
    (row: LieferantenListeDisplayJson) => () => {
      const quote = (text) => (text ? `"${text}"` : '')
      askToDelete({
        title: `Lieferantenverteiler ${quote(row.name)} wirklich löschen`,
        onConfirm: () => {
          apiCall<any>({
            method: 'POST',
            rest: `/lieferantenverteiler/delete`,
            params: { id: row.id },
            onErrorMsg: `Lieferantenverteiler ${quote(row.name)} konnte nicht gelöscht werden.`,
            onSuccessMsg: `Lieferantenverteiler ${quote(row.name)} erfolgreich gelöscht.`,
            onSuccess: () => setResult((rs) => arrayItemReplace(rs, (r) => r.id === row.id, null))
          })
        }
      })
    },
    [askToDelete, apiCall, setResult]
  )

  useObserver(EventNames.LIEFERANTENVERTEILER, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <LieferantenVerteilerUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <LieferantenVerteilerUebersichtTable result={result} onDelete={handleDeleteClick} />
      </FrameBody>
    </Frame>
  )
}
