import { Grid } from '@mui/material'
import { usePreisEbenenCache } from '@one/datacaches/usePreisEbenenCache'
import { NeonKontext, PreisEbeneDisplayJson } from '@one/typings/apiTypings'
import { SelectField } from '@utils/ui/fields/SelectField'
import { ifString, zeroPad } from '@utils/utils'

export interface PreisEbeneFieldProps {
  value?: number | PreisEbeneDisplayJson
  onChange: any
  name?: string
  error?: string | boolean
  label?: string
  emptyText?: string
  disabled?: boolean
  required?: boolean
  asJson?: boolean
  helperText?: string
  kontext?: NeonKontext
}

export const PreisEbeneField = ({
  value = null,
  onChange = null,
  name = null,
  label = null,
  emptyText = null,
  error = null,
  disabled = false,
  required = false,
  asJson = false,
  helperText = '',
  kontext = null
}) => {
  const { data } = usePreisEbenenCache()
  const items = kontext ? data?.filter((pe) => pe.kontext === kontext) : data
  return (
    <SelectField
      label={label}
      value={value}
      error={!!error}
      helperText={ifString(error) ?? helperText}
      options={items}
      onChange={onChange}
      name={name}
      optionValue={asJson ? (o) => o : 'id'}
      optionText={(pe: PreisEbeneDisplayJson) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>{zeroPad(pe?.nr, 3)}</Grid>
          <Grid item>
            <span>{pe?.name}</span>
          </Grid>
        </Grid>
      )}
      displayEmpty
      emptyText={emptyText}
      disabled={disabled}
      required={required}
      fullWidth
    />
  )
}
