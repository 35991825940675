import React from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  blur: {
    '& > *': {
      filter: 'blur(2px)'
    }
  }
})

export type LockPlaneProps = {
  blur?: boolean
  children: React.ReactNode
}

export const BlurPlane = ({ blur = false, children }: LockPlaneProps) => {
  const { classes } = useStyles()

  return <span className={blur ? classes.blur : null}>{children}</span>
}
