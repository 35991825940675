import { Grid } from '@mui/material'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import {
  EkKonditionenDefinitionBearbeitenJson,
  EkKonditionenDefinitionJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { nameOf } from '@utils/utils'
import { useContext, useMemo } from 'react'

const EditField = ({ model, label, name, onChange, disabled }: any) => {
  const nameKurz = 'kurz' + name
  const nameBez = 'bez' + name
  const checkedName = name.charAt(0).toLowerCase() + name.slice(1)
  return (
    <Grid container spacing={2} flexWrap="nowrap">
      <Grid item>
        <TextField
          label={label + ' Kurzbez.'}
          placeholder={label}
          value={model[nameKurz]}
          name={nameKurz}
          onChange={onChange}
          maxLength={10}
          style={{ width: '10em' }}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <TextField
          label={label + ' Bezeichnung'}
          value={model[nameBez]}
          name={nameBez}
          onChange={onChange}
          maxLength={50}
          style={{ width: '50em' }}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <Checkbox
          title="EKPFH Relevant ja/nein"
          name={checkedName}
          checked={model[checkedName]}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export const EkPreisKonditionsschemaPflegeView = () => {
  const { checkUserRole, isPIMModel, isAllianz } = useContext(AppContext)

  const readonly = !checkUserRole(UserRoles.GS_EIN_EDITOR) || !isPIMModel

  const { model, onValueChange, uiLock, isNew, isChanged, save, reload, envelope } = useModelMgr<
    EkKonditionenDefinitionJson,
    EkKonditionenDefinitionJson,
    EkKonditionenDefinitionBearbeitenJson
  >({
    noid: true,
    api,
    rest: 'ekkonditiondefiniton',
    unwrapField: nameOf<EkKonditionenDefinitionBearbeitenJson>('data')
  })

  const ekKondDefEx = useMemo(() => enrichKondiDefinition(model, isAllianz), [isAllianz, model])

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <CardEx title="EK-Preis Kalkulationsschema" backLink>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.zuschlAbs1Label}
                  name="ZuschlAbs1"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.zuschlAbs2Label}
                  name="ZuschlAbs2"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.rabattProz1Label}
                  name="RabattProz1"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.rabattProz2Label}
                  name="RabattProz2"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.rabattProz3Label}
                  name="RabattProz3"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.rabattProz4Label}
                  name="RabattProz4"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.rabattProz5Label}
                  name="RabattProz5"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.zuschlAbs3Label}
                  name="ZuschlAbs3"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.zuschlAbs4Label}
                  name="ZuschlAbs4"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
              <Grid item xs={12}>
                <EditField
                  label={ekKondDefEx.zuschlAbs5Label}
                  name="ZuschlAbs5"
                  model={model}
                  onChange={onValueChange}
                  disabled={readonly}
                />
              </Grid>
            </Grid>
          </CardEx>
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton
              isNew={isNew}
              isChanged={isChanged}
              onClickVoid={save}
              visible={!readonly}
            />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClick={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
