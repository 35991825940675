import { useMessageDialog } from '@utils/ui/MessageDialog'
import { useCallback } from 'react'
import { Button, ButtonProps } from './Button'

export interface ButtonWithConfirmationProps {
  isNew?: boolean
  disabled?: boolean
  visible?: boolean
  onAction?: VoidFunction
  tooltip?: string
  size?: ButtonProps['size']
  label?: string
  confirmationMsg: string
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
}

export const ButtonWithConfirmation = ({
  isNew = false,
  disabled = false,
  onAction,
  tooltip,
  size,
  color,
  label = 'Löschen',
  visible = true,
  confirmationMsg,
  variant = 'contained'
}: ButtonWithConfirmationProps) => {
  const { askToConfirm } = useMessageDialog()

  const onClick = useCallback(() => {
    askToConfirm({
      title: `${confirmationMsg}?`,
      onConfirm: onAction
    })
  }, [askToConfirm, confirmationMsg, onAction])

  return (
    <Button
      label={label}
      size={size}
      onClick={onClick}
      variant={variant}
      color={color}
      disabled={isNew || disabled}
      visible={visible}
      tooltip={tooltip}
    />
  )
}
