import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { AllianzBearbeitenJson, AllianzJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useState } from 'react'
import { GesellschafterAllianzPflegeKopf } from './GesellschafterAllianzPflegeKopf'
import { GesellschafterAllianzPflegeTable } from './GesellschafterAllianzPflegeTable'

const validate = (model) => {
  const errors = {} as any

  if (!model.name) {
    errors.name = 'Name ist ein Pflichtfeld'
  }
  return errors
}

export const GesellschafterAllianzPflegeView = () => {
  const { id, gsId } = useContext(RouteContext) as any
  const [shouldWait, setShouldWait] = useState(false)

  const { model, isNew, isChanged, uiLock, onValueChange, save, reload, errors } = useModelMgr<
    AllianzBearbeitenJson,
    AllianzJson
  >({
    id,
    api,
    validate,
    restps: {
      gsId
    },
    unwrapField: 'allianz',
    title: 'Allianz',
    rest: 'allianz'
  })

  const onReload = useCallback(() => {
    reload()
    setShouldWait(false)
  }, [reload])

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.Gesellschafter} wait={shouldWait}>
      <Frame space>
        <FrameRow>
          <GesellschafterAllianzPflegeKopf
            allianz={model}
            onChange={onValueChange}
            errors={errors}
          />
        </FrameRow>
        <FrameBody>
          <GesellschafterAllianzPflegeTable
            mitglieder={model.mitglieder}
            isNew={isNew}
            reload={onReload}
            setWait={setShouldWait}
            allianzId={model.id}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton
              onClickVoid={save}
              isNew={isNew}
              isChanged={isChanged}
              tooltip="Gesellschafter in Allianz umwandel"
            />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
