import { Card, CardActions, CardContent, CardHeader, Divider } from '@mui/material'
import { CenterGrid } from '@utils/ui/CenterCard'
import React from 'react'

export type InfoPageProps = {
  title: any
  content?: any
  buttons?: React.ReactNode
}

export const InfoPage = ({ title, content, buttons }: InfoPageProps) => {
  return (
    <CenterGrid>
      <Card>
        <CardHeader title={title} />
        {(content || buttons) && <Divider orientation="horizontal" />}
        {content && <CardContent>{content}</CardContent>}
        {buttons && <CardActions style={{ justifyContent: 'flex-end' }}>{buttons}</CardActions>}
      </Card>
    </CenterGrid>
  )
}
