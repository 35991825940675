import { Box, LinearProgress } from '@mui/material'
import { EkPreisListeImportFehlerZeileJson, MessageSeverity } from '@one/typings/apiTypings'
import { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  validationProgress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  progressBarWrapper: { width: '100%' },
  progressBar: {
    height: '8px!important',
    '& .MuiLinearProgress-dashed': {
      backgroundColor: '#888',
      animation: 'none',
      backgroundImage: 'none'
    },
    '& .MuiLinearProgress-bar2Buffer': {
      backgroundColor: 'red'
    }
  }
})

export type EkPreisImportZeileStatusProps = {
  anzahlZeilen: number
  anzahlFehler: number
  fehlerZeilen: EkPreisListeImportFehlerZeileJson[]
}

export const EkPreisImportZeileStatus = ({
  anzahlZeilen,
  anzahlFehler,
  fehlerZeilen
}: EkPreisImportZeileStatusProps) => {
  const { classes } = useStyles()

  const progressBar = useMemo(() => {
    if (anzahlZeilen || anzahlFehler) {
      const invalid = fehlerZeilen.filter(
        (fehler) =>
          fehler.severity === MessageSeverity.ERR || fehler.severity === MessageSeverity.FATAL
      ).length
      const ignored = fehlerZeilen.filter(
        (fehler) => fehler.ignoriert || fehler.autoIgnoriert
      ).length
      const valid = Math.max(anzahlZeilen - invalid, 0)
      // im Fall einer Datei, die nur den Header enthält gibt es eine Fehlerzeile aber kein 'anzahlZeilen' = 0
      const total = Math.max(anzahlZeilen, fehlerZeilen.length)
      return {
        valid,
        ignored,
        invalid,
        total,
        progress: (valid * 100) / anzahlZeilen,
        buffer: ((invalid + valid - ignored) * 100) / total
      }
    } else {
      return {
        valid: 0,
        ignored: 0,
        invalid: 0,
        total: 0,
        progress: 0,
        buffer: 0
      }
    }
  }, [anzahlFehler, anzahlZeilen, fehlerZeilen])

  return (
    <>
      <Box className={classes.validationProgress}>
        <span>Zeilen ohne Fehler: {progressBar.valid ?? '0'}</span>
        <span>Zeilen mit Fehlern: {progressBar.invalid ?? '0'}</span>
        <span>ignorierte Zeilen: {progressBar.ignored ?? '0'}</span>
        <span>Insgesamt: {progressBar.total ?? '0'}</span>
      </Box>
      <Box className={classes.progressBarWrapper}>
        <LinearProgress
          variant="buffer"
          className={classes.progressBar}
          value={progressBar.progress}
          valueBuffer={progressBar.buffer}
        />
      </Box>
    </>
  )
}
