/* eslint-disable react/jsx-one-expression-per-line */
import { Box, Icon, Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import React from 'react'
import { Link } from 'react-router-dom'

export type ButtonProps = {
  visible?: boolean
  label?: any
  component?: MUIButtonProps['component']
  /** sets <Link>-Component imlicit, do not add it as component prop */
  to?: any
  onClick?: MUIButtonProps['onClick']
  onClickVoid?: () => void
  color?: MUIButtonProps['color']
  disabled?: boolean
  variant?: MUIButtonProps['variant']
  style?: any
  className?: any
  size?: MUIButtonProps['size']
  fullWidth?: MUIButtonProps['fullWidth']
  startIcon?: string | React.ReactNode
  StartIcon?: React.ComponentType<any>
  autoFocus?: boolean
  title?: string
  name?: string
  tooltip?: string | React.ReactNode
}

export const Button = ({
  visible = true,
  label,
  name,
  to,
  startIcon,
  tooltip,
  StartIcon,
  onClick,
  onClickVoid,
  ...props
}: ButtonProps) => {
  if (visible) {
    let button = (
      <span>
        <MUIButton
          startIcon={
            (StartIcon && <StartIcon />) ||
            (typeof startIcon == 'string' ? <Icon>{startIcon}</Icon> : startIcon)
          }
          onClick={onClick || (onClickVoid && (() => onClickVoid()))}
          data-name={name || label}
          {...props}
        >
          {label}
        </MUIButton>
      </span>
    )

    if (to && !props.disabled) {
      button = <Link to={to}>{button}</Link>
    }

    return tooltip ? (
      <TooltipEx title={tooltip} arrow>
        <Box>{button}</Box>
      </TooltipEx>
    ) : (
      button
    )
  }
  return null
}
