import { errorLog } from '@utils/logging'
import { isEmptyEx } from '@utils/utils'
import { DragEvent } from 'react'
import { generatePath } from 'react-router'

export const isChrome = navigator.userAgent.indexOf('Chrome/') > -1

export const isBrowserEdge = navigator.userAgent.indexOf('Edg/') > -1

export const autoCompleteOff = isBrowserEdge ? 'new-password' : 'off'

export const getFirstOfType = (el, type) => {
  if (el == null) {
    return null
  }
  if (el.localName === type) {
    return el
  }
  return getFirstOfType(el.parentElement, type)
}

export const findScrollParent = (node) => {
  if (node) {
    const style = window.getComputedStyle(node)
    if (style) {
      if (style['overflow-y'] === 'scroll' || style['overflow-y'] === 'auto') {
        return node
      }
      // eslint-disable-next-line dot-notation
      if (style['overflow'] === 'scroll' || style['overflow'] === 'auto') {
        return node
      }
    }
    return findScrollParent(node.parentNode)
  }
  return node
}

export const getParentVScrollPanel = (node: HTMLElement): HTMLElement => {
  if (node) {
    const style = window.getComputedStyle(node.parentElement)
    if (style) {
      if (style['overflow-y'] === 'scroll' || style['overflow-y'] === 'auto') {
        return node.parentElement
      }
      // eslint-disable-next-line dot-notation
      if (style['overflow'] === 'scroll' || style['overflow'] === 'auto') {
        return node.parentElement
      }
    }
  }
  return null
}
export const calcElementInViewport = (el: HTMLElement, offset?: number): number | null => {
  if (el) {
    const scrollEl = findScrollParent(el)
    if (scrollEl) {
      const { top: y } = el.getBoundingClientRect()
      const { top, bottom } = scrollEl.getBoundingClientRect()
      if (y > bottom) {
        return y - bottom
      }
      if (y < (offset ? top + offset : top)) {
        return y - (offset ? top + offset : top)
      }
    }
    return 0
  }
  return null
}

export const stopPropagation = (e) => {
  if (e) {
    e.stopPropagation()
    e.preventDefault()
  }
}

export const generatePathSafe = (path: string, params?: any) => {
  try {
    if (isEmptyEx(params)) {
      return null
    }
    return generatePath(path, params)
  } catch (error) {
    errorLog('generatePath failed', path, params)
    return null
  }
}
export const generatePathSafeArr = (path: string[], params?: any) => {
  const validParamCount = Object.keys(params).filter((key) => params[key] != null).length
  return generatePathSafe(path[path.length - validParamCount], params)
}

export const setNativeValue = (element: HTMLInputElement, value: string) => {
  let lastValue = element.value
  element.value = value
  //@ts-ignore
  let event = new Event('input', { target: element, bubbles: true })
  // React 15
  // event.simulated = true
  // React 16
  //@ts-ignore
  let tracker = element._valueTracker
  if (tracker) {
    tracker.setValue(lastValue)
  }
  element.dispatchEvent(event)
}

export const getFilesFromDragEvent = (e: DragEvent) => {
  return (
    e &&
    ((e.dataTransfer?.items &&
      [...e.dataTransfer.items].filter((i) => i.kind === 'file').map((i) => i.getAsFile())) ||
      (e.dataTransfer?.files && [...e.dataTransfer.files]))
  )
}

export const checkFilesAccepted = (files: File[], accept: string) => {
  const extraxtExtention = (name: string) => name.split('.').pop()
  const acceptedTypes = accept?.toUpperCase().split(/\s*,\s*/)
  return (
    files &&
    acceptedTypes &&
    !(
      files.find(
        (file) =>
          acceptedTypes.indexOf('.' + extraxtExtention(file.name.toUpperCase())) != -1 ||
          (file.type && acceptedTypes.indexOf(file.type.toUpperCase()) != -1)
      ) == null
    )
  )
}
