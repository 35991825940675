import { AppPaths } from '@one/AppPaths'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { ModelAction } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { RowGrouper } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { FrameBody } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { useContext, useMemo } from 'react'
import {
  EkPreisImportUsecase,
  EkPreisListImportAssistentStepIANDuplikateArtikelEdit
} from './model/EkPreisImportUsecase'

export interface EkPreisImportPflegeStep4IANDuplikateProps {
  ianDuplikate?: EkPreisListImportAssistentStepIANDuplikateArtikelEdit[]
  ianDuplikateChanges?: Map<number, boolean>
  dispatch: (action: ModelAction) => void
}

export const EkPreisImportPflegeStep4IANDuplikate = ({
  ianDuplikate,
  ianDuplikateChanges,
  dispatch
}: EkPreisImportPflegeStep4IANDuplikateProps) => {
  const { isGesellschafter } = useContext(AppContext)

  const [selected, setSelected, getSelected] = useStateEx(
    new Set<EkPreisListImportAssistentStepIANDuplikateArtikelEdit>()
  )

  const columnProps = useMemo<
    ArtikelDefaultColumnsProps<EkPreisListImportAssistentStepIANDuplikateArtikelEdit>
  >(
    () => ({
      isGesellschafter: isGesellschafter,
      fieldWrapper: {
        wrapper: 'duplikat.artikel'
      },
      addColumnToIndex: [
        {
          beforeField: true,
          column: {
            field: 'ianAusDatei',
            header: 'IAN aus Datei'
          }
        },
        {
          beforeField: true,
          column: {
            field: 'eanAusDatei',
            header: 'EAN aus Datei'
          }
        },
        {
          beforeField: true,
          column: {
            field: 'zeilenNr',
            header: 'Zeilen-Nr.',
            type: 'number'
          }
        },
        {
          beforeField: true,
          column: {
            field: 'ok',
            header: 'Bepreisen?',
            align: 'center',
            body: (row: EkPreisListImportAssistentStepIANDuplikateArtikelEdit) => {
              const val = ianDuplikateChanges?.get(row.duplikat.id) ?? row.duplikat.bepreisen
              return (
                <Checkbox
                  size="small"
                  compact
                  checked={val}
                  onChange={(e) => {
                    dispatch({
                      type: EkPreisImportUsecase.SET_IANDUPLIKAT_BEPREISEN,
                      id: row.duplikat.id,
                      selected: getSelected(),
                      checked: e.target.checked
                    })
                    return false
                  }}
                />
              )
            }
          }
        }
      ]
    }),
    [dispatch, getSelected, ianDuplikateChanges, isGesellschafter]
  )

  const columns = useArtikelColumns(columnProps)

  const grouper = {
    field: 'grouperKey',
    body: (row) =>
      row.zuschnittIAN
        ? `Duplikate zu IAN ${row.zuschnittIAN}`
        : `Duplikate durch EAN ${row.eanAusDatei}`
  } as RowGrouper<EkPreisListImportAssistentStepIANDuplikateArtikelEdit>

  const bottomActions = (
    <>
      <Button
        label="Einschließen"
        disabled={selected.size === 0}
        onClick={() =>
          dispatch({
            type: EkPreisImportUsecase.SET_IANDUPLIKAT_BEPREISEN,
            selected,
            checked: true
          })
        }
      />
      <Button
        label="Ausschließen"
        disabled={selected.size === 0}
        onClick={() =>
          dispatch({
            type: EkPreisImportUsecase.SET_IANDUPLIKAT_BEPREISEN,
            selected,
            checked: false
          })
        }
      />
    </>
  )

  const tableActions = useMemo(
    (): DataTableAction<EkPreisListImportAssistentStepIANDuplikateArtikelEdit>[] => [
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data) => data.duplikat?.artikel && AppPaths.ArtikelFn(data.duplikat.artikel?.id)
      }
    ],
    []
  )

  return (
    <FrameBody>
      <DataTableCard
        name="Step4IANDuplikate"
        localStateName="Step4IANDuplikate"
        title="IAN Duplikate"
        paging
        selectMode="multi"
        selectCol
        columns={columns}
        value={ianDuplikate}
        onSelect={setSelected}
        groupBy={grouper}
        dense
        emptyMessage="Keine Duplikate gefunden"
        bottomActions={bottomActions}
        actions={tableActions}
      />
    </FrameBody>
  )
}
