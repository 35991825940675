import { LocalOffer } from '@mui/icons-material'
import { PreisEbeneDisplayJson, StandortJson } from '@one/typings/apiTypings'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'

export interface PreisEbeneDisplayExJson extends PreisEbeneDisplayJson {
  hatPreis?: boolean
}

export interface StandortFieldProps {
  options?: PreisEbeneDisplayExJson[] | StandortJson[]
  value?: any
  onChange?: (value: any) => void
  readonly?: boolean
  label?: string
  name?: string
  emptyText?: string
  fullWidth?: boolean
  withButtons?: boolean
  required?: boolean
  error?: string | boolean
  usedStandordIds?: Set<number>
}

export const StandortField = ({
  label,
  value,
  name,
  onChange,
  options,
  emptyText,
  withButtons,
  fullWidth = false,
  readonly = false,
  required = false,
  error,
  usedStandordIds
}: StandortFieldProps) => {
  const listeSafe = options || []

  const handleChange = (e) => {
    if (onChange) {
      onChange({ name, value: e.target.value })
    }
  }

  const renderItem = (opt: PreisEbeneDisplayExJson) => (
    <div
      style={{ display: 'flex', width: '100%', fontWeight: opt.hatPreis ? 'bold' : null }}
      title={opt.hatPreis ? 'Preis vorhanden' : null}
    >
      <div
        style={{
          width: '4em',
          textAlign: 'right',
          paddingRight: 4
        }}
      >{`${opt.nr}:`}</div>
      <div style={{ flexGrow: 1 }}>{opt.alias || opt.name}</div>
      {opt.hatPreis ? (
        <TooltipEx title="Standort hat Preise">
          <LocalOffer fontSize="small" color="info" />
        </TooltipEx>
      ) : null}
    </div>
  )

  return (
    <AutocompleteEx
      label={label}
      value={value}
      fullWidth={fullWidth}
      options={listeSafe}
      onChange={handleChange}
      required={required}
      disabled={readonly}
      optionValue="id"
      optionLabel={(opt) => `${opt.nr}: ${opt.alias || opt.name}`}
      filterItem={(opt) => usedStandordIds?.has(opt.id) || !opt.ausgeblendet}
      renderItem={renderItem}
      emptyText={emptyText}
      error={error}
      withButtons={withButtons}
    />
  )
}
