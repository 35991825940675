import { api } from '@one/api'
import {
  SparteDisplayJson,
  WarengruppeDisplayJson,
  WarengruppenListeJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useEffect, useState } from 'react'

const sortSpartenListe = (sparten: SparteDisplayJson[]): WarengruppeDisplayJson[] => {
  return sparten.sort((a, b) => {
    let aa = b.anzahlArtikel - a.anzahlArtikel
    if (aa === 0 && a.nr && b.nr) {
      aa = b.nr.localeCompare(a.nr, undefined, { numeric: true })
    }
    if (aa === 0 && a.bezeichnung && b.bezeichnung) {
      aa = b.bezeichnung.localeCompare(a.bezeichnung)
    }
    return aa
  })
}

const sortWarengruppenListe = (
  warengruppen: WarengruppeDisplayJson[]
): WarengruppeDisplayJson[] => {
  return warengruppen.sort((a, b) => {
    let aa = b.anzahlArtikel - a.anzahlArtikel
    if (aa === 0 && a.nummer && b.nummer) {
      aa = b.nummer.localeCompare(a.nummer, undefined, { numeric: true })
    }
    if (aa === 0 && a.name && b.name) {
      aa = b.name.localeCompare(a.name)
    }
    return aa
  })
}

export const useWarengruppeUndSparteListeGlobal = (): [
  WarengruppeDisplayJson[],
  SparteDisplayJson[],
  boolean
] => {
  const [data, setData] = useState<WarengruppenListeJson>(null)

  const [apiCall, apiBusy] = useApiCaller(api)

  useEffect(() => {
    apiCall<WarengruppenListeJson>({
      method: 'GET',
      rest: 'artikel/warengruppenUndSparten',

      params: { auchLeere: true },
      onSuccess: (data) => {
        data.warengruppen = sortWarengruppenListe(data.warengruppen)
        data.sparten = sortSpartenListe(data.sparten)
        setData(data)
      },
      onError: (error, response, duration) => {
        setData(null)
      }
    })
  }, [apiCall])

  return [data?.warengruppen || [], data?.sparten || [], apiBusy]
}

export const useWarengruppeUndSparteListeSelektion = (
  selektionId: number
): [WarengruppeDisplayJson[], SparteDisplayJson[], boolean] => {
  const [data, setData] = useState<WarengruppenListeJson>(null)

  const [apiCall, apiBusy] = useApiCaller(api)

  useEffect(() => {
    if (selektionId == null) {
      setData(null)
      return
    }

    apiCall<WarengruppenListeJson>({
      method: 'GET',
      rest: 'artikel/warengruppenUndSpartenAusSelektion',
      params: { selektionId },
      onSuccess: (data, response, duration) => {
        data.warengruppen = sortWarengruppenListe(data.warengruppen)
        data.sparten = sortSpartenListe(data.sparten)

        setData(data)
      },
      onError: (error, response, duration) => {
        setData(null)
      }
    })
  }, [apiCall, selektionId])

  return [data?.warengruppen || [], data?.sparten || [], apiBusy]
}
