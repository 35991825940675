/* eslint-disable no-plusplus */
import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { LieferantAbonniertStatusMedal } from '@one/components/common/LieferantAbonniertStatusMedal'
import { SyncEinstellungField } from '@one/components/common/SyncEinstellungField'
import { LieferantAbonniertStatus, LieferantDisplayJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { UpdModelFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { ThemeContext } from '@utils/ui/Theme'
import { TextField } from '@utils/ui/fields/TextField'
import { dataFromEvent } from '@utils/utils'
import { useContext, useEffect, useState } from 'react'
import { LieferantAboEdit } from './LieferantAboView'

export interface LieferantAboFormProps {
  model: LieferantAboEdit
  onChange: (e: any) => void
  updModel: UpdModelFn<LieferantAboEdit>
  errors?: any
  isNew?: boolean
  isNewLieferant?: boolean
}

export const LieferantAboForm = ({
  model,
  onChange,
  updModel,
  errors = {},
  isNew = false,
  isNewLieferant = false
}: LieferantAboFormProps) => {
  const [apiCall] = useApiCaller(api)
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)

  const [lastLieferantERPSugg, setLastLieferantERPSugg] = useState<LieferantDisplayJson>()
  const [defaultERPSugg, setDefaultERPSugg] = useState<LieferantDisplayJson[]>()

  const onLieferantChange = (e) => {
    const { name, value } = dataFromEvent(e)
    if (name) {
      updModel({ [name]: value, name: value?.name1 })
    }
  }

  useEffect(() => {
    if (
      (isNew || model.status === LieferantAbonniertStatus.UNBESTIMMT) &&
      lastLieferantERPSugg !== model.lieferant
    ) {
      setLastLieferantERPSugg(model.lieferant)
      if (model.lieferant == null) {
        setDefaultERPSugg(null)
      } else {
        apiCall<any>({
          method: 'GET',
          rest: 'lieferant/findMatchingDzLieferant',
          params: { zeLieferantId: model.lieferant.id },
          onSuccess: (data) => {
            setDefaultERPSugg(data.matches)
            if (data.connectedLieferant) {
              updModel((m) => ({
                ...m,
                dzLieferant: data.connectedLieferant,
                dzLieferantConnected: true
              }))
            } else if (data.matches.length === 1) {
              updModel((m) => ({
                ...m,
                dzLieferant: data.matches[0].lieferant,
                dzLieferantConnected: false
              }))
            } else if (model.dzLieferant || model.dzLieferantConnected) {
              updModel((m) => ({
                ...m,
                dzLieferant: null,
                dzLieferantConnected: false
              }))
            }
          }
        })
      }
    }
  }, [apiCall, isNew, lastLieferantERPSugg, model, onChange, updModel])

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.LieferantAboUebersicht}
      title={
        <Grid container spacing={1}>
          <Grid item>
            {isNew ? 'Lieferantenabo erstellen' : `Lieferantenabo ${model.name || ''}`}
          </Grid>
          <Grid item>
            <LieferantAbonniertStatusMedal
              value={model.status ?? LieferantAbonniertStatus.UNERWUENSCHT}
              darkMode={darkMode}
              et={et}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2} direction="row">
        <Grid item container spacing={2} direction="column" xs={12} md={7} lg={5}>
          <Grid item>
            <TextField
              name="name"
              label="Name"
              error={errors.name}
              value={model.name}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item>
            <LieferantField
              name="lieferant"
              label="Lieferant Zentral"
              error={errors.lieferant}
              value={model.lieferant}
              onChange={onLieferantChange}
              disabled={!isNew || isNewLieferant}
              fullWidth
              modus="zentral"
            />
          </Grid>
          <Grid item>
            <LieferantField
              name="dzLieferant"
              label="Lieferant ERP"
              error={errors.dzLieferant}
              value={model.dzLieferant}
              defaultSugg={defaultERPSugg}
              onChange={onChange}
              placeholder={model.lieferant == null ? undefined : 'Als neuen ERP Lieferant anlegen'}
              disabled={
                model.dzLieferantConnected ||
                (!isNew &&
                  !(
                    model.status === LieferantAbonniertStatus.UNBESTIMMT ||
                    model.status === LieferantAbonniertStatus.UNERWUENSCHT
                  ))
              }
              fullWidth
              modus="dezentral"
            />
          </Grid>
          {/* <Grid item>
            <StaticField
              label="Status"
              value={
                <LieferantAbonniertStatusMedal
                  value={model.status ?? LieferantAbonniertStatus.UNERWUENSCHT}
                  darkMode={darkMode}
                  et={et}
                />
              }
              fullWidth
            />
          </Grid> */}
          <Grid item>
            <SyncEinstellungField
              label="LiSST Einstellung"
              error={errors.lieferantSyncEinstList}
              options={model.lieferantSyncEinstList}
              name="lieferantSyncEinstId"
              value={model.lieferantSyncEinstId}
              onChange={onChange}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} lg={7}>
          <TextField
            label="Bemerkung"
            name="bemerkung"
            error={errors.bemerkung}
            multiline
            value={model.bemerkung}
            onChange={onChange}
            maxRows={12}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
