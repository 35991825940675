import { LibraryAdd } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { LieferantenMappingDialog } from '@one/components/LieferantenMapping/LieferantenMappingDialog'
import { SeverityCell } from '@one/components/common/SeverityCell'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  EkPreisListeImportDefinitionMappingType,
  EkPreisListeImportFehlerZeileJson,
  EkPreisListeImportJson,
  EkPreisListeImportStatus,
  LieferantDisplayTinyJson,
  MessageJson,
  MessageSeverity
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { flattenErrors } from '@utils/error'
import { Action } from '@utils/ui/Action'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { FrameBody, FrameRow } from '@utils/ui/Frame'
import { Permission } from '@utils/ui/Permission'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useMemo, useState } from 'react'
import { EkPreisImportZeileStatus } from './fields/EkPreisImportZeilenStatus'

export interface EkPreisImportPflegeStep2ValidateProps {
  status: EkPreisListeImportStatus
  fehlerZeilen: EkPreisListeImportFehlerZeileJson[]
  updModel: (fn: (model: EkPreisListeImportJson) => EkPreisListeImportJson) => void
  anzahlZeilen: number
  anzahlFehler: number
  globaleFehler: MessageJson[]
  lieferant?: LieferantDisplayTinyJson
}

export const EkPreisImportPflegeStep2Validate = ({
  status,
  fehlerZeilen,
  anzahlZeilen,
  anzahlFehler,
  globaleFehler,
  updModel,
  lieferant
}: EkPreisImportPflegeStep2ValidateProps) => {
  const { et } = useEnums()
  const [selected, setSelected] = useState<Set<EkPreisListeImportFehlerZeileJson>>(new Set())
  const [DlgAnker, showDlg] = useDialogAnker()

  const toggleIgnoreStatus = useCallback(
    (row: EkPreisListeImportFehlerZeileJson) => {
      updModel((model) => {
        const fehlerZeilen = model.fehlerZeilen.map((fehlerZeile) => {
          return {
            ...fehlerZeile,
            ignoriert: fehlerZeile.zeile === row.zeile ? !row.ignoriert : fehlerZeile.ignoriert
          }
        })
        return { ...model, fehlerZeilen }
      })
    },
    [updModel]
  )

  const hatIgnorierbareZeilenMarkiert = useMemo(() => {
    const list = Array.from(selected)
    return (
      list.find(
        (item) =>
          item.autoIgnoriert !== true &&
          item.severity !== MessageSeverity.INFO &&
          item.severity !== MessageSeverity.WARN
      ) != null
    )
  }, [selected])

  const handleIgnore = useCallback(
    (ignoriert: boolean) => () => {
      updModel((model) => {
        return {
          ...model,
          fehlerZeilen: model.fehlerZeilen
            .filter(
              (fehlerZeile) =>
                fehlerZeile.severity !== MessageSeverity.INFO &&
                fehlerZeile.severity !== MessageSeverity.WARN
            )
            .map((fehlerZeile) => {
              return {
                ...fehlerZeile,
                ignoriert: fehlerZeile.autoIgnoriert ? false : ignoriert
              }
            })
        }
      })
    },
    [updModel]
  )

  const columns = useMemo<Column<EkPreisListeImportFehlerZeileJson>[]>(
    () => [
      {
        field: 'zeile',
        header: 'Zeile',
        type: 'number'
      },
      {
        field: 'severity',
        header: 'Fehlerart',
        align: 'center',
        valueGetter: (row) => et(HkmEnum.MessageSeverity, row.severity),
        body: (row) => <SeverityCell value={row.severity} fullWidth />
      },
      {
        field: 'ignoriert',
        header: 'ignorieren',
        align: 'center',
        valueGetter: (row) => {
          return (
            (row.severity == MessageSeverity.WARN && 'Warnung') ||
            (row.severity == MessageSeverity.INFO && 'Information') ||
            (row.autoIgnoriert && 'Automatisch ignoriert') ||
            (row.ignoriert ? 'ignoriert' : 'nicht ignoriert')
          )
        },
        body: (row) => {
          if (row.severity == MessageSeverity.INFO || row.severity == MessageSeverity.WARN) {
            return null
          }
          return row.autoIgnoriert ? (
            <Typography fontSize="80%">Automatisch ignoriert</Typography>
          ) : (
            <Checkbox
              compact
              onChange={() => toggleIgnoreStatus(row)}
              checked={row.ignoriert || row.autoIgnoriert}
              disabled={
                row.autoIgnoriert ||
                status === EkPreisListeImportStatus.FEHLER ||
                status === EkPreisListeImportStatus.IMPORTIERT
              }
            />
          )
        }
      },
      {
        field: 'messages',
        header: 'Fehlermeldung',
        width: '100%',
        valueGetter: (row) => row?.messages?.map((message) => message.message),
        body: (row) => {
          const messages =
            row?.messages?.map((message, index) => {
              const hint = message?.hint
                ? `Spalte "${message.hint}": `
                : message?.spalte !== null
                  ? `Spalte ${message.spalte + 1}: `
                  : ''
              return (
                <div key={`${message.messageId.id}-${index}`}>{`${hint}${message.message}`}</div>
              )
            }) ?? null
          return messages
        }
      }
    ],
    [et, status, toggleIgnoreStatus]
  )

  const topActions = useMemo<Action[]>(
    () => [
      {
        text: 'Mengeneinheiten Mapping',
        link: AppPaths.LieferantenMengenMappingFn(lieferant?.nummer)
      }
    ],
    [lieferant?.nummer]
  )

  const bottomActions = useMemo(
    () =>
      [
        {
          tooltip: 'Ausgewählte Einträge ignorieren',
          text: 'Ignorieren',
          enabled: hatIgnorierbareZeilenMarkiert,
          onClick: handleIgnore(true)
        },
        {
          tooltip: 'Ignorieren aufheben für ausgewählte Einträge',
          text: 'Nicht ignorieren',
          enabled: hatIgnorierbareZeilenMarkiert,
          onClick: handleIgnore(false)
        }
      ] as Action[],
    [handleIgnore, hatIgnorierbareZeilenMarkiert]
  )

  const actions = useMemo(
    (): DataTableAction[] => [
      {
        icon: <LibraryAdd />,
        tooltip: 'Mengeneinheit Mapping ergänzen',
        check: (row) => (row.unbekannteME != null ? Permission.ENABLED : Permission.HIDDEN),
        direct: true,
        handler: (row) => {
          showDlg((visible, onClose) => (
            <LieferantenMappingDialog
              open={visible}
              onClose={onClose()}
              type={EkPreisListeImportDefinitionMappingType.MENGENEINHEIT}
              lieferantId={lieferant?.id}
              mappingId={null}
              defExtern={row.unbekannteME}
            />
          ))
        }
      }
    ],
    [lieferant?.id, showDlg]
  )

  const errors = useMemo(() => flattenErrors(globaleFehler), [globaleFehler])

  const onRowDoubleClick = (row: EkPreisListeImportFehlerZeileJson, e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    toggleIgnoreStatus(row)
  }

  const errorStyle = {
    backgroundColor: '#ff0000',
    padding: '4px',
    marginTop: '8px',
    color: 'white'
  }

  return (
    <>
      <FrameBody>
        <DataTableCard
          name="Step2Validate"
          localStateName="Step2Validate"
          filterMode="both"
          title="Fehlerhafte Zeilen"
          columns={columns}
          value={fehlerZeilen}
          dense
          paging
          selectCol
          selectMode="multi"
          initialOrderBy="zeilennummer"
          emptyMessage="Keine Fehler gefunden"
          wrapMode="normal"
          selected={selected}
          onSelect={setSelected}
          topActions={topActions}
          bottomActions={bottomActions}
          onRowDoubleClick={onRowDoubleClick}
          actions={actions}
        />
      </FrameBody>
      <FrameRow>
        <CardEx title="Validierungsübersicht">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EkPreisImportZeileStatus
                anzahlFehler={anzahlFehler}
                fehlerZeilen={fehlerZeilen}
                anzahlZeilen={anzahlZeilen}
              />
            </Grid>
            {errors?.length > 0 ? (
              <Grid item xs={12} alignItems="center" display="flex">
                <StaticField
                  label="Import-Fehler"
                  value={errors.map((fehler) => (
                    <div key={fehler.message}>{fehler.message}</div>
                  ))}
                  style={
                    EkPreisListeImportStatus.FEHLER === status ||
                    EkPreisListeImportStatus.INVALID === status
                      ? errorStyle
                      : null
                  }
                />
              </Grid>
            ) : null}
          </Grid>
        </CardEx>
      </FrameRow>
      <DlgAnker />
    </>
  )
}
