import { useContext, useMemo } from 'react'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import languageJson from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import languageXml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml'
import { docco, dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { makeStyles } from 'tss-react/mui'
import beautifyXml from 'xml-beautifier'
import { ScrollPanel } from './ScrollPanel'
import { ThemeContext } from './Theme'

export interface StructPanelProps {
  value: string | null
}

SyntaxHighlighter.registerLanguage('xml', languageXml)
SyntaxHighlighter.registerLanguage('json', languageJson)

const useStyles = makeStyles()((theme: any) => ({
  root: {
    overflow: 'unset!important',
    minWidth: 'fit-content',
    marginTop: 0,
    marginBottom: 0
  }
}))

export const StructPanel = ({ value }: StructPanelProps) => {
  const { classes } = useStyles()
  const { darkMode } = useContext(ThemeContext)

  const content = useMemo(() => {
    if (value == null) {
      return null
    }
    try {
      return JSON.stringify(JSON.parse(value), null, 2)
    } catch (error) {
      // scheint kein json...
    }
    try {
      return beautifyXml(value)
    } catch (error) {
      // scheint kein xml...
    }
    return value
  }, [value])

  return (
    content && (
      <ScrollPanel>
        <span>
          <SyntaxHighlighter className={classes.root} style={darkMode ? dracula : docco}>
            {content}
          </SyntaxHighlighter>
        </span>
      </ScrollPanel>
    )
  )
}
