import { Grid, Typography } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { VkPreisQuelle } from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { dataFromEvent } from '@utils/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import { makeStyles } from 'tss-react/mui'
import { VkPreisStaffelTable } from './VkPreisStaffelTable'
import { VkPreisEdit, VkPreisListeBlattEdit, emptyVkPreisBlatt } from './model/VkPreisTypes'
import { VKPreisUsecase } from './model/VkPreisUsecase'

// Styling definitions
const useStyles = makeStyles()({
  flexGrow: {
    flexGrow: 1
  },
  width100: {
    width: '100%'
  },
  noTopMargin: {
    marginTop: '-32px'
  },
  artikelRow: {
    borderBottom: '1px solid black'
  },
  artikel: {
    fontWeight: '100'
  },
  headline: {
    marginTop: '-12px',
    marginBottom: '-12px'
  },
  title: {
    flexGrow: 1
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export interface VkPreisStaffelProps {
  mengeneinheiten: any[]
  preisblatt?: VkPreisListeBlattEdit
  selectedPreis?: VkPreisEdit
  dispatch: (action: ModelAction) => void
  readonly?: boolean
  fullHeight?: boolean
  forEinzelhandel?: boolean
}

export const VkPreisStaffel = ({
  preisblatt,
  selectedPreis,
  mengeneinheiten,
  dispatch,
  readonly,
  forEinzelhandel,
  fullHeight
}: VkPreisStaffelProps) => {
  const { classes } = useStyles()

  const preisblattSafe = preisblatt || emptyVkPreisBlatt
  const preisGruppeId = selectedPreis && selectedPreis.preisGruppeId

  const readonlyTable = forEinzelhandel && preisblatt.quelleStaffel === VkPreisQuelle.LISTUNG

  const [preise, setPreise] = useState([])

  useEffect(() => {
    const staffelpreise = preisblattSafe.preise.filter(
      (p) => p.preisGruppeId === preisGruppeId && p.staffelmenge !== 0
    )
    if (!isEqual(staffelpreise, preise)) {
      setPreise(staffelpreise)
    }
  }, [preisGruppeId, preisblattSafe.preise, preise])

  const onAddStaffel = useCallback(() => {
    dispatch({
      type: VKPreisUsecase.ADDSTAFFEL,
      standortId: preisblatt.standortId,
      preis: selectedPreis
    })
  }, [dispatch, preisblatt.standortId, selectedPreis])

  const onRemoveStaffel = useCallback(
    (row: any) => {
      dispatch({
        type: VKPreisUsecase.REMOVESTAFFEL,
        standortId: preisblatt.standortId,
        preis: row
      })
    },
    [dispatch, preisblatt.standortId]
  )

  const onStaffelChange = useCallback(
    (model, name, value) => {
      dispatch({
        type: VKPreisUsecase.UPDATEPREISVALUE,
        standortId: preisblatt.standortId,
        preis: model,
        name,
        value
      })
    },
    [dispatch, preisblatt.standortId]
  )

  const setStaffelQuelle = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.SETQUELLESTAFFEL,
        standortId: preisblatt.standortId,
        value
      })
    },
    [dispatch, preisblatt.standortId]
  )

  const header = useMemo(
    () => (
      <Grid container alignItems="center">
        <Grid item className={classes.title}>
          <Typography variant="h6">
            Staffelpreise {selectedPreis ? `für ${selectedPreis.preisGruppeName}` : ''}
          </Typography>
        </Grid>
        {forEinzelhandel && selectedPreis && (
          <>
            <Grid item paddingRight={1}>
              <Typography variant="body2">Quelle:</Typography>
            </Grid>
            <Grid item md={12} lg={5}>
              <SelectEnumField
                name="quelleStaffel"
                value={preisblatt.quelleStaffel}
                onChange={setStaffelQuelle}
                disabled={readonly}
                fullWidth
                required
                enumType={HkmEnum.VkPreisQuelle}
                filter={(opt) => opt.id !== VkPreisQuelle.NEUKALKULATION}
              />
            </Grid>
          </>
        )}
      </Grid>
    ),
    [
      classes.title,
      forEinzelhandel,
      preisblatt.quelleStaffel,
      readonly,
      selectedPreis,
      setStaffelQuelle
    ]
  )

  const table = useMemo(
    () => (
      <VkPreisStaffelTable
        preise={preise}
        onStaffelChange={onStaffelChange}
        onRemoveStaffel={onRemoveStaffel}
        readonly={readonlyTable}
        mengeneinheiten={mengeneinheiten}
      />
    ),
    [mengeneinheiten, onRemoveStaffel, onStaffelChange, preise, readonlyTable]
  )

  return (
    <CardEx header={header} height="100%">
      {selectedPreis == null ? (
        <span>Keine Preisgruppe ausgewählt</span>
      ) : (
        <Frame fullHeight={fullHeight}>
          <FrameBody>{table}</FrameBody>
          <FrameRow className={classes.buttonRow}>
            {readonlyTable ? null : (
              <Button
                label="Neue Staffelzeile"
                variant="contained"
                size="small"
                onClick={onAddStaffel}
                style={{ marginTop: '8px' }}
              />
            )}
          </FrameRow>
        </Frame>
      )}
    </CardEx>
  )
}
