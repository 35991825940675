import { HkmEnum } from '@one/enums/HkmEnum'
import { VkPreisBasisTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SelectField } from '@utils/ui/fields/SelectField'

export interface VkPreisBasisFieldProps {
  model: any
  onChange: (model: any, field: any, value: any) => void
  name: string
  basisPreisgruppeName: string
  emptyText?: string
  ekFreiHaus?: number
  value?: any
  listenpreis?: number
  readonly?: boolean
  required?: boolean
  forcePreise?: boolean
}

export const VkPreisBasisField = ({
  value,
  name,
  onChange,
  model,
  basisPreisgruppeName,
  emptyText,
  ekFreiHaus,
  listenpreis,
  readonly = false,
  required = false,
  forcePreise = false
}: VkPreisBasisFieldProps) => {
  const { et, items } = useEnums()

  const rv = (name && model ? model[name] : value) || ''

  const options = items(HkmEnum.VkPreisBasisTyp)
    .filter((i) => i.id !== VkPreisBasisTyp.STAFFEL)
    .filter((i) => i.id !== VkPreisBasisTyp.PREISGRUPPE || basisPreisgruppeName != null)
    // .filter((i) => forcePreise || i.id !== VkPreisBasisTyp.LISTENPREIS || listenpreis != null)
    // .filter(
    //   (i) =>
    //     forcePreise ||
    //     i.id !== VkPreisBasisTyp.KALK_EK ||
    //     ekFreiHaus != null /*|| listenpreis != null*/
    // )
    .map((i) =>
      i.id !== VkPreisBasisTyp.PREISGRUPPE ? i : { ...i, text: <i>{basisPreisgruppeName}</i> }
    )

  const handleChange = (e) => {
    if (onChange) {
      onChange(model, e.target.name, e.target.value)
    }
  }

  const getText = (i) => {
    return i.text
  }

  if (readonly) {
    if (rv) {
      const opt = options.find((opt) => opt.id === rv)
      return <div>{opt ? opt.text : et(HkmEnum.VkPreisBasisTyp, rv)}</div>
    }
    return null
  }

  return (
    <SelectField
      value={rv}
      name={name}
      onChange={handleChange}
      disabled={readonly}
      renderItem={getText}
      options={options}
      required={required}
      emptyText={emptyText}
      fullWidth
      noClear
      size="small"
    />
  )
}
