import { useCallback, useImperativeHandle, useRef, useState } from 'react'

/**
 * Wie useState, zusätzlich mit State-Abruf ohne Event/Render-Triggering
 *
 * @param initialState Siehe useState
 * @returns Wie useState, zusätzlich aber getState, das eine immutable funtion ist
 */
export const useStateEx = <T = any>(
  initialState: T | (() => T)
): [T, param: React.Dispatch<React.SetStateAction<T>>, () => T] => {
  const [state, setState] = useState<T>(initialState)

  const stateRef = useRef<any>()
  useImperativeHandle(stateRef, () => state, [state])

  const getState = useCallback(() => <T>(stateRef.current && stateRef.current), [])

  return [state, setState, getState]
}
