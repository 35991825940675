import { Typography, TypographyProps } from '@mui/material'
import { formatNumber } from '@utils/numberutils'
import { isStringBlank } from '@utils/utils'
import clsx from 'clsx'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { FormField } from './FormField'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  field: {
    width: '100%',
    '& label': {
      whiteSpace: 'nowrap'
      // color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  text: {
    position: 'relative',
    marginTop: 16,
    minHeight: 32,
    paddingBottom: 4,
    paddingTop: 4,
    width: '100%',

    '&::after': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '" "',
      position: 'absolute',
      borderBottom: '1px dotted rgba(0, 0, 0, 0.42)'
    },
    '&>*': {
      minWidth: '11.8rem'
    }
  },
  nowrap: {
    '&>*': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  placeholder: {
    '&>*': {
      opacity: 0.7,
      userSelect: 'none'
    }
  }
})

export type StaticFieldProps = {
  id?: string
  label?: string
  name?: string
  value?: React.ReactNode
  variant?: TypographyProps['variant']
  formatter?: (value: any) => React.ReactNode
  children?: React.ReactNode
  fullWidth?: boolean
  style?: React.CSSProperties
  placeholder?: string
  wrap?: boolean
  formStyle?: React.CSSProperties
  align?: TypographyProps['align']
  empty?: boolean
  error?: boolean | string
  yesno?: boolean
}

export const StaticField = ({
  id,
  label,
  name,
  value,
  variant,
  formatter,
  children,
  fullWidth,
  style,
  placeholder,
  wrap,
  formStyle,
  align,
  empty,
  error,
  yesno
}: StaticFieldProps) => {
  const { classes } = useStyles()
  const placeholderEx = empty ? '---' : placeholder
  const ip = placeholderEx && (value == null || isStringBlank(value))
  return (
    <FormField
      id={id}
      label={label}
      className={classes.field}
      fullWidth={fullWidth}
      style={formStyle}
      error={error}
    >
      <div
        className={clsx(classes.text, !wrap && classes.nowrap, ip && classes.placeholder)}
        data-name={name}
      >
        {children || (React.isValidElement(value) && value) || (
          <Typography
            variant={variant}
            style={style}
            align={align}
            title={
              wrap
                ? null
                : (yesno && value != null && (value ? 'Ja' : 'Nein')) ||
                  (typeof value === 'string' && value) ||
                  (typeof value === 'number' && formatNumber(value))
            }
          >
            {ip ? (
              <em>{placeholderEx}</em>
            ) : (
              (formatter && formatter(value)) ||
              (yesno && value != null && (value ? 'Ja' : 'Nein')) ||
              value
            )}
          </Typography>
        )}
      </div>
    </FormField>
  )
}

export const StaticFieldRaw = ({ value, formatter, style, children }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.text} style={style}>
      {children || <Typography>{formatter ? formatter(value) : value || ''}</Typography>}
    </div>
  )
}
