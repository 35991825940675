import { EventNames } from '@one/EventNames'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { ArtikelLabelSearcherCriteriaJson, LabelJson } from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { PermissionUtil } from '@utils/ui/Permission'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { NewLabelDialog } from './NewLabelDialog'

export const LabelView = () => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const { askToDelete } = useMessageDialog()
  const [apiCall, apiBusy] = useApiCaller(api)
  const { checkUserRole } = useContext(AppContext)

  const { result, search } = useSearcherState<ArtikelLabelSearcherCriteriaJson, LabelJson>({
    api,
    url: '/artikel/label',
    doOpen: false
  })

  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteLabel = useCallback(
    (id: number) => {
      apiCall({
        method: 'POST',
        exclusive: ApiExclusive.BLOCK,
        rest: '/artikel/label/delete',
        onErrorMsg: 'Label konnten nicht gelöscht werden.',
        params: {
          id
        },
        onSuccess: () => {
          search()
        }
      })
    },
    [apiCall, search]
  )

  useObserver(EventNames.ARTIKELLABEL, () => {
    search()
  })

  const columns = useMemo<Column<LabelJson>[]>(
    () => [
      {
        field: 'system',
        header: 'Systemlabel',
        sortable: true
      },
      {
        field: 'kurz',
        header: 'Kurzbezeichnung'
      },
      {
        width: '100%',
        field: 'bezeichnung',
        header: 'Bezeichnung'
      }
    ],
    []
  )

  const onOpenEdit = useCallback(
    (label: LabelJson = null) => {
      showDlg((visible, onClose) => (
        <NewLabelDialog open={visible} onClose={onClose()} label={label} />
      ))
    },
    [showDlg]
  )

  const remove = useCallback(
    (label: LabelJson = null) =>
      () => {
        askToDelete({
          title: 'Soll das Label wirklich gelöscht werden?',
          onConfirm: () => {
            deleteLabel(label.id)
          }
        })
      },
    [askToDelete, deleteLabel]
  )

  const actions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Neues Label erstellen',
        icon: 'add',
        name: 'label-erstellen',
        onClick: (label: LabelJson) => {
          onOpenEdit()
        }
      },
      {
        tooltip: 'Aktualisieren',
        icon: 'refresh',
        name: 'label-aktualisieren',
        onClick: (label: LabelJson) => {
          search()
        }
      }
    ],
    [onOpenEdit, search]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        tooltip: 'Label bearbeiten',
        handler: (label: LabelJson) => () => {
          onOpenEdit(label)
        },
        check: (label: LabelJson) =>
          PermissionUtil.show(!label.system || checkUserRole(UserRoles.SYSLABEL_EDITOR))
      },
      {
        icon: 'delete',
        tooltip: 'Label löschen',
        handler: remove,
        check: (label: LabelJson) =>
          PermissionUtil.show(!label.system || checkUserRole(UserRoles.SYSLABEL_EDITOR))
      }
    ],
    [onOpenEdit, remove, checkUserRole]
  )

  return (
    <Frame space>
      <FrameBody>
        <DataTableCard
          backLink
          name="ArtikelLabelUebersichtTable"
          title="Artikel-Labels"
          topActions={actions}
          actions={tableActions}
          columns={columns}
          filterMode="both"
          localStateName="ArtikelLabelUebersichtTable"
          loading={apiBusy}
          dense
          emptyMessage={
            result.notFound
              ? 'Es sind keine Labels vorhanden. Legen sie mit + ein Label an'
              : undefined
          }
          result={result}
          initialOrderBy="kurz"
        />
      </FrameBody>
      <DlgAnker />
    </Frame>
  )
}
