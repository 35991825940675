// const useStyles = makeStyles()((theme: any) => ({}))

import { Link } from 'react-router-dom'

export interface AppLinkProps {
  path: string
  text: string
}

/**
 * Applikation-Link.
 *
 * @param path Pfad der Route
 * @param text Text des Links
 */
export const AppLink = ({ path, text }: AppLinkProps) => {
  // const { classes } = useStyles()

  return <Link to={path}>{text}</Link>
}
