import { Button } from '@utils/ui/Buttons/Button'

export const FreigabeButton = ({ isChanged, isNew, actionInfo, onClick }) => {
  const action = actionInfo || { visible: false }
  return !isChanged && !isNew && action.visible ? (
    <Button
      label="Preisliste freigeben"
      onClick={onClick}
      variant="text"
      color="primary"
      disabled={action.enabled === false}
      tooltip={action.info}
    />
  ) : null
}
