import { Grid } from '@mui/material'
import { useFormState } from '@utils/formstate'
import { errorLog } from '@utils/logging'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { SplitButton } from '@utils/ui/Buttons/SplitButton'
import { Column, FilterColumn, FilterType } from '@utils/ui/DataTable/DataTable'
import {
  buildFilterIndex,
  matchesRowContains,
  matchesRowExact,
  matchesRowIndex,
  matchesRowRange
} from '@utils/ui/DataTable/DataTableUtils'
import { DialogEx } from '@utils/ui/DialogEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RadioGroupItem } from '@utils/ui/fields/RadioGroup'
import { SelectField } from '@utils/ui/fields/SelectField'
import { TextField } from '@utils/ui/fields/TextField'
import { useMemo } from 'react'
import { IndexField } from './IndexField'

export interface ColumnFilterDialogProps {
  open: boolean
  onClose: (fn?: () => void) => () => void
  column: Column
  getRows: () => any[]
  filterIndex: number
  filterState: FilterColumn
  setFilterState: (state: FilterColumn | false) => void
}

const empty = {} as FilterColumn

export const ColumnFilterDialog = ({
  open,
  onClose,
  column,
  getRows,
  filterState,
  filterIndex,
  setFilterState
}: ColumnFilterDialogProps) => {
  const [state, onChange, getState] = useFormState<FilterColumn>(() => {
    if (filterState) {
      return filterState
    }

    return { key: column.key, type: 'index' }
  })

  const buildMatcher = (): ((row: any, col: Column<any>) => boolean) => {
    switch (state.type) {
      case 'index':
        return (row, col) => matchesRowIndex(row, col, state.index)
      case 'contains': {
        const vf = typeof state.value === 'string' ? state.value.toLocaleUpperCase() : state.value
        return (row, col) => matchesRowContains(row, col, vf)
      }
      case 'exact': {
        const vf = typeof state.value === 'string' ? state.value.toLocaleUpperCase() : state.value
        return (row, col) => matchesRowExact(row, col, vf)
      }
      case 'range':
        return (row, col) => matchesRowRange(row, col, state.value)
      default:
        errorLog('Unbekannter type', state.type)
        return () => true
    }
  }

  const actions = (
    <ButtonRow>
      <SplitButton
        actions={[
          {
            text: 'Löschen',
            onClick: onClose(() => {
              setFilterState(null)
            })
          },
          {
            text: 'Alle Filter löschen',
            onClick: onClose(() => {
              setFilterState(false)
            })
          }
        ]}
        variant="contained"
      />
      <Button
        label="Anwenden"
        onClick={onClose(() => {
          if (state.value == null || state.value.length == 0) {
            setFilterState(null)
          } else {
            setFilterState({
              ...state,
              matches: buildMatcher()
            })
          }
        })}
        disabled={filterState === state}
        variant="contained"
      />
      <Button label="Abbrechen" onClick={onClose()} variant="contained" />
    </ButtonRow>
  )

  const onChangeType = (e: any) => {
    onChange({ name: 'value', index: null, value: null } as Partial<FilterColumn>)
    onChange(e)
  }

  const options = useMemo(() => {
    const isNumber = ['money2', 'number'].indexOf(column.type) !== -1
    const isDate = ['date', 'datetime'].indexOf(column.type) !== -1
    const isString = column.type == null || column.type === 'string'
    return (
      [
        { label: 'Indexsuche', value: 'index' },
        isString && { label: 'Exakte Suche', value: 'exact' },
        isString && { label: 'Enthalten Suche', value: 'contains' },
        (isDate || isNumber) && { label: 'Bereich', value: 'range' }
      ] as RadioGroupItem<FilterType>[]
    ).filter(Boolean)
  }, [column.type])

  const indexData = useMemo(() => {
    if (state.type === 'index') {
      return buildFilterIndex(getRows(), column.type, column.valueGetter)
    }
  }, [column, getRows, state.type])

  const filterBody = useMemo(() => {
    switch (state.type) {
      case 'index':
        return (
          <Grid item width="100%">
            <IndexField
              open
              label="Indexauswahl"
              index={indexData}
              filter={state}
              type={column.type}
              align={column.align}
              setFilter={(f) => {
                onChange((o) => ({
                  ...o,
                  ...f
                }))
              }}
            />
          </Grid>
        )
      case 'exact':
      case 'contains':
        if (column.type === 'money2') {
          return (
            <Grid item flexGrow={1}>
              <NumberField
                value={state.value}
                name="value"
                onChange={onChange}
                fullWidth
                fraction={2}
                thousandSeparator
              />
            </Grid>
          )
        } else if (column.type === 'number') {
          return (
            <Grid item flexGrow={1}>
              <NumberField
                value={state.value}
                name="value"
                onChange={onChange}
                fullWidth
                fraction={0}
              />
            </Grid>
          )
        } else if (column.type === 'date') {
          return (
            <Grid item flexGrow={1}>
              <DateField value={state.value} name="value" onChange={onChange} fullWidth />
            </Grid>
          )
        } else if (column.type === 'boolean') {
          return (
            <Grid item flexGrow={1}>
              <Checkbox checked={state.value} name="value" onChange={onChange} indeterminate />
            </Grid>
          )
        } else {
          return (
            <Grid item flexGrow={1}>
              <TextField value={state.value} name="value" onChange={onChange} fullWidth />
            </Grid>
          )
        }
      case 'range':
        if (column.type === 'money2') {
          return (
            <>
              <Grid item>
                <NumberField
                  label="von"
                  value={state.value?.von}
                  name="value.von"
                  onChange={onChange}
                  fullWidth
                  fraction={2}
                  thousandSeparator
                />
              </Grid>
              <Grid item>
                <NumberField
                  label="bis"
                  value={state.value?.bis}
                  name="value.bis"
                  onChange={onChange}
                  fullWidth
                  fraction={2}
                  thousandSeparator
                />
              </Grid>
            </>
          )
        } else if (column.type === 'date') {
          return (
            <>
              <Grid item>
                <DateField
                  label="von"
                  value={state.value?.von}
                  name="value.von"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <DateField
                  label="bis"
                  value={state.value?.bis}
                  name="value.bis"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
            </>
          )
        } else {
          return (
            <>
              <Grid item>
                <TextField
                  label="von"
                  value={state.value?.von}
                  name="value.von"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  label="bis"
                  value={state.value?.bis}
                  name="value.bis"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
            </>
          )
        }
      default:
        return null
    }
  }, [column.align, column.type, indexData, onChange, state])

  return (
    <DialogEx
      title={
        <span>
          Filter {filterIndex} für Spalte {column.header}
        </span>
      }
      open={open}
      onClose={onClose()}
      actions={actions}
      maxWidth="xs"
      fullWidth
      height="60vh"
    >
      <Grid container direction="column" padding={2} spacing={2}>
        <Grid item>
          <SelectField
            label="Suchart"
            name="type"
            value={state.type}
            onChange={onChangeType}
            options={options}
            optionValue="value"
            optionText="label"
            required
            fullWidth
          />
        </Grid>
        {filterBody}
      </Grid>
    </DialogEx>
  )
}
