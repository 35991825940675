import { SeverityCell } from '@one/components/common/SeverityCell'
import { HkmEnum } from '@one/enums/HkmEnum'
import { MessageJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { flattenErrors } from '@utils/error'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface FehlermeldungenTableProps {
  title: string
  values: MessageJson[]
  loading: boolean
}

export const FehlermeldungenTable = ({ title, values, loading }: FehlermeldungenTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column<MessageJson>[]>(
    () => [
      {
        field: 'message',
        header: 'Meldung',
        sortable: true,
        width: '100%'
      },
      {
        field: 'severity',
        header: 'Schwere',
        sortable: true,
        valueGetter: (row) => et(HkmEnum.MessageSeverity, row.severity),
        body: (row) => <SeverityCell value={row.severity} />
      }
    ],
    [et]
  )

  const errors = useMemo(() => flattenErrors(values, true), [values])

  return (
    <DataTableCard
      backLink
      name="ValidateFehlerTable"
      title={title}
      filterMode="both"
      localStateName="ValidateFehlerTable"
      value={errors}
      columns={columns}
      loading={loading}
      dense
    />
  )
}
