import { Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'

export interface ArtikelListeSuchFormProps {
  searchValues: any
  onChange: (e?: any) => void
}

export const ArtikelListeSuchForm = ({ searchValues, onChange }: ArtikelListeSuchFormProps) => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <SelectEnumField
        fullWidth
        onChange={onChange}
        name="typ"
        label="Typ"
        value={searchValues.typ}
        enumType={HkmEnum.ArtikelListeTyp}
        emptyText="Alle"
      />
    </Grid>
    <Grid item xs={6}>
      <TextField fullWidth onChange={onChange} name="name" label="Name" value={searchValues.name} />
    </Grid>
  </Grid>
)
