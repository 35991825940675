import { Typography } from '@mui/material'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  MapperBearbeitenJson,
  MapperEinstellungJson,
  MapperUebersichtSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace } from '@utils/utils'
import { useCallback, useMemo } from 'react'
import { ArtikelLieferantSyncTemplateUebersichtParams } from './ArtikelLieferantSyncTemplateUebersichtParams'
import { ArtikelLieferantSyncTemplateUebersichtTable } from './ArtikelLieferantSyncTemplateUebersichtTable'

export interface ArtikelLieferantSyncTemplateUebersichtViewProps {
  isAdminMode?: boolean
}

export const ArtikelLieferantSyncTemplateUebersichtView = ({
  isAdminMode = false
}: ArtikelLieferantSyncTemplateUebersichtViewProps) => {
  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    MapperUebersichtSearcherCriteriaJson,
    MapperEinstellungJson
  >({
    api,
    url: '/mapper',
    initialParams: {
      name: undefined,
      typ: undefined
    }
  })

  const { askToDelete } = useMessageDialog()

  const label = useMemo(() => (isAdminMode ? 'ArSST' : 'LiSST/ArSST'), [isAdminMode])

  const [apiCall, apiBusy] = useApiCaller(api)

  useObserver([EventNames.ARSST, EventNames.LISST], () => {
    search()
  })

  const onDelete = useCallback(
    (row: MapperEinstellungJson) => () => {
      askToDelete({
        title: `Soll die ${label} Einstellung "${row.name}" wirklich gelöscht werden?`,
        onConfirm: () => {
          apiCall({
            method: 'POST',
            rest: '/mapper/delete',
            params: { typ: row.typ, id: row.id },
            onErrorMsg: `Die ${label} Einstellung "${row.name}" konnte nicht gelöscht werden.`,
            onSuccessMsg: `Die ${label} Einstellung "${row.name}" wurde gelöscht.`,
            onSuccess: () => {
              setResult(arrayItemReplace(result.rows, (r) => r.id === row.id, null))
            }
          })
        }
      })
    },
    [askToDelete, label, apiCall, setResult, result.rows]
  )

  const onStandard = useCallback(
    (row: MapperEinstellungJson) => () => {
      apiCall<MapperBearbeitenJson>({
        method: 'POST',
        rest: '/mapper/setstandard',
        params: { typ: row.typ, id: row.id },
        onErrorMsg: `Die ${label} Einstellung "${row.name}" konnte nicht als Standard gesetzt werden.`,
        onSuccess: (data) => {
          setResult(
            result.rows.map((r) => {
              if (r.id === row.id) {
                return data.einstellung
              }
              if (r.standardFH && r.typ === data.einstellung.typ) {
                return { ...r, standard: false }
              }
              return r
            })
          )
        }
      })
    },
    [apiCall, label, setResult, result.rows]
  )
  return (
    <Frame space>
      <FrameRow>
        <ArtikelLieferantSyncTemplateUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
          label={
            isAdminMode
              ? 'Vordefinierte Artikelstammdaten-Synchronisationstemplates (ArSST)'
              : 'Lieferanten- und Artikelstammdaten-Synchronisationstemplates (LiSST/ArSST)'
          }
          subLabel={
            isAdminMode && (
              <Typography color="error">
                Achtung: Zentrale Vorgaben - Änderungen wirken sich auf alle Gesellschafter aus!
              </Typography>
            )
          }
          isAdminMode={isAdminMode}
        />
      </FrameRow>
      <FrameBody>
        <ArtikelLieferantSyncTemplateUebersichtTable
          result={result}
          onDelete={onDelete}
          onStandard={onStandard}
          label={label}
          isAdminMode={isAdminMode}
        />
      </FrameBody>
    </Frame>
  )
}
