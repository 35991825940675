import { ExportErrorToErpLieferantJson } from '@one/typings/apiTypings'
import { Column } from '@utils/ui/DataTable/DataTable'
import { useMemo } from 'react'
import { ExportFehlerView } from './ExportFehlerView'

export const ExportLieferantFehlerUebersichtView = () => {
  const columns = useMemo<Column<ExportErrorToErpLieferantJson>[]>(
    () => [
      {
        field: 'lieferant.name1',
        header: 'Name 1'
      },
      {
        field: 'lieferant.name2',
        header: 'Name 2'
      },
      {
        field: 'lieferant.nummer',
        header: 'Nummer'
      },
      {
        field: 'lieferant.iln',
        header: 'ILN'
      }
    ],
    []
  )

  return (
    <ExportFehlerView
      columns={columns}
      title="Lieferanten Exportfehler"
      rest="export/lieferant"
      name="EKLieferantExportFehlerTable"
    />
  )
}
