import { AppPaths } from '@one/AppPaths'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelListeDisplayJson, ArtikelListeTyp } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { PermissionUtil } from '@utils/ui/Permission'
import { useMemo } from 'react'

export interface ArtikelListeUebersichtTableProps {
  result: SearcherResultType<ArtikelListeDisplayJson>
}

export const ArtikelListeUebersichtTable = ({ result }: ArtikelListeUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column<ArtikelListeDisplayJson>[]>(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: 'typ',
        header: 'Typ',
        body: (row: any) => et(HkmEnum.ArtikelListeTyp, row.typ)
      },
      {
        field: 'artikelAnzahl',
        header: 'Anzahl der Artikel',
        type: 'number'
      }
    ],
    [et]
  )

  // Button actions for each table row
  const tableActions = useMemo(
    (): DataTableAction<ArtikelListeDisplayJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Artikelliste bearbeiten',
        check: (data) => PermissionUtil.show(data.typ === ArtikelListeTyp.MANUAL),
        getLink: (data) => AppPaths.artikelliste.ArtikelListePflegeFn(data.id)
      },
      {
        icon: 'visibility',
        tooltip: 'Artikelliste anzeigen',
        check: (data) => PermissionUtil.show(data.typ !== ArtikelListeTyp.MANUAL),
        getLink: (data) => AppPaths.artikelliste.ArtikelListePflegeFn(data.id)
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikelselektion öffnen',
        check: (data) =>
          PermissionUtil.show(
            [ArtikelListeTyp.ARTIKEL_SELEKTION, ArtikelListeTyp.ABO].includes(data.typ)
          ),
        getLink: (data) =>
          ArtikelListeTyp.ABO === data.typ
            ? AppPaths.ArtikelAboPflegeFn(data.ownerId)
            : AppPaths.ArtikelAboSelektionPflegeFn(data.ownerId)
      }
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Artikelliste erstellen',
        navlink: AppPaths.artikelliste.ArtikelListePflegeFn('neu'),
        icon: 'add'
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="ArtikelListeUebersichtTable"
      title="Suchergebnis"
      filterMode="both"
      localStateName="ArtikelListeUebersichtTable"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      dense
      initialOrderBy="name"
      rowFiller
      result={result}
    />
  )
}
