import { Grid2 as Grid, Typography } from '@mui/material'
import { api } from '@one/api'
import { CompositeAttributeChangeTreeView } from '@one/components/common/CompositeAttributeChangeTreeView'
import { EkPreisChangeTreeView } from '@one/components/common/EkPreisChangeTreeView'
import { SeverityCell } from '@one/components/common/SeverityCell'
import { VkPreisChangeTreeView } from '@one/components/common/VkPreisChangeTreeView'
import { cleanUpCompositeAttributeChange } from '@one/components/common/utils'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelSelektionDetailsJson,
  ArtikelSelektionEintragChangeJson,
  EkKonditionenDefinitionJson,
  MessageJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { ModelAction } from '@utils/modelmgr'
import { useResolveCache } from '@utils/resolveCache'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { isEmpty } from '@utils/utils'
import React, { useMemo } from 'react'
import { ArtikelSelektionDetailsErrorAction } from '@one/components/ArtikelSelektion/ArtikelSelektionDetailsErrorAction'

export interface ArtikelAboSelektionDetailsProps {
  eintragId?: number
  dispatch: (changes: ModelAction) => void
  ergebnisseChanges?: Map<number, ArtikelSelektionEintragChangeJson>
  resetToggle?: any
  konditionDefinition?: EkKonditionenDefinitionJson
  zubestaetigenHints?: Map<number, string>
}

export const ArtikelAboSelektionDetails = ({
  eintragId,
  dispatch,
  ergebnisseChanges,
  resetToggle = null,
  konditionDefinition,
  zubestaetigenHints
}: ArtikelAboSelektionDetailsProps) => {
  const { et } = useEnums()

  // const { allianzMitglied } = useContext(AppContext)

  const [artikelDetails, resolving, error] = useResolveCache<ArtikelSelektionDetailsJson>({
    api,
    resetToggle,
    dataId: eintragId,
    paramName: 'artikelSelektionEintragId',
    rest: '/artikelselektion/artikelDetails'
  })

  // const konflikt = artikelDetails?.aenderungsTyp === ArtikelAenderungTyp.KONFLIKT_DZ
  // const labelAlt = /*(konflikt && 'ERP:') ||*/ (allianzMitglied && 'ALZ:') || 'LST:'

  const loadError = useMemo(
    () =>
      error && (
        <Grid flex={1} width="100%">
          <CardEx title="Fehler" height="100%">
            <Typography>{error.message}</Typography>
          </CardEx>
        </Grid>
      ),
    [error]
  )

  const messages = useMemo(() => {
    const errorColumns: Column<MessageJson>[] = [
      {
        field: 'severity',
        header: 'Schweregrad',
        valueGetter: (row) => et(HkmEnum.MessageSeverity, row.severity),
        body: (row) => <SeverityCell value={row.severity} />
      },
      {
        field: 'message',
        header: 'Meldung',
        body: (row) => (
          <>
            <Grid container direction="column">
              <Grid>{row.message}</Grid>
              <Grid>
                <ArtikelSelektionDetailsErrorAction
                  eintragId={eintragId}
                  message={row}
                  changes={ergebnisseChanges.get(eintragId)}
                  dispatch={dispatch}
                />
              </Grid>
            </Grid>
          </>
        )
      }
    ]

    return error || isEmpty(artikelDetails?.messages) ? null : (
      <Grid flex={1} width="100%">
        <DataTableCard
          title="Aktualisierungsfehler"
          // height="100%"
          name="AktualisierungsfehlerTable"
          columns={errorColumns}
          value={artikelDetails?.messages}
          wrapMode="normal"
          emptyMessage="Keine Fehler gefunden"
          dense
        />
      </Grid>
    )
  }, [artikelDetails?.messages, dispatch, eintragId, ergebnisseChanges, error, et])

  const konflikte = useMemo(() => {
    const konflikte = cleanUpCompositeAttributeChange(artikelDetails?.konflikte, false)
    if (!error && konflikte) {
      return (
        <Grid flex={1} width="100%">
          <CardEx title="Attributänderungen" height="100%">
            <CompositeAttributeChangeTreeView
              composite={konflikte}
              labelAlt="LST:"
              labelNeu="ERP:"
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.konflikte, error])

  const konflikteArrstFolgestufe = useMemo(() => {
    const konflikte = cleanUpCompositeAttributeChange(artikelDetails?.konflikte, true)
    if (!error && konflikte) {
      return (
        <Grid flex={1} width="100%">
          <CardEx title="Attributänderungen in ArSST Folgestufe" height="100%">
            <CompositeAttributeChangeTreeView
              composite={konflikte}
              zusatz
              labelAlt="LST:"
              labelNeu="ERP:"
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.konflikte, error])

  const listenpreise = useMemo(() => {
    if (!error && !isEmpty(artikelDetails?.listenpreisChanges)) {
      return (
        <Grid flex={1} width="100%">
          <CardEx title="Listenpreisänderungen" height="100%">
            <EkPreisChangeTreeView
              konditionDefinition={konditionDefinition}
              preisChanges={artikelDetails?.listenpreisChanges}
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.listenpreisChanges, error, konditionDefinition])

  const ekPreise = useMemo(() => {
    if (!error && !isEmpty(artikelDetails?.ekPreisChanges)) {
      return (
        <Grid flex={1} width="100%">
          <CardEx title="EK-Preisänderungen" height="100%">
            <EkPreisChangeTreeView
              konditionDefinition={konditionDefinition}
              preisChanges={artikelDetails?.ekPreisChanges}
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.ekPreisChanges, error, konditionDefinition])

  const vkPreise = useMemo(() => {
    if (
      !error &&
      !isEmpty(artikelDetails?.vkPreisChanges) &&
      !isEmpty(artikelDetails?.preisGruppen)
    ) {
      return (
        <Grid flex={1} width="100%">
          <CardEx title="VK-Preisänderungen" height="100%">
            <VkPreisChangeTreeView
              preisGruppen={artikelDetails.preisGruppen}
              preisChanges={artikelDetails.vkPreisChanges}
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.preisGruppen, artikelDetails?.vkPreisChanges, error])

  const empty = useMemo(() => {
    if (resolving) {
      return null
    }
    if (loadError == null && messages == null && konflikte == null && listenpreise == null) {
      return (
        <Grid flex={1} width="100%">
          <CardEx title="Artikeldetails" height="100%">
            <Typography variant="subtitle1">Keine Details verfügbar.</Typography>
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [messages, konflikte, listenpreise, loadError, resolving])

  return (
    <ScrollPanel height="100%">
      <WaitPlane wait={resolving}>
        <Grid container height="100%" spacing={1} direction="column" flexWrap="nowrap">
          {loadError}
          {messages}
          {konflikte}
          {konflikteArrstFolgestufe}
          {listenpreise}
          {ekPreise}
          {vkPreise}
          {empty}
        </Grid>
      </WaitPlane>
    </ScrollPanel>
  )
}
