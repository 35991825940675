import { Remove } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { StaffelField } from '@one/components/EkPreis/EKPreisPflege/fields/StaffelField'
import {
  EkKonditionenRabattgruppeVersionJson,
  EkRabattgruppenBearbeitenJson
} from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { formatDateRange } from '@utils/dateutils'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Column, VerticalAlignType } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { PermissionUtil } from '@utils/ui/Permission'
import { aidOf, singleFromSet } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'

export interface EkRabattgruppeVersionTableProps {
  model: EkRabattgruppenBearbeitenJson
  onAddVersion: () => void
  onRemoveVersion: (row: EkKonditionenRabattgruppeVersionJson) => void
  onStaffelChange: (staffel: any) => void
  onStaffelGruppenChange: (staffelgruppeId: any) => void
  checkStaffelwerte: () => Map<number, boolean>
  staffelmengen: number[]
  currentVersion: EkKonditionenRabattgruppeVersionJson
  setVersionIndex: (index: number) => void
  getPreisKategorieName: (preisEbeneId: number | null) => string
}

export const EkRabattgruppeVersionTable = ({
  model,
  onAddVersion,
  onRemoveVersion,
  onStaffelChange,
  checkStaffelwerte,
  staffelmengen,
  currentVersion,
  setVersionIndex,
  getPreisKategorieName
}: EkRabattgruppeVersionTableProps) => {
  const { isAllianz } = useContext(AppContext)

  const { replaceHistory } = useContext(AppRouteCtx)

  const isFlexStaffelGruppe = useMemo(() => {
    if (currentVersion) {
      const staffelgruppe = model.staffelgruppen.find(
        (s) => s.id === currentVersion.staffelgruppeId
      )
      return staffelgruppe?.flex || false
    }
    return false
  }, [model, currentVersion])

  const columns = useMemo<Column<EkKonditionenRabattgruppeVersionJson>[]>(() => {
    const columns = [
      {
        field: 'preisEbeneId',
        header: 'Preisebene',
        valign: 'top' as VerticalAlignType,
        off: !isAllianz,
        width: '30%',
        valueGetter: (row) => getPreisKategorieName(row.preisEbeneId),
        sortable: false
      },
      {
        field: 'gueltigVon',
        header: 'Gültig ab',
        off: isAllianz,
        type: 'date',
        sortable: false
      },
      {
        field: 'gueltigBis',
        header: 'Gültig bis',
        off: isAllianz,
        type: 'date',
        sortable: false
      },
      {
        field: 'staffelgruppe',
        header: 'Staffelgruppe',
        sortable: false,
        width: '100%',
        valueGetter: (row) =>
          row.staffelgruppeId == null
            ? null
            : model.staffelgruppen.find((sg) => sg.id === row.staffelgruppeId)?.name,
        body: (row) =>
          row.staffelgruppeId == null ? (
            <i>Keine</i>
          ) : (
            model.staffelgruppen.find((sg) => sg.id === row.staffelgruppeId)?.name
          )
      }
    ] as Column<EkKonditionenRabattgruppeVersionJson>[]
    return columns.filter(Boolean)
  }, [isAllianz, getPreisKategorieName, model.staffelgruppen])

  const onSelectVersion = useCallback(
    (data: Set<any>) => {
      const selected = singleFromSet(data)
      if (selected) {
        const versionen = model.rabattgruppe?.versionen ?? []
        const index = versionen.findIndex((v) => aidOf(v) === aidOf(selected))
        setVersionIndex(index)
        if (model.rabattgruppe?.kopf?.id) {
          replaceHistory(() =>
            AppPaths.EkRabattgruppenPflegeFn({
              id: model.rabattgruppe.kopf.id,
              versionId: selected.id
            })
          )
        }
      } else {
        setVersionIndex(null)
        if (model.rabattgruppe?.kopf?.id) {
          replaceHistory(() =>
            AppPaths.EkRabattgruppenPflegeFn({
              id: model.rabattgruppe.kopf.id,
              versionId: null
            })
          )
        }
      }
    },
    [model.rabattgruppe, replaceHistory, setVersionIndex]
  )

  const selected = useMemo(() => {
    return new Set([currentVersion])
  }, [currentVersion])

  const topActions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Neue Rabattgruppen-Version erstellen',
        text: 'Version',
        icon: 'add',
        onClick: onAddVersion
      }
    ],
    [onAddVersion]
  )

  const bottomActions = (
    <StaffelField
      readonly={!isFlexStaffelGruppe || currentVersion?.id != null}
      buttonMode
      werte={staffelmengen}
      onWerteChange={onStaffelChange}
      checkStaffelwerte={checkStaffelwerte}
    />
  )

  const tableActions = [
    {
      icon: <Remove />,
      check: (row) => PermissionUtil.show(row.id == null),
      direct: true,
      tooltip: 'Löschen',
      handler: onRemoveVersion
    }
  ] as DataTableAction<EkKonditionenRabattgruppeVersionJson>[]

  return (
    <DataTableCard
      title="EK-Rabattgruppen Versionen"
      name="EkRabattgruppenPflegeVersionTable"
      actions={tableActions}
      topActions={topActions}
      bottomActions={bottomActions}
      columns={columns}
      selected={selected}
      value={model.rabattgruppe?.versionen ?? []}
      dense
      selectMode="single"
      onSelect={onSelectVersion}
      initialOrderBy={['gueltigVon', 'preisEbeneId']}
      groupBy={
        isAllianz && {
          field: 'gueltigVon',
          body: (row) => formatDateRange(row.gueltigVon, row.gueltigBis)
        }
      }
    />
  )
}
