import { MapperBearbeitenJson, MapperEinstellungElementJson } from '@one/typings/apiTypings'

export interface MapperBearbeitenEdit extends MapperBearbeitenJson {
  groupMap?: Map<string, boolean>
}

const groupMap = (elemente: MapperEinstellungElementJson[]) =>
  new Map<string, boolean>(
    elemente
      .filter((el) => el.groupName && el.needMap && el.optional)
      .map((el) => [el.groupName, true])
  )

export const updateModel = (model: MapperBearbeitenEdit, value: boolean): MapperBearbeitenEdit => {
  const { elemente } = model

  const elementeCopy = elemente.map((element) => {
    if (element.optional) {
      return {
        ...element,
        needMap: value
      }
    }
    return { ...element }
  })
  return { ...model, elemente: elementeCopy, groupMap: groupMap(elementeCopy) }
}

export const updateModelGroup = (
  model: MapperBearbeitenEdit,
  groupName: string
): MapperBearbeitenEdit => {
  const { elemente, groupMap } = model
  const needMap = !groupMap.get(groupName)
  const elementeCopy = elemente.map((element) => {
    if (element.optional && element.groupName === groupName) {
      return {
        ...element,
        needMap
      }
    }
    return { ...element }
  })
  const groupMapCopy = new Map(groupMap)
  groupMapCopy.set(groupName, needMap)
  return { ...model, elemente: elementeCopy, groupMap: groupMapCopy }
}

export const editMutator = (data: MapperBearbeitenJson): MapperBearbeitenEdit => {
  return {
    ...data,
    groupMap: groupMap(data.elemente)
  }
}

export const saveMutator = (model: MapperBearbeitenEdit): MapperBearbeitenJson => {
  const { groupMap, ...data } = model
  return data
}
