import { AppPaths } from '@one/AppPaths'
import { SuchLieferantTyp } from '@one/typings/apiTypings'

export const getLieferantLink = (type: SuchLieferantTyp, id: number) => {
  switch (type) {
    case SuchLieferantTyp.DEZENTRAL:
      return AppPaths.LieferantFn(id)
    case SuchLieferantTyp.ZENTRAL:
      return AppPaths.LieferantListungFn(id)
    case SuchLieferantTyp.ABO:
      return AppPaths.LieferantAllianzFn(id)
    default:
      return null
  }
}
