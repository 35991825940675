import { Check } from '@mui/icons-material'
import { orange, red } from '@mui/material/colors'
import { ArtikelBetriebstyp } from '@one/typings/apiTypings'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { Medal } from '@utils/ui/fields/Medal'

export interface ArtikelAbonniertBTypStatusSignProps {
  betriebsTypErmittelt: string
  aboBetriebsTyp: string
  abonniert: boolean
}

export const ArtikelAbonniertBTypStatusSign = ({
  betriebsTypErmittelt,
  aboBetriebsTyp,
  abonniert
}: ArtikelAbonniertBTypStatusSignProps) => {
  if (betriebsTypErmittelt == null || abonniert) {
    return null
  }
  if (betriebsTypErmittelt === aboBetriebsTyp) {
    return (
      <TooltipEx title="Ermittelter Betriebstyp passt zum Abo">
        <Check fontSize="small" />
      </TooltipEx>
    )
  }
  if (betriebsTypErmittelt === ArtikelBetriebstyp.UNBEKANNT) {
    return (
      <Medal
        backgroundColor={red[500]}
        text="000"
        tooltip={
          <span>
            Es konnte kein Betriebstyp ermittelt werden.
            <br />
            <em>Beim Abonnieren wird der aktuelle Abo-Betriebstyp {aboBetriebsTyp} verwendet</em>
          </span>
        }
      />
    )
  }
  return (
    <Medal
      backgroundColor={orange[500]}
      text={betriebsTypErmittelt}
      tooltip={
        <span>
          Es wurde ein der Betriebstyp {betriebsTypErmittelt} abweichend vom Abo ermittelt.
          <br />
          <em>Beim Abonnieren wird der Abo-Betriebstyp {aboBetriebsTyp} verwendet</em>
        </span>
      }
    />
  )
}
