import { blue, green } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { DzMapperEinstellungTyp, MapperEinstellungJson } from '@one/typings/apiTypings'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { PermissionUtil } from '@utils/ui/Permission'
import { Medal } from '@utils/ui/fields/Medal'
import { Radio } from '@utils/ui/fields/Radio'
import { useContext, useMemo } from 'react'

export interface ArtikelLieferantSyncTemplateUebersichtTableProps {
  result: SearcherResultType<MapperEinstellungJson>
  onDelete: (row: MapperEinstellungJson) => VoidFunction
  onStandard: (row: MapperEinstellungJson) => VoidFunction
  label: string
  isAdminMode: boolean
}

const formatName = (row: MapperEinstellungJson, isAdminMode: boolean) => {
  const raw = `${row.typ}${isAdminMode && row.pimDatenmodell ? ' (PIM)' : ''}`
  if (row.standardEH) {
    return `${raw} (Standard EH)`
  }
  return raw
}

export const ArtikelLieferantSyncTemplateUebersichtTable = ({
  result,
  onDelete,
  onStandard,
  label,
  isAdminMode
}: ArtikelLieferantSyncTemplateUebersichtTableProps) => {
  const { isFachhandel } = useContext(AppContext)

  const columns = useMemo<Column<MapperEinstellungJson>[]>(
    () => [
      {
        field: 'typ',
        header: 'Typ',
        sortable: true,
        align: 'center',
        width: '1em',
        valueGetter: (row) => formatName(row, isAdminMode),
        body: (row) => (
          <Medal
            text={formatName(row, isAdminMode)}
            variant="square"
            backgroundColor={row.vorgabe ? green[500] : blue[100]}
            fullWidth
          />
        )
      },
      !isAdminMode && {
        field: 'standard',
        header: 'Standard',
        headerTip: 'Standardauswahl für Anlage neuer Artikelselektionen festlegen',
        sortable: true,
        align: 'center',
        width: '1em',
        body: (row) => (
          <Radio
            checked={row.standardFH || row.standardEH}
            size="small"
            compact
            onClick={onStandard(row)}
            disabled={row.standardEH}
          />
        )
      },
      {
        field: 'name',
        header: 'Name',
        sortable: true,
        body: (row) =>
          row.vorgabe ? <span style={{ fontStyle: 'italic' }}>{row.name}</span> : row.name
      },
      isAdminMode && {
        field: 'standardStufe',
        header: 'Stufe',
        headerTip:
          'Je höher die gewählte Stufe, desto mehr Attribute werden genutzt: Stufe 1 wenige bis Stufe 5 alle Attribute.',
        sortable: true,
        align: 'center',
        type: 'number',
        width: '1em'
      },
      {
        field: 'refCount',
        header: 'Verwendungen',
        headerTip: 'Anzahl Aboselektion, die das Template verwenden',
        type: 'number',
        sortable: true,
        width: '1em'
      }
    ],
    [isAdminMode, onStandard]
  )

  // Button actions for each table row
  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        check: (data) => PermissionUtil.show((isAdminMode || !data.vorgabe) && !data.standardEH),
        getLink: (data) =>
          AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(data.typ, data.id)
      },
      {
        icon: 'visibility',
        tooltip: 'Vordefinierte ArSST anzeigen',
        check: (data) => PermissionUtil.show((!isAdminMode && data.vorgabe) || data.standardEH),
        getLink: (data) =>
          AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(data.typ, data.id)
      },
      {
        icon: 'content_copy',
        tooltip: 'Kopieren',
        getLink: (data) => {
          return AppPaths.synchronisation.ArtikelLieferantSyncTemplateCloneFn(data.id)
        },
        check: (data) => PermissionUtil.show(data.typ === DzMapperEinstellungTyp.ARSST)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        check: (data) => PermissionUtil.show((isAdminMode || !data.vorgabe) && !data.standardEH),
        handler: onDelete
      }
    ],
    [isAdminMode, onDelete]
  )

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          visible: isFachhandel,
          navlink: AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(
            DzMapperEinstellungTyp.ARSST
          ),
          text: 'neues ArSST'
        },
        isAdminMode && {
          role: UserRoles.STAMMDATEN_EDITOR,
          navlink:
            AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(
              DzMapperEinstellungTyp.ARSST
            ) + '?pim',
          text: 'neues ArSST (PIM)'
        },
        !isAdminMode && {
          role: UserRoles.STAMMDATEN_EDITOR,
          navlink: AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(
            DzMapperEinstellungTyp.LISST
          ),
          text: 'neues LiSST'
        }
      ] as Action[],
    [isAdminMode, isFachhandel]
  )

  return (
    <DataTableCard
      name="ArtikelLieferantSyncTemplateUebersichtTable"
      title={`${label} Übersicht`}
      dense
      topActions={actions}
      columns={columns}
      actions={tableActions}
      initialOrderBy={['typ', 'name']}
      localStateName="ArtikelLieferantSyncTemplateUebersichtTable"
      rowFiller
      result={result}
    />
  )
}
