import { red } from '@mui/material/colors'
import { EkKonditionenRabattgruppenViewJson } from '@one/typings/apiTypings'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { useMemo } from 'react'

export interface RabattgruppeFieldProps {
  label?: string
  options?: EkKonditionenRabattgruppenViewJson[]
  value?: any
  name?: string
  onChange?: (rg: EkKonditionenRabattgruppenViewJson) => void
  readonly?: boolean
  required?: boolean
  allianzModus?: boolean
  artikelRabattgruppeId?: number
  referencePreisEbeneId?: number
  nurLeere?: boolean
  error?: string
  emptyTextOvr?: string
}

export const RabattgruppeField = ({
  label = null,
  options,
  value,
  name,
  onChange,
  readonly = false,
  required = false,
  allianzModus = false,
  artikelRabattgruppeId = null,
  referencePreisEbeneId = null,
  nurLeere,
  error,
  emptyTextOvr
}: RabattgruppeFieldProps) => {
  const rv = artikelRabattgruppeId === value ? null : value

  const optionsSafe = useMemo(() => {
    const os = options || []
    if (allianzModus && referencePreisEbeneId != null) {
      const xx = os.filter(
        (opt) =>
          opt.id == rv ||
          ((opt.preisEbenenVersions == null || opt.preisEbenenVersions.length === 0) &&
            opt.version != null &&
            (opt.version.staffelwerte == null || opt.version.staffelwerte.length === 0)) ||
          (referencePreisEbeneId != null &&
            opt.preisEbenenVersions
              ?.filter((pe) =>
                nurLeere ? pe.staffelwerte == null || pe.staffelwerte.length === 0 : true
              )
              .find((pe) => pe.preisEbeneId === referencePreisEbeneId) != null)
      )
      return xx
    }
    return os //.filter(opt => opt.id == rv || opt.version != null)
  }, [allianzModus, nurLeere, options, referencePreisEbeneId, rv])

  const handleChange = (e) => {
    if (onChange) {
      const vorlage = optionsSafe.find((v) => v.id === e.target.value)
      onChange(vorlage)
    }
  }

  const emptyText = emptyTextOvr || (artikelRabattgruppeId == null ? null : 'Art EK-RG')

  const renderItem = (optIn: EkKonditionenRabattgruppenViewJson) => {
    if (optIn == null) {
      return emptyText
    }
    const opt =
      optIn || (artikelRabattgruppeId && optionsSafe.find((rg) => rg.id === artikelRabattgruppeId))

    let label = opt?.kopf?.name
    if (label && opt?.kopf?.bezeichnung) {
      label += ` - ${opt?.kopf?.bezeichnung}`
    } else if (opt?.kopf?.bezeichnung) {
      label = opt?.kopf?.bezeichnung
    }
    return label
  }

  const renderLabel = (optIn: EkKonditionenRabattgruppenViewJson) => {
    return optIn?.kopf?.bezeichnung ?? optIn?.kopf?.name
  }

  if (readonly && label == null) {
    const opt = rv == null ? null : optionsSafe.find((v) => v.id === rv)
    return <div>{renderItem(opt)}</div>
  }

  return (
    <>
      <AutocompleteEx
        label={label}
        name={name}
        options={optionsSafe}
        value={rv}
        onChange={handleChange}
        disabled={readonly}
        optionLabel={renderLabel}
        optionValue="id"
        renderItem={renderItem}
        renderNullItem={artikelRabattgruppeId != null}
        fullWidth
        emptyText={emptyText}
        required={required}
        error={!!error}
      />
      {error ? (
        <div style={{ paddingTop: '4px', whiteSpace: 'normal', fontSize: '80%', color: red[200] }}>
          {error}
        </div>
      ) : null}
    </>
  )
}
