import { PimArtikelVersionPackstueckJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface ArtikelPimPackstueckeProps {
  packstuecke: PimArtikelVersionPackstueckJson[]
}

export const ArtikelPimPackstuecke = ({ packstuecke }: ArtikelPimPackstueckeProps) => {
  const column: Column<PimArtikelVersionPackstueckJson>[] = [
    {
      field: 'nummer',
      header: 'Nummer'
    },
    {
      field: 'laenge',
      header: 'Länge'
    },
    {
      field: 'breite',
      header: 'Breite'
    },
    {
      field: 'hoehe',
      header: 'Höhe'
    },
    {
      field: 'inhalt',
      header: 'Inhalt'
    },
    {
      field: 'gewicht',
      header: 'Gewicht'
    }
  ]

  return (
    <CardEx title="Packstücke" height="100%" name="Packstuecke">
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimPackstueckeTabelle"
          value={packstuecke}
          columns={column}
          emptyMessage="Keine Daten"
          dense
          rowFiller
        />
      </ScrollPanel>
    </CardEx>
  )
}
