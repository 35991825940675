import { AppContext } from '@utils/ui/App/AppContext'
import { createContext, useContext, useMemo, useRef, useState } from 'react'
import { HelpLocation, HelpPanel } from './HelpPanel'

export type HelpContextType = {
  /**
   * Hilfeseite ansteuern
   * @param key Adresse der altuellen Seite
   * @param title Titel aktuellen Seite
   */
  setHelpLocation: (key: string, title?: string) => void

  /**
   * Öffne spezielles Hilfe-Kapitel, i.d.R. eine besonderer Status oder Assistenzschritt der aktuellen Seite
   * @param chapterKey Key des Kapitels
   * @param chapterTitle Titel des Kapitels
   */
  setHelpChapter: (chapterKey: string, chapterTitle?: string) => void
  setHelpVisible: (show: boolean, forceOverlay?: boolean) => void
  getHelpLocation: () => HelpLocation
}

export const HelpContext = createContext<HelpContextType>(null)

export type HelpWrapperProps = {
  visible: boolean
  setVisible(show: boolean): void
  api: any
  editRoleName?: string
  children: any
}

export const HelpWrapper = ({
  visible,
  setVisible,
  editRoleName,
  api,
  children
}: HelpWrapperProps) => {
  const [helpNode, setHelpNode] = useState<HelpLocation>()
  const helpNodeRef = useRef<HelpLocation>()
  helpNodeRef.current = helpNode
  const [forcedOverlay, setForcedOverlay] = useState<boolean>(false)
  const { checkUserRole } = useContext<any>(AppContext)

  const editAllowed = useMemo(
    () => editRoleName != null && checkUserRole(editRoleName),
    [checkUserRole, editRoleName]
  )

  const ctx = useMemo<HelpContextType>(
    () => ({
      setHelpVisible: (isVisible: boolean, forcedOverlay: boolean = false) => {
        setVisible(isVisible)
        if (isVisible && forcedOverlay) {
          setForcedOverlay(true)
        } else {
          setForcedOverlay(false)
        }
      },
      setHelpLocation: (key: string, title: string) => {
        setHelpNode({ ...helpNodeRef.current, key, title })
      },
      setHelpChapter: (chapterKey: string, chapterTitle?: string) => {
        setHelpNode({ ...helpNodeRef.current, chapterKey, chapterTitle })
      },
      getHelpLocation: () => helpNodeRef.current
    }),
    [setHelpNode, setVisible]
  )

  return (
    <HelpContext.Provider value={ctx}>
      <div style={{ paddingRight: visible ? '25%' : undefined, height: '100%', width: '100%' }}>
        {children}
        {visible ? (
          <HelpPanel
            visible={visible}
            forcedOverlay={forcedOverlay}
            setForcedOverlay={setForcedOverlay}
            setVisible={setVisible}
            helpLocation={helpNode}
            editAllowed={editAllowed}
            api={api}
          />
        ) : null}
      </div>
    </HelpContext.Provider>
  )
}
