import { Divider, Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { HkmEnum } from '@one/enums/HkmEnum'
import { PimArtikelLieferantJson, SuchLieferantTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { CardEx } from '@utils/ui/CardEx'
import { Link } from '@utils/ui/Navigation/Link'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useMemo } from 'react'
import { ArtikelPimLieferantEkPreise } from './ArtikelPimLieferantEkPreise'

export interface ArtikelPimEkPreiseProps {
  lieferanten: PimArtikelLieferantJson[]
}

export const ArtikelPimEkPreise = ({ lieferanten }: ArtikelPimEkPreiseProps) => {
  const { et } = useEnums()

  const renderLieferant = useCallback(
    (lieferant: PimArtikelLieferantJson, index: number) => {
      const renderWithLink = (label) => {
        const link =
          lieferant.lieferant.typ === SuchLieferantTyp.ZENTRAL
            ? AppPaths.LieferantListungFn(lieferant.lieferant.id)
            : AppPaths.LieferantFn(lieferant.lieferant.id)

        return <Link to={link}>{label}</Link>
      }

      return (
        <Grid container direction="column" spacing={4} width="100%">
          <Grid item container spacing={2} width="100%">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Lieferant &quot;
                {lieferant.lieferant?.notanlage
                  ? `Notanlage (${lieferant.lieferant?.nummer ?? ''})`
                  : lieferant.lieferant.name1}
                &quot;
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <StaticField
                label="Nummer"
                value={renderWithLink(lieferant.lieferant.nummer)}
                name="nummer"
                empty
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={5}>
              <StaticField
                label="Name 1"
                value={lieferant.lieferant.notanlage ? 'Notlieferant' : lieferant.lieferant.name1}
                name="name1"
                empty
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={5}>
              <StaticField label="Name 2" value={lieferant.lieferant.name2} name="name2" empty />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <StaticField
                label="GLN"
                value={renderWithLink(lieferant.lieferant.gln)}
                name="gln"
                empty
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StaticField
                label="Abo-Status"
                value={et(HkmEnum.ArtikelAbonniertStatus, lieferant.lieferant.aboStatus)}
                name="aboStatus"
                empty
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <StaticField
                label="Anzahl Gelistet"
                value={lieferant.lieferant.anzahlGelistet}
                name="anzahlGelistet"
                empty
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <StaticField
                label="Anzahl Abonniert"
                value={lieferant.lieferant.anzahlAbonniert}
                name="anzahlAbonniert"
                empty
              />
            </Grid>
          </Grid>
          <Grid item width="100%">
            <ScrollPanel autoHeight autoHeightMax="600px">
              <ArtikelPimLieferantEkPreise ekPreise={lieferant.ekPreise} />
            </ScrollPanel>
          </Grid>
        </Grid>
      )
    },
    [et]
  )

  const lieferantenRendered = useMemo(() => {
    return lieferanten?.map(renderLieferant) || []
  }, [lieferanten, renderLieferant])

  if (lieferantenRendered.length === 0) {
    return null
  }

  return (
    <CardEx title="EK-Preise" name="LieferantenPreise">
      {lieferantenRendered}
    </CardEx>
  )
}
