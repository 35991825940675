import { blue, blueGrey } from '@mui/material/colors'
import { api } from '@one/api'
import { router } from '@one/AppRouter'
import { LoginDialog } from '@one/components/LoginDialog/LoginDialog'
import { DefaultFrame } from '@one/DefaultFrame'
import enumData from '@one/enums/data.json'
import { EventNames } from '@one/EventNames'
import { useHeartbeat } from '@one/heartbeat'
import { ExternesSystemType, GesellschafterTyp, SessionJson } from '@one/typings/apiTypings'
import { setGlobalEnumData } from '@utils/enums'
import { AppContextState } from '@utils/ui/App/AppContext'
import { AppRoot } from '@utils/ui/App/AppRoot'
import { AppState } from '@utils/ui/App/AppState'
import { useCallback } from 'react'
import { RouterProvider } from 'react-router-dom'
import packageJson from '../package.json'

setGlobalEnumData(enumData)

const myThemeAddOns = (darkMode: boolean) => ({
  palette: {
    primary: {
      main: '#008542', // darkMode ? '#008542' : '#5b874b'
      badge: darkMode ? blue[900] : blue[300]
    },
    secondary: {
      main: darkMode ? blueGrey[900] : blueGrey[400]
    },
    error: {
      main: '#ff0000'
    },
    background: {
      default: darkMode ? '#212121' : '#eeeeee'
    }
  },
  datatable: {
    rowfocus: darkMode ? '#5d6600' : '#f4ff81'
  }
})

export const App = () => {
  useHeartbeat()

  const extendAppContext = useCallback((session: SessionJson): Partial<AppContextState> => {
    const company = session?.companies?.find((c) => c.gln === session.gln)
    return {
      isGesellschafter: company?.typ === GesellschafterTyp.ORGANISATION,
      isWithoutErp: company?.ohneERP,
      isWithErpImport: !company?.ohneErpImport,
      isWithEkPreis: !company?.ohneEkPreis,
      isWithVkPreis: !company?.ohneVkPreis,
      isAllianz: company?.allianz,
      isAllianzMitglied: company?.allianzMitglied,
      isInterCompany: company?.interCompany,
      isDatapoolNEON: session?.datapoolNeonEnabled,
      isFachhandel: session?.fachhandel,
      isEinzelhandel: session?.einzelhandel,
      isSortimente: session?.sortimente,
      isPIMModel: session?.pimModel,
      isExternesERP: company?.externesSystemType === ExternesSystemType.ERP_EXTERN
    }
  }, [])

  return (
    <AppRoot themeAddOns={myThemeAddOns} locale="de-DE">
      <AppState
        api={api}
        version={packageJson.version}
        LoginDialog={LoginDialog}
        title="ONE Frontend"
        titleShort="ONE"
        extendAppContext={extendAppContext}
        DefaultFrame={DefaultFrame}
        connectEvent={EventNames.SESSION_CONNECT}
      >
        <RouterProvider router={router} />
      </AppState>
    </AppRoot>
  )
}
