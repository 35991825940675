import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { ArtikelBearbeitungsListeEdit } from '@one/components/PreisAnlage/ArtikelBearbeitungsListeBearbeitenUseCase'
import { PreisAnlageView } from '@one/components/PreisAnlage/PreisAnlageView'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { useContext } from 'react'

export const VkPreisAnlageView = () => {
  const { replaceHistory } = useContext(AppSwitchCtx)
  const { askToConfirm } = useMessageDialog()

  const handleSave = (save: () => void, anzLieferanten: number) => {
    if (anzLieferanten > 1) {
      askToConfirm({
        title: 'Mehrere Hauptlieferanten',
        message:
          'Es wird pro Hauptlieferant eine VK-Preisliste erstellt. Sie werden auf die Ek-Preislisten-Übersichtseite weitergeleitet.',
        confirmLabel: 'Anlegen',
        onConfirm: save
      })
    } else {
      save()
    }
  }

  const onSave = (savedModel: ArtikelBearbeitungsListeEdit) => {
    if (savedModel?.vkPreisListenIds?.length > 0) {
      if (savedModel.vkPreisListenIds.length == 1) {
        replaceHistory(() => AppPaths.VkPreisPflegeFn(savedModel?.vkPreisListenIds[0]))
      } else {
        replaceHistory(() => AppPaths.VkPreisUebersichtFn(savedModel.vkPreisListenIds))
      }
    }
  }

  return (
    <PreisAnlageView
      mode="vk"
      title="Verkaufspreispflege Artikelauswahl"
      rest="vkpreisliste/anlage"
      eventName={EventNames.VKPREISLISTE}
      overviewLink={AppPaths.VkPreisUebersicht[0]}
      onSaveCallback={onSave}
      saveCallback={handleSave}
      appPathFn={AppPaths.VkPreisAnlageFn}
    />
  )
}
