import { grey, yellow } from '@mui/material/colors'
import { SeverityCell } from '@one/components/common/SeverityCell'
import { HkmEnum } from '@one/enums/HkmEnum'
import { MessageSeverity, ProtokollStatus } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column, DataTableOrderType } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { PermissionUtil } from '@utils/ui/Permission'
import { Medal } from '@utils/ui/fields/Medal'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const protokollStatusColor = (status: any) => {
  switch (status) {
    case ProtokollStatus.STARTED:
      return yellow[200]
    case ProtokollStatus.ENDED:
      return grey[200]
    default:
      return null
  }
}

export const protokollSeverity = (row: any) => {
  if (row.detailFatalCount > 0) {
    return MessageSeverity.FATAL
  }
  if (row.detailErrorCount > 0) {
    return MessageSeverity.ERR
  }
  if (row.detailWarningCount > 0) {
    return MessageSeverity.WARN
  }
  return null
}

const initialOrderBy: DataTableOrderType[] = [
  { key: 'start', dir: 'desc' },
  { key: 'end', dir: 'desc' }
]

export const ProtokolleUebersichtTable = ({ loading, values = [], limited = false }) => {
  const { et } = useEnums()

  const columns = useMemo(
    () =>
      [
        {
          field: 'start',
          header: 'gestartet am',
          type: 'datetime',
          sortable: true
        },
        {
          field: 'end',
          header: 'beendet am',
          type: 'datetime',
          sortable: true
        },
        {
          field: 'typ',
          header: 'Typ',
          valueGetter: (row: any) => et(HkmEnum.ProtokollTyp, row.typ),
          sortable: true
        },
        {
          field: 'status',
          header: 'Status',
          align: 'center',
          valueGetter: (row: any) => et(HkmEnum.ProtokollStatus, row.status),
          body: (row) => (
            <Medal
              text={et(HkmEnum.ProtokollStatus, row.status)}
              backgroundColor={protokollStatusColor(row.status)}
            />
          ),
          sortable: true
        },
        {
          field: 'severity',
          header: 'Schwere',
          valueGetter: (row: any) => et(HkmEnum.MessageSeverity, protokollSeverity(row)),
          body: (row) => <SeverityCell value={protokollSeverity(row)} />,
          sortable: true
        },
        {
          field: 'detailCount',
          header: 'Meldungen',
          type: 'number',
          body: (row) => (row.detailCount > 0 ? row.detailCount : ''),
          sortable: true
        },
        {
          field: 'detailWarningCount',
          header: 'Warnungen',
          type: 'number',
          body: (row) => (row.detailWarningCount > 0 ? row.detailWarningCount : ''),
          sortable: true
        },
        {
          field: 'detailErrorCount',
          header: 'Fehler',
          type: 'number',
          body: (row) => (row.detailErrorCount > 0 ? row.detailErrorCount : ''),
          sortable: true
        },
        {
          field: 'detailFatalCount',
          header: 'Fatale Fehler',
          type: 'number',
          body: (row) => (row.detailFatalCount > 0 ? row.detailFatalCount : ''),
          sortable: true
        }
      ] as Column[],
    [et]
  )

  const tableActions = useMemo(
    () => [
      {
        icon: 'visibility',
        tooltip: 'Details',
        getLink: (data) => `/protokolldetails/${data.id}/`,
        check: (row) => PermissionUtil.show(row.detailCount > 0)
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="ProtokolleUebersichtTable"
      title="Suchergebnis"
      columns={columns}
      value={values}
      actions={tableActions}
      loading={loading}
      dense
      filterMode="both"
      initialOrderBy={initialOrderBy}
      limited={limited}
      localStateName="ProtokolleUebersichtTable"
      rowFiller
    />
  )
}

ProtokolleUebersichtTable.propTypes = {
  values: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
}
