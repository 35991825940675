import { Sync } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { ArtikelBetriebstyp, BetriebstypJson } from '@one/typings/apiTypings'
import { Rotate } from '@utils/ui/Rotate'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { zeroPad } from '@utils/utils'

export type ArtikelBetriebstypCellProps = {
  name?: string
  value: ArtikelBetriebstyp
  all?: boolean
}

export const ArtikelBetriebstypCell = ({ value, name, all }: ArtikelBetriebstypCellProps) => {
  const betriebstypenCache = useArtikelBetriebstypCache()
  if (value == null || value === ArtikelBetriebstyp.PIM) {
    return null
  }
  const bt = betriebstypenCache.get(value)
  if (bt == null) {
    return (
      <Rotate>
        <Sync fontSize="small" />
      </Rotate>
    )
  }
  if (all) {
    return (
      <span>
        <span data-name={name}>{zeroPad(bt.btNr, 3)}</span>&nbsp;{bt?.bezeichnung ? '-' : ''}&nbsp;
        {bt?.bezeichnung ?? ''}
      </span>
    )
  }
  return (
    <TooltipEx title={bt.bezeichnung}>
      <Box data-name={name}>{zeroPad(bt.btNr, 3)}</Box>
    </TooltipEx>
  )
}

export const getArtikelBetriebstypLabel = (bt: BetriebstypJson) => zeroPad(bt?.btNr ?? 0, 3)
