import { Button, ButtonProps } from './Button'

export type ReloadButtonProps = {
  isNew?: boolean
  isChanged?: boolean
  variant?: ButtonProps['variant']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  visible?: boolean
}

export const ReloadButton = ({
  isNew,
  isChanged,
  variant,
  onClick,
  onClickVoid,
  visible
}: ReloadButtonProps) => (
  <Button
    label={isChanged ? 'Verwerfen' : 'Neu laden'}
    onClick={onClick}
    onClickVoid={onClickVoid}
    variant={variant || 'contained'}
    color="primary"
    tooltip="Lädt die aktuelle Anischt neu"
    visible={visible}
    disabled={isNew && !isChanged}
  />
)
