import { Undo } from '@mui/icons-material'
import { ButtonProps } from './Button'
import { IconButton, IconButtonProps } from './IconButton'

export type UndoButtonProps = {
  canUndo?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  visible?: boolean
  size?: IconButtonProps['size']
}

export const UndoButton = ({
  canUndo,
  onClick,
  onClickVoid,
  visible = true,
  size
}: UndoButtonProps) => (
  <IconButton
    tooltip="Letzte Eingabe rückgängig machen"
    onClick={onClick}
    onClickVoid={onClickVoid}
    color="primary"
    visible={visible}
    icon={<Undo />}
    disabled={!canUndo}
    size={size}
  />
)
