import { UseCaseStateJson } from '@one/typings/apiTypings'
import { isSeverityError } from '@utils/utils'
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar, VariantType } from 'notistack'
import { useCallback } from 'react'
import { Button } from './Buttons/Button'
import { ScrollPanel } from './ScrollPanel'

/**
 * Meldungsarten
 */
export enum SnackbarType {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info'
}

/**
 * Erweiterte Snackbar zum Umgang mit UseCaseStateJson
 *
 */
export type SnackbarEx = {
  /**
   * Meldung anzeigen
   */
  enqueMsg: (msg: SnackbarMessage, variant: VariantType, options?: OptionsObject) => SnackbarKey

  /**
   * Fehler anzeigen, bestehend aus Hauptmeldung und Details
   */
  enqueError: (msg: any, details?: any) => SnackbarKey

  /**
   * Zeigt Fehler aus State an
   */
  enqueState: (state: UseCaseStateJson) => SnackbarKey

  /**
   * Originalaufruf um beliebige Meldungen/Popup-Inhalte zu zeigen
   */
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey

  /**
   * Ein als Snackbar geeigte Meldung wieder schließen
   */
  closeSnackbar: (key?: SnackbarKey) => void
}

/**
 * Zugriff auf die Meldungscontroller, um Meldungen zu zeigen.
 *
 * Es gibt ein globalen Snackbarprovider (siehe AppRoot) für übergeordnete Meldungen.
 *
 * Je Route/Seite gibt es einen separaten (siehe AppSwitch), so das Meldungen je Seite isoliert erscheinen.
 *
 * @returns Functions zum Umgang mit Meldungen
 */
export const useSnackbarEx = (): SnackbarEx => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueMsg = useCallback(
    (msg, variant?, props?) =>
      enqueueSnackbar(msg, {
        ...props,
        variant
      }),
    [enqueueSnackbar]
  )

  const enqueError = useCallback(
    (msg: string, details: string = null) =>
      enqueMsg(
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          data-name={`snackbar-${SnackbarType.error}`}
        >
          <div>{msg}</div>
          {details && (
            <ScrollPanel autoHeight style={{ maxWidth: '80vw', maxHeight: '40vh' }}>
              <pre>{details}</pre>
            </ScrollPanel>
          )}
        </div>,
        SnackbarType.error,
        {
          persist: true,
          action: (key: SnackbarKey) => (
            <Button
              label="Schließen"
              style={{ color: 'white' }}
              size="small"
              variant="text"
              onClick={() => closeSnackbar(key)}
            />
          )
        }
      ),
    [closeSnackbar, enqueMsg]
  )

  const enqueState = useCallback(
    (state: UseCaseStateJson) => {
      if (isSeverityError(state?.mainMessage?.severity)) {
        const details =
          state.messages &&
          state.messages
            .map((m) => m.message)
            .filter((v, idx, self) => self.indexOf(v) === idx)
            .join('\n')
        return enqueError(state.mainMessage.message, details)
      }
      return null
    },
    [enqueError]
  )

  return { enqueMsg, enqueError, enqueState, enqueueSnackbar, closeSnackbar }
}
