import { Block } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { getLieferantLink } from '@one/components/Lieferant/LieferantUtils'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantDisplayJson, SuchLieferantTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Column, DataTableProps } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface LieferantenUebersichtTableProps {
  result: SearcherResultType<LieferantDisplayJson>
  borderless?: boolean
  autoHeight?: boolean
  selectMode?: DataTableProps['selectMode']
  onSelect?: DataTableProps['onSelect']
  onRowDoubleClick?: DataTableProps['onRowDoubleClick']
}

export const LieferantenUebersichtTable = ({
  result,
  selectMode = null,
  onSelect = null,
  onRowDoubleClick = null,
  borderless = false
}: LieferantenUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo(
    () =>
      [
        {
          field: 'eigenlistung',
          header: 'EL?',
          headerTip: 'Eigenlistungslieferant?',
          sortable: true,
          align: 'center',
          type: 'boolean',
          hideIfEmpty: true,
          // body: (row) => (row.eigenlistung ? <BuildCircleOutlined fontSize="small" /> : null),
          width: '1em'
        },
        {
          field: 'nummer',
          header: 'Nummer',
          sortable: true
        },
        {
          field: 'name1',
          header: 'Name 1',
          sortable: true,
          valueGetter: (row) => {
            return row.notanlage ? 'Notlieferant' : row.name1
          }
        },
        {
          field: 'name2',
          header: 'Name 2',
          sortable: true
        },
        {
          field: 'iln',
          header: 'ILN',
          sortable: true
        },
        {
          field: 'edi',
          header: 'EDI',
          sortable: true,
          align: 'center'
        },
        {
          field: 'gesperrt',
          header: 'Gesperrt/Gelöscht',
          sortable: true,
          align: 'center',
          valueGetter: (row) =>
            row.typ == SuchLieferantTyp.DEZENTRAL
              ? row.gesperrtDz || row.geloeschtDz
              : row.gesperrt || row.gesperrt,
          body: (row) =>
            (
              row.typ == SuchLieferantTyp.DEZENTRAL
                ? row.gesperrtDz || row.geloeschtDz
                : row.gesperrt || row.gesperrt
            ) ? (
              <Block fontSize="small" />
            ) : null
        },
        // {
        //   field: 'anzahlAbonniert',
        //   header: 'Abonnierte Artikel',
        //   sortable: true,
        //   type: 'number'
        // },
        // {
        //   field: 'anzahlGelistet',
        //   header: 'Gelistete Artikel',
        //   sortable: true,
        //   type: 'number'
        // },

        {
          field: 'typ',
          header: 'Quelle',
          sortable: true,
          valueGetter: (row) => et(HkmEnum.SuchLieferantTyp, row.typ)
        }
      ] as Column<LieferantDisplayJson>[],
    [et]
  )

  const tableActions = useMemo(
    (): DataTableAction<LieferantDisplayJson>[] =>
      [
        {
          icon: 'visibility',
          tooltip: 'zum Lieferanten',
          getLink: (data) => getLieferantLink(data.typ, data.id)
        },
        {
          icon: 'edit',
          tooltip: 'Bearbeiten',
          getLink: (data) =>
            data.eigenlistung && AppPaths.EigenlistungsLieferantPflegeFn(data.seLieferantId),
          check: (data) => data.eigenlistung
        }
      ] as DataTableAction<LieferantDisplayJson>[],
    []
  )

  return (
    <DataTableCard
      name="LieferantenUebersichtTable"
      title="Lieferantenübersicht"
      filterMode="both"
      selectMode={selectMode}
      selectCol={selectMode != null}
      columns={columns}
      actions={tableActions}
      dense
      hover
      localStateName="LieferantenUebersichtTable"
      onSelect={onSelect}
      onRowDoubleClick={onRowDoubleClick}
      borderless={borderless}
      result={result}
    />
  )
}
