import { alpha, TooltipProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme) => ({
  medal: {
    display: 'inline-flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',

    textAlign: 'center',
    fontWeight: '500',

    // width: 'fit-content',
    minWidth: '42px',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',

    padding: '1px 8px 0px 8px',

    fontSize: '12px',

    color: 'black',

    backgroundColor: (theme.palette?.primary as any)?.badge,

    cursor: 'default',

    '&.active': {
      userSelect: 'none',
      cursor: 'pointer'
    },
    '&:hover.active': {
      marginLeft: '-1px',
      marginTop: '-1px',
      marginRight: '1px',
      boxShadow:
        theme.palette.mode === 'dark' ? '4px 4px 5px -2px #d5d5d5' : '4px 4px 5px -2px #000000'
    }

    // '&>span>svg': {
    //   fontSize: '100%'
    // }
  },
  round: {
    boxShadow: 'inset 0 0 0 1px rgba(34, 36, 38, 0.15)',
    borderRadius: '12px'
  },
  square: {
    boxShadow: 'inset 0 0 0 1px rgba(34, 36, 38, 0.15)',
    borderRadius: '3px'
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      // fontSize: "0.86em!important",
      backgroundColor: alpha(grey[600], 0.96)
    }
  },
  fullWidth: {
    width: '100%'
  }
}))

export interface MedalProps {
  text: string
  title?: string
  color?: string
  backgroundColor?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onDblClick?: React.MouseEventHandler<HTMLDivElement>
  fullWidth?: boolean
  icon?: React.ReactNode
  tooltip?: React.ReactNode
  placement?: TooltipProps['placement']
  variant?: 'round' | 'square'
}

export const Medal = ({
  text,
  title,
  color,
  backgroundColor,
  onClick,
  onDblClick,
  tooltip,
  icon,
  fullWidth,
  placement = 'left-start',
  variant = 'round'
}: MedalProps) => {
  const { classes } = useStyles()
  const style = useMemo(
    () => ({
      color,
      backgroundColor
    }),
    [color, backgroundColor]
  )
  if (text == null || text.length === 0) {
    return null
  }
  const body = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={clsx(
        classes.medal,
        variant === 'round' && classes.round,
        variant === 'square' && classes.square,
        fullWidth && classes.fullWidth,
        (onClick || onDblClick) && 'active'
      )}
      style={style}
      title={title}
      onClick={onClick}
      onDoubleClick={onDblClick}
      role={onDblClick && 'button'}
    >
      {icon}
      <span>{text}</span>
    </div>
  )
  if (tooltip) {
    return (
      <TooltipEx arrow popperClassName={classes.tooltip} placement={placement} title={tooltip}>
        {body}
      </TooltipEx>
    )
  }
  return body
}
