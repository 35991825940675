import { PriceChange } from '@mui/icons-material'
import { HandelTyp, VkPreisDiffJson, VkPreisGruppeJson } from '@one/typings/apiTypings'
import { formatDate } from '@utils/dateutils'
import { isEmpty } from '@utils/utils'
import { AttributeTreeItem, ChangesTreeView, DiffTreeItem } from './ChangesTreeView'
import { formatNeonKontext } from './formatters'

const generateVkPreisChangeItems =
  (preisChanges: VkPreisDiffJson[], preisGruppen: VkPreisGruppeJson[]) =>
  (idgen, expandedIds, treeClasses, itemClasses) => {
    if (isEmpty(preisChanges)) {
      return []
    }

    const ehGruppenMap = new Map(
      preisGruppen.filter((pg) => pg.eh).map((pg) => [pg.pimMappingNr, pg])
    )

    const fhGruppenMap = new Map(
      preisGruppen.filter((pg) => pg.fh).map((pg) => [pg.pimMappingNr, pg])
    )

    const items = []

    preisChanges.forEach((preisDiff) => {
      const id1 = idgen()
      expandedIds.push(id1)
      const id2 = idgen()
      expandedIds.push(id2)

      const { alt, neu } = preisDiff
      const infoText = (alt == null && 'Neu') || (neu == null && 'Gelöscht') || 'Geändert'
      const base = neu || alt
      const path = `${infoText}/`

      const gueltigAb = formatNeonKontext(base.kontext) + ' ' + formatDate(base.gueltigAb)

      const pgMap = base.handelTyp === HandelTyp.EH ? ehGruppenMap : fhGruppenMap

      items.push(
        <AttributeTreeItem
          key={id1}
          nodeId={id1}
          labelText={gueltigAb}
          name="gueltigAb"
          labelIcon={<PriceChange />}
          labelInfo={infoText}
          treeClasses={treeClasses}
          itemClasses={itemClasses}
        >
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText={pgMap.get(1)?.name}
            name="pg1"
            type="money"
          />
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText={pgMap.get(2)?.name}
            name="pg2"
            type="money"
          />
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText={pgMap.get(3)?.name}
            name="pg3"
            type="money"
          />
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText={pgMap.get(4)?.name}
            name="pg4"
            type="money"
          />
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText={pgMap.get(5)?.name}
            name="pg5"
            type="money"
          />
          <DiffTreeItem
            idgen={idgen}
            path={path}
            alt={alt}
            neu={neu}
            labelText={pgMap.get(6)?.name}
            name="pg6"
            type="money"
          />
        </AttributeTreeItem>
      )
    })

    return items
  }

export type VkPreisChangeTreeViewProps = {
  preisChanges: VkPreisDiffJson[]
  preisGruppen: VkPreisGruppeJson[]
}

export const VkPreisChangeTreeView = ({
  preisChanges,
  preisGruppen
}: VkPreisChangeTreeViewProps) =>
  preisChanges ? (
    <ChangesTreeView generateItems={generateVkPreisChangeItems(preisChanges, preisGruppen)} />
  ) : null
