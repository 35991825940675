import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  AllianzDatenBereitstellungJson,
  AllianzDatenValidierenType,
  MessageSeverity
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { isAsyncTaskActive, useAsyncTaskPoller } from '@utils/asynctask'
import { notifyObservers } from '@utils/observer'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { UILockType } from '@utils/uilock'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AllianzDatenBereitstellungParams } from './AllianzDatenBereitstellungParams'

export const AllianzDatenBereitstellungView = () => {
  const { pushHistory } = useContext(AppSwitchCtx)

  const { visible } = useContext(AppRouteCtx)

  const [state, setState] = useState<AllianzDatenBereitstellungJson>()

  const [uiLock, setUiLock] = useState<UILockType>({} as UILockType)

  const [apiCall, apiBusy] = useApiCaller(api)

  const [asyncPoller] = useAsyncTaskPoller()

  const onAllianzExport = () => {
    apiCall<AllianzDatenBereitstellungJson>({
      method: 'GET',
      rest: '/allianz/datenBereitstellen',
      onSuccessMsg: 'Erfolgreich gestartet.',
      onSuccess,
      onError: (error) => {
        if (error?.mainMessage?.severity === MessageSeverity.ERR) {
          const type = AppPaths.AllianzDatenFehlerViewFn(AllianzDatenValidierenType.Bereitstellung)
          pushHistory(type)
          setTimeout(() => {
            notifyObservers({ name: EventNames.ALLIANZBEREITSTELLUNG })
          }, 50)
          return true
        }
        return false
      }
    })
  }

  const onSuccess = useCallback(
    (data: AllianzDatenBereitstellungJson) => {
      const asyncTask = data?.state?.asyncTask
      if (isAsyncTaskActive(asyncTask)) {
        asyncPoller({
          asyncTask,
          title: 'Bereitstellung wird ausgeführt',
          setUiLock,
          apiCall,
          onReady: () => {},
          onError: () => {}
        })
      }
    },
    [apiCall, asyncPoller]
  )

  const fetchData = useCallback(() => {
    apiCall<AllianzDatenBereitstellungJson>({
      method: 'GET',
      rest: '/allianz/loadDatenBereitstellung',
      onSuccess
    })
  }, [apiCall, onSuccess])

  useEffect(() => {
    if (visible) {
      fetchData()
    }
  }, [fetchData, visible])

  return (
    <StatePlane wait={apiBusy} uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <AllianzDatenBereitstellungParams onRefresh={fetchData} data={null} />
        </FrameRow>
        <FrameBody>
          {/* <ExportToErpTable
            values={state.result}
            limited={state.limited}
            reasonArtikel={state.reasonArtikel}
            reasonLieferant={state.reasonLieferant}
            loading={apiBusy}
          /> */}
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <Button
              label="Bereitstellung starten"
              tooltip="Aktuelle Preise an alle ONE Allianzmitglieder verteilen"
              onClick={onAllianzExport}
              variant="contained"
            />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
