import { api } from '@one/api'
import { AllianzMitgliedDisplayJson, GesellschafterDisplayJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { SelectField } from '@utils/ui/fields/SelectField'
import { useCallback, useEffect, useState } from 'react'

export interface AllianzMitgliedFieldProps {
  mitglieder: AllianzMitgliedDisplayJson[]
  onSelect: (id: string) => void
}

export const AllianzMitgliedField = ({ mitglieder, onSelect }: AllianzMitgliedFieldProps) => {
  const [firmen, setFirmen] = useState<GesellschafterDisplayJson[]>([])
  const [selectedFirmaId, setSelectedFirmaId] = useState(null)

  const [apiCall, apiBusy] = useApiCaller(api)

  const getFirmen = useCallback(() => {
    apiCall<any>({
      method: 'POST',
      rest: '/gesellschafter/uebersichtAnzeigen/search',
      data: {
        isAllianz: false,
        isFirma: true
      },
      onSuccess: (data) => {
        const selectableFirmen = (data?.searcher?.result || []).filter(
          (firma: GesellschafterDisplayJson) =>
            mitglieder.findIndex((mitglied) => mitglied.firma.id === firma.id) === -1
        )
        setFirmen(selectableFirmen)
      }
    })
  }, [apiCall, mitglieder])

  useEffect(() => {
    getFirmen()
  }, [getFirmen])

  const renderFirma = useCallback(
    (firma: GesellschafterDisplayJson) => (
      <div>
        {firma.name} ({firma.gln})
      </div>
    ),
    []
  )

  const onSelectFirma = useCallback(
    (e) => {
      const id = e.target?.value
      setSelectedFirmaId(id)
      onSelect(id)
    },
    [onSelect]
  )

  return (
    <SelectField
      name="id"
      label="Firma wählen"
      required
      options={firmen}
      value={selectedFirmaId}
      onChange={onSelectFirma}
      renderItem={renderFirma}
      fullWidth
    />
  )
}
