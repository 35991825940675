import { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { errorLog } from './logging'
import { ValueDecorator, dataFromEvent, updateObjectField } from './utils'

export type ChangeFunction<T> = (param: any | ((old: T) => T)) => void

export const useFormState = <T = any>(
  initialState: T | (() => T),
  valueDecorator: ValueDecorator = null,
  stateInterceptor: (name: string, state: T, old: T) => T = null
): [T, ChangeFunction<T>, () => T] => {
  const [state, setState] = useState<T>(initialState)

  const stateRef = useRef<any>()
  useImperativeHandle(stateRef, () => ({ state, valueDecorator, stateInterceptor }), [
    state,
    valueDecorator,
    stateInterceptor
  ])

  const onChange = useCallback((param: any | ((old: T) => T)) => {
    if (typeof param === 'function') {
      setState(param)
    } else {
      const data = dataFromEvent(param, stateRef.current.valueDecorator)
      if (data && data.name) {
        setState((obj: any) => {
          let copy = updateObjectField(obj, data.name, data.value)
          if (stateRef.current.stateInterceptor) {
            copy = stateRef.current.stateInterceptor(data.name, copy, obj)
          }
          return copy
        })
      } else {
        errorLog('Invalid argument', param)
      }
    }
  }, [])

  const getState = useCallback(() => <T>(stateRef.current && stateRef.current.state), [])

  return [state, onChange, getState]
}
