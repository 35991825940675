import { Checkbox } from '@mui/material'
import React from 'react'

export interface CheckboxCellProps {
  model: any
  field: string
  onChange: (model: any, field: string, checked: boolean) => void
  readonly?: boolean
  size?: 'small' | 'medium'
}

export const CheckboxCell = ({
  model,
  field,
  onChange,
  readonly = false,
  size = undefined
}: CheckboxCellProps) => {
  const value = model[field]

  const handleChange = (e) => {
    onChange(model, field, e.target.checked)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      e.stopPropagation()
      onChange(model, field, value !== true)
    }
  }
  // Fix wg onKeyDown geht nicht..?
  return value ? (
    <Checkbox
      checked
      onChange={handleChange}
      onKeyDown={onKeyDown}
      disabled={readonly}
      size={size}
    />
  ) : (
    <Checkbox
      checked={false}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      disabled={readonly}
      size={size}
    />
  )
}
