import { Check, Close } from '@mui/icons-material'
import { green, red } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { ArtikelAbonniertStatusSymbol } from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { HkmEnum } from '@one/enums/HkmEnum'
import { PreisAenderungEintragChangeJson, PreisAenderungEintragJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { AppLink } from '@utils/ui/App/AppLink'
import { Button } from '@utils/ui/Buttons/Button'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Medal } from '@utils/ui/fields/Medal'
import { useContext, useMemo } from 'react'

export type PreisAenderungenArtikelTableProps = {
  liste: PreisAenderungEintragJson[]
  onSelect: (selected: Set<PreisAenderungEintragJson>) => void
  selected: Set<PreisAenderungEintragJson> | null
  changedRows?: Map<number, PreisAenderungEintragChangeJson>
  onToggleRow: (id: number) => void
  onSetChange: (selected: Set<PreisAenderungEintragJson>, accept: boolean) => void
}

export const PreisAenderungenArtikelTable = ({
  liste,
  onSelect,
  selected,
  changedRows,
  onToggleRow,
  onSetChange
}: PreisAenderungenArtikelTableProps) => {
  const { isGesellschafter } = useContext(AppContext)
  const { darkMode } = useContext(ThemeContext)
  const { et } = useEnums()

  const columnProps = useMemo<ArtikelDefaultColumnsProps<PreisAenderungEintragJson>>(
    () => ({
      isGesellschafter,
      fieldWrapper: {
        wrapper: 'artikel'
      },
      addColumnToIndex: [
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'id',
            header: 'Übernahme',
            headerTip: 'Preisänderung übernehmen?',
            align: 'center',
            valueGetter: (row) => changedRows?.get(row.id)?.accept ?? false,
            body: (row) => {
              const accepted = changedRows?.get(row.id)?.accept ?? false
              return row.vkPreislisteId == null ? (
                <Medal
                  text={accepted ? 'ja' : 'nein'}
                  icon={accepted ? <Check fontSize="small" /> : <Close fontSize="small" />}
                  backgroundColor={accepted ? green[500] : red[500]}
                  onDblClick={() => onToggleRow(row.id)}
                />
              ) : (
                <TooltipWrapper title="VK-Preisliste öffnen">
                  <AppLink
                    path={AppPaths.VkPreisPflegeFn(row.vkPreislisteId)}
                    text="VK-Preisliste bereits erstellt"
                  />
                </TooltipWrapper>
              )
            }
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ekPreis',
            header: 'EK?',
            headerTip: 'Einkaufspreisänderung?',
            align: 'center',
            type: 'boolean'
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'vkPreis',
            header: 'VK?',
            headerTip: 'Verkaufspreisänderung?',
            align: 'center',
            type: 'boolean'
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'gueltigVon',
            header: 'Gültig ab',
            type: 'datetime',
            hideIfEmpty: true
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'status',
            header: 'Abo',
            headerTip: 'Artikel-Abonnement-Status',
            align: 'center',
            body: (row) => {
              return <ArtikelAbonniertStatusSymbol value={row.aboStatus} et={et} />
            },
            valueGetter: (row) => et(HkmEnum.ArtikelAbonniertStatus, row.aboStatus)
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'preisMitEigenpflege',
            header: 'Eigenpflege?',
            align: 'center',
            type: 'boolean'
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'preisMitNeuKalkulation',
            header: 'Neukalkulation?',
            align: 'center',
            type: 'boolean'
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'preisMitEigenpflegeBisListungsaenderung',
            header: 'Eigenpflege bis Listungsänderung?',
            align: 'center',
            type: 'boolean'
          }
        }
      ]
    }),
    [changedRows, isGesellschafter, onToggleRow]
  )

  const columns = useArtikelColumns(columnProps)

  const actions = useMemo<DataTableAction<PreisAenderungEintragJson>[]>(
    () => [
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data) => AppPaths.ArtikelFn(data.artikel?.id)
      },
      {
        icon: 'edit',
        tooltip: 'Artikelselektion öffnen',
        getLink: (data) => AppPaths.ArtikelAboSelektionPflegeFn(data.aboSelektionId)
      }
    ],
    []
  )

  const bottomActions = (
    <>
      <Button
        label="übernehmen"
        onClickVoid={() => onSetChange(selected, true)}
        disabled={
          selected == null ||
          selected.size === 0 ||
          !Array.from(selected).some((a) => a.vkPreislisteId == null)
        }
      />
      <Button
        label="später übernehmen"
        onClickVoid={() => onSetChange(selected, false)}
        disabled={selected == null || selected.size === 0}
      />
    </>
  )

  return (
    <DataTableCard
      title="Artikel"
      name="PreisaenderungenArtikel"
      paging
      filterMode="both"
      columns={columns}
      actions={actions}
      value={liste}
      dense
      selectMode="multi"
      selectCol
      onSelect={onSelect}
      emptyMessage="Keine Preisänderungen gefunden."
      bottomActions={bottomActions}
    />
  )
}
