import { Grid } from '@mui/material'
import { formatISODate } from '@utils/dateutils'
import { useFormState } from '@utils/formstate'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { DateField } from '@utils/ui/fields/DateField'

export const ArtikelEtikettendruckVerschiebenDialog = ({ open, onClose }) => {
  const [state, onChange] = useFormState({ gueltigAb: null })

  const handleCancel = () => {
    onClose()
  }

  const handleSave = () => {
    onClose(formatISODate(state.gueltigAb))
  }
  const today = new Date().setHours(0, 0, 0, 0)
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  const actions = (
    <>
      <ApplyButton onClick={handleSave} disabled={!state.gueltigAb || state.gueltigAb < tomorrow} />
      <CancelButton onClick={handleCancel} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={handleCancel}
      actions={actions}
      fullWidth
      disabledFullscreen
      maxWidth="sm"
      title="Artikel auf spätern Etikettendruck verschieben"
    >
      <Frame>
        <FrameBody>
          <Grid container spacing={2} direction="row">
            <Grid item xs={6}>
              <DateField
                label="Gültig ab"
                name="gueltigAb"
                fullWidth
                value={state.gueltigAb}
                onChange={onChange}
                minDate={tomorrow}
              />
            </Grid>
          </Grid>
        </FrameBody>
      </Frame>
    </DialogEx>
  )
}
