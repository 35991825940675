import { ModeEdit, Visibility } from '@mui/icons-material'
import { FormGroup, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { api } from '@one/api'
import { useApiCaller } from '@utils/apicaller'
import { UploadButton } from '@utils/ui/Buttons/UploadButton'
import { GlassPlane } from '@utils/ui/planes/GlassPlane'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { useEffect, useState } from 'react'
import { AdminViewPanel } from './AdminViewPanel'

export type AVHelpProps = {
  zoomed: boolean
  setZoomed: () => void
}

export const AVHelp = ({ zoomed, setZoomed }: AVHelpProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [helpReadonly, setHelpReadonly] = useState(null)

  const triggerOvrHelpReadonly = (ro: boolean) => {
    setHelpReadonly(ro)
    apiCall<any>({
      method: 'POST',
      rest: 'help/ovrReadonly',
      params: { readonly: ro },
      onSuccess: (data) => setHelpReadonly(data?.readonly),
      onError: (error, r) => {
        setHelpReadonly(r?.data?.readonly)
        return false
      }
    })
  }

  useEffect(() => {
    apiCall<any>({
      method: 'GET',
      rest: 'help/ovrReadonly',
      onSuccess: (data) => {
        setHelpReadonly(data?.readonly)
      },
      onError: (error, r) => {
        setHelpReadonly(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminViewPanel
      // zoomed={zoomed}
      // setZoomed={setZoomed}
      title="Online-Hilfe"
      subtitle="Bereitstellung der Online-Hilfe und Testoptionen"
      actions={
        <UploadButton
          api={api}
          disabled={!helpReadonly}
          path="help/uploadHelpZip"
          accept=".zip"
          label="Hilfearchiv hochladen"
        />
      }
    >
      <GlassPlane show={apiBusy}>
        <WaitPlane wait={apiBusy}>
          <FormGroup>
            <ToggleButtonGroup
              exclusive
              style={{ paddingBottom: 8 }}
              value={helpReadonly ? 1 : 0}
              onChange={(e, v) => {
                if (v != null) {
                  triggerOvrHelpReadonly(v === 1)
                }
              }}
              size="small"
              data-name="helpmode-switch"
            >
              <ToggleButton value={0} data-name="editmode" size="small">
                <ModeEdit fontSize="small" />
                <span style={{ marginLeft: 4 }}>Bearbeitungsmodus</span>
              </ToggleButton>
              <ToggleButton value={1} data-name="readonly" size="small">
                <Visibility fontSize="small" />
                <span style={{ marginLeft: 4 }}>Lesemodus</span>
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="subtitle2" fontSize="80%" paddingBottom={1}>
              Die Umschaltung ist nur temporär für Testzwecke nutzbar!
              <br /> Eine Umschaltung ist nur möglich, wenn der Bearbeitungsmodus in der Anwendung
              konfiguriert wurde. Nur im Lesemodus kann ein Online-Hilfe-ZIP hochgeladen werden.
            </Typography>
          </FormGroup>
        </WaitPlane>
      </GlassPlane>
    </AdminViewPanel>
  )
}
