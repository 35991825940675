import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { MapperBearbeitenJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { useQuery } from '@utils/routing'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useMemo } from 'react'
import { ArtikelLieferantSyncTemplatePflegeKopfView } from './ArtikelLieferantSyncTemplatePflegeKopfView'
import { ArtikelLieferantSyncTemplatePflegeTabelleView } from './ArtikelLieferantSyncTemplatePflegeTabelleView'
import {
  MapperBearbeitenEdit,
  editMutator,
  saveMutator,
  updateModel,
  updateModelGroup
} from './model/ArtikelLieferantSyncModel'

export const ArtikelLieferantSyncTemplatePflegeView = () => {
  const { id, typ, cloneId } = useContext(RouteContext) as any
  const [{ pim }] = useQuery()
  const appContext = useContext(AppContext)
  const isAdminMode = appContext?.session?.adminMode

  const { replaceHistory } = useContext(AppSwitchCtx)

  const validate = useCallback(
    (model: MapperBearbeitenEdit) => {
      if (model?.einstellung?.name == null) {
        return { name: 'Name muss angegeben werden' }
      }
      const standardStufe = model?.einstellung?.standardStufe
      if (isAdminMode && standardStufe == null) {
        return { name: 'Es muss eine Standartstufe angegeben werden' }
      }
      if (standardStufe !== null && (standardStufe < 1 || standardStufe > 5)) {
        return { name: 'Die Standartstufe muss einen Wert zwischen 1 und 5 haben' }
      }
    },
    [isAdminMode]
  )

  const onSave = useCallback(
    (savedModel) => {
      if (savedModel?.einstellung?.id) {
        replaceHistory(() =>
          AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(
            savedModel.einstellung.typ,
            savedModel.einstellung.id
          )
        )
      }
    },
    [replaceHistory]
  )

  const { model, isNew, isChanged, uiLock, onValueChange, save, reload, updModel } = useModelMgr<
    MapperBearbeitenJson,
    MapperBearbeitenEdit
  >({
    id: cloneId || id,
    api,
    title: 'Pflege LiSST/ArSST Einstellung',
    rest: 'mapper',
    restps: { typ, clone: Boolean(cloneId), pim: pim != null && 'true' },
    init: { einstellung: { typ } },
    validate,
    editMutator,
    saveMutator,
    onSave,
    eventName: typ === 'ARRST' ? EventNames.ARSST : EventNames.LISST
  })

  const toggleAll = useCallback(
    (value: boolean) => {
      updModel((old) => updateModel(old, value))
    },
    [updModel]
  )

  const toggleGroup = useCallback(
    (groupName: string) => {
      updModel((old) => updateModelGroup(old, groupName))
    },
    [updModel]
  )

  const readOnly = useMemo(
    () => (!isAdminMode && model?.einstellung?.vorgabe) || model.einstellung.standardEH,
    [isAdminMode, model]
  )

  return (
    <StatePlane
      uiLock={uiLock}
      altLink={AppPaths.synchronisation.ArtikelLieferantSyncTemplateUebersicht}
    >
      <Frame space>
        <FrameRow>
          <ArtikelLieferantSyncTemplatePflegeKopfView
            cloneId={cloneId}
            model={model.einstellung}
            onValueChange={onValueChange}
            readOnly={readOnly}
          />
        </FrameRow>
        <FrameBody>
          <ArtikelLieferantSyncTemplatePflegeTabelleView
            elemente={model.elemente}
            onValueChange={onValueChange}
            toggleAll={toggleAll}
            readOnly={readOnly}
            toggleGroup={toggleGroup}
            groupMap={model.groupMap}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton
              isNew={isNew}
              isChanged={isChanged}
              onClickVoid={save}
              visible={!readOnly}
            />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClick={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
