import { useEffect, useState } from 'react'
import { api } from './api'
import { apiCreateAbort, useApiCaller } from './utils/apicaller'

export type ServerIdent = {
  envName: string
  version: string
}

export const useServerIdent = (): [ServerIdent, boolean] => {
  const [state, setState] = useState({
    envName: null,
    version: null
  })

  const [apiCall, apiBusy] = useApiCaller(api)

  useEffect(() => {
    const abc = apiCreateAbort()
    apiCall<any>({
      method: 'GET',
      rest: 'ident',
      signal: abc.signal,
      onSuccess: (data) => setState(data),
      onError: () => true // ignore...
    })
    return () => abc.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, apiBusy]
}
