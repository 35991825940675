import { MessageID } from '@one/MessageIDs'
import { UseCaseStateJson } from '@one/typings/apiTypings'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { useCallback } from 'react'

export const useConfirmAboDelete = () => {
  const { askToConfirm } = useMessageDialog()

  const onConfirmDelete = useCallback(
    (error: UseCaseStateJson, onConfirm: VoidFunction) => {
      if (
        error.mainMessage?.messageId?.id ===
        MessageID.IDE_ABO_SELEKTION_DELETE_HAUPT_SELEKTION_DIFFERENT
      ) {
        askToConfirm({
          title: 'Hauptselektion verhindert löschen',
          message: error.mainMessage.message,
          confirmLabel: 'Trotzdem Löschen',
          severity: 'severe',
          onConfirm
        })
        return true
      }
      return false
    },
    [askToConfirm]
  )

  return onConfirmDelete
}
