import { SelectField } from '@utils/ui/fields/SelectField'

export interface VPEFieldProps {
  mengeneinheiten: any[]
  value?: any
  model?: any
  onChange?: (model: any, field: any, value: any) => void
  field?: string
  readonly?: boolean
}

export const VPEField = ({
  value,
  field,
  onChange,
  model,
  mengeneinheiten,
  readonly = false
}: VPEFieldProps) => {
  const rv = (field && model ? model[field] : value) || null

  if (readonly) {
    if (rv) {
      const me = mengeneinheiten.find((i) => i.id === rv)
      if (me) {
        return <div>{me.bez}</div>
      }
    }
    return null
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(model, e.target.name, e.target.value)
    }
  }

  return (
    <SelectField
      value={rv}
      name={field}
      onChange={handleChange}
      disabled={readonly}
      fullWidth
      renderItem={(a) => a.bez}
      options={mengeneinheiten}
      required
    />
  )
}
