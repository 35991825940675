import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  ArtikelVerteilungDefinitionDisplayJson,
  ArtikelVerteilungDefinitionSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace } from '@utils/utils'
import { useCallback } from 'react'
import { ArtikelVerteilerUebersichtParams } from './ArtikelVerteilerUebersichtParams'
import { ArtikelVerteilerUebersichtTable } from './ArtikelVerteilerUebersichtTable'

export const ArtikelVerteilerUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    ArtikelVerteilungDefinitionSearcherCriteriaJson,
    ArtikelVerteilungDefinitionDisplayJson
  >({
    api,
    url: '/artikelverteiler',
    initialParams: {
      name: ''
    }
  })

  const { askToDelete } = useMessageDialog()

  const [apiCall, apiBusy] = useApiCaller(api)

  const handleDeleteClick = useCallback(
    (row: any) => () => {
      const quote = (text) => (text ? `"${text}"` : '')
      askToDelete({
        title: `Artikelverteiler ${quote(row.name)} wirklich löschen`,
        onConfirm: () => {
          apiCall<any>({
            method: 'POST',
            rest: `/artikelverteiler/delete`,
            params: { id: row.id },
            onErrorMsg: `Artikelverteiler ${quote(row.name)} konnte nicht gelöscht werden.`,
            onSuccessMsg: `Artikelverteiler ${quote(row.name)} erfolgreich gelöscht.`,
            onSuccess: () => setResult((rs) => arrayItemReplace(rs, (r) => r.id === row.id, null))
          })
        }
      })
    },
    [askToDelete, apiCall, setResult]
  )

  useObserver(EventNames.ARTIKELVERTEILER, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <ArtikelVerteilerUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <ArtikelVerteilerUebersichtTable result={result} onDelete={handleDeleteClick} />
      </FrameBody>
    </Frame>
  )
}
