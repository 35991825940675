import { green, grey, lightBlue, orange, red, yellow } from '@mui/material/colors'
import { EkPreisListeImportStatus } from '@one/typings/apiTypings'
import { ThemeContext } from '@utils/ui/Theme'
import { Medal } from '@utils/ui/fields/Medal'
import { useContext } from 'react'

export type ImportStatusCellProps = {
  status: EkPreisListeImportStatus
  text: string
}

export const ImportStatusCell = ({ status, text }: ImportStatusCellProps) => {
  const { darkMode } = useContext(ThemeContext)

  if (status == null) {
    return null
  }

  const idx = darkMode ? 900 : 400

  const statusColor = () => {
    switch (status) {
      case EkPreisListeImportStatus.NEU:
        return lightBlue[idx]

      case EkPreisListeImportStatus.ERLEDIGT:
        return grey[idx]

      case EkPreisListeImportStatus.IMPORTIERT:
      case EkPreisListeImportStatus.VALID:
        return green[idx]

      case EkPreisListeImportStatus.FEHLER:
        return red[idx]

      case EkPreisListeImportStatus.INVALID:
        return orange[idx]

      case EkPreisListeImportStatus.IMPORT:
      case EkPreisListeImportStatus.VALIDATE:
        return yellow[idx]
    }
  }

  return <Medal text={text} backgroundColor={statusColor()} fullWidth />
}
