import { Icon } from '@mui/material'
import {
  HauptlieferantPflegeEintragJson,
  HauptlieferantPflegeEintragLieferantJson,
  LieferantDisplayJson
} from '@one/typings/apiTypings'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { asNumber, formatMoney2 } from '@utils/utils'
import { useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  unavailable: {
    color: 'red'
  },
  underline: {
    textDecoration: 'underline'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: '5px',
    transform: 'scale(0.8)'
  },
  iconUnchecked: {
    cursor: 'pointer'
  }
})

export type HauptLieferantenCellProps = {
  eintrag?: HauptlieferantPflegeEintragJson
  hauptlieferant?: HauptlieferantPflegeEintragLieferantJson
  lieferantenMap?: { [id: number]: LieferantDisplayJson }
  onClickLieferant?: (artikel: any, lieferantId: number) => (e: any) => void
  showRadioButtons?: boolean
  onlyFavoredLieferant?: boolean
}

export const HauptLieferantenCell = ({
  eintrag,
  hauptlieferant,
  lieferantenMap = [],
  onClickLieferant,
  showRadioButtons,
  onlyFavoredLieferant
}: HauptLieferantenCellProps) => {
  const { classes } = useStyles()

  const getLieferantEntry = useCallback(
    (artikelLieferant) => {
      const lieferant = lieferantenMap[artikelLieferant.lieferantId]
      let res = ''
      if (lieferant && lieferant.name1) {
        res += `(${lieferant.nummer}) ${lieferant.name1}`
      }
      if (artikelLieferant.ekFreiHaus) {
        res += `: ${formatMoney2(artikelLieferant.ekFreiHaus)}€ pro ${
          artikelLieferant.preismenge
        } ${artikelLieferant.mengenEinheit}`
      }
      return res
    },
    [lieferantenMap]
  )

  const isLieferantAvailable = useCallback((entry: HauptlieferantPflegeEintragLieferantJson) => {
    return !entry?.geloescht && !entry.gesperrt && !entry.ausgelistet
  }, [])

  const unavailableLieferanten = [<div key="unavail">Nicht verfügbare Lieferanten:</div>]

  const availableLieferanten = eintrag.lieferanten
    .filter((lieferant) => lieferant && lieferant.preisEbeneId === null)
    .sort((a, b) => {
      if (a.ekFreiHaus && !b.ekFreiHaus) {
        return -1
      }
      if (b.ekFreiHaus && !a.ekFreiHaus) {
        return 1
      }
      if (a.ekFreiHaus === b.ekFreiHaus) {
        const idA = asNumber(lieferantenMap[a.lieferantId]?.nummer)
        const idB = asNumber(lieferantenMap[b.lieferantId]?.nummer)
        if (idA != null && idB != null) {
          return idA - idB
        }
      }
      return a.ekFreiHaus - b.ekFreiHaus
    })
    .map((entry) => {
      if (!entry) {
        return null
      }
      if (!isLieferantAvailable(entry)) {
        unavailableLieferanten.push(<div>{getLieferantEntry(entry)}</div>)
        return null
      }
      const entryHtml = getLieferantEntry(entry)
      const isFavoredLieferant = onlyFavoredLieferant
        ? (!entry.bevorzugterHauptlieferant && eintrag.nichtBevorzugterHauptLieferant) ||
          entry.bevorzugterHauptlieferant
        : true

      return (
        <TooltipWrapper
          key={`cell-lieferant-${entry.lieferantId}`}
          title={entry.bevorzugterHauptlieferant ? 'Bevorzugter Lieferant' : null}
        >
          <div
            className={`${classes.cell} ${
              entry.bevorzugterHauptlieferant ? classes.underline : ''
            }`}
          >
            {showRadioButtons && (
              <Icon
                className={`${classes.icon} ${classes.iconUnchecked}`}
                color="inherit"
                onClick={onClickLieferant(eintrag, entry.lieferantId)}
              >
                {hauptlieferant &&
                hauptlieferant.lieferantId === entry.lieferantId &&
                isFavoredLieferant
                  ? 'radio_button_checked'
                  : 'radio_button_unchecked'}
              </Icon>
            )}
            {hauptlieferant && hauptlieferant.lieferantId === entry.lieferantId ? (
              <b>{entryHtml}</b>
            ) : (
              entryHtml
            )}
          </div>
        </TooltipWrapper>
      )
    })

  return (
    <>
      {[
        ...availableLieferanten.filter(Boolean),
        ...(unavailableLieferanten.length > 1 ? unavailableLieferanten : [])
      ]}
    </>
  )
}
