import { Button, ButtonProps } from './Button'

export type ResetButtonProps = {
  visible?: ButtonProps['visible']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  size?: ButtonProps['size']
}

export const ResetButton = ({ onClick, onClickVoid, visible = true, size }: ResetButtonProps) => (
  <Button
    label="Zurücksetzen"
    onClick={onClick}
    onClickVoid={onClickVoid}
    variant="contained"
    color="error"
    visible={visible}
    size={size}
  />
)
