import { Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelAboSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelAboSection'
import { ArtikelEanTable } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelEanTable'
import { ArtikelErpEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpEkPreise'
import { ArtikelErpVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpVkPreise'
import { ArtikelErpLabelTableView } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpLabelTableView'
import { ArtikelErpLieferanten } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpLieferanten'
import { ArtikelListenSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelListenSection'
import { ArtikelMengeneinheiten } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelMengeneinheiten'
import { ArtikelWarengruppenDz } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelWarengruppenDz'
import { ArtikelAbonniertStatusMedal } from '@one/components/common/ArtikelAbonniertStatusMedal'
import { ArtikelBetriebstypCell } from '@one/components/common/ArtikelBetriebstypCell'
import { ArtikelQuelle, DzArtikelAnzeigenJson, SuchArtikelTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { HistoricDataPager } from '@utils/ui/HistoricDataPager'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { ArtikelErpListenPreise } from '../details/ArtikelErpListenPreise'
import { StandortArtikelRegalplatz } from '@one/components/Artikel/ArtikelAnzeigen/details/StandortArtikelRegalplatz'
import { ArtikelErpHauptlieferant } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpHauptlieferant'

const useStyles = makeStyles()((theme: any) => ({
  header: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px'
    }
  },
  historicPagerWrapper: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px !important'
    }
  }
}))

export type DzArtikelViewProps = {
  data?: DzArtikelAnzeigenJson
  reload: () => void
}

export const DzArtikelView = ({ reload, data }: DzArtikelViewProps) => {
  const { classes } = useStyles()
  const { isWithEkPreis, isWithVkPreis, isWithErpImport } = useContext(AppContext)
  const navigate = useNavigate()
  const display = data.display
  const id = display.id
  const artikel = data.artikel
  const revision = data.revision
  const aboStatus = data.details?.aboStatus
  const title = artikel?.bez1 ? `"${artikel.bez1}"` : ''
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)

  const hauptEan = useMemo(() => {
    return artikel?.eans?.find((ean) => ean.hauptEAN)?.ean || null
  }, [artikel])

  const topActions = useMemo(
    () =>
      [
        data.partnerArtikelId
          ? ({
              navlink: AppPaths.ArtikelFn(data.partnerArtikelId),
              text:
                (data.partnerArtikelQuelle === ArtikelQuelle.ALLIANZ && 'Zum Allianzartikel') ||
                (data.partnerArtikelQuelle === ArtikelQuelle.ERP && 'Zum ERP-Artikel') ||
                'Zum Listungsartikel',
              variant: 'outlined'
            } as Action)
          : null
      ].filter(Boolean),
    [data.partnerArtikelId, data.partnerArtikelQuelle]
  )
  const historicNavigate = useCallback(
    (revision) => navigate(AppPaths.ArtikelExFn(id, revision.id, null)),
    [navigate, id]
  )

  const header = useMemo(
    () => (
      <Grid container spacing={3} className={classes.header}>
        <Grid item>
          <Typography variant="h6">{`${data.quelle === ArtikelQuelle.ALLIANZ ? 'Allianzartikel' : 'ERP-Artikel'} ${title}`}</Typography>
        </Grid>
        {aboStatus && (
          <Grid item>
            <ArtikelAbonniertStatusMedal value={aboStatus} et={et} darkMode={darkMode} />
          </Grid>
        )}
        <Grid item className={classes.historicPagerWrapper}>
          <HistoricDataPager
            revisions={data.revisions}
            currentRevision={revision}
            to={historicNavigate}
          />
        </Grid>
      </Grid>
    ),
    [
      classes.header,
      classes.historicPagerWrapper,
      data.quelle,
      data.revisions,
      title,
      aboStatus,
      et,
      darkMode,
      revision,
      historicNavigate
    ]
  )

  const content = [
    {
      id: 'bezeichnung',
      label: 'Bezeichnung',
      body: () => (
        <CardEx title="Bezeichnung">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StaticField label="Bezeichnung 1" value={artikel.bez1} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StaticField label="Bezeichnung 2" value={artikel.bez2} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StaticField label="Bezeichnung 3" value={artikel.bez3} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StaticField label="Bezeichnung 4" value={artikel.bez4} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StaticField label="Bontext" value={artikel.bontext} />
            </Grid>
          </Grid>
        </CardEx>
      )
    },
    {
      id: 'kennzeichen',
      label: 'Kennzeichen',
      body: () => (
        <CardEx title="Kennzeichen">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <StaticField label="hage-Nr." value={artikel.hageNummer} />
            </Grid>
            <Grid item xs={8}>
              <StaticField label="BT-Nr." value={artikel.btNummer} />
            </Grid>
            <Grid item xs={4}>
              <StaticField label="B-Typ" formStyle={{ minWidth: '12rem' }}>
                <ArtikelBetriebstypCell value={artikel.betriebsTyp} all name="betriebsTyp" />
              </StaticField>
            </Grid>
            <Grid item xs={4}>
              <StaticField label="Haupt-EAN" value={hauptEan} />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <TooltipWrapper title={artikel.artikelArt?.ignoriert ? 'ignorierte Artikelart' : ''}>
                <StaticField
                  label="Artikelart"
                  style={artikel.artikelArt?.ignoriert ? { color: 'grey' } : {}}
                  value={`${artikel.artikelArt?.bez ?? ''} ${
                    artikel.artikelArt?.kurz ? `(${artikel.artikelArt?.kurz})` : ''
                  }`}
                />
              </TooltipWrapper>
            </Grid>
            <Grid item xs={4}>
              <StaticField label="Matchcode" value={artikel.matchcode} />
            </Grid>
            <Grid item xs={8}>
              <StaticField label="Lagerkennzeichen" value={artikel.minEinLager ? '+' : ''} />
            </Grid>
            <Grid item xs={4}>
              <StaticField label="Online" value={artikel.online ? 'J' : 'N'} />
            </Grid>
          </Grid>
        </CardEx>
      )
    },
    {
      id: 'masse',
      label: 'Maße',
      body: () => (
        <CardEx title="Maße">
          <Grid container spacing={2}>
            <Grid item xs={3} md={2}>
              <StaticField label="Länge" value={artikel.laenge} />
            </Grid>
            <Grid item xs={3} md={2}>
              <StaticField label="Breite" value={artikel.breite} />
            </Grid>
            <Grid item xs={3} md={2}>
              <StaticField label="Höhe" value={artikel.hoehe} />
            </Grid>
            <Grid item xs={3} md={6} />
            <Grid item xs={12}>
              <StaticField label="Gewicht" value={artikel.gewicht} />
            </Grid>
          </Grid>
        </CardEx>
      )
    },
    {
      id: 'status',
      label: 'Status',
      body: () => (
        <CardEx title="Status">
          <Grid container spacing={2}>
            {isWithErpImport && (
              <>
                <Grid item xs={6} md={4} lg={2}>
                  <Checkbox
                    disabled
                    labelNotDisabled
                    indeterminate
                    label="Importfehler"
                    checked={data.importErrorStatus?.importFehlerData}
                  />
                </Grid>
                {isWithEkPreis && (
                  <Grid item xs={6} md={4} lg={2}>
                    <Checkbox
                      disabled
                      labelNotDisabled
                      indeterminate
                      label="Importfehler (Preise - EK)"
                      checked={data.importErrorStatus?.importFehlerPreiseEk}
                    />
                  </Grid>
                )}
                {isWithVkPreis && (
                  <Grid item xs={6} md={4} lg={2}>
                    <Checkbox
                      disabled
                      labelNotDisabled
                      indeterminate
                      label="Importfehler (Preise - VK)"
                      checked={data.importErrorStatus?.importFehlerPreiseVk}
                    />
                  </Grid>
                )}
              </>
            )}
            {(isWithEkPreis || isWithVkPreis) && (
              <Grid item xs={6} md={4} lg={2}>
                <Checkbox
                  disabled
                  labelNotDisabled
                  indeterminate
                  label="Archivierter Artikel"
                  checked={artikel.archiviert}
                />
              </Grid>
            )}
            <Grid item xs={6} md={4} lg={2}>
              <Checkbox
                label="Rabattfähig"
                checked={artikel.rabattFaehig}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Checkbox
                label="Skontofähig"
                checked={artikel.skontoFaehig}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Checkbox
                label="Aufmassliste"
                checked={artikel.aufmassliste}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Checkbox label="Mhd" checked={artikel.mhd} disabled labelNotDisabled indeterminate />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Checkbox
                label="Eckpreis"
                checked={artikel.eckpreis}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Checkbox
                label="Dauerniedrigpreis"
                checked={artikel.dnp}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
          </Grid>
        </CardEx>
      ),
      exclude: artikel == null
    },
    {
      id: 'eans',
      label: 'EANs',
      body: () => <ArtikelEanTable eans={artikel.eans} />,
      exclude: artikel == null
    },
    {
      id: 'mengeneinheiten',
      label: 'Mengeneinheiten',
      body: () => <ArtikelMengeneinheiten artikel={artikel} mode="dz" />,
      exclude: artikel == null
    },
    {
      id: 'warengruppen',
      label: 'Warengruppen',
      body: () => <ArtikelWarengruppenDz artikel={artikel} />,
      exclude: artikel == null
    },
    {
      id: 'lieferanten',
      label: 'Lieferanten',
      body: () => <ArtikelErpLieferanten details={data.details} />,
      exclude: data?.details == null
    },
    {
      id: 'hauptlieferant',
      label: 'Hauptieferanten',
      body: () => <ArtikelErpHauptlieferant details={data.details} />,
      exclude: data?.details == null
    },
    {
      id: 'regalplaetze',
      label: 'Regalplätze',
      body: () => <StandortArtikelRegalplatz regalplaetze={data.details?.regalplaetze} />,
      exclude: !(data.details?.regalplaetze?.length > 0)
    },
    {
      id: 'artikelabos',
      label: 'Artikelabos',
      body: () => <ArtikelAboSection selektionen={data.details?.selektionen} />,
      exclude: !(data.details?.selektionen?.length > 0)
    },
    {
      id: 'manuelleListen',
      label: 'Artikellisten',
      body: () => <ArtikelListenSection manuelleListen={data.details?.manuelleListen} />,
      exclude: !(data.details?.manuelleListen?.length > 0)
    },
    {
      id: 'label',
      label: 'Artikellabels',
      body: () => (
        <ArtikelErpLabelTableView
          labels={data.details?.labels}
          artikel={{ typ: SuchArtikelTyp.DEZENTRAL, id }}
          reload={reload}
        />
      ),
      exclude: !(data.details?.labels?.length > 0)
    },
    {
      id: 'ekpreise',
      label: 'EK-Preise',
      body: () => (
        <ArtikelErpEkPreise
          ekPreise={data.details?.ekPreise}
          konditionenDefinitionen={data.details?.konditionenDefinitionen}
        />
      ),
      exclude: !(data.details?.ekPreise?.length > 0)
    },
    {
      id: 'vkpreise',
      label: 'VK-Preise',
      body: () => (
        <ArtikelErpVkPreise
          vkPreise={data.details?.vkPreise}
          preisGruppen={data?.details?.vkPreisgruppen}
          mengeneinheiten={data.details?.mengeneinheiten}
        />
      ),
      exclude: !(data.details?.vkPreise?.length > 0)
    },
    {
      id: 'listenPreise',
      label: 'Listenpreise',
      body: () => <ArtikelErpListenPreise listenPreise={data.details?.listenPreise} />,
      exclude: !(data.details?.listenPreise?.length > 0)
    }
  ] as IndexPanelItem[]

  return (
    <Frame space>
      <FrameRow>
        <CardEx
          backLink
          overviewLink={AppPaths.ArtikelUebersicht}
          topActions={topActions}
          header={header}
          contentStyle={{ paddingBottom: 0 }}
        />
      </FrameRow>
      <FrameBody>
        <IndexPanel content={content} />
      </FrameBody>
      <FrameRow>
        <ButtonRow>
          <ReloadButton onClick={reload} />
        </ButtonRow>
      </FrameRow>
    </Frame>
  )
}
