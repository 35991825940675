import { AppPaths } from '@one/AppPaths'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantenListeDisplayJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { useMemo } from 'react'

export interface LieferantDetailsListenProps {
  listen: LieferantenListeDisplayJson[]
  loading: boolean
}

export const LieferantDetailsListen = ({ listen, loading }: LieferantDetailsListenProps) => {
  const { et } = useEnums()
  const columns: Column[] = useMemo(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: 'typ',
        header: 'Typ',
        body: (row: any) => et(HkmEnum.LieferantenListeTyp, row.typ)
      },
      {
        field: 'lieferantenAnzahl',
        header: 'Lieferantenanzahl',
        sortable: true
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'arrow_forward',
        tooltip: 'Lieferantenliste öffnen',
        getLink: (data: any) => AppPaths.lieferanteliste.LieferantenListePflegeFn(data.id)
      }
    ],
    []
  )

  return (
    <CardEx title="Manuelle Lieferantenlisten">
      <ScrollPanel autoHeight={true}>
        <DataTable
          name="LieferantDetailsTable"
          columns={columns}
          value={listen}
          loading={loading}
          actions={tableActions}
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}
