import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { EkPreisListeImportStatus } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { FrameBody, FrameRow } from '@utils/ui/Frame'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { StaticField } from '@utils/ui/fields/StaticField'
import { Switch } from '@utils/ui/fields/Switch'
import { EkPreisImportZeileStatus } from './fields/EkPreisImportZeilenStatus'
import { EkPreisListeImportEdit } from './model/EkPreisImportUsecase'

export interface EkPreisImportPflegeStep7FinishProps {
  model: EkPreisListeImportEdit
  onValueChange: ValueChangeFn<EkPreisListeImportEdit>
}

export const EkPreisImportPflegeStep7Finish = ({
  model,
  onValueChange
}: EkPreisImportPflegeStep7FinishProps) => {
  return (
    <>
      <FrameBody>
        <CardEx title="Import erfolgreich" height="100%">
          <ScrollPanel>
            <Grid container spacing={2} direction="row" padding={1}>
              <Grid item xs={12} marginBottom={4}>
                <EkPreisImportZeileStatus
                  fehlerZeilen={model.fehlerZeilen}
                  anzahlFehler={model.anzahlFehler}
                  anzahlZeilen={model.anzahlZeilen}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} xl={3}>
                <StaticField
                  label="Anzahl der Artikel, für die neue EK-Preisblätter erzeugt werden"
                  value={model.anzahlArtikelEKPreisblaetterErzeugt || 0}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} xl={3}>
                <StaticField
                  label="Anzahl der Artikel, die nicht identifiziert wurden"
                  value={model.anzahlArtikelNichtIdentifiziert || 0}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} xl={3}>
                <StaticField
                  label="Anzahl der in prohibis identifizierten Artikel"
                  value={model.anzahlProhibisIdentifiziertenArtikel || 0}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} xl={3}>
                <StaticField
                  label="Anzahl der als 'Duplikate bepreisen' markierten Artikel"
                  value={model.anzahlDuplikateBepreisen || 0}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <StaticField
                  label="Anzahl nicht getroffener Artikel"
                  value={model.anzahlNichtGetroffenerArtikel || 0}
                  fullWidth
                />
              </Grid>
            </Grid>
          </ScrollPanel>
        </CardEx>
      </FrameBody>
      <FrameRow>
        {model.status !== EkPreisListeImportStatus.ERLEDIGT ? null : (
          <Grid container direction="row" padding={1} justifyContent="end" spacing={2}>
            <Grid item>
              <Button
                label={model.preisliste ? 'Preisliste öffnen' : 'Die Preisliste wurde gelöscht'}
                variant="outlined"
                disabled={model.preisliste == null}
                to={AppPaths.EkPreisMassenPflegeFn(model.preisliste?.id)}
              />
            </Grid>
            {model.aktStep?.artikelListeRef == null ? null : (
              <Grid item>
                <Button
                  label="Vorgang VK-Preisliste öffnen"
                  variant="outlined"
                  to={AppPaths.VkPreisAnlageFn({
                    lieferantId: model.lieferant.id,
                    artikelListeRef: model.aktStep.artikelListeRef
                  })}
                />
              </Grid>
            )}
          </Grid>
        )}
        {model.status === EkPreisListeImportStatus.ERLEDIGT ? null : (
          <Grid container direction="row" padding={1} justifyContent="end">
            <Grid item>
              <Switch
                size="small"
                checked={model.kondiListeFreigeben}
                name="kondiListeFreigeben"
                onChange={onValueChange}
                label="EK-Preisliste freigeben"
              />
            </Grid>
            <Grid item>
              <Switch
                size="small"
                checked={model.kondiListeCreateVkPreisvorgang}
                name="kondiListeCreateVkPreisvorgang"
                onChange={onValueChange}
                disabled={!model.kondiListeFreigeben}
                label="VK-Preisvorgang erzeugen"
              />
            </Grid>
          </Grid>
        )}
      </FrameRow>
    </>
  )
}
