import { Grid } from '@mui/material'
import { DashboardJson, SyncInfoJson, SyncState } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StatusItem } from './StatusItem'
import { SyncStatusItem } from './SyncStatusItem'

export interface StatusHeaderProps {
  data: DashboardJson
  syncStatus: SyncInfoJson
}

export const StatusHeader = ({ data, syncStatus }: StatusHeaderProps) => {
  const { countArtAboNeu, countArtAboGeaendert, countArtAboGesperrt } = data
  const artikelCounts = `${countArtAboNeu || 0}/${countArtAboGeaendert || 0}/${
    countArtAboGesperrt || 0
  }`
  const { countLiefAboNeu, countLiefAboGeaendert, countLiefAboGesperrt } = data
  const lieferantCounts = `${countLiefAboNeu || 0}/${countLiefAboGeaendert || 0}/${
    countLiefAboGesperrt || 0
  }`
  return (
    <CardEx title="Dashboard" backLink collapsiable>
      <Grid container direction="row" spacing={2}>
        {syncStatus && (
          <SyncStatusItem
            title="ERP Synchronisation"
            icon="hourglass_bottom"
            count={1}
            progress={syncStatus.progress}
            isAktiv={syncStatus.syncState === SyncState.RUNNING}
            startTime={syncStatus.start}
            endTime={syncStatus.end}
            hasError={syncStatus.syncState === SyncState.ERROR}
          />
        )}
        <StatusItem
          title="Lieferanten"
          count={data.countLief}
          icon="local_shipping"
          info="gesamt"
          name="LieferantenGesamt"
        />
        <StatusItem
          title="Lieferanten"
          count={data.countLiefAbo}
          icon="local_shipping"
          info="abonniert"
          name="LieferantenAboniert"
        />

        <StatusItem
          title="Lieferanten"
          count={lieferantCounts}
          icon="local_shipping"
          info="neu/geändert/gesperrt"
          name="LieferantenGeaendert"
        />

        <StatusItem
          title="Artikel"
          count={data.countArt}
          icon="view_agenda"
          info="gesamt"
          name="ArtikelGesamt"
        />

        <StatusItem
          title="Artikel"
          count={data.countArtAbo}
          icon="view_agenda"
          info="abonniert"
          name="ArtikeLAboniert"
        />

        <StatusItem
          title="Artikel"
          count={artikelCounts}
          icon="view_agenda"
          info="neu/geändert/gesperrt"
          name="ArtikelGeaendert"
        />

        {/* <StatusItem
            title="ERP-Konflikte"
            count={articleInfo.countKonflikte }
            icon="view_agenda"
            info="Anzahl"
            color={blue[400]}
            name="ERPKonflikte"
          /> */}

        <StatusItem
          title="Pool Artikel"
          count={data.countPool}
          icon="view_agenda"
          info="Anzahl"
          name="PoolArtikel"
        />
        <StatusItem
          title="Artikel"
          count={data.countArtExport}
          icon="view_agenda"
          info="zu exportieren"
          name="ArtikelZuExportieren"
        />
      </Grid>
    </CardEx>
  )
}
