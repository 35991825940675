import { EventNames } from '@one/EventNames'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { formatNeonKontext } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import { PreisEbeneJson, PreisEbenenBearbeitenJson, PreisEbenenJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { getValueComparator } from '@utils/ui/DataTable/DataTableUtils'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { aidOf, nameOf, sortArray } from '@utils/utils'
import { useContext, useMemo } from 'react'

export const PreisEbenenPflegeView = () => {
  const { checkUserRole, isPIMModel, isAllianz } = useContext(AppContext)

  const { model, updModel, onValueChange, save, reload, errors, isChanged, isNew, uiLock } =
    useModelMgr<PreisEbenenJson, PreisEbenenJson, PreisEbenenBearbeitenJson>({
      noid: true,
      api,
      unwrapField: 'preisEbenen',
      title: 'Preisebenen',
      rest: 'gesellschafter/preisebenen',
      eventName: EventNames.PREISEBENEN,
      editMutator: (payload) => {
        const preisebenen = [...payload.preisebenen]
        sortArray(
          preisebenen,
          getValueComparator('asc', (pe) => pe.reihe)
        )
        return { ...payload, preisebenen }
      }
    })

  const onReorderRows = (rows: PreisEbeneJson[]) => {
    updModel((old: PreisEbenenJson) => ({
      ...old,
      preisebenen: rows.map((r, idx) => ({ ...r, reihe: idx + 1 }))
    }))
  }

  const readonly = !checkUserRole(UserRoles.GS_EIN_EDITOR)

  const columns = useMemo<Column<PreisEbeneJson>[]>(() => {
    return [
      {
        field: nameOf<PreisEbeneJson>('name'),
        header: 'Name',
        sortable: false,
        width: '10em'
      },
      {
        field: nameOf<PreisEbeneJson>('nr'),
        header: 'Nummer',
        sortable: false,
        align: 'right',
        width: '10em'
      },
      {
        off: !isPIMModel,
        field: nameOf<PreisEbeneJson>('kontext'),
        header: 'Kontext',
        sortable: false,
        width: '10em',
        valueGetter: (row) => formatNeonKontext(row.kontext)
      },
      {
        field: nameOf<PreisEbeneJson>('ausgeblendet'),
        header: 'Ausblenden',
        align: 'center',
        width: '1em',
        sortable: false,
        body: (row) => (
          <Checkbox
            compact
            checked={row.ausgeblendet}
            size="small"
            name={`preisebenen.[__aid=${aidOf(row)}].ausgeblendet`}
            onChange={onValueChange}
            disabled={readonly}
          />
        )
      },
      {
        field: nameOf<PreisEbeneJson>('stufe'),
        header: 'Stufe',
        width: '4em',
        sortable: false,
        off: !isAllianz,
        body: (row) => (
          <SelectEnumField
            enumType={HkmEnum.PreisEbeneStufe}
            value={row.stufe}
            name={`preisebenen.[__aid=${aidOf(row)}].stufe`}
            onChange={onValueChange}
            fullWidth
            disabled={readonly}
            emptyText="Stufe B"
          />
        )
      },
      {
        field: nameOf<PreisEbeneJson>('alias'),
        header: 'Alias',
        width: '10em',
        sortable: false,
        body: (row) => (
          <TextField
            value={row.alias}
            name={`preisebenen.[__aid=${aidOf(row)}].alias`}
            onChange={onValueChange}
            fullWidth
            maxLength={20}
            disabled={readonly}
          />
        )
      }
    ]
  }, [isAllianz, isPIMModel, onValueChange, readonly])

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <DataTableCard
            backLink
            title="Preisebenen"
            name="PreisebenenTable"
            value={model.preisebenen}
            columns={columns}
            filterMode="global"
            localStateName="PreisebenenTable"
            emptyMessage="Keine Preisebenen vorhanden"
            selectMode="single"
            onReorderRows={readonly ? null : onReorderRows}
            dense
            rowFiller
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
