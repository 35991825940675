import { api } from '@one/api'
import { ArtikelPimLieferantEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimLieferantEkPreise'
import { ArtikelPimVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimVkPreise'
import {
  ArtikelBearbeitungsListeJson,
  PreisAenderungEintragChangeJson,
  PreisAenderungEintragJson,
  PreisAenderungenBearbeitenJson,
  PreisAenderungListeChangeJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { singleFromSet } from '@utils/utils'
import { useContext, useState } from 'react'
import { PreisAenderungenArtikelTable } from './PreisAenderungenArtikelTable'
import { AppPaths } from '@one/AppPaths'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { useNeonKontextCache } from '@one/datacaches/useNeonKontextCache'
import { formatBetriebsTyp, formatNeonKontext } from '@one/components/common/formatters'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { Button } from '@utils/ui/Buttons/Button'

interface PreisAenderungenBearbeitenEdit {
  liste: PreisAenderungEintragJson[]
  artikelBearbeitungsListe?: ArtikelBearbeitungsListeJson
  changedRows?: Map<number, PreisAenderungEintragChangeJson>
}

export const PreisAenderungenView = () => {
  const { artikelListeRef, betriebstyp, kontext } = useContext(RouteContext) as any

  const { replaceHistory } = useContext(AppSwitchCtx)
  const [selected, setSelected] = useState<Set<PreisAenderungEintragJson> | null>(null)

  const kontextCache = useNeonKontextCache()
  const betriebstypCache = useArtikelBetriebstypCache()

  const { model, save, reload, isChanged, uiLock, updModel } = useModelMgr<
    PreisAenderungenBearbeitenJson,
    PreisAenderungenBearbeitenEdit,
    PreisAenderungenBearbeitenJson,
    PreisAenderungListeChangeJson
  >({
    api,
    noid: true,
    rest: 'preise/aenderungen',
    restps: { artikelListeRef, bt: betriebstyp, kontext },
    restEdit: 'anzeigen',
    editMutator(payload) {
      setSelected(null)
      return {
        liste: payload.liste,
        artikelBearbeitungsListe: payload.artikelBearbeitungsListe,
        changedRows: new Map(payload.liste.map((a) => [a.id, { id: a.id, accept: false }]))
      } as PreisAenderungenBearbeitenEdit
    },
    saveMutator(model) {
      const data: PreisAenderungListeChangeJson = {
        artikelListeRef,
        bt: betriebstyp,
        kontext,
        changes: Array.from(model.changedRows.values()).filter((c) => c.accept)
      }
      return data
    },
    savedMessage: 'Preisänderungen wurden übernommen'
  })

  const handelSave = (manuell: boolean) => {
    save(
      {
        then: (data) => {
          if (manuell) {
            if (data.artikelBearbeitungsListe?.vkPreisListenIds?.length > 0) {
              if (data.artikelBearbeitungsListe.vkPreisListenIds.length == 1) {
                replaceHistory(() =>
                  AppPaths.VkPreisPflegeFn(data.artikelBearbeitungsListe.vkPreisListenIds[0])
                )
              } else {
                replaceHistory(() =>
                  AppPaths.VkPreisUebersichtFn(data.artikelBearbeitungsListe.vkPreisListenIds)
                )
              }
            }
          }
        }
      },
      null,
      manuell ? 'vkpreispflege' : 'save'
    )
  }

  const onToggleRow = (id: number) => {
    updModel((old) => {
      const changedRows = new Map(old.changedRows)
      const a = changedRows.get(id)
      changedRows.set(id, { ...a, accept: !a.accept })
      return { ...old, changedRows }
    })
  }

  const onSetChange = (selected: Set<PreisAenderungEintragJson>, accept: boolean) => {
    updModel((old) => {
      const changedRows = new Map(old.changedRows)
      for (const a of selected) {
        if (a.vkPreislisteId == null) {
          const c = changedRows.get(a.id)
          changedRows.set(a.id, { ...c, accept })
        }
      }
      return { ...old, changedRows }
    })
  }

  const artikel = singleFromSet(selected)

  const info =
    (kontext && `für Kontext ${formatNeonKontext(kontextCache.get(kontext))}`) ||
    (betriebstyp && `für Kontext ${formatBetriebsTyp(betriebstypCache.get(betriebstyp))}`)

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx
            backLink
            title={`Preisänderungen ${info}`}
            subheader="Listungsänderungen der EK- und VK-Preise anzeigen und übernehmen. Das betrifft nur Lieferanten aus Artikelselektionen mit aktiver Option zur getrennten Preisbearbeitung."
          />
        </FrameRow>
        <FrameBody>
          <PreisAenderungenArtikelTable
            liste={model?.liste}
            onSelect={setSelected}
            changedRows={model.changedRows}
            onToggleRow={onToggleRow}
            onSetChange={onSetChange}
            selected={selected}
          />
        </FrameBody>
        {artikel?.ekPreise && (
          <FrameBody>
            <ArtikelPimLieferantEkPreise ekPreise={artikel?.ekPreise} card />
          </FrameBody>
        )}
        {artikel?.vkPreise && (
          <FrameBody>
            <ArtikelPimVkPreise vkPreise={artikel?.vkPreise} />
          </FrameBody>
        )}
        <FrameRow>
          <ButtonRow>
            <Button
              label="EK-Preise übernehmen und VK-Preisliste erstellen"
              onClickVoid={() => handelSave(true)}
              disabled={!isChanged}
              tooltip="Die EK-Preise werden ohne Preisliste übernommen, für die VK-Preise werden VK-Preislisten erstellt, so das die VK-Preise manuell angepasst werden können. Die VK-Preise werden erst nach friegabe der VK-Preisliste wirksam!"
              variant="contained"
            />
            <Button
              label="EK- und VK-Preise übernehmen"
              onClickVoid={() => handelSave(false)}
              disabled={!isChanged}
              tooltip="Die EK- und VK-Preise werden direkt übernommen, es enstehen keine Preislisten, es ist keine manuelle Anpassung möglich."
              variant="contained"
            />
            <ReloadButton isChanged={isChanged} onClickVoid={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
