import { api } from '@one/api'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { checkRequired } from '@utils/utils'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArtikelVerteilerForm } from './ArtikelVerteilerForm'
import { ArtikelVerteilerListenTable } from './ArtikelVerteilerListenTable'

const validate = (model) => {
  const errors = {} as any
  checkRequired(model, errors, 'name', 'Name')
  checkRequired(model, errors, 'eintraege', 'Artikellisten')
  checkRequired(model, errors, 'verteilungsZiele', 'Verteilung an')
  return errors
}

export const ArtikelVerteilerView = () => {
  const { id } = useContext(RouteContext) as any
  const navigate = useNavigate()

  const { model, isNew, isChanged, errors, uiLock, onValueChange, save, reload, updModel, remove } =
    useModelMgr({
      id,
      api,
      title: 'Artikelverteiler',
      unwrapField: 'data',
      rest: 'artikelverteiler',
      init: {},
      eventName: EventNames.ARTIKELVERTEILER,
      validate
    })

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          navigate(AppPaths.artikelverteiler.ArtikelVerteilerUebersicht)
        }
      }),
    [navigate, remove]
  )

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.artikelverteiler.ArtikelVerteilerUebersicht}>
      <Frame space>
        <FrameRow>
          <ArtikelVerteilerForm
            model={model}
            errors={errors}
            isNew={isNew}
            onChange={onValueChange}
            // updModel={updModel}
          />
        </FrameRow>
        <FrameBody>
          <ArtikelVerteilerListenTable
            model={model}
            // errors={errors}
            // isNew={isNew}
            updModel={updModel}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton
              onDelete={onDeleteClick}
              isNew={isNew}
              deleteMsg={`Artikelverteiler "${model.name}" wirklich löschen`}
              tooltip="Artikelverteiler löschen"
            />
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
