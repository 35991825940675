import { AppPaths } from '@one/AppPaths'
import { GesellschafterDisplayTinyJson } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface OrganisationAnlageTableProps {
  firmen: GesellschafterDisplayTinyJson[]
  id: number
}

export const OrganisationAnlageTable = ({ firmen, id }: OrganisationAnlageTableProps) => {
  const columns = useMemo<Column<GesellschafterDisplayTinyJson>[]>(
    () => [
      {
        field: 'gln',
        header: 'Gesellschafternr.',
        align: 'right',
        sortable: true
      },
      {
        field: 'nr',
        header: 'ERP Firmennr.',
        align: 'right',
        sortable: true
      },
      {
        header: 'Name',
        field: 'name',
        sortable: true
      }
    ],
    []
  )

  const topActions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.ADMIN,
        tooltip: 'Gesellschafterfirma anlegen',
        icon: 'add',
        link: AppPaths.FirmaViewFn('neu', id)
      }
    ],
    [id]
  )

  const tableActions = useMemo(
    (): DataTableAction<GesellschafterDisplayTinyJson>[] => [
      {
        icon: 'arrow_forward',
        tooltip: 'Gesellschafterfirma öffnen',
        getLink: (data: GesellschafterDisplayTinyJson) => AppPaths.FirmaViewFn(data.id),
        role: UserRoles.ADMIN
      }
    ],
    []
  )

  return (
    <DataTableCard
      title="Firmen des Gesellschafters"
      name="GesellschafterFirmenTabelle"
      columns={columns}
      value={firmen}
      topActions={topActions}
      actions={tableActions}
      dense
      selectMode="none"
      rowFiller
    />
  )
}
