/* eslint-disable react/jsx-props-no-spreading */
import {Clear} from '@mui/icons-material'
import {Grid, LinearProgress, SxProps, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, Theme} from '@mui/material'
import {Actions, buildActionButtons} from '@utils/ui/Action'
import {IconButton} from '@utils/ui/Buttons/IconButton'
import {FieldCtrlType} from '@utils/ui/FieldCtrlType'
import {propsShrink} from '@utils/ui/styles'
import {autoCompleteOff} from '@utils/ui/uiutils'
import {ifString, trimStringToNull} from '@utils/utils'
import clsx from 'clsx'
import {makeStyles} from 'tss-react/mui'
import React, {ReactElement} from 'react'

const useStyles = makeStyles()((_theme) => ({
  clear: {
    '& .clear-button': {
      visibility: 'hidden',
      '& svg': { fontSize: '1.3rem' }
    },
    '& :focus-within .clear-button, & :hover .clear-button': {
      visibility: 'visible'
    }
  },
  multiline: {
    // '& > div': {
    // border: 'solid 2px ' + blueGrey[theme.darkMode ? 900 : 50],
    // backgroundColor: blueGrey[theme.darkMode ? 900 : 50],
    // borderRadius: '4px'
    // },
    '& textarea': {
      padding: '0px 4px 4px 0px'
    }
  },
  helperText: {
    display: 'flex'
  },
  helperTextBody: {
    flexGrow: 1
  },
  helperTextCount: {
    fontSize: '88%'
  },
  compact: {
    '& label + div': {
      marginTop: 8
    }
  }
}))

export type TextFieldProps = {
  id?: string
  label?: any
  name?: any
  value?: any
  onChange?: (e: any) => void
  disabled?: any
  error?: string | boolean
  helperText?: string
  fieldCtrl?: FieldCtrlType
  autoComplete?: string
  margin?: MuiTextFieldProps['margin']
  type?: any
  fullWidth?: boolean
  multiline?: boolean
  rows?: number
  maxRows?: number
  actions?: Actions
  startActions?: Actions
  style?: React.CSSProperties
  required?: boolean
  placeholder?: string
  InputLabelProps?: any
  InputProps?: any
  inputProps?: any
  title?: string
  onKeyDown?: any
  actionsLeft?: boolean
  regex?: RegExp
  maxLength?: number
  startComp?: React.ReactNode
  endComp?: ReactElement
  sx?: SxProps<Theme>
  variant?: MuiTextFieldProps['variant']
  noClear?: boolean
  onPaste?: React.ClipboardEventHandler<HTMLDivElement>
  align?: 'left' | 'right'
  /** Bei Verlassen werden Leerzeichen abgeschnitten, das kann hiermit abgeschaltet werden */
  doNotTrim?: boolean
  loading?: boolean
  size?: 'small' | 'medium'
  compact?: boolean
}

export const TextField = ({
  id,
  label,
  name,
  value,
  onChange,
  disabled,
  error,
  helperText,
  fieldCtrl,
  autoComplete = autoCompleteOff,
  margin,
  type = 'text',
  fullWidth,
  multiline,
  rows,
  maxRows,
  actions,
  actionsLeft,
  startActions,
  style,
  required,
  placeholder,
  title,
  onKeyDown,
  regex,
  maxLength,
  startComp,
  endComp,
  sx,
  variant = 'standard',
  noClear,
  onPaste,
  align,
  doNotTrim,
  loading,
  size,
  compact,
  ...TextFieldProps // TODO remove
}: TextFieldProps) => {
  const { classes } = useStyles()
  const onClear = () => {
    if (onChange) {
      onChange({ target: { name, value: null } })
    }
  }
  const onChangeInt = (e: any) => {
    const value = e.target.value
    if (
      onChange &&
      (regex == null || value == null || regex.test(value)) &&
      (maxLength == null || value?.length <= maxLength)
    ) {
      onChange(e)
    }
  }
  const onBlur = () => {
    if (onChange && !doNotTrim) {
      const trimmed = trimStringToNull(value)
      if (trimmed !== value) {
        onChange({ target: { name, value: trimmed } })
      }
    }
  }
  const buttons = (
    <>
      {buildActionButtons({
        actions,
        pre: true,
        tiny: true,
        xtra: !disabled && onChange && !noClear && (
          <IconButton
            icon={<Clear />}
            className="clear-button"
            onClick={onClear}
            size="tiny"
            tabIndex={-1}
          />
        ),
        asgriditem: true
      })}
      {buildActionButtons({ actions, pre: false, tiny: true, asgriditem: true })}
      {endComp && <Grid item>{endComp}</Grid>}
    </>
  )

  const startAdornment = (
    <Grid container direction="row" width="auto" wrap="nowrap" alignItems="center">
      {startComp && (
        <Grid item key="start">
          {startComp}
        </Grid>
      )}
      {buildActionButtons({ actions: startActions, tiny: true, asgriditem: true })}
      {actionsLeft ? buttons : null}
    </Grid>
  )
  const endAdornment = (
    <Grid container direction="row" width="auto" wrap="nowrap" alignItems="center">
      {actionsLeft ? null : buttons}
    </Grid>
  )

  const rs = {
    ...style,
    padding: multiline ? '1px 0 1px' : null
  }

  const helperTextEx = multiline ? (
    <span className={classes.helperText}>
      <span className={classes.helperTextBody}>
        {ifString(error) || fieldCtrl?.helperText || helperText}
      </span>
      {value && <span className={classes.helperTextCount}>{value.length + ' Zeichen '}</span>}
    </span>
  ) : (
    ifString(error) || fieldCtrl?.helperText || helperText
  )

  return (
    <>
      <MuiTextField
        {...TextFieldProps}
        className={clsx(classes.clear, multiline && classes.multiline, compact && classes.compact)}
        type={type}
        size={size}
        name={name}
        value={value == null ? '' : value}
        onChange={onChangeInt}
        onBlur={onBlur}
        label={label}
        fullWidth={fullWidth}
        multiline={multiline}
        onPaste={onPaste}
        rows={rows}
        maxRows={maxRows}
        margin={margin}
        autoComplete={autoComplete}
        error={!!error || fieldCtrl?.error}
        helperText={helperTextEx}
        required={required || fieldCtrl?.required}
        disabled={disabled || fieldCtrl?.disabled}
        placeholder={placeholder}
        InputLabelProps={
          //@ts-ignore
          TextFieldProps.InputLabelProps
            ? //@ts-ignore
              { ...TextFieldProps.InputLabelProps, ...propsShrink }
            : propsShrink
        }
        InputProps={{
          ...TextFieldProps.InputProps,
          startAdornment,
          endAdornment,
          style: rs
        }}
        inputProps={{
          ...TextFieldProps.inputProps,
          'data-name': label,
          style: { textAlign: align, ...TextFieldProps.inputProps?.style }
        }}
        variant={variant}
        title={title}
        onKeyDown={onKeyDown}
        sx={sx}
      />
      {loading && <LinearProgress style={{ marginBottom: -4 }} />}
    </>
  )
}
