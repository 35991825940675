import { api } from '@one/api'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import {
  ArtikelListeBearbeitenJson,
  ArtikelListeJson,
  ArtikelListeTyp
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { checkRequired } from '@utils/utils'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArtikelListeForm } from './ArtikelListeForm'
import { ArtikelListeTable } from './ArtikelListeTable'

const validate = (model: any) => {
  const errors = {} as any
  checkRequired(model, errors, 'name', 'Name')
  checkRequired(model, errors, 'artikel', 'Artikel')
  return errors
}

export const ArtikelListeView = () => {
  const { id, kontext } = useContext(RouteContext) as any
  const navigate = useNavigate()

  const { model, isNew, isChanged, errors, uiLock, onValueChange, save, reload, remove, updModel } =
    useModelMgr<ArtikelListeBearbeitenJson, ArtikelListeJson>({
      id,
      restps: { kontext },
      api,
      title: 'Artikelliste',
      unwrapField: 'artikelListe',
      rest: 'artikelliste',
      restEdit: kontext ? 'ansicht' : 'edit',
      init: {},
      validate,
      eventName: EventNames.ARTIKELLISTE
    })

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          navigate(AppPaths.artikelliste.ArtikelListeUebersicht)
        }
      }),
    [navigate, remove]
  )

  const readonly = model?.typ !== ArtikelListeTyp.MANUAL || kontext != null

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.artikelliste.ArtikelListeUebersicht}>
      <Frame space>
        <FrameRow>
          <ArtikelListeForm
            model={model}
            errors={errors}
            isNew={isNew}
            onChange={onValueChange}
            readonly={readonly}
            isChanged={isChanged}
            kontext={kontext}
          />
        </FrameRow>
        <FrameBody>
          <ArtikelListeTable
            model={model}
            updModel={updModel}
            reload={reload}
            readonly={readonly}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton
              onDelete={onDeleteClick}
              isNew={isNew}
              visible={!readonly}
              deleteMsg={`Artikelliste "${model.name}" wirklich löschen`}
              tooltip="Artikelliste löschen"
            />
            <SaveButton
              onClickVoid={save}
              isNew={isNew}
              isChanged={isChanged}
              visible={!readonly}
            />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
