import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { checkRequired } from '@utils/utils'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { LieferantenVerteilerForm } from './LieferantenVerteilerForm'
import { LieferantenVerteilerListenTable } from './LieferantenVerteilerListenTable'
const validate = (model) => {
  const errors = {} as any
  checkRequired(model, errors, 'name', 'Name')
  checkRequired(model, errors, 'eintraege', 'Lieferantenlisten')
  checkRequired(model, errors, 'verteilungsZiele', 'Verteilung an')
  return errors
}

export const LieferantenVerteilerView = () => {
  const { id } = useContext(RouteContext) as any
  const navigate = useNavigate()

  const { model, isNew, isChanged, errors, uiLock, onValueChange, save, reload, updModel, remove } =
    useModelMgr({
      id,
      api,
      title: 'Lieferantenverteiler',
      unwrapField: 'data',
      rest: 'lieferantenverteiler',
      init: {},
      validate,
      eventName: EventNames.LIEFERANTENVERTEILER
    })

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          navigate(AppPaths.lieferantenverteiler.LieferantenVerteilerUebersicht)
        }
      }),
    [navigate, remove]
  )

  return (
    <StatePlane
      uiLock={uiLock}
      altLink={AppPaths.lieferantenverteiler.LieferantenVerteilerUebersicht}
    >
      <Frame space>
        <FrameRow>
          <LieferantenVerteilerForm
            model={model}
            errors={errors}
            isNew={isNew}
            onChange={onValueChange}
            // updModel={updModel}
          />
        </FrameRow>
        <FrameBody>
          <LieferantenVerteilerListenTable
            model={model}
            // errors={errors}
            // isNew={isNew}
            updModel={updModel}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton
              onDelete={onDeleteClick}
              isNew={isNew}
              deleteMsg={`Lieferantenverteiler "${model.name}" wirklich löschen`}
              tooltip="Lieferantenverteiler löschen"
            />
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
