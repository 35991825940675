import { AppPaths } from '@one/AppPaths'
import { ArtikelBearbeitungsListeEdit } from '@one/components/PreisAnlage/ArtikelBearbeitungsListeBearbeitenUseCase'
import { PreisAnlageView } from '@one/components/PreisAnlage/PreisAnlageView'
import { EventNames } from '@one/EventNames'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { useContext } from 'react'

export const EkPreisAnlageView = () => {
  const { replaceHistory } = useContext(AppSwitchCtx)

  const onSave = (savedModel: ArtikelBearbeitungsListeEdit) => {
    if (savedModel.ekPreisListenId) {
      replaceHistory(() => AppPaths.EkPreisPflegeFn(savedModel.ekPreisListenId))
    }
    return true
  }

  return (
    <PreisAnlageView
      title="Einkaufspreispflege Artikelauswahl"
      rest="ekpreisliste/anlage"
      mode="ek"
      eventName={EventNames.EKPREISLISTE}
      overviewLink={AppPaths.EkPreisUebersicht}
      onSaveCallback={onSave}
      appPathFn={AppPaths.EkPreisAnlageFn}
    />
  )
}
