import { AppPaths } from '@one/AppPaths'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { ArtikelSearchDialog } from '@one/components/Artikel/ArtikelSearchDialog'
import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import { ArtikelDisplayJson, ArtikelListeJson } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useEnums } from '@utils/enums'
import { UpdModelFn } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import {
  AdditionalFilterConfig,
  useAdditionalFilter
} from '@utils/ui/DataTable/filter/AdditionalFilterMenu'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { Permission } from '@utils/ui/Permission'
import { arrayMergeUnique } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'

export interface ArtikelListeTableProps {
  updModel: UpdModelFn<ArtikelListeJson>
  model?: ArtikelListeJson
  reload?: () => void
  readonly?: boolean
}

export const ArtikelListeTable = ({
  updModel,
  model = {},
  reload,
  readonly = false
}: ArtikelListeTableProps) => {
  const { askToRemove } = useMessageDialog()
  const [DlgAnker, showDlg] = useDialogAnker()
  const [selected, setSelected, getSelected] = useStateEx(new Set<ArtikelDisplayJson>())
  const { et } = useEnums()
  const appContext = useContext(AppContext)
  const { openLabelsEdit } = useArtikelLabelEdit()

  const afc = useMemo<AdditionalFilterConfig>(
    () =>
      ({
        label: 'archivierte',
        name: 'archivierte',
        onFilter: (row) => row.archiviert
      }) as AdditionalFilterConfig,
    []
  )

  const [eintraegeFiltered, additionalFilter] = useAdditionalFilter(model?.artikel, afc)

  const onComplete = useCallback(
    (data) => {
      if (data) {
        updModel((prevModel: ArtikelListeJson) => {
          const artikel = arrayMergeUnique(prevModel.artikel.filter(Boolean), data, 'id')
          return {
            ...prevModel,
            artikel
          } as ArtikelListeJson
        })
      }
    },
    [updModel]
  )

  const onMultiRemoveSelection = useCallback(
    (artikelList) => () => {
      updModel((prevModel: ArtikelListeJson) => {
        const artikel = prevModel.artikel.filter((item) => {
          return !artikelList.has(item)
        })
        return { ...prevModel, artikel }
      })
    },
    [updModel]
  )

  const removeSelection = useCallback(
    (data) => () => {
      if (data) {
        onMultiRemoveSelection(new Set([data]))()
      }
    },
    [onMultiRemoveSelection]
  )

  const removeAfterCheck = useCallback(() => {
    askToRemove({
      title: `Selektion mit ${getSelected().size} Artikeln entfernen`,
      onConfirm: onMultiRemoveSelection(getSelected())
    })
  }, [onMultiRemoveSelection, getSelected, askToRemove])

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(getSelected()),
      callback: { then: () => reload() }
    })
  }, [openLabelsEdit, getSelected, reload])

  const onNewArtikelListe = useCallback(() => {
    showDlg((visible, onClose) => (
      <ArtikelSearchDialog
        open={visible}
        onClose={onClose(onComplete)}
        defaultDezentral
        disableDezentral
        disableListung
        multiselect
      />
    ))
  }, [onComplete, showDlg])

  const columnProps = useMemo<ArtikelDefaultColumnsProps<ArtikelDisplayJson>>(
    () => ({
      isGesellschafter: appContext.isGesellschafter,
      addColumnToIndex: [
        {
          beforeField: 'industrieArtikelNummer',
          column: {
            field: 'aboStatus',
            header: 'Abo',
            align: 'center',
            body: (row) => <ArtikelAbonniertStatusSymbol value={row.aboStatus} et={et} />,
            valueGetter: (row) => getArtikelAbonniertStatusText(et, row.aboStatus)
          }
        }
      ]
    }),
    [et, appContext.isGesellschafter]
  )

  const columns = useArtikelColumns(columnProps)

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'info',
        tooltip: 'Archivierter Artikel',
        handler: () => {},
        check: (row) => (row.archiviert ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data) => AppPaths.ArtikelFn(data.id)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        check: () => (readonly ? Permission.HIDDEN : Permission.ENABLED),
        handler: removeSelection
      }
    ],
    [removeSelection, readonly]
  )

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Labels bearbeiten',
        icon: 'label',
        onClick: openLabelDialog
      },
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Artikel hinzufügen',
        text: 'Artikel',
        icon: 'add',
        visible: !readonly,
        onClick: onNewArtikelListe
      }
    ],
    [readonly, onNewArtikelListe, openLabelDialog]
  )

  const bottomActions = useMemo(
    () =>
      readonly
        ? []
        : [
            <Button
              key="removeArtikel"
              label="Artikel entfernen"
              disabled={selected.size === 0}
              onClick={removeAfterCheck}
              variant="contained"
              color="error"
            />
          ],

    [readonly, removeAfterCheck, selected.size]
  )

  const getRowStyle = useCallback((row: any) => getArchiviertRowStyle(row?.archiviert), [])

  return (
    <>
      <DataTableCard
        name="ArtikelListeTable"
        title="Artikel"
        selectMode="multi"
        initialOrderBy={appContext.isPIMModel ? 'hageNummer' : 'btNummer'}
        selectCol
        selected={selected}
        onSelect={setSelected}
        topActions={topActions}
        bottomActions={bottomActions}
        columns={columns}
        value={eintraegeFiltered}
        actions={tableActions}
        rowStyle={getRowStyle}
        additionalFilter={additionalFilter}
        paging
        filterMode="both"
        dense
        localStateName="ArtikelListeTable"
      />
      <DlgAnker />
    </>
  )
}
