import { FormControl, FormHelperText, FormLabelProps, InputLabel } from '@mui/material'
import { FieldCtrlType } from '@utils/ui/FieldCtrlType'
import { ifString } from '@utils/utils'
import { ReactNode, useMemo } from 'react'

// const useStyles = makeStyles((theme:any) => ({
//   spacing: {
//     marginBottom: theme.spacing(1)
//   }
// }))

export type FormFieldProps = {
  id?: string
  label?: string
  children?: ReactNode
  className?: string
  labelClassName?: string
  error?: string | boolean
  required?: boolean
  labelColor?: FormLabelProps['color']
  shrink?: boolean
  margin?: 'dense' | 'normal' | 'none'
  fullWidth?: boolean
  helperText?: string
  fieldCtrl?: FieldCtrlType
  disabled?: boolean
  style?: any
}

export const FormField = ({
  id,
  label,
  children,
  className,
  labelClassName,
  error,
  required,
  labelColor,
  shrink = true,
  margin,
  fullWidth,
  helperText,
  disabled,
  fieldCtrl,
  style
}: FormFieldProps) => {
  // const { classes } = useStyles()

  const ids = useMemo(
    () => ({
      label: id && `${id}-label`,
      helper: id && `${id}-helper`
    }),
    [id]
  )

  return (
    <FormControl
      className={className}
      disabled={disabled || fieldCtrl?.disabled}
      required={required}
      margin={margin}
      fullWidth={fullWidth}
      variant="standard"
      style={style}
      error={!!error || fieldCtrl?.error}
    >
      <InputLabel
        className={labelClassName}
        color={labelColor}
        id={ids.label}
        shrink={shrink}
        required={required || fieldCtrl?.required}
      >
        {label}
      </InputLabel>
      {children}
      <FormHelperText id={ids.helper} error={!!error || fieldCtrl?.error}>
        {ifString(error) || fieldCtrl?.helperText || helperText}
      </FormHelperText>
    </FormControl>
  )
}
