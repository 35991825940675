import { api } from '@one/api'
import { EkPreisListeFreigabeDialog } from '@one/components/EkPreis/EkPreisListeFreigabeDialog'
import { EventNames } from '@one/EventNames'
import {
  EkKonditionenListeDisplayJson,
  EkKonditionenListeSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace } from '@utils/utils'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router'
import { EkPreisUebersichtParams } from './EkPreisUebersichtParams'
import { EkPreisUebersichtTable } from './EkPreisUebersichtTable'

export const EkPreisUebersichtView = () => {
  const { visible } = useContext(AppRouteCtx)

  const navigate = useNavigate()

  const { criteria, result, search, setResult, onCriteriaChange } = useSearcherState<
    EkKonditionenListeSearcherCriteriaJson,
    EkKonditionenListeDisplayJson
  >({
    api,
    url: '/ekpreisliste'
  })

  const { askToConfirm, askToDelete } = useMessageDialog()

  const [DlgAnker, showDialog] = useDialogAnker()

  const [apiCall, apiBusy] = useApiCaller(api)

  const onFreigeben = useCallback(
    (row: EkKonditionenListeDisplayJson) => () => {
      showDialog((open, onClose) => (
        <EkPreisListeFreigabeDialog
          open={open}
          navigate={navigate}
          onClose={onClose}
          id={row.id}
          onSuccess={(data: any) => {
            setResult((rs) => arrayItemReplace(rs, (r) => r.id === row.id, data.listeDisplay))
          }}
        />
      ))
    },
    [navigate, setResult, showDialog]
  )

  const onAbschliessen = useCallback(
    (row: EkKonditionenListeDisplayJson) => () => {
      askToConfirm({
        title: 'Soll die Preisliste abgeschlossen werden?',
        message: 'Es werden alle Preise abgeschlossen und können dann freigegeben werden.',
        onConfirm: () => {
          apiCall<any>({
            method: 'POST',
            rest: '/ekpreisliste/alleAbschliessen',
            params: { id: row.id },
            onErrorMsg: 'Die Preisliste konnte nicht abgeschlossen werden',
            onSuccessMsg: 'Die Preisliste wurde erfolgreich abgeschlossen.',
            onSuccess: (data) =>
              setResult((rs) => arrayItemReplace(rs, (r) => r.id === row.id, data.listeDisplay))
          })
        }
      })
    },
    [apiCall, askToConfirm, setResult]
  )

  const onDelete = useCallback(
    (row: EkKonditionenListeDisplayJson) => () => {
      askToDelete({
        title: 'Soll die Preisliste wirklich gelöscht werden?',
        onConfirm: () => {
          apiCall({
            method: 'POST',
            rest: '/ekpreisliste/delete',
            params: { id: row.id },
            onErrorMsg: 'Die Preisliste konnte nicht gelöscht werden.',
            onSuccessMsg: 'Die Preisliste wurde gelöscht.',
            onSuccess: () => setResult((rs) => arrayItemReplace(rs, (r) => r.id === row.id, null))
          })
        }
      })
    },
    [apiCall, askToDelete, setResult]
  )

  useObserver(
    EventNames.EKPREISLISTE,
    () => {
      search()
    },
    visible
  )

  return (
    <Frame space>
      <FrameRow>
        <EkPreisUebersichtParams value={criteria} onSearch={search} onChange={onCriteriaChange} />
      </FrameRow>
      <FrameBody>
        <EkPreisUebersichtTable
          result={result}
          onFreigeben={onFreigeben}
          onAbschliessen={onAbschliessen}
          onDelete={onDelete}
        />
      </FrameBody>
      <DlgAnker />
    </Frame>
  )
}
