import { api } from '@one/api'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import { EventNames } from '@one/EventNames'
import {
  EkKonditionenDefinitionJson,
  EkKonditionenRabattgruppeDisplayJson,
  EkRabattgruppenSearcherCriteriaJson,
  EkRabattgruppenUebersichtJson
} from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useQuery } from '@utils/routing'
import { useSearcherState } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useContext, useEffect, useMemo, useState } from 'react'
import { EkRabattgruppenForm } from './EkRabattgruppenForm'
import { EkRabattgruppenTable } from './EkRabattgruppenTable'

export const EkRabattgruppenUebersichtView = () => {
  const { isAllianz } = useContext(AppContext)
  const [{ lieferantenNr }, updateQuery] = useQuery()
  const [kondiDef, setKondiDef] = useState<EkKonditionenDefinitionJson>()

  const { criteria, onCriteriaChange, result, search, searchEx } = useSearcherState<
    EkRabattgruppenSearcherCriteriaJson,
    EkKonditionenRabattgruppeDisplayJson,
    EkRabattgruppenUebersichtJson
  >({
    api,
    url: '/ekrabattgruppe',
    initialParams: {
      lieferantNr: lieferantenNr,
      lieferant: undefined,
      gueltigVon: undefined // new Date()
    },
    doOpen: false,
    onData: (data) => {
      setKondiDef(data.searcher.konditionDefinition)
      if (data.searcher.criteria) {
        onCriteriaChange(() => data.searcher.criteria)
      }
      return data
    }
  })

  const kondiDefEx = useMemo(
    () => enrichKondiDefinition(kondiDef, isAllianz),
    [isAllianz, kondiDef]
  )

  useEffect(() => {
    if (lieferantenNr != null) {
      searchEx({ lieferantNr: lieferantenNr })
    }
    updateQuery({})
  }, [lieferantenNr, updateQuery, searchEx])

  useObserver(EventNames.EKRABATTGRUPPEN, (event) => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <EkRabattgruppenForm onSearch={search} value={criteria} onChange={onCriteriaChange} />
      </FrameRow>
      <FrameBody>
        <EkRabattgruppenTable
          result={result}
          kondiDefinition={kondiDefEx}
          lieferant={criteria.lieferant}
        />
      </FrameBody>
    </Frame>
  )
}
