/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, Chip, TextField } from '@mui/material'
import { ColumnType } from '@utils/ui/DataTable/DataTable'
import { formatValue } from '@utils/ui/DataTable/DataTableUtils'
import { propsShrink } from '@utils/ui/styles'
import isEqual from 'react-fast-compare'
import { makeStyles } from 'tss-react/mui'
import { FilterModeIcon } from './FilterModeIcon'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  root: {
    '& .remove-button': {
      display: 'none'
    },
    '& :focus-within,& :hover': {
      '& .remove-button': {
        display: 'inline-block'
      }
    },
    marginRight: 24
  },
  listbox: {
    '&.MuiAutocomplete-listbox': {
      maxHeight: 'unset'
    }
  },
  popper: {
    minWidth: 'fit-content'
  }
})

export interface IndexFieldProps {
  index: any
  filter: any
  setFilter: any
  open?: boolean
  label?: string
  align?: any
  type?: ColumnType
}

export const IndexField = ({
  index,
  filter,
  setFilter,
  open,
  label,
  align,
  type
}: IndexFieldProps) => {
  const { classes } = useStyles()

  const formatOption = (v: any) => formatValue(v, type)

  const renderOption = (props, option) => {
    return (
      <li {...props} key={option[0]}>
        <div style={{ display: 'flex', flexFlow: 'row', width: '100%' }}>
          <div style={{ flexGrow: '1', paddingRight: 32, textAlign: align }}>
            {option[0] == null || option[0].length === 0 ? (
              <small>leer</small>
            ) : (
              formatOption(option[0])
            )}
          </div>
          <div>
            <small title="Anzahl Einträge">{`(${option[1]})`}</small>
          </div>
        </div>
      </li>
    )
  }

  return (
    <Autocomplete
      open={open}
      disablePortal={open}
      classes={classes}
      // style={{ maxWidht: 400, minWidth: 120 } as any}
      multiple
      size="small"
      fullWidth
      options={index}
      getOptionLabel={(option) =>
        option != null && option[0] != null ? formatOption(option[0]) : 'leer'
      }
      filterSelectedOptions
      disableCloseOnSelect
      renderOption={renderOption}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const ies = filter.index?.get(option[0]) ? 'I' : 'E'
          return (
            <Chip
              key={index}
              variant="filled"
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  <div
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                    {formatOption(option[0]) ?? 'leer'}
                  </div>
                  <div>
                    <FilterModeIcon
                      value={ies}
                      onChange={() => {
                        const ch = new Map(filter.index)
                        ch.set(option[0], ies !== 'I')
                        setFilter({ ...filter, index: ch })
                      }}
                      size="small"
                    />
                  </div>
                </div>
              }
              size="small"
              {...getTagProps({ index })}
            />
          )
        })
      }
      noOptionsText="Keine Auswahl"
      value={filter?.value ?? []}
      onChange={(e, v) => {
        setFilter({
          ...filter,
          index: new Map(v.map((x) => [x[0], filter.index?.get(x[0]) ?? true])),
          value: v
        })
      }}
      isOptionEqualToValue={isEqual}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          InputLabelProps={propsShrink}
          label={label}
          placeholder={(filter?.index as Map<any, any>)?.size == 0 ? 'Alle' : undefined}
        />
      )}
    />
  )
}
