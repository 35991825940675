import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { useConfirmAboDelete } from '@one/components/common/useConfirmAboDelete'
import {
  ArtikelAboDisplayJson,
  ArtikelAboSearcherArtikelAboCriteriaJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace } from '@utils/utils'
import { useCallback } from 'react'
import { ArtikelAboUebersichtParams } from './ArtikelAboUebersichtParams'
import { ArtikelAboUebersichtTable } from './ArtikelAboUebersichtTable'

const initialState = {
  name: '',
  gueltigVon: null,
  lieferant: null,
  abgeschlossene: false,
  mitStatistik: false
} as ArtikelAboSearcherArtikelAboCriteriaJson

export const ArtikelAboUebersichtView = () => {
  const { criteria, result, onCriteriaChange, setResult, search } = useSearcherState<
    ArtikelAboSearcherArtikelAboCriteriaJson,
    ArtikelAboDisplayJson
  >({
    api,
    url: '/artikelabo',
    initialParams: initialState,
    valueDecorator: {
      name: (v: any) => (v ? v.toUpperCase() : null)
    }
  })

  const [apiCall, apiBusy] = useApiCaller(api)

  const { askToDelete } = useMessageDialog()

  const onConfirmDelete = useConfirmAboDelete()

  const handleDeleteClick = useCallback(
    (row: any) => () => {
      const onRemove = (forceChangeHauptSelection: boolean) => {
        apiCall({
          method: 'POST',
          rest: '/artikelabo/delete',
          params: { id: row.id, forceChangeHauptSelection },
          onErrorMsg: `Artikelabo ${quote(row.name)} konnte nicht gelöscht werden.`,
          onError: (error) => onConfirmDelete(error, () => onRemove(true)),
          onSuccessMsg: `Artikelabo ${quote(row.name)} erfolgreich gelöscht.`,
          onSuccess: () =>
            setResult((rs) => arrayItemReplace(rs, (r: any) => r.id === row.id, null))
        })
      }
      const quote = (text: any) => (text ? `"${text}"` : '')
      askToDelete({
        title: `Artikelabo ${(row || {}).name} wirklich löschen?`,
        onConfirm: () => {
          onRemove(false)
        }
      })
    },
    [apiCall, askToDelete, onConfirmDelete, setResult]
  )

  useObserver(EventNames.ARTIKELABO, () => search())

  return (
    <Frame space>
      <FrameRow>
        <ArtikelAboUebersichtParams
          value={criteria}
          onChange={onCriteriaChange}
          onSearch={search}
        />
      </FrameRow>
      <FrameBody>
        <ArtikelAboUebersichtTable result={result} onDelete={handleDeleteClick} />
      </FrameBody>
    </Frame>
  )
}
