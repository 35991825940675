export enum Permission {
  HIDDEN = 0,
  DISABLED = 1,
  ENABLED = 2
}

export const PermissionUtil = {
  /**
   * @function
   * returns ENABLED or DISABLED
   */
  enabled: (flag: any) => (flag ? Permission.ENABLED : Permission.DISABLED),

  /**
   * @function
   * returns ENABLED or HIDDEN
   */
  show: (flag: any) => (flag ? Permission.ENABLED : Permission.HIDDEN),

  /**
   * @function
   * returns build permission
   */
  build: (enable, show) =>
    show ? (enable ? Permission.ENABLED : Permission.DISABLED) : Permission.HIDDEN,

  /**
   * @function
   * returns valid permission
   */
  check: (perm: Permission) =>
    perm == null
      ? Permission.ENABLED
      : perm >= Permission.HIDDEN && perm <= Permission.ENABLED
        ? perm
        : Permission.HIDDEN,

  /**
   * @function
   * returns check if not hidden
   */
  isVisible: (perm: Permission) => perm !== Permission.HIDDEN,

  /**
   * @function
   * returns check if hidden
   */
  isHidden: (perm: Permission) => perm === Permission.HIDDEN,

  /**
   * @function
   * returns check if enabled
   */
  isEnabled: (perm: Permission) => perm == null || perm === Permission.ENABLED,

  /**
   * @function
   * returns check if disabled
   */
  isDisabled: (perm: Permission) => perm != null && perm !== Permission.ENABLED
}
