/* eslint-disable no-plusplus */
import { Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ErrorsType } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantenListeFormProps {
  onChange: (e: any) => void
  model: any
  errors: ErrorsType
  readonly: boolean
  isNew: boolean
}

export const LieferantenListeForm = ({
  model,
  errors,
  onChange,
  isNew = false,
  readonly = false
}: LieferantenListeFormProps) => {
  return (
    <CardEx
      backLink
      title={isNew ? 'Lieferantenliste erstellen' : `Lieferantenliste ${model.name || ''}`}
    >
      <Grid container spacing={2} direction="column">
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              name="name"
              label="Name"
              error={errors.name}
              value={model.name}
              onChange={onChange}
              fullWidth
              required
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SelectEnumField
              label="Typ"
              name="typ"
              value={model.typ}
              displayEmpty={false}
              fullWidth
              enumType={HkmEnum.LieferantenListeTyp}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
