import { PimLandDisplayJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'

export type PimLandFieldProps = {
  id?: string
  value: PimLandDisplayJson
  values: PimLandDisplayJson[]
  label?: string
  name: string
  onChange: ValueChangeFn<SeArtikelJson>
  fullWidth?: boolean
  disabled?: boolean
  long?: boolean
  error?: any
}

export const PimLandField = ({
  id,
  value,
  values,
  label,
  name,
  onChange,
  fullWidth,
  disabled,
  long,
  error
}: PimLandFieldProps) => {
  return (
    <AutocompleteEx<PimLandDisplayJson>
      id={id}
      name={name}
      label={label}
      value={value}
      options={values}
      onChange={onChange}
      optionLabel={
        long ? (option) => `${option.name} ${option.beschreibung ?? ''}` : (option) => option.name
      }
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
    />
  )
}
