import { GetApp } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  AllianzDatenValidierenType,
  ErpStammdatenExportResultJson,
  ErpStammdatenExportUebersichtJson,
  ExportToErpUebersichtJson,
  MessageSeverity
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { notifyObservers } from '@utils/observer'
import { AppContext } from '@utils/ui/App/AppContext'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Switch } from '@utils/ui/fields/Switch'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AllianzPreisEbeneField } from './AllianzPreisEbeneField'
import { ExportToErpParams } from './ExportToErpParams'
import { ExportToErpTable } from './ExportToErpTable'

export const ExportToErpView = () => {
  const { isAllianz, isWithErpImport } = useContext(AppContext)

  const { pushHistory } = useContext(AppSwitchCtx)

  const { visible } = useContext(AppRouteCtx)

  const [state, setState] = useState<ErpStammdatenExportUebersichtJson>({})

  const [apiCall, apiBusy] = useApiCaller(api)

  const fetchData = useCallback(() => {
    apiCall<ErpStammdatenExportUebersichtJson>({
      method: 'GET',
      rest: '/erp/stammdatenExport/open',
      onErrorMsg: 'ArtikelstammExports konnten nicht geladen werden.',
      onSuccess: (data) => setState(data || {})
    })
  }, [apiCall])

  useEffect(() => {
    if (visible) {
      fetchData()
    }
  }, [fetchData, visible])

  const handleStartExport = useCallback(() => {
    apiCall<ErpStammdatenExportResultJson>({
      method: 'POST',
      rest: '/erp/stammdatenExport/exec',
      onErrorMsg: 'Fehler während der ArtikelstammExport',
      onError: (error) => {
        if (isAllianz && error?.mainMessage?.severity === MessageSeverity.ERR) {
          const type = AppPaths.AllianzDatenFehlerViewFn(AllianzDatenValidierenType.Erpexport)
          pushHistory(type)
          setTimeout(() => {
            notifyObservers({ name: EventNames.EXPORTINFO })
          }, 50)
          return true
        }
        return false
      },
      onSuccess: (data) => {
        notifyObservers({ name: EventNames.EXPORTINFO })
        return setState(data || {})
      }
    })
  }, [apiCall, isAllianz, pushHistory])

  const toggleAutomatischerExport = () => {
    apiCall<ExportToErpUebersichtJson>({
      method: 'POST',
      rest: '/erp/stammdatenExport/automatischerExport',
      params: { enabled: !state.automatischerExport },
      onErrorMsg: 'Fehler während Umschaltung des Automatischen Exports!',
      onError: (error) => {
        if (isAllianz && error?.mainMessage?.severity === MessageSeverity.ERR) {
          const type = AppPaths.AllianzDatenFehlerViewFn(AllianzDatenValidierenType.Erpexport)
          pushHistory(type)
          setTimeout(() => {
            notifyObservers({ name: EventNames.EXPORTINFO })
          }, 50)
          return true
        }
        return false
      },
      onSuccess: (data) => {
        notifyObservers({ name: EventNames.EXPORTINFO })
        setState({ ...state, automatischerExport: data.automatischerExport })
        fetchData()
      }
    })
  }

  const onErpImport = useCallback(() => {
    apiCall<ExportToErpUebersichtJson>({
      method: 'GET',
      rest: '/import/getERPChanges',
      onSuccessMsg: (r) => `Erfolgreich durchgeführt. Export Id=${r.data}`
    })
  }, [apiCall])

  return (
    <StatePlane wait={apiBusy}>
      <Frame space>
        <FrameRow>
          <ExportToErpParams onRefresh={fetchData} data={state} />
        </FrameRow>
        <FrameBody>
          <ExportToErpTable
            values={state.result}
            limited={state.limited}
            reasonArtikel={state.reasonArtikel}
            reasonLieferant={state.reasonLieferant}
            loading={apiBusy}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {isAllianz && <AllianzPreisEbeneField onChange={fetchData} />}
            <div style={{ flexGrow: 1 }} />
            {!state.automatischerExport && isWithErpImport && (
              <Button
                label="ERP Änderungen importieren"
                startIcon={<GetApp />}
                onClick={onErpImport}
              />
            )}
            {(!isAllianz || state?.automatischerExport) && (
              <TooltipWrapper
                title={state.nextExportTime == null ? 'Export Job ist nicht aktiviert' : null}
              >
                <Switch
                  label="Automatischer Erp Export"
                  checked={state.automatischerExport || false}
                  onClick={toggleAutomatischerExport}
                  disabled={state.nextExportTime == null}
                />
              </TooltipWrapper>
            )}
            {(!state.automatischerExport || state.nextExportTime == null) && (
              <Button
                label="Export starten"
                onClick={handleStartExport}
                variant="contained"
                disabled={!state.canExport}
              />
            )}
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
