import { Grid2 as Grid, Switch } from '@mui/material'
import { MessageID } from '@one/MessageIDs'
import { EanZuordnung, MessageJson } from '@one/typings/apiTypings'
import { asNumber } from '@utils/utils'
import { ArtikelSelektionEintragChangeEdit } from './model/ArtikelSelektionTypes'
import { ArtikelAboSelektionUsecase } from '@one/components/ArtikelSelektion/model/ArtikelSelektionUsecase'
import { ModelAction } from '@utils/modelmgr'

export type ArtikelSelektionDetailsErrorActionProps = {
  eintragId: number
  message: MessageJson
  changes: ArtikelSelektionEintragChangeEdit
  dispatch: (changes: ModelAction) => void
}

export const ArtikelSelektionDetailsErrorAction = ({
  eintragId,
  message,
  changes,
  dispatch
}: ArtikelSelektionDetailsErrorActionProps) => {
  const messageId = message.messageId?.id

  const onMessageAction = ({
    verbindungMitSeArtikelId,
    checked,
    eanZuordnung,
    name
  }: {
    verbindungMitSeArtikelId?: number
    checked: boolean
    eanZuordnung?: EanZuordnung
    name?: string
  }) =>
    dispatch({
      type: ArtikelAboSelektionUsecase.SETMESSAGEACTION,
      eintragId,
      messageId,
      verbindungMitSeArtikelId,
      eanZuordnung,
      checked,
      name
    })

  switch (messageId) {
    case MessageID.IDE_DZ_EAN_KONFLIKT:
      return (
        <Grid container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid>
            <Switch
              size="small"
              name="aboean"
              checked={changes?.eanZuordnung === EanZuordnung.ZUGEORDNET}
              onChange={(e, checked) =>
                onMessageAction({ checked, eanZuordnung: EanZuordnung.ZUGEORDNET })
              }
            />{' '}
            EAN übernehmen und abonnieren
          </Grid>
          <Grid>
            <Switch
              size="small"
              name="aboeannicht"
              checked={changes?.eanZuordnung === EanZuordnung.IGNORIERT}
              onChange={(e, checked) =>
                onMessageAction({ checked, eanZuordnung: EanZuordnung.IGNORIERT })
              }
            />{' '}
            EAN NICHT übernehmen und abonnieren
          </Grid>
        </Grid>
      )
    case MessageID.IDE_DZ_BT_ANDERER_ERMITTELT:
      return (
        <Grid container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid>
            <Switch
              size="small"
              name="uebernehmeBetriebsTypSwitch"
              checked={changes?.uebernehmeBetriebsTypSwitch}
              onChange={(e, checked) => onMessageAction({ checked })}
            />{' '}
            In Betriebstyp des Abos übernehmen und abonnieren
          </Grid>
        </Grid>
      )
    case MessageID.IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE:
      return (
        <Grid container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid>
            <Switch
              size="small"
              name="uebernehmeEanSwitch"
              checked={changes?.uebernehmeEanSwitch}
              onChange={(e, checked) =>
                onMessageAction({
                  checked,
                  verbindungMitSeArtikelId: asNumber(message.hint)
                })
              }
            />{' '}
            Artikel verbinden
          </Grid>
        </Grid>
      )
    case MessageID.IDE_DZ_MEHRERE_WURZEL_MES:
      return (
        <Grid container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid>
            <Switch
              size="small"
              name="ergaenzeMEs"
              checked={changes?.ergaenzeMEs}
              onChange={(e, checked) => {
                if (!checked) {
                  onMessageAction({ checked: false, name: 'verbergeMEs' })
                }
                onMessageAction({ checked, name: 'ergaenzeMEs' })
              }}
            />{' '}
            Fehlende ME im ERP Artikel ergänzen
          </Grid>
          <Grid>
            <Switch
              size="small"
              name="verbergeMEs"
              checked={changes?.verbergeMEs}
              onChange={(e, checked) => {
                if (checked) {
                  onMessageAction({ checked: true, name: 'ergaenzeMEs' })
                }
                onMessageAction({ checked, name: 'verbergeMEs' })
              }}
            />{' '}
            Fehlende ME nicht ins ERP übernehmen
          </Grid>
        </Grid>
      )
    case MessageID.IDE_ZE_ABGLEICH_MEHRDEUTIG:
      return (
        <Grid container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid>
            <Switch
              size="small"
              name="uebernehmeMehrdeutigAusZE"
              checked={changes?.uebernehmeMehrdeutigAusZE}
              onChange={(e, checked) => onMessageAction({ checked })}
            />{' '}
            Mehrdeutigen Listungsartikel zuordnen
          </Grid>
        </Grid>
      )
    default:
      return null
  }
}
