import {
  ArtikelBearbeitungsEintragJson,
  ArtikelBearbeitungsListeBearbeitenJson,
  ArtikelBearbeitungsListeJson,
  AuswahlStatus
} from '@one/typings/apiTypings'
import { safeArray } from '@utils/utils'

export interface ArtikelBearbeitungsListeEdit extends ArtikelBearbeitungsListeJson {
  eintraegeChangesMap: Map<number, AuswahlStatus>
}

// eslint-disable-next-line no-unused-vars
export const ArtikelBearbeitungsList_validate = (model: ArtikelBearbeitungsListeEdit) => {
  const validationErrors = {}
  return validationErrors
}

const transformStatus = (map: Map<number, AuswahlStatus>): ArtikelBearbeitungsEintragJson[] => {
  const result = []
  map.forEach((value, key) => {
    switch (value) {
      case AuswahlStatus.EINGESCHL:
      case AuswahlStatus.IGNORIEREN:
        result.push({ id: key, status: value })
        break
      default:
        break
    }
  })
  return result
}

const transformInitialChanges = (
  eintraege: ArtikelBearbeitungsEintragJson[]
): Map<number, AuswahlStatus> =>
  eintraege == null ? new Map() : new Map(safeArray(eintraege).map((e) => [e.artikel.id, e.status]))

export const ArtikelBearbeitungsListe_editMutator = (
  payload: ArtikelBearbeitungsListeJson,
  lastModel: ArtikelBearbeitungsListeEdit,
  envelope: ArtikelBearbeitungsListeBearbeitenJson,
  context: any
): ArtikelBearbeitungsListeEdit => {
  const { eintraege, ...copy } = payload
  return {
    ...copy,
    // einzelhandel:
    //   copy.einzelhandel == null
    //     ? context.isPIMModel
    //       ? copy.kontext
    //       : context.isEinzelhandel && !context.isFachhandel
    //     : copy.einzelhandel,
    eintraegeChangesMap: transformInitialChanges(eintraege)
  }
}

export const ArtikelBearbeitungsListe_saveMutator = (
  model: ArtikelBearbeitungsListeEdit,
  lastPayload: ArtikelBearbeitungsListeJson
): ArtikelBearbeitungsListeJson => {
  const { eintraegeChangesMap, ...copy } = model
  return {
    ...copy,
    eintraegeChanges: transformStatus(model.eintraegeChangesMap)
  }
}

export const ArtikelBearbeitungsListe_onValueChanged = (
  model: ArtikelBearbeitungsListeEdit,
  field: string
) => {
  if (field == 'kontext') {
    return {
      ...model,
      preisEbeneId: null
    } as ArtikelBearbeitungsListeEdit
  }
  return model
}
