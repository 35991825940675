import { AddBox, InfoOutlined, Search } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
    ArtikelDisplayJson, ArtikelDisplayTinyJson,
    ArtikelUebersichtJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { AutocompleteAsync } from '@utils/ui/fields/AutocompleteAsync'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import {compareStrings, ifString, sortArray} from '@utils/utils'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {ArtikelSearchDialog} from "@one/components/Artikel/ArtikelSearchDialog";

export interface ArtikelFieldProps {
  label: string
  onChange?: (e: any) => void
  name?: string
  id?: string
  placeholder?: string
  value?: any
  valueAsId?: boolean
  error?: string | boolean
  className?: string
  defaultSugg?: any[]
  disabled?: boolean
  fullWidth?: boolean
  required?: boolean
  modus: 'zentral' | 'dezentral' | 'alle'
  disableActions?: boolean
  loading?: boolean
  asLink?: boolean
  helperText?: string
}

export const ArtikelField = ({
  label,
  onChange,
  id,
  name,
  value,
  valueAsId,
  placeholder,
  loading,
  error,
  className,
  defaultSugg,
  modus,
  disabled,
  required,
  fullWidth,
  disableActions,
  helperText = '',
  asLink
}: ArtikelFieldProps) => {
  const [apiCall] = useApiCaller(api)
  const navigate = useNavigate()

  const { isAllianzMitglied } = useContext(AppContext)

  const [DlgAnker, dlgShow] = useDialogAnker()

  const { et } = useEnums()

  const onOpenSearch = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      dlgShow((open, onClose) => (
        <ArtikelSearchDialog
          open={open}
          onClose={onClose((sel) => {
            onChange({ name, value: sel })
          })}
          disableDezentral={modus !== 'alle'}
          disableListung={true}
        />
      ))
    },
    [dlgShow, modus, name, onChange]
  )

  const onChangeInt = useCallback(
    (e) => {
      if (onChange) {
        onChange(e)
      }
    },
    [onChange]
  )

  const optionLabel = useCallback((v) => {
    return `${v.hageNummer}`
  }, [])

  const renderItem = useCallback(
    (v: ArtikelDisplayTinyJson) => {
      return (
        <Grid container spacing={1}>
          <Grid item>{v.hageNummer}</Grid>
        </Grid>
      )
    }, []
  )

  const tooltip = useMemo(() => {
    if (value == null) {
      return null
    }
    return (
      <>
        <Typography variant="subtitle1">
          {value.hageNummer} {value.bez1}
        </Typography>
      </>
    )
  }, [et, value])

  const queryArtikel = useCallback(
    ({ reason, query, onSuccess, onError }) => {
      if (reason === 'query' && query.length >= 3) {
        apiCall<ArtikelUebersichtJson>({
          method: 'POST',
          rest: '/artikel/uebersichtAnzeigen/search',
          exclusive: ApiExclusive.CANCEL,
          data: {
            hageNummer: query,
            matchHagenummer: true,
            forAbo: isAllianzMitglied && (modus === 'zentral' || modus === 'alle'),
            dezentral: modus === 'dezentral' || modus === 'alle'
          },
          onSuccess: (data) => {
            onSuccess(data.searcher && sortArray<ArtikelDisplayTinyJson>(data.searcher.result, [
                (a, b) => compareStrings(a.hageNummer, b.hageNummer)
                ])
            )
          },
          onError
        })
      }
    },
    [apiCall, isAllianzMitglied, modus]
  )

  const onArtikelInfoClick = useCallback(() => {
    navigate(AppPaths.ArtikelFn(value.id))
  }, [navigate, value])

  const optionValue = (v) => {
    return valueAsId ? v?.id : v
  }

  const isOptionEqualToValue = (o, v) => {
    if (valueAsId) {
      const lieferant = o.lieferant ? o.lieferant : o
      return lieferant?.id === v
    }
    return o === v || (o.lieferant || o).id === (v.lieferant || v).id
  }

  return (
    <span>
      <AutocompleteAsync
        id={id}
        label={label}
        name={name}
        loading={loading}
        emptyText={placeholder}
        error={!!error}
        helperText={ifString(error) ?? helperText}
        disabled={disabled}
        value={value}
        onChange={onChangeInt}
        renderItem={renderItem}
        queryOptions={queryArtikel}
        className={className}
        fullWidth={fullWidth}
        required={required}
        loadResetToggle={modus}
        actions={
          disableActions || disabled
            ? null
            : [
                <IconButton
                  key="search"
                  size="small"
                  name={name && `${name}-search`}
                  icon={<Search />}
                  onClick={onOpenSearch}
                  tooltip="Ausführliche Suche"
                />
              ]
        }
        optionLabel={optionLabel}
        optionValue={optionValue}
        defaultOptions={defaultSugg}
        endAdornments={
          tooltip &&
          (value.id ? (
            <span style={{ cursor: 'help' }}>
              <IconButton
                icon={<InfoOutlined />}
                size="small"
                onClick={onArtikelInfoClick}
                disabled={!asLink}
                tooltip={tooltip}
                tooltipPlacement="top-start"
              />
            </span>
          ) : (
            <TooltipEx title={tooltip} arrow placement="top-start">
              <InfoOutlined />
            </TooltipEx>
          ))
        }
      />
      <DlgAnker />
    </span>
  )
}
