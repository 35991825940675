import { Close, Download } from '@mui/icons-material'
import { Grid, LinearProgress, Paper, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import { api } from '@one/api'
import { AsyncTaskJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useAsyncTaskPoller } from '@utils/asynctask'
import { UILockMode, UILockType } from '@utils/uilock'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { Button } from './Buttons/Button'
import { IconButton } from './Buttons/IconButton'
import { useDownloader } from './downloader'
import { noLock } from './planes/StatePlane'

export type AsyncDownloadProps = {
  downloadKey: string
  asyncTask: AsyncTaskJson
  closeFnc: () => void
  title: string
}

export const AsyncDownload = forwardRef(
  ({ downloadKey, asyncTask: asyncTaskIn, closeFnc, title }: AsyncDownloadProps, ref) => {
    const [asyncPoller] = useAsyncTaskPoller()
    const [uiLock, setUiLock] = useState<UILockType>(noLock)
    const [apiCall] = useApiCaller(api)
    const [download] = useDownloader()
    const [ready, setReady] = useState(false)
    const [asyncTask, setAsyncTask] = useState(asyncTaskIn)

    const onDownload = () => {
      download(`rest/download/${downloadKey}`)
      closeFnc()
    }

    useEffect(() => {
      asyncPoller({
        apiCall,
        asyncTask,
        setUiLock,
        onReady: () => {
          setReady(true)
        },
        onPoll: (at) => {
          setAsyncTask(at)
        }
      })
    }, [apiCall, asyncPoller, asyncTask])

    const progress =
      asyncTask?.aktCount && asyncTask?.totalCount
        ? (asyncTask?.aktCount * 100.0) / asyncTask?.totalCount
        : null

    return (
      <Paper
        ref={ref as any}
        elevation={2}
        variant="elevation"
        style={{
          backgroundColor: blue[200],
          overflow: 'hidden',
          padding: '6px 12px'
        }}
        sx={ready ? undefined : [{ opacity: 0.6 }, { '&:hover': { opacity: 1 } }]}
      >
        <Grid container direction="column">
          <Grid item container>
            <Grid item flexGrow={1}>
              <Typography variant="caption">{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton Icon={Close} size="tiny" onClick={closeFnc} />
            </Grid>
          </Grid>
          <Grid item paddingBottom="4px">
            <Typography variant="body2">{uiLock.asyncTask?.progressInfo}</Typography>
          </Grid>
          <Grid item>
            {uiLock.error ? <div>{uiLock.error.mainMessage?.message}</div> : null}
            {uiLock.mode === UILockMode.ASYNC_WAIT ? (
              <LinearProgress
                variant={progress ? 'determinate' : 'indeterminate'}
                value={progress}
              />
            ) : null}
            {ready ? (
              <Button startIcon={<Download />} label="Download" onClick={onDownload} fullWidth />
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    )
  }
)

AsyncDownload.displayName = 'AsyncDownload'

export type AsyncDownloaderProps = {
  downloadKey: string
  asyncTask: AsyncTaskJson
  title: string
}

export const useAsyncDownloader = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const fnc = useCallback(
    ({ asyncTask, downloadKey, title }: AsyncDownloaderProps) =>
      enqueueSnackbar('', {
        variant: 'info',
        persist: true,
        content: (key) => (
          <AsyncDownload
            downloadKey={downloadKey}
            title={title}
            asyncTask={asyncTask}
            closeFnc={() => closeSnackbar(key)}
          />
        )
      }),
    [enqueueSnackbar, closeSnackbar]
  )

  return fnc
}
