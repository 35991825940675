import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { debugLog } from './logging'
import { safeExecute } from './utils'

type ObserverFn = (e: any) => void
const observerMap = new Map<string, Set<ObserverFn>>()
const empty = { name: null, data: null }

export type EventType<T = any> = {
  /** Name des Events */
  name: string
  /** ID des eigenen Observers, um das Event nicht selbst zu erhalten */
  origin?: string | null
  /** Optionale Angabe einer Aktion */
  action?: string
  /** Daten des Events */
  data?: T
}

/**
 * Beobachter für ein oder mehr Events
 * @param {string|[string]} eventNames Ein oder mehr Eventnamen, die beobachtet werden sollen
 * @param callback Funktion, die das vom Typ  EventType<T> erhält
 * @param visible
 * @returns {string} Eindeutige ID des Beobachters
 */
export const useObserver = <T = any>(
  eventNames: string | string[] | null,
  callback: (event: EventType<T>) => void,
  /** Wenn false, werden die Events erst zugestellt, wenn wieder true */
  visible: boolean = true
): string => {
  const uuidRef = useRef<string>(uuidv4())

  const ref = useRef<any>()
  ref.current = { eventNames, callback, visible: visible }

  const eventStackRef = useRef({
    lastVisible: visible,
    stack: [] as EventType<T>[]
  })

  useEffect(() => {
    if (visible !== eventStackRef.current.lastVisible) {
      eventStackRef.current.lastVisible = visible
      if (eventStackRef.current.stack.length > 0) {
        debugLog('event observer delayed send')
        const events = eventStackRef.current.stack
        eventStackRef.current.stack = []
        events.forEach((event) => safeExecute(() => ref.current.callback(event)))
      }
    }
  }, [visible])

  useEffect(() => {
    const names = Array.isArray(ref.current.eventNames)
      ? ref.current.eventNames
      : ref.current.eventNames && [ref.current.eventNames]

    if (ref.current.eventNames == null) {
      return undefined
    }

    const callback = (event: EventType<T>) => {
      if (ref.current && (event.origin == null || uuidRef.current !== event.origin)) {
        if (ref.current.visible) {
          ref.current.callback(event)
        } else {
          debugLog('event observer delayed', event)
          eventStackRef.current.stack = eventStackRef.current.stack.filter(
            (e) => e.name !== event.name
          )
          eventStackRef.current.stack.push(event)
        }
      }
    }

    debugLog('event observer added', names)
    const unregisterer = [] as any

    names.forEach((name: string) => {
      let observers = observerMap.get(name)
      if (observers == null) {
        observers = new Set<(e: any) => void>()
        observerMap.set(name, observers)
      }
      observers.add(callback)
      unregisterer.push(() => observers.delete(callback))
    })

    return () => {
      debugLog('event observer removed', names)
      unregisterer.forEach((unregister: any) => safeExecute(unregister))
    }
  }, [])

  return uuidRef.current
}

export const notifyObservers = (event: EventType<any>) => {
  const observers = observerMap.get(event.name)
  debugLog('notify observers', event)
  if (observers) {
    observers.forEach((notifyObserver) => safeExecute(() => notifyObserver(event)))
  }
}
