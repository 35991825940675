import { ForbiddenPage } from '@utils/ui/Pages/ForbiddenPage'
import { UnsupportedPage } from '@utils/ui/Pages/UnsupportedPage'
import { flattenArray, oidOf } from '@utils/utils'
import React, { useMemo } from 'react'
import { Route } from 'react-router'
import { AppContextState } from './AppContext'
import { AppRoute } from './AppRoute'
import { UserRole } from './AppState'

export type AppRouteDefinition = {
  id?: string
  label?: string
  windowTitle?: string
  path?: string | string[]
  component?: React.ComponentType<any> | undefined
  dialog?: React.ComponentType<any> | undefined
  role?: UserRole
  disabled?: (appContext: AppContextState) => boolean
  /** Die Anwendung kennt diese Route nicht, wenn disabled = true */
  forgetWhenDisabled?: boolean
  admin?: 'only' | 'both'
  items?: AppRouteDefinition[]
  nocache?: boolean
  defaultExpanded?: boolean
}

const isDisabled = (i: AppRouteDefinition, appContext: any, adminMode: boolean): boolean => {
  if (i.disabled != null && i.disabled(appContext)) {
    return true
  }
  if (i.items?.length > 0) {
    return false
  }
  return adminMode ? i.admin == null : i.admin !== 'both' && i.admin != null
}

const isForbidden = (
  i: AppRouteDefinition,
  checkUserRole: null | ((role: UserRole) => boolean),
  defaultUserRole: string | null
): boolean => {
  return (
    (i.role != null || defaultUserRole != null) &&
    checkUserRole != null &&
    !checkUserRole(i.role || defaultUserRole)
  )
}

const filterMenu = (
  items: AppRouteDefinition[] | null,
  appContext: any,
  defaultUserRole: string | null,
  adminMode: boolean
): AppRouteDefinition[] => {
  return items == null
    ? null
    : items
        // .filter(
        //   (i) =>
        //     i.label != null &&
        //     ((i.dialog == null && i.path == null) ||
        //       (!isForbidden(i, appContext.checkUserRole, defaultUserRole) &&
        //         !isDisabled(i, appContext, adminMode)))
        // )
        .filter(
          (i) =>
            !(
              i.label == null ||
              isForbidden(i, appContext.checkUserRole, defaultUserRole) ||
              isDisabled(i, appContext, adminMode)
            )
        )
        .map(
          (i) =>
            ({
              id: i.id,
              label: i.label,
              path: i.path,
              dialog: i.dialog,
              defaultExpanded: i.defaultExpanded,
              items: filterMenu(i.items, appContext, defaultUserRole, adminMode)
            }) as AppRouteDefinition
        )
        .filter((i) => i.items == null || i.items.length !== 0)
}

export const useAppRouteMgr = (
  appRoutes: AppRouteDefinition[],
  appContext: any,
  defaultUserRole: string
): [any, AppRouteDefinition[]] => {
  // TODO: Refactoring möglicherweise nötig: Stichwort create-hash-router  -> AppRoutes müsste komplett neu aufgebaut werden
  return useMemo(() => {
    const routes = flattenArray(
      appRoutes,
      (i) => i.items,
      (i) => i.path != null && i.component != null
    )
      .filter(
        (i) => !(isDisabled(i, appContext, !!appContext.session?.adminMode) && i.forgetWhenDisabled)
      )
      .reduce((_routes, i) => {
        const paths = Array.isArray(i.path) ? i.path : [i.path]
        paths.forEach((path, index) => {
          _routes.push(
            <Route
              key={`${oidOf(i)}-${index}`}
              path={path}
              element={
                <AppRoute
                  title={i.windowTitle || i.label}
                  nocache={i.nocache}
                  path={i.path}
                  component={
                    (isForbidden(i, appContext.checkUserRole, defaultUserRole) && ForbiddenPage) ||
                    (isDisabled(i, appContext, !!appContext.session?.adminMode) &&
                      UnsupportedPage) ||
                    i.component
                  }
                  // exact
                />
              }
            ></Route>
          )
        })
        return _routes
      }, [])
    const menu = filterMenu(appRoutes, appContext, defaultUserRole, !!appContext.session?.adminMode)

    return [routes, menu]
  }, [appContext, appRoutes, defaultUserRole])
}
