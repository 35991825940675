import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { api } from '@one/api'
import { updateFrachtkosten } from '@one/components/VkPreis/VkPreisPflege/model/VkPreisUsecase'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  VkFrachtArt,
  VkPreisListeFrachtkostenJson,
  VkPreiseActionResultJson,
  VkPreiseMasseBearbeitenEintragJson,
  ZufuhrMultiDisplayJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { Action } from '@utils/ui/Action'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { PerformButton } from '@utils/ui/Buttons/PerformButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { dataFromEvent } from '@utils/utils'
import { useCallback } from 'react'
import { VkPreiseMasseBearbeitenKopfJsonEx } from './model/VkPreisMassenKopfModel'

export interface VkPeisMassenFrachtkostenDialogProps {
  kopf: VkPreiseMasseBearbeitenKopfJsonEx
  onClose: (fn?: () => void) => (data: any) => void
  onComplete: (updated?: any) => void
  selected?: VkPreiseMasseBearbeitenEintragJson[]
  visible?: boolean
}

export const VkPeisMassenFrachtkostenDialog = ({
  kopf,
  onClose,
  onComplete,
  selected = [],
  visible = false
}: VkPeisMassenFrachtkostenDialogProps) => {
  const { model, updModel, isChanged, save, uiLock } = useModelMgr<VkPreisListeFrachtkostenJson>({
    id: 'neu',
    api,
    title: 'VK-Preis Frachtkosten-Massenänderung',
    rest: 'vkpreisliste',
    restCreate: null,
    restSave: 'executeaction/frachtkosten',
    init: () => ({
      standortId: kopf.standortId,
      frachtkosten: {}
    }),
    onSave: (rs: VkPreiseActionResultJson) => onClose(onComplete)(rs.artikelListe),
    editMutator: (data) => ({
      ...data,
      standortId: kopf.standortId,
      frachtkosten: {}
    }),
    saveMutator: (data) => ({
      listeId: Number(kopf.id),
      standortId: data.standortId,
      eintraege: selected.map((s) => s.id),
      frachtkosten: data.frachtkosten
    })
  })

  const frachtkosten = model.frachtkosten
  const zufuhrMultis = kopf.zufuhrMultis
  const currency = '€'

  const setFrachtkosten = useCallback(
    (e: any) => {
      const { name, value } = dataFromEvent(e)
      updModel((old) => updateFrachtkosten(old, name, value))
    },
    [updModel]
  )

  const frachtArt = frachtkosten?.frachtArt

  const nullAction = (name: string, pre: boolean = false) => {
    const flag = frachtkosten[name + '_setnull'] && frachtkosten[name] == null
    return {
      pre,
      Icon:
        frachtkosten[name] != null ? CheckBox : flag ? CheckBoxOutlineBlank : IndeterminateCheckBox,
      onClick: () => {
        setFrachtkosten({
          name: name + '_setnull',
          value: !flag
        })
        setFrachtkosten({
          name: name,
          value: null
        })
      }
    } as Action
  }

  return (
    <DialogEx
      open={visible}
      onClose={onClose()}
      height={360}
      maxWidth="md"
      fullWidth
      title={`Frachtkosten und Skonto für ${selected.length} Artikel`}
      actions={
        <>
          <PerformButton
            onClickVoid={save}
            disabled={!isChanged}
            label={`Für ${selected.length} Einträge anwenden`}
          />
          <CancelButton onClick={onClose()} />
        </>
      }
    >
      <StatePlane uiLock={uiLock}>
        <Grid
          container
          direction="column"
          spacing={2}
          paddingLeft={2}
          paddingRight={2}
          marginBottom={1}
          marginTop={1}
        >
          <Grid item container direction="row" spacing={2}>
            <Grid item sm={4}>
              <SelectEnumField
                label="Frachtart"
                enumType={HkmEnum.VkFrachtArt}
                fullWidth
                name="frachtArt"
                value={frachtkosten.frachtArt}
                onChange={setFrachtkosten}
                emptyText={frachtkosten.frachtArt_setnull ? 'entfernen' : 'Unverändert'}
                actions={nullAction('frachtArt')}
                noClear
              />
            </Grid>
            {frachtkosten.frachtArt === VkFrachtArt.PROZENT ? (
              <Grid item sm={4}>
                <NumberField
                  label="Fracht Prozent"
                  fullWidth
                  fraction={2}
                  min={0}
                  thousandSeparator
                  name="frachtProz"
                  value={frachtkosten.frachtProz}
                  suffix="%"
                  onChange={setFrachtkosten}
                  disabled={frachtArt != VkFrachtArt.PROZENT}
                  placeholder={frachtkosten.frachtProz_setnull ? 'entfernen' : 'Unverändert'}
                  actions={nullAction('frachtProz', true)}
                  noClear
                />
              </Grid>
            ) : null}
            {frachtkosten.frachtArt === VkFrachtArt.FIX ? (
              <Grid item sm={4}>
                <NumberField
                  label="Fracht Absolut"
                  fullWidth
                  fraction={2}
                  min={0}
                  thousandSeparator
                  suffix={currency}
                  name="frachtAbs"
                  value={frachtkosten.frachtAbs}
                  onChange={setFrachtkosten}
                  disabled={frachtArt != VkFrachtArt.FIX}
                  placeholder={frachtkosten.frachtAbs_setnull ? 'entfernen' : 'Unverändert'}
                  actions={nullAction('frachtAbs', true)}
                  noClear
                />
              </Grid>
            ) : null}
            {frachtkosten.frachtArt === VkFrachtArt.GEWICHT || frachtkosten.frachtArt == null ? (
              <Grid item sm={4} />
            ) : null}
            <Grid item sm={4}>
              <NumberField
                label="Enthaltene Frachtzufuhr"
                fullWidth
                fraction={2}
                thousandSeparator
                suffix={currency}
                name="enthalteneFrachtZufuhr"
                value={frachtkosten.enthalteneFrachtZufuhr}
                onChange={setFrachtkosten}
                placeholder={
                  frachtkosten.enthalteneFrachtZufuhr_setnull ? 'entfernen' : 'Unverändert'
                }
                actions={nullAction('enthalteneFrachtZufuhr', true)}
                noClear
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <Grid item sm={8}>
              <AutocompleteEx
                label="Multifracht"
                name="zufuhrMultiId"
                value={frachtkosten.zufuhrMultiId}
                onChange={setFrachtkosten}
                options={zufuhrMultis}
                optionLabel={(opt: ZufuhrMultiDisplayJson) => opt.name + '-' + opt.bez}
                optionValue="id"
                fullWidth
                emptyText={frachtkosten.zufuhrMultiId_setnull ? 'entfernen' : 'Unverändert'}
                actions={nullAction('zufuhrMultiId')}
                noClear
              />
            </Grid>
            <Grid item sm={4}>
              <Checkbox
                label="Fracht nach Rabatt"
                name="frachtNachRabatt"
                checked={frachtkosten.frachtNachRabatt}
                onChange={setFrachtkosten}
                size="small"
                indeterminate
                // disabled={frachtArt == null}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <Grid item sm={4}>
              <NumberField
                label="EK nicht Skonto"
                fullWidth
                fraction={2}
                thousandSeparator
                suffix={currency}
                name="ekNichtSkonto"
                value={frachtkosten.ekNichtSkonto}
                onChange={setFrachtkosten}
                placeholder={frachtkosten.ekNichtSkonto_setnull ? 'entfernen' : 'Unverändert'}
                actions={nullAction('ekNichtSkonto', true)}
                noClear
              />
            </Grid>
            <Grid item sm={4}>
              <NumberField
                label="VK nicht Skonto"
                fullWidth
                fraction={2}
                thousandSeparator
                suffix={currency}
                name="vkNichtSkonto"
                value={frachtkosten.vkNichtSkonto}
                onChange={setFrachtkosten}
                placeholder={frachtkosten.vkNichtSkonto_setnull ? 'entfernen' : 'Unverändert'}
                actions={nullAction('vkNichtSkonto', true)}
                noClear
              />
            </Grid>
            <Grid item sm={4}>
              <Checkbox
                label="Fracht nicht skontierfähig"
                name="frachtNichtSkonto"
                checked={frachtkosten.frachtNichtSkonto}
                onChange={setFrachtkosten}
                size="small"
                indeterminate
              />
            </Grid>
          </Grid>
        </Grid>
      </StatePlane>
    </DialogEx>
  )
}
