import { CircularProgress, Modal } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  root: {
    position: 'relative'
  },
  waiter: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'wait'
  },
  waiter2: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // zIndex: 1000,
    cursor: 'wait'
  }
})

const minhs = {
  height: '100%',
  display: 'block'
}

export type WaitPlaneProps = {
  wait: boolean
  fullHeight?: boolean
  children?: React.ReactNode
}

export const WaitPlane = ({ wait, fullHeight, children }: WaitPlaneProps) => {
  const { classes } = useStyles()

  const waitPlane = wait ? (
    <Modal open hideBackdrop>
      <div className={classes.waiter}>
        <CircularProgress size={120} />
      </div>
    </Modal>
  ) : null

  if (children) {
    return (
      <span className={classes.root} style={wait || fullHeight ? minhs : null}>
        {wait ? (
          <div className={classes.waiter2}>
            <CircularProgress size={120} />
          </div>
        ) : null}
        {children}
      </span>
    )
  }

  return waitPlane
}
