import { Grid } from '@mui/material'
import { LieferantAboField } from '@one/components/LieferantAbo/LieferantAboField'
import { EkPreisListeImportSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'

export interface EkPreisImportUebersichtKopfProps {
  value: EkPreisListeImportSearcherCriteriaJson
  onChange: ChangeFunction<EkPreisListeImportSearcherCriteriaJson>
  onSearch: VoidFunction
}

export const EkPreisImportUebersichtKopf = ({
  value,
  onChange,
  onSearch
}: EkPreisImportUebersichtKopfProps) => {
  return (
    <CardEx
      title="Einkaufspreis Importübersicht"
      backLink
      bottomActions={<SearchButton onClick={onSearch} />}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3} lg={4}>
          <LieferantAboField
            value={value.lieferantId}
            valueAsId
            label="Lieferant"
            name="lieferantId"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DateField
            label="Gültig von"
            disableToolbar
            value={value.gueltigAb}
            name="gueltigAb"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DateField
            label="Hochgeladen am"
            disableToolbar
            value={value.erstelltAb}
            name="erstelltAb"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Checkbox
            label="Nur Offene"
            size="small"
            checked={value.nurOffene}
            name="nurOffene"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
