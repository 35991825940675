import { NumberCell, NumberCellOnChange } from '@one/components/common/NumberCell'
import { EkKonditionenRabattVerwendung } from '@one/typings/apiTypings'
import { EkKonditionenWertEdit } from './model/EkPreiseTypes'

export interface KondiValueCellProps {
  row: EkKonditionenWertEdit
  field: string
  readonly: boolean
  kondiDefinition?: any
  setKondiValue: NumberCellOnChange
  sonderpreisRabattierbar: boolean
}

export const KondiValueCell = ({
  row,
  field,
  readonly,
  setKondiValue,
  kondiDefinition,
  sonderpreisRabattierbar
}: KondiValueCellProps) => {
  const notAbs = !field.startsWith('zuschlAbs')
  return (
    <NumberCell
      model={row}
      field={field}
      onChange={setKondiValue}
      allowNegative
      defaultValue={row.effektiveRabattgruppeWerte && row.effektiveRabattgruppeWerte[field]}
      readonly={
        readonly ||
        row.readonly ||
        !row.standortEk ||
        (!!row.ebeneRabattgruppeId &&
          row.effektiveRabattgruppeWerte &&
          (row.ebeneRabattVerwendung == EkKonditionenRabattVerwendung.NUR_RABATTGRUPPE ||
            (row.ebeneRabattVerwendung == EkKonditionenRabattVerwendung.IGNORIERE_ABS_RABATT &&
              notAbs) ||
            (row.ebeneRabattVerwendung == EkKonditionenRabattVerwendung.ADDITIV_ABS_RABATT &&
              notAbs))) ||
        (sonderpreisRabattierbar
          ? row.listenpreis == null && row.sonderpreis == null
          : (row.sonderpreis != null || row.sonderpreisOnly) &&
            (kondiDefinition == null || kondiDefinition[field] === true))
      }
    />
  )
}
