import { Grid, InputLabel } from '@mui/material'
import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface LabeledFieldProps {
  label: string
  control: React.ReactNode
  id?: string
  labelWidth?: any
  fieldWidth?: any
  fullWidth?: boolean
}

const useStyles = makeStyles()({
  label: {
    color: 'unset',
    paddingRight: 8
  },
  field: {
    '& >*': {
      minWidth: '100%'
    }
  }
})

export const LabeledField = ({
  id,
  label,
  labelWidth,
  fieldWidth,
  control,
  fullWidth = false
}: LabeledFieldProps) => {
  const { classes } = useStyles()

  const state = useMemo(
    () => ({
      ls: labelWidth == null ? null : { width: labelWidth },
      lc: classes.label,
      fs: { width: fieldWidth, flexGrow: fullWidth && 1 },
      fc: fieldWidth == null ? null : classes.field
    }),
    [classes.field, classes.label, fieldWidth, fullWidth, labelWidth]
  )

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <InputLabel htmlFor={id} className={state.lc} style={state.ls}>
          {label}
        </InputLabel>
      </Grid>
      <Grid item className={state.fc} style={state.fs} flexGrow={1}>
        {control}
      </Grid>
    </Grid>
  )
}
