import { PimArtikelJson, PimArtikelVersionLandJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface ArtikelPimSteuerProps {
  artikel: PimArtikelJson
}

export const ArtikelPimSteuer = ({ artikel }: ArtikelPimSteuerProps) => {
  if (!artikel || !artikel.version?.laender) {
    return null
  }

  const columns: Column<PimArtikelVersionLandJson>[] = [
    {
      field: 'land.name',
      header: 'Landname',
      tooltip: (row) => row.land.beschreibung
    },
    {
      field: 'steuerindikation',
      header: 'Steuerindikation'
    }
  ]

  return (
    <CardEx title="Steuerindikation" height="100%" name="Laender">
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimArtikelVersionLaenderTabelle"
          columns={columns}
          value={artikel.version.laender}
          emptyMessage="Keine Daten"
          dense
          rowFiller
        />
      </ScrollPanel>
    </CardEx>
  )
}
