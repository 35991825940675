import { Grid, Typography } from '@mui/material'
import { api } from '@one/api'
import { CacheNameJson, CacheNamesJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Button } from '@utils/ui/Buttons/Button'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { useSnackbarEx } from '@utils/ui/snackbarex'
import { useCallback, useEffect, useState } from 'react'
import { AdminViewPanel } from './AdminViewPanel'

export type AVDatabaseProps = {
  zoomed: boolean
  setZoomed: () => void
}

export const AVDatabase = ({ zoomed, setZoomed }: AVDatabaseProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const [cacheNames, setCacheNames] = useState<CacheNameJson[]>()
  const [cacheName, setCacheName] = useState<CacheNameJson>()
  const { enqueMsg } = useSnackbarEx()

  const updateStatistics = () => {
    apiCall({
      method: 'GET',
      rest: 'admin/updateStatistics'
    })
    enqueMsg('Wird ausgeführt, Vorgang wird im Hintergrund ausgeführt', 'info')
  }

  const invalidateCaches = () => {
    apiCall({
      method: 'POST',
      rest: 'admin/invalidateCaches',
      onSuccess: () => {
        enqueMsg('Alle Caches gelöscht', 'success')
        loadCachedNames()
      }
    })
  }

  const loadCachedNames = useCallback(() => {
    apiCall<CacheNamesJson>({
      method: 'GET',
      rest: 'admin/cachenames',
      onSuccess: (data) => {
        setCacheNames(
          data.cacheNames.sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
        )
      }
    })
  }, [apiCall])

  const invalidateCache = () => {
    apiCall({
      method: 'POST',
      rest: 'admin/invalidateCache',
      params: { entityName: cacheName.name },
      onSuccess: () => {
        enqueMsg('Cache gelöscht', 'success')
        loadCachedNames()
      }
    })
  }

  useEffect(() => {
    if (zoomed) {
      loadCachedNames()
    }
  }, [loadCachedNames, zoomed])

  return (
    <AdminViewPanel
      zoomed={zoomed}
      setZoomed={setZoomed}
      title="DB Wartung"
      subtitle="Funktionen zur Wartung der Datenbank"
    >
      {zoomed && (
        <Grid container spacing={4} direction="column">
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                label="DB Statistiken aktualisieren"
                variant="contained"
                onClick={updateStatistics}
                disabled={apiBusy}
                fullWidth
                color="warning"
              />
            </Grid>
            <Grid item xs={12} sm={6} flexGrow={1}>
              <Typography variant="subtitle2">
                Wenn Suchanfragen zu lange dauern kann das an fragmentierten Indexen liegen, die
                hiermit neu aufgebaut werden. <br />
                ACHTUNG: Der Vorgang kann länger Zeit dauern!
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                label="Alle Cachedaten löschen"
                variant="contained"
                onClick={invalidateCaches}
                disabled={apiBusy}
                fullWidth
                color="warning"
              />
            </Grid>
            <Grid item xs={12} sm={6} flexGrow={1}>
              <Typography variant="subtitle2">
                Löscht alle im Cache gespeicherten Daten. <br />
                Für den Fall, dass die Datenbank manuell geändert wurde und die Caches damit nicht
                mehr gültig sind.
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <AutocompleteEx<CacheNameJson>
                value={cacheName}
                onChange={(e) => setCacheName(e.target.value)}
                label="Tabelle oder Klasse"
                disabled={apiBusy}
                fullWidth
                variant="outlined"
                required
                options={cacheNames}
                optionLabel={
                  (o) =>
                    (o.name.lastIndexOf('.') > 0
                      ? o.name.substring(o.name.lastIndexOf('.') + 1)
                      : o.name) +
                    ' (' +
                    o.tablename +
                    ') '
                  /* TLP-4385 : Feedback/Verify  bzgl. 'invalidate Cache' funktioniert noch nicht */
                  //      +
                  //     o.cacheCount
                }
                optionValue={(o) => o}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Button
                label="Cache löschen"
                variant="contained"
                fullWidth
                color="warning"
                disabled={cacheName == null}
                onClick={invalidateCache}
              />
            </Grid>
            <Grid item xs={12} sm={6} flexGrow={1}>
              <Typography variant="subtitle2">
                Löscht Cache für eine Tabelle. <br />
                Für den Fall, dass die Datenbank manuell geändert wurde und ein einzelner Cache
                betroffen ist.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </AdminViewPanel>
  )
}
