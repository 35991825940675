import { Grid } from '@mui/material'
import { grey } from '@mui/material/colors'
import { EkPreisWertJson } from '@one/typings/apiTypings'
import { resolveObjectField } from '@utils/utils'

export function preisCellFormator<T = EkPreisWertJson>(
  werte: T[],
  formatter: (val: any) => string,
  field: string
) {
  if (werte == null) {
    return null
  }
  return werte
    .map((w) => w[field])
    .filter((w) => w != null)
    .map((m) => formatter(m))
}

export type WerteCellProps<T = any> = {
  werte: T[]
  field?: string
  formatter: (val: any, w: T) => any
  darkMode: boolean
  align?: 'center' | 'right' | 'left'
  padlr?: boolean
}

export const WerteCell = <T = any,>({
  werte,
  field,
  formatter,
  darkMode,
  align = 'right',
  padlr = true
}: WerteCellProps<T>) => {
  const werteEx = werte?.length > 0 ? werte : [{ staffelmenge: 0 }]
  const color = darkMode ? grey[500] : grey[500]
  const p = werteEx.length > 1 ? '8px' : '4px'
  return (
    <Grid container paddingTop={p} paddingBottom={p}>
      {werteEx.map((w, idx) => (
        <Grid
          item
          key={w.staffelmenge}
          textAlign={align}
          minWidth="100%"
          paddingLeft={padlr && '12px'}
          paddingRight={padlr && '4px'}
          paddingTop="2px"
          style={{
            fontSize: werteEx.length > 1 ? '90%' : null,
            borderBottom: idx < werteEx.length - 1 ? `solid 1px ${color}` : null
          }}
        >
          {field && resolveObjectField(w, field) == null ? (
            <>&nbsp;</>
          ) : (
            <>{formatter(resolveObjectField(w, field), w)}</>
          )}
        </Grid>
      ))}
    </Grid>
  )
}
