import { HideSource, OnlinePrediction } from '@mui/icons-material'
import { Chip, Grid } from '@mui/material'
import { red } from '@mui/material/colors'
import { ReactNode, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'
import { AppPaths } from './AppPaths'
import { EventNames } from './EventNames'
import { JobSchedulerStatus, SyncInfoJson, SyncState } from './typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { useObserver } from '@utils/observer'
import { SyncSign, SyncSignType } from '@utils/ui/SyncSign'

const useStyles = makeStyles()({
  alert: {
    backgroundColor: red[300],
    animation: `${keyframes`
    0% {
      opacity: 1;
    }
    80% {
      opacity: 0.5;
      backgroundColor: unset;
    }
    100% {
      opacity: 1;
    }
    `} 6s ease 0s infinite normal forwards`,
    '&:hover': {
      animation: 'none'
    }
  }
})

export type ExportInfo = {
  sign: SyncSignType
  goto?: string
  auto?: string
}

const init = { sign: SyncSignType.unknown }

export const SyncMonitor = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [exportState, setExportState] = useState<ExportInfo>(init)
  const [importState, setImportState] = useState<JobSchedulerStatus>(null)

  useObserver<any>(
    [
      EventNames.PULSE_OFFLINE,
      EventNames.PULSE_ERROR,
      EventNames.PULSE_EXPORTSTATUS,
      EventNames.PULSE_IMPORTSCHEDULERSTATUS
    ],
    ({ name, data }) => {
      switch (name) {
        case EventNames.PULSE_OFFLINE:
        case EventNames.PULSE_ERROR:
          setExportState({ sign: SyncSignType.offline })
          setImportState(null)
          break

        case EventNames.PULSE_IMPORTSCHEDULERSTATUS:
          setImportState(data)
          break

        case EventNames.PULSE_EXPORTSTATUS:
          setExportState(() => {
            const syncInfo = data as SyncInfoJson
            if (syncInfo == null) {
              return { sign: SyncSignType.off }
            }

            switch (syncInfo.syncState) {
              case SyncState.OK:
                return {
                  sign: SyncSignType.ok,
                  goto: AppPaths.Dashboard
                }

              case SyncState.TODOS:
                if (syncInfo.autoExport) {
                  return {
                    sign: SyncSignType.autoTodo,
                    auto: syncInfo.autoExport,
                    goto: AppPaths.ExportToErpUebersicht
                  }
                }
                return {
                  sign: SyncSignType.todo,
                  goto: AppPaths.ExportToErpUebersicht
                }

              case SyncState.RUNNING:
                return {
                  sign: SyncSignType.running,
                  goto: AppPaths.Dashboard
                }

              case SyncState.ERROR:
                return {
                  sign: SyncSignType.error,
                  goto: AppPaths.DashboardFilterFn(AppPaths.DashboardQueries.error)
                }

              default:
                return {
                  sign: SyncSignType.unknown
                }
            }
          })
          break
      }
    }
  )

  return useMemo(() => {
    const onSyncClick = () => {
      navigate(exportState.goto)
    }

    let importStatusIcon: ReactNode = null
    switch (importState) {
      case JobSchedulerStatus.NOT_STARTED:
        importStatusIcon = (
          <Chip
            icon={<HideSource />}
            label="Importer inaktiv!"
            variant="filled"
            size="small"
            className={classes.alert}
          />
        )
        break
      case JobSchedulerStatus.RUNNING:
        importStatusIcon = <OnlinePrediction />
        break
      case JobSchedulerStatus.SLEEPING:
        importStatusIcon = null
        break
    }

    const exportStatusIcon =
      exportState.sign != null ? (
        <SyncSign
          type={exportState.sign}
          info={
            exportState.sign === SyncSignType.autoTodo ? formatDateTime(exportState.auto) : null
          }
          onClick={onSyncClick}
        />
      ) : null

    return (
      <>
        {importStatusIcon != null ? <Grid item>{importStatusIcon}</Grid> : null}
        {exportStatusIcon != null ? <Grid item>{exportStatusIcon}</Grid> : null}
      </>
    )
  }, [importState, exportState.sign, exportState.auto, exportState.goto, navigate, classes.alert])
}
