import { NeonKontext } from '@one/typings/apiTypings'
import { ChipContainer } from '@utils/ui/fields/ChipContainer'
import { distinctItems } from '@utils/utils'

export type NeonKontextChipProps = {
  value: NeonKontext[]
  fehlerArtikel?: NeonKontext[]
  fehlerEkPreis?: NeonKontext[]
  fehlerVkPreis?: NeonKontext[]
  green?: boolean
}

export const NeonKontextChips = ({
  value,
  fehlerArtikel,
  fehlerEkPreis,
  fehlerVkPreis,
  green
}: NeonKontextChipProps) => {
  const merged = distinctItems(
    [
      ...(value ?? []),
      ...(fehlerArtikel ?? []),
      ...(fehlerEkPreis ?? []),
      ...(fehlerVkPreis ?? [])
    ],
    (a) => a
  ).sort((a, b) => a.localeCompare(b))

  const isError = (k: NeonKontext) => {
    return fehlerArtikel?.includes(k) || fehlerEkPreis?.includes(k) || fehlerVkPreis?.includes(k)
  }

  return (
    <ChipContainer
      value={merged}
      getValueLabel={(k) => k.substr(2)}
      getChipColor={(k) => (isError(k) ? 'error' : green ? 'success' : 'default')}
      getValueTip={(k) => {
        let msg = ''
        if (fehlerArtikel?.includes(k)) {
          msg = msg + 'Fehlerhafte Artikeldaten\n'
        }
        if (fehlerEkPreis?.includes(k)) {
          msg = msg + 'Fehlerhafte EK-Preise.\n'
        }
        if (fehlerVkPreis?.includes(k)) {
          msg = msg + 'Fehlerhafte VK-Preise'
        }
        if (msg.length > 0) {
          return (
            'Daten zum Kontext fehlen oder sind nicht aktuell, da die Listung fehlerhafte Daten lieferte:\n' +
            msg
          )
        }
        return green ? 'Kontext ok' : null
      }}
      compact
    />
  )
}

export const formatNeonKontexte = (
  kontexte: NeonKontext[],
  fehlerArtikel: NeonKontext[],
  fehlerEkPreis: NeonKontext[],
  fehlerVkPreis: NeonKontext[]
) => {
  return [
    ...(kontexte?.map((k) => k.substr(2)) ?? []),
    ...(fehlerArtikel?.map((k) => k.substr(2) + '-Artikelfehler') ?? []),
    ...(fehlerEkPreis?.map((k) => k.substr(2) + '-EkPreisfehler') ?? []),
    ...(fehlerVkPreis?.map((k) => k.substr(2) + '-VkPreisfehler') ?? [])
  ]
}
