import { Typography } from '@mui/material'
import { Grid2 as Grid } from '@mui/material'
import { ArtikelCell } from '@one/components/common/ArtikelCell'
import { ArtikelDatenpoolUebernahmeJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Chip } from '@mui/material'
import React from "react"

export interface UebernahmeEigenlistungDetailsProps {
  artikel: ArtikelDatenpoolUebernahmeJson
}

export const UebernahmeEigenlistungDetails = ({ artikel }: UebernahmeEigenlistungDetailsProps) => {
  const fehler =
    artikel && artikel.fehler ? (
      <Grid flexGrow={1}>
        <CardEx title="Fehler" height="100%" borderless>
          <table style={{tableLayout: 'fixed', textAlign: 'right'}}>
            {artikel.fehler.subMessages.map((item, index) => (
              // eslint-disable-next-line react/jsx-key
              <tr>
                <td>
                  <Chip color={item.severity==="WARN"?"default":"error"}
                    label={item.severity==="WARN"?"Warnung":"Fehler"}
                    sx={{minWidth:80}}
                    size='small'
                  />&nbsp;
                </td>
                <td style={{textAlign:'left'}}>
                  <div key={`${item.messageId.id}-${index}`}>
                    <Typography variant="body2">{item.message}</Typography>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </CardEx>
      </Grid>
) : null

const artikelList = artikel && artikel.artikel?.length ? (
      <Grid flexGrow={1}>
        <CardEx
          title="Artikel, die eine Anlage verhindern"
          subheader="hage-Nr., IAN oder EAN werden schon verwendet:"
          height="100%"
          borderless
        >
          {artikel.artikel
            .filter((a, idx) => artikel.artikel.findIndex((aa) => aa.id == a.id) == idx)
            .map((item) => (
              <ArtikelCell key={`${item.id}`} artikel={item} asLink />
            ))}
        </CardEx>
      </Grid>
    ) : null

  return (
    <Grid container direction="column" spacing={1} height="calc( 100% + 8px )" marginBottom={-1}>
      {fehler}
      {artikelList}
    </Grid>
  )
}
