import { HkmEnum } from '@one/enums/HkmEnum'
import { SpreadSheetType } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SelectField } from '@utils/ui/fields/SelectField'

export type ExportTypFieldProps = {
  value: SpreadSheetType
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  name?: string
  label?: string
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
}

export const ExportTypField = ({
  name,
  label,
  disabled,
  required,
  fullWidth,
  value,
  onChange
}: ExportTypFieldProps) => {
  const { et } = useEnums()
  return (
    <SelectField
      value={value}
      label={label ?? 'Export-Typ'}
      name={name}
      onChange={onChange}
      options={[{ id: SpreadSheetType.CSV }, { id: SpreadSheetType.XLSX }]}
      renderItem={(v) => et(HkmEnum.SpreadSheetType, v.id)}
      required={required}
      fullWidth={fullWidth}
      disabled={disabled}
    />
  )
}
