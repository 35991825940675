import { Button, ButtonProps } from './Button'

export type CancelButtonProps = {
  visible?: ButtonProps['visible']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  size?: ButtonProps['size']
  autoFocus?: ButtonProps['autoFocus']
}

export const CancelButton = (props: CancelButtonProps) => (
  <Button {...props} label="Abbrechen" variant="contained" color="secondary" />
)
