import { yellow } from '@mui/material/colors'
import React from 'react'

export interface EmphasizeFrameProps {
  emphasize?: boolean
  children: React.ReactNode
}

export const EmphasizeFrame = ({ emphasize = false, children }) => {
  const style = emphasize
    ? { padding: 4, margin: -6, border: '3px solid ' + yellow[400], borderRadius: 4 }
    : {}
  return <div style={style}>{children}</div>
}
