import clsx from 'clsx'
import { CSSProperties, DragEventHandler, ReactNode, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ScrollPanel } from './ScrollPanel'

const useStyles = makeStyles()({
  frame: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    alignItems: 'stretch',
    marginLeft: -2,
    paddingLeft: 2
  },
  body: {
    display: 'block',
    // minHeight: '200px',
    flexGrow: 1
  },
  denseBody: {
    display: 'block',
    height: '50px',
    flexGrow: 1
  },
  scroll: {
    overflow: 'auto'
  },
  row: {
    //
  },
  w100: {
    width: '100%'
  },
  h100: {
    height: '100%'
  },
  space: {
    gap: 8
  }
})

export type FrameProps = {
  className?: any
  padding?: CSSProperties['padding']
  fullHeight?: boolean
  space?: boolean
  children: ReactNode
  onDragOver?: DragEventHandler<HTMLDivElement>
}

export const Frame = ({
  className,
  padding,
  fullHeight,
  onDragOver,
  space,
  children
}: FrameProps) => {
  const { classes } = useStyles()
  return (
    <ScrollPanel>
      <div
        onDragOver={onDragOver}
        className={clsx(
          classes.frame,
          space && classes.space,
          fullHeight && classes.h100,
          className
        )}
        style={{ padding }}
      >
        {children}
      </div>
    </ScrollPanel>
  )
}

export type FrameRowProps = {
  className?: any
  style?: any
  children: ReactNode
}

export const FrameRow = ({ className, style, children }: FrameRowProps) => {
  const { classes } = useStyles()
  const cn = useMemo(() => clsx(classes.row, className), [className, classes.row])
  return (
    <div className={cn} style={style}>
      {children}
    </div>
  )
}

export type FrameBodyProps = {
  ref?: any
  className?: any
  style?: any
  scroll?: boolean
  grow?: number
  children?: ReactNode
  display?: CSSProperties['display']
  padding?: CSSProperties['padding']
  position?: CSSProperties['position']
  dense?: boolean
  bodyRef?: any
}

export const FrameBody = ({
  className,
  style,
  scroll,
  grow,
  display,
  padding,
  position,
  bodyRef,
  dense,
  children
}: FrameBodyProps) => {
  const { classes } = useStyles()
  const styler = { ...style }
  if (grow != null) styler.flexGrow = grow
  if (display != null) styler.display = display
  if (padding != null) styler.padding = padding
  if (position != null) styler.position = position
  return (
    <div
      ref={bodyRef}
      className={clsx(
        dense ? classes.denseBody : classes.body,
        className,
        scroll && classes.scroll
      )}
      style={styler}
    >
      <ScrollPanel>{children}</ScrollPanel>
    </div>
  )
}
