import { StandortGruppeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'

export interface StandortGruppeUbersichtKopfProps {
  value: StandortGruppeSearcherCriteriaJson
  onChange: ValueChangeFn<StandortGruppeSearcherCriteriaJson>
  onSearch: VoidFunction
}

export const StandortGruppeUbersichtKopf = ({
  value,
  onChange,
  onSearch
}: StandortGruppeUbersichtKopfProps) => {
  return (
    <CardEx
      title="Standortgruppen Übersicht"
      backLink
      bottomActions={<SearchButton onClickVoid={onSearch} />}
    >
      {/* <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
         
        </Grid>
      </Grid> */}
    </CardEx>
  )
}
