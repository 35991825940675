import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  EkPreisListeImportDisplayJson,
  EkPreisListeImportSearcherCriteriaJson,
  EkPreisListeUploadResultJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { DropArea } from '@utils/ui/DropArea'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { EkPreisImportUebersichtKopf } from './EkPreisImportUebersichtKopf'
import { EkPreisImportUebersichtTable } from './EkPreisImportUebersichtTable'

export const EkPreisImportUebersichtView = () => {
  const { search, criteria, onCriteriaChange, result } = useSearcherState<
    EkPreisListeImportSearcherCriteriaJson,
    EkPreisListeImportDisplayJson
  >({
    api,
    url: '/ekpreislisteimport'
  })

  const navigate = useNavigate()
  const [apiCall, apiBusy] = useApiCaller(api)

  useObserver<any>(EventNames.EKPREISIMPORT, () => {
    search()
  })

  const onUpload = useCallback(
    (response: AxiosResponse) => {
      const result = response.data as EkPreisListeUploadResultJson
      if (result.id != null) {
        search()
        navigate(AppPaths.EkPreisImportPflegeFn(result.id))
      }
    },
    [search, navigate]
  )

  return (
    <DropArea
      api={api}
      path="ekpreislisteimport/upload"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      onComplete={onUpload}
    >
      <Frame space>
        <FrameRow>
          <EkPreisImportUebersichtKopf
            value={criteria}
            onChange={onCriteriaChange}
            onSearch={search}
          ></EkPreisImportUebersichtKopf>
        </FrameRow>
        <FrameBody>
          <EkPreisImportUebersichtTable result={result} onUpload={onUpload} />
        </FrameBody>
      </Frame>
    </DropArea>
  )
}
