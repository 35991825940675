import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useFormState } from '@utils/formstate'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { ensureArray } from '@utils/utils'
import React, { ChangeEvent, useMemo } from 'react'

export interface AdditionalFilter {
  count: number
  total: number
  label: string
  name: string
  checked: boolean
  onChange: (event: ChangeEvent) => void
  onFilter: (row: any) => boolean
}

export interface AdditionalFilterMenuProps {
  filter?: AdditionalFilter[]
}

export const AdditionalFilterMenu = ({ filter = [] }: AdditionalFilterMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (filter == null || filter.length === 0 || filter.find((f) => f.count > 0) == null) {
    return null
  }

  const filterCount = filter.reduce((fcount, i) => fcount + (i.checked ? 0 : i.count), 0)
  const filterTotal = filter[0].total

  return (
    <div style={{ paddingTop: 8 }}>
      <Button
        variant="contained"
        onClick={handleClick}
        color={filterCount > 0 && filterCount == filterTotal ? 'error' : 'primary'}
      >
        Ausschlüsse... {filterCount} / {filterTotal}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {filter
          .filter((item) => item.count > 0)
          .map((item, index) => (
            <MenuItem key={`menu-item-${index}`}>
              <Checkbox
                key={item.name || index}
                label={item.label}
                size="small"
                name={item.name}
                checked={item.count != 0 && item.checked}
                onChange={item.onChange}
                disabled={item.count === 0}
                color="default"
              />
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
}

export interface AdditionalFilterConfig<T = any> {
  label: string
  name: string
  onFilter: (row: T) => boolean
}

export const useAdditionalFilter = <T = any,>(
  liste: T[],
  filter: AdditionalFilterConfig<T> | AdditionalFilterConfig<T>[],
  filterException?: (row: any) => boolean
): [T[], AdditionalFilter[]] => {
  const [checked, onChangeChecked] = useFormState({})

  const state = useMemo(() => {
    if (liste == null) {
      return [[], []]
    }

    const sf = ensureArray(filter)
    if (sf.length === 0) {
      return [liste, []]
    }

    const filterConfig = sf.map((fc) => {
      const diff = liste.filter((row) => fc.onFilter(row)).length
      return {
        label: `Inkl. ${diff} ${fc.label}`,
        name: fc.name,
        checked: checked[fc.name],
        onChange: onChangeChecked,
        count: diff,
        total: liste.length,
        onFilter: fc.onFilter
      } as AdditionalFilter
    })

    const filtered = liste.filter((row) => {
      if (filterException && filterException(row)) {
        return true
      }
      return filterConfig.find((fc) => !fc.checked && fc.onFilter(row)) == null
    })

    return [filtered, filterConfig]
  }, [checked, filter, filterException, liste, onChangeChecked]) as [[], AdditionalFilter[]]

  return state
}
