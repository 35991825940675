import { api } from '@one/api'
import { SeverityCell } from '@one/components/common/SeverityCell'
import { protokollSeverity } from '@one/components/Protokolle/ProtokolleUebersicht/ProtokolleUebersichtTable'
import { HkmEnum } from '@one/enums/HkmEnum'
import { DownloadResponseJson, ExportToErpDisplayJson } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column, DataTableOrderType } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDownloader } from '@utils/ui/downloader'
import { Medal } from '@utils/ui/fields/Medal'
import { useContext, useMemo } from 'react'

const initialOrderBy: DataTableOrderType[] = [
  { key: 'exportStart', dir: 'desc' },
  { key: 'exportEnd', dir: 'desc' }
]

export interface ExportToErpUebersichtTableProps {
  result: SearcherResultType<ExportToErpDisplayJson>
}

export const ExportToErpUebersichtTable = ({ result }: ExportToErpUebersichtTableProps) => {
  const { isExternesERP } = useContext(AppContext)
  const { et } = useEnums()
  const [download] = useDownloader()
  const [apiCall] = useApiCaller(api)

  const columns = useMemo(
    () =>
      [
        {
          field: 'exportStart',
          header: 'Gestartet',
          sortable: true,
          type: 'datetime'
        },
        {
          field: 'exportEnd',
          header: 'Beendet',
          sortable: true,
          type: 'datetime'
        },
        {
          field: 'types',
          header: 'Typ',
          sortable: true,
          valueGetter: (row) => row.types.map((typ) => et(HkmEnum.ExportDataToErpStepType, typ)),
          body: (row: any, { column }) =>
            row.types.map((typ) => (
              <Medal
                key={typ}
                text={et(HkmEnum.ExportDataToErpStepType, typ)}
                fullWidth
                variant="square"
              />
            ))
        },
        {
          field: 'confirmed',
          header: 'Bestätigt',
          type: 'datetime',
          sortable: true,
          off: isExternesERP
        },
        {
          field: 'exportStatus',
          header: 'Status',
          type: 'string',
          sortable: true,
          off: isExternesERP
        },
        {
          field: 'exportedCount',
          header: 'Anzahl Exportiert',
          sortable: true,
          type: 'number'
        },
        {
          field: 'confirmedCount',
          header: 'Anzahl Bestätigt',
          sortable: true,
          type: 'number',
          off: isExternesERP
        },
        {
          field: 'severity',
          header: 'Schwere',
          align: 'center',
          valueGetter: (row: any) => et(HkmEnum.MessageSeverity, protokollSeverity(row)),
          body: (row) => <SeverityCell value={protokollSeverity(row)} />,
          sortable: true
        },
        {
          field: 'detailCount',
          header: 'Meldungen',
          type: 'number',
          body: (row) => (row.protokoll.detailCount > 0 ? `${row.protokoll.detailCount}` : ''),
          sortable: true
        },
        {
          field: 'detailWarningCount',
          header: 'Warnungen',
          type: 'number',
          body: (row) =>
            row.protokoll.detailWarningCount > 0 ? `${row.protokoll.detailWarningCount}` : '',
          sortable: true
        },
        {
          field: 'detailErrorCount',
          header: 'Fehler',
          type: 'number',
          body: (row) =>
            row.protokoll.detailErrorCount > 0 ? `${row.protokoll.detailErrorCount}` : '',
          sortable: true
        },
        {
          field: 'detailFatalCount',
          header: 'Fatale Fehler',
          type: 'number',
          body: (row) =>
            row.protokoll.detailFatalCount > 0 ? `${row.protokoll.detailFatalCount}` : '',
          sortable: true
        }
      ] as Column<ExportToErpDisplayJson>[],
    [et, isExternesERP]
  )

  const actions = useMemo(
    () =>
      [
        {
          role: [UserRoles.ERP_DOWNLOAD, UserRoles.ADMIN],
          tooltip: 'Download Exportdaten',
          icon: 'download',
          direct: true,
          handler: (row) => {
            apiCall<DownloadResponseJson>({
              rest: 'export/downloadExportStepData',
              method: 'GET',
              params: { id: row.id },
              onSuccess: (data) => {
                download(`rest/download/${data.key}`)
              }
            })
          }
        }
      ] as DataTableAction<ExportToErpDisplayJson>[],
    [apiCall, download]
  )

  return (
    <DataTableCard
      name="ExportToErpUebersichtTable"
      title="Exporte"
      columns={columns}
      actions={actions}
      filterMode="both"
      dense
      initialOrderBy={initialOrderBy}
      localStateName="ExportToErpUebersichtTable"
      result={result}
    />
  )
}
