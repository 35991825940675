import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { ImportErrorErpArtikelJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { useContext, useMemo } from 'react'
import { ImportFehlerView } from './ImportFehlerView'

export const ImportArtikelFehlerUebersichtView = () => {
  const { et } = useEnums()
  const { isPIMModel } = useContext(AppContext)

  const columns = useMemo<Column<ImportErrorErpArtikelJson>[]>(
    () => [
      {
        field: 'artikel.hageNummer',
        header: 'hage-Nr.'
      },
      {
        off: isPIMModel,
        field: 'artikel.btNummer',
        header: 'Artikel-Nr.'
      },
      {
        field: 'artikel.ean',
        header: 'EAN'
      },
      {
        field: 'artikel.aboStatus',
        header: 'Abostatus',
        align: 'center',
        body: (row) =>
          row?.artikel && <ArtikelAbonniertStatusSymbol value={row.artikel.aboStatus} et={et} />,
        valueGetter: (row) =>
          row?.artikel && getArtikelAbonniertStatusText(et, row.artikel.aboStatus)
      },
      {
        field: 'artikel.bez1',
        header: 'Bez1'
      },
      {
        field: 'artikel.bez2',
        header: 'Bez2'
      },
      {
        field: 'artikel.bez3',
        header: 'Bez3'
      },
      {
        field: 'artikel.bez4',
        header: 'Bez4'
      }
    ],
    [et]
  )
  return (
    <ImportFehlerView
      rest="import/artikel"
      title="Artikel Importfehler"
      columns={columns}
      name="ArtikelImportFehlerView"
    />
  )
}
