import { Button, ButtonProps } from './Button'

export type SaveButtonProps = {
  isNew?: boolean
  isChanged?: boolean
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  visible?: boolean
  anlegen?: boolean
  disabled?: boolean
  size?: ButtonProps['size']
  tooltip?: React.ReactNode
}
export const SaveButton = ({
  isNew,
  isChanged,
  onClick,
  onClickVoid,
  visible = true,
  anlegen,
  disabled,
  size,
  tooltip
}: SaveButtonProps) => (
  <Button
    label={isNew || anlegen ? 'Anlegen' : 'Speichern'}
    onClick={onClick}
    onClickVoid={onClickVoid}
    variant="contained"
    color="primary"
    disabled={disabled != null ? disabled : !isNew && !isChanged}
    visible={visible}
    tooltip={tooltip}
    size={size}
  />
)
