/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import { Delete } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Grid, Typography } from '@mui/material'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { NumberField } from '@utils/ui/fields/NumberField'
import { counter } from '@utils/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { makeStyles } from 'tss-react/mui'

export interface StaffelwerteDialogProps {
  onClose?: (fn?: () => void) => any
  visible?: boolean
  value?: any[]
  onChange?: (values: any[]) => void
  checkStaffelwerte?: () => object
  readonly?: boolean
}

const useStyles = makeStyles()({
  editfield: {
    width: '7rem',
    marginRight: 12,
    '& input': {
      textAlign: 'right'
    }
  },
  editdel: {
    '& input': {
      paddingRight: 20
    }
  },
  used: {
    '& input': {
      // color: red[800],
      fontWeight: 'bold'
    }
  },
  ditem: {
    alignSelf: 'baseline',
    paddingTop: 2
  },
  delete: {
    display: 'inline',
    marginLeft: -32,
    marginRight: 4
  }
})

const trim = (v) => {
  if (v == null) {
    return null
  }
  return Number(v)
}

export const StaffelwerteDialog = ({
  onClose,
  visible,
  value,
  onChange,
  checkStaffelwerte,
  readonly
}: StaffelwerteDialogProps) => {
  const { classes } = useStyles()

  const [state, setState] = useState([])
  const [org, setOrg] = useState([])

  const { askToConfirm } = useMessageDialog()

  const keyg = useMemo(() => counter(1, 1), [])

  const focusRef = useRef<number>(null)

  useEffect(() => {
    const used = checkStaffelwerte ? checkStaffelwerte() : {}
    const arr = value && value.length > 0 ? value : []
    arr.sort((a, b) => a - b)
    const next = arr.map((w) => ({
      key: keyg.next().value,
      orgValue: w,
      value: w,
      ro: true,
      deletable: w !== 0,
      used: used[w]
    }))
    setState(next)
    setOrg(next)
  }, [value, keyg, classes, checkStaffelwerte])

  const save = () => {
    const result = state.map((w) => [trim(w.value), trim(w.orgValue)])
    onClose(
      onChange
        ? () => {
            onChange(result)
          }
        : null
    )()
  }

  const onAdd = () => {
    const key = keyg.next().value as number
    focusRef.current = key
    setState([
      ...state,
      {
        value: '',
        orgValue: null,
        ro: false,
        deletable: true,
        key,
        used: false,
        error: 'Bitte Wert angeben'
      }
    ])
  }

  const onDeleteIndex = (idx) => {
    const w = state[idx]
    const copy = [...state]
    copy.splice(idx, 1)
    if (w.used) {
      askToConfirm({
        title: 'Diese Staffelmenge hat Werte, trotzdem löschen?',
        severity: 'severe',
        message: 'Die erfassten Werte gehen verloren.',
        confirmLabel: 'Löschen',
        onConfirm: () => setState(copy)
      })
    } else {
      setState(copy)
    }
  }

  useEffect(() => {
    if (focusRef.current != null) {
      const id = 'staffelwert-' + focusRef.current
      focusRef.current = null
      const el = document.getElementById(id)
      if (el != null) {
        el.focus()
      }
    }
  }, [state])

  const onValueChange = (idx) => (e) => {
    const val = e.target.value
    const vi = val == null ? -1 : state.findIndex((w) => Number(w.value) === Number(val))
    const error =
      val == null ? 'Bitte Wert angeben' : vi !== -1 && vi !== idx ? 'Doppelter Wert!' : null

    const copy = [...state]
    copy.splice(idx, 1, { ...state[idx], value: val, error })
    setState(copy)
  }

  const onSort = () => {
    const copy = [...state]
    copy.sort((a, b) => a.value - b.value)
    if (!isEqual(copy, state)) {
      setState(copy)
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSort()
    }
  }

  const actions = (
    <>
      {readonly ? null : (
        <>
          <Button label="Sortieren" onClick={onSort} variant="text" size="small" />
          <ApplyButton
            onClickVoid={save}
            size="small"
            disabled={
              state.findIndex((w) => w.error || w.value == null || w.value === '') !== -1 ||
              isEqual(state, org)
            }
          />
        </>
      )}
      <CancelButton onClick={onClose()} size="small" />
    </>
  )

  return (
    <DialogEx open={visible} onClose={onClose()} title="Staffelwerte" actions={actions} fullWidth>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
        spacing={2}
        padding={2}
      >
        {state.length === 0 ? (
          <Grid item>
            <Typography>Keine Staffelwerte vorhanden</Typography>
          </Grid>
        ) : (
          state.map((w, idx) => (
            <Grid item key={w.key} xs={3} minWidth="10em" minHeight="5em">
              <NumberField
                id={'staffelwert-' + w.key}
                title={w.used ? 'Achtung: Zu dieser Staffelmenge gibt es Werte!' : ''}
                thousandSeparator
                fraction={4}
                value={w.value}
                onChange={onValueChange(idx)}
                disabled={readonly || w.ro}
                error={w.error}
                onKeyDown={onKeyDown}
                fullWidth
                min={0}
                actions={
                  !readonly && {
                    Icon: Delete,
                    tooltip: 'Staffel entfernen',
                    onClick: () => onDeleteIndex(idx),
                    disabled: !w.deletable
                  }
                }
              />
            </Grid>
          ))
        )}
        {readonly ? null : (
          <Grid item style={{ alignSelf: 'baseline' }}>
            <IconButton
              icon={<AddIcon />}
              onClick={onAdd}
              size="small"
              title="Hinzufügen"
            ></IconButton>
          </Grid>
        )}
      </Grid>
    </DialogEx>
  )
}
