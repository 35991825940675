import { MessageJson } from './../typings/apiTypings'
import { ensureArray } from './utils'

export const flattenErrors = (
  errors: MessageJson[],
  showLevel: boolean = false,
  level: number = 1
): Omit<MessageJson, 'subMessages'>[] => {
  return ensureArray<MessageJson>(errors).reduce((newErrors, error) => {
    const { subMessages, ...newError } = error
    newError.message = showLevel
      ? `${new Array(level).join('--')}${level > 1 ? '>' : ''} ${newError.message}`
      : newError.message

    newErrors.push(newError)
    if (error?.subMessages && error?.subMessages.length) {
      return newErrors.concat(flattenErrors(error.subMessages, showLevel, level + 1))
    }
    return newErrors
  }, [])
}
