import { Box, Drawer, Grid, useTheme } from '@mui/material'
import { AppRouteDefinition } from '@utils/ui/App/AppRouteMgr'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { NavLinkProps } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { NavHeader } from './NavHeader'
import { NavList } from './NavList'
import { UserSettings } from './UserSettings'

// Constants
const drawerWidth = 256

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 1099
    }
  },
  drawerPaper: {
    '& .MuiPaper-root': {
      width: drawerWidth,
      overflowX: 'hidden',
      top: 48,
      height: 'calc(100% - 48px)'
    }
  }
}))
export interface SideNavigationProps {
  menuHideMode?: boolean
  setMenuHideMode?: (e: any, checked: boolean) => void
  drawerVisible?: boolean
  onDrawerToggle: NavLinkProps['onClick']
  menuItems: AppRouteDefinition[]
  children?: React.ReactNode
}

/**
 * Container component for a navigational drawer.
 *
 * @param {boolean} visible Flag indicating if the drawer should be visible or not
 * @param {function} onDrawerToggle Callback function to toggle drawer visibility
 */
export const SideNavigation = ({
  onDrawerToggle,
  menuItems,
  setMenuHideMode = null,
  drawerVisible = false,
  menuHideMode = true,
  children
}: SideNavigationProps) => {
  const { classes } = useStyles()
  const theme = useTheme()

  const renderDrawer = (closable = false) => (
    <Grid
      item
      container
      direction="column"
      width="100%"
      minHeight="100%"
      alignItems="stretch"
      flexShrink={0}
    >
      <Grid item width="100%">
        <NavHeader
          settingsNode={
            <UserSettings menuHideMode={menuHideMode} setMenuHideMode={setMenuHideMode} />
          }
        />
      </Grid>
      <Grid item width="100%" flexGrow={1} flexShrink={0}>
        <NavList
          menuItems={menuItems.filter((mi) => mi.label != null)}
          onNavigate={closable ? onDrawerToggle : null}
        />
      </Grid>
    </Grid>
  )

  const drawerToggled = (
    <Drawer
      className={classes.drawerPaper}
      variant="temporary"
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={drawerVisible}
      onClose={onDrawerToggle}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <ScrollPanel>{renderDrawer(true)}</ScrollPanel>
    </Drawer>
  )

  // if (menuHideMode) {
  //   return drawerToggled
  // }

  return (
    <Grid container direction="row" flexWrap="nowrap" height="100%" width="100%">
      {menuHideMode ? (
        drawerToggled
      ) : (
        <nav className={classes.drawer}>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {drawerToggled}
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Drawer className={classes.drawerPaper} variant="permanent" open>
              <ScrollPanel>{renderDrawer()}</ScrollPanel>
            </Drawer>
          </Box>
        </nav>
      )}
      <Grid item flexGrow={1} height="100%" width="100%">
        {children}
      </Grid>
    </Grid>
  )
}
