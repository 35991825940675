import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { LieferantAenderungenPanel } from '@one/components/Lieferant/LieferantAenderungenPanel'
import { useConfirmAboDelete } from '@one/components/common/useConfirmAboDelete'
import { ArtikelAboBearbeitenJson, ArtikelAboJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { checkRequired } from '@utils/utils'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArtikelAboForm } from './ArtikelAboForm'
import { ArtikelAboSelektionTable } from './ArtikelAboSelektionTable'

const validate = (model: ArtikelAboJson, last: ArtikelAboJson, uc: ArtikelAboBearbeitenJson) => {
  const errors = {} as any
  checkRequired(model, errors, 'name', 'Name')
  checkRequired(model, errors, 'lieferant', 'Lieferant')
  checkRequired(model, errors, 'lieferantSyncEinstId', 'LiSST')
  checkRequired(model, errors, 'bt', 'Betriebstyp')
  if (uc?.supportsEanGruppen) {
    checkRequired(model, errors, 'eanGruppeId', 'EAN-Gruppe')
  }
  return errors
}

export const ArtikelAboView = () => {
  const { id } = useContext(RouteContext) as any
  const { askToConfirm } = useMessageDialog()
  const navigate = useNavigate()
  const [apiCall] = useApiCaller(api)

  const preSaveHook = useCallback(
    ({ isNew, model, onContinue }) => {
      if (isNew && !model.dzLieferant) {
        askToConfirm({
          title: 'Neuen Lieferant abonnieren',
          message:
            'Es wurde kein Lieferant aus dem ERP-System zugeordnet. Ein neuer Lieferant wird im ERP-System angelegt.',
          confirmLabel: 'Anlegen',
          onConfirm: onContinue
        })
      } else {
        onContinue()
      }
    },
    [askToConfirm]
  )

  const {
    envelope,
    model,
    isNew,
    isChanged,
    errors,
    uiLock,
    onValueChange,
    save,
    reload,
    updModel,
    remove
  } = useModelMgr<
    ArtikelAboBearbeitenJson,
    ArtikelAboJson,
    ArtikelAboBearbeitenJson,
    ArtikelAboJson
  >({
    id,
    api,
    title: 'Artikelabo',
    unwrapField: 'abo',
    rest: 'artikelabo',
    init: { selektionen: [] },
    validate,
    preSaveHook,
    eventName: EventNames.ARTIKELABO
  })

  const onConfirmDelete = useConfirmAboDelete()

  const removeSelektion = (row: any) => () => {
    const onConfirm = () => {
      doRemove(true)
    }
    const doRemove = (forceChangeHauptSelection: boolean) => {
      apiCall({
        method: 'POST',
        rest: '/artikelselektion/delete',
        params: { id: row.id, forceChangeHauptSelection },
        onSuccess: reload,
        onError: (error) => onConfirmDelete(error, onConfirm)
      })
    }
    doRemove(false)
  }

  const addSelektion = (row: any) => {
    if (!isChanged) {
      reload()
    }
  }

  const onDeleteClick = useCallback(() => {
    const onRemoved = () => {
      navigate(AppPaths.ArtikelAboUebersicht)
    }
    const onConfirm = () => {
      remove({ onRemoved, params: { id, forceChangeHauptSelection: true } })
    }
    remove({
      onRemoved,
      onError: (error) => onConfirmDelete(error, onConfirm)
    })
  }, [navigate, id, onConfirmDelete, remove])

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.ArtikelAboUebersicht}>
      <Frame space>
        <FrameRow>
          <ArtikelAboForm
            model={model}
            supportsEanGruppen={envelope.supportsEanGruppen}
            errors={errors}
            isNew={isNew}
            onChange={onValueChange}
            updModel={updModel}
            lieferantSyncEinstList={envelope?.lieferantSyncEinstList}
            eanGruppen={envelope?.eanGruppen}
          />
        </FrameRow>
        <FrameBody>
          {isNew ? (
            <LieferantAenderungenPanel
              lieferant={model.lieferant}
              dzLieferant={model.dzLieferant}
              lieferantSyncEinstId={model.lieferantSyncEinstId}
              konditionDefinition={envelope.kondiDefinition}
            />
          ) : (
            <ArtikelAboSelektionTable
              model={model}
              errors={errors}
              removeSelektion={removeSelektion}
              addSelektion={addSelektion}
            />
          )}
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton
              onDelete={onDeleteClick}
              isNew={isNew}
              deleteMsg={`Artikelabo "${model.name}" wirklich löschen`}
              tooltip="Artikelabo löschen"
            />
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
