import { Grid, Typography } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { DzLieferantJson, ZeLieferantJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export type LieferantAdresseProps = {
  lieferant: DzLieferantJson | ZeLieferantJson
  dz?: boolean
}

export const LieferantAdresse = ({ lieferant, dz }: LieferantAdresseProps) => {
  const { et } = useEnums()
  const landKategorie = (lieferant as DzLieferantJson).landKategorie
  return (
    <CardEx title="Adresse">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StaticField label="Adresse">
            <Typography>{lieferant.hauptAdresse?.strasse ?? ''}</Typography>
            <Typography>{`${lieferant.hauptAdresse?.plz ?? ''} ${
              lieferant.hauptAdresse?.ort ?? ''
            }`}</Typography>
          </StaticField>
        </Grid>
        <Grid item xs={6} />
        {dz && (
          <Grid item xs={12}>
            <StaticField
              label="Länderkategorie"
              value={et(HkmEnum.LieferantLandKategorie, landKategorie)}
              empty
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <StaticField label="E-Mail" value={lieferant.hauptAdresse?.email} empty />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Internet" value={lieferant.hauptAdresse?.internet} empty />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Telefon" value={lieferant.hauptAdresse?.tel1} empty />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Fax" value={lieferant.hauptAdresse?.fax} empty />
        </Grid>
      </Grid>
    </CardEx>
  )
}
