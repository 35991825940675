import { amber, deepOrange, lightBlue, red } from '@mui/material/colors'
import { HkmEnum } from '@one/enums/HkmEnum'
import { MessageSeverity } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { ThemeContext } from '@utils/ui/Theme'
import { Medal, MedalProps } from '@utils/ui/fields/Medal'
import { useContext } from 'react'

const color = (severity, darkMode) => {
  switch (severity) {
    case MessageSeverity.INFO:
      return lightBlue[darkMode ? 500 : 200]
    case MessageSeverity.WARN:
      return amber[darkMode ? 500 : 200]
    case MessageSeverity.ERR:
      return deepOrange[darkMode ? 500 : 200]
    case MessageSeverity.FATAL:
      return red[darkMode ? 500 : 200]
    default:
      return null
  }
}

export type SeverityCellProps = {
  value?: MessageSeverity
  fullWidth?: MedalProps['fullWidth']
}

export const SeverityCell = ({ value, fullWidth }: SeverityCellProps) => {
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)
  return (
    <Medal
      text={et(HkmEnum.MessageSeverity, value)}
      backgroundColor={color(value, darkMode)}
      fullWidth={fullWidth}
    />
  )
}
