import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React from 'react'

export type CenterGridProps = {
  children: React.ReactNode
}

const useStyles = makeStyles()({
  grid: {
    flex: 1,
    height: '100vh'
  }
})

export const CenterGrid = ({ children }: CenterGridProps) => {
  const { classes } = useStyles()

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.grid}>
      {children}
    </Grid>
  )
}
