import { Add, Visibility } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { ArtikelListeSuchDialog } from '@one/components/Listen/ArtikelListeSuchDialog/ArtikelListeSuchDialog'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelListeDisplayJson,
  HKMAuditJson,
  SortimentBearbeitenJson,
  SortimentEintragJson,
  SortimentJson,
  SynchronisationStatus
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useModelMgr } from '@utils/modelmgr'
import { Action } from '@utils/ui/Action'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { Permission } from '@utils/ui/Permission'
import { StaticField } from '@utils/ui/fields/StaticField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { aidOf, pathOf } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'

export const SortimentView = () => {
  const { standortGruppeId, standortId } = useContext(RouteContext) as any

  const { envelope, model, isChanged, isNew, save, updModel, uiLock, reload, post } = useModelMgr<
    SortimentJson,
    SortimentJson,
    SortimentBearbeitenJson,
    SortimentJson
  >({
    id: standortGruppeId || standortId,
    idField: standortGruppeId ? 'standortgruppeId' : 'standortId',
    api,
    title: 'Sortimentspflege',
    rest: '/sortiment',
    unwrapField: 'sortiment'
  })

  const status = envelope.standort?.status || envelope.standortgruppe?.status
  const readonly = status === SynchronisationStatus.AKTIVIERT

  const [DlgAnker, dlgShow] = useDialogAnker()

  const { askToRemove } = useMessageDialog()

  const { et } = useEnums()

  const removeEintrag = useCallback(
    (row: SortimentEintragJson) => {
      askToRemove({
        onConfirm: () => {
          updModel((old) => ({
            ...old,
            eintraege: old.eintraege?.filter((e) => aidOf(e) !== aidOf(row))
          }))
        }
      })
    },
    [askToRemove, updModel]
  )

  const addEintrag = useCallback(() => {
    const doAddEintraege = (artikelListe: ArtikelListeDisplayJson[]) => {
      if (artikelListe != null) {
        updModel((old) => ({
          ...old,
          eintraege: [
            ...(old.eintraege ?? []),
            ...artikelListe.map((artikelListe) => ({
              artikelListe
            }))
          ]
        }))
      }
    }
    const filterEintraege = (al: ArtikelListeDisplayJson) => {
      return model.eintraege.find((e) => e.artikelListe.id === al.id) == null
    }
    dlgShow((visible, onClose) => (
      <ArtikelListeSuchDialog
        open={visible}
        onClose={onClose((v) => doAddEintraege(v))}
        multiple
        filter={filterEintraege}
        kontext={model.kontext}
      />
    ))
  }, [dlgShow, model.eintraege, model.kontext, updModel])

  // const sync = () => {
  //   post({
  //     params: { id: model.id },
  //     srv: 'sync'
  //   })
  // }

  const tableActions = useMemo(
    (): DataTableAction[] =>
      [
        {
          icon: 'delete',
          tooltip: 'Artikelliste entfernen',
          handler: removeEintrag,
          direct: true,
          check: () => (readonly ? Permission.DISABLED : Permission.ENABLED)
        },
        {
          icon: <Visibility />,
          tooltip: 'Artikelliste öffnen',
          getLink: (row) =>
            AppPaths.artikelliste.ArtikelListePflegeFn(row.artikelListe?.id, model.kontext)
        }
      ] as DataTableAction<SortimentEintragJson>[],
    [model.kontext, readonly, removeEintrag]
  )

  const columns = useMemo<Column<SortimentEintragJson>[]>(
    () => [
      {
        field: pathOf<SortimentEintragJson, ArtikelListeDisplayJson>('artikelListe', 'typ'),
        header: 'Typ',
        body: (row) => et(HkmEnum.ArtikelListeTyp, row.artikelListe?.typ)
      },
      {
        field: pathOf<SortimentEintragJson, ArtikelListeDisplayJson>('artikelListe', 'name'),
        header: 'Name'
      },
      {
        field: pathOf<SortimentEintragJson, ArtikelListeDisplayJson>(
          'artikelListe',
          'artikelAnzahl'
        ),
        header: 'Anzahl Artikel',
        type: 'number'
      },
      {
        field: pathOf<SortimentEintragJson, HKMAuditJson>('audit', 'createdBy'),
        header: 'Zugeordnet von'
      },
      {
        field: pathOf<SortimentEintragJson, HKMAuditJson>('audit', 'createdOn'),
        header: 'Zugeordnet am',
        type: 'datetime'
      }
    ],
    [et]
  )

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          icon: <Add />,
          tooltip: 'Artikelliste zuordnen',
          onClick: addEintrag,
          enabled: !readonly
        }
      ] as Action[],
    [addEintrag, readonly]
  )

  const ownerLabel = envelope?.standortgruppe ? 'Standortgruppe' : 'Standort'
  const statusName = et(HkmEnum.SynchronisationStatus, status)

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx backLink title="Sortiment">
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StaticField
                  label={ownerLabel}
                  value={envelope?.standortgruppe?.name || envelope?.standort?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StaticField
                  label={'Status ' + ownerLabel}
                  value={
                    readonly
                      ? `${statusName} - Sortiment nicht änderbar`
                      : `${statusName} - Sortiment änderbar`
                  }
                />
              </Grid>
            </Grid>
          </CardEx>
        </FrameRow>
        <FrameBody>
          <DataTableCard
            name="artikellisten"
            localStateName="SortimentArtikelListenTable"
            title="Zugeordnete Artikellisten"
            columns={columns}
            value={model.eintraege ?? []}
            emptyMessage="Es sind noch keine Artikellisten zugeordnet"
            dense
            actions={tableActions}
            topActions={actions}
            rowFiller
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {/* <PerformButton
              label="Zusammenstellen"
              tooltip="Artikel der Artikellisten werden zusammengestellt. Erfolgt sonst automatisch bei Aktivierung des Standorts bzw. der Standortgruppe"
              onClickVoid={sync}
              disabled={isNew || isChanged || model.syncTime != null}
              variant="text"
            /> */}
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
      <DlgAnker />
    </StatePlane>
  )
}
