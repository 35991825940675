import Grid from '@mui/material/Grid2'
import { HkmEnum } from '@one/enums/HkmEnum'
import {ErpArtikelDetailsJson, PimArtikelJson, SeArtikelJson} from '@one/typings/apiTypings'
import { ErrorsType, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { resolveObjectField } from '@utils/utils'

export interface PimArtikelKopfProps {
  pimArtikel: PimArtikelJson
  details?: ErpArtikelDetailsJson | null
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: ErrorsType
  readonly?: boolean
}

/**
 * PimArtikelKopf wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikel
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelKopf = ({
  pimArtikel,
  onChange,
  errors,
  readonly
}: PimArtikelKopfProps) => {
  return (
    <CardEx title="Kopf">
      <Grid container spacing={2}>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <StaticField label="hage-Nr." value={pimArtikel.hageNummer} name="hageNummer" />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          {
            readonly?(
              <StaticField
                label="Artikelart"
                value={pimArtikel.version.artikelArt}
                name="ArtikelArt"
                empty
            />
            ):(
              <SelectEnumField
                label="Artikelart"
                name="pimArtikel.version.artikelArt"
                onChange={onChange}
                enumType={HkmEnum.PimArtikelArt}
                value={pimArtikel.version.artikelArt}
                error={resolveObjectField(errors, 'pimArtikel.version.artikelArt')}
                fullWidth
            />
            )
          }
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <StaticField
            label="GTIN"
            name="pimArtikel.version.gtin"
            value={pimArtikel.version.gtin}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <TextField
            label="Produkttyp"
            name="pimArtikel.version.produkttyp"
            value={pimArtikel.version.produkttyp}
            error={resolveObjectField(errors, 'pimArtikel.version.produkttyp')}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <TextField
            label="Ursprungsland"
            name="pimArtikel.version.ursprungsland"
            value={pimArtikel.version.ursprungsland}
            error={resolveObjectField(errors, 'pimArtikel.version.ursprungsland')}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <TextField
            label="Ursprungsregion"
            name="pimArtikel.version.intrastatRegion"
            value={pimArtikel.version.intrastatRegion}
            error={resolveObjectField(errors, 'pimArtikel.version.intrastatRegion')}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <TextField
            label="Vorläufer"
            name="pimArtikel.version.vorlaeufer"
            value={pimArtikel.version.vorlaeufer}
            error={resolveObjectField(errors, 'pimArtikel.version.vorlaeufer')}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <TextField
            label="Nachfolger"
            name="pimArtikel.version.nachfolger"
            value={pimArtikel.version.nachfolger}
            error={resolveObjectField(errors, 'pimArtikel.version.nachfolger')}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <TextField
            label="Bontext"
            name="pimArtikel.versionKontext.bontext"
            value={pimArtikel.versionKontext?.bontext}
            error={resolveObjectField(errors, 'pimArtikel.versionKontext.bontext')}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <Checkbox
            label="Baustein"
            name="pimArtikel.version.bausteinKennzeichen"
            checked={pimArtikel.version.bausteinKennzeichen}
            disabled={readonly}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <Checkbox
            label="GFC-Artikel"
            name="pimArtikel.version.gfcArtikel"
            checked={pimArtikel.version.gfcArtikel}
            disabled={readonly}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs:12, sm:6, md:3, lg:2, xl:2 }}>
          <Checkbox
            label="Chargenpflicht"
            name="pimArtikel.version.chargenpflicht"
            checked={pimArtikel.version.chargenpflicht}
            disabled={readonly}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelKopfMemo = React.memo(PimArtikelKopf)
