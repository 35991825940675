import { MessageJson } from '@one/typings/apiTypings'
import { useEffect, useRef, useState } from 'react'
import { ApiExclusive, useApiCaller } from './apicaller'

const init = {
  data: null,
  resolving: null,
  error: null,
  toggle: null
}

export type ResolveCacheProps = {
  api: any
  dataId: any
  dataField?: string
  resetToggle?: any
  rest: string
  paramName: string
}

export const useResolveCache = <T>({
  api,
  dataId,
  dataField,
  resetToggle,
  rest,
  paramName
}: ResolveCacheProps): [T, boolean, MessageJson] => {
  const [reference, setReference] = useState(init)

  const cacheRef = useRef({ dataMap: new Map(), dataId: null })

  const [apiCall] = useApiCaller(api)

  useEffect(
    () => {
      if (dataId == null) {
        if (cacheRef.current) {
          cacheRef.current.dataId = null
        }
        setReference(init)
        return
      }
      const cache = cacheRef.current as any
      if (resetToggle !== cache.toggle) {
        cache.toggle = resetToggle
        cache.dataMap.clear()
      }

      if (cache.dataId !== dataId) {
        cache.dataId = dataId
        const cached = cache.dataMap.get(dataId)
        if (cached == null) {
          //@ts-ignore
          setReference({ ...init, resolving: true })
          apiCall({
            method: 'GET',
            exclusive: ApiExclusive.NONE,
            rest,
            params: { [paramName]: dataId },
            onSuccess: (data) => {
              cache.dataMap.set(dataId, { data: dataField ? data[dataField] : data })
              if (cache.dataId === dataId) {
                const k = cache.dataMap.get(cache.dataId)
                setReference(k)
              }
            },
            onError: (error) => {
              cache.dataMap.set(dataId, { error: error.mainMessage })
              if (cache.dataId === dataId) {
                const k = cache.dataMap.get(cache.dataId)
                setReference(k)
              }
            }
          })
          return
        }
        setReference(cached)
        return
      }
      cache.dataId = null
      setReference(init)
    },
    //@ts-ignore
    [apiCall, dataId, paramName, rest, resetToggle, dataField, reference?.dataId]
  )

  return [reference?.data as T, reference?.resolving, reference?.error]
}
