import React from "react"
import { green, grey } from "@mui/material/colors"
import { ArtikelDatenpoolMengeneinheit, PimArtikelVersionDisplayJson } from "@one/typings/apiTypings"
import { Column } from "@utils/ui/DataTable/DataTable"
import { Avatar, Chip } from '@mui/material'

export type WerteCellProps<T = any> = {
  werte: Record<string, ArtikelDatenpoolMengeneinheit>
  darkMode: boolean
}
/**
 * WerteCell: Zur Darstellung von Mengeneinheiten zb. innerhalb Tabellen.
 * @param werte
 * @param darkMode
 * @constructor
 */
export const WerteCell = <T = any>({werte, darkMode}: WerteCellProps<T>) => {
  if(Object.keys(werte).length == 0){
    return (<>Nicht vorhanden</>)
  }
  const color = darkMode ? grey[100] : 'black'
  let temp = [], i = 1;
  for(const [key, value] of Object.entries(werte)) {
    temp.push(value)
  }

  const columns: Column<PimArtikelVersionDisplayJson>[] = [
    {
      field: 'name',
      header: 'Einheit'
    },
    {
      field: 'smallestItemUnit',
      header: '' //Basis
    },
    {
      field: 'weightOfUnitMeasureBum',
      header: 'Gewicht'
    },
    {
      field: 'lengthOfUnitMeasureBum',
      header: 'Länge'
    },
    {
      field: 'widthOfUnitMeasureBum',
      header: 'Breite'
    },
    {
      field: 'heightOfUnitMeasureBum',
      header: 'Höhe'
    }
  ]


  return (
    <table style={{ tableLayout: 'fixed', textAlign: 'right' }}>
      <thead>
        {
          columns.map((m) => (
          // eslint-disable-next-line react/jsx-key
          <td style={{textAlign:'left'}}><b>{m.header}</b></td>
        ))}
      </thead>
      <tbody>
        {temp.map((row) => (
          // eslint-disable-next-line react/jsx-key
          <tr>
            <td style={{textAlign:'left', border:'none'}}>
              {row.name}
            </td>
            <td style={{textAlign:'center',border:'none'}}>
              {row.smallestItemUnit ?
                <Chip variant="outlined"
                  color="success"
                  label='Basis'
                  size='small'
                  avatar={
                  <Avatar sx={{ bgcolor: green[500] }}>B</Avatar>
                }/>
                  : ''
              }
            </td>
            <td style={{border:'none'}}>
              {row.weightOfUnitMeasureBum}
            </td>
            <td style={{border:'none'}}>
              {row.lengthOfUnitMeasureBum}
            </td>
            <td style={{border:'none'}}>
              {row.widthOfUnitMeasureBum}
            </td>
            <td style={{border:'none'}}>
              {row.heightOfUnitMeasureBum}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
