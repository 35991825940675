import { createContext } from 'react'
import { AppRouteCtxType } from './AppRouteCtx'

export interface AppSwitchState {
  current: string
  cache: Map<string, AppRouteCtxType>
  hookCache: Map<string, any>
  all: AppRouteCtxType[]
  appTitle: string
  maxCacheAge: number
  minCacheAge: number
  maxCacheKeepAliveCount: number
  session: any
}

export type AppSwitchCtxType = {
  getState: () => AppSwitchState
  setRoutes: (args: any) => void
  replaceHistory: (replaceFnc) => void
  replaceSearch: (search: string) => void
  pushHistory: (path: string) => void
  registerDirtyHook: (hook: any) => void
  invalidateHistory: (url: string) => void
  checkDirtyHook: (key: string) => boolean
  setWindowTitle: (title?: string) => void
  currentRouteKey: string
}

const AppSwitchCtxInit = {
  getState: () => {},
  setRoutes: (x) => {},
  replaceHistory: (replaceFnc) => {},
  replaceSearch: (search) => {},
  pushHistory: (path) => {},
  registerDirtyHook: (hook) => {},
  invalidateHistory: (url) => {},
  checkDirtyHook: (key) => false,
  setWindowTitle: (title) => {},
  currentRouteKey: null
} as AppSwitchCtxType

export const AppSwitchCtx = createContext<AppSwitchCtxType>(AppSwitchCtxInit)
