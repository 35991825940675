import { Grid } from '@mui/material'
import { EventNames } from '@one/EventNames'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { LabelGruppeBearbeitenJson, LabelGruppeJson, LabelJson } from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { PerformButton } from '@utils/ui/Buttons/PerformButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'
import { stopPropagation } from '@utils/ui/uiutils'
import { useCallback, useContext, useEffect, useState } from 'react'

export interface NewLabelDialogProps {
  onClose: (newLabel?: LabelJson, oldLabel?: LabelJson) => void
  open?: boolean
  label?: LabelJson
}

export const NewLabelDialog = ({ open, label, onClose }: NewLabelDialogProps) => {
  const [params, onChangeParams] = useFormState(
    label
      ? { ...label }
      : {
          kurz: '',
          bezeichnung: '',
          system: false
        }
  )
  const [error, setError] = useState({
    kurz: null,
    bezeichnung: null
  })

  const { checkUserRole } = useContext(AppContext)
  const isSysLabelEditor = checkUserRole(UserRoles.SYSLABEL_EDITOR)

  const { model, updModel, save, errors } = useModelMgr<
    LabelGruppeBearbeitenJson,
    LabelGruppeJson,
    LabelGruppeJson,
    LabelGruppeJson
  >({
    noid: true,
    api,
    unwrapField: 'data',
    title: 'Artikel-Labels',
    rest: 'artikel/labelgruppe',
    eventName: EventNames.ARTIKELLABEL,
    saveMutator: (model) =>
      isSysLabelEditor
        ? model
        : {
            ...model,
            elemente: model.elemente.filter((label) => !label.system)
          }
  })
  const [index, setIndex] = useState(null)

  const onLabelChange = useCallback(
    (nextLabel, oldLabel) => {
      if (!model.elemente || !nextLabel) {
        return
      }
      updModel((prevModel) => {
        const elemente = [...prevModel.elemente]
        if (index !== null) {
          elemente[index] = nextLabel
        } else if (oldLabel?.id) {
          let oldIndex = elemente.findIndex((label: LabelJson) => label.id === oldLabel?.id)
          elemente[oldIndex] = nextLabel
        } else {
          setIndex(elemente.length)
          elemente.push(nextLabel)
        }

        return { ...prevModel, elemente }
      })
    },
    [model, updModel, index]
  )

  useEffect(() => {
    onLabelChange(params, label)
  }, [params, onLabelChange, label])

  const onAdd = () => {
    if (params.kurz) {
      save({ then: () => onClose() })
    } else {
      setError({
        kurz: params.kurz?.length > 0 ? null : 'Bitte eine Kurz-Bezeichnung angeben',
        bezeichnung: null
      })
    }
  }

  const onCancel = () => {
    onClose()
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      stopPropagation(e)
      onAdd()
    }
  }

  return (
    <DialogEx
      open={open}
      onClose={onCancel}
      title={label ? 'Label editieren' : 'Neues Label erstellen'}
      disabledFullscreen
      maxWidth="sm"
      fullWidth
      height={isSysLabelEditor ? '330px' : '300px'}
      actions={
        <>
          <PerformButton onClick={onAdd} label={label ? 'Ändern' : 'Hinzufügen'} />
          <CancelButton onClick={onCancel} />
        </>
      }
    >
      <Grid container spacing={2} direction="column" padding={2}>
        <Grid item>
          <TextField
            name="kurz"
            value={params.kurz}
            onChange={onChangeParams}
            label="Kurzbezeichnung"
            error={error.kurz || errors.kurz}
            onKeyDown={onKeyDown}
            required
          />
        </Grid>
        <Grid item>
          <TextField
            name="bezeichnung"
            value={params.bezeichnung}
            onChange={onChangeParams}
            label="Bezeichnung"
            error={error.bezeichnung || errors.bezeichnung}
            onKeyDown={onKeyDown}
          />
        </Grid>
        {isSysLabelEditor && !label && (
          <Grid item>
            <Checkbox
              name="system"
              checked={params.system}
              onChange={onChangeParams}
              label="Systemlabel"
            />
          </Grid>
        )}
      </Grid>
    </DialogEx>
  )
}
