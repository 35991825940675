/* eslint-disable no-console */
import {
  formatDate,
  formatDateTime,
  formatDateTimeMillisecond,
  formatDateTimeSecond,
  toDate,
  trimDate0000
} from '@utils/dateutils'
import { formatNumber } from '@utils/numberutils'
import {
  asNumber,
  compareStrings,
  counter,
  isObject,
  isString,
  resolveObjectField,
  safeExecute,
  toNull,
  toString
} from '@utils/utils'
import {
  Column,
  ColumnType,
  DataTableOrderType,
  FilterColumn,
  FilterDef as GlobalFilterDef,
  OrderDirection,
  ValueGetter
} from './DataTable'

export const getFieldValue = (
  row: any,
  fieldName: string | string[] | null
): any | any[] | null => {
  if (row == null || fieldName == null) {
    return null
  }
  if (Array.isArray(fieldName)) {
    return fieldName.map((name) => getFieldValue(row, name))
  }
  if (fieldName.includes('.')) {
    return fieldName.split('.').reduce((obj, field) => (isObject(obj) ? obj[field] : null), row)
  }
  return row[fieldName]
}

export const formatValue = (val: any, type: ColumnType): string => {
  if (val == null) {
    switch (type) {
      case 'dateunlimited':
        return formatDate(null, true)
      default:
        return null
    }
  }
  if (type == null) {
    switch (typeof val) {
      case 'string':
        return val
      case 'boolean':
        return val ? 'ja' : 'nein'
      case 'number':
        return formatNumber(val, 2)
    }
    if (val instanceof Date) {
      return formatDateTime(val)
    }
    return toString(val)
  }
  switch (type) {
    case 'number':
      return formatNumber(val, 0)
    case 'money2':
      return formatNumber(val, 2)
    case 'money4':
      return formatNumber(val, 4)
    case 'money6':
      return formatNumber(val, 6)
    case 'date':
      return formatDate(val)
    case 'dateunlimited':
      return formatDate(val, true)
    case 'datetime':
      return formatDateTime(val)
    case 'datetimesecond':
      return formatDateTimeSecond(val)
    case 'datetimemillisec':
      return formatDateTimeMillisecond(val)
    case 'string':
      return isString(val) ? val : `${val}`
    case 'boolean':
      return val ? 'ja' : 'nein'
    default:
      console.error(`Unsupported column type=${type}`)
      return toString(val)
  }
}

/**
 * Comparator that sorts two given objects by the given property.
 *
 * @param {object} obj1 The left object
 * @param {object} obj2 The right object
 * @param {func} sortGetter Extrahiert das zu Value zum Vergleich
 * @return {number} The compare result (-1, 0, 1)
 */
export const descendingComparator = <T>(
  obj1: T,
  obj2: T,
  sortGetter: ValueGetter<T>,
  usage: 'search' | 'sort' = 'sort'
): number => {
  const value1 = sortGetter(obj1)
  const value2 = sortGetter(obj2)

  if (value1 === value2) {
    return 0
  }
  if (value1 == null) {
    return -1
  }
  if (value2 == null) {
    return +1
  }

  if (typeof value1 === 'string' || typeof value2 === 'string') {
    return compareStrings(value2, value1, usage)
  }

  if (value2 < value1) {
    return -1
  }
  if (value2 > value1) {
    return 1
  }
  return 0
}

/**
 * Get the matching comparator for the given direction and property.
 *
 * @param {string} orderDirection The sort direction
 * @param {func} sortGetter Function which returns the property to sort by
 * @return {func} A comparator function
 */
export const getValueComparator = <T>(
  orderDirection: OrderDirection,
  sortGetter: ValueGetter<T>
): ((a: T, b: T) => number) => {
  return orderDirection === 'desc'
    ? (a, b) => descendingComparator(a, b, sortGetter)
    : (a, b) => -descendingComparator(a, b, sortGetter)
}

export const getComparator = (orderBy: DataTableOrderType[], columns: Column[]) => {
  const ori = orderBy
    .map((oi) => ({ ...oi, col: columns.find((cc) => cc.key === oi.key) }))
    .filter((oi) => oi.col != null && oi.col.field != null && oi.dir)
    .map((oi) => getValueComparator(oi.dir, oi.col.sortGetter))
  return (a: any, b: any) => {
    for (let oin = 0; oin < ori.length; oin += 1) {
      const cmp = ori[oin]
      const rs = cmp(a, b)
      if (rs !== 0) {
        return rs
      }
    }
    return 0
  }
}

/**
 * Sort the given objects with the given comparator.
 *
 * @param array The array of objects to be sorted
 * @param  comparator The comparator function
 * @return The sorted object array
 */
export const stableSort = <T = any>(array: T[], comparator: (a: any, b: any) => number): T[] => {
  if (!array || !array.length) {
    return []
  }
  const stabilizedThis = array.map((el, index) => [el, index]) as [T, number]
  stabilizedThis.sort((a, b) => {
    const orderDirection = comparator(a[0], b[0])
    if (orderDirection !== 0) {
      return orderDirection
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const filterCollectionGlobal = <T>(
  rows: T[],
  filter: GlobalFilterDef,
  columns: Column[]
) => {
  if (filter == null || !(filter.text?.length > 0) || rows == null) {
    return rows || []
  }
  return rows.filter((row: any) => {
    const pos = columns
      .filter((col) => col.field != null)
      .findIndex((col) => {
        const fieldValue = col.valueGetter(row)
        if (fieldValue == null) {
          return false
        }
        if (Array.isArray(fieldValue)) {
          return (
            fieldValue
              .map(toString)
              .filter(Boolean)
              .map((s) => s.toLocaleUpperCase())
              .find((s) => s.includes(filter.text)) != null
          )
        }
        const cellVal = formatValue(fieldValue, col.type).toLocaleUpperCase()
        return cellVal.includes(filter.text)
      })
    return filter.mode === 'E' ? pos === -1 : pos !== -1
  })
}

export const filterCollection = <T>(
  rows: T[],
  columnFilter: FilterColumn<T>[],
  globalFilter: GlobalFilterDef,
  columns: Column<T>[],
  grouper: Column<T>
): T[] => {
  const cols = grouper ? [...columns, grouper] : columns
  let filtered = filterCollectionGlobal(rows, globalFilter, cols)
  if (filtered.length === 0 || columnFilter == null) {
    return filtered
  }

  columnFilter
    .filter((cf) => cf?.matches != null)
    .forEach((cf) => {
      const col = cols.find((col) => col.key === cf.key)
      filtered = filtered.filter((row) => cf.matches(row, col))
    })

  return filtered
}

const trimValue = (v: any) =>
  v == null || ((Array.isArray(v) || typeof v === 'string') && v.length === 0) ? null : v

export const matchesRowIndex = <T>(row: T, col: Column<T>, index: Map<any, boolean>): boolean => {
  if (index == null || index.size === 0) {
    return true
  }

  let inc = false
  for (let ie of index.values()) {
    if (ie) {
      inc = true
      break
    }
  }

  const value = trimValue(col.valueGetter(row))

  if (Array.isArray(value)) {
    let found = null
    for (let v of value) {
      const f = index.get(v) //trimValue(formatValue(v, col.type)))
      if (f === false) {
        return false
      }
      if (f) {
        found = f
      }
    }
    return found == null ? !inc : found
  }

  const found = index.get(value)
  return found == null ? !inc : found
}

export const matchesRowContains = <T>(row: T, col: Column<T>, value: any) => {
  if (value == null || value.length === 0) {
    return true
  }
  const rv = col.valueGetter(row)
  if (Array.isArray(rv)) {
    for (let v of rv) {
      const f = formatValue(v, col.type).toLocaleUpperCase().includes(value)
      if (f) {
        return true
      }
    }
    return false
  }
  return toString(rv).toLocaleUpperCase().includes(value)
}

const typeFix = (value: any, col: Column) => {
  switch (col.type) {
    case 'date':
      return trimDate0000(toDate(value))
    case 'number':
    case 'money2':
      return asNumber(value)
  }
  if (typeof value === 'string') {
    return value.toLocaleUpperCase()
  }
  return value
}

/**
 * Problem, das Formatter array mit formatieren Inhalten liefert, für Zahlen das aber nicht gewünscht ist wg. vergleich...
 *
 * @param row
 * @param col
 * @returns
 */
const getterFix = <T>(row: T, col: Column<T>) => {
  switch (col.type) {
    case 'date':
    case 'number':
    case 'money2':
    case 'boolean':
      // case 'datetime':
      // case 'datetimemillisec':
      // case 'dateunlimited':
      return col.valueGetter(row)
  }
  // insb. formartierte objects und "enums"
  const form = col.valueGetter(row)
  return form && toString(form).toLocaleUpperCase()
}

export const matchesRowExact = <T>(row: T, col: Column<T>, value: any) => {
  if (value == null || value.length === 0) {
    return true
  }
  const rv = getterFix(row, col)
  if (Array.isArray(rv)) {
    for (let v of rv) {
      if (typeFix(v, col) === value) {
        return true
      }
    }
    return false
  }
  return typeFix(rv, col) == value
}

export type RangeType<T = any> = {
  von: T
  bis: T
}

const checkRange = <T = any>(v: T, value: RangeType<T>) => {
  if (value.von != null && value.bis != null) {
    if (v >= value.von && v <= value.bis) {
      return true
    }
  } else {
    if (value.von != null && v >= value.von) {
      return true
    }
    if (value.bis != null && v <= value.bis) {
      return true
    }
  }
  return false
}

export const matchesRowRange = <T>(row: T, col: Column<T>, value: RangeType) => {
  if (value == null) {
    return true
  }
  const rv = col.valueGetter(row)
  if (Array.isArray(rv)) {
    for (let v of rv) {
      if (checkRange(typeFix(v, col), value)) {
        return true
      }
    }
    return false
  }
  return checkRange(typeFix(rv, col), value)
}

export const buildFilterIndex = <T>(
  rows: T[],
  colType: ColumnType,
  valueGetter: ValueGetter<T>
): [any, any][] => {
  const index =
    rows == null
      ? []
      : Array.from(
          rows
            .map((row) => {
              const val = valueGetter(row)
              if (Array.isArray(val) && val.length === 0) {
                return null
              }
              return val
            })
            .flatMap((val) => val)
            .reduce((map, val) => {
              // const v = typeof val === 'string' ? val : formatValue(val, colType)
              const v = toNull(val) // typeof val === 'number' ? val : formatValue(val, colType)
              const e = map.get(v)
              if (e == null) {
                map.set(v, 1)
              } else {
                map.set(v, e + 1)
              }
              return map
            }, new Map())
        ).sort((a, b) => b[1] - a[1] || compareStrings(a[0], b[0], 'sort'))
  return index
}

export const buildColumnKey = <T>(col: Column, idx: number): string => {
  let key = col.key ?? col.field
  if (key == null) {
    key = `col${idx}`
  } else if (Array.isArray(key)) {
    key = key.join('-')
  }
  if (key.startsWith('::')) {
    // reserviert...
    key = key.substring(2)
  }
  return key
}

export const amendCol = <T>(col: Column, idx: number): Column<T> => {
  const key = buildColumnKey(col, idx)

  const valueGetter = col.valueGetter
    ? (row: T) => safeExecute(() => col.valueGetter(row))
    : (row: T) => getFieldValue(row, col.field)

  const sortGetter =
    col.sortGetter ??
    ((row: T) => {
      const value = valueGetter(row)
      if (Array.isArray(value)) {
        return value.join(';') // TODO ggf als arry lassen?
      }
      return value
    })

  const exportGetter =
    col.exportGetter ??
    ((row: T) => {
      const value = valueGetter(row)
      if (Array.isArray(value)) {
        const exp = value.map((vv) => formatValue(vv, col.type))
        if (exp.length > 1) return exp.join(', ')
        else if (exp.length === 1) return exp[0]
        return null
      }
      const formatted = formatValue(value, col.type)
      return formatted
    })

  let align = col.align
  if (align == null) {
    switch (col.type) {
      case 'number':
      case 'money2':
      case 'money4':
      case 'money6':
        align = 'right'
        break
      default:
        align = 'left'
        break
    }
  }

  return { ...col, key, align, valueGetter, sortGetter, exportGetter }
}

const tableidcounter = counter(1, 1)
export const nextTableId = () => tableidcounter.next().value

export const computeLeftPos = <T extends { cells: any }>(
  row: T,
  colCount: number,
  stickyColumsLeft: number
  // selectCol: number
): number[] => {
  let left = 0
  const lefts = []
  const { cells } = row
  const maxCols = Math.min(cells.length, colCount, stickyColumsLeft) // + (selectCol ? 1 : 0))
  for (let ci = 0; ci < maxCols; ci += 1) {
    const cell = cells[ci]
    lefts.push(left)
    left += cell.offsetWidth
  }
  return lefts
}

export const adjustLeftPos = <T extends { cells: any }>(rows: T[], lefts: number[]) => {
  for (let ri = 0; ri < rows.length; ri += 1) {
    const { cells } = rows[ri]
    const maxCols = Math.min(cells.length, lefts.length)
    for (let ci = 0; ci < maxCols; ci += 1) {
      const cell = cells[ci]
      cell.style.left = `${lefts[ci]}px`
    }
  }
}

export const delayedSetFocus = (tr: HTMLTableRowElement, cellIndex: number, fieldType: string) => {
  tr.focus()
  const d2 = cellIndex >= 0 && cellIndex < tr.cells.length && tr.cells[cellIndex]
  if (d2) {
    const e = d2.getElementsByTagName(fieldType)
    if (e && e.length > 0) {
      ;(e[0] as HTMLInputElement).focus()
    }
  }
}

export const addOrRemove = (arr: any[], obj: any): any[] => {
  if (obj == null) {
    return arr
  }
  if (arr == null || arr.length === 0) {
    return [obj]
  }
  const idx = arr.indexOf(obj)
  if (idx === -1) {
    return [...arr, obj]
  }
  return [...arr].splice(idx, 0)
}

export const compareColumField = (
  field: string | string[],
  fieldFilter: string | string[] | RegExp
): boolean => {
  if (fieldFilter instanceof RegExp) {
    if (Array.isArray(field) && Array.isArray(fieldFilter)) {
      return field.find((f) => f.match(fieldFilter)) != null
    } else if (!Array.isArray(field) && !Array.isArray(fieldFilter)) {
      return field.match(fieldFilter) != null
    }
  } else if (Array.isArray(field) && Array.isArray(fieldFilter)) {
    return field.length === fieldFilter.length && field.every((f, i) => f === fieldFilter[i])
  } else if (!Array.isArray(field) && !Array.isArray(fieldFilter)) {
    return field === fieldFilter
  }
  return false
}

export const addColumnFieldWrapper = <T = any>(
  columns: Column<T>[],
  wrapperField: string = '',
  excludeByFields: (string | string[])[] = []
): Column<T>[] => {
  const unwrapRowFn = (fn) =>
    fn && typeof fn === 'function'
      ? (...args) => {
          // ersetzt die originale Column-Funktionen mit einer neuen, in der der 'row'-parameter per unwrapp vereinfacht wird
          const row = wrapperField ? resolveObjectField(args[0], wrapperField) : args[0]

          return fn(...[row, ...args.slice(1)])
        }
      : fn ?? null

  return columns.map((column) => {
    return {
      ...column,
      field: excludeByFields.find((field) => compareColumField(column.field, field))
        ? column.field
        : `${wrapperField}.${column.field}`,
      body: unwrapRowFn(column.body),
      valueGetter: unwrapRowFn(column.valueGetter),
      tooltip: unwrapRowFn(column.tooltip),
      decorator: unwrapRowFn(column.decorator)
    }
  })
}

export const excludeColumns = <T = any>(
  columns: Column<T>[],
  filterByField: (string | string[] | RegExp)[]
): Column<T>[] => {
  return columns.reduce((res, column) => {
    if (!filterByField.find((field) => compareColumField(column.field, field))) {
      res.push(column)
    }
    return res
  }, [])
}
