import {
  Add,
  BlurOn,
  ChangeHistory,
  Check,
  Clear,
  Grade,
  Link,
  LinkOff,
  NotInterested,
  QuestionMark,
  Remove
} from '@mui/icons-material'
import { Box } from '@mui/material'
import {
  amber,
  blue,
  brown,
  deepOrange,
  green,
  grey,
  lightGreen,
  lime,
  orange,
  red,
  yellow
} from '@mui/material/colors'
import { ArtikelAbonniertStatusEx } from '@one/components/ArtikelSelektion/model/ArtikelSelektionTypes'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelAbonniertStatus } from '@one/typings/apiTypings'
import { EnumTextGetter } from '@utils/enums'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { memo } from 'react'

const iconStyle = { fontSize: '100%' }

export const getAbonniertStatusIcon = (
  status: ArtikelAbonniertStatusEx | ArtikelAbonniertStatus
) => {
  switch (status) {
    case ArtikelAbonniertStatusEx.EINGESCHL:
      return <Link style={iconStyle} />
    case ArtikelAbonniertStatusEx.AUSGESCHL:
      return <Clear style={iconStyle} />
    case ArtikelAbonniertStatusEx.UNTERBROCHEN:
      return <LinkOff style={iconStyle} />
    case ArtikelAbonniertStatusEx.AUSGELISTET:
      return <NotInterested style={iconStyle} />
    case ArtikelAbonniertStatusEx.UNBESTIMMT:
      return <QuestionMark style={iconStyle} />
    case ArtikelAbonniertStatusEx.NEU:
      return <Add style={iconStyle} />
    case ArtikelAbonniertStatusEx.GEAENDERT:
      return <Grade style={iconStyle} />
    case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
      return <ChangeHistory style={iconStyle} />
    case ArtikelAbonniertStatusEx.BESTAETIGT:
      return <Check style={iconStyle} />
    case null:
    case undefined:
      return <Remove style={iconStyle} />
    default:
      return <BlurOn style={iconStyle} />
  }
}

export const getArtikelAbonniertStatusText = (
  et: EnumTextGetter,
  status: ArtikelAbonniertStatusEx | ArtikelAbonniertStatus,
  oldStatus?: ArtikelAbonniertStatusEx
) => {
  switch (status) {
    case ArtikelAbonniertStatusEx.EINGESCHL:
    case ArtikelAbonniertStatusEx.AUSGESCHL:
    case ArtikelAbonniertStatusEx.UNTERBROCHEN:
    case ArtikelAbonniertStatusEx.AUSGELISTET:
    case ArtikelAbonniertStatusEx.AUSLISTUNG:
    case ArtikelAbonniertStatusEx.UNBESTIMMT:
    case ArtikelAbonniertStatusEx.NEU:
    case ArtikelAbonniertStatusEx.GEAENDERT:
    case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
      return et(HkmEnum.ArtikelAbonniertStatusEx, status)

    case ArtikelAbonniertStatusEx.BESTAETIGT: {
      const text = et(HkmEnum.ArtikelAbonniertStatusEx, status)
      if (oldStatus !== status) {
        switch (oldStatus) {
          case ArtikelAbonniertStatusEx.NEU:
          case ArtikelAbonniertStatusEx.AUSLISTUNG:
          case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
          case ArtikelAbonniertStatusEx.GEAENDERT:
            return `${et(HkmEnum.ArtikelAbonniertStatusEx, oldStatus)} - ${text}`
        }
      }
      return text
    }
    case undefined:
    case null:
      return 'Kein Abo'
    default:
  }
}

export const ArtikelAbonniertStatusRGB = {
  UNBESTIMMT: yellow,
  NEU: amber,
  AUSGESCHL: blue,
  EINGESCHL: green,
  BESTAETIGT: lightGreen,
  UNTERBROCHEN: orange,
  AUSGELISTET: brown,
  AUSLISTUNG: deepOrange,
  GEAENDERT: lime,
  KONFLIKT: red,
  UNKNOWN: grey
}

export const getArtikelAbonniertStatusRGB = (
  status: ArtikelAbonniertStatusEx | ArtikelAbonniertStatus
) => {
  switch (status) {
    case ArtikelAbonniertStatusEx.UNBESTIMMT:
      return ArtikelAbonniertStatusRGB.UNBESTIMMT
    case ArtikelAbonniertStatusEx.AUSGESCHL:
      return ArtikelAbonniertStatusRGB.AUSGESCHL
    case ArtikelAbonniertStatusEx.EINGESCHL:
      return ArtikelAbonniertStatusRGB.EINGESCHL
    case ArtikelAbonniertStatusEx.BESTAETIGT:
      return ArtikelAbonniertStatusRGB.BESTAETIGT
    case ArtikelAbonniertStatusEx.UNTERBROCHEN:
      return ArtikelAbonniertStatusRGB.UNTERBROCHEN
    case ArtikelAbonniertStatusEx.AUSLISTUNG:
      return ArtikelAbonniertStatusRGB.AUSLISTUNG
    case ArtikelAbonniertStatusEx.AUSGELISTET:
      return ArtikelAbonniertStatusRGB.AUSGELISTET
    case ArtikelAbonniertStatusEx.GEAENDERT:
      return ArtikelAbonniertStatusRGB.GEAENDERT
    case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
      return ArtikelAbonniertStatusRGB.KONFLIKT
    case ArtikelAbonniertStatusEx.NEU:
      return ArtikelAbonniertStatusRGB.NEU
    default:
      return ArtikelAbonniertStatusRGB.UNKNOWN
  }
}

export const getArtikelAbonniertStatusColor = (
  status: ArtikelAbonniertStatusEx | ArtikelAbonniertStatus,
  darkMode: boolean
) => getArtikelAbonniertStatusRGB(status)[darkMode ? 800 : 500]

export type ArtikelAbonniertStatusSymbolProps = {
  value: ArtikelAbonniertStatusEx | ArtikelAbonniertStatus
  oldValue?: ArtikelAbonniertStatusEx
  et: EnumTextGetter
}

export const ArtikelAbonniertStatusSymbol = memo(
  ({ value, oldValue, et }: ArtikelAbonniertStatusSymbolProps) => (
    <TooltipEx title={getArtikelAbonniertStatusText(et, value, oldValue)}>
      <Box data-name={value}>{getAbonniertStatusIcon(value)}</Box>
    </TooltipEx>
  )
)
ArtikelAbonniertStatusSymbol.displayName = 'ArtikelAbonniertStatusSymbol'
