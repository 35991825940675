import { HkmEnum } from '@one/enums/HkmEnum'
import {
  EkPreisListeImportDefinitionJson,
  EkPreisListeImportDefinitionStepJson,
  LabelJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useCallback, useMemo } from 'react'
import { EkPReisImportKonfigurationsDialog } from './EkPReisImportKonfigurationsDialog'

export interface EkPreisImportKonfigurationTableProps {
  model: EkPreisListeImportDefinitionJson
  updModel: (
    fn: (prevModel: EkPreisListeImportDefinitionJson) => EkPreisListeImportDefinitionJson
  ) => void
  title?: string
  backLink?: boolean
  labels: LabelJson[]
}

export const EkPreisImportKonfigurationTable = ({
  title,
  backLink,
  model,
  labels,
  updModel
}: EkPreisImportKonfigurationTableProps) => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const { et } = useEnums()

  const columns = useMemo<Column<EkPreisListeImportDefinitionStepJson>[]>(
    () => [
      {
        field: 'stepId',
        header: 'Schritt',
        sortable: false,
        valueGetter: (row) => et(HkmEnum.EkPreisListImportStepId, row.stepId)
      },
      {
        field: 'name',
        header: 'Name',
        sortable: false
      },
      {
        field: 'status',
        header: 'Aktiv',
        sortable: false,
        valueGetter: (row) => et(HkmEnum.EkPreisListeImportDefinitionStepStatus, row.status)
      },
      {
        field: 'freigabe',
        header: 'Freigabe',
        sortable: false,
        valueGetter: (row) =>
          et(HkmEnum.EkPreisListeImportDefinitionStepSchrittfreigabe, row.freigabe)
      }
    ],
    [et]
  )

  const onComplete = useCallback(
    (nextStep: EkPreisListeImportDefinitionStepJson) => {
      if (!nextStep) {
        return
      }
      updModel((prevModel) => {
        const nextSteps = [...prevModel.steps]
        const changeIndex = nextSteps.findIndex((step) => step.stepId === nextStep.stepId)
        nextSteps[changeIndex] = { ...nextSteps[changeIndex], ...nextStep }

        return { ...prevModel, steps: nextSteps }
      })
    },
    [updModel]
  )

  const openEditDialog = useCallback(
    (step) => {
      showDlg((visible, onClose) => (
        <EkPReisImportKonfigurationsDialog
          labels={labels}
          open={visible}
          onClose={onClose(onComplete)}
          step={step}
        />
      ))
    },
    [onComplete, showDlg, labels]
  )

  const actions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        direct: true,
        handler: openEditDialog
      }
    ],
    [openEditDialog]
  )

  return (
    <>
      <DataTableCard
        name="EK-Preis Importkonfiguration"
        columns={columns}
        actions={actions}
        value={model?.steps ?? []}
        // initialOrderBy="order"
        onRowDoubleClick={openEditDialog}
        dense
        title={title}
        backLink={backLink}
      />
      <DlgAnker />
    </>
  )
}
