import { Button } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { LieferantSearchDialog } from '@one/components/Lieferant/LieferantSearchDialog'
import { LieferantenListeTyp } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useStateEx } from '@utils/stateex'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayMergeUnique } from '@utils/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface LieferantenListeTableProps {
  model: any
  updModel: (nextModel: any) => void
}

export const LieferantenListeTable = ({ model, updModel }: LieferantenListeTableProps) => {
  const { askToRemove } = useMessageDialog()
  const [DlgAnker, showDlg] = useDialogAnker()
  const [isReadonly, setIsReadonly] = useState(false)
  const [selected, setSelected, getSelected] = useStateEx(new Set())

  useEffect(() => {
    setIsReadonly(model.typ === LieferantenListeTyp.ARTVD)
  }, [model])

  const onComplete = useCallback(
    (data) => {
      if (data) {
        updModel((old) => {
          const lieferanten = arrayMergeUnique(old.lieferanten.filter(Boolean), data, 'id')
          return {
            ...old,
            lieferanten
          }
        })
      }
    },
    [updModel]
  )

  const onMultiRemoveSelection = useCallback(
    (lieferantenList) => () => {
      updModel((oldModel) => {
        const lieferanten = oldModel.lieferanten.filter((item) => {
          return !lieferantenList.has(item)
        })
        return { ...oldModel, lieferanten }
      })
    },
    [updModel]
  )

  const removeSelektion = useCallback(
    (data) => () => {
      if (data) {
        onMultiRemoveSelection(new Set([data]))()
      }
    },
    [onMultiRemoveSelection]
  )

  const removeAfterCheck = useCallback(() => {
    askToRemove({
      title: `Selektion mit ${getSelected().size} Lieferanten entfernen`,
      onConfirm: onMultiRemoveSelection(getSelected())
    })
  }, [onMultiRemoveSelection, getSelected, askToRemove])

  const onNewLieferantenListe = useCallback(() => {
    showDlg((visible, onClose) => (
      <LieferantSearchDialog
        open={visible}
        onClose={onClose(onComplete)}
        multiselect
        disableZentral
        disableDezentral
        defaultDezentral
      />
    ))
  }, [onComplete, showDlg])

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'name1',
        header: 'Lieferantenname 1',
        valueGetter: (row) => {
          return row.notanlage ? 'Notlieferant' : row.name1
        }
      },
      {
        field: 'name2',
        header: 'Lieferantenname 2'
      },
      {
        field: 'anzahlGelistet',
        header: 'Gelistete Artikel',
        sortable: true,
        type: 'number'
      }
    ],
    []
  )

  const tableActions = useMemo(() => {
    const basicAction = [
      {
        icon: 'local_shipping',
        tooltip: 'Lieferant öffnen',
        getLink: (data) => AppPaths.LieferantFn(data.id)
      }
    ]
    return isReadonly
      ? basicAction
      : [
          ...basicAction,
          {
            icon: 'delete',
            handler: (data) => () => {
              askToRemove({
                title: `Selektion "${data.name1}" entfernen`,
                onConfirm: removeSelektion(data)
              })
            }
          }
        ]
  }, [askToRemove, removeSelektion, isReadonly])

  const actions = useMemo(
    () =>
      isReadonly
        ? []
        : [
            {
              role: UserRoles.STAMMDATEN_EDITOR,
              tooltip: 'Lieferanten hinzufügen',
              text: 'Lieferanten',
              icon: 'add',
              onClick: onNewLieferantenListe
            }
          ],
    [onNewLieferantenListe, isReadonly]
  )

  const bottomActions = useMemo(
    () => [
      <Button
        disabled={selected.size === 0}
        onClick={removeAfterCheck}
        variant="contained"
        color="error"
        key="removeLieferant"
      >
        Lieferanten entfernen
      </Button>
    ],
    [removeAfterCheck, selected]
  )

  return (
    <>
      <DataTableCard
        name="LieferantenListeTable"
        title="Lieferanten"
        topActions={actions}
        columns={columns}
        value={model?.lieferanten}
        actions={tableActions}
        bottomActions={bottomActions}
        selectMode="multi"
        selected={selected}
        onSelect={setSelected}
        dense
        localStateName="LieferantenListeTable"
      />
      <DlgAnker />
    </>
  )
}
