import { green, red } from '@mui/material/colors'
import { Axios, AxiosResponse } from 'axios'
import clsx from 'clsx'
import { DragEvent, ReactNode, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useUploader } from './Uploader'
import { useSnackbarEx } from './snackbarex'
import { checkFilesAccepted, getFilesFromDragEvent } from './uiutils'

export type DropAreaProps = {
  id?: string
  children: ReactNode
  disabled?: boolean
  api: Axios
  path: string
  accept?: string
  multiple?: boolean
  onComplete?: (response: AxiosResponse) => void
  quiet?: boolean
  label?: string
}

const useStyles = makeStyles()({
  container: {
    position: 'relative'
  },
  plane0: {
    position: 'absolute',
    zIndex: 5002,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none'
  },
  plane1: {
    position: 'absolute',
    // border: `4px solid ${red[50]}`,
    zIndex: 5001,
    opacity: 0.4,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none'
  },
  plane2: {
    position: 'absolute',
    zIndex: 5000,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none'
  },
  msg: {
    position: 'absolute',
    zIndex: 5001,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '200%'
  },
  disabled: {
    background: `repeating-linear-gradient( 45deg, ${red[200]}, ${red[200]} 10px, ${red[600]} 10px, ${red[600]} 20px  )`
  },
  dragover: {
    background: `repeating-linear-gradient( 45deg, ${green[200]}, ${green[200]} 20px, ${green[600]} 20px, ${green[600]} 40px  )`
  },
  show: {
    display: 'block'
  },
  showFlex: {
    display: 'flex'
  }
})

export const DropArea = ({
  id,
  children,
  disabled,
  api,
  path,
  accept,
  multiple,
  onComplete,
  quiet,
  label
}: DropAreaProps) => {
  const { classes } = useStyles()

  const submitFiles = useUploader({ api, path, onComplete })
  const { enqueMsg } = useSnackbarEx()
  const [dragOver, setDragOver] = useState(false)

  const handleDrop = (e: DragEvent) => {
    e.preventDefault()
    setDragOver(false)
    const files = getFilesFromDragEvent(e)
    if (files.length > 1 && !multiple) {
      enqueMsg('Nur eine Datei zur zeit erlaubt!', 'error')
    } else if ((files.length > 1 && !multiple) || !checkFilesAccepted(files, accept)) {
      enqueMsg('Enthaltene Dateitypen nicht erlaubt', 'error')
    } else {
      submitFiles(files)
    }
  }

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (disabled) {
      e.dataTransfer.dropEffect = 'none'
    }
  }

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(true)
  }

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)
  }

  // TLP-3629 Abgebrochenes D&D ggf hier korrigieren
  useEffect(() => {
    if (dragOver) {
      const mousemove = () => {
        if (dragOver) {
          setDragOver(false)
        }
      }
      window.document.addEventListener('mousemove', mousemove, true)
      return () => {
        window.document.removeEventListener('keydown', mousemove)
      }
    }
    return () => {}
  }, [dragOver])

  return (
    <span
      id={id}
      className={classes.container}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
    >
      <div
        className={clsx(classes.plane0, dragOver && classes.show)}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnter={handleDragEnter}
      />
      <div
        className={clsx(
          classes.plane1,
          dragOver && classes.show,
          dragOver && (disabled ? classes.disabled : classes.dragover)
        )}
      />
      <div className={clsx(classes.plane2, classes.msg, dragOver && classes.showFlex)}>
        {disabled ? 'Upload nicht erlaubt' : label || 'Datei zum Upload ablegen'}
      </div>
      {children}
    </span>
  )
}
