import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelEANJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'

export interface ArtikelEanTableProps {
  eans?: ArtikelEANJson[]
}

export const ArtikelEanTable = ({ eans }: ArtikelEanTableProps) => {
  const { et } = useEnums()

  const columns: Column<ArtikelEANJson>[] = [
    {
      field: 'ean',
      header: 'EAN',
      type: 'string',
      sortable: true
    },
    {
      field: 'hauptEAN',
      header: 'Haupt EAN?',
      type: 'boolean',
      sortable: true
    },
    {
      field: 'geloescht',
      header: 'gelöscht?',
      type: 'boolean',
      sortable: true
    },
    {
      field: 'eanGruppe',
      header: 'EAN Gruppe',
      sortable: true,
      valueGetter: (ean) =>
        ean.eanGruppe && ean.eanGruppe?.name + ' - ' + ean.eanGruppe?.bezeichnung
    },
    {
      field: 'eanZuordnung',
      header: 'EAN Zuordnung',
      sortable: true,
      valueGetter: (ean) => et(HkmEnum.EanZuordnung, ean.eanZuordnung)
    }
  ]

  return (
    <DataTableCard
      name="artikelEanTable"
      value={eans}
      title="Artikel EANs"
      columns={columns}
      noScrollPanel
      emptyMessage="Keine EAN vorhanden"
      dense
      rowFiller
    />
  )
}
