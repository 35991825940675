import { AppPaths } from '@one/AppPaths'
import { VerteilungsZieleField } from '@one/components/common/VerteilungsZielSel'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelVerteilungDefinitionDisplayJson } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface ArtikelVerteilerUebersichtTableProps {
  result: SearcherResultType<ArtikelVerteilungDefinitionDisplayJson>
  onDelete: (ArtikelVerteilungDefinitionDisplayJson: any) => void
}

export const ArtikelVerteilerUebersichtTable = ({
  result,
  onDelete
}: ArtikelVerteilerUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column<ArtikelVerteilungDefinitionDisplayJson>[]>(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: 'artikelListenCount',
        header: 'Anzahl der Artikellisten'
      },
      {
        field: 'preisVerteilungsEinstellung',
        header: 'Preisverteilungseinstellung',
        body: (row: any) => et(HkmEnum.PreisVerteilungEinstellung, row.preisVerteilungsEinstellung)
      },
      {
        field: 'verteilungsZiele',
        header: 'Verteilungsziele',
        body: (row: any) => <VerteilungsZieleField value={row.verteilungsZiele} />
      },
      {
        field: 'aktiv',
        header: 'Aktiviert',
        type: 'boolean'
      }
    ],
    [et]
  )

  // Button actions for each table row
  const tableActions = useMemo(
    (): DataTableAction<ArtikelVerteilungDefinitionDisplayJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (data) => AppPaths.artikelverteiler.ArtikelVerteilerPflegeFn(data.id)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: onDelete,
        role: UserRoles.STAMMDATEN_EDITOR
      }
    ],
    [onDelete]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Artikelverteiler hinzufügen',
        navlink: AppPaths.artikelverteiler.ArtikelVerteilerPflegeFn('neu'),
        icon: 'add'
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="ArtikelVerteilerUebersichtTable"
      title="Suchergebnis"
      filterMode="both"
      localStateName="ArtikelVerteilerUebersichtTable"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      dense
      initialOrderBy="name"
      result={result}
    />
  )
}
