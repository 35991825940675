import { api } from '@one/api'
import { BetriebstypJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<BetriebstypJson> = {
  api,
  // refreshEvents: EventNames.ARSST_LOAD,
  datakey: 'betriebstypen',
  rest: 'betriebstypen',
  method: 'GET',
  idField: 'bt',
  field: 'betriebstypen'
} as DataCacheConfig

export const useArtikelBetriebstypCache = () => useDataCache(config)
