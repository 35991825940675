import { CompositeAttributeChange } from '@one/typings/apiTypings'

export const isCompositeAttributeChangeEmpty = (composite: CompositeAttributeChange): boolean =>
  composite == null || (!(composite.composites?.length > 0) && !(composite.attributes?.length > 0))

export const swapAndCleanCompositeAttributeChange = (
  changes: CompositeAttributeChange | null
): CompositeAttributeChange | null => {
  const ctx = new Set<number>()

  const doit = (changes: CompositeAttributeChange | null) => {
    if (changes == null) {
      return null
    }

    if (changes.id != null) {
      ctx.add(changes.id)
    }

    const comps = changes.composites.map(doit).filter(Boolean)

    const attrs = changes.attributes
      ?.map((attr) => {
        if (attr.attributeId != null) {
          ctx.add(attr.attributeId)
        }
        return {
          ...attr,
          newString: attr.oldString,
          oldString: attr.newString
        }
      })
      .filter(Boolean)

    return {
      ...changes,
      attributes: attrs,
      composites: comps
    }
  }

  return doit(changes)
}

export const cleanUpCompositeAttributeChange = (
  changes: CompositeAttributeChange | null,
  zusatz: boolean
) => {
  if (changes == null) {
    return null
  }

  const attrs = changes.attributes?.filter((attr) => !!attr.zusatz === !!zusatz)
  const comps = changes.composites
    ?.map((comp) => cleanUpCompositeAttributeChange(comp, zusatz))
    .filter(Boolean)

  if (attrs?.length > 0 || comps?.length > 0)
    return {
      ...changes,
      attributes: attrs,
      composites: comps
    }
  return null
}
