import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { api } from '@one/api'
import { NumberCellEdit } from '@one/components/common/NumberCell'
import { RabattgruppeField } from '@one/components/EkPreis/EKPreisPflege/fields/RabattgruppeField'
import { RabattverwendungField } from '@one/components/EkPreis/EKPreisPflege/fields/RabattverwendungField'
import {
  EkKonditionenRabattgruppenViewJson,
  EkKonditionenWertJson,
  EkPreisListePreiseActionJson
} from '@one/typings/apiTypings'
import { useLocalState } from '@utils/localState'
import { useModelMgr } from '@utils/modelmgr'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { PerformButton } from '@utils/ui/Buttons/PerformButton'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { FormField } from '@utils/ui/fields/FormField'
import { Switch } from '@utils/ui/fields/Switch'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  EkPreisMassenPflegeModel,
  EkPreiseMasseBearbeitenEintragExJson
} from './model/EkPreisMassenPflegeModel'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  space: {
    marginRight: '42px'
  },
  paper: {
    width: 'fit-content'
  },
  table: {
    width: 'auto',
    '& th, & td': {
      padding: '4px 8px'
    },
    '& th:last, & td:last': {
      textAlign: 'right'
    }
  },
  headfree: {
    '& input': {
      marginTop: '1rem'
    }
  }
}))

export interface EkPreisMassenAenderDialogProps {
  kopf: EkPreisMassenPflegeModel
  visible?: boolean
  onClose?: (fn?: () => void) => () => void
  selected?: EkPreiseMasseBearbeitenEintragExJson[]
  onComplete?: (updated?: any) => void
  allianzModus: boolean
}

interface EkPreisListePreiseActionEdit extends EkPreisListePreiseActionJson {
  rabattgruppeWerte: EkKonditionenWertJson
}

export const EkPreisMassenAenderDialog = ({
  onClose,
  onComplete,
  kopf,
  visible = true,
  selected = [],
  allianzModus
}: EkPreisMassenAenderDialogProps) => {
  const { classes } = useStyles()

  const [allianzAllowZuschl, setAllianzAllowZuschl] = useLocalState('allianzAllowZuschl', false)

  const { mitListenPreis, homogenePreiseEbeneId, mitArtikelRabattgruppe } = useMemo(() => {
    const probe = selected.reduce(
      (acc, row) => {
        if (acc.lastId == null) {
          acc.lastId = row.preisebene?.id
          acc.cnt = 1
        } else if (acc.lastId === row.preisebene?.id) {
          acc.cnt += 1
        }
        return acc
      },
      { lastId: null, cnt: 0 }
    )
    const homogenePreiseEbeneId = probe.cnt === selected.length ? probe.lastId : null

    const mitListenPreis =
      selected.find((row) => {
        const hs =
          row.werte?.find((w) => w.hauptstaffel) || row.werte?.find((w) => w.staffelmenge === 0)
        return hs?.listenpreis != null
      }) != null

    const mitArtikelRabattgruppe = selected.find((row) => row.artikelRabattgruppe != null) != null

    const mitVerwendung = false

    return {
      mitListenPreis,
      homogenePreiseEbeneId,
      mitArtikelRabattgruppe,
      mitVerwendung
    }
  }, [selected])

  const { model, updModel, save, uiLock, onValueChange } = useModelMgr<
    EkPreisListePreiseActionJson,
    EkPreisListePreiseActionEdit
  >({
    id: 'neu',
    api,
    title: 'EK-Preis Massenänderung',
    rest: '/ekpreisliste/executeaction',
    restSave: 'preis',
    restCreate: null,
    init: (): EkPreisListePreiseActionEdit => ({
      abgeschlossen: false,
      rows: [{ staffelmenge: 0 }],
      listeId: kopf.id,
      preisEbeneId: kopf.preisEbeneId,
      eintraege: selected.map((s) => s.id),
      rabattgruppeWerte: null,
      rabattgruppeId: null,
      rabattVerwendung: null
    }),
    onSave: onClose(onComplete)
  })

  const onRabattgruppe = useCallback(
    (rabattgruppe: EkKonditionenRabattgruppenViewJson) => {
      updModel((old) => ({
        ...old,
        rabattgruppeId: rabattgruppe?.id
      }))
    },
    [updModel]
  )

  const updateRow = useCallback(
    (nameValue) => {
      updModel((old) => {
        const row0 = old.rows?.length > 0 ? old.rows[0] : { staffelmenge: 0 }
        return {
          ...old,
          rows: [{ ...row0, ...nameValue }]
        }
      })
    },
    [updModel]
  )

  const header = useMemo(
    () => (
      <Grid item container direction="row" spacing={4}>
        <Grid item className={classes.space} style={{ flexGrow: '1' }}>
          <Typography variant="h6">EK-Preise ändern</Typography>
        </Grid>
        {model.abgeschlossen !== undefined && (
          <Grid item>
            <Switch
              label="Abgeschlossen"
              size="small"
              checked={model.abgeschlossen}
              onChange={onValueChange}
              name="abgeschlossen"
            />
          </Grid>
        )}
      </Grid>
    ),
    [classes.space, model.abgeschlossen, onValueChange]
  )

  const row0 = model.rows?.length > 0 ? model.rows[0] : null

  const fields = kopf?.kondiRabattFields
    .filter((z) => allianzAllowZuschl || !allianzModus || z.typ !== 'abs')
    .map((z) => (
      <Grid item key={z.field}>
        <FormField key={z.field} label={z.kurz} className={classes.headfree}>
          <NumberCellEdit
            title={z.bez}
            model={row0}
            field={z.field}
            style={{ width: '6rem' }}
            allowNegative
            onChange={(_row, field, value) => {
              updateRow({ [field]: value })
            }}
            readonly={z.typ === 'proz' && (mitArtikelRabattgruppe || model.rabattgruppeId != null)}
          />
        </FormField>
      </Grid>
    ))

  return (
    <Dialog open={visible} onClose={onClose()} maxWidth={false}>
      <StatePlane uiLock={uiLock} noNav onClose={onClose()}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={6} lg={6}>
                <RabattgruppeField
                  label={
                    allianzModus ? 'Rabattgruppe der Preisebene' : 'Rabattgruppe des Standorts'
                  }
                  options={kopf.ekRabattgruppen}
                  value={model.rabattgruppeId}
                  allianzModus={allianzModus}
                  onChange={onRabattgruppe}
                  nurLeere={!mitListenPreis}
                  emptyTextOvr={mitArtikelRabattgruppe ? 'Wie Artikel/Keine' : 'Keine'}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <RabattverwendungField
                  label="RG-Verwendung"
                  value={model.rabattVerwendung}
                  name="rabattVerwendung"
                  onChange={onValueChange}
                  withText
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" direction="row" spacing={2} xs={12}>
              {/* Sinnvoll erst als Tabelle, backend anpassen wg. Staffelgruppe...
                <Grid item>
                <FormField label="Staffelmenge" className={classes.headfree}>
                  <NumberCellEdit
                    title="Staffelmenge"
                    model={row0}
                    field="staffelmenge"
                    style={{ width: '6rem' }}
                    onChange={(row, field, value) => {
                      updateRow({ [field]: value })
                    }}
                  />
                </FormField>
              </Grid> */}
              <Grid item>
                <FormField label="Sonderpreis" className={classes.headfree}>
                  <NumberCellEdit
                    title="Sonderpreis"
                    model={row0}
                    field="sonderpreis"
                    style={{ width: '6rem' }}
                    onChange={(row, field, value) => {
                      updateRow({ [field]: value })
                    }}
                  />
                </FormField>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" direction="row" spacing={2} xs={12}>
              {fields}
            </Grid>
            <Grid item container alignItems="center" direction="row" spacing={2} xs={12}>
              <Grid item md={6}>
                <RabattgruppeField
                  label="Rabattgruppe der Staffel"
                  options={kopf.ekRabattgruppen}
                  value={row0?.rabattgruppeId}
                  allianzModus={allianzModus}
                  onChange={(rg) => {
                    updateRow({ rabattgruppeId: rg?.id })
                  }}
                  nurLeere={!mitListenPreis}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonRow>
            {allianzModus && (
              <div style={{ flexGrow: 1 }}>
                <Checkbox
                  checked={allianzAllowZuschl}
                  label="Absolute Rabatte"
                  size="small"
                  paddingTop={0}
                  onChange={() => setAllianzAllowZuschl(!allianzAllowZuschl)}
                />
              </div>
            )}
            <span>
              <PerformButton
                label={`Für ${selected.length} Einträge anwenden`}
                onClickVoid={save}
                // disabled={!isChanged}
              />
            </span>
            <CancelButton onClick={onClose()} />
          </ButtonRow>
        </DialogActions>
      </StatePlane>
    </Dialog>
  )
}
