import { AppPaths } from '@one/AppPaths'
import { ArtikelListeDisplayJson } from '@one/typings/apiTypings'
import { Column } from '@utils/ui/DataTable/DataTable'
import { ArtikelDetailsTabelle } from './ArtikelDetailsTabelle'

const ManuelleArtikelListenColumns: Column[] = [
  {
    field: 'name',
    header: 'Name',
    sortable: true
  }
]

const ManuelleArtikelListenTableActions = [
  {
    icon: 'arrow_forward',
    tooltip: 'Artikelliste öffnen',
    getLink: (data: any) => AppPaths.artikelliste.ArtikelListePflegeFn(data.id)
  }
]

export interface ArtikelListenSectionProps {
  manuelleListen: ArtikelListeDisplayJson[]
}

export const ArtikelListenSection = ({ manuelleListen }: ArtikelListenSectionProps) => (
  <ArtikelDetailsTabelle
    name="ArtikelListenSection"
    values={manuelleListen}
    title="Manuelle Artikellisten"
    columns={ManuelleArtikelListenColumns}
    tableActions={ManuelleArtikelListenTableActions}
    normal
  />
)
