import { HkmEnum } from '@one/enums/HkmEnum'
import { PimArtikelLieferantEkPreisJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column, DataTable, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface ArtikelPimLieferantEkPreiseProps {
  ekPreise: PimArtikelLieferantEkPreisJson[]
  card?: boolean
}

export const ArtikelPimLieferantEkPreise = ({
  ekPreise,
  card
}: ArtikelPimLieferantEkPreiseProps) => {
  const { et } = useEnums()
  const columns = useMemo(
    () =>
      [
        {
          field: 'type',
          header: 'Typ',
          valueGetter: (row) => et(HkmEnum.PimArtikelLieferantEkType, row.type)
        },
        {
          field: 'gueltigAb',
          header: 'Gültig ab',
          type: 'date'
        },
        {
          field: 'gueltigBis',
          header: 'Gültig bis',
          type: 'date'
        },
        {
          field: 'waehrung.bez',
          header: 'Währung',
          tooltip: (row) => row.waehrung?.kurzBez
        },
        {
          field: 'preisBasis',
          header: 'Preis-Basis',
          type: 'number'
        },
        {
          field: 'preisMenge',
          header: 'Preis-Menge',
          type: 'number'
        },
        {
          field: 'preisMengeneinheit',
          header: 'Preismengeneinheit',
          valueGetter: (row) => row.preisMengeneinheit && `${row.preisMengeneinheit.name ?? ''}`
        },
        {
          field: 'preis',
          header: 'Preis',
          type: 'money2'
        },
        {
          field: 'rabattgruppe',
          header: 'Rabattgruppe'
        },
        {
          field: 'mengeFreiHausStaffel1',
          header: 'Menge Staffel 1',
          type: 'number'
        },
        {
          field: 'preisFreiHausStaffel1',
          header: 'Preis Staffel 1',
          type: 'money2'
        },
        {
          field: 'mengeFreiHausStaffel2',
          header: 'Menge Staffel 2',
          type: 'number'
        },
        {
          field: 'preisFreiHausStaffel2',
          header: 'Preis Staffel 2',
          type: 'money2'
        },
        {
          field: 'mengeFreiHausStaffel3',
          header: 'Menge Staffel 3',
          type: 'number'
        },
        {
          field: 'preisFreiHausStaffel3',
          header: 'Preis Staffel 3',
          type: 'money2'
        },
        {
          field: 'mengeFreiHausStaffel4',
          header: 'Menge Staffel 4',
          type: 'number'
        },
        {
          field: 'preisFreiHausStaffel4',
          header: 'Preis Staffel 4',
          type: 'money2'
        },
        {
          field: 'mengeFreiHausStaffel5',
          header: 'Menge Staffel 5',
          type: 'number'
        },
        {
          field: 'preisFreiHausStaffel5',
          header: 'Preis Staffel 5',
          type: 'money2'
        }
      ] as Column<PimArtikelLieferantEkPreisJson>[],
    [et]
  )

  const groupBy: RowGrouper<PimArtikelLieferantEkPreisJson> = {
    field: 'type',
    header: 'Typ',
    valueGetter: (row) => et(HkmEnum.PimArtikelLieferantEkType, row.type)
  }

  return card ? (
    <DataTableCard
      title="EK-Preise"
      name="PimLieferantPreisTabelle"
      columns={columns}
      value={ekPreise}
      dense
      emptyMessage="Keine Preise vorhanden"
      filterMode="both"
      groupBy={groupBy}
    />
  ) : (
    <DataTable
      name="PimLieferantPreisTabelle"
      columns={columns}
      value={ekPreise}
      dense
      emptyMessage="Keine Preise vorhanden"
    />
  )
}
