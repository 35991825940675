import { SparteDisplayJson, WarengruppeDisplayJson } from '@one/typings/apiTypings'

export type WarengruppeCellProps = {
  warengruppe?: WarengruppeDisplayJson | null
}

export const formatWarengruppe = (warengruppe: WarengruppeDisplayJson | null) =>
  warengruppe == null ? null : warengruppe.nummer + ' ' + warengruppe.name

export const WarengruppeNameCell = ({ warengruppe }: WarengruppeCellProps) => {
  if (warengruppe == null) {
    return null
  }
  return <span title={warengruppe.nummer}>{warengruppe.name}</span>
}

export const WarengruppeNrCell = ({ warengruppe }: WarengruppeCellProps) => {
  if (warengruppe == null) {
    return null
  }
  return <span title={warengruppe.name}>{warengruppe.nummer}</span>
}

export const formatSparte = (sparte: SparteDisplayJson | null) =>
  sparte == null ? null : sparte.nr + ' ' + sparte.bezeichnung
