import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { ImportStatusCell } from '@one/components/EkPreis/EkPreisImportPflege/fields/ImportStatusCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import { EkPreisListeImportDisplayJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { UploadButton } from '@utils/ui/Buttons/UploadButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

export interface EkPreisImportUebersichtTableProps {
  result: SearcherResultType<EkPreisListeImportDisplayJson>
  onUpload: (response: AxiosResponse) => void
}

export const EkPreisImportUebersichtTable = ({
  result,
  onUpload
}: EkPreisImportUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column<EkPreisListeImportDisplayJson>[]>(
    () => [
      {
        field: 'erstelltAm',
        header: 'Hochgeladen am',
        type: 'datetime'
      },
      {
        field: 'dateiname',
        header: 'Dateiname'
      },
      {
        field: 'status',
        header: 'Status',
        align: 'center',
        width: '1em',
        valueGetter: (row) => et(HkmEnum.EkPreisListeImportStatus, row.status),
        body: (row) => (
          <ImportStatusCell
            status={row.status}
            text={et(HkmEnum.EkPreisListeImportStatus, row.status)}
          />
        )
      },
      {
        field: 'anzahlZeilen',
        header: 'Anzahl Zeilen',
        type: 'number'
      },
      {
        field: ['lieferant.name1', 'lieferant.ort', 'lieferant.nummer'],
        header: 'Lieferant',
        valueGetter: (row) => formatLieferant(row.lieferant),
        body: (row: any) => <LieferantCell lieferant={row.lieferant} asLink />,
        sortable: true
      },
      {
        field: 'gueltigAb',
        header: 'Gültig ab',
        type: 'date'
      },
      {
        field: 'anzahlFehler',
        header: 'Anzahl Fehler',
        type: 'number'
      },
      {
        field: 'anzahlIgnoriert',
        header: 'Anzahl Ignoriert',
        type: 'number'
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    (): DataTableAction<EkPreisListeImportDisplayJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (row) => {
          return AppPaths.EkPreisImportPflegeFn(row.id)
        }
      }
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Einkaufspreisliste (CSV/XLSX) hochladen',
        component: (
          <UploadButton
            api={api}
            path="ekpreislisteimport/upload"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onComplete={onUpload}
          />
        )
      }
    ],
    [onUpload]
  )

  return (
    <DataTableCard
      name="EkPreisImportUebersichtTable"
      title="Preislisten-Importe"
      filterMode="both"
      localStateName="EkPreisUebersichtTable"
      columns={columns}
      topActions={actions}
      actions={tableActions}
      dense
      initialOrderBy={[{ key: 'erstelltAm', dir: 'desc' }]}
      result={result}
    />
  )
}
