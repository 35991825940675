import { AppPaths } from '@one/AppPaths'
import { ArtikelSelektionCreateDialog } from '@one/components/ArtikelSelektion/ArtikelSelektionCreateDialog'
import { useArSSTCache } from '@one/datacaches/useArSSTCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelAboJson,
  ArtikelSelektionJson,
  ArtikelSelektionType,
  DzMapperEinstellungTyp
} from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useEnums } from '@utils/enums'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { Link } from '@utils/ui/Navigation/Link'
import { nameOf } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export interface ArtikelAboSelektionTableProps {
  model: ArtikelAboJson
  removeSelektion: (data: any) => void
  addSelektion: (data: any) => void
  errors?: any
}

export const ArtikelAboSelektionTable = ({
  model = {},
  errors,
  removeSelektion,
  addSelektion
}: ArtikelAboSelektionTableProps) => {
  const { askToDelete } = useMessageDialog()
  const { et } = useEnums()
  const { isAllianz } = useContext(AppContext)
  const arrstCache = useArSSTCache()
  const [DlgAnker, showDlg] = useDialogAnker()

  const navigate = useNavigate()

  const onComplete = useCallback(
    (data: ArtikelSelektionJson) => {
      addSelektion(data)
      navigate(AppPaths.ArtikelAboSelektionPflegeFn(data.id))
    },
    [addSelektion, navigate]
  )

  const onNew = useCallback(
    (typ) => {
      showDlg((visible, onClose) => (
        <ArtikelSelektionCreateDialog
          visible={visible}
          onClose={onClose}
          onComplete={onComplete}
          typ={typ}
          lieferant={model.lieferant}
          betriebsTyp={model.bt}
          aboId={model.id}
        />
      ))
    },
    [showDlg, onComplete, model.lieferant, model.bt, model.id]
  )

  const onNewAlle = useCallback(() => {
    onNew(ArtikelSelektionType.ALLE)
  }, [onNew])

  const onNewWarengruppe = useCallback(() => {
    onNew(ArtikelSelektionType.WARENGRUPPE)
  }, [onNew])

  const onNewArtikel = useCallback(() => {
    onNew(ArtikelSelektionType.ARTIKEL)
  }, [onNew])

  const columns = useMemo<Column<ArtikelSelektionJson>[]>(
    () => [
      {
        field: 'typ',
        header: 'Selektionstyp',
        body: (row: any) => et(HkmEnum.ArtikelSelektionType, row.typ)
      },
      {
        field: 'name',
        header: 'Name'
      },
      {
        field: 'arsstId',
        header: 'ArSST',
        headerTip: 'ArtikelStammdaten-Synchronisationstemplate',
        valueGetter: (row) => row.arsstId && arrstCache.get(row.arsstId)?.name,
        body: (row) =>
          row.arsstId && (
            <Link
              to={AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflegeFn(
                DzMapperEinstellungTyp.ARSST,
                row.arsstId
              )}
            >
              {arrstCache.get(row.arsstId)?.name}
            </Link>
          )
      },
      {
        field: nameOf<ArtikelSelektionJson>('aenderungenAutomatischUebernehmen'),
        header: 'Übernahme',
        headerTip: 'Änderungen automatisch übernehmen',
        type: 'boolean',
        align: 'center',
        body: (row) => (row.aenderungenAutomatischUebernehmen ? 'auto' : 'manuell')
      },
      {
        field: 'countEintragStatusEingeschl',
        header: 'Artikel abonniert',
        type: 'number'
      },
      {
        field: 'countEintragStatusUnbest',
        header: 'Zu entscheiden',
        type: 'number'
      },
      {
        field: 'countEintragGesperrt',
        header: 'gesperrt',
        type: 'number'
      },
      {
        field: 'countEintragStatusAusgeschl',
        header: 'Nicht abonniert',
        type: 'number'
      },
      {
        field: 'countEintragUnterbrochen',
        header: 'Unterbrochen',
        type: 'number'
      }
    ],
    [arrstCache, et]
  )

  const tableActions = useMemo(
    () => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (data) => AppPaths.ArtikelAboSelektionPflegeFn(data.id)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: (data) => () => {
          askToDelete({
            title: `Selektion ${data.id} löschen`,
            onConfirm: removeSelektion(data)
          })
        }
      }
    ],
    [askToDelete, removeSelektion]
  )

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Selektion für alle Lieferanternartikel hinzufügen',
          text: 'Alle',
          icon: 'add',
          onClick: onNewAlle
        },
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Selektion aus Warengrupppe hinzufügen',
          text: 'Warengruppe',
          icon: 'add',
          onClick: onNewWarengruppe,
          visible: !isAllianz
        },
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Selektion mit eigener Artikelliste hinzufügen',
          text: 'Artikelauswahl',
          icon: 'add',
          onClick: onNewArtikel,
          visible: !isAllianz
        }
      ] as Action[],
    [onNewAlle, onNewArtikel, onNewWarengruppe, isAllianz]
  )

  return (
    <>
      <DataTableCard
        title="Selektionen"
        topActions={actions}
        name="ArtikelAboSelektionTable"
        columns={columns}
        value={model.selektionen}
        actions={tableActions}
        dense
        filterMode="both"
      />
      <DlgAnker />
    </>
  )
}
