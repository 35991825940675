import { useMemo } from 'react'

import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { UmsatzsteuerJson } from '@one/typings/apiTypings'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'

export interface UmsatzsteuerTableProps {
  result: SearcherResultType<UmsatzsteuerJson>
  reload: () => void
  onDelete: (row: UmsatzsteuerJson) => () => void
}

export const UmsatzsteuerOverviewTable = ({
  result,
  onDelete
}: UmsatzsteuerTableProps) => {
  const [DlgAnker] = useDialogAnker()

  const columns = useMemo<Column<UmsatzsteuerJson>[]>(
    () => [

      {
        header: 'Steuersatz',
        field: 'steuersatz',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'Steuerkey',
        field: 'steuerkey',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'Datenmodell',
        field: 'datenmodell',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'gültig ab',
        field: 'gueltigAb',
        align: 'right',
        width: '1em',
        type: 'date',
        sortable: true
      },
      {
        header: 'Land',
        field: 'land',
        align: 'right',
        width: '1em',
        sortable: true
      }
    ],
    []
  )

  const topActions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.ADMIN,
        tooltip: 'Umsatzsteuer anlegen',
        icon: 'add',
        navlink: AppPaths.Umsatzsteuer.UmsatzsteuerFn('neu')
      }
    ],
    []
  )

  const tableActions = useMemo(
    (): DataTableAction<UmsatzsteuerJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Öffnen',
        getLink: (data: UmsatzsteuerJson) => AppPaths.Umsatzsteuer.UmsatzsteuerFn(data.id),
        role: UserRoles.ADMIN
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: onDelete,
        role: UserRoles.ADMIN
      }
    ], [onDelete]
  )
  return (
    <>
      <DataTableCard
        title="Suchergebnis"
        name="UmsatzsteuerTabelle"
        columns={columns}
        topActions={topActions}
        actions={tableActions}
        dense
        selectMode="none"
        rowFiller
        result={result}
      />
      <DlgAnker />
    </>
  )
}
