import { RemoveParams } from '@utils/modelmgr'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonProps } from './Button'

export interface DeleteButtonProps {
  isNew?: boolean
  disabled?: boolean
  visible?: boolean
  onDelete?: () => void
  deleteMsg?: string
  remove?: (args?: RemoveParams) => void
  backLink?: string
  tooltip?: string
  size?: ButtonProps['size']
  label?: string
  variant?: ButtonProps['variant']
  addVersion?: boolean
}

export const DeleteButton = ({
  isNew = false,
  disabled = false,
  onDelete,
  remove,
  backLink,
  tooltip,
  size,
  label = 'Löschen',
  visible = true,
  deleteMsg = 'Möchten Sie dieses Objekt wirklich löschen',
  variant = 'contained',
  addVersion
}: DeleteButtonProps) => {
  const { askToDelete } = useMessageDialog()
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    askToDelete({
      title: `${deleteMsg}?`,
      onConfirm:
        onDelete ??
        (() =>
          remove({
            onRemoved: () => {
              if (backLink) {
                navigate(backLink)
              } else {
                navigate(-1) // back
              }
            },
            addVersion
          }))
    })
  }, [askToDelete, deleteMsg, onDelete, remove, addVersion, navigate, backLink])

  return (
    <Button
      label={label}
      size={size}
      onClick={onClick}
      variant={variant}
      color="error"
      disabled={isNew || disabled}
      visible={visible}
      tooltip={tooltip}
    />
  )
}
