import { ArrowLeft } from '@mui/icons-material'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { useNavigate } from 'react-router-dom'

export const UnknownPage = () => {
  const navigate = useNavigate()
  return (
    <CardEx
      title="Unbekannte Seite"
      subheader="Die angeforderte Seite existiert nicht."
      // minWidth="30%"
      bottomActions={
        <Button label="Zur Startseite" onClick={() => navigate('/')} startIcon={<ArrowLeft />} />
      }
    />
  )
}
