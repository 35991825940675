/* eslint-disable react/jsx-props-no-spreading */
import { ifString, safeArray, trimStringToBlank } from '@utils/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AutocompleteBaseProps, AutocompleteEx } from './AutocompleteEx'

export interface AutocompleteAsyncProps extends AutocompleteBaseProps {
  queryMinChars?: number
  queryOptions?: any
  queryTimeout?: any
  defaultOptions?: any
  loading?: boolean
  loadResetToggle?: any
}

export const AutocompleteAsync = ({
  id,
  name,
  required,
  value,
  label,
  fieldCtrl,
  error,
  helperText,
  disabled,
  fullWidth,
  onChange,
  emptyText,
  renderOption,
  renderItem,
  optionLabel,
  optionValue,
  multiple,
  isOptionEqualToValue,
  actions,
  startAdornments,
  endAdornments,
  queryMinChars,
  queryOptions,
  queryTimeout,
  defaultOptions,
  groupBy,
  className,
  loading: l2,
  loadResetToggle
}: AutocompleteAsyncProps) => {
  const timerRef = useRef()

  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState([])
  const loadResetToggleRef = useRef(loadResetToggle)

  useEffect(() => {
    if (loadResetToggleRef.current !== loadResetToggle) {
      loadResetToggleRef.current = loadResetToggle
      setLoaded([])
    }
  }, [loadResetToggle])

  const options = useMemo(() => {
    if (defaultOptions != null) {
      return [...safeArray(defaultOptions), ...safeArray(loaded)]
    }
    return safeArray(loaded)
  }, [defaultOptions, loaded])

  const onSuccess = useCallback(
    (data) => {
      const arr = safeArray(data)
      setLoading(false)
      //@ts-ignore
      setLoaded(arr)
      if (arr.length === 1) {
        onChange({ name, value: arr[0] })
      }
    },
    [name, onChange]
  )

  const onError = useCallback(() => {
    setLoading(false)
    setLoaded([])
  }, [])

  const onInputChange = useCallback(
    (e, v, reason) => {
      if (reason !== 'input') {
        return
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      // if (value != null || (Array.isArray(value) && value.length !== 0)) {
      //   onChange({ name, value: multiple ? [] : null })
      //   return
      // }
      const query = trimStringToBlank(v)
      if (query.length >= (queryMinChars == null ? 1 : queryMinChars)) {
        // @ts-ignore
        timerRef.current = setTimeout(
          () => {
            setLoading(true)
            queryOptions({
              reason: 'query',
              query,
              onSuccess,
              onError
            })
          },
          queryTimeout > 0 ? queryTimeout : 800
        )
      }
    },
    [onSuccess, onError, queryMinChars, queryOptions, queryTimeout]
  )

  useEffect(() => {
    queryOptions({ onSuccess, onError, reason: 'init' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions])

  return (
    <AutocompleteEx
      id={id}
      name={name}
      value={value}
      label={label}
      fieldCtrl={fieldCtrl}
      error={!!error || fieldCtrl?.error}
      helperText={ifString(error) || fieldCtrl?.helperText || helperText}
      required={required || fieldCtrl?.required}
      disabled={disabled || fieldCtrl?.disabled}
      fullWidth={fullWidth}
      onChange={onChange}
      onInputChange={onInputChange}
      emptyText={emptyText}
      renderOption={renderOption}
      renderItem={renderItem}
      optionLabel={optionLabel}
      optionValue={optionValue}
      options={options}
      // filterOptions={filterOptions}
      loading={loading || l2}
      loadingText="Suche..."
      multiple={multiple}
      isOptionEqualToValue={isOptionEqualToValue}
      actions={actions}
      startAdornments={startAdornments}
      endAdornments={endAdornments}
      groupBy={groupBy}
      className={className}
    />
  )
}
