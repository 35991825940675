import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  StandortGruppeDisplayJson,
  StandortGruppeSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useCallback, useContext } from 'react'
import { StandortGruppeUbersichtKopf } from './StandortgruppeUebersichtKopf'
import { StandortGruppeUebersichtTable } from './StandortgruppeUebersichtTable'

export const StandortGruppeUbersichtView = () => {
  const { pushHistory } = useContext(AppSwitchCtx)
  const { criteria, result, search } = useSearcherState<
    StandortGruppeSearcherCriteriaJson,
    StandortGruppeDisplayJson
  >({
    api,
    url: 'standortgruppe'
  })

  useObserver<any>(EventNames.STANDORTGRUPPE, () => search())

  const onNewStandortGruppe = useCallback(() => {
    pushHistory(AppPaths.StandortgruppePflegeFn('neu'))
  }, [pushHistory])
  return (
    <Frame space>
      <FrameRow>
        <StandortGruppeUbersichtKopf
          value={criteria}
          onSearch={search}
          onChange={onchange}
        ></StandortGruppeUbersichtKopf>
      </FrameRow>
      <FrameBody>
        <StandortGruppeUebersichtTable result={result} onNewStandortGruppe={onNewStandortGruppe} />
      </FrameBody>
    </Frame>
  )
}
