import { Download, Refresh } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { api } from '@one/api'
import { LogFileJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { Action } from '@utils/ui/Action'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTableOrderType } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useDownloader } from '@utils/ui/downloader'
import { SelectField } from '@utils/ui/fields/SelectField'
import { useCallback, useEffect, useState } from 'react'

const initialOrderBy = [
  { key: 'ts', dir: 'desc' },
  { key: 'tid', dir: 'asc' }
] as DataTableOrderType[]

export const LogView = () => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [download] = useDownloader()

  const [data, setData] = useState([])
  const [emptyMsg, setEmptyMsg] = useState(null)
  const [logFiles, setLogFiles] = useState([])
  const [state, onChange] = useFormState({ logDatei: null })

  const refresh = useCallback(() => {
    if (state.logDatei) {
      apiCall({
        method: 'GET',
        rest: 'admin/log',
        params: { file: state.logDatei },
        onSuccess: (data: LogFileJson) => {
          if (data.lines?.length == null) {
            setData([])
            setEmptyMsg('Das Log scheint zu groß zu sein und kann nicht angezeigt werden!')
          } else {
            setData(data.lines)
            setEmptyMsg(null)
          }
        }
      })
    }
  }, [apiCall, state.logDatei])

  useEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    // refresh()
    apiCall({
      method: 'GET',
      rest: 'admin/logFiles',
      onSuccess: (data: string[]) => {
        setLogFiles(data)
        onChange({ name: 'logDatei', value: data.length > 0 ? data[0] : null })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      width: '20px',
      header: 'Zeitpunkt',
      field: 'ts',
      type: 'datetimemillisec',
      valign: 'top'
    },
    {
      width: '20px',
      header: 'TID',
      headerTip: 'Thread ID',
      field: 'tid',
      type: 'number',
      valign: 'top'
    },
    {
      width: '20px',
      header: 'GLN',
      field: 'gln',
      valign: 'top'
    },
    {
      width: '20px',
      header: 'Level',
      field: 'level',
      valign: 'top'
    },
    {
      header: 'Meldung',
      field: 'msg',
      body: (row) => <pre style={{ margin: 0 }}>{row.msg}</pre>
    }
  ] as Column[]

  const actions = [
    {
      icon: <Download />,
      tooltip: 'Logdatei herunterladen',
      onClick: () => download(`rest/admin/logFile?file=${state.logDatei}`),
      enabled: state.logDatei != null
    },
    {
      icon: <Refresh />,
      tooltip: 'Aktualisieren',
      onClick: () => refresh()
    }
  ] as Action[]

  return (
    <Frame space>
      <FrameRow>
        <CardEx title="Log Viewer" backLink topActions={actions}>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <SelectField
              required
              label="Logdatei"
              options={logFiles}
              optionValue={(opt) => opt}
              optionText={(opt) => opt}
              name="logDatei"
              value={state.logDatei}
              onChange={onChange}
              disabled={apiBusy}
              fullWidth
            />
          </Grid>
        </CardEx>
      </FrameRow>
      <FrameBody>
        <DataTableCard
          name="LogView"
          title="Log Meldungen"
          emptyMessage={emptyMsg}
          filterMode="both"
          paging
          dense
          loading={apiBusy}
          columns={columns}
          value={data}
          initialOrderBy={initialOrderBy}
          localStateName="LogView"
        />
      </FrameBody>
    </Frame>
  )
}
