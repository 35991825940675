import { QuestionMark } from '@mui/icons-material'
import {
  Box,
  Icon as MuiIcon,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  TooltipProps
} from '@mui/material'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import React, { ComponentType, forwardRef, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'

export type IconButtonProps = {
  icon?: ReactNode
  Icon?: ComponentType<any>
  title?: any
  tooltip?: TooltipProps['title']
  disabled?: boolean
  component?: any
  onClick?: MuiIconButtonProps['onClick']
  onClickVoid?: () => void
  style?: any
  size?: 'tiny' | 'small' | 'medium' | 'large'
  className?: any
  color?: MuiIconButtonProps['color']
  tabIndex?: any
  to?: any
  visible?: boolean
  name?: string
  tooltipPlacement?: TooltipProps['placement']
}

const tinyStyle = { fontSize: '1rem' }

export const IconButton = forwardRef(
  (
    {
      icon,
      Icon,
      title,
      disabled,
      component,
      onClick,
      onClickVoid,
      style,
      size,
      className,
      color,
      tabIndex,
      to,
      visible = true,
      tooltip,
      tooltipPlacement,
      name
    }: IconButtonProps,
    ref
  ) => {
    if (!visible) {
      return null
    }

    const is = size === 'tiny' ? 'small' : size
    const eis = size === 'tiny' ? tinyStyle : null

    const ico = (Icon && <Icon fontSize={is} style={eis} />) ||
      (React.isValidElement(icon) &&
        React.cloneElement(icon as ReactElement, { fontSize: is, style: eis })) ||
      (typeof icon === 'string' && (
        <MuiIcon fontSize={is} style={eis}>
          {icon}
        </MuiIcon>
      )) || <QuestionMark fontSize={is} />

    let button = (
      <MuiIconButton
        ref={ref}
        tabIndex={tabIndex}
        onClick={onClick || (onClickVoid && (() => onClickVoid()))}
        color={color}
        title={title}
        disabled={disabled}
        style={style}
        className={className}
        size={is}
        component={component}
        // to={to}
        data-name={name}
      >
        {ico}
      </MuiIconButton>
    )

    if (to) {
      button = <Link to={to}>{button}</Link>
    }

    return tooltip ? (
      <TooltipEx title={tooltip} placement={tooltipPlacement} arrow>
        <Box>{button}</Box>
      </TooltipEx>
    ) : (
      button
    )
  }
)

IconButton.displayName = 'IconButton'
