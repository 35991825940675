import { FormControlLabel, Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material'
import { marginMinus12 } from '@utils/ui/styles'
import { stopPropagation } from '@utils/ui/uiutils'
import React from 'react'

export interface RadioProps {
  label?: any
  name?: string
  value?: any
  checked?: boolean
  onChange?: (event: React.ChangeEvent<any>, checked: boolean) => void
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  style?: any
  disabled?: boolean
  compact?: boolean
  size?: MuiRadioProps['size']
  icon?: MuiRadioProps['icon']
  checkedIcon?: MuiRadioProps['checkedIcon']
  sx?: MuiRadioProps['sx']
}

export const Radio = ({
  label = null,
  name = null,
  value = null,
  checked = null,
  onChange = null,
  onClick = null,
  disabled = false,
  style = null,
  compact = false,
  size,
  icon,
  checkedIcon,
  sx
}: RadioProps) => {
  if (label == null) {
    return (
      <MuiRadio
        name={name}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        size={size}
        sx={sx}
        icon={icon}
        checkedIcon={checkedIcon}
        style={compact ? (style && { ...style, ...marginMinus12 }) || marginMinus12 : null}
        onKeyDown={
          disabled
            ? null
            : (e) => {
                if (value !== true) {
                  if (e.code === 'Space' || e.code === 'Enter') {
                    stopPropagation(e)
                    onChange(e, true)
                  }
                }
              }
        }
      />
    )
  }

  return (
    <FormControlLabel
      value={value}
      control={<MuiRadio />}
      name={name}
      label={label}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      style={compact ? (style && { ...style, ...marginMinus12 }) || marginMinus12 : null}
    />
  )
}
