import { SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'

export type StringArrayFieldProps = {
  name: string
  label: string
  value: string[]
  onChange: ValueChangeFn<SeArtikelJson>
  fullWidth?: boolean,
  readonly?: boolean
}

export const StringArrayField = ({
  label,
  value,
  name,
  fullWidth,
  onChange,
  readonly
}: StringArrayFieldProps) => {
  return (
    <AutocompleteEx
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      fullWidth={fullWidth}
      chipType="square"
      multiple
      freeSolo
      optionLabel={(option) => option}
      disabled={readonly}
    />
  )
}
