import { Grid } from '@mui/material'
import { ImportErrorJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ImportErrorProps {
  error: ImportErrorJson
}

export const ImportError = ({ error }: ImportErrorProps) => {
  return (
    <CardEx title="Importfehler">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StaticField label="Meldung" value={error.errorMsg} empty />
        </Grid>
        <Grid item xs={12}>
          <StaticField label="Datei" value={error.importFile} empty />
        </Grid>
        <Grid item xs={12}>
          <StaticField label="Status" value={error.status} empty />
        </Grid>
      </Grid>
    </CardEx>
  )
}
