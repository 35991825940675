import { Clear, Event } from '@mui/icons-material'
import { CalendarPickerView } from '@mui/lab'
import { Box, ClickAwayListener, Grid, Grow, Paper, Popper, TextField } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'
import {
  formatDate,
  getDateToday,
  isDate,
  isDateLower,
  parseDateVariants,
  toDate,
  trimDate0000
} from '@utils/dateutils'
import { Button } from '@utils/ui/Buttons/Button'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { propsShrink } from '@utils/ui/styles'
import { autoCompleteOff, stopPropagation } from '@utils/ui/uiutils'
import { ifString, restartTimer } from '@utils/utils'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  clear: {
    '& .clear-button': {
      visibility: 'hidden',
      '& svg': { fontSize: '1.3rem' }
    },
    '& :focus-within .clear-button, & :hover .clear-button': {
      visibility: 'visible'
    }
  }
}))

export type DateFieldProps = {
  name?: string | null
  label?: string | null
  value?: Date | string | null
  onChange?: any
  disabled?: boolean
  style?: object
  error?: string | boolean
  helperText?: string | null
  required?: boolean
  fullWidth?: boolean
  disableToolbar?: boolean
  minDate?: Date | null
  maxDate?: Date | null
  placeholder?: string
}

export const DateField = ({
  name,
  label,
  value: valueEx,
  onChange,
  disabled,
  style,
  error,
  helperText,
  required,
  fullWidth,
  minDate,
  maxDate,
  disableToolbar,
  placeholder
}: DateFieldProps) => {
  const { classes } = useStyles()

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const inputRef = useRef(null)

  const [edit, setEdit] = useState<string>()
  const [editErr, setEditErr] = useState<string>(null)

  const parseTimerRef = useRef()
  const selRef = useRef(null)

  const [view, setView] = useState<CalendarPickerView>('day')

  const lastDateRef = useRef<Date>()
  const lastValueExRef = useRef<any>()
  if (lastValueExRef.current !== valueEx) {
    lastValueExRef.current = valueEx
    if (valueEx == null) {
      lastDateRef.current = null
      setEdit('')
      setEditErr(null)
    } else if (isDate(valueEx)) {
      // @ts-ignore
      lastDateRef.current = valueEx
      const str = formatDate(valueEx)
      setEdit(str)
      setEditErr(null)
    } else if (typeof valueEx === 'string') {
      const date = new Date(valueEx)
      lastDateRef.current = isDate(date) ? trimDate0000(date) : null
      const str = (lastDateRef.current && formatDate(lastDateRef.current)) || valueEx
      setEdit(str)
      setEditErr(lastDateRef.current == null ? null : 'Kein gültiges Datum')
    }
  }

  const checkMinMax = useCallback(
    (date: Date) => {
      if (date != null) {
        const minDateEx = trimDate0000(toDate(minDate))
        const maxDateEx = trimDate0000(toDate(maxDate))
        if (minDate && minDateEx > date) {
          return 'Datum darf nicht kleiner sein als ' + formatDate(minDateEx)
        }
        if (maxDate && maxDateEx < date) {
          return 'Datum darf nicht größer sein als ' + formatDate(maxDateEx)
        }
      }
      return null
    },
    [maxDate, minDate]
  )

  const onOpen = useCallback(() => {
    if (!open) setView('day')
    setOpen(!open)
  }, [open])

  const onClose = () => {
    setOpen(false)
  }

  const onChangeInt = useCallback(
    (e) => {
      if (onChange) {
        onChange(e)
      }
    },
    [onChange]
  )

  const onChangeCalendar = useCallback(
    (v0, isfinish) => {
      setEditErr(null)
      const v = trimDate0000(v0)
      onChangeInt({ name, value: v })
      if (isfinish) {
        switch (view) {
          case 'day':
            onClose()
            break
          case 'year':
            setView('month')
            break
          case 'month':
            setView('day')
            break
        }
      }
    },
    [name, onChangeInt, view]
  )

  const onEditChange = useCallback(
    (e) => {
      const text = e?.target?.value
      const editParser = () => {
        if (text == null || text.length === 0) {
          if (lastValueExRef.current != null) {
            lastValueExRef.current = null
            lastDateRef.current = null
            onChangeInt({ name, value: null })
          }
          setEditErr(null)
          return
        }

        const date = text.length >= 2 && parseDateVariants(text)
        if (date) {
          selRef.current = text.length
          setEdit(formatDate(date))
          lastValueExRef.current = date
          lastDateRef.current = date
          onChangeInt({ name, value: date })
          setEditErr(checkMinMax(date))
          return
        }

        setEditErr('Ungültiges Datum')

        if (lastValueExRef.current != null) {
          lastValueExRef.current = null
          lastDateRef.current = null
          onChangeInt({ name, value: null })
        }
      }

      setEdit(text)
      restartTimer(parseTimerRef, editParser, 500)
    },
    [checkMinMax, name, onChangeInt]
  )

  const onKeyDown = useCallback(
    (e) => {
      if (disabled) {
        return false
      }
      switch (e.key) {
        case '#':
          stopPropagation(e)
          selRef.current = 0
          onChangeInt({ name, value: new Date() })
          return true
        case 'PageUp':
        case 'PageDown':
          stopPropagation(e)
          if (lastDateRef.current != null) {
            selRef.current = 0
            const next = lastDateRef.current == null ? new Date() : new Date(lastDateRef.current)
            const inc = e.key === 'PageDown' ? -1 : 1
            if (e.shiftKey) {
              next.setMonth(next.getMonth() + inc)
            } else {
              next.setDate(next.getDate() + inc)
            }
            onChangeInt({ name, value: next })
            return true
          }
          return false
      }
      return false
    },
    [disabled, name, onChangeInt]
  )

  useEffect(() => {
    setEditErr(checkMinMax(lastDateRef.current))
  }, [checkMinMax, valueEx])

  useEffect(() => {
    if (selRef.current != null) {
      if (inputRef.current != null) {
        inputRef.current.setSelectionRange(selRef.current, inputRef.current.value.length)
      }
      selRef.current = null
    }
  }, [edit])

  const onBlur = () => {
    // if (editErr) {
    //   setEditErr(false)
    //   onEditChange({})
    // }
  }

  const inputProps = useMemo(() => {
    const onClear = (e) => {
      stopPropagation(e)
      setEdit(null)
      setEditErr(null)
      onChangeInt({ name, value: null })
    }
    return {
      endAdornment: !disabled && (
        <>
          <IconButton
            Icon={Clear}
            size="tiny"
            tabIndex={-1}
            disabled={disabled}
            onClick={onClear}
            className="clear-button"
          />
          <IconButton Icon={Event} size="small" disabled={disabled} onClick={onOpen} />
        </>
      )
    }
  }, [disabled, onOpen, onChangeInt, name])

  const calendar = useMemo(
    () => (
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal sonst probleme in dialogen
        style={{ zIndex: 5000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={onClose}>
                <Grid container direction="column">
                  <Grid item>
                    <Box>
                      <DateCalendar
                        value={lastDateRef.current}
                        view={view}
                        onViewChange={setView}
                        onChange={onChangeCalendar}
                        maxDate={maxDate ?? undefined}
                        minDate={minDate ?? undefined}
                      />
                    </Box>
                  </Grid>
                  <Grid item alignSelf="center">
                    <Button
                      label="Heute"
                      onClick={() => onChangeCalendar(getDateToday(), true)}
                      disabled={disabled}
                      visible={
                        (minDate == null || isDateLower(minDate, getDateToday(), true)) &&
                        (maxDate == null || isDateLower(getDateToday(), maxDate, true))
                      }
                    />
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    ),
    [disabled, maxDate, minDate, onChangeCalendar, open, view]
  )

  return (
    <>
      <TextField
        ref={anchorRef}
        className={clsx(classes.clear)}
        autoComplete={autoCompleteOff}
        size="small"
        variant="standard"
        name={name}
        label={label}
        InputLabelProps={propsShrink}
        InputProps={inputProps}
        inputProps={{
          'data-name': label
        }}
        value={edit || ''}
        onChange={onEditChange}
        onKeyDown={onKeyDown}
        style={style}
        error={!!error || !!editErr}
        helperText={ifString(error) ?? (helperText || editErr)}
        required={required}
        disabled={disabled}
        fullWidth={fullWidth}
        inputRef={inputRef}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {calendar}
    </>
  )
}
