import { AppPaths } from '@one/AppPaths'
import { ArtikelSelektionDisplayJson } from '@one/typings/apiTypings'
import { Column } from '@utils/ui/DataTable/DataTable'
import { ArtikelDetailsTabelle } from './ArtikelDetailsTabelle'

const AboColumns: Column[] = [
  {
    field: 'aboName',
    header: 'Abo-Name',
    sortable: true
  }
]

const AboTableActions = [
  {
    icon: 'arrow_forward',
    tooltip: 'Artikelabo öffnen',
    getLink: (data: any) => AppPaths.ArtikelAboPflegeFn(data.aboId)
  }
]

export interface ArtikelAboSectionProps {
  selektionen: ArtikelSelektionDisplayJson[]
}

export const ArtikelAboSection = ({ selektionen }: ArtikelAboSectionProps) => {
  if (selektionen == null || selektionen.length === 0) {
    return null
  }

  return (
    <ArtikelDetailsTabelle
      name="ArtikelAboSection"
      values={selektionen}
      title="Artikel Abo"
      columns={AboColumns}
      tableActions={AboTableActions}
      normal
    />
  )
}
