import { Add, Delete } from '@mui/icons-material'
import Grid from '@mui/material/Grid2'
import { PimArtikelVersionFlieseJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelFlieseProps {
  fliese: PimArtikelVersionFlieseJson
  onChange?: ValueChangeFn<SeArtikelJson>
  dispatch?: (action: ModelAction) => void
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelFliese wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param fliese
 * @param onChange
 * @param dispatch
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelFliese = ({
  fliese,
  onChange,
  dispatch,
  errors,
  readonly
}: PimArtikelFlieseProps) => {
  const onAddFliese = () => {
    dispatch({
      type: EigenlistungsArtikelUseCase.ADDFLIESE
    })
  }
  const onRemoveFliese = () => {
    dispatch({
      type: EigenlistungsArtikelUseCase.REMOVEFLIESE
    })
  }
  if (fliese == null) {
    if (readonly) {
      return null
    }
    return (
      <Button
        label="Fliese hinzufügen"
        variant="contained"
        StartIcon={Add}
        onClickVoid={onAddFliese}
      />
    )
  }
  return (
    <CardEx
      title="Fliese"
      name="fliese"
      topActions={
        readonly ? null : (
          <Button
            label="Entfernen"
            StartIcon={Delete}
            onClickVoid={onRemoveFliese}
            color="warning"
          />
        )
      }
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Kategorie"
            value={fliese.kategorie}
            name="pimArtikel.version.fliese.kategorie"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Serienname"
            value={fliese.serienname}
            name="pimArtikel.version.fliese.serienname"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Angebotstext 1"
            value={fliese.angebotstext1}
            name="pimArtikel.version.fliese.angebotstext1"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Angebotstext 2"
            value={fliese.angebotstext2}
            name="pimArtikel.version.fliese.angebotstext2"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}></Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Produktbreite"
            value={fliese.produktBreite}
            name="pimArtikel.version.fliese.produktBreite"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            suffix="mm"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Produktlänge"
            value={fliese.produktLaenge}
            name="pimArtikel.version.fliese.produktLaenge"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            suffix="mm"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Produkthöhe"
            value={fliese.produktHoehe}
            name="pimArtikel.version.fliese.produktHoehe"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            suffix="mm"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Nennbreite"
            value={fliese.nennBreite}
            name="pimArtikel.version.fliese.nennBreite"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            suffix="mm"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Nennlänge"
            value={fliese.nennLaenge}
            name="pimArtikel.version.fliese.nennLaenge"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            suffix="mm"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Verdrängungsraum"
            value={fliese.verdraengungsraum}
            name="pimArtikel.version.fliese.verdraengungsraum"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Farbe"
            value={fliese.farbe}
            name="pimArtikel.version.fliese.farbe"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Farbfamilie"
            value={fliese.farbfamilie}
            name="pimArtikel.version.fliese.farbfamilie"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Material"
            value={fliese.material}
            name="pimArtikel.version.fliese.material"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Oberfläche"
            value={fliese.oberflaeche}
            name="pimArtikel.version.fliese.oberflaeche"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Design"
            value={fliese.design}
            name="pimArtikel.version.fliese.design"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Einsatzbereich"
            value={fliese.einsatzbereich}
            name="pimArtikel.version.fliese.einsatzbereich"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Nassbereich"
            value={fliese.nassbereich}
            name="pimArtikel.version.fliese.nassbereich"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Rutschfestigkeit"
            value={fliese.rutschfestigkeit}
            name="pimArtikel.version.fliese.rutschfestigkeit"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Abriebgrupppe"
            value={fliese.abriebgruppe}
            name="pimArtikel.version.fliese.abriebgruppe"
            onChange={onChange}
            disabled={readonly}
            fraction={4}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Formatgruppe"
            value={fliese.formatgruppe}
            name="pimArtikel.version.fliese.formatgruppe"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Gewicht pro Quadratmeter"
            value={fliese.gewichtProM2}
            name="pimArtikel.version.fliese.gewichtProM2"
            onChange={onChange}
            disabled={readonly}
            fraction={4}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Inhalt pro Palette"
            value={fliese.inhaltProPaletteM2}
            name="pimArtikel.version.fliese.inhaltProPaletteM2"
            onChange={onChange}
            disabled={readonly}
            fraction={4}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Inhalt pro Karton"
            value={fliese.inhaltProKartonM2}
            name="pimArtikel.version.fliese.inhaltProKartonM2"
            onChange={onChange}
            disabled={readonly}
            fraction={4}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Kantenausbildung"
            value={fliese.kantenausbildung}
            name="pimArtikel.version.fliese.kantenausbildung"
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Oberflächenveredelung"
            value={fliese.oberflaechenveredelung}
            name="pimArtikel.version.fliese.oberflaechenveredelung"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}></Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <Checkbox
            indeterminate
            label="Glasierung"
            checked={fliese.glasierung}
            name="pimArtikel.version.fliese.glasierung"
            onChange={onChange}
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <Checkbox
            indeterminate
            label="Frostsicher"
            checked={fliese.frostsicher}
            name="pimArtikel.version.fliese.frostsicher"
            onChange={onChange}
            disabled={readonly}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelFlieseMemo = React.memo(PimArtikelFliese)
