import { api } from '@one/api'
import { AboArtikelAttrChangeJson, ArtikelSelektionMultiDetailsJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useEffect, useState } from 'react'

export const useMultiArtikelDetailsState = (
  aboSelektionId: number,
  konfliktIds: number[]
): [Record<string, Record<string, AboArtikelAttrChangeJson>>, boolean] => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const [konfliktDetails, setKonfliktDetails] = useState<ArtikelSelektionMultiDetailsJson>()

  useEffect(() => {
    if (aboSelektionId != null && konfliktIds?.length > 0) {
      apiCall({
        method: 'GET',
        rest: 'artikelselektion/multiArtikelDetails',
        params: {
          aboSelektionId,
          konfliktIds
        },
        onSuccess: (data, response, duration) => {
          setKonfliktDetails(data)
        }
      })
    }
  }, [aboSelektionId, apiCall, konfliktIds])

  return [konfliktDetails?.eintragChanges, apiBusy]
}
