import { Grid } from '@mui/material'
import { DzLieferantJson, SeLieferantJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import React from 'react'

export type LieferantSperreProps = {
  dzLieferant: DzLieferantJson
  onValueChange: ValueChangeFn<SeLieferantJson>
  errors: any
}

export const LieferantSperre = ({ dzLieferant, onValueChange, errors }: LieferantSperreProps) => (
  <CardEx title="Sperren">
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={6} lg={3} xl={2}>
          <Checkbox
            label="Buchungssperre"
            checked={dzLieferant.buchungsSperre}
            name="dzLieferant.buchungsSperre"
            onChange={onValueChange}
          />
        </Grid>
        <Grid item xs={6} lg={3} xl={2}>
          <Checkbox
            label="Einkaufssperre"
            checked={dzLieferant.einkaufsSperre}
            name="dzLieferant.einkaufsSperre"
            onChange={onValueChange}
          />
        </Grid>
        <Grid item xs={3} lg={3} xl={2}>
          <Checkbox
            label="Gesperrt"
            checked={dzLieferant.gesperrtDz}
            name="dzLieferant.gesperrtDz"
            onChange={onValueChange}
          />
        </Grid>
        <Grid item xs={3} lg={2} xl={2}>
          <Checkbox
            label="Gelöscht"
            checked={dzLieferant.geloeschtDz}
            name="dzLieferant.geloeschtDz"
            onChange={onValueChange}
          />
        </Grid>
        <Grid item container spacing={2}>
          {dzLieferant.geloeschtDz && (
            <>
              <Grid item xs={6} lg={3} xl={2}>
                <DateField
                  label="Gelöscht von"
                  value={dzLieferant.geloeschtDzGueltigVon}
                  name="dzLieferant.geloeschtDzGueltigVon"
                  error={errors['dzLieferant.geloeschtDzGueltigVon']}
                  onChange={onValueChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={3} xl={2}>
                <DateField
                  label="Gelöscht bis"
                  value={dzLieferant.geloeschtDzGueltigBis}
                  name="dzLieferant.geloeschtDzGueltigBis"
                  error={errors['dzLieferant.geloeschtDzGueltigBis']}
                  onChange={onValueChange}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  </CardEx>
)

export const LieferantSperreMemo = React.memo(LieferantSperre)
