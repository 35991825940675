import { Typography } from '@mui/material'
import { api } from '@one/api'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import {
  ArtikelBetriebstyp,
  ArtikelBetriebstypChangeJson,
  ArtikelDisplayJson,
  ArtikelDisplayResonseJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useArtikelColumns } from './ArtikelColumn'

export type ChangeBetriebstypDialogProps = {
  open: boolean
  onClose: () => void
  updatSelection: (artikel: ArtikelDisplayJson[]) => void
  selected: ArtikelDisplayJson[]
}

export const ChangeBetriebstypDialog = ({
  open,
  onClose,
  updatSelection,
  selected
}: ChangeBetriebstypDialogProps) => {
  const [state, onChange] = useFormState({ bt: undefined as ArtikelBetriebstyp })

  const [apiCall, apiBusy] = useApiCaller(api)

  const changeBetriebstyp = () => {
    apiCall<ArtikelDisplayResonseJson>({
      rest: '/artikel/betriebstypChange',
      method: 'POST',
      data: {
        seArtikelIds: selected.map((a) => a.seArtikelId),
        betriebstyp: state.bt
      } as ArtikelBetriebstypChangeJson,
      onSuccess: (data) => {
        updatSelection(data.items)
        onClose()
      }
    })
  }

  const actions = [
    <Button
      key="do"
      label={`${selected.length} Artikel ändern`}
      onClickVoid={changeBetriebstyp}
      disabled={!state.bt}
      variant="contained"
      color="error"
    />,
    <CancelButton key="cancel" onClickVoid={onClose} />
  ]

  const artikelColumns = useArtikelColumns()

  return (
    <DialogEx
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      disabledCloseButton={apiBusy}
      title="Betriebstyp ändern"
      actions={actions}
      height="500px"
    >
      <StatePlane wait={apiBusy}>
        <Frame space>
          <FrameRow>
            <Typography>
              Der Betriebstyp der ausgewählten Artikel auf einen anderen ändern.
            </Typography>
          </FrameRow>
          <FrameRow>
            <ArtikelBetriebstypField
              label="Neuer Betriebstyp"
              required
              name="bt"
              value={state.bt}
              onChange={onChange}
            />
          </FrameRow>
          <FrameBody>
            <DataTableCard
              value={selected}
              columns={artikelColumns}
              title="Artikel deren Betriebstyp geändert werden soll"
              name="artikelbetriebstypwechsel"
              dense
              nopadding
            />
          </FrameBody>
        </Frame>
      </StatePlane>
    </DialogEx>
  )
}
