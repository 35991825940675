import {
  AllianzVerteilerMatrixMitgliedJson,
  LieferantDisplayJson,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'
import { SelectField } from '@utils/ui/fields/SelectField'
import { toString } from '@utils/utils'

export interface MitgliedLieferantMatrixCellProps {
  node: AllianzVerteilerMatrixMitgliedJson
  lieferant: LieferantDisplayJson
  preisebenen: PreisEbeneDisplayJson[]
  onSetPreisEbene: (mitgliedId: number, lieferantId: number, preisEbeneId: number) => void
  disabled: boolean
}

export const MitgliedLieferantMatrixCell = ({
  node,
  lieferant,
  preisebenen,
  onSetPreisEbene,
  disabled
}: MitgliedLieferantMatrixCellProps) => (
  <SelectField<PreisEbeneDisplayJson>
    emptyText="Standard"
    value={node?.lieferanten[toString(lieferant.seLieferantId)]?.preisEbeneId}
    fullWidth
    borderless
    options={preisebenen}
    optionText={(opt) => opt.alias || opt.name}
    optionValue={(opt) => opt.id}
    optionIgnored={(opt) => opt.ausgeblendet}
    onChange={(e) =>
      onSetPreisEbene(node.mitglied.id, lieferant.seLieferantId, e.target.value as any)
    }
    disabled={disabled}
  />
)
