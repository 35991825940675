import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApiMethod, useApiCaller } from './apicaller'
import { debugLog } from './logging'
import { useObserver } from './observer'
import { CallbackType, resolveObjectField } from './utils'

export enum CacheStatus {
  init,
  loading,
  loaded,
  error
}

type CacheType<T, K> = {
  data?: T[]
  status?: CacheStatus
  listener?: Set<(cache: CacheType<T, K>) => void>
  keyMap?: Map<K, T>
  timestamp?: number
  error?: string
}
const datacache = new Map<string, CacheType<any, any>>()

const getCache = (datakey: string) => (): CacheType<any, any> => {
  let cache = datacache.get(datakey)
  if (cache == null) {
    cache = {
      status: CacheStatus.init,
      listener: new Set(),
      data: [],
      keyMap: new Map(),
      timestamp: 0
    }
    datacache.set(datakey, cache)
  }
  return cache
}

export type DataCacheConfig<T = any> = {
  api: any
  datakey: string
  rest: any
  params?: any
  data?: any
  init?: T[]
  field: any
  refreshEvents?: any
  method?: ApiMethod
  decorator?: any
  idField?: string
  maxAge?: number
}

export type DataCache<T, K = any> = {
  data?: T[]
  status: CacheStatus
  cacheRefresh: () => void
  get: (id: K) => T | null
  getData: (callback: CallbackType<T[]>) => void
}

export const useDataCache = <T, K = any>(
  {
    api,
    datakey,
    rest,
    params,
    data,
    idField,
    init,
    field,
    refreshEvents,
    decorator,
    method = 'POST',
    maxAge = 5 * 60 * 1000
  }: DataCacheConfig<T>,
  flush: boolean = false
): DataCache<T, K> => {
  const thisRef = useRef<any>()
  thisRef.current = {
    rest,
    params,
    data,
    idField,
    init,
    field,
    refreshEvents,
    decorator,
    maxAge,
    method
  }

  const [apiCall] = useApiCaller(api)

  const [state, setState] = useState<CacheType<T, K>>(getCache(datakey))

  const cacheRefresh = useCallback(() => {
    let cache = getCache(datakey)()
    if (cache != null && cache.status !== CacheStatus.loading) {
      debugLog('DataCache refresh', datakey, cache)
      apiCall({
        rest: thisRef.current.rest,
        params: thisRef.current.params,
        data: thisRef.current.data,
        method: thisRef.current.method,
        onSuccess: (data) => {
          cache.status = CacheStatus.loaded
          cache.timestamp = Date.now()
          cache.error = null
          cache.data =
            resolveObjectField(data, thisRef.current.field || 'result') ||
            thisRef.current.init ||
            []
          if (thisRef.current.decorator) {
            cache.data = cache.data.map(thisRef.current.decorator)
          }
          cache.keyMap =
            thisRef.current.idField == null
              ? null
              : new Map(cache.data.map((d) => [d[thisRef.current.idField], d]))
          const cacheCopy = { ...cache }
          cache.listener.forEach((l) => l(cacheCopy))
        },
        onError: (error) => {
          cache.status = CacheStatus.error
          cache.timestamp = Date.now()
          cache.error = error.mainMessage?.message
          const cacheCopy = { ...cache }
          cache.listener.forEach((l) => l(cacheCopy))
        },
        onCall: () => {
          cache.status = CacheStatus.loading
          // const cacheCopy = { ...cache }
          // cache.listener.forEach((l) => l(cacheCopy))
        }
      })
    }
  }, [apiCall, datakey])

  const get = useCallback(
    (id: K): T => {
      if (thisRef.current.idField == null) {
        throw new Error('idField not set')
      }
      if (id == null) {
        return null
      }
      if (state.keyMap == null) {
        return null
      }
      let item = state.keyMap.get(id)
      // Falls es fehlt, ggf neuladen
      if (item == null && Date.now() - state.timestamp > 10000) {
        cacheRefresh()
      }
      return item
    },
    [cacheRefresh, state.keyMap, state.timestamp]
  )

  const getData = useCallback(
    (callback: CallbackType<T[]>): void => {
      let cache = getCache(datakey)()
      if (cache.status === CacheStatus.error) {
        callback.catch(new Error('Daten nicht verfügbar'))
      } else if (cache.status === CacheStatus.loaded) {
        callback.then(cache.data)
      } else if (cache.status === CacheStatus.loading || cache.status === CacheStatus.init) {
        cache.listener.add((state) => {
          if (cache.status === CacheStatus.loaded) {
            callback.then(cache.data)
          } else {
            callback.catch(new Error('Daten nicht verfügbar'))
          }
        })
      }
    },
    [datakey]
  )
  useObserver<any>(refreshEvents, () => {
    cacheRefresh()
  })

  useEffect(() => {
    let cache = getCache(datakey)()
    cache.listener.add(setState)
    if (
      flush ||
      cache.status === CacheStatus.init ||
      (cache.status !== CacheStatus.loading &&
        thisRef.current.maxAge &&
        Date.now() - cache.timestamp > thisRef.current.maxAge)
    ) {
      cacheRefresh()
    }
    return () => {
      cache.listener.delete(setState)
    }
  }, [cacheRefresh, datakey, flush])

  const res = useMemo(
    () => ({
      data: state.data,
      status: state.status,
      error: state.error,
      cacheRefresh,
      get,
      getData
    }),
    [cacheRefresh, get, getData, state.data, state.error, state.status]
  )

  return res
}
