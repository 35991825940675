import { api } from '@one/api'
import {
  EkKonditionenListeEintragDisplayJson,
  EkPreiseArtikelListeJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

export type EkPreisArtikelListeRes = [
  EkKonditionenListeEintragDisplayJson[],
  number,
  number,
  () => void,
  boolean
]

export const useEkPreisArtikelListe = (
  preislisteId: number,
  eintragId?: number
): EkPreisArtikelListeRes => {
  const [liste, setListe] = useState<EkKonditionenListeEintragDisplayJson[]>([])
  const [pos, setPos] = useState(-1)
  const [last, setLast] = useState<number>()
  const [apiCall, isReloading] = useApiCaller(api)

  const reload = useCallback(() => {
    apiCall<EkPreiseArtikelListeJson>({
      method: 'GET',
      rest: 'ekpreisliste/artikelliste',
      params: { id: preislisteId },
      onSuccess: (data) => {
        setListe(data.artikelListe || [])
      }
    })
  }, [apiCall, preislisteId])

  useEffect(() => {
    if (!isEqual(preislisteId, last)) {
      setLast(preislisteId)
      reload()
    }
  }, [apiCall, last, preislisteId, reload])

  useEffect(() => {
    setPos(liste.findIndex((i) => i.id === eintragId) + 1)
  }, [liste, eintragId])

  return [liste, liste.length, pos, reload, isReloading]
}
