import {
  ArtikelBetriebstyp,
  NeonKontext,
  PreisEbeneDisplayJson,
  VkKalkulationsvorschlagJson,
  VkPreiseBearbeitenJson,
  VkPreisGruppeJson,
  VkPreisJson,
  VkPreisListeBlattJson,
  VkPreisListeEintragJson,
  VkRundungsregelJson
} from '@one/typings/apiTypings'

export interface VkPreisEdit extends VkPreisJson {
  key: number
  kasse?: boolean
  runden?: boolean
  basisBrutto?: boolean
  preisAlt?: number
  preisDelta?: number
  preisGruppeName?: string
  preisGruppeBez?: string
  staffelErr?: boolean
  staffelCount?: number
  rundungGanzeCent?: number
  preisx?: number
  vpeeinheitName?: string
}

export interface VkPreisListeBlattEdit extends VkPreisListeBlattJson {
  key?: number
  standortNr?: string
  defaultStandort: boolean
  warAbgeschlossen?: boolean
  rundungGanzeCent?: number
  referenzPreisgruppeId?: number
  preise: VkPreisEdit[]
  effektiverListenpreis?: number
  effektiverEkFreiHaus?: number
  effektiverEkPreis?: number
  effektiverEkPreisBrutto?: number
}

export interface VkPreisListeEintragEdit extends VkPreisListeEintragJson {
  blaetter?: VkPreisListeBlattEdit[]
  basisPreisgruppe?: VkPreisGruppeJson
}
export interface VkPreiseBearbeitenEdit extends VkPreiseBearbeitenJson {
  neueKondi?: VkPreisListeEintragEdit
  alteStandortPreise?: Map<number, Map<string, VkPreisJson>>
  rundungsregelnMap: Map<number, VkRundungsregelJson>
  preisgruppen: VkPreisGruppeJson[]
  kalkulationsvorschlaegeMap: Map<number, VkKalkulationsvorschlagJson>
  standorte: PreisEbeneDisplayJson[]
  standort0Id?: number
  kalkulationsvorschlaege?: VkKalkulationsvorschlagJson[]
  rundungsregeln?: VkRundungsregelJson[]
  rundungsregelGanzeCentId?: number
  currency?: string
  kontext?: NeonKontext
  betriebstyp?: ArtikelBetriebstyp
}

export const emptyVkPreise = {
  artikel: {},
  neueKondi: {
    blaetter: []
  }
} as VkPreiseBearbeitenEdit

export const emptyVkPreisBlatt = {
  preise: []
} as VkPreisListeBlattEdit
