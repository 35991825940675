import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { AppRouteDefinition } from '@utils/ui/App/AppRouteMgr'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { aidOf, flattenArray } from '@utils/utils'
import React, { useState } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  link: {
    textDecoration: 'none',
    display: 'block',
    color: theme.palette.text.primary
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.main, 0.42),
    color: 'inherit',
    textDecoration: 'none',
    display: 'block',
    '& div': {
      // color: theme.palette.primary.main
    },
    '& span': {
      // color: theme.palette.primary.main
    }
  },
  textItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  nested1: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0)
  },
  nested2: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0)
  },
  nested3: {
    paddingLeft: theme.spacing(6),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0)
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  item: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  menu: {
    marginTop: -8
    // marginTopBottom: theme.spacing(2)
  }
}))

export interface NavListProps {
  menuItems?: AppRouteDefinition[]
  onNavigate?: NavLinkProps['onClick']
}

/**
 * Component that displays a list of navigation links.
 */
export const NavList = ({ menuItems = [], onNavigate }: NavListProps) => {
  const { classes } = useStyles()
  const [DlgAnker, dlgShow] = useDialogAnker(false)

  const [actionGroupToggleState, toggleActionGroup] = useState<any>(() =>
    flattenArray(menuItems, 'items').reduce((p, v) => {
      p[aidOf(v)] = !!v.defaultExpanded
      return p
    }, {})
  )
  /**
   * Toggle the sub navigation visibility of the given navigation item.
   *
   * @param {AppRouteDefinition} item A navigation item
   */
  const handleItemClick = (item: AppRouteDefinition) => () => {
    // if (!item.enabled) {
    //   return
    // }

    if (item.dialog) {
      dlgShow((open, onClose) => {
        const Dialog = item.dialog
        return <Dialog open={open} onClose={onClose()} />
      })
      return
    }

    const key = aidOf(item)
    const s = actionGroupToggleState[key]
    toggleActionGroup({ ...actionGroupToggleState, [key]: s !== true })
  }

  /**
   * Render a navigation list item.
   *
   * @param {AppRouteDefinition} item A navigation item
   * @param {number} [level=0] The navigation level
   * @param {string} [className] A style class name
   */
  const renderListItem = (
    item: AppRouteDefinition,
    level: number = 0,
    className: string = null
  ) => {
    const renderSubnav = (subItem: AppRouteDefinition, subLevel: number) => {
      return (
        <Collapse in={actionGroupToggleState[aidOf(subItem)]} timeout="auto" unmountOnExit>
          <List component="ul" key={aidOf(subItem)} disablePadding className={classes.menu}>
            {subItem.items
              .filter((action) => action.label != null)
              // .filter((action) => action.visible !== false)
              .map((action) => {
                return renderListItem(action, subLevel, classes[`nested${subLevel}`])
              })}
          </List>
        </Collapse>
      )
    }

    return item.path ? (
      <NavLink
        to={Array.isArray(item.path) ? item.path[0] : item.path}
        key={aidOf(item)}
        end
        className={({ isActive }) => (isActive ? classes.active : classes.link)}
        onClick={onNavigate || null}
      >
        <ListItemButton key={aidOf(item)} className={classes.item} data-name={item.path}>
          <ListItemText primary={item.label} className={className} />
        </ListItemButton>
      </NavLink>
    ) : (
      <React.Fragment key={aidOf(item)}>
        <ListItemButton
          onClick={handleItemClick(item)}
          className={classes.textItem}
          data-name={item.id}
        >
          <ListItemText primary={item.label} className={className} />
          {item.items ? (
            actionGroupToggleState[aidOf(item)] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItemButton>
        {item.items ? renderSubnav(item, level + 1) : null}
      </React.Fragment>
    )
  }

  return (
    <>
      <List key="navList">
        {menuItems
          .filter((entry) => entry.label != null)
          // .filter((action) => action.visible !== false)
          .map((entry: any) => {
            return renderListItem(entry)
          })}
      </List>
      <DlgAnker />
    </>
  )
}
