import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import {
  EANGruppeDisplayJson,
  ErpSystemJson,
  GesellschafterFirmaBearbeitenJson,
  GesellschafterFirmaJson,
  GetErpDataStatusJson,
  VkPreisGruppeJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useModelMgr } from '@utils/modelmgr'
import { Action } from '@utils/ui/Action'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectField } from '@utils/ui/fields/SelectField'
import { TextField } from '@utils/ui/fields/TextField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { nameOf } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

export interface FirmaAnlageViewProps {
  onSave?: () => void
}

export enum ErpInitStatus {
  INIT = 'INIT',
  DONE = 'DONE',
  In_PROGRESS = 'In_PROGRESS'
}

export const FirmaAnlageView = ({ onSave }: FirmaAnlageViewProps) => {
  const { id, organisationsId } = useContext(RouteContext) as any
  const [apiCall, apiBusy] = useApiCaller(api)
  const [erpSysteme, setErpSysteme] = useState([])
  const [erpInitStatus, setErpInitStatus] = useState<ErpInitStatus>()

  const { model, envelope, errors, uiLock, onValueChange, isNew, isChanged, save, reload } =
    useModelMgr<
      GesellschafterFirmaJson,
      GesellschafterFirmaJson,
      GesellschafterFirmaBearbeitenJson
    >({
      id: organisationsId ? 'neu' : id,
      api,
      unwrapField: 'gesellschafterFirma',
      restps: {
        id: organisationsId
      },
      onSave,
      title: 'Firma anlage',
      rest: 'gesellschafter/firma'
    })

  const getStatus = useCallback(() => {
    apiCall<GetErpDataStatusJson>({
      method: 'GET',
      rest: '/gesellschafter/getErpDataStatus',
      params: {
        id: envelope.letzteAnbindung?.id
      },
      onSuccess: (data) => {
        const initial =
          data.erpEbeneImportRequest?.length > 0 ? data.erpEbeneImportRequest[0] : null
        if (initial == null) {
          setErpInitStatus(null)
        } else if (initial.importEnd) {
          setErpInitStatus(ErpInitStatus.DONE)
        } else {
          setErpInitStatus(ErpInitStatus.In_PROGRESS)
        }
      }
    })
  }, [envelope, apiCall])

  useEffect(() => {
    if (!envelope?.letzteAnbindung?.id) {
      setErpInitStatus(ErpInitStatus.INIT)
    } else {
      getStatus()
    }
  }, [envelope, getStatus])

  const handleErpInit = useCallback(() => {
    setErpInitStatus(ErpInitStatus.In_PROGRESS)
    apiCall({
      method: 'GET',
      rest: '/gesellschafter/getERPInitial',
      params: { id: model.stammEbeneId }
    })
  }, [apiCall, model.stammEbeneId])

  useEffect(() => {
    if (envelope?.erpSysteme?.length) {
      setErpSysteme(envelope.erpSysteme.filter((erpSystem) => erpSystem.status === 'validiert'))
    }
  }, [envelope.erpSysteme])

  const readonly = !isNew

  const supportsEanGruppen =
    model.erpSystemId &&
    envelope.erpSysteme?.find((es) => es.id === model.erpSystemId)?.supportsEanGruppen

  const actions = useMemo<Action[]>(
    () =>
      [
        !organisationsId
          ? {
              role: UserRoles.ADMIN,
              navlink: AppPaths.OrganisationViewFn(model?.gsOrg?.id),
              text: 'Zur Organisation',
              variant: 'outlined',
              visible: Boolean(model.id)
            }
          : null,
        {
          role: UserRoles.ADMIN,
          // navlink: AppPaths.OrganisationViewFn(model?.gsOrg?.id),
          onClick: () => handleErpInit(),
          visible: Boolean(model.id),
          icon:
            erpInitStatus === ErpInitStatus.INIT
              ? ''
              : erpInitStatus === ErpInitStatus.In_PROGRESS
                ? 'sync'
                : 'check',
          text:
            erpInitStatus === ErpInitStatus.INIT
              ? 'ERP initialisieren'
              : erpInitStatus === ErpInitStatus.In_PROGRESS
                ? 'ERP wird initialisiert'
                : 'ERP initialisiert',
          variant: 'outlined',
          enabled: erpInitStatus === ErpInitStatus.INIT
        }
      ].filter(Boolean) as Action[],
    [model, organisationsId, handleErpInit, erpInitStatus]
  )
  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <CardEx
            backLink
            overviewLink={AppPaths.artikelliste.ArtikelListeUebersicht}
            title={`Firma ${model.name || 'anlegen'}`}
            topActions={actions}
          >
            <Grid container spacing={2} direction="column">
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    name="name"
                    label="Name"
                    error={errors.name}
                    value={model.name}
                    onChange={onValueChange}
                    fullWidth
                    required
                    disabled={readonly}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    name="gln"
                    label="Gesellschafternr."
                    error={errors.gln}
                    value={model.gln}
                    onChange={onValueChange}
                    fullWidth
                    required
                    disabled={readonly}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    name="nr"
                    label="ERP Firmennr."
                    error={errors.nr}
                    value={model.nr}
                    onChange={onValueChange}
                    fullWidth
                    required
                    disabled={readonly}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <SelectField
                    name="erpSystemId"
                    label="ERP-System Verbindung"
                    error={errors.erpSystemId}
                    value={model.erpSystemId}
                    options={erpSysteme}
                    onChange={onValueChange}
                    renderItem={(erpSystem: ErpSystemJson) => erpSystem.name}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <SelectField
                    name={nameOf<GesellschafterFirmaJson>('defaultEinzelhandelPreisgruppeId')}
                    label="Standard EH VK-Preisgruppe"
                    error={errors.defaultPreisgruppeId}
                    value={model.defaultEinzelhandelPreisgruppeId}
                    options={envelope.preisgruppen}
                    onChange={onValueChange}
                    renderItem={(eanGruppe: VkPreisGruppeJson) =>
                      eanGruppe.name + ' - ' + eanGruppe.bezeichnung
                    }
                    fullWidth
                    required
                  />
                </Grid>
                {supportsEanGruppen && (
                  <Grid item xs={12} sm={6} lg={3}>
                    <SelectField
                      name="defaultEanGruppeId"
                      label="Standard EAN-Gruppe"
                      error={errors.defaultEanGruppeId}
                      value={model.defaultEanGruppeId}
                      options={envelope.eanGruppen}
                      onChange={onValueChange}
                      renderItem={(eanGruppe: EANGruppeDisplayJson) =>
                        eanGruppe.name + ' - ' + eanGruppe.bezeichnung
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Checkbox
                    label="ist Einzelhändler"
                    name="einzlhandelAusPIM"
                    checked={model.einzlhandelAusPIM}
                    onChange={onValueChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardEx>
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
