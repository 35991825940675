import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { ArtikelAnzeigenJson, UseCaseStateJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { CardEx } from '@utils/ui/CardEx'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { PimArtikelView } from './pim/PimArtikelView'
import { DzArtikelView } from './zart/DzArtikelView'
import { ZeArtikelView } from './zart/ZeArtikelView'

export const ArtikelView = () => {
  const { id, revision, kontext } = useContext(RouteContext) as any
  const { setWindowTitle, replaceHistory } = useContext(AppRouteCtx)

  const [error, setError] = useState<UseCaseStateJson>()
  const [data, setData] = useState<ArtikelAnzeigenJson>()

  const [apiCall, apiBusy] = useApiCaller(api)

  const lastRef = useRef(null)

  const fetchData = useCallback(() => {
    apiCall<ArtikelAnzeigenJson>({
      method: 'GET',
      rest: '/artikel/open',
      params: { id, kontext, revision },
      onErrorMsg: `Artikel mit ID "${id}" konnte nicht geladen werden.`,
      onError: (err) => setError(err),
      onSuccess: (data) => {
        setData(data)
        const display = data.dz?.display || data.ze?.display || data.pim?.display
        const revision = data.dz?.revision || data.ze?.revision || data.pim?.revision
        const kontext = data.pim?.kontext
        setWindowTitle(`[${display.btNummer || display.hageNummer}] ${display.bez1}`)
        replaceHistory(() => AppPaths.ArtikelExFn(display.id, revision, kontext))
        lastRef.current = {
          id: display?.id,
          revision: revision,
          kontext: kontext
        }
      }
    })
  }, [apiCall, id, kontext, replaceHistory, revision, setWindowTitle])

  useEffect(() => {
    if (
      lastRef.current == null ||
      lastRef.current.id != id ||
      lastRef.current.revision != revision ||
      lastRef.current.kontext != kontext
    ) {
      fetchData()
    }
  }, [fetchData, id, kontext, revision])

  const reload = useCallback(() => fetchData(), [fetchData])

  const body = useMemo(() => {
    if (data == null) {
      return <CardEx title="Lade Artikel..." />
    }
    if (data.pim) {
      return <PimArtikelView data={data.pim} reload={reload} />
    }
    if (data.ze) {
      return <ZeArtikelView data={data.ze} reload={reload} />
    }
    if (data.dz) {
      return <DzArtikelView data={data.dz} reload={reload} />
    }
    return <CardEx title="Unbekannter Artikeltyp!" />
  }, [data, reload])

  return (
    <StatePlane wait={apiBusy} error={error}>
      {body}
    </StatePlane>
  )
}
