import { createContext } from 'react'

export type AppRouteCtxType = {
  key: string
  id: string
  name: string
  componentName?: string
  domBaseId?: string
  url?: string
  path?: string
  pathKey: string
  action?: 'PUSH' | 'POP' | 'REPLACE'
  search?: any
  visible?: boolean
  replaceSearch?: any
  replaceHistory?: any
  style?: object
  body?: React.ReactNode
  setWindowTitle?: (title: string) => void
  dynamicTitle?: string
  invalidateRoute?: () => void
  lastNav?: number
  nocache?: boolean
}

export const AppRouteCtx = createContext<AppRouteCtxType>({} as any)
