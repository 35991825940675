import '@fortawesome/fontawesome-free/css/all.min.css'
import React from 'react'

export interface AwesomeIconProps {
  iconClass: string
  title?: string
  style?: React.CSSProperties
}

export const AwesomeIcon = ({ iconClass, title, style }: AwesomeIconProps) => {
  return <i className={iconClass} title={title} style={style} />
}
