import { AppPaths, VirtualArtikelListIds } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { MessageID } from '@one/MessageIDs'
import { api } from '@one/api'
import {
  ArtikelBetriebstyp,
  HauptlieferantBearbeitenJson,
  HauptlieferantPflegeEintragJson,
  HauptlieferantPflegeListeJson,
  HauptliefersantSaveJson,
  NeonKontext,
  PreisVorgangTyp
} from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { ModelState, useModelMgr } from '@utils/modelmgr'
import { notifyObservers } from '@utils/observer'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HauptlieferantPflegeKopf } from './HauptlieferantPflegeKopf'
import { HauptlieferantPflegeTable } from './HauptlieferantPflegeTable'

export interface HauptlieferantPflegeModel extends HauptlieferantBearbeitenJson {
  preisVorgangTyp?: PreisVorgangTyp
}

// eslint-disable-next-line no-unused-vars
const validate = (model: HauptlieferantPflegeModel) => {
  const validationErrors = {}
  return validationErrors
}

const onSave = () => notifyObservers({ name: EventNames.DASHBOARD })

export const HauptlieferantPflegeView = () => {
  const { id, kontext, bt } = useContext(RouteContext) as any
  const { replaceHistory } = useContext(AppRouteCtx)
  const { isWithEkPreis, isWithVkPreis, isPIMModel } = useContext(AppContext)
  const [changedItems, setChangedItems] = useState<HauptlieferantPflegeEintragJson[]>([])
  const [filterLieferantId, setFilterLieferantId] = useState(null)
  const [preisEbeneId, setPreisEbeneId] = useState(null)
  const [filters, setFilters] = useFormState({
    filterWithoutHauptlieferant: false,
    filterWithAltHauptlieferant: false,
    filterPreisebene: false
  })
  const navigate = useNavigate()

  const saveMutator = (model: HauptlieferantPflegeModel): HauptlieferantPflegeListeJson => {
    const { preisVorgangTyp } = model
    const { eintraege, ...other } = model?.pflegeListe || {}
    setChangedItems([])
    return { ...other, preisVorgangTyp }
  }

  const editMutator = (
    model: HauptlieferantBearbeitenJson | HauptliefersantSaveJson,
    altModel: HauptlieferantPflegeModel
  ): HauptlieferantPflegeModel => {
    if (model['pflegeListe']) {
      return model as HauptlieferantPflegeModel
    }

    // after save use old model, but reset eintraegeChanges
    return {
      ...altModel,
      pflegeListe: {
        ...altModel.pflegeListe,
        eintraegeChanges: []
      }
    }
  }

  const { model, isNew, isChanged, uiLock, save, reload, updModel, modelState, silentUpd } =
    useModelMgr<HauptlieferantPflegeModel, HauptlieferantPflegeModel, HauptlieferantBearbeitenJson>(
      {
        id,
        api,
        title: 'Hauptlieferenten Pflege',
        rest: 'hauptlieferant/artikelliste',
        restps: { kontext, bt },
        restEdit: 'open',
        init: {},
        retryMessageId: MessageID.IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN,
        validate,
        editMutator,
        saveMutator,
        onSave
      }
    )

  const onReload = useCallback(() => {
    setChangedItems([])
    reload()
  }, [reload])

  const saveHandler = useCallback(() => {
    // Neuladen um bearbeitete Einträge aus dem Vorgang zu entfernen, da diese schon im Backend aus dem Vorgang entfernt wurden
    if (id === VirtualArtikelListIds.NichtBeforzugterHauptlieferant) {
      save({ then: () => reload() })
    } else {
      save()
    }
  }, [save, reload, id])

  const onChangeKontext = (k: NeonKontext) => {
    replaceHistory(() => AppPaths.HauptlieferantPflegeFn(id, k, null))
  }

  const onChangeBetriebstyp = (bt: ArtikelBetriebstyp) => {
    replaceHistory(() => AppPaths.HauptlieferantPflegeFn(id, null, bt))
  }

  const handlePreisVorgangClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let preisVorgangTyp = null
      if (e.target.checked) {
        preisVorgangTyp = e.target.name
      }
      updModel({ preisVorgangTyp })
    },
    [updModel]
  )

  const onSearch = useCallback((lieferantenId, _preisEbeneId) => {
    setFilterLieferantId(lieferantenId)
    setPreisEbeneId(_preisEbeneId)
  }, [])

  const items = useMemo(() => {
    if (model.pflegeListe?.eintraege) {
      const filterFn = (eintrag: HauptlieferantPflegeEintragJson) => {
        if (eintrag.lieferanten == null || eintrag.lieferanten.length === 0) {
          // Hat keine, dann das melden
          return true
        }

        const hauptlieferant = eintrag.lieferanten.find(
          (lieferant) => lieferant.hauptlieferant && lieferant.preisEbeneId === null
        )

        const res =
          eintrag.lieferanten.find((lieferant) => {
            let match = filterLieferantId ? lieferant.lieferantId === filterLieferantId : true
            match = filters.filterWithoutHauptlieferant ? !hauptlieferant && match : match
            match = filters.filterWithAltHauptlieferant ? lieferant.preisEbeneId && match : match
            match = filters.filterPreisebene
              ? lieferant.preisEbeneId === preisEbeneId && match
              : match
            return match
          }) != null

        return res
      }
      const res = model.pflegeListe.eintraege.filter(filterFn)
      return res
    }
  }, [filterLieferantId, model, filters, preisEbeneId])

  const setEintraegeChanges = useCallback(
    (next: HauptlieferantPflegeEintragJson[]) => {
      updModel({ pflegeListe: { ...model.pflegeListe, eintraegeChanges: next } })
      setChangedItems(next)
    },
    [updModel, model]
  )

  const onSaveAndNavigate = useCallback(() => {
    save({
      then: () => {
        if (model.preisVorgangTyp === PreisVorgangTyp.EK_PREIS) {
          navigate(
            AppPaths.EkPreisAnlageFn({
              artikelListeRef: VirtualArtikelListIds.EkArtikelListe,
              kontext: model?.pflegeListe?.kontext
            })
          )
        } else {
          navigate(
            AppPaths.VkPreisAnlageFn({
              artikelListeRef: VirtualArtikelListIds.VkArtikelListe,
              kontext: model?.pflegeListe?.kontext
            })
          )
        }
      }
    })
  }, [save, model.preisVorgangTyp, model?.pflegeListe?.kontext, navigate])

  const showBody =
    model.pflegeListe?.eintraege?.length && (!isPIMModel || model?.pflegeListe?.kontext)

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.Dashboard}>
      <Frame space>
        <FrameRow>
          <HauptlieferantPflegeKopf
            lieferanten={model.lieferanten?.filter(Boolean) || []}
            preisEbenen={model.preisEbenen || []}
            kontext={model.pflegeListe?.kontext}
            betriebstyp={model.pflegeListe?.betriebstyp}
            onChangeKontext={onChangeKontext}
            onChangeBetriebstyp={onChangeBetriebstyp}
            onSearch={onSearch}
            filters={filters}
            onFilterChange={setFilters}
            isChanged={isChanged}
          />
        </FrameRow>
        <FrameBody>
          {showBody ? (
            <HauptlieferantPflegeTable
              eintraege={items}
              eintraegeChanges={changedItems}
              setEintraegeChanges={setEintraegeChanges}
              lieferanten={model.lieferanten?.filter(Boolean)}
              preisEbenen={model.preisEbenen}
              hauptlieferantId={filterLieferantId}
              selectedPreisEbeneId={preisEbeneId}
              silentUpd={silentUpd}
              onlyFavoredLieferant={VirtualArtikelListIds.NichtBeforzugterHauptlieferant === id}
            />
          ) : modelState === ModelState.loading /*|| wait*/ ? null : (
            <CardEx
              title={
                !isPIMModel || model?.pflegeListe?.kontext
                  ? 'Keine Artikel vorhanden'
                  : 'Bitte einen Kontext auswählen'
              }
            ></CardEx>
          )}
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {isWithEkPreis && (
              <Checkbox
                label="EK Preisliste anlegen"
                size="small"
                name={PreisVorgangTyp.EK_PREIS}
                checked={model.preisVorgangTyp === PreisVorgangTyp.EK_PREIS}
                onChange={handlePreisVorgangClick}
                color="default"
              />
            )}
            {isWithVkPreis && (
              <Checkbox
                label="VK Preisliste anlegen"
                size="small"
                name={PreisVorgangTyp.VK_PREIS}
                checked={model.preisVorgangTyp === PreisVorgangTyp.VK_PREIS}
                onChange={handlePreisVorgangClick}
                color="default"
              />
            )}
            <SaveButton
              onClickVoid={saveHandler}
              isNew={isNew}
              isChanged={isChanged && changedItems.length > 0}
            />
            <Button
              label={isNew ? 'Anlegen und zum Vorgang' : 'Speichern und zum Vorgang'}
              onClick={onSaveAndNavigate}
              variant="contained"
              color="primary"
              disabled={!(changedItems.length > 0 && model.preisVorgangTyp && (isNew || isChanged))}
            />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClick={onReload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
