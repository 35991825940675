import React, { useLayoutEffect, useRef } from 'react'
import { makeStyles } from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  root: {
    position: 'relative'
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'wait',
    '&>div': {
      cursor: 'default'
    }
  }
})

const minhs = {
  height: '100%',
  display: 'block',
  minHeight: '230px'
}

export type GlassPlaneProps = {
  show: boolean
  fullHeight?: boolean
  overlayColor?: string
  overlay?: React.ReactNode
  children: React.ReactNode
}

export const GlassPlane = ({
  show,
  fullHeight,
  overlay,
  overlayColor,
  children
}: GlassPlaneProps) => {
  const { classes } = useStyles()

  const ref = useRef<any>()

  useLayoutEffect(() => {
    if (show) {
      const old = document.activeElement as any
      const id = old?.id // Finde anhand id, react rendert ggf neu...
      ref.current.focus()
      return () => {
        if (id) {
          setTimeout(() => {
            const el = document.getElementById(id)
            if (el) {
              el.focus()
            } else if (old) {
              old.focus()
            }
          }, 250)
        }
      }
    }
    return () => {}
  }, [show])

  return (
    <span className={classes.root} style={show || fullHeight ? minhs : null}>
      {children}
      {show ? (
        <div
          ref={ref}
          data-name="glassplane"
          tabIndex={show ? -1 : undefined}
          className={classes.overlay}
          style={{ backgroundColor: overlayColor }}
        >
          {overlay}
        </div>
      ) : null}
    </span>
  )
}
