/* eslint-disable react/jsx-props-no-spreading */
import {QuestionMark} from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Grid, Icon} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import {Permission, PermissionUtil} from '@utils/ui/Permission'
import {aidOf, isString} from '@utils/utils'
import {MouseEventHandler, ReactElement, useEffect, useRef, useState} from 'react'
import isEqual from 'react-fast-compare'
import {Button} from './Button'
import {IconButton, IconButtonProps} from './IconButton'

export type SplitIconButtonAction = {
  name: string
  icon: ReactElement
  label: string
  to?: string
  onClick?: MouseEventHandler
  permission?: Permission
}

export type SplitIconButtonProps = {
  actions: SplitIconButtonAction[]
  disabled?: boolean
  size?: IconButtonProps['size']
  showLastUsed?: boolean
  withLabel?: boolean
  zIndex?: number
}

export const SplitIconButton = ({
  actions,
  size,
  disabled = false,
  showLastUsed = false,
  zIndex,
  withLabel
}: SplitIconButtonProps) => {
  const [lastActions, setLastActions] = useState([])
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!isEqual(actions, lastActions)) {
      setLastActions(actions)
      if (actions.length > 0) {
        if (showLastUsed) {
          if (actions.length !== lastActions.length || selectedIndex === -1) {
            setSelectedIndex(actions.findIndex((a: any) => PermissionUtil.isEnabled(a.permission)))
          }
        }
      } else {
        setSelectedIndex(-1)
      }
    }
  }, [actions, lastActions, selectedIndex, showLastUsed])

  const selectedAction = (selectedIndex >= 0 &&
    selectedIndex < lastActions.length &&
    lastActions[selectedIndex]) || {
    permission: Permission.DISABLED,
    icon: <QuestionMark />,
    text: 'Keine Aktion verfügbar'
  }

  const handleMenuItemClick = (index: number) => () => {
    if (showLastUsed) {
      setSelectedIndex(index)
    }
    setOpen(false)
    const action = lastActions[index]
    if (action.onClick) {
      action.onClick()
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    //@ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const buildIconButton = (action: SplitIconButtonAction, index: number) => (
    <IconButton
      key={aidOf(action)}
      size={size}
      icon={isString(action.icon) ? <Icon>{action.icon}</Icon> : action.icon}
      to={action.to ? action.to : null}
      onClick={handleMenuItemClick(index)}
      disabled={disabled || PermissionUtil.isDisabled(action.permission)}
      title={action.label}
    />
  )

  const buildIconButtonMenuItem = (action: SplitIconButtonAction, index: number) =>
    withLabel ? (
      <Button
        key={aidOf(action)}
        size={size == 'tiny' ? 'small' : size}
        startIcon={isString(action.icon) ? <Icon>{action.icon}</Icon> : action.icon}
        to={action.to ? action.to : null}
        onClick={handleMenuItemClick(index + 1)}
        disabled={disabled || PermissionUtil.isDisabled(action.permission)}
        label={action.label}
        fullWidth
        style={{ justifyContent: 'start' }}
      />
    ) : (
      buildIconButton(action, index + 1)
    )

  //@ts-ignore
  const menu = ({ TransitionProps = {}, placement = '' }) => (
    <Grow
      {...TransitionProps}
      style={{
        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
      }}
    >
      <Paper elevation={6}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container direction="column" padding={1}>
            {actions
              //@ts-ignore
              .filter((a, i) => i > 0)
              //@ts-ignore
              .map((action, index) => (
                <Grid key={index} item justifyItems="start">
                  {buildIconButtonMenuItem(action, index)}
                </Grid>
              ))}
          </Grid>
        </ClickAwayListener>
      </Paper>
    </Grow>
  )

  if (actions.length <= 1) {
    return buildIconButton(selectedAction, selectedIndex)
  }

  return (
    <div>
      <Grid container ref={anchorRef}>
        <Grid item>{buildIconButton(selectedAction, selectedIndex)}</Grid>
        <Grid item marginLeft={-1}>
          <IconButton
            name="link-menu-toggle"
            size={size}
            icon={<ArrowDropDownIcon />}
            onClick={handleToggle}
            title={selectedAction.text}
          />
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={zIndex && { zIndex }}
      >
        {menu}
      </Popper>
    </div>
  )
}
