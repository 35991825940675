import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant } from '@one/components/common/formatters'
import { usePreisEbenenCache } from '@one/datacaches/usePreisEbenenCache'
import { ListenpreisJson } from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { ThemeContext } from '@utils/ui/Theme'
import { useContext, useMemo } from 'react'

export interface ArtikelErpListenPreiseProps {
  listenPreise: ListenpreisJson[]
}

export const ArtikelErpListenPreise = ({ listenPreise }: ArtikelErpListenPreiseProps) => {
  const { darkMode } = useContext(ThemeContext)
  const { isAllianz } = useContext(AppContext)
  const { data: preisEbenen } = usePreisEbenenCache()

  const columns = useMemo(
    () =>
      [
        {
          field: 'lieferant.id',
          header: 'Lieferant',
          valueGetter: (row) => formatLieferant(row.lieferant),
          body: (row) => <LieferantCell lieferant={row.lieferant} asLink />
        },
        {
          type: 'date',
          field: 'gueltigVon',
          align: 'right',
          header: 'Gültig von'
        },
        {
          type: 'date',
          field: 'gueltigBis',
          align: 'right',
          header: 'Gültig bis'
        },
        {
          type: 'string',
          field: 'mengeneinheit',
          header: 'Mengeneinheit'
        },
        {
          type: 'number',
          field: 'preismenge',
          header: 'Preismenge'
        },
        {
          type: 'money2',
          field: 'listenpreis',
          header: 'Listenpreis'
        }
      ] as Column<ListenpreisJson>[],
    []
  )

  const grouper: RowGrouper<ListenpreisJson> = {
    field: 'lieferant.id',
    valueGetter: (row) => formatLieferant(row.lieferant),
    body: (row) => <LieferantCell lieferant={row.lieferant} asLink />
  }

  return (
    <CardEx name="ErpListenPreise" title="Listenpreise">
      <ScrollPanel autoHeight autoHeightMax="420px">
        <DataTable
          name="ErpListenPreiseTable"
          dense
          columns={columns}
          value={listenPreise}
          emptyMessage="Keine Daten"
          groupBy={grouper}
        />
      </ScrollPanel>
    </CardEx>
  )
}
