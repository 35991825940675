import { MenuItem } from '@mui/material'
import { useCallback } from 'react'

export type EnumItemType = {
  id: string
  text: string
  short?: string
  descr?: string
}

interface EnumType {
  [key: string]: EnumItemType
}

interface EnumRootType {
  [key: string]: EnumType
}

export type EnumRawItemType = {
  text: string
  short?: string
  descr?: string
}

interface EnumRawItemsType {
  [key: string]: EnumRawItemType | string
}

interface EnumRawType {
  [key: string]: EnumRawItemsType
}

interface EnumArrType {
  [key: string]: EnumItemType[]
}

const globalData = {
  data: {} as EnumRootType,
  dataArr: {} as EnumArrType
}

export type EnumFields = 'short' | 'text'

export type EnumTextGetter = (type: EnumInfo, id: string, field?: EnumFields) => string

export type EnumInfo = {
  name: string
  type: any
}

export type HkmEnumType = Record<string, EnumInfo>

export const validateEnums = (enums: HkmEnumType, data: any): string => {
  const errors = []

  Object.keys(enums).forEach((en) => {
    const { name, type } = enums[en]
    const items = data[name]
    if (items == null) {
      errors.push('Enum ' + name + ' fehlt')
    } else {
      Object.keys(type).forEach((it) => {
        const itemName = type[it]
        const entry = items[itemName]
        if (entry == null) {
          errors.push('Enum ' + name + ' ' + itemName + ' fehlt')
        }
      })
    }
  })

  Object.keys(data).forEach((name) => {
    const struct = data[name]
    const items = enums[name]
    if (items == null) {
      errors.push('Enum ' + name + ' existiert nicht in HkmEnum')
    } else {
      Object.keys(struct).forEach((it) => {
        const entry = Object.keys(items.type).find((x) => items.type[x] == it)
        if (entry == null) {
          errors.push('Enum ' + name + ' ' + it + ' existiert nicht in HkmEnum')
        }
      })
    }
  })

  return errors.length > 0 ? errors.join('\n') : undefined
}

export const setGlobalEnumData = (data: EnumRawType) => {
  const sd = data || {}

  globalData.data = Object.keys(sd).reduce((pc, type) => {
    const obj = sd[type]
    pc[type] = Object.keys(obj).reduce((pe, name) => {
      const val = obj[name]
      pe[name] = (
        typeof val === 'object' ? { ...val, id: name } : { text: val, id: name }
      ) as EnumItemType
      return pe
    }, {})
    return pc
  }, {})

  globalData.dataArr = Object.keys(globalData.data).reduce((pc, type) => {
    const obj = globalData.data[type]
    pc[type] = Object.keys(obj).map((name) => obj[name])
    return pc
  }, {})
}

const getEnums = (type: EnumInfo): EnumType => {
  const en = type && globalData.data[type.name]
  if (en == null) {
    console.error('Enum fehlt', type)
    return {}
  }
  return en
}

const getEnum = (type: EnumInfo, name?: string): EnumItemType => {
  if (name == null) {
    return { id: name, text: null }
  }
  const en = getEnums(type)[name]
  if (en == null) {
    console.error('Enum-Eintrag fehlt', type, name)
    return { id: name, text: name }
  }
  return en
}

const getEnumArr = (type: EnumInfo) => globalData.dataArr[type.name] || []

/**
 * Access enumeration texts
 *
 * @component
 */
export const useEnums = () => {
  /**
   * get enum text
   *
   * @param {string} type Name of enum
   * @param {string} import {  } from 'module'; Name of type
   * @return {string} enum text or key as fallback
   *
   * @memberof! useEnums
   */
  const et = useCallback((type: EnumInfo, id: string, field: EnumFields = 'text'): string => {
    return getEnum(type, id)[field]
  }, [])

  /**
   * get enum
   *
   * @param {string} type Name of enum
   * @param {string} id Name of type
   * @return {object} { id, text, ... } where "..." means any additional provided json field, text uses id as fallback
   *
   * @memberof! useEnums
   */
  const en = useCallback((type: EnumInfo, id: string) => {
    return getEnum(type, id)
  }, [])

  /**
   * build array of menu items
   *
   * @param {string} type Name of enum
   * @return {array} MenuItems
   *
   * @memberof! useEnums
   */
  const mi = useCallback(
    (type: EnumInfo, field: EnumFields = 'text') =>
      getEnumArr(type).map((e) => (
        <MenuItem key={e.id} value={e.id}>
          {e[field]}
        </MenuItem>
      )),
    []
  )

  /**
   * get enum items
   *
   * @param {string} type Name of enum
   * @return {array} enums with { id, text, ... } where "..." means any additional provided json field, text uses id as fallback
   *
   * @memberof! useEnums
   */
  const items = useCallback((type: EnumInfo) => {
    return getEnumArr(type)
  }, [])

  return {
    et,
    en,
    mi,
    items
  }
}
