import { api } from '@one/api'
import { DzMapperEinstellungTyp, MapperEinstellungElementJson } from '@one/typings/apiTypings'
import { DataCacheConfig, useDataCache } from '@utils/datacache'

const config: DataCacheConfig<MapperEinstellungElementJson> = {
  api,
  // refreshEvents: EventNames.ARSST_LOAD,
  datakey: 'arrst',
  rest: `/mapper/all?typ=${DzMapperEinstellungTyp.ARSST}`,
  method: 'GET',
  field: 'elemente'
}

export const useArtikelMapperEinstellungenCache = () => useDataCache(config)
