import { Grid } from '@mui/material'
import { MapperEinstellungElementJson } from '@one/typings/apiTypings'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { compareStrings } from '@utils/utils'

export type DiffAttrType = {
  attr: MapperEinstellungElementJson
  anz: number
}

export interface DiffSelectFieldProps {
  artikelMapperEinstellungen: MapperEinstellungElementJson[]
  value: number[]
  aenderungenIdx: Map<number, number>
  onChange: (value: number[]) => void
}

export const DiffSelectField = ({
  artikelMapperEinstellungen,
  aenderungenIdx,
  value,
  onChange
}: DiffSelectFieldProps) => {
  const options = artikelMapperEinstellungen
    ?.filter(Boolean)
    .map((attr) => {
      return { attr, anz: aenderungenIdx?.get(attr.attributeId) } as DiffAttrType
    })
    .filter((opt) => opt.anz > 0)
    .sort((a, b) => compareStrings(a.attr.name, b.attr.name))
    .sort((a, b) => b.anz - a.anz)

  return (
    <AutocompleteEx<DiffAttrType, number>
      multiple
      limitTags={4}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      options={options}
      emptyText="Einzelansicht"
      label="Feldvergleich"
      optionLabel="attr.name"
      renderItem={(opt) => (
        <Grid container>
          <Grid item flexGrow={1}>
            {opt.attr.name}
          </Grid>
          <Grid item fontSize="80%">
            #{opt.anz}
          </Grid>
        </Grid>
      )}
      optionValue={(opt) => opt.attr?.attributeId}
      fullWidth
    />
  )
}
