import {
  EkKonditionenEbeneJson,
  EkKonditionenJson,
  EkKonditionenRabattgruppeWertJson,
  EkKonditionenRabattVerwendung,
  EkKonditionenWertJson,
  EkPreiseBearbeitenJson,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'

// export interface PreisEbeneDisplayJsonEx extends PreisEbeneDisplayJson {
//   level?: number
//   parent?: PreisEbeneDisplayJsonEx
// }

export interface EkKonditionenDefinitionEx {
  waehrung?: string
  standortLabel: string
  standort1Label: string
  standort1Tip: string
  standort2Label: string
  standort2Tip: string
  rabattgruppeLabel: string
  staffelRabattgruppeLabel: string
  standortEkLabel: string
  staffelmengeTip: string
  listenpreisTip: string
  sonderpreisTip: string
  ekKondiFreiHausTip: string
  ekKondiFreiDiffTip: string
  standortEkTip: string
  staffelmengeLabel: string
  listenpreisLabel: string
  sonderpreisLabel: string
  ekKondiFreiHausLabel: string
  ekKondiFreiDiffLabel: string
  hauptstaffelLabel: string
  hauptstaffelTip: string
  sonderPreisZusch: string
  allianz: boolean

  zuschlAbs1?: boolean
  zuschlAbs1Label: string
  zuschlAbs1Tip: string

  zuschlAbs2?: boolean
  zuschlAbs2Label: string
  zuschlAbs2Tip: string

  zuschlAbs3?: boolean
  zuschlAbs3Label: string
  zuschlAbs3Tip: string

  zuschlAbs4?: boolean
  zuschlAbs4Label: string
  zuschlAbs4Tip: string

  zuschlAbs5?: boolean
  zuschlAbs5Label: string
  zuschlAbs5Tip: string

  rabattProz1?: boolean
  rabattProz1Label: string
  rabattProz1Tip: string

  rabattProz2?: boolean
  rabattProz2Label: string
  rabattProz2Tip: string

  rabattProz3?: boolean
  rabattProz3Label: string
  rabattProz3Tip: string

  rabattProz4?: boolean
  rabattProz4Label: string
  rabattProz4Tip: string

  rabattProz5?: boolean
  rabattProz5Label: string
  rabattProz5Tip: string
}
export interface EkKonditionenWertEdit extends EkKonditionenWertJson {
  standortEk?: boolean
  readonly?: boolean
  level?: number
  preisEbene?: PreisEbeneDisplayJson
  mengenEinheit?: string
  preismenge?: number
  standortEkOrg: boolean
  ekFreiHausOld?: number
  ekFreiHausDiff?: number
  sonderpreisOnly?: boolean
  effektiveRabattgruppeWerte?: EkKonditionenRabattgruppeWertJson
  // redundant
  ebeneRabattgruppeId?: number
  ebeneRabattVerwendung?: EkKonditionenRabattVerwendung
  ebeneStaffelgruppeId?: number
  ebeneDefaultstandort?: boolean
}

export interface EkKonditionenEbeneEdit extends EkKonditionenEbeneJson {
  preisEbene?: PreisEbeneDisplayJson
  effektiveRabattgruppeId?: number
  effektiveStaffelgruppeId?: number
  effektiveRabattstaffeln?: number[]
  staffelgruppeFlex?: boolean
  werte: EkKonditionenWertEdit[]
}
export interface EkKonditionenEdit extends EkKonditionenJson {
  konditionsEbenen?: EkKonditionenEbeneEdit[]
  warAbgeschlossen?: boolean
  sonderpreisRabattierbar?: boolean
  listenpreisEx?: number
  rf?: number
}

export interface EkPreiseBearbeitenEdit extends EkPreiseBearbeitenJson {
  warAbgeschlossen?: boolean
  neueKondi: EkKonditionenEdit
  vergangeneListe?: EkKonditionenEdit[]
  standort0: PreisEbeneDisplayJson
  kondiDefinition?: EkKonditionenDefinitionEx
  invalid?: boolean
}

export enum Ansicht {
  RABATTGRUPPE = 'RABATTGRUPPE',
  STANDORT = 'STANDORT',
  STAFFEL = 'STAFFEL'
}
