import { DarkMode, ExpandLess, ExpandMore, LightMode, ManageAccounts } from '@mui/icons-material'
import {
  Collapse,
  FormControlLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { useDataTableGlobals } from '@utils/ui/DataTable/DataTable'
import { SelectField } from '@utils/ui/fields/SelectField'
import { Switch } from '@utils/ui/fields/Switch'
import { ThemeContext } from '@utils/ui/Theme'
import { useContext, useState } from 'react'

const actionspos = [
  { id: 'left', text: 'Links' },
  { id: 'right', text: 'Rechts' }
]

export interface UserSettingsProps {
  menuHideMode?: boolean
  setMenuHideMode?: (e: any, checked: boolean) => void
}

const screenScales = [
  { id: 12, label: 'Klein' },
  { id: 14, label: 'Kompakt' },
  { id: 16, label: 'Normal' }
]

const labelWidth = {
  typography: { width: '130px' }
}

/**
 * Component that displays a header for a navigation drawer.
 */
export const UserSettings = ({
  menuHideMode = false,
  setMenuHideMode = null
}: UserSettingsProps) => {
  const { darkMode, setDarkMode, screenScale, setScreenScale } = useContext(ThemeContext)

  const { dataTableGlobalConfig, onDataTableGlobalConfigChange } = useDataTableGlobals()

  const [expanded, setExpanded] = useState(false)

  return (
    <Grid container width="100%" marginTop={-1}>
      <Grid item width="100%">
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <ManageAccounts
              fontSize="small"
              style={{ verticalAlign: 'middle', marginBottom: 2, marginRight: 4 }}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <Typography variant="subtitle1">Benutzereinstellungen</Typography>
          </Grid>
          <Grid item>
            <IconButton
              icon={expanded ? <ExpandLess /> : <ExpandMore />}
              onClick={() => {
                setExpanded(!expanded)
              }}
            />
          </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container spacing={1} paddingBottom={1}>
            <Grid item xs={12} alignSelf="start">
              <FormControlLabel
                control={
                  <SelectField
                    value={screenScale}
                    onChange={setScreenScale}
                    options={screenScales}
                    optionText="label"
                    notNull
                    size="small"
                  />
                }
                componentsProps={labelWidth}
                labelPlacement="start"
                label="Zoom"
              />
            </Grid>
            {setMenuHideMode && (
              <Grid item xs={12} alignSelf="start">
                <Switch
                  size="small"
                  checked={Boolean(menuHideMode)}
                  name="menustatic"
                  onChange={setMenuHideMode}
                  componentsProps={labelWidth}
                  labelPlacement="start"
                  label={menuHideMode ? 'Menü ausblenden' : 'Menü statisch'}
                />
              </Grid>
            )}
            <Grid item xs={12} alignSelf="start">
              <Switch
                size="small"
                checked={Boolean(dataTableGlobalConfig.striped)}
                name="striped"
                onChange={onDataTableGlobalConfigChange}
                componentsProps={labelWidth}
                labelPlacement="start"
                label="Tabellenstreifen"
              />
            </Grid>
            <Grid item xs={12} alignSelf="start">
              <Switch
                size="small"
                checked={Boolean(dataTableGlobalConfig.borders)}
                name="borders"
                onChange={onDataTableGlobalConfigChange}
                componentsProps={labelWidth}
                labelPlacement="start"
                label="Tabellenrahmen"
              />
            </Grid>
            <Grid item xs={12} alignSelf="start">
              <FormControlLabel
                control={
                  <SelectField
                    name="actionsPos"
                    value={dataTableGlobalConfig.actionsPos}
                    onChange={onDataTableGlobalConfigChange}
                    options={actionspos}
                    optionValue="id"
                    optionText="text"
                    notNull
                  />
                }
                componentsProps={labelWidth}
                labelPlacement="start"
                label="Tabellenaktionen"
              />
            </Grid>
            <Grid item xs={12} alignSelf="start" paddingTop={1}>
              <FormControlLabel
                control={
                  <ToggleButtonGroup
                    exclusive
                    value={darkMode ? 1 : 0}
                    onChange={(e, v) => {
                      if (v != null) {
                        setDarkMode(v === 1)
                      }
                    }}
                    size="small"
                    data-name="darkmodeswitch"
                  >
                    <ToggleButton value={0} data-name="lightmode">
                      <LightMode fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value={1}>
                      <DarkMode fontSize="small" data-name="darkmode" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                }
                componentsProps={labelWidth}
                labelPlacement="start"
                label="Nachtmodus"
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}
