import { TextField } from '@mui/material'
import { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'
import { makeStyles } from 'tss-react/mui'
import { autoCompleteOff } from './uiutils'

const useStyles = makeStyles()({
  numberformat: {
    width: '100%',
    '& input': {
      textAlign: 'right',
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 0
    }
  }
})

export interface NumberFormatProps {
  id?: string
  name?: string
  value?: number
  onChange?: (data?: any) => void
  disabled?: boolean
  thousandSeparator?: string | boolean
  decimalSeparator?: string
  fraction?: number
  minValue?: number
  maxValue?: number
  suffix?: any
  variant?: any
  autoComplete?: string
  dataName?: string
  placeholder?: string
}

export const NumberFormat = forwardRef(
  (
    {
      id,
      name,
      value,
      onChange,
      disabled,
      fraction,
      minValue,
      maxValue,
      suffix,
      thousandSeparator,
      decimalSeparator,
      variant,
      autoComplete = autoCompleteOff,
      dataName,
      placeholder
    }: NumberFormatProps,
    ref
  ) => {
    const { classes } = useStyles()

    const onValueChange = (values) => {
      const value = values.value == null || values.value.length === 0 ? null : values.floatValue
      onChange({
        name,
        value,
        target: {
          name,
          value
        }
      })
    }

    const isAllowed = (values) => {
      const val = Number(values.value)
      return (
        values.formattedValue === '-' ||
        ((minValue == null || val >= minValue) && (maxValue == null || val <= maxValue))
      )
    }

    return (
      <NumericFormat
        id={id}
        getInputRef={ref}
        className={classes.numberformat}
        decimalScale={fraction}
        fixedDecimalScale
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        value={value}
        // isNumericString
        allowNegative={minValue == null || minValue < 0}
        onValueChange={onValueChange}
        customInput={TextField}
        isAllowed={isAllowed}
        suffix={suffix == null ? '' : suffix}
        disabled={disabled}
        variant={variant}
        autoComplete={autoComplete}
        inputProps={{ 'data-name': dataName }}
        placeholder={placeholder}
      />
    )
  }
)

NumberFormat.displayName = 'NumberFormat'
