import { Search } from '@mui/icons-material'
import { Button, ButtonProps } from './Button'

export type SearchButtonProps = {
  visible?: ButtonProps['visible']
  disabled?: ButtonProps['disabled']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  // size?: ButtonProps['size']
  autoFocus?: ButtonProps['autoFocus']
}

export const SearchButton = ({ ...props }: SearchButtonProps) => (
  <Button {...props} label="Suchen" variant="text" color="primary" StartIcon={Search} />
)
