import { Column } from './DataTable'
import { addColumnFieldWrapper, excludeColumns } from './DataTableUtils'

export interface AddColumnToIndex<T> {
  beforeField?: string | true // relative to the default columns
  afterField?: string | true // relative to the default columns
  column: Column<T>
}

export interface FieldWrapper {
  wrapper: string
  excludeByField?: (string | string[])[]
}

export interface DefaultColumnsProps<T = any> {
  removeDefaultsByField?: (string | string[] | RegExp)[]
  fieldWrapper?: FieldWrapper
  addColumnToIndex?: AddColumnToIndex<T>[]
  additionalColumns?: Column<T>[]
}

export interface ComputeColumns<T> extends DefaultColumnsProps<T> {
  defaultColumns: Column<T>[]
}

export const computeColumns = <T extends any>({
  defaultColumns,
  addColumnToIndex,
  fieldWrapper,
  removeDefaultsByField,
  additionalColumns = []
}: ComputeColumns<T>) => {
  let columns = [...defaultColumns]

  if (removeDefaultsByField) {
    columns = excludeColumns(columns, removeDefaultsByField)
  }

  if (fieldWrapper) {
    columns = addColumnFieldWrapper(columns, fieldWrapper.wrapper, fieldWrapper.excludeByField)
  }

  if (addColumnToIndex) {
    addColumnToIndex.filter(Boolean).forEach((item) => {
      const fr = item.beforeField || item.afterField
      let index = typeof fr === 'string' ? columns.findIndex((col) => col.field === fr) : -2
      if (index === -1) {
        console.error('beforeField not found', item.beforeField, columns)
      }
      if (item.beforeField) {
        if (item.beforeField === true) {
          index = 0
        }
        if (index >= 0 && index < columns.length) {
          columns.splice(index, 0, item.column)
        } else {
          columns.push(item.column)
        }
      } else {
        if (index >= 0 && index < columns.length - 1) {
          columns.splice(index + 1, 0, item.column)
        } else {
          columns.push(item.column)
        }
      }
    })
  }

  return [...columns, ...additionalColumns]
}
