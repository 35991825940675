import { PimArtikelJson, PimArtikelVersionDisplayJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface ArtikelPimDisplayArtikelProps {
  artikel: PimArtikelJson
}

export const ArtikelPimDisplayArtikel = ({ artikel }: ArtikelPimDisplayArtikelProps) => {
  if (!artikel || !artikel.version || !artikel?.version?.displayArtikel) {
    return null
  }
  const columns: Column<PimArtikelVersionDisplayJson>[] = [
    {
      field: 'hageNummer',
      header: 'hage-Nr.'
    },
    {
      field: 'menge',
      header: 'Menge'
    },
    {
      field: 'ek',
      header: 'EK'
    }
  ]

  return (
    <CardEx title="Display-Artikel" height="100%" name="Displayartikel">
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimProduktLieferanten"
          columns={columns}
          value={artikel.version.displayArtikel}
          emptyMessage="Keine Daten"
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}
