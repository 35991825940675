import { green, grey, red } from '@mui/material/colors'
import { HkmEnum } from '@one/enums/HkmEnum'
import { SynchronisationStatus } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Medal } from '@utils/ui/fields/Medal'

const getStatusColor = (status: SynchronisationStatus): string => {
  switch (status) {
    case SynchronisationStatus.AKTIVIERT:
      return green[100]
    case SynchronisationStatus.AKTIVIERUNG_FEHLGESCHLAGEN:
      return red[100]
    case SynchronisationStatus.DEAKTIVIERT:
      return grey[100]
  }
}
export interface SynchronisationStatusCellProps {
  value: SynchronisationStatus
}

export const SynchronisationStatusCell = ({ value }: SynchronisationStatusCellProps) => {
  const { et } = useEnums()

  return (
    <Medal
      text={et(HkmEnum.SynchronisationStatus, value)}
      backgroundColor={getStatusColor(value)}
    />
  )
}
