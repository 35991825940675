/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AboArtikelAttrChangeJson {
  /** @format int32 */
  id?: number
  newString?: string
  oldString?: string
  path?: string
  zusatz?: boolean
}

export enum AboArtikelEanGruppeStatus {
  EINGESCHL = 'EINGESCHL',
  AUSGESCHL = 'AUSGESCHL',
  UNBESTIMMT = 'UNBESTIMMT'
}

export interface AboArtikelEanGruppeStatusJson {
  eanGruppe?: EANGruppeDisplayJson
  /** @format int64 */
  id?: number
  status?: AboArtikelEanGruppeStatus
}

export interface AboLieferantAnzeigenJson {
  ekKonditionenDefinition?: EkKonditionenDefinitionJson
  fachSchluessel?: string
  importError?: ImportErrorJson
  lieferant?: DzLieferantJson
  lieferantAbo?: LieferantAboDisplayJson
  manuelleListen?: LieferantenListeDisplayJson[]
  revisions?: AuditRevisionInfoJson[]
  state?: UseCaseStateJson
  /** @format int64 */
  zeLieferantId?: number
}

export interface AboQuelleJson {
  /** @format int64 */
  id?: number
  quelle?: string
}

export interface AboQuellewechselJson {
  nach?: AboQuelleJson
  von?: AboQuelleJson
}

export interface ActionInfoJson {
  enabled?: boolean
  info?: string
  visible?: boolean
}

export interface AllianzBearbeitenJson {
  allianz?: AllianzJson
  state?: UseCaseStateJson
}

export interface AllianzBeitrittJson {
  /** @format int32 */
  artikelQwCount?: number
  /** @format int32 */
  artikelQwErrorCount?: number
  audit?: HKMAuditJson
  beitritt?: boolean
  /** @format int64 */
  id?: number
  /** @format int32 */
  lieferantenQwCount?: number
  /** @format int32 */
  version?: number
}

export interface AllianzDatenBereitstellungArtikelJson {
  /** @format int64 */
  id?: number
}

export interface AllianzDatenBereitstellungCriteriaJson {
  /** @format date */
  start?: string
}

export interface AllianzDatenBereitstellungDisplayJson {
  /** @format int32 */
  aenArtikelCount?: number
  /** @format int32 */
  aenLieferantenCount?: number
  audit?: HKMAuditJson
  /** @format int32 */
  delArtikelCount?: number
  /** @format int32 */
  delLieferantenCount?: number
  /** @format date-time */
  end?: string
  /** @format int64 */
  id?: number
  /** @format int32 */
  neuArtikelCount?: number
  /** @format int32 */
  neuEkKonditionenListeCount?: number
  /** @format int32 */
  neuEkKonditionenRabattgruppeCount?: number
  /** @format int32 */
  neuLieferantenCount?: number
  /** @format int32 */
  qwArtikelCount?: number
  /** @format int32 */
  qwLieferantenCount?: number
  /** @format date-time */
  start?: string
  status?: AsyncTaskStatus
}

export interface AllianzDatenBereitstellungJson {
  artikel?: AllianzDatenBereitstellungArtikelJson[]
  bereistellung?: AllianzDatenBereitstellungDisplayJson
  state?: UseCaseStateJson
}

export interface AllianzDatenBereitstellungSearcherJson {
  criteria?: AllianzDatenBereitstellungCriteriaJson
  limited?: boolean
  result?: AllianzDatenBereitstellungDisplayJson[]
}

export interface AllianzDatenBereitstellungUebersichtJson {
  searcher?: AllianzDatenBereitstellungSearcherJson
  state?: UseCaseStateJson
}

export interface AllianzDatenUmschaltenJson {
  /** @format int64 */
  allianzId?: number
  /** @format int64 */
  lieferantId?: number
  /** @format int64 */
  preisEbeneId?: number
}

export interface AllianzDatenUmschaltungDisplayJson {
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
  preisEbene?: PreisEbeneDisplayJson
  task?: AsyncTaskJson
}

export interface AllianzDatenUmschaltungJson {
  state?: UseCaseStateJson
  umschaltung?: AllianzDatenUmschaltungDisplayJson
}

export enum AllianzDatenValidierenType {
  Bereitstellung = 'bereitstellung',
  Erpexport = 'erpexport'
}

export interface AllianzJson {
  allePreisEbenen?: PreisEbeneDisplayJson[]
  audit?: HKMAuditJson
  bemerkung?: string
  /** @format int64 */
  defaultPreisEbeneId?: number
  /** @format int64 */
  id?: number
  lieferanten?: AllianzLieferantJson[]
  mitglieder?: AllianzMitgliedDisplayJson[]
  name?: string
  /** @format int64 */
  stammEbeneId?: number
  /** @format int32 */
  version?: number
}

export interface AllianzLieferantJson {
  bereitgestellt?: boolean
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
}

export interface AllianzMitgliedBearbeitenJson {
  allePreisEbenen?: PreisEbeneDisplayJson[]
  alleStandorte?: StandortJson[]
  allianzBeitritt?: AllianzBeitrittJson
  allianzMitglied?: AllianzMitgliedJson
  state?: UseCaseStateJson
}

export interface AllianzMitgliedDisplayJson {
  audit?: HKMAuditJson
  defaultStandort?: StandortJson
  firma?: GesellschafterDisplayJson
  /** @format int64 */
  id?: number
}

export interface AllianzMitgliedJson {
  /** @format int64 */
  allianzId?: number
  audit?: HKMAuditJson
  defaultStandort?: StandortJson
  firma?: GesellschafterDisplayJson
  /** @format int64 */
  id?: number
  lieferanten?: AllianzMitgliedLieferantJson[]
  /** @format int32 */
  version?: number
}

export interface AllianzMitgliedLieferantJson {
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  preisEbene?: PreisEbeneDisplayJson
}

export interface AllianzPreisEbenenAnzeigenJson {
  preisEbenen?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
}

export interface AllianzVerteilerMatrixBearbeitenJson {
  hagebauLogistikILN?: string
  lieferanten?: LieferantDisplayJson[]
  matrix?: AllianzVerteilerMatrixJson
  preisebenen?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
}

export interface AllianzVerteilerMatrixJson {
  eintraege?: AllianzVerteilerMatrixMitgliedJson[]
}

export interface AllianzVerteilerMatrixMitgliedJson {
  lieferanten?: Record<string, AllianzVerteilerMatrixMitgliedLieferantJson>
  mitglied?: AllianzMitgliedDisplayJson
}

export interface AllianzVerteilerMatrixMitgliedLieferantJson {
  /** @format int64 */
  id?: number
  /** @format int64 */
  preisEbeneId?: number
  /** @format int64 */
  seLieferantId?: number
}

export interface AppInfoJson {
  envName?: string
  version?: string
}

export interface ArtikelAboArtikelDisplayJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  archiviert?: boolean
  artikelArt?: ArtikelArtDisplayJson
  betriebsTyp?: ArtikelBetriebstyp
  btNummer?: string
  displayArtikelListe?: string[]
  ean?: string
  eanIgnoriert?: boolean
  eigenlistung?: boolean
  hageNummer?: string
  hageWarengruppe?: WarengruppeDisplayJson
  hauptlieferant?: LieferantDisplayTinyJson
  /** @format int64 */
  id?: number
  importFehlerData?: boolean
  importFehlerPreiseEk?: boolean
  importFehlerPreiseVk?: boolean
  industrieArtikelNummer?: string
  kontexte?: NeonKontext[]
  kontexteFehlerArtikel?: NeonKontext[]
  kontexteFehlerEkPreis?: NeonKontext[]
  kontexteFehlerVkPreis?: NeonKontext[]
  labels?: number[]
  lagerME?: MengeneinheitDisplayTinyJson
  lieferantIAN?: string
  minEinLager?: boolean
  pimArtikelArt?: PimArtikelArt
  quelle?: ArtikelQuelle
  sparte?: SparteDisplayJson
  typ?: SuchArtikelTyp
  warengruppe?: WarengruppeDisplayJson
}

export interface ArtikelAboBearbeitenJson {
  abo?: ArtikelAboJson
  artikelSyncEinstList?: SyncEinstellungDisplayJson[]
  eanGruppen?: EANGruppeJson[]
  kondiDefinition?: EkKonditionenDefinitionJson
  lieferantSyncEinstList?: SyncEinstellungDisplayJson[]
  state?: UseCaseStateJson
  supportsEanGruppen?: boolean
}

export interface ArtikelAboDisplayJson {
  /** @format int64 */
  artikelListeId?: number
  betriebsTyp?: ArtikelBetriebstyp
  /** @format int64 */
  countArtikel?: number
  /** @format int64 */
  countSelektionen?: number
  /** @format int64 */
  id?: number
  /** @format date-time */
  letztePflege?: string
  /** @format date-time */
  letzteVerteilung?: string
  lieferant?: LieferantDisplayJson
  name?: string
}

export interface ArtikelAboJson {
  /** @format int64 */
  artikelListeId?: number
  audit?: HKMAuditJson
  bemerkung?: string
  bt?: ArtikelBetriebstyp
  dzLieferant?: LieferantDisplayJson
  /** @format int64 */
  eanGruppeId?: number
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  lieferantAboStatus?: LieferantAbonniertStatus
  /** @format int64 */
  lieferantSyncEinstId?: number
  name?: string
  selektionen?: ArtikelSelektionJson[]
  /** @format int32 */
  version?: number
}

export interface ArtikelAboSearcherArtikelAboCriteriaJson {
  lieferant?: LieferantDisplayJson
  name?: string
}

export interface ArtikelAboSearcherJson {
  criteria?: ArtikelAboSearcherArtikelAboCriteriaJson
  limited?: boolean
  result?: ArtikelAboDisplayJson[]
}

export interface ArtikelAboUebersichtJson {
  searcher?: ArtikelAboSearcherJson
  state?: UseCaseStateJson
}

export enum ArtikelAbonniertStatus {
  EINGESCHL = 'EINGESCHL',
  AUSGESCHL = 'AUSGESCHL',
  UNBESTIMMT = 'UNBESTIMMT',
  UNTERBROCHEN = 'UNTERBROCHEN'
}

export interface ArtikelAenderungJson {
  aenderungsStatus?: ArtikelAenderungStatus
  aenderungsTyp?: ArtikelAenderungTyp
  /** @format int64 */
  id?: number
  isNichtMehrSelektiert?: boolean
  quellenwechsel?: AboQuellewechselJson
  unbestaetigt?: boolean
}

export enum ArtikelAenderungStatus {
  BEARBEITET = 'BEARBEITET',
  OFFEN = 'OFFEN',
  ESKALIERT = 'ESKALIERT',
  UEBERSPRUNGEN = 'UEBERSPRUNGEN',
  VERALTET = 'VERALTET',
  NICHT_MEHR_VORHANDEN = 'NICHT_MEHR_VORHANDEN'
}

export enum ArtikelAenderungTyp {
  NEU = 'NEU',
  GEAENDERT = 'GEAENDERT',
  AUSGELISTET = 'AUSGELISTET',
  KONFLIKT_DZ = 'KONFLIKT_DZ'
}

export interface ArtikelAnzeigenJson {
  dz?: DzArtikelAnzeigenJson
  pim?: PimArtikelAnzeigenJson
  state?: UseCaseStateJson
  ze?: ZeArtikelAnzeigenJson
}

export interface ArtikelArtAnzeigenJson {
  result?: ArtikelArtJson[]
  state?: UseCaseStateJson
}

export interface ArtikelArtDisplayJson {
  bez?: string
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  kurz?: string
}

export interface ArtikelArtJson {
  bez?: string
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  kurz?: string
  name?: string
}

export interface ArtikelBearbeitungsEintragJson {
  artikel?: ArtikelDisplayJson
  /** @format int64 */
  id?: number
  keinePreispflege?: boolean
  status?: AuswahlStatus
}

export interface ArtikelBearbeitungsListeBearbeitenJson {
  liste?: ArtikelBearbeitungsListeJson
  state?: UseCaseStateJson
}

export interface ArtikelBearbeitungsListeJson {
  artikelListe?: ArtikelListeDisplayJson
  artikelListeFix?: boolean
  artikelListeRef?: string
  bemerkung?: string
  bt?: ArtikelBetriebstyp
  doNotAdd?: boolean
  eintraege?: ArtikelBearbeitungsEintragJson[]
  eintraegeChanges?: ArtikelBearbeitungsEintragJson[]
  einzelhandel?: boolean
  /** @format int64 */
  ekPreisListenId?: number
  /** @format date */
  gueltigAb?: string
  /** @format date */
  gueltigAbMax?: string
  /** @format date */
  gueltigAbMin?: string
  ignorierbar?: boolean
  kontext?: NeonKontext
  lieferant?: LieferantDisplayJson
  lieferantFix?: boolean
  name?: string
  /** @format int64 */
  preisEbeneId?: number
  vergangenheitOk?: boolean
  vkPreisListenIds?: number[]
}

export enum ArtikelBetriebstyp {
  UNBEKANNT = 'UNBEKANNT',
  EH010 = 'EH_010',
  EH015 = 'EH_015',
  EH027 = 'EH_027',
  EH427 = 'EH_427',
  EH028 = 'EH_028',
  EH030 = 'EH_030',
  EH035 = 'EH_035',
  EL040 = 'EL_040',
  EL041 = 'EL_041',
  EL042 = 'EL_042',
  EL043 = 'EL_043',
  EL044 = 'EL_044',
  EL045 = 'EL_045',
  EL046 = 'EL_046',
  EL047 = 'EL_047',
  EL097 = 'EL_097',
  EL447 = 'EL_447',
  EL048 = 'EL_048',
  EL049 = 'EL_049',
  EH037 = 'EH_037',
  EH337 = 'EH_337',
  EH437 = 'EH_437',
  ZE050 = 'ZE_050',
  EH093 = 'EH_093',
  FH099 = 'FH_099',
  FH499 = 'FH_499',
  FH098 = 'FH_098',
  EH430 = 'EH_430',
  ZE920 = 'ZE_920',
  ZE930 = 'ZE_930',
  ZE940 = 'ZE_940',
  XX998 = 'XX_998',
  XX999 = 'XX_999',
  DP = 'DP',
  PIM = 'PIM'
}

export interface ArtikelBetriebstypChangeJson {
  betriebstyp?: ArtikelBetriebstyp
  seArtikelIds?: number[]
}

export interface ArtikelCriteriaNummernJson {
  betriebsTyp?: ArtikelBetriebstyp
  /** @format int64 */
  lieferantId?: number
  numbers?: ArtikelNummerParams
}

export interface ArtikelDatenpoolDisplayJson {
  definitionOfStandardDeliveryUnit1?: string
  additionalGtin?: string
  articleType?: string
  artikelDpMengeneinheiten?: Record<string, ArtikelDatenpoolMengeneinheit>
  definitionBaseUnitOfMeasure?: string
  einkaufMeFh?: string
  einkaufMeName?: string
  factoryPriceAT?: number
  factoryPriceDE?: number
  factoryPriceInternational?: number
  factoryPriceLUX?: number
  gln?: string
  gtin?: string
  hageNummer?: string
  heightOfUnitMeasureBUM?: string
  /** @format int64 */
  id?: number
  lan?: string
  lengthOfUnitMeasureBUM?: string
  lieferant?: LieferantDisplayTinyJson
  lieferantWarenGruppe?: string
  minimumOrderQuantityFH?: string
  priceBasisFH?: number
  priceValidityFactoryPrice?: string
  seEinkaufMe?: MengeneinheitDisplayTinyJson
  statisticalCode?: string
  supplierArticleDescription?: string
  supplierCountryOfOrigin?: string
  supplierDistributionChannel?: string
  supplierID?: string
  taxIndicatorAT?: string
  taxIndicatorDE?: string
  taxIndicatorLUX?: string
  uebernahmeInfo?: ArtikelDatenpoolUebernahmeInfoJson
  weightOfUnitMeasureBUM?: string
  widthOfUnitMeasureBUM?: string
}

export interface ArtikelDatenpoolKeyJson {
  hageNummer?: string
  supplierID?: string
}

export interface ArtikelDatenpoolMengeneinheit {
  baseUnitFactor?: number
  heightOfUnitMeasureBum?: string
  lengthOfUnitMeasureBum?: string
  name?: string
  smallestItemUnit?: boolean
  weightOfUnitMeasureBum?: string
  widthOfUnitMeasureBum?: string
}

export interface ArtikelDatenpoolSearcherCriteriaJson {
  gtin?: string
  hageNummer?: string
  lan?: string
  lieferant?: LieferantDisplayJson
  lieferantArtikelBez?: string
  lieferantWarenGruppe?: string
  /** @format int32 */
  maxResults?: number
  volltext?: string
}

export interface ArtikelDatenpoolSearcherJson {
  criteria?: ArtikelDatenpoolSearcherCriteriaJson
  limited?: boolean
  result?: ArtikelDatenpoolDisplayJson[]
}

export interface ArtikelDatenpoolUebernahmeBearbeitenJson {
  artikelArten?: ArtikelArtJson[]
  /** @format int64 */
  defaultArtikelArtId?: number
  defaultBetriebstyp?: ArtikelBetriebstyp
  /** @format int64 */
  defaultEanGruppeId?: number
  defaultKontext?: NeonKontext
  defaultPimArtikelArt?: PimArtikelArt
  /** @format int64 */
  defaultWarengruppeId?: number
  eanGruppen?: EANGruppeJson[]
  eintraege?: ArtikelDatenpoolUebernahmeJson[]
  state?: UseCaseStateJson
}

export interface ArtikelDatenpoolUebernahmeChangeJson {
  /** @format int64 */
  artikelArtId?: number
  bt?: ArtikelBetriebstyp
  /** @format int64 */
  eanGruppeId?: number
  keyList?: ArtikelDatenpoolKeyJson[]
  kontext?: NeonKontext
  pimArtikelArt?: PimArtikelArt
  /** @format int64 */
  sparteId?: number
  /** @format int64 */
  warengruppeId?: number
}

export interface ArtikelDatenpoolUebernahmeInfoJson {
  artikel?: ArtikelDisplayTinyJson
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
}

export interface ArtikelDatenpoolUebernahmeJson {
  artikel?: ArtikelDisplayTinyJson[]
  audit?: HKMAuditJson
  dpArtikel?: ArtikelDatenpoolDisplayJson
  einkaufME?: MengeneinheitDisplayTinyJson
  fehler?: MessageJson
  lieferant?: LieferantDisplayTinyJson
  status?: ArtikelDatenpoolUebernahmeStatus
}

export enum ArtikelDatenpoolUebernahmeStatus {
  ANLAGE = 'ANLAGE',
  AKTUALISIEREN = 'AKTUALISIEREN',
  UNVERAENDERT = 'UNVERAENDERT',
  FEHLER = 'FEHLER'
}

export interface ArtikelDatenpoolUebersichtJson {
  searcher?: ArtikelDatenpoolSearcherJson
  state?: UseCaseStateJson
}

export interface ArtikelDisplayJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  /** @format int64 */
  aboArtikelId?: number
  aboStatus?: ArtikelAbonniertStatus
  archiviert?: boolean
  artikelArt?: ArtikelArtDisplayJson
  betriebsTyp?: ArtikelBetriebstyp
  bontext?: string
  breite?: number
  btNummer?: string
  ean?: string
  eanIgnoriert?: boolean
  eigenlistung?: boolean
  gewicht?: number
  hageNummer?: string
  hauptlieferant?: LieferantDisplayTinyJson
  hoehe?: number
  /** @format int64 */
  id?: number
  importFehlerData?: boolean
  importFehlerPreiseEk?: boolean
  importFehlerPreiseVk?: boolean
  industrieArtikelNummer?: string
  kontexte?: NeonKontext[]
  kontexteFehlerArtikel?: NeonKontext[]
  kontexteFehlerEkPreis?: NeonKontext[]
  kontexteFehlerVkPreis?: NeonKontext[]
  labels?: number[]
  laenge?: number
  lagerME?: MengeneinheitDisplayTinyJson
  lieferant?: LieferantDisplayJson
  lieferantAusgeschlossen?: boolean
  lieferantIAN?: string
  matchcode?: string
  minEinLager?: boolean
  pimArtikelArt?: PimArtikelArt
  quelle?: ArtikelQuelle
  /** @format int64 */
  seArtikelId?: number
  sparte?: SparteDisplayJson
  typ?: SuchArtikelTyp
  warengruppe?: WarengruppeDisplayJson
}

export interface ArtikelDisplayResonseJson {
  items?: ArtikelDisplayJson[]
  state?: UseCaseStateJson
}

export interface ArtikelDisplaySmallJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  archiviert?: boolean
  artikelArt?: ArtikelArtDisplayJson
  betriebsTyp?: ArtikelBetriebstyp
  btNummer?: string
  ean?: string
  eanIgnoriert?: boolean
  eigenlistung?: boolean
  hageNummer?: string
  hauptlieferant?: LieferantDisplayTinyJson
  /** @format int64 */
  id?: number
  importFehlerData?: boolean
  importFehlerPreiseEk?: boolean
  importFehlerPreiseVk?: boolean
  industrieArtikelNummer?: string
  kontexte?: NeonKontext[]
  kontexteFehlerArtikel?: NeonKontext[]
  kontexteFehlerEkPreis?: NeonKontext[]
  kontexteFehlerVkPreis?: NeonKontext[]
  labels?: number[]
  lagerME?: MengeneinheitDisplayTinyJson
  minEinLager?: boolean
  pimArtikelArt?: PimArtikelArt
  quelle?: ArtikelQuelle
  sparte?: SparteDisplayJson
  typ?: SuchArtikelTyp
  warengruppe?: WarengruppeDisplayJson
}

export interface ArtikelDisplayTinyJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  archiviert?: boolean
  artikelArt?: ArtikelArtDisplayJson
  betriebsTyp?: ArtikelBetriebstyp
  btNummer?: string
  ean?: string
  eanIgnoriert?: boolean
  eigenlistung?: boolean
  hageNummer?: string
  /** @format int64 */
  id?: number
  importFehlerData?: boolean
  importFehlerPreiseEk?: boolean
  importFehlerPreiseVk?: boolean
  industrieArtikelNummer?: string
  kontexte?: NeonKontext[]
  kontexteFehlerArtikel?: NeonKontext[]
  kontexteFehlerEkPreis?: NeonKontext[]
  kontexteFehlerVkPreis?: NeonKontext[]
  pimArtikelArt?: PimArtikelArt
  quelle?: ArtikelQuelle
  typ?: SuchArtikelTyp
}

export interface ArtikelEANJson {
  ean?: string
  eanGruppe?: EANGruppeDisplayJson
  eanZuordnung?: EanZuordnung
  geloescht?: boolean
  hauptEAN?: boolean
  /** @format int64 */
  id?: number
}

export interface ArtikelEkPreiseJson {
  artikelRabattgruppe?: EkKonditionenRabattgruppeKopfJson
  ebeneRabattgruppe?: EkKonditionenRabattgruppeKopfJson
  einheit?: string
  /** @format int64 */
  eintragId?: number
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  /** @format int64 */
  preisEbeneId?: number
  werte?: EkPreisWertJson[]
}

export interface ArtikelLabelByTypeZuordnungJson {
  labelIds?: number[]
  type?: EkPreisListeImportDefinitionStepLabelsType
}

export interface ArtikelLabelSearcherCriteriaJson {
  todo?: string
}

export interface ArtikelLabelSearcherJson {
  criteria?: ArtikelLabelSearcherCriteriaJson
  limited?: boolean
  result?: LabelJson[]
}

export interface ArtikelLabelUebersichtJson {
  searcher?: ArtikelLabelSearcherJson
  state?: UseCaseStateJson
}

export interface ArtikelLieferantJson {
  ausgelistet?: boolean
  einkaufMengenEinheit?: string
  einkaufMengenEinheitBasis?: string
  einkaufMengenEinheitBasisFaktor?: BruchJson
  einkaufPreismenge?: number
  hauptLieferant?: boolean
  /** @format int64 */
  id?: number
  industrieArtikelNummer?: string
  keinePreispflege?: boolean
  lieferant?: LieferantDisplayJson
}

export interface ArtikelListeBearbeitenJson {
  artikelListe?: ArtikelListeJson
  state?: UseCaseStateJson
}

export interface ArtikelListeDisplayJson {
  /** @format int32 */
  artikelAnzahl?: number
  /** @format int64 */
  id?: number
  name?: string
  /** @format int64 */
  ownerId?: number
  typ?: ArtikelListeTyp
}

export interface ArtikelListeJson {
  artikel?: ArtikelDisplayJson[]
  audit?: HKMAuditJson
  bt?: ArtikelBetriebstyp
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  name?: string
  /** @format int64 */
  ownerId?: number
  typ?: ArtikelListeTyp
  /** @format int32 */
  version?: number
}

export interface ArtikelListeSearcherCriteriaJson {
  kontext?: NeonKontext
  name?: string
  typ?: ArtikelListeTyp
}

export interface ArtikelListeSearcherJson {
  criteria?: ArtikelListeSearcherCriteriaJson
  limited?: boolean
  result?: ArtikelListeDisplayJson[]
}

export enum ArtikelListeTyp {
  ABO = 'ABO',
  MANUAL = 'MANUAL',
  ARTIKEL_SELEKTION = 'ARTIKEL_SELEKTION',
  ANGEFORDERT = 'ANGEFORDERT',
  LABEL = 'LABEL',
  ARTIKEL_OHNE_HAUPTLIEFERANTEN = 'ARTIKEL_OHNE_HAUPTLIEFERANTEN',
  ARTIKEL_MIT_GESPERRTEN_HAUPTLIEFERANTEN = 'ARTIKEL_MIT_GESPERRTEN_HAUPTLIEFERANTEN',
  ARTIKEL_HAUPTLIEFERANT_EK = 'ARTIKEL_HAUPTLIEFERANT_EK',
  ARTIKEL_HAUPTLIEFERANT_VK = 'ARTIKEL_HAUPTLIEFERANT_VK',
  ARTIKEL_PREISPFLEGE_VK = 'ARTIKEL_PREISPFLEGE_VK',
  ARTIKEL_PREISPFLEGE_EK = 'ARTIKEL_PREISPFLEGE_EK',
  ARTIKEL_PREISPFLEGE_EK_KOND = 'ARTIKEL_PREISPFLEGE_EK_KOND',
  ARTIKEL_PREISPFLEGE_EK_RG = 'ARTIKEL_PREISPFLEGE_EK_RG',
  ARTIKEL_PREISPFLEGE_EK_QW = 'ARTIKEL_PREISPFLEGE_EK_QW',
  ARTIKEL_ANDERER_HAUPTLIEFERANT = 'ARTIKEL_ANDERER_HAUPTLIEFERANT',
  ARTIKEL_ETIKETTEN_DRUCK = 'ARTIKEL_ETIKETTEN_DRUCK',
  ARTIKEL_PREISAEND_EK_UND_VK = 'ARTIKEL_PREISAEND_EK_UND_VK'
}

export interface ArtikelListeUebersichtJson {
  searcher?: ArtikelListeSearcherJson
  state?: UseCaseStateJson
}

export interface ArtikelMengeneinheitJson {
  basisMengeneinheit?: MengeneinheitJson
  breite?: number
  ean?: string
  faktor?: BruchJson
  gewicht?: number
  hoehe?: number
  /** @format int64 */
  id?: number
  laenge?: number
  menge?: number
  mengeneinheit?: MengeneinheitJson
  shouldBeHiddenFromERP?: boolean
  typ?: ArtikelMengeneinheitTyp
  umrechnungInfo?: string
  volumen?: number
}

export enum ArtikelMengeneinheitTyp {
  EME = 'EME',
  VPE = 'VPE',
  LTE = 'LTE',
  UNKNOWN = 'UNKNOWN'
}

export interface ArtikelNummerParams {
  eans?: string[]
  hageNrs?: string[]
  ians?: string[]
}

export interface ArtikelNummernSucheResultJson {
  noresult?: ArtikelNummerParams
  result?: ArtikelDisplayJson[]
  state?: UseCaseStateJson
}

export enum ArtikelQuelle {
  ZENTRAL = 'ZENTRAL',
  ALLIANZ = 'ALLIANZ',
  ERP = 'ERP'
}

export interface ArtikelSearcherArtikelCriteriaJson {
  archiviert?: boolean
  betriebstyp?: ArtikelBetriebstyp
  betriebstypKontext?: BetriebstypKontext
  bez?: string
  btNummer?: string
  datenpool?: boolean
  dezentral?: boolean
  ean?: string
  eigenlistung?: boolean
  forAbo?: boolean
  gfWarengruppe?: WarengruppeDisplayJson
  hageNummer?: string
  ignoriert?: boolean
  industrieArtikelNummer?: string
  kontext?: NeonKontext
  lieferant?: LieferantDisplayJson
  listung?: boolean
  matchHagenummer?: boolean
  mcode?: string
  pim?: boolean
  warengruppe?: WarengruppeDisplayJson
}

export interface ArtikelSearcherJson {
  criteria?: ArtikelSearcherArtikelCriteriaJson
  limited?: boolean
  result?: ArtikelDisplayJson[]
}

export interface ArtikelSelektionBearbeitenJson {
  changeWarningHints?: Record<string, string>
  filter?: ArtikelAenderungTyp
  kondiDefinition?: EkKonditionenDefinitionJson
  selektion?: ArtikelSelektionJson
  selektionen?: ArtikelSelektionDisplayJson[]
  state?: UseCaseStateJson
}

export interface ArtikelSelektionDetailsJson {
  changes?: CompositeAttributeChange
  ekPreisChanges?: EkPreisDiffJson[]
  konflikte?: CompositeAttributeChange
  listenpreisChanges?: LpPreisDiffJson[]
  messages?: MessageJson[]
  preisGruppen?: VkPreisGruppeJson[]
  state?: UseCaseStateJson
  vkPreisChanges?: VkPreisDiffJson[]
}

export interface ArtikelSelektionDisplayJson {
  /** @format int64 */
  aboId?: number
  aboName?: string
  aenderungenAutomatischUebernehmen?: boolean
  bt?: ArtikelBetriebstyp
  eanGruppe?: EANGruppeJson
  eanGruppeStatus?: AboArtikelEanGruppeStatus
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  name?: string
}

export interface ArtikelSelektionEintragChangeJson {
  /** @format int64 */
  arsstId?: number
  attributWarnungenGeprueft?: boolean
  changeEanGruppenStatus?: boolean
  eanGruppeStatus?: AboArtikelEanGruppeStatus
  eanZuordnung?: EanZuordnung
  ekPreiseVorgaengeErzeugen?: boolean
  ergaenzeMEs?: boolean
  /** @format int64 */
  hauptSelektionId?: number
  /** @format int64 */
  id?: number
  /** @format int64 */
  seSparteId?: number
  /** @format int64 */
  seWarengruppeId?: number
  status?: ArtikelAbonniertStatus
  uebernehmeBetriebsTyp?: boolean
  uebernehmeMehrdeutigAusZE?: boolean
  verbergeMEs?: boolean
  verbindungAlsNeuerArtikel?: boolean
  /** @format int64 */
  verbindungMitSeArtikelId?: number
}

export interface ArtikelSelektionEintragDetailsInputItemJson {
  /** @format int64 */
  aenderungId?: number
  /** @format int64 */
  eintragId?: number
}

export interface ArtikelSelektionEintragDetailsInputJson {
  items?: ArtikelSelektionEintragDetailsInputItemJson[]
}

export interface ArtikelSelektionEintragDetailsJson {
  changes?: CompositeAttributeChange
  ekPreisChanges?: EkPreisDiffJson[]
  /** @format int64 */
  id?: number
  konflikte?: CompositeAttributeChange
  listenpreisChanges?: LpPreisDiffJson[]
  messages?: MessageJson[]
  vkPreisChanges?: VkPreisDiffJson[]
}

export interface ArtikelSelektionEintragDetailsLoadJson {
  details?: ArtikelSelektionEintragDetailsJson[]
  state?: UseCaseStateJson
}

export interface ArtikelSelektionEintragJson {
  abgleichMehrdeutig?: boolean
  abgleichMehrdeutigErp?: boolean
  /** @format int64 */
  abgleichMehrdeutigVorschlagArtikelId?: number
  abgleichMehrdeutigZentral?: boolean
  /** @format int64 */
  aboQuelleId?: number
  aenderung?: ArtikelAenderungJson
  aenderungenIds?: number[]
  /** @format int64 */
  arsstId?: number
  artikel?: ArtikelAboArtikelDisplayJson
  attributWarnungenGeprueft?: boolean
  betriebsTypErmittelt?: ArtikelBetriebstyp
  betriebsTypUnbekannt?: boolean
  details?: ArtikelSelektionEintragDetailsJson
  eanGruppeExtra?: boolean
  eanGruppeStatus?: AboArtikelEanGruppeStatus
  eintragNichtMehrSelektiert?: boolean
  erpAusgelistet?: boolean
  erpVorhanden?: boolean
  /** @format int64 */
  fromAboQuelleId?: number
  /** @format int64 */
  hauptSelektionId?: number
  /** @format int64 */
  id?: number
  messageIds?: string[]
  messages?: MessageJson[]
  notValid?: boolean
  status?: ArtikelAbonniertStatus
  zusatzAenderungenIds?: number[]
}

export interface ArtikelSelektionJson {
  aboBetriebsTyp?: ArtikelBetriebstyp
  aboEanGruppe?: EANGruppeDisplayJson
  /** @format int64 */
  aboId?: number
  aboName?: string
  aenderungenAutomatischUebernehmen?: boolean
  /** @format int64 */
  arsstId?: number
  /** @uniqueItems true */
  artikelIds?: number[]
  /** @format int64 */
  artikelListeId?: number
  ausgelisteteArtikelAnbieten?: boolean
  /** @format int64 */
  countEintragAusgelistet?: number
  /** @format int64 */
  countEintragGesperrt?: number
  /** @format int64 */
  countEintragStatusAusgeschl?: number
  /** @format int64 */
  countEintragStatusEingeschl?: number
  /** @format int64 */
  countEintragStatusUnbest?: number
  /** @format int64 */
  countEintragUnterbrochen?: number
  ekPreislisteErzeugen?: boolean
  ergebnisse?: ArtikelSelektionEintragJson[]
  ergebnisseChanges?: ArtikelSelektionEintragChangeJson[]
  /** @format int64 */
  folgeArsstId?: number
  forEinzelhandel?: boolean
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  name?: string
  preisaenderungenGetrennt?: boolean
  seLieferant?: LieferantDisplayJson
  statistik?: ArtikelSelektionStatistik
  typ?: ArtikelSelektionType
  /** @format int32 */
  version?: number
  warengruppe?: WarengruppeDisplayJson
  wgSparteAuswahlManuell?: boolean
}

export interface ArtikelSelektionMigrationDisplayJson {
  /** @format int64 */
  aboId?: number
  aboName?: string
  /** @format int64 */
  arsstId?: number
  /** @format int64 */
  id?: number
  name?: string
  typ?: ArtikelSelektionType
}

export interface ArtikelSelektionMultiDetailsJson {
  eintragChanges?: Record<string, Record<string, AboArtikelAttrChangeJson>>
  state?: UseCaseStateJson
}

export interface ArtikelSelektionStatistik {
  /** @format int64 */
  countAusgelistet?: number
  /** @format int64 */
  countAusgeschlossen?: number
  /** @format int64 */
  countEingeschlossen?: number
  /** @format int64 */
  countGesperrt?: number
  /** @format int64 */
  countUnbestimmt?: number
  /** @format int64 */
  countUnterbrochen?: number
  /** @format int64 */
  id?: number
}

export enum ArtikelSelektionType {
  ALLE = 'ALLE',
  ARTIKEL = 'ARTIKEL',
  ARTIKEL_AUTOMATISCH = 'ARTIKEL_AUTOMATISCH',
  WARENGRUPPE = 'WARENGRUPPE'
}

export interface ArtikelStammdatenExportEintragArtikelEkKonditionPreisJson {
  artikel?: ArtikelDisplayTinyJson
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  lieferant?: LieferantDisplayTinyJson
}

export interface ArtikelStammdatenExportEintragArtikelEkRabattgruppeJson {
  bezeichnung?: string
  lieferant?: LieferantDisplayTinyJson
  name?: string
}

export interface ArtikelStammdatenExportEintragArtikelEkRabattgruppeVersionJson {
  bezeichnung?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  lieferant?: LieferantDisplayTinyJson
  name?: string
}

export interface ArtikelStammdatenExportEintragArtikelVkEtikettJson {
  artikel?: ArtikelDisplayTinyJson
  /** @format date */
  gueltigVon?: string
  standort?: StandortJson
}

export interface ArtikelStammdatenExportEintragArtikelVkPreisJson {
  artikel?: ArtikelDisplayTinyJson
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  lieferant?: LieferantDisplayTinyJson
}

export enum ArtikelStammdatenExportEintragExportTyp {
  ARTIKEL = 'ARTIKEL',
  STANDORT_ARTIKEL = 'STANDORT_ARTIKEL',
  STANDORT_ARTIKEL_EK = 'STANDORT_ARTIKEL_EK',
  STANDORT_ARTIKEL_VK = 'STANDORT_ARTIKEL_VK',
  STANDORT_ARTIKEL_VK_SPERRE = 'STANDORT_ARTIKEL_VK_SPERRE',
  LIEFERANT = 'LIEFERANT',
  EK_KONDITION = 'EK_KONDITION',
  EK_PREIS = 'EK_PREIS',
  VK_PREIS = 'VK_PREIS',
  VK_ETIKETT = 'VK_ETIKETT',
  EK_RABATT_GRP = 'EK_RABATT_GRP',
  EK_RABATT_GRP_VERS = 'EK_RABATT_GRP_VERS'
}

export interface ArtikelStammdatenExportEintragJson {
  artikel?: ArtikelDisplayTinyJson
  ekKondition?: ArtikelStammdatenExportEintragArtikelEkKonditionPreisJson
  ekPreis?: ArtikelStammdatenExportEintragArtikelEkKonditionPreisJson
  ekRabattgruppe?: ArtikelStammdatenExportEintragArtikelEkRabattgruppeJson
  ekRabattgruppeVersion?: ArtikelStammdatenExportEintragArtikelEkRabattgruppeVersionJson
  errorSeArtikelIds?: number[]
  errorSeLieferantIds?: number[]
  lieferant?: LieferantDisplayTinyJson
  notExported?: boolean
  standortArtikel?: StandortArtikelJson
  typ?: ArtikelStammdatenExportEintragExportTyp
  vkEtiketten?: ArtikelStammdatenExportEintragArtikelVkEtikettJson
  vkPreis?: ArtikelStammdatenExportEintragArtikelVkPreisJson
}

export interface ArtikelStandortJson {
  hauptLieferant?: LieferantDisplayJson
  /** @format int64 */
  id?: number
  lagerKz?: LagerKz
  standortName?: string
  standortNr?: string
}

export interface ArtikelToExportInfoJson {
  /** @format int32 */
  exportierbareDPArtikel?: number
  /** @format int32 */
  exportierbareHGArtikel?: number
  state?: UseCaseStateJson
  /** @format int32 */
  zuEexportierendenDPArtikel?: number
  /** @format int32 */
  zuExportierendenHGArtikel?: number
}

export interface ArtikelUebersichtJson {
  searcher?: ArtikelSearcherJson
  state?: UseCaseStateJson
}

export interface ArtikelVerteilungDefinitionBearbeitenJson {
  data?: ArtikelVerteilungDefinitionJson
  state?: UseCaseStateJson
}

export interface ArtikelVerteilungDefinitionDisplayJson {
  aktiv?: boolean
  /** @format int32 */
  artikelListenCount?: number
  /** @format int64 */
  id?: number
  name?: string
  preisVerteilungsEinstellung?: string
  verteilungsZiele?: VerteilungsZielFlatDisplayJson[]
}

export interface ArtikelVerteilungDefinitionJson {
  aktiv?: boolean
  eintraege?: ArtikelListeDisplayJson[]
  /** @format int64 */
  id?: number
  name?: string
  preisVerteilungsEinstellung?: PreisVerteilungEinstellung
  /** @format int32 */
  version?: number
  verteilungsZielList?: VerteilungsZielDisplayJson[]
  verteilungsZiele?: number[]
}

export interface ArtikelVerteilungDefinitionSearcherCriteriaJson {
  name?: string
}

export interface ArtikelVerteilungDefinitionSearcherJson {
  criteria?: ArtikelVerteilungDefinitionSearcherCriteriaJson
  limited?: boolean
  result?: ArtikelVerteilungDefinitionDisplayJson[]
}

export interface ArtikelVerteilungDefinitionUebersichtJson {
  searcher?: ArtikelVerteilungDefinitionSearcherJson
  state?: UseCaseStateJson
}

export enum ArtikelVertriebsWeg {
  UN = 'UN',
  FH = 'FH',
  EH = 'EH',
  ZE = 'ZE',
  DP = 'DP',
  EL = 'EL',
  PIM = 'PIM'
}

export interface ArtikelVkBlattJson {
  forEinzelhandel?: boolean
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  preise?: VkPreisJson[]
  quelle?: VkPreisQuelle
  quelleStaffel?: VkPreisQuelle
  /** @format int64 */
  referenzPreisgruppeId?: number
  /** @format int64 */
  standortId?: number
}

export interface ArtikelVkPreiseJson {
  preisblaetter?: ArtikelVkBlattJson[]
}

export interface AsyncTaskJson {
  /** @format int32 */
  aktCount?: number
  /** @format date-time */
  end?: string
  error?: boolean
  /** @format int64 */
  id?: number
  messages?: MessageJson[]
  progressInfo?: string
  properties?: Record<string, string>
  /** @format date-time */
  start?: string
  status?: AsyncTaskStatus
  /** @format int32 */
  totalCount?: number
}

export interface AsyncTaskPollInfoJson {
  state?: UseCaseStateJson
}

export enum AsyncTaskStatus {
  PREPARED = 'PREPARED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED'
}

export interface AttributeChange {
  /** @format int32 */
  attributeId?: number
  label?: string
  mapped?: boolean
  newString?: string
  oldString?: string
  zusatz?: boolean
}

export interface AuditRevisionInfoJson {
  /** @format date-time */
  bis?: string
  /** @format int64 */
  id?: number
  /** @format int32 */
  nr?: number
  /** @format date-time */
  von?: string
}

export enum AuswahlStatus {
  EINGESCHL = 'EINGESCHL',
  AUSGESCHL = 'AUSGESCHL',
  IGNORIEREN = 'IGNORIEREN'
}

export interface BetriebsTypenBearbeitenJson {
  betriebsTypen?: BetriebsTypenJson
  state?: UseCaseStateJson
}

export interface BetriebsTypenJson {
  betriebsTypen?: BetriebstypJson[]
  gln?: string
  /** @format int64 */
  id?: number
  name?: string
  pimMode?: boolean
  typ?: GesellschafterTyp
}

export interface BetriebsTypenNeuVergebenJson {
  /**
   * Die Anzahl der Artikel die den Betriebstyp UNBEKANNT zugeordnet haben.
   * @format int32
   */
  artikelMitUnbekanntemBetriebstyp?: number
  /**
   * Die Anzahl der Artikel für die ein neuer Betriebstyp ermittelt werden konnte.
   * @format int32
   */
  neuErmittelteBetriebstypen?: number
  /**
   * Die Id der Stammebene
   * @format int64
   */
  stammEbeneId?: number
}

export interface BetriebsTypenNeuVergebenResultJson {
  betriebsTypenNeuVergeben?: BetriebsTypenNeuVergebenJson[]
  state?: UseCaseStateJson
}

export interface BetriebstypJson {
  /** @format int32 */
  austauschstandard?: number
  bezeichnung?: string
  bt?: ArtikelBetriebstyp
  /** @format int32 */
  btNr?: number
  default?: boolean
  handelTyp?: HandelTyp
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  kontextLand?: string
  kontextName?: string
  kontextNr?: string
  nummer?: string
  /** @format int32 */
  pflegeverhalten?: number
  vorlauftext?: string
  zugeordnet?: boolean
}

export interface BetriebstypKontext {
  betriebstyp?: ArtikelBetriebstyp
  dzModel?: boolean
  einzelhandel?: boolean
  fachhandel?: boolean
  kontext?: NeonKontext
  none?: boolean
  pimModel?: boolean
  valid?: boolean
}

export interface BetriebstypListeJson {
  betriebstypen?: BetriebstypJson[]
  state?: UseCaseStateJson
}

export interface BruchJson {
  faktor?: number
  nenner?: number
  zaehler?: number
}

/**
 * GrundpreisFaktor
 *  Der Faktor von Grundpreis Mengeneinheit zur Einzel Mengeneinheit.
 */
export interface BruchStruct {
  /**
   * Represented fraction as decimal
   * @example 0.2
   */
  Faktor?: number
  /**
   * Denominator of fraction
   * @example 1
   */
  Nenner?: number
  /**
   * Numerator of fraction
   * @example 5
   */
  Zaehler?: number
}

export interface CacheNameJson {
  /** @format int64 */
  cacheCount?: number
  /** @format int64 */
  cacheHits?: number
  /** @format int64 */
  cacheMiss?: number
  name?: string
  tablename?: string
}

export interface CacheNamesJson {
  cacheNames?: CacheNameJson[]
  state?: UseCaseStateJson
}

export interface CompositeAttributeChange {
  allDzAttributeMapperIDs?: number[]
  attributes?: AttributeChange[]
  composites?: CompositeAttributeChange[]
  /** @format int32 */
  id?: number
  label?: string
  mapped?: boolean
}

export interface ContentDisposition {
  attachment?: boolean
  charset?: {
    registered?: boolean
  }
  /** @format date-time */
  creationDate?: string
  filename?: string
  formData?: boolean
  inline?: boolean
  /** @format date-time */
  modificationDate?: string
  name?: string
  /** @format date-time */
  readDate?: string
  /** @format int64 */
  size?: number
  type?: string
}

export interface DashboardJson {
  /** @format int64 */
  countArt?: number
  /** @format int64 */
  countArtAbo?: number
  /** @format int64 */
  countArtAboGeaendert?: number
  /** @format int64 */
  countArtAboGesperrt?: number
  /** @format int64 */
  countArtAboNeu?: number
  /** @format int64 */
  countArtExport?: number
  /** @format int64 */
  countLief?: number
  /** @format int64 */
  countLiefAbo?: number
  /** @format int64 */
  countLiefAboGeaendert?: number
  /** @format int64 */
  countLiefAboGesperrt?: number
  /** @format int64 */
  countLiefAboHaupt?: number
  /** @format int64 */
  countLiefAboNeben?: number
  /** @format int64 */
  countLiefAboNeu?: number
  /** @format int64 */
  countLiefGeaendert?: number
  /** @format int64 */
  countLiefNeu?: number
  /** @format int64 */
  countPool?: number
  state?: UseCaseStateJson
  vorgaenge?: DashboardVorgangJson[]
}

export interface DashboardVorgangJson {
  /** @format int32 */
  anzahl?: number
  /** @format int32 */
  anzahlEskaliert?: number
  artikelSelektion?: string
  /** @format int64 */
  artikelSelektionId?: number
  betriebstyp?: ArtikelBetriebstyp
  /** @format date-time */
  datum?: string
  /** @format int32 */
  detailCount?: number
  error?: boolean
  kontext?: NeonKontext
  lieferant?: string
  /** @format int64 */
  lieferantAboId?: number
  lieferantAboName?: string
  /** @format int64 */
  lieferantId?: number
  referenz?: string
  standort?: string
  /** @format int64 */
  standortId?: number
  text?: string
  type?: DashboardVorgangTyp
  verteilungsGrund?: VerteilungsModelErrorGrund
}

export enum DashboardVorgangTyp {
  NEUE_LIEFERANTEN_ARTIKEL = 'NEUE_LIEFERANTEN_ARTIKEL',
  GEANDERTE_LIEFERANTEN_ARTIKEL = 'GEANDERTE_LIEFERANTEN_ARTIKEL',
  GELOESCHTE_LIEFERANTEN_ARTIKEL = 'GELOESCHTE_LIEFERANTEN_ARTIKEL',
  KONFLIKT_DZ_LIEFERANTEN_ARTIKEL = 'KONFLIKT_DZ_LIEFERANTEN_ARTIKEL',
  LIEFERANTEN_NEU = 'LIEFERANTEN_NEU',
  LIEFERANT_QUELLENWECHSEL = 'LIEFERANT_QUELLENWECHSEL',
  LIEFERANTEN_GEAENDERT = 'LIEFERANTEN_GEAENDERT',
  KONFLIKT_DZ_LIEFERANT = 'KONFLIKT_DZ_LIEFERANT',
  LIEFERANT_ABO_UNBESTIMMT = 'LIEFERANT_ABO_UNBESTIMMT',
  MAP_ERROR_ARTIKEL_ART = 'MAP_ERROR_ARTIKEL_ART',
  MAP_ERROR_STEUER = 'MAP_ERROR_STEUER',
  MAP_ERROR_MENGEN_EINHEIT = 'MAP_ERROR_MENGEN_EINHEIT',
  MAP_ERROR_MULTIPLE_MENGEN_EINHEIT = 'MAP_ERROR_MULTIPLE_MENGEN_EINHEIT',
  MAP_ERROR_LIEFERANT = 'MAP_ERROR_LIEFERANT',
  MAP_ERROR_WARENGRUPPE = 'MAP_ERROR_WARENGRUPPE',
  MAP_ERROR_PREISGRUPPE = 'MAP_ERROR_PREISGRUPPE',
  MAP_ERROR_PREISMENGE = 'MAP_ERROR_PREISMENGE',
  MAP_ERROR_EAN_GRUPPE = 'MAP_ERROR_EAN_GRUPPE',
  MAP_ERROR_RABATTGRUPPE = 'MAP_ERROR_RABATTGRUPPE',
  EXPORT_ERP_ERROR_ARTIKEL = 'EXPORT_ERP_ERROR_ARTIKEL',
  EXPORT_ERP_ERROR_LIEFERANT = 'EXPORT_ERP_ERROR_LIEFERANT',
  EXPORT_ERP_ERROR_EKPREIS = 'EXPORT_ERP_ERROR_EKPREIS',
  EXPORT_ERP_ERROR_EKKONDITION = 'EXPORT_ERP_ERROR_EKKONDITION',
  EXPORT_ERP_ERROR_VKPREIS = 'EXPORT_ERP_ERROR_VKPREIS',
  IMPORT_ERP_ERROR_ARTIKEL = 'IMPORT_ERP_ERROR_ARTIKEL',
  IMPORT_ERP_ERROR_LIEFERANT = 'IMPORT_ERP_ERROR_LIEFERANT',
  IMPORT_ERP_ERROR_EKPREIS = 'IMPORT_ERP_ERROR_EKPREIS',
  IMPORT_ERP_ERROR_EKKONDITION = 'IMPORT_ERP_ERROR_EKKONDITION',
  IMPORT_ERP_ERROR_VKPREIS = 'IMPORT_ERP_ERROR_VKPREIS',
  VERTEILUNG_ARTIKEL = 'VERTEILUNG_ARTIKEL',
  ARTIKEL_ANDERER_HAUPTLIEFERANT = 'ARTIKEL_ANDERER_HAUPTLIEFERANT',
  ARTIKEL_OHNE_HAUPTLIEFERANTEN = 'ARTIKEL_OHNE_HAUPTLIEFERANTEN',
  ARTIKEL_MIT_GESPERRTEN_HAUPTLIEFERANTEN = 'ARTIKEL_MIT_GESPERRTEN_HAUPTLIEFERANTEN',
  HAUPTLIEFERANT_GEAENDERT_EK = 'HAUPTLIEFERANT_GEAENDERT_EK',
  HAUPTLIEFERANT_GEAENDERT_VK = 'HAUPTLIEFERANT_GEAENDERT_VK',
  VK_PREISPFLEGE = 'VK_PREISPFLEGE',
  EK_PREISPFLEGE = 'EK_PREISPFLEGE',
  EK_UND_VK_PREISAENDERUNG = 'EK_UND_VK_PREISAENDERUNG',
  ARTIKEL_ETIKETTEN_DRUCK = 'ARTIKEL_ETIKETTEN_DRUCK'
}

export interface DataFilesJson {
  root?: FileJson
  state?: UseCaseStateJson
}

/** Struktur für Regalplätze aus dem DataLake */
export interface DataLakeRegalplatzJSON {
  /**
   * Datenbank Aktion. Bei 'DELETE' wird von Löschung ausgegangen. Ansonsten von Anlage/Änderung.
   * @example "UPDATE"
   */
  ACTION?: string
  /** @format int32 */
  Ab_Datum?: number
  /**
   * Anzahl der Etiketten des Etikettentyp an diesem Regalplatz
   * @format int32
   */
  Anzahl_Fronstuecke?: number
  /**
   * Hagebau Artikelnummer (HageNr)
   * @example "444444"
   */
  Artikelnummer: string
  /**
   * Company
   * @example "100"
   */
  Company?: string
  DruckSequenzNr?: string
  /** Etikettentyp an diesem Regalplatz */
  Etikettentyp?: string
  /** Gesellschafternummer (GLN) */
  Gesellschafternummer: string
  /**
   * Lagerbereich
   * @example "BM"
   */
  Lagerbereich: string
  /**
   * Lagerort
   * @example "110"
   */
  Lagerort: string
  /**
   * Lagerplatz
   * @example "0010100"
   */
  Lagerplatz: string
  /**
   * Lagerplatztyp
   * @example "R1"
   */
  Lagerplatztyp?: string
  /**
   * Standortnummer
   * @format int32
   * @example 100
   */
  Standort: number
  Tenant?: string
}

export interface DatabaseIndexStatistics {
  blevel?: number
  indexName?: string
  /** @format date-time */
  lastAnalyzed?: string
  leafBlocks?: number
  tableName?: string
}

export interface DatabaseStatisticsJson {
  /** @format int64 */
  anzBenutzer?: number
  /** @format int64 */
  anzErpArtikel?: number
  /** @format int64 */
  anzErpArtikelAbos?: number
  /** @format int64 */
  anzErpArtikelSelektionen?: number
  /** @format int64 */
  anzErpLieferanten?: number
  /** @format int64 */
  anzFirmen?: number
  /** @format int64 */
  anzListungsArtikel?: number
  /** @format int64 */
  anzListungsLieferanten?: number
  /** @format int64 */
  anzLoginsHeute?: number
  /** @format int64 */
  anzOrganisationen?: number
  datenbankIndexes?: DatabaseIndexStatistics[]
  datenbankMB?: number
  /** @format int64 */
  datenbankSession?: number
  datenbankTableSizes?: Record<string, number[]>
  datenbankVersion?: string
  state?: UseCaseStateJson
}

export enum Datenmodell {
  PIM = 'PIM',
  ZART = 'ZART'
}

export type DeferredResultDeferredResultHandler = object

export interface DefferedPulseJson {
  errorResult?: boolean
  lastResult?: PulseJson
  result?: PulseJson
  resultCond?: PulseJson
  resultHandler?: DeferredResultDeferredResultHandler
  resultInternal?: boolean
  setOrExpired?: boolean
}

export interface DownloadResponseJson {
  key?: string
  state?: UseCaseStateJson
}

export interface DpArtikelLieferantXML {
  /**
   * EANCOM Element 6411-Code der Berechnungsmengeneinheit, in der der Lieferant abrechnet.
   * @example "KGM"
   */
  BME?: string
  /**
   * Global Trade Item Number (früher auch EAN).
   * @format 8-. 11- oder 13-stellig (GTIN-8, GTIN-13), wenn 11-stellig kommt --> dann mit 2 führenden 0 aufgeüllt.
   * @minLength 0
   * @maxLength 35
   * @example "4027808029305"
   */
  GTIN?: string
  /**
   * Systemübergreifende Hagebau Artikelnummer, welche vom ersten erfassenden System vergeben wird.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * Lieferantenartikelnummer
   * Artikelnummer, die der Lieferant intern für seine Artikel vergibt. Führende Nullen bitte mit angeben.
   * @minLength 0
   * @maxLength 35
   * @example "087467-A01"
   */
  LAN: string
  /**
   * Lieferanten Artikelbezeichnung
   * Artikelbezeichnung, die der Lieferant intern für seine Artikel vergibt.
   * @example "Lithon Hochbord Übergangsst. li+re  grau"
   */
  LieferantArtikelBez: string
  /**
   * Lieferanten-Nr.
   * interne Lieferantennummer der hagebau.
   * @example "900448"
   */
  LieferantNr: string
  /**
   * Lieferanten Warengruppe
   * Der Name der Warengruppe, welcher der Lieferant führt; Identifikation innerhalb des Datenpools.
   */
  LieferantenWarengruppe: string
  /**
   * Werkslistenpreis.
   * Der Werkslistenpreis gilt für eine Berechnungsmengeneinheit (BME).
   * @example 12.78
   */
  Werkslistenpreis?: number
}

export interface DpImportDataXML {
  Lieferanten?: DpArtikelLieferantXML[]
}

export interface DzArtikelAnzeigenJson {
  artikel?: DzArtikelJson
  details?: ErpArtikelDetailsJson
  display?: ArtikelDisplayTinyJson
  importErrorStatus?: ImportErrorStatusJson
  /** @format int64 */
  partnerArtikelId?: number
  partnerArtikelQuelle?: ArtikelQuelle
  quelle?: ArtikelQuelle
  /** @format int64 */
  revision?: number
  revisions?: AuditRevisionInfoJson[]
  state?: UseCaseStateJson
}

export interface DzArtikelArtXML {
  ArtikelArt?: string
  Bezeichnung?: string
  KurzBez?: string
  Name?: string
}

export interface DzArtikelJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  akt?: boolean
  archiviert?: boolean
  artikelArt?: ArtikelArtDisplayJson
  aufmassliste?: boolean
  betriebsTyp?: ArtikelBetriebstyp
  bontext?: string
  breite?: number
  btNummer?: string
  dnp?: boolean
  eans?: ArtikelEANJson[]
  eckpreis?: boolean
  einkaufME?: MengeneinheitJson
  einkaufPreismengeWert?: number
  einzelME?: MengeneinheitJson
  gewicht?: number
  hageNummer?: string
  hageWarengruppe?: WarengruppeDisplayJson
  hageWarengruppeNummer?: string
  hauptlieferant?: LieferantDisplayJson
  hoehe?: number
  /** @format int64 */
  id?: number
  laenge?: number
  lagerME?: MengeneinheitJson
  matchcode?: string
  mengeneinheiten?: ArtikelMengeneinheitJson[]
  mhd?: boolean
  minEinLager?: boolean
  online?: boolean
  pfandBTArtikelNr?: string
  rabattFaehig?: boolean
  selektionDz?: string
  skontoFaehig?: boolean
  sparte?: SparteDisplayJson
  standorte?: ArtikelStandortJson[]
  steuer?: SteuerJson
  verkaufME?: MengeneinheitJson
  verkaufPreismengeWert?: number
  warengruppe?: WarengruppeDisplayJson
  warengruppeBDBNummer?: string
}

/** Definition ArtikelLabels */
export interface DzArtikelLabelXML {
  /** A Label that should be attached to this article. An equally named label in your hkm Stammebene is required for this to function. */
  ArtikelLabel?: string[]
}

export interface DzArtikelMEXML {
  /** Represents a decimal as fraction. */
  BasisUmrechnung?: DzBruchXML
  Breite?: number
  /** EAN (European Article Number) of this unit of measure */
  EAN?: string
  Gewicht?: number
  HauptVME?: boolean
  Hoehe?: number
  Laenge?: number
  LagerMeErpId?: string
  LagerMeName?: string
  /** Represents a decimal as fraction. */
  LagerMeUmrechnung?: DzBruchXML
  MEBasisErpId?: string
  MEBasisName?: string
  /**
   * ERP system id for this unit of measure
   * @example "PA0502::d0e78823-c66a-46ea-914a-6ac3f5c0ee00"
   */
  MEErpId?: string
  MEName?: string
  Menge?: number
  Typ?: ArtikelMengeneinheitTyp
  Volumen?: number
}

export interface DzArtikelXML {
  /** description 1 */
  Bez1?: string
  /** description 2 */
  Bez2?: string
  /** description 3 */
  Bez3?: string
  /** description 4 */
  Bez4?: string
  /**
   * ERP system id for 'ArtikelArt' (item type)
   * @example "PA0124::79b748c5-6eac-4e73-9eec-03ae8ce2ed80"
   */
  ArtikelArtErpId?: string
  ArtikelArtName?: string
  /** Definition ArtikelLabels */
  ArtikelLabels?: DzArtikelLabelXML
  ArtikelME?: DzArtikelMEXML[]
  ArtikelNr?: string
  /** Aufmassliste - marks an item as being an Aufmassliste */
  Aufmassliste?: boolean
  BetriebsTyp?: ArtikelBetriebstyp
  Bontext?: string
  Breite?: number
  /** marks an item as being an 'DNP' item */
  DNP?: boolean
  /** Definition EANs */
  EANs?: DzEANXML[]
  /** marks an item as being an 'Eckpreis' item */
  Eckpreis?: boolean
  EinkaufMeErpId?: string
  EinkaufMeName?: string
  EinkaufPmWert?: number
  EinzelMeErpId?: string
  EinzelMeName?: string
  /** item weight for one storage unit of measure in kg (kilograms) */
  Gewicht?: number
  HageNr?: string
  HageWgNr?: string
  Hoehe?: number
  IsArchiviert?: boolean
  Laenge?: number
  /**
   * ERP system id for 'LagerME' (Storage unit of measure)
   * @example "PA0502::d0e78823-c66a-46ea-914a-6ac3f5c0ee00"
   */
  LagerMeErpId?: string
  /** item weight for one storage unit of measure in kg (kilograms) */
  LagerMeGewicht?: number
  LagerMeName?: string
  LieferantenArtikel?: DzLieferantenArtikelXML[]
  /** marks an item as relevant for minimum shelf life */
  MHD?: boolean
  /** matchcode */
  Mcode?: string
  Online?: boolean
  PfandArtikelNr?: string
  Plakat?: DzPlakatXML
  /** marks an item as being eligible for discounts */
  RabattFaehig?: boolean
  /** Selektion, wir aus dem Matchcode erzeugt. */
  Selektion?: string
  /** marks an item as being eligible for cash discount */
  SkontoFaehig?: boolean
  /**
   * ERP system id for 'Sparte' (commodity group)
   * @example "PA0487::23bcaaf9-2fae-45bd-a70e-c9658e3254"
   */
  SparteErpId?: string
  StandortArtikel?: DzStandortArtikelXML[]
  /**
   * id for tax in ERP system
   * @example "PA0494::ddb8786b-2b3b-4079-ba12-37f2eefe0294"
   */
  SteuerSchluesselErpId?: string
  SteuerSchluesselName?: string
  /** Definition of parts list (Stückliste) */
  Stuelis?: DzStuelisXML[]
  VerkaufMeErpId?: string
  VerkaufMeName?: string
  VerkaufPmWert?: number
  VorlaeuferArtikelNr?: string
  WarengruppeBdbNr?: string
  /**
   * ERP system id for 'Warengruppe' (commodity group)
   * @example "PA0487::23bcaaf9-2fae-45bd-a70e-c9658e071ab1"
   */
  WarengruppeErpId?: string
  WarengruppeNummer?: string
  ZeBtNummer?: string
  archiviert?: boolean
  /** Definition ArtikelLabels */
  orCreateArtikelLabels?: DzArtikelLabelXML
}

export enum DzAttributeMapperIdType {
  VALUE = 'VALUE',
  REFERENCE = 'REFERENCE',
  COLLECTION = 'COLLECTION'
}

export interface DzBetriebstypNummerXML {
  BisArtikel?: string
  VonArtikel?: string
}

export interface DzBetriebstypXML {
  /** @format int32 */
  Austauschstandard?: number
  /** @format int32 */
  BT?: number
  BetriebstypNummer?: DzBetriebstypNummerXML[]
  Bezeichnung?: string
  /** @format int32 */
  Pflegeverhalten?: number
  Vorlauftext?: string
}

/** Represents a decimal as fraction. */
export interface DzBruchXML {
  /**
   * Represented fraction as decimal
   * @example 0.2
   */
  Faktor?: number
  /**
   * Denominator of fraction
   * @example 1
   */
  Nenner?: number
  /**
   * Numerator of fraction
   * @example 5
   */
  Zaehler?: number
}

export interface DzEANGruppeXML {
  Bezeichnung?: string
  KurzBez?: string
  Name?: string
}

/** Definition EANs */
export interface DzEANXML {
  EAN?: string
  EANAutomatik?: boolean
  EANGruppeErpId?: string
  Geloescht?: boolean
  HauptEAN?: boolean
}

export interface DzEKPreisRabattXML {
  RabattProz1?: number
  RabattProz2?: number
  RabattProz3?: number
  RabattProz4?: number
  RabattProz5?: number
  ZuschlAbs1?: number
  ZuschlAbs2?: number
  ZuschlAbs3?: number
  ZuschlAbs4?: number
  ZuschlAbs5?: number
  EkFreiHaus?: number
  Hauptstaffel?: boolean
  Listenpreis?: number
  RabattgruppeErpId?: string
  Sonderpreis?: number
  Staffelmenge?: number
}

export interface DzEkKonditionRabattGruppeVersionXML {
  EkKonditionRabattGruppeWert?: DzEkKonditionRabattGruppeWertXML[]
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  KopfErpId?: string
  StaffelgruppeErpId?: string
}

export interface DzEkKonditionRabattGruppeWertXML {
  RabattProz1?: number
  RabattProz2?: number
  RabattProz3?: number
  RabattProz4?: number
  RabattProz5?: number
  ZuschlAbs1?: number
  ZuschlAbs2?: number
  ZuschlAbs3?: number
  ZuschlAbs4?: number
  ZuschlAbs5?: number
  Staffelmenge?: number
}

export interface DzEkKonditionRabattGruppeXML {
  RabattProz1?: number
  RabattProz2?: number
  RabattProz3?: number
  RabattProz4?: number
  RabattProz5?: number
  ZuschlAbs1?: number
  ZuschlAbs2?: number
  ZuschlAbs3?: number
  ZuschlAbs4?: number
  ZuschlAbs5?: number
  Bezeichnung?: string
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  LieferantErpId?: string
  Name?: string
}

export interface DzEkKonditionenDefinitionXML {
  BezRabattProz1?: string
  BezRabattProz2?: string
  BezRabattProz3?: string
  BezRabattProz4?: string
  BezRabattProz5?: string
  BezZuschlAbs1?: string
  BezZuschlAbs2?: string
  BezZuschlAbs3?: string
  BezZuschlAbs4?: string
  BezZuschlAbs5?: string
  IsRabattProz1?: boolean
  IsRabattProz2?: boolean
  IsRabattProz3?: boolean
  IsRabattProz4?: boolean
  IsRabattProz5?: boolean
  IsZuschlAbs1?: boolean
  IsZuschlAbs2?: boolean
  IsZuschlAbs3?: boolean
  IsZuschlAbs4?: boolean
  IsZuschlAbs5?: boolean
  KurzRabattProz1?: string
  KurzRabattProz2?: string
  KurzRabattProz3?: string
  KurzRabattProz4?: string
  KurzRabattProz5?: string
  KurzZuschlAbs1?: string
  KurzZuschlAbs2?: string
  KurzZuschlAbs3?: string
  KurzZuschlAbs4?: string
  KurzZuschlAbs5?: string
  LieferantErpId?: string
}

export interface DzEkKonditionenXML {
  ArtikelErpId?: string
  EKPreisRabatt?: DzEKPreisRabattXML[]
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  LieferantErpId?: string
  Listenpreis?: number
  MengeneinheitErpId?: string
  Preismenge?: number
  PreismengeErpId?: string
  RabattVerwendung?: EkKonditionenRabattVerwendung
  RabattgruppeErpId?: string
  StaffelgruppeErpId?: string
  StandortErpId?: string
}

export interface DzEkPreisXML {
  ArtikelErpId?: string
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  LieferantErpId?: string
  Listenpreis?: number
  MengeneinheitErpId?: string
  Preismenge?: number
  PreismengeErpId?: string
}

export interface DzEkStaffelgruppeMengeXML {
  Menge?: number
}

export interface DzEkStaffelgruppeXML {
  Bezeichnung?: string
  EkStaffelgruppeMenge?: DzEkStaffelgruppeMengeXML[]
  Flex?: boolean
  Name?: string
}

export interface DzErpArtikelArtXML {
  AktionCode?: ErpAktionCode
  ArtikelArt?: DzArtikelArtXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpArtikelXML {
  AktionCode?: ErpAktionCode
  Artikel?: DzArtikelXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpBetriebstypXML {
  AktionCode?: ErpAktionCode
  Betriebstyp?: DzBetriebstypXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpEANGruppeXML {
  AktionCode?: ErpAktionCode
  EANGruppe?: DzEANGruppeXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpEkKonditionRabattgruppeVersionXML {
  AktionCode?: ErpAktionCode
  EkKonditionRabattGruppeVersion?: DzEkKonditionRabattGruppeVersionXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  modelXML?: DzEkKonditionRabattGruppeVersionXML
}

export interface DzErpEkKonditionRabattgruppeXML {
  AktionCode?: ErpAktionCode
  EkKonditionRabattGruppe?: DzEkKonditionRabattGruppeXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  modelXML?: DzEkKonditionRabattGruppeXML
}

export interface DzErpEkKonditionenXML {
  AktionCode?: ErpAktionCode
  EKKondition?: DzEkKonditionenXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpEkPreisXML {
  AktionCode?: ErpAktionCode
  EKPreis?: DzEkPreisXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpEkStaffelgruppeXML {
  AktionCode?: ErpAktionCode
  EkStaffelgruppe?: DzEkStaffelgruppeXML
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
}

export interface DzErpExchangeHeaderXML {
  /** @format int64 */
  DepSequenzNummer?: number
  /** @format int64 */
  DepSubSequenzNummer?: number
  ErpMandantSystemId?: string
  ErpSystemEinstellungen?: ErpSystemEinstellungen
  ErpSystemId?: string
  ErpVersion?: string
  GfeNr?: string
  GfeTyp?: StammEbeneTyp
  HkmVersion?: string
  RefTime?: string
  /** @format int64 */
  SequenzNummer?: number
  SubLast?: boolean
  /** @format int64 */
  SubSequenzNummer?: number
}

export interface DzErpKalkulationsvorschlagXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Kalkulationsvorschlag?: DzKalkulationsvorschlagXML
}

export interface DzErpLieferantXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Lieferant?: DzLieferantXML
}

export interface DzErpMandant {
  ebeneId?: string
  erpSystemEinstellungen?: ErpSystemEinstellungen
  intercompanyEinstellungen?: IntercompanyEinstellungen
  mandantSystemId?: string
  parent?: DzErpMandant
}

export interface DzErpMengenEinheitXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  MengenEinheit?: DzMengenEinheitXML
}

export interface DzErpPreisgruppeXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Preisgruppe?: DzPreisgruppeXML
}

export interface DzErpPreismengeXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Preiseinheit?: DzPreismengeXML
}

export interface DzErpRundungsregelXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Rundungsregel?: DzRundungsregelXML
}

export interface DzErpSparteXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Sparte?: DzSparteXML
}

export interface DzErpStandortXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Standort?: DzStandortXML
}

export interface DzErpSteuerXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Steuer?: DzSteuerXML
}

/** Preisinformationen für Etiketten. */
export interface DzErpVkEtikettenPreisBlattXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  VkPreisBlatt?: DzVkPreisBlattXML
}

export interface DzErpVkPreisBlattXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  VkPreisBlatt?: DzVkPreisBlattXML
}

export interface DzErpWarengruppeXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  Warengruppe?: DzWarengruppeXML
}

export interface DzExportErpDataConfirmHeaderXML {
  ErpExchangeHeader?: DzErpExchangeHeaderXML
}

export interface DzExportErpDataConfirmXML {
  ExportErpDataConfirmHeader?: DzExportErpDataConfirmHeaderXML
  SequenzItem?: DzExportErpDataSequenceItemXML[]
  sequenceItems?: DzExportErpDataSequenceItemXML[]
}

export interface DzExportErpDataHeaderXML {
  ErpExchangeHeader?: DzErpExchangeHeaderXML
  ExportRequestId?: string
}

export interface DzExportErpDataRequestHeaderXML {
  ErpExchangeHeader?: DzErpExchangeHeaderXML
  ErpSystemEinstellungen?: ErpSystemEinstellungen
  ExportRequestId?: string
  ExportTyp?: ExportErpDataRequestTyp
  IcEbene?: string
  IcErpMandantSystemId?: string
  IntercompanyEinstellungen?: IntercompanyEinstellungen
  /**
   * Mögliche Werte (STAMMDATEN, LIEFERANTEN, ARTIKEL, EKPREISE, VKPREISE)
   * @example "EKPREISE,VKPREISE"
   */
  exportSubTyp?: string
  mandant?: DzErpMandant
}

export enum DzExportErpDataRequestItemTyp {
  ARTIKEL = 'ARTIKEL',
  ARTIKEL_BY_LIEFERANT = 'ARTIKEL_BY_LIEFERANT',
  LIEFERANT = 'LIEFERANT',
  EK_PREISE = 'EK_PREISE',
  EK_KONDITIONEN = 'EK_KONDITIONEN',
  VK_PREISE = 'VK_PREISE',
  VK_ETIKETT = 'VK_ETIKETT'
}

export interface DzExportErpDataRequestItemXML {
  ErpId?: string
  Typ?: DzExportErpDataRequestItemTyp
}

export interface DzExportErpDataRequestXML {
  ExportErpDataRequestHeader?: DzExportErpDataRequestHeaderXML
  Item?: DzExportErpDataRequestItemXML[]
  SequenzItem?: DzExportErpDataSequenceItemXML[]
}

export interface DzExportErpDataSequenceItemXML {
  /** @format int64 */
  SequenzNummer?: number
  /** @format int64 */
  SubSequenzNummer?: number
}

export interface DzExportErpDataXML {
  ErpArtikel?: DzErpArtikelXML[]
  ErpArtikelArt?: DzErpArtikelArtXML[]
  ErpBetriebstyp?: DzErpBetriebstypXML[]
  ErpEANGruppe?: DzErpEANGruppeXML[]
  ErpEKKondition?: DzErpEkKonditionenXML[]
  ErpEKPreis?: DzErpEkPreisXML[]
  ErpEkKonditionRabattGruppe?: DzErpEkKonditionRabattgruppeXML[]
  ErpEkKonditionRabattGruppeVersion?: DzErpEkKonditionRabattgruppeVersionXML[]
  ErpEkStaffelgruppe?: DzErpEkStaffelgruppeXML[]
  ErpKalkulationsvorschlag?: DzErpKalkulationsvorschlagXML[]
  ErpLieferant?: DzErpLieferantXML[]
  ErpMengenEinheit?: DzErpMengenEinheitXML[]
  ErpPreiseinheit?: DzErpPreismengeXML[]
  ErpPreisgruppe?: DzErpPreisgruppeXML[]
  ErpRundungsregel?: DzErpRundungsregelXML[]
  ErpSparte?: DzErpSparteXML[]
  ErpStandort?: DzErpStandortXML[]
  ErpSteuer?: DzErpSteuerXML[]
  /** Preisinformationen für Etiketten. */
  ErpVkEtikettenPreisBlatt?: DzErpVkEtikettenPreisBlattXML[]
  ErpVkPreisBlatt?: DzErpVkPreisBlattXML[]
  ErpWarengruppe?: DzErpWarengruppeXML[]
  ErpZufuhrMulti?: SeErpZufuhrMultiXML[]
  ExportErpDataDataHeader?: DzExportErpDataHeaderXML
  ReferenzMapEntry?: DzReferenzMapEntryXML[]
}

export interface DzHkmParameterXML {
  HkmJMSQue?: string
  /** @format int32 */
  HkmJMSSendMaxSize?: number
  HkmJmsGln?: string
  /** @format int32 */
  HkmSonderpreisInRabatt?: number
}

export interface DzHkmSetParameterXML {
  HkmParameter?: DzHkmParameterXML
}

export interface DzImportHKMDataHeaderXML {
  ErpExchangeHeader?: DzErpExchangeHeaderXML
  ExportId?: string
}

export interface DzImportHKMDataResultItemArtikelArtenXML {
  ArtikelArt?: DzArtikelArtXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzArtikelArtXML
}

export interface DzImportHKMDataResultItemArtikelXML {
  Artikel?: DzArtikelXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzArtikelXML
}

export interface DzImportHKMDataResultItemBetriebstypXML {
  Betriebstyp?: DzBetriebstypXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzBetriebstypXML
}

export interface DzImportHKMDataResultItemEANGruppenXML {
  EANGruppe?: DzEANGruppeXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzEANGruppeXML
}

export interface DzImportHKMDataResultItemEkKonditionXML {
  EKKondition?: DzEkKonditionenXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzEkKonditionenXML
}

export interface DzImportHKMDataResultItemEkPreisXML {
  EKPreis?: DzEkPreisXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzEkPreisXML
}

export interface DzImportHKMDataResultItemEkStaffelgruppeXML {
  EkStaffelgruppe?: DzEkStaffelgruppeXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzEkStaffelgruppeXML
}

export interface DzImportHKMDataResultItemKalkulationsvorschlagXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Kalkulationsvorschlag?: DzKalkulationsvorschlagXML
  modelXML?: DzKalkulationsvorschlagXML
}

export interface DzImportHKMDataResultItemLieferantXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Lieferant?: DzLieferantXML
  modelXML?: DzLieferantXML
}

export interface DzImportHKMDataResultItemMengeneinheitXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  MengenEinheit?: DzMengenEinheitXML
  modelXML?: DzMengenEinheitXML
}

export interface DzImportHKMDataResultItemPreisgruppeXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Preisgruppe?: DzPreisgruppeXML
  modelXML?: DzPreisgruppeXML
}

export interface DzImportHKMDataResultItemRabattgruppeVersionXML {
  EkKonditionRabattGruppeVersion?: DzEkKonditionRabattGruppeVersionXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzEkKonditionRabattGruppeVersionXML
}

export interface DzImportHKMDataResultItemRabattgruppeXML {
  EkKonditionRabattGruppe?: DzEkKonditionRabattGruppeXML
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  modelXML?: DzEkKonditionRabattGruppeXML
}

export interface DzImportHKMDataResultItemRundungsregelXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Rundungsregel?: DzRundungsregelXML
  modelXML?: DzRundungsregelXML
}

export interface DzImportHKMDataResultItemSparteXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Sparte?: DzSparteXML
  modelXML?: DzSparteXML
}

export interface DzImportHKMDataResultItemStandortXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Standort?: DzStandortXML
  modelXML?: DzStandortXML
}

export interface DzImportHKMDataResultItemSteuerXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Steuer?: DzSteuerXML
  modelXML?: DzSteuerXML
}

export interface DzImportHKMDataResultItemVkPreisXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  VkPreisBlatt?: DzVkPreisBlattXML
  modelXML?: DzVkPreisBlattXML
}

export interface DzImportHKMDataResultItemWarengruppeXML {
  ErpId?: string
  ErrorMessage?: string
  FachSchluessel?: string
  ImportRef?: string
  Warengruppe?: DzWarengruppeXML
  modelXML?: DzWarengruppeXML
}

export interface DzImportHKMDataResultXML {
  ChangedArtikel?: DzImportHKMDataResultItemArtikelXML[]
  ChangedArtikelArt?: DzImportHKMDataResultItemArtikelArtenXML[]
  ChangedBetriebstyp?: DzImportHKMDataResultItemBetriebstypXML[]
  ChangedEANGruppe?: DzImportHKMDataResultItemEANGruppenXML[]
  ChangedEkKondition?: DzImportHKMDataResultItemEkKonditionXML[]
  ChangedEkKonditionRabattGruppe?: DzImportHKMDataResultItemRabattgruppeXML[]
  ChangedEkKonditionRabattGruppeVersion?: DzImportHKMDataResultItemRabattgruppeVersionXML[]
  ChangedEkPreis?: DzImportHKMDataResultItemEkPreisXML[]
  ChangedEkStaffelgruppe?: DzImportHKMDataResultItemEkStaffelgruppeXML[]
  ChangedKalkulationsvorschlag?: DzImportHKMDataResultItemKalkulationsvorschlagXML[]
  ChangedLieferant?: DzImportHKMDataResultItemLieferantXML[]
  ChangedMengeneinheit?: DzImportHKMDataResultItemMengeneinheitXML[]
  ChangedPreisgruppe?: DzImportHKMDataResultItemPreisgruppeXML[]
  ChangedRundungsregel?: DzImportHKMDataResultItemRundungsregelXML[]
  ChangedSparte?: DzImportHKMDataResultItemSparteXML[]
  ChangedStandort?: DzImportHKMDataResultItemStandortXML[]
  ChangedSteuer?: DzImportHKMDataResultItemSteuerXML[]
  ChangedVkPreis?: DzImportHKMDataResultItemVkPreisXML[]
  ChangedWarengruppe?: DzImportHKMDataResultItemWarengruppeXML[]
  ImportHKMDataHeaderResult?: DzImportHKMDataHeaderXML
  changedRabattgruppeVersion?: DzImportHKMDataResultItemRabattgruppeVersionXML[]
  exchangeHeader?: DzErpExchangeHeaderXML
}

export interface DzImportHKMDataXML {
  ErpArtikel?: DzErpArtikelXML[]
  ErpArtikelArt?: DzErpArtikelArtXML[]
  ErpBetriebstyp?: DzErpBetriebstypXML[]
  ErpEKKondition?: DzErpEkKonditionenXML[]
  ErpEKPreis?: DzErpEkPreisXML[]
  ErpEkKonditionRabattGruppe?: DzErpEkKonditionRabattgruppeXML[]
  ErpEkKonditionRabattGruppeVersion?: DzErpEkKonditionRabattgruppeVersionXML[]
  ErpEkStaffelgruppe?: DzErpEkStaffelgruppeXML[]
  ErpKalkulationsvorschlag?: DzErpKalkulationsvorschlagXML[]
  ErpLieferant?: DzErpLieferantXML[]
  ErpMengenEinheit?: DzErpMengenEinheitXML[]
  ErpPreisgruppe?: DzErpPreisgruppeXML[]
  ErpRundungsregel?: DzErpRundungsregelXML[]
  ErpSparte?: DzErpSparteXML[]
  ErpStandort?: DzErpStandortXML[]
  ErpSteuer?: DzErpSteuerXML[]
  ErpVkPreisBlatt?: DzErpVkPreisBlattXML[]
  ErpWarengruppe?: DzErpWarengruppeXML[]
  ImportHKMDataHeader?: DzImportHKMDataHeaderXML
}

export interface DzKalkulationsvorschlagPreisXML {
  Aufschlag?: number
  BasisTyp?: VkPreisBasisTyp
  Basistyp?: VkPreisBasisTyp
  IsBrutto?: boolean
  PreisgruppeErpId?: string
  Spanne?: number
  Staffelmenge?: number
}

export interface DzKalkulationsvorschlagXML {
  Bezeichnung?: string
  KalkulationsvorschlagPreis?: DzKalkulationsvorschlagPreisXML[]
  Name?: string
}

export interface DzLieferantAdresseXML {
  Name1?: string
  Name2?: string
  Name3?: string
  AdrTyp?: LieferantAdresseTyp
  Anrede?: string
  Email?: string
  Homepage?: string
  Land?: string
  Mobil?: string
  Ort?: string
  Ortsteil?: string
  Plz?: string
  PostLz?: string
  Postfach?: string
  PostfachOrt?: string
  Strasse?: string
  Telefax?: string
  Telefon?: string
}

export interface DzLieferantAnzeigenJson {
  ekKonditionenDefinition?: EkKonditionenDefinitionJson
  fachSchluessel?: string
  importError?: ImportErrorJson
  lieferant?: DzLieferantJson
  lieferantAbo?: LieferantAboDisplayJson
  manuelleListen?: LieferantenListeDisplayJson[]
  revisions?: AuditRevisionInfoJson[]
  state?: UseCaseStateJson
  /** @format int64 */
  zeLieferantId?: number
}

export interface DzLieferantEinkaeuferGruppe {
  Name?: string
  Nummer?: string
  Telefonnummer?: string
}

export interface DzLieferantJson {
  abholAdresse?: LieferantAdresseJson
  branche?: string
  buchungsSperre?: boolean
  client?: string
  edi?: boolean
  /** @format int32 */
  ediDsp?: number
  ediTest?: boolean
  eigenlistung?: boolean
  einkaufsSperre?: boolean
  ekKonditionenDefinition?: EkKonditionenDefinitionJson
  /** @format int64 */
  ekKonditionenDefinitionId?: number
  fhEinheit?: string
  frachtzuschlag?: number
  /** @format int32 */
  freihausgrenze?: number
  /** @format int32 */
  freihausmenge?: number
  geloescht?: boolean
  geloeschtDz?: boolean
  /** @format date */
  geloeschtDzGueltigBis?: string
  /** @format date */
  geloeschtDzGueltigVon?: string
  gesperrt?: boolean
  gesperrtDz?: boolean
  /** @format date */
  gesperrtDzGueltigBis?: string
  /** @format date */
  gesperrtDzGueltigVon?: string
  gruppe?: string
  handelsCode?: string
  hauptAdresse?: LieferantAdresseJson
  /** @format int64 */
  id?: number
  iln?: string
  landKategorie?: LieferantLandKategorie
  lieferantArt?: LieferantArt
  lieferbedingung?: string
  mindestBestellwert?: number
  nummer?: string
  /** @format int32 */
  planzeit?: number
  sprache?: string
  steuernummer?: string
  suchbegriff?: string
  unsereKundennummer?: string
  ustIDNummer?: string
  /** @format int32 */
  version?: number
  vorlauf?: string
  waehrung?: string
  zahlungsZiel?: string
}

export interface DzLieferantXML {
  'FH-Einheit'?: string
  Adresse?: DzLieferantAdresseXML[]
  Aenderungsdatum?: string
  Branche?: string[]
  Buchungssperre?: boolean
  EDI?: boolean
  /** @format int32 */
  EDI_DSP?: number
  EDI_Test?: boolean
  /** Kennzeichen, dass es sich um eine Eigenlistung handelt, ansonsten ist es eine zentraler geführter Lieferant. */
  Eigenlistung?: boolean
  Einkaeufergruppe?: DzLieferantEinkaeuferGruppe
  Einkaufssperre?: boolean
  EkKonditionenDefinition?: DzEkKonditionenDefinitionXML
  Frachtzuschlag?: number
  /** @format int32 */
  Freihausgrenze?: number
  /** @format int32 */
  Freihausmenge?: number
  Geloescht?: boolean
  GeloeschtDz?: boolean
  /** @format date */
  GeloeschtDzGueltigBis?: string
  /** @format date */
  GeloeschtDzGueltigVon?: string
  Gesperrt?: boolean
  GesperrtDz?: boolean
  /** @format date */
  GesperrtDzGueltigBis?: string
  /** @format date */
  GesperrtDzGueltigVon?: string
  HageLieferantNr?: string
  Handelscode?: string
  ILN?: string
  IsArchiviert?: boolean
  /** Kategorisierung eines Landes nach I: Inland, E: EU, D: Drittland. */
  LandKategorie?: string
  /** Lieferantenart, Werte sind W: Warenlieferant K: Kostenlieferant. */
  LieferantArt?: string
  Lieferbedingung?: string
  MindestBestellwert?: number
  Nr?: string
  /** @format int32 */
  Planzeit?: number
  Steuernummer?: string
  Suchbegriff?: string
  UStIDNummer?: string
  UnsereKundennummer?: string
  Vorlauf?: string
  Waehrung?: string
  Zahlungsziel?: string
  branchen?: string[]
}

export interface DzLieferantenArtikelXML {
  Angebotstext?: string
  ArtikelME?: DzArtikelMEXML[]
  Ausgelistet?: boolean
  /** @format int32 */
  Einkaeufergruppe?: number
  EinkaufMeErpId?: string
  EinkaufMeName?: string
  EinkaufPmWert?: number
  GefahrstoffLink?: string
  Hauptlieferant?: boolean
  IAN?: string
  IsArchiviert?: boolean
  IsGeloescht?: boolean
  IsGesperrt?: boolean
  KeinePreispflege?: boolean
  LieferantErpId?: string
  LieferantNummer?: string
  /** @format int32 */
  Lieferzeit?: number
  MindestBestellmenge?: number
  RabattgruppeErpId?: string
}

export enum DzMapperEinstellungTyp {
  ARSST = 'ARSST',
  LISST = 'LISST'
}

export interface DzMengenEinheitXML {
  Art?: MengeneinheitArt
  Bezeichnung?: string
  Kurzbez?: string
  Name?: string
}

export interface DzPlakatXML {
  Text1?: string
  Text2?: string
  Text3?: string
  Text4?: string
  Text5?: string
  Text6?: string
  Text7?: string
  Text8?: string
  Zusatztext1?: string
  Zusatztext2?: string
  Zusatztext3?: string
  Zusatztext4?: string
  Zusatztext5?: string
  Zusatztext6?: string
  Zusatztext7?: string
  Zusatztext8?: string
  Layout?: string
}

export interface DzPreisgruppeXML {
  Bezeichnung?: string
  IsBasis?: boolean
  IsBrutto?: boolean
  IsEH?: boolean
  IsFH?: boolean
  IsKasse?: boolean
  IsRundung?: boolean
  Name?: string
}

export interface DzPreismengeXML {
  Bezeichnung?: string
  Faktor?: number
  Kurzbez?: string
  Name?: string
  Preiseinheit?: string
}

export enum DzReferenceTyp {
  EAN_GRUPPE = 'EAN_GRUPPE',
  ARTIKEL_ART = 'ARTIKEL_ART',
  STEUER = 'STEUER',
  LIEFERANT = 'LIEFERANT',
  WARENGRUPPE = 'WARENGRUPPE',
  MENGENEINHEIT = 'MENGENEINHEIT',
  RABATTGRUPPE = 'RABATTGRUPPE',
  PREISMENGE = 'PREISMENGE',
  PREISGRUPPE = 'PREISGRUPPE'
}

export interface DzReferenzMapEntryXML {
  /** @format int32 */
  Austauschstandard?: number
  ExtRef?: string
  Ref?: string
  Typ?: DzReferenceTyp
}

export interface DzRundungsregelXML {
  Bezeichnung?: string
  /** @format int32 */
  GanzeCent?: number
  Name?: string
  bezechnug?: string
}

export interface DzSparteXML {
  Bezeichnung?: string
  Nr?: string
}

export interface DzStandortArtikelXML {
  HauptLieferantErpId?: string
  LagerKz?: LagerKz
  StandortErpId?: string
}

export interface DzStandortXML {
  Bezeichnung?: string
  EANGruppeErpId?: string
  /** @format int32 */
  Nummer?: number
  UeberegionalerStandortErpId?: string
  ueberegionalerStandortErbId?: string
}

export interface DzSteuerXML {
  Bezeichnung?: string
  Name?: string
}

/** One parts list item */
export interface DzStueliXML {
  /**
   * Number of HageNr items in this parts list
   * @example 20
   */
  Anzahl?: number
  /**
   * HageNr
   * @example "840765"
   */
  HageNr?: string
}

/** Definition of parts list (Stückliste) */
export interface DzStuelisXML {
  /** One parts list item */
  Stueli?: DzStueliXML[]
}

export interface DzVkPreisBlattXML {
  ArtikelErpId?: string
  Eigenpflege?: VkPreisQuelle
  EigenpflegeStaffel?: VkPreisQuelle
  EkNichtSkonto?: number
  EnthalteneFrachtZufuhr?: number
  Festpreis?: boolean
  FrachtAbs?: number
  FrachtArt?: VkFrachtArt
  FrachtNachRabatt?: boolean
  FrachtNichtSkonto?: boolean
  FrachtProz?: number
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  KalkulationsvorschlagErpId?: string
  ReferenzPreisgruppeEHErpId?: string
  ReferenzPreisgruppeErpId?: string
  RundungsregelErpId?: string
  StandortErpId?: string
  VkNichtSkonto?: number
  VkPreis?: DzVkPreisXML[]
  ZufuhrMultiErpId?: string
}

export interface DzVkPreisXML {
  Aufschlag?: number
  Basis?: number
  Basistyp?: VkPreisBasisTyp
  Etikettpreis?: number
  IsBrutto?: boolean
  IsFix?: boolean
  Preis?: number
  PreisgruppeErpId?: string
  Spanne?: number
  StaffelRabattAbs?: number
  StaffelRabattProz?: number
  StaffelTyp?: VkPreisStaffelTyp
  Staffelmenge?: number
  VpEinheitErpId?: string
  Vpmenge?: number
}

export interface DzWarengruppeXML {
  ArtikelGruppe?: string
  Bezeichnung?: string
  Suchbegriff?: string
}

export interface EANGruppeDisplayJson {
  bezeichnung?: string
  /** @format int64 */
  id?: number
  name?: string
}

export interface EANGruppeJson {
  bezeichnung?: string
  /** @format int64 */
  id?: number
  name?: string
  standorte?: StandortJson[]
}

export interface EANSuchArtikelListeJson {
  items?: Record<string, ArtikelDisplayJson[]>
  state?: UseCaseStateJson
}

export interface EKPreisRabattXML {
  RabattProz1?: number
  RabattProz2?: number
  RabattProz3?: number
  RabattProz4?: number
  RabattProz5?: number
  ZuschlAbs1?: number
  ZuschlAbs2?: number
  ZuschlAbs3?: number
  ZuschlAbs4?: number
  ZuschlAbs5?: number
  EkFreiHaus?: number
  Hauptstaffel?: boolean
  Listenpreis?: number
  Sonderpreis?: number
  Staffelmenge?: number
}

export enum EanZuordnung {
  UNBEKANNT = 'UNBEKANNT',
  ZUGEORDNET = 'ZUGEORDNET',
  IGNORIERT = 'IGNORIERT'
}

export interface EigenlistungsArtikelBearbeitenJson {
  laender?: PimLandDisplayJson[]
  mengeneinheiten?: PimMengeneinheitDisplayJson[]
  seArtikel?: SeArtikelJson
  state?: UseCaseStateJson
  waehrungen?: PimWaehrungDisplayJson[]
}

export interface EigenlistungsLieferantBearbeitenJson {
  seLieferant?: SeLieferantJson
  state?: UseCaseStateJson
}

export interface EkKonditionenDefinitionBearbeitenJson {
  data?: EkKonditionenDefinitionJson
  state?: UseCaseStateJson
}

export interface EkKonditionenDefinitionJson {
  bezRabattProz1?: string
  bezRabattProz2?: string
  bezRabattProz3?: string
  bezRabattProz4?: string
  bezRabattProz5?: string
  bezZuschlAbs1?: string
  bezZuschlAbs2?: string
  bezZuschlAbs3?: string
  bezZuschlAbs4?: string
  bezZuschlAbs5?: string
  kurzRabattProz1?: string
  kurzRabattProz2?: string
  kurzRabattProz3?: string
  kurzRabattProz4?: string
  kurzRabattProz5?: string
  kurzZuschlAbs1?: string
  kurzZuschlAbs2?: string
  kurzZuschlAbs3?: string
  kurzZuschlAbs4?: string
  kurzZuschlAbs5?: string
  rabattProz1?: boolean
  rabattProz2?: boolean
  rabattProz3?: boolean
  rabattProz4?: boolean
  rabattProz5?: boolean
  zuschlAbs1?: boolean
  zuschlAbs2?: boolean
  zuschlAbs3?: boolean
  zuschlAbs4?: boolean
  zuschlAbs5?: boolean
  /** @format int64 */
  id?: number
  /** @format int32 */
  sonderpreisInRabatt?: number
  /** @format int32 */
  version?: number
  waehrung?: string
}

export interface EkKonditionenEbeneDisplayJson {
  /** @format int64 */
  id?: number
  rabattVerwendung?: EkKonditionenRabattVerwendung
  rabattgruppe?: EkKonditionenRabattgruppeKopfJson
  staffelgruppe?: StaffelgruppeDisplayJson
  werte?: EkKonditionenWertJson[]
}

export interface EkKonditionenEbeneJson {
  /** @format int64 */
  id?: number
  rabattVerwendung?: EkKonditionenRabattVerwendung
  /** @format int64 */
  rabattgruppeId?: number
  /** @format int64 */
  staffelgruppeId?: number
  werte?: EkKonditionenWertJson[]
}

export interface EkKonditionenJson {
  abgeschlossen?: boolean
  /** @format int64 */
  artikelRabattgruppeId?: number
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  konditionsEbenen?: EkKonditionenEbeneJson[]
  listenpreis?: number
  listenpreisAusAlterKondition?: number
  /** @format date */
  listenpreisGueltigVon?: string
  mengenEinheit?: string
  mengenEinheitKurz?: string
  preismenge?: number
  /** @format int32 */
  version?: number
}

export interface EkKonditionenListeDisplayJson {
  abgeschlossen?: boolean
  /** @format date-time */
  angelegtUm?: string
  /** @format int64 */
  anzArtikel?: number
  /** @format int64 */
  anzArtikelAbgeschlossen?: number
  freigegeben?: boolean
  /** @format date-time */
  freigegebenUm?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  /** @format int32 */
  kannAbschliessen?: number
  /** @format int32 */
  kannFreigeben?: number
  kontext?: NeonKontextDisplayJson
  /** @format date-time */
  letztePflege?: string
  lieferant?: LieferantDisplayJson
  name?: string
  preisEbene?: PreisEbeneDisplayJson
  status?: string
  typ?: PreisListeTyp
}

export interface EkKonditionenListeEintragDisplayJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  /** @format int64 */
  artikelId?: number
  btNummer?: string
  hageNummer?: string
  /** @format int64 */
  id?: number
}

export interface EkKonditionenListeSearcherCriteriaJson {
  abgeschlossene?: boolean
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  lieferant?: LieferantDisplayJson
  name?: string
}

export interface EkKonditionenListeSearcherJson {
  criteria?: EkKonditionenListeSearcherCriteriaJson
  limited?: boolean
  result?: EkKonditionenListeDisplayJson[]
}

export enum EkKonditionenRabattVerwendung {
  NUR_RABATTGRUPPE = 'NUR_RABATTGRUPPE',
  IGNORIERE_ABS_RABATT = 'IGNORIERE_ABS_RABATT',
  ADDITIV_ABS_RABATT = 'ADDITIV_ABS_RABATT'
}

export interface EkKonditionenRabattgruppeDisplayJson {
  rabattProz1?: number
  rabattProz2?: number
  rabattProz3?: number
  rabattProz4?: number
  rabattProz5?: number
  zuschlAbs1?: number
  zuschlAbs2?: number
  zuschlAbs3?: number
  zuschlAbs4?: number
  zuschlAbs5?: number
  bezeichnung?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  /** @format int64 */
  kopfId?: number
  name?: string
  preisEbene?: PreisEbeneDisplayJson
  /** @format int64 */
  staffelgruppeId?: number
  staffelmenge?: number
  /** @format int64 */
  versionId?: number
}

export interface EkKonditionenRabattgruppeJson {
  ekPreislisteErzeugen?: boolean
  /** @format int64 */
  id?: number
  kopf?: EkKonditionenRabattgruppeKopfJson
  lieferant?: LieferantDisplayTinyJson
  versionen?: EkKonditionenRabattgruppeVersionJson[]
}

export interface EkKonditionenRabattgruppeKopfJson {
  bezeichnung?: string
  /** @format int64 */
  id?: number
  name?: string
}

export interface EkKonditionenRabattgruppeVersionChangeJson {
  alt?: EkKonditionenRabattgruppeVersionJson
  neu?: EkKonditionenRabattgruppeVersionJson
}

export interface EkKonditionenRabattgruppeVersionJson {
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  kopf?: EkKonditionenRabattgruppeKopfJson
  /** @format int64 */
  preisEbeneId?: number
  /** @format int64 */
  staffelgruppeId?: number
  staffelwerte?: EkKonditionenRabattgruppeWertJson[]
}

export interface EkKonditionenRabattgruppeWertJson {
  rabattProz1?: number
  rabattProz2?: number
  rabattProz3?: number
  rabattProz4?: number
  rabattProz5?: number
  zuschlAbs1?: number
  zuschlAbs2?: number
  zuschlAbs3?: number
  zuschlAbs4?: number
  zuschlAbs5?: number
  /** @format int64 */
  id?: number
  staffelmenge?: number
}

export interface EkKonditionenRabattgruppenListeChangeJson {
  changes?: EkKonditionenRabattgruppenListeEintragChangeJson[]
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  /** @format int64 */
  lieferantId?: number
  /** @format int32 */
  version?: number
}

export interface EkKonditionenRabattgruppenListeEintragChangeJson {
  rabattProz1?: number
  rabattProz2?: number
  rabattProz3?: number
  rabattProz4?: number
  rabattProz5?: number
  zuschlAbs1?: number
  zuschlAbs2?: number
  zuschlAbs3?: number
  zuschlAbs4?: number
  zuschlAbs5?: number
  /** @format int64 */
  id?: number
  /** @format int64 */
  preisEbeneId?: number
  /** @format int64 */
  rabattgruppeId?: number
  /** @format int64 */
  staffelgruppeId?: number
  staffelmenge?: number
}

export interface EkKonditionenRabattgruppenListeEintragJson {
  rabattProz1?: number
  rabattProz2?: number
  rabattProz3?: number
  rabattProz4?: number
  rabattProz5?: number
  zuschlAbs1?: number
  zuschlAbs2?: number
  zuschlAbs3?: number
  zuschlAbs4?: number
  zuschlAbs5?: number
  /** @format int64 */
  id?: number
  preisEbene?: PreisEbeneDisplayJson
  rabattgruppe?: EkKonditionenRabattgruppeKopfJson
  rabattgruppenWertAlt?: EkKonditionenRabattgruppeWertJson
  staffelgruppe?: StaffelgruppeDisplayJson
  staffelmenge?: number
}

export interface EkKonditionenRabattgruppenListeJson {
  akt?: boolean
  audit?: HKMAuditJson
  eintraege?: EkKonditionenRabattgruppenListeEintragJson[]
  /** @format date-time */
  freigegebenUm?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  /** @format int32 */
  version?: number
}

export interface EkKonditionenRabattgruppenViewJson {
  /** @format int64 */
  id?: number
  kopf?: EkKonditionenRabattgruppeKopfJson
  preisEbenenVersions?: EkKonditionenRabattgruppeVersionJson[]
  version?: EkKonditionenRabattgruppeVersionJson
}

export interface EkKonditionenWertJson {
  rabattProz1?: number
  rabattProz2?: number
  rabattProz3?: number
  rabattProz4?: number
  rabattProz5?: number
  zuschlAbs1?: number
  zuschlAbs2?: number
  zuschlAbs3?: number
  zuschlAbs4?: number
  zuschlAbs5?: number
  ekFreiHaus?: number
  hauptstaffel?: boolean
  /** @format int64 */
  id?: number
  listenpreis?: number
  /** @format int64 */
  rabattgruppeId?: number
  sonderpreis?: number
  staffelmenge?: number
  /** @format int64 */
  standortId?: number
  /** @format int32 */
  version?: number
}

export interface EkKonditionenXML {
  EKPreisRabatt?: EKPreisRabattXML[]
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  Lieferant?: string
  Listenpreis?: number
  Mengeneinheit?: string
  MengeneinheitI?: string
  Preismenge?: number
  RabattVerwendung?: EkKonditionenRabattVerwendung
}

export interface EkListenPreisXML {
  Artikel?: string
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  Lieferant?: string
  Listenpreis?: number
  Mengeneinheit?: string
  Preismenge?: number
}

export interface EkPreisDiffJson {
  alt?: PreisDiffJson
  neu?: PreisDiffJson
  zeLieferant?: LieferantDisplayJson
}

export interface EkPreisListImportAssistentNextJson {
  step?: EkPreisListImportAssistentStepJson
  /** @format int64 */
  uploadId?: number
}

export interface EkPreisListImportAssistentStepDisplayJson {
  executed?: boolean
  lastStep?: boolean
  ok?: boolean
  stepId?: EkPreisListImportStepId
  stepName?: string
  /** @format int32 */
  stepNummer?: number
}

export interface EkPreisListImportAssistentStepIANDuplikateArtikelJson {
  artikel?: ArtikelDisplaySmallJson
  bepreisen?: boolean
  ean?: string
  ian?: string
  /** @format int64 */
  id?: number
  original?: boolean
  /** @format int32 */
  zeilenNr?: number
}

export interface EkPreisListImportAssistentStepIANDuplikateChangeJson {
  bepreisen?: boolean
  /** @format int64 */
  id?: number
}

export interface EkPreisListImportAssistentStepIANDuplikateJson {
  artikel?: EkPreisListImportAssistentStepIANDuplikateArtikelJson[]
  artikelText?: string
  eanAusDatei?: string
  ianAusDatei?: string
  /** @format int32 */
  zeilenNr?: number
}

export interface EkPreisListImportAssistentStepJson {
  allLabels?: LabelJson[]
  allePreisEbenen?: PreisEbeneDisplayJson[]
  alleWarengruppen?: WarengruppeDisplayJson[]
  artikelListeRef?: string
  executed?: boolean
  ianDuplikate?: EkPreisListImportAssistentStepIANDuplikateJson[]
  ianDuplikateChanges?: EkPreisListImportAssistentStepIANDuplikateChangeJson[]
  kondiListeCreateVkPreisvorgang?: boolean
  kondiListeFreigeben?: boolean
  lastStep?: boolean
  nichtGetroffenenArtikel?: EkPreisListImportAssistentStepNichtGetroffeneArtikelJson[]
  nichtGetroffenenArtikelChanges?: EkPreisListImportAssistentStepNichtGetroffeneArtikelChangeJson[]
  ok?: boolean
  peMeUnterschiede?: EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson[]
  peMeUnterschiedeChanges?: EkPreisListImportAssistentStepPeMeUnterschiedChangeJson[]
  stepId?: EkPreisListImportStepId
  stepLabelIds?: ArtikelLabelByTypeZuordnungJson[]
  stepName?: string
  /** @format int32 */
  stepNummer?: number
}

export interface EkPreisListImportAssistentStepNichtGetroffeneArtikelChangeJson {
  /** @format int64 */
  id?: number
  ok?: boolean
}

export interface EkPreisListImportAssistentStepNichtGetroffeneArtikelJson {
  artikel?: ArtikelDisplaySmallJson
  btNummer?: string
  ean?: string
  ekPreis?: number
  ian?: string
  /** @format int64 */
  id?: number
  keinePreispflege?: boolean
  minEinLager?: boolean
  ok?: boolean
  preisEbenenIdsMitEkPreise?: number[]
  /** @format int64 */
  seWgId?: number
}

export interface EkPreisListImportAssistentStepPeMeUnterschiedArtikelJson {
  artikel?: ArtikelDisplaySmallJson
  bepreisen?: boolean
  konnteUmgerechnetWerden?: boolean
  lagerartikel?: boolean
  meErp?: MengeneinheitJson
  peErp?: number
  werte?: EkPreisListImportAssistentStepPeMeUnterschiedWertJson[]
  /** @format int32 */
  zeilenNr?: number
}

export interface EkPreisListImportAssistentStepPeMeUnterschiedChangeJson {
  bepreisen?: boolean
  /** @format int64 */
  suArtikelId?: number
}

export interface EkPreisListImportAssistentStepPeMeUnterschiedWertJson {
  ausDateiMe?: string
  ausDateiPe?: number
  ekListenpreis?: number
  ekListenpreisUmgerechnet?: number
  ekSonderpreis?: number
  ekSonderpreisUmgerechnet?: number
  fehlerTypMe?: boolean
  fehlerTypPe?: boolean
  /** @format int64 */
  id?: number
  konnteUmgerechnetWerden?: boolean
  staffelAb?: number
  umrechnungsfaktorME?: BruchJson
}

export enum EkPreisListImportStepId {
  STEP1PARS = 'STEP1_PARS',
  STEP2FORMAT = 'STEP2_FORMAT',
  STEP3NTARTIKEL = 'STEP3_NT_ARTIKEL',
  STEP4IAN = 'STEP4_IAN',
  STEP5EKMEDIFF = 'STEP5_EK_ME_DIFF',
  STEP6EKSIM = 'STEP6_EK_SIM',
  STEP7STATISTIKENDE = 'STEP7_STATISTIK_ENDE'
}

export interface EkPreisListeAbschliessenActionJson {
  abgeschlossen?: boolean
  eintraege?: number[]
  /** @format int64 */
  listeId?: number
  /** @format int64 */
  preisEbeneId?: number
}

export interface EkPreisListeArtikelRabattgruppeActionJson {
  eintraege?: number[]
  /** @format int64 */
  listeId?: number
  /** @format int64 */
  preisEbeneId?: number
  /** @format int64 */
  rabattgruppeId?: number
}

export interface EkPreisListeDisplayJson {
  abgeschlossen?: boolean
  /** @format int64 */
  anzArtikel?: number
  /** @format int64 */
  anzArtikelAbgeschlossen?: number
  betriebstyp?: ArtikelBetriebstyp
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  /** @format int64 */
  konditionenListeId?: number
  kontext?: NeonKontext
  lieferant?: LieferantDisplayJson
  name?: string
  preisEbene?: PreisEbeneDisplayJson
}

export interface EkPreisListeImportBearbeitenJson {
  data?: EkPreisListeImportJson
  state?: UseCaseStateJson
}

export interface EkPreisListeImportDefinitionBearbeitenJson {
  allLabels?: LabelJson[]
  data?: EkPreisListeImportDefinitionJson
  state?: UseCaseStateJson
}

export interface EkPreisListeImportDefinitionJson {
  /** @format int64 */
  id?: number
  steps?: EkPreisListeImportDefinitionStepJson[]
  /** @format int32 */
  version?: number
}

export interface EkPreisListeImportDefinitionMappingBearbeitenJson {
  data?: EkPreisListeImportDefinitionMappingJson
  state?: UseCaseStateJson
}

export interface EkPreisListeImportDefinitionMappingEntryJson {
  audit?: HKMAuditJson
  defExtern?: string
  defIntern?: string
  /** @format int64 */
  id?: number
  /** @format int32 */
  version?: number
}

export interface EkPreisListeImportDefinitionMappingJson {
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
  mappingType?: EkPreisListeImportDefinitionMappingType
  mappings?: EkPreisListeImportDefinitionMappingEntryJson[]
  /** @format int64 */
  seLieferantId?: number
  /** @format int32 */
  version?: number
}

export interface EkPreisListeImportDefinitionMappingSearcherCriteriaJson {
  mappingType?: EkPreisListeImportDefinitionMappingType
  /** @format int64 */
  suLieferantId?: number
}

export interface EkPreisListeImportDefinitionMappingSearcherJson {
  criteria?: EkPreisListeImportDefinitionMappingSearcherCriteriaJson
  limited?: boolean
  result?: EkPreisListeImportDefinitionMappingEntryJson[]
}

export enum EkPreisListeImportDefinitionMappingType {
  MENGENEINHEIT = 'MENGENEINHEIT'
}

export interface EkPreisListeImportDefinitionMappingUebersichtJson {
  searcher?: EkPreisListeImportDefinitionMappingSearcherJson
  state?: UseCaseStateJson
}

export interface EkPreisListeImportDefinitionStepJson {
  artikelLabelIds?: Record<string, number[]>
  freigabe?: EkPreisListeImportDefinitionStepSchrittfreigabe
  hasLabels?: boolean
  /** @format int64 */
  id?: number
  name?: string
  needVerifikation?: boolean
  optional?: boolean
  /** @format int32 */
  order?: number
  peMeUmrechnen?: boolean
  status?: EkPreisListeImportDefinitionStepStatus
  stepId?: EkPreisListImportStepId
}

export enum EkPreisListeImportDefinitionStepLabelsType {
  DEFAULT_AUTOMATISCH = 'DEFAULT_AUTOMATISCH',
  DEFAULT_MANUELL = 'DEFAULT_MANUELL',
  DUPLIKATE_BEPREISEN = 'DUPLIKATE_BEPREISEN',
  DUPLIKATE_NICHT_BEPREISEN = 'DUPLIKATE_NICHT_BEPREISEN',
  PE_ME_UNTERSCHIEDE_NICHT_BEPREISEN = 'PE_ME_UNTERSCHIEDE_NICHT_BEPREISEN'
}

export enum EkPreisListeImportDefinitionStepSchrittfreigabe {
  MANUELL = 'MANUELL',
  AUTOMATISCH = 'AUTOMATISCH'
}

export enum EkPreisListeImportDefinitionStepStatus {
  AKTIVIERT = 'AKTIVIERT',
  DEAKTIVIERT = 'DEAKTIVIERT'
}

export interface EkPreisListeImportDisplayJson {
  /** @format int32 */
  anzahlFehler?: number
  /** @format int32 */
  anzahlIgnoriert?: number
  /** @format int32 */
  anzahlZeilen?: number
  /** @format int64 */
  asyncTaskId?: number
  /** @format int64 */
  dateigroesse?: number
  dateiname?: string
  /** @format date-time */
  erstelltAm?: string
  /** @format date */
  gueltigAb?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  preisliste?: EkPreisListeDisplayJson
  status?: EkPreisListeImportStatus
}

export interface EkPreisListeImportFehlerZeileJson {
  autoIgnoriert?: boolean
  ignoriert?: boolean
  messages?: MessageJson[]
  severity?: MessageSeverity
  /** @format int32 */
  zeile?: number
}

export interface EkPreisListeImportJson {
  aktStep?: EkPreisListImportAssistentStepJson
  aktStepId?: EkPreisListImportStepId
  allSteps?: EkPreisListImportAssistentStepDisplayJson[]
  /** @format int32 */
  anzahlArtikelEKPreisblaetterErzeugt?: number
  /** @format int32 */
  anzahlArtikelNichtIdentifiziert?: number
  /** @format int32 */
  anzahlDuplikateBepreisen?: number
  /** @format int32 */
  anzahlFehler?: number
  /** @format int32 */
  anzahlIgnoriert?: number
  /** @format int32 */
  anzahlNichtGetroffenerArtikel?: number
  /** @format int32 */
  anzahlProhibisIdentifiziertenArtikel?: number
  /** @format int32 */
  anzahlZeilen?: number
  audit?: HKMAuditJson
  bemerkung?: string
  betriebstyp?: ArtikelBetriebstyp
  /** @format int64 */
  dateigroesse?: number
  dateiname?: string
  fehlerZeilen?: EkPreisListeImportFehlerZeileJson[]
  globaleFehler?: MessageJson[]
  /** @format date */
  gueltigAb?: string
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  /** @format date-time */
  lastChange?: string
  /** @format date-time */
  lastUpload?: string
  lieferant?: LieferantDisplayTinyJson
  name?: string
  nichtGefundenArtikel?: EkPreisListeImportNichtGefundeneArtikelJson[]
  preisliste?: EkPreisListeDisplayJson
  status?: EkPreisListeImportStatus
  type?: SpreadSheetType
  /** @format int32 */
  version?: number
}

export interface EkPreisListeImportNichtGefundeneArtikelJson {
  ean?: string
  ian?: string
  /** @format int64 */
  id?: number
  keinePreispflege?: boolean
  zuAbonierenderArtikel?: ArtikelDisplayTinyJson
}

export interface EkPreisListeImportSaveJson {
  bemerkung?: string
  /** @format int64 */
  id?: number
  /** @uniqueItems true */
  ignorierteZeilen?: number[]
  name?: string
  /** @format int32 */
  version?: number
}

export interface EkPreisListeImportSearcherCriteriaJson {
  /** @format date */
  erstelltAb?: string
  /** @format date */
  gueltigAb?: string
  /** @format int64 */
  id?: number
  /** @format int64 */
  lieferantId?: number
  nurOffene?: boolean
  status?: EkPreisListeImportStatus
}

export interface EkPreisListeImportSearcherJson {
  criteria?: EkPreisListeImportSearcherCriteriaJson
  limited?: boolean
  result?: EkPreisListeImportDisplayJson[]
}

export enum EkPreisListeImportStatus {
  NEU = 'NEU',
  VALIDATE = 'VALIDATE',
  VALID = 'VALID',
  INVALID = 'INVALID',
  IMPORT = 'IMPORT',
  IMPORTIERT = 'IMPORTIERT',
  ERLEDIGT = 'ERLEDIGT',
  FEHLER = 'FEHLER'
}

export interface EkPreisListeImportUebersichtJson {
  searcher?: EkPreisListeImportSearcherJson
  state?: UseCaseStateJson
}

export interface EkPreisListePreisJson {
  rabattProz1?: number
  rabattProz2?: number
  rabattProz3?: number
  rabattProz4?: number
  rabattProz5?: number
  zuschlAbs1?: number
  zuschlAbs2?: number
  zuschlAbs3?: number
  zuschlAbs4?: number
  zuschlAbs5?: number
  /** @format int64 */
  rabattgruppeId?: number
  sonderpreis?: number
  staffelmenge?: number
}

export interface EkPreisListePreiseActionJson {
  abgeschlossen?: boolean
  eintraege?: number[]
  /** @format int64 */
  listeId?: number
  /** @format int64 */
  preisEbeneId?: number
  rabattVerwendung?: EkKonditionenRabattVerwendung
  /** @format int64 */
  rabattgruppeId?: number
  rows?: EkPreisListePreisJson[]
}

export interface EkPreisListeUploadResultJson {
  /** @format int64 */
  id?: number
  state?: UseCaseStateJson
}

export interface EkPreisWertJson {
  abs1?: number
  abs2?: number
  abs3?: number
  abs4?: number
  abs5?: number
  proz1?: number
  proz2?: number
  proz3?: number
  proz4?: number
  proz5?: number
  delta?: number
  freiHaus?: number
  hauptstaffel?: boolean
  /** @format int64 */
  id?: number
  lastFreiHaus?: number
  listenpreis?: number
  rabattgruppe?: EkKonditionenRabattgruppeKopfJson
  sonderpreis?: number
  staffelmenge?: number
}

export interface EkPreiseActionResultJson {
  actionAbschliessen?: ActionInfoJson
  actionFreigeben?: ActionInfoJson
  artikelListe?: EkPreiseMasseBearbeitenEintragJson[]
  /** @format int64 */
  id?: number
  /** @format int64 */
  listeId?: number
  state?: UseCaseStateJson
}

export interface EkPreiseArtikelListeJson {
  artikelListe?: EkKonditionenListeEintragDisplayJson[]
  /** @format int64 */
  id?: number
  state?: UseCaseStateJson
}

export interface EkPreiseBearbeitenJson {
  actionFreigeben?: ActionInfoJson
  allianzModus?: boolean
  artikel?: ArtikelDisplayJson
  artikelListeRef?: string
  artikelMitAnderemHauptlieferanten?: boolean
  bemerkung?: string
  betriebstyp?: ArtikelBetriebstyp
  /** @format int64 */
  eintragId?: number
  ekRabattgruppen?: EkKonditionenRabattgruppenViewJson[]
  ekRabattgruppenHistorische?: EkKonditionenRabattgruppeVersionJson[]
  freigegeben?: boolean
  /** @format date-time */
  freigegebenUm?: string
  /** @format int64 */
  id?: number
  kondiDefinition?: EkKonditionenDefinitionJson
  kontext?: NeonKontext
  lieferant?: LieferantDisplayJson
  listeDisplay?: EkKonditionenListeDisplayJson
  name?: string
  neueKondi?: EkKonditionenJson
  /** @format int64 */
  preisEbeneId?: number
  preisEbenen?: PreisEbeneDisplayJson[]
  /** @format int64 */
  staffelgruppeManuellId?: number
  staffelgruppen?: StaffelgruppeDisplayJson[]
  state?: UseCaseStateJson
  vergangeneListe?: EkKonditionenJson[]
}

export interface EkPreiseMasseBearbeitenEintragJson {
  abgeschlossen?: boolean
  artikel?: ArtikelDisplaySmallJson
  artikelRabattgruppe?: EkKonditionenRabattgruppeKopfJson
  ebeneRabattgruppe?: EkKonditionenRabattgruppeKopfJson
  einheit?: string
  /** @format int64 */
  id?: number
  neu?: boolean
  /** @format int64 */
  preisEbeneId?: number
  staffelgruppe?: StaffelgruppeDisplayJson
  werte?: EkPreisWertJson[]
}

export interface EkPreiseMasseBearbeitenKopfJson {
  actionAbschliessen?: ActionInfoJson
  actionFreigeben?: ActionInfoJson
  allianzModus?: boolean
  artikelListe?: EkPreiseMasseBearbeitenEintragJson[]
  bemerkung?: string
  betriebsTyp?: ArtikelBetriebstyp
  ekRabattgruppen?: EkKonditionenRabattgruppenViewJson[]
  freigegeben?: boolean
  /** @format date-time */
  freigegebenUm?: string
  /** @format int64 */
  id?: number
  kondiDefinition?: EkKonditionenDefinitionJson
  kontext?: NeonKontext
  name?: string
  /** @format int64 */
  preisEbeneId?: number
  preisEbenen?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
  /** @uniqueItems true */
  usedPreisEbenenIds?: number[]
}

export interface EkPreispflegeUebersichtJson {
  searcher?: EkKonditionenListeSearcherJson
  state?: UseCaseStateJson
}

export interface EkRabattgruppenBearbeitenJson {
  artikelListeEkPreiseRef?: string
  /** @format int64 */
  id?: number
  konditionDefinition?: EkKonditionenDefinitionJson
  preisEbenen?: PreisEbeneDisplayJson[]
  rabattgruppe?: EkKonditionenRabattgruppeJson
  staffelgruppen?: StaffelgruppeDisplayJson[]
  state?: UseCaseStateJson
  weitereArtikelListeEkPreise?: boolean
}

export interface EkRabattgruppenListeBearbeitenJson {
  artikelListeEkPreiseRef?: string
  ekRabattgruppenListe?: EkKonditionenRabattgruppenListeJson
  konditionDefinition?: EkKonditionenDefinitionJson
  preisEbenen?: PreisEbeneDisplayJson[]
  staffelgruppen?: StaffelgruppeDisplayJson[]
  state?: UseCaseStateJson
}

export interface EkRabattgruppenSearcherCriteriaJson {
  /** @format date */
  gueltigVon?: string
  lieferant?: LieferantDisplayTinyJson
  lieferantNr?: string
}

export interface EkRabattgruppenSearcherJson {
  criteria?: EkRabattgruppenSearcherCriteriaJson
  konditionDefinition?: EkKonditionenDefinitionJson
  limited?: boolean
  result?: EkKonditionenRabattgruppeDisplayJson[]
}

export interface EkRabattgruppenUebersichtJson {
  searcher?: EkRabattgruppenSearcherJson
  state?: UseCaseStateJson
}

export interface EmptyResponseJson {
  state?: UseCaseStateJson
}

export interface EosConfirmArtikelMessageXML {
  AktionCode?: ErpAktionCode
  Artikel?: PimArtikelXML
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  erpId?: string
  modelXML?: PimArtikelXML
}

export interface EosConfirmLieferantMessageXML {
  AktionCode?: ErpAktionCode
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  Lieferant?: DzLieferantXML
  MessageHeader?: MessageHeaderXML
  erpId?: string
  modelXML?: DzLieferantXML
}

export interface EosConfirmStandortArtikelEkPreiseMessageXML {
  AktionCode?: ErpAktionCode
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  erpId?: string
  /** Struktur für den Austausch von StandortArtikel Ek-Preisen */
  modelXML?: StandortArtikelEkPreiseXML
}

export interface EosConfirmStandortArtikelMessageXML {
  AktionCode?: ErpAktionCode
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  StandortArtikel?: StandortArtikelXML
  erpId?: string
  modelXML?: StandortArtikelXML
}

export interface EosConfirmStandortArtikelVkPreiseMessageXML {
  AktionCode?: ErpAktionCode
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  erpId?: string
  /** Struktur für den Austausch von StandortArtikel Vk-Preisen */
  modelXML?: StandortArtikelVkPreiseXML
}

export interface EosConfirmStandortArtikelVkSperreMessageXML {
  AktionCode?: ErpAktionCode
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  erpId?: string
  /** Struktur für den Austausch von StandortArtikel Vk-Sperre Daten */
  modelXML?: StandortArtikelVkSperreXML
}

export interface EosEnvelopeHeaderXML {
  GLN?: string
  ReplyForRequestId?: string
  RequestId?: string
  RequestLast?: boolean
  /** @format int32 */
  RequestSeq?: number
  Timestamp?: string
}

export interface EosEnvelopeXML {
  ArtikelAnforderungMessage?: EosRequestArtikelXML[]
  ConfirmArtikelMessage?: EosConfirmArtikelMessageXML[]
  ConfirmLieferantMessage?: EosConfirmLieferantMessageXML[]
  ConfirmStandortArtikelEkPreiseMessage?: EosConfirmStandortArtikelEkPreiseMessageXML[]
  ConfirmStandortArtikelMessage?: EosConfirmStandortArtikelMessageXML[]
  ConfirmStandortArtikelVkPreiseMessage?: EosConfirmStandortArtikelVkPreiseMessageXML[]
  ConfirmStandortArtikelVkSperreMessage?: EosConfirmStandortArtikelVkSperreMessageXML[]
  EnvelopeHeader?: EosEnvelopeHeaderXML
  ExportStandortMessage?: EosExportStandortMessageXML[]
  StandortArtikelRegalplaetzeMessages?: StandortArtikelRegalplaetzeXML[]
}

/** Struktur für Export-Nachrichten von EOS an HKM zu Standort-Daten */
export interface EosExportStandortMessageXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  MessageHeader?: MessageHeaderXML
  Standort?: StandortXML
  modelXML?: StandortXML
}

/** Struktur für die Anforderung von Artikel durch EOS */
export interface EosRequestArtikelXML {
  /** Hagebau Artikelnummer (HageNr) */
  Artikel?: string
  Lieferant?: string
  /** @format int32 */
  Standort?: number
}

export enum ErpAktionCode {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  READ = 'READ',
  SYNC = 'SYNC'
}

export interface ErpArtikelDetailsJson {
  aboStatus?: ArtikelAbonniertStatus
  aliasNr?: string
  allAboStatus?: AboArtikelEanGruppeStatusJson[]
  artikelLieferanten?: ArtikelLieferantJson[]
  ekPreise?: ArtikelEkPreiseJson[]
  hauptLieferant?: LieferantDisplayJson
  konditionenDefinitionen?: EkKonditionenDefinitionJson[]
  labels?: LabelJson[]
  listenPreise?: ListenpreisJson[]
  manuelleListen?: ArtikelListeDisplayJson[]
  mengeneinheiten?: MengeneinheitDisplayTinyJson[]
  preisEbenenLieferanten?: PreisEbeneMitLieferantDisplayJson[]
  regalplaetze?: StandortArtikelRegalplatzJson[]
  selektionen?: ArtikelSelektionDisplayJson[]
  sparte?: SparteDisplayJson
  vkPreise?: ArtikelVkBlattJson[]
  vkPreisgruppen?: VkPreisGruppeJson[]
  warengruppe?: WarengruppeDisplayJson
}

export enum ErpCommunticationType {
  HTTP = 'HTTP',
  FILETRANSFER = 'FILETRANSFER',
  JMS = 'JMS',
  KAFKA = 'KAFKA',
  OHNE = 'OHNE'
}

export enum ErpDataImportSubTyp {
  ERPDATAIMPORT = 'ERP-DATA-IMPORT',
  HKMCONFIRMEXPORT = 'HKM-CONFIRM-EXPORT',
  HKMISALIVE = 'HKM-ISALIVE',
  TERPEDITDATARESULT = 'T-ERP-EDIT-DATA-RESULT',
  ERPDATAIMPORTCONFIRMRESULT = 'ERP-DATA-IMPORT-CONFIRM-RESULT',
  UNKNOWN = 'UNKNOWN'
}

export interface ErpEbeneImportRequestJson {
  exportTyp?: ExportErpDataRequestTyp
  /** @format int64 */
  id?: number
  /** @format date-time */
  importEnd?: string
  /** @format date-time */
  importStart?: string
}

export interface ErpStammdatenExportResultJson {
  /** @format int32 */
  confirmedArtikelCount?: number
  /** @format int32 */
  confirmedArtikelErrorCount?: number
  /** @format int32 */
  confirmedEkKonditionenCount?: number
  /** @format int32 */
  confirmedEkKonditionenErrorCount?: number
  /** @format int32 */
  confirmedEkPreiseCount?: number
  /** @format int32 */
  confirmedEkPreiseErrorCount?: number
  /** @format int32 */
  confirmedEkRabattgruppeCount?: number
  /** @format int32 */
  confirmedEkRabattgruppeErrorCount?: number
  /** @format int32 */
  confirmedEkRabattgruppeVersionCount?: number
  /** @format int32 */
  confirmedEkRabattgruppeVersionErrorCount?: number
  /** @format int32 */
  confirmedLieferantenCount?: number
  /** @format int32 */
  confirmedLieferantenErrorCount?: number
  /** @format int32 */
  confirmedStandortArtikelCount?: number
  /** @format int32 */
  confirmedStandortArtikelEkCount?: number
  /** @format int32 */
  confirmedStandortArtikelEkErrorCount?: number
  /** @format int32 */
  confirmedStandortArtikelErrorCount?: number
  /** @format int32 */
  confirmedStandortArtikelVkCount?: number
  /** @format int32 */
  confirmedStandortArtikelVkErrorCount?: number
  /** @format int32 */
  confirmedStandortArtikelVkSperreCount?: number
  /** @format int32 */
  confirmedStandortArtikelVkSperreErrorCount?: number
  /** @format int32 */
  confirmedVkPreiseCount?: number
  /** @format int32 */
  confirmedVkPreiseErrorCount?: number
  /** @format int64 */
  exportDataToErpId?: number
  /** @format int32 */
  exportedArtikelCount?: number
  /** @format int32 */
  exportedArtikelErrorCount?: number
  /** @format int32 */
  exportedEkKonditionenCount?: number
  /** @format int32 */
  exportedEkKonditionenErrorCount?: number
  /** @format int32 */
  exportedEkPreisCount?: number
  /** @format int32 */
  exportedEkPreisErrorCount?: number
  /** @format int32 */
  exportedEkRabattgruppeCount?: number
  /** @format int32 */
  exportedEkRabattgruppeErrorCount?: number
  /** @format int32 */
  exportedEkRabattgruppeVersionCount?: number
  /** @format int32 */
  exportedEkRabattgruppeVersionErrorCount?: number
  /** @format int32 */
  exportedLieferantenCount?: number
  /** @format int32 */
  exportedLieferantenErrorCount?: number
  /** @format int32 */
  exportedStandortArtikelCount?: number
  /** @format int32 */
  exportedStandortArtikelEkCount?: number
  /** @format int32 */
  exportedStandortArtikelEkErrorCount?: number
  /** @format int32 */
  exportedStandortArtikelErrorCount?: number
  /** @format int32 */
  exportedStandortArtikelVkCount?: number
  /** @format int32 */
  exportedStandortArtikelVkErrorCount?: number
  /** @format int32 */
  exportedStandortArtikelVkSperreCount?: number
  /** @format int32 */
  exportedStandortArtikelVkSperreErrorCount?: number
  /** @format int32 */
  exportedVkEtikettCount?: number
  /** @format int32 */
  exportedVkPreiseCount?: number
  /** @format int32 */
  exportedVkPreiseErrorCount?: number
  info?: string
  reasonArtikel?: NoExportReasonSeArtikelJson[]
  reasonLieferant?: NoExportReasonSeLieferantJson[]
  reasonStandortArtikel?: NoExportReasonSeStandortArtikelJson[]
  result?: ArtikelStammdatenExportEintragJson[]
  state?: UseCaseStateJson
  /** @format int32 */
  vtArtikelCount?: number
  /** @format int32 */
  vtArtikelErrorCount?: number
  /** @format int32 */
  vtEkKonditionenCount?: number
  /** @format int32 */
  vtEkKonditionenErrorCount?: number
  /** @format int32 */
  vtEkListenpreisCount?: number
  /** @format int32 */
  vtEkListenpreisErrorCount?: number
  /** @format int32 */
  vtLieferantenCount?: number
  /** @format int32 */
  vtLieferantenErrorCount?: number
  /** @format int32 */
  vtVkKonditionenCount?: number
  /** @format int32 */
  vtVkKonditionenErrorCount?: number
}

export interface ErpStammdatenExportUebersichtJson {
  automatischerExport?: boolean
  canExport?: boolean
  /** @format int64 */
  exportDataToErpId?: number
  info?: string
  limited?: boolean
  /** @format date-time */
  nextExportTime?: string
  reasonArtikel?: NoExportReasonSeArtikelJson[]
  reasonLieferant?: NoExportReasonSeLieferantJson[]
  reasonStandortArtikel?: NoExportReasonSeStandortArtikelJson[]
  result?: ArtikelStammdatenExportEintragJson[]
  state?: UseCaseStateJson
}

export enum ErpSystemEinstellungen {
  PROHIBIS = 'PROHIBIS',
  EOS = 'EOS',
  SAP = 'SAP',
  EXTERN = 'EXTERN'
}

export interface ErpSystemJson {
  accountEntity?: string
  communicationType?: ErpCommunticationType
  dataTopic?: string
  errorTopic?: string
  exportDir?: string
  fromLogicalId?: string
  groupId?: string
  hkmQueue?: string
  /** @format int64 */
  id?: number
  importDir?: string
  intercompanyEinstellungen?: IntercompanyEinstellungen
  /** @format int32 */
  jmsMaxSendCount?: number
  /** @format int32 */
  jmsMaxSendSize?: number
  jmsQueue?: string
  kafkaServerLink?: string
  name?: string
  noValidation?: boolean
  password?: string
  preiseOnDemand?: boolean
  revision?: string
  securityProtocol?: string
  serverLink?: string
  skipConfirmImport?: boolean
  status?: 'init' | 'validiert'
  supportsEanGruppen?: boolean
  /** @format int32 */
  synchronTimeOut?: number
  toLogicalId?: string
  token?: string
  type?: ErpSystemEinstellungen
  user?: string
  zielGln?: string
}

export interface EtikettenDruckAnlageJson {
  artikel?: EtikettendruckAnlageEintragJson[]
  /** @format int64 */
  standortId?: number
}

export interface EtikettenDruckArtikelKafka {
  /**
   * AktuellerVkPreis
   * Aktuell nicht gefüllt.
   */
  aktuellerVkPreis?: number
  /**
   * AlleEANs.
   * Kommaseparierte Liste aller weiteren EANs des Artikels.
   * @example "6178188227879,4035791563287,1743323693830"
   */
  alleEANs?: string
  /**
   * Artikel
   * HageNr des Artikels.
   * @example "837131"
   */
  artikel?: string
  /**
   * Artikelname.
   * Text 'Etikett 1' des Artikels.
   * @example "Cryptocoryne Lutea Hobbit"
   */
  artikelname?: string
  /**
   * Beschreibung.
   * Text 'Etikett 2' des Artikels.
   * @example "MIX-CC (=22 STK.)"
   */
  beschreibung?: string
  /**
   * Brutto
   * Attribut 'Brutto' aus dem 'RegulaererVkPreis'.
   * @example true
   */
  brutto?: boolean
  /**
   * Grundpreisanzeige
   * Grundpreisauszeichnungspflicht (AS0138).
   * @example false
   */
  grundpreisanzeige?: boolean
  /**
   * HauptEAN.
   * @example "9403782567913"
   */
  hauptEAN?: string
  /**
   * Info
   * Interne Informationen zur Ermittlung der übergebenen Daten.
   */
  info?: string
  /**
   * LieferantenArtikelnummer.
   * IAN des Hauptlieferanten dieses Artikels
   * @example "3093736"
   */
  lieferantenArtikelnummer?: string
  /**
   * LieferantenNummer.
   * LieferantenNr. des Hauptlieferanten
   * @example "723564"
   */
  lieferantenNummer?: string
  /**
   * PreisDatum
   * Preis Änedrungsdatum.
   * @format date
   * @example "2024-01-01"
   */
  preisDatum?: string
  regalplaetze?: EtikettenDruckArtikelRegalplatzKafka[]
  /**
   * RegulaererVkPreis
   * Preis der Staffelmenge 0.
   * @example 14.95
   */
  regulaererVkPreis?: number
  staffeln?: EtikettenDruckArtikelStaffelKafka[]
  /**
   * StandortNr.
   * @format int32
   * @example 110
   */
  standortNr?: number
  /**
   * Verkaufseinheit
   * Verkaufpreismengeneinheit.
   * @example "PCE"
   */
  verkaufseinheit?: string
  /**
   * Verkaufsinhalt
   * Verkaufpreismenge in 'Verkaufseinheit'.
   * @example 1
   */
  verkaufsinhalt?: number
  /**
   * Waehrung
   * Aktuell fix EUR.
   * @example "2024-01-01"
   */
  waehrung?: string
  /**
   * Warengruppe.
   * Basiswarengruppe (hagebau-interne Warengruppenummer).
   * @example "723564"
   */
  warengruppe?: string
}

export interface EtikettenDruckArtikelRegalplatzKafka {
  anzahl?: number
  etikettentyp?: string
  regalplatznummer?: string
  sequenznummer?: number
}

export interface EtikettenDruckArtikelStaffelKafka {
  /**
   * AktuellerVkPreis
   * Aktuell nicht gefüllt.
   */
  aktuellerVkPreis?: number
  /**
   * Menge
   * Staffelmenge in 'Verkaufseinheit'.
   * @example 29.99
   */
  menge?: number
  /**
   * RegulaererVkPreis
   * Preis der Staffelmenge.
   * @example 14.95
   */
  regulaererVkPreis?: number
}

export interface EtikettenDruckEintragJson {
  abgelaufeneVkPreisAenderungIds?: number[]
  artikel?: ArtikelDisplayJson
  artikelAenderung?: boolean
  /** @format date */
  gueltigAb?: string
  status?: AuswahlStatus
  vkPreisAenderung?: boolean
  /** @format int64 */
  vkPreisAenderungId?: number
}

export interface EtikettenDruckKopfKafka {
  artikel?: EtikettenDruckArtikelKafka[]
  gln?: string
  kontext?: NeonKontext
}

export interface EtikettenDruckUseCaseJson {
  actionVerschieben?: ActionInfoJson
  eintraege?: EtikettenDruckEintragJson[]
  /** @format int64 */
  etikettenDruckId?: number
  standort?: StandortJson
  state?: UseCaseStateJson
}

export interface EtikettendruckAnlageEintragJson {
  abgelaufeneVkPreisAenderungsIds?: number[]
  /** @format int64 */
  artikelId?: number
  /** @format date */
  gueltigAb?: string
  /** @format date */
  gueltigAbVerschobenAuf?: string
  status?: AuswahlStatus
  /** @format int64 */
  vkPreisAenderungsId?: number
}

export enum ExportDataToErpDetailStatus {
  EXPORTED = 'EXPORTED',
  EXPORT_FAILED = 'EXPORT_FAILED',
  CONFIRM_ERROR = 'CONFIRM_ERROR',
  CONFIRMED = 'CONFIRMED'
}

export enum ExportDataToErpStepType {
  WARENGRUPPE = 'WARENGRUPPE',
  SPARTE = 'SPARTE',
  STEUER = 'STEUER',
  ME = 'ME',
  RABATT_GRUPPE = 'RABATT_GRUPPE',
  RABATT_GRUPPE_VERSION = 'RABATT_GRUPPE_VERSION',
  PREIS_GRUPPE = 'PREIS_GRUPPE',
  KALK_VORSCHLAG = 'KALK_VORSCHLAG',
  STAFFEL_GRUPPE = 'STAFFEL_GRUPPE',
  STANDORT = 'STANDORT',
  RUNDUNGSREGEL = 'RUNDUNGSREGEL',
  BETRIEBSTYP = 'BETRIEBSTYP',
  LIEFERANT = 'LIEFERANT',
  ARTIKEL = 'ARTIKEL',
  EK_PREIS = 'EK_PREIS',
  EK_KONDITION = 'EK_KONDITION',
  VK_PREIS = 'VK_PREIS',
  VK_ETIKETT = 'VK_ETIKETT',
  ARTIKEL_ART = 'ARTIKEL_ART',
  PREISMENGE = 'PREISMENGE',
  STANDORT_ARTIKEL = 'STANDORT_ARTIKEL',
  STANDORT_ARTIKEL_VKP = 'STANDORT_ARTIKEL_VKP',
  STANDORT_ARTIKEL_VKPBL = 'STANDORT_ARTIKEL_VKPBL',
  STANDORT_ARTIKEL_EKP = 'STANDORT_ARTIKEL_EKP',
  STANDORT_ARTIKEL_VKSPERRE = 'STANDORT_ARTIKEL_VKSPERRE'
}

export enum ExportErpDataRequestTyp {
  INITIAL = 'INITIAL',
  STAMMDATEN = 'STAMMDATEN',
  MODELS = 'MODELS',
  CHANGES = 'CHANGES',
  RESEND = 'RESEND',
  ARTIKEL_PREISE = 'ARTIKEL_PREISE'
}

export interface ExportErrorToErpArtikelBearbeitenJson {
  exportErrors?: ExportErrorToErpArtikelJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpArtikelJson {
  artikel?: ArtikelDisplayJson
  /** @format date-time */
  confirmTime?: string
  /** @format int64 */
  id?: number
  needsExportErp?: boolean
  protokollEintrag?: ProtokollEintragJson
  status?: ExportDataToErpDetailStatus
}

export interface ExportErrorToErpEkKonditionBearbeitenJson {
  exportErrors?: ExportErrorToErpEkKonditionJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpEkKonditionJson {
  artikel?: ArtikelDisplayJson
  /** @format date-time */
  confirmTime?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  gueltigstandort?: StandortJson
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  needsExportErp?: boolean
  preisliste?: EkKonditionenListeDisplayJson
  /** @format int64 */
  preislisteEintragId?: number
  protokollEintrag?: ProtokollEintragJson
  standort?: StandortJson
  status?: ExportDataToErpDetailStatus
}

export interface ExportErrorToErpEkPreisBearbeitenJson {
  exportErrors?: ExportErrorToErpEkPreisJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpEkPreisJson {
  artikel?: ArtikelDisplayJson
  /** @format date-time */
  confirmTime?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  listenpreis?: number
  mengeneinheit?: MengeneinheitJson
  needsExportErp?: boolean
  preismenge?: number
  protokollEintrag?: ProtokollEintragJson
  status?: ExportDataToErpDetailStatus
}

export interface ExportErrorToErpLieferantBearbeitenJson {
  exportErrors?: ExportErrorToErpLieferantJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpLieferantJson {
  /** @format date-time */
  confirmTime?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  needsExportErp?: boolean
  protokollEintrag?: ProtokollEintragJson
  status?: ExportDataToErpDetailStatus
}

export interface ExportErrorToErpStandortArtikelBearbeitenJson {
  exportErrors?: ExportErrorToErpStandortArtikelJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpStandortArtikelJson {
  artikel?: ArtikelDisplayJson
  /** @format date-time */
  confirmTime?: string
  /** @format int64 */
  id?: number
  needsExportErp?: boolean
  protokollEintrag?: ProtokollEintragJson
  standort?: StandortJson
  status?: ExportDataToErpDetailStatus
}

export interface ExportErrorToErpVkPreisBearbeitenJson {
  exportErrors?: ExportErrorToErpVkPreisJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpVkPreisJson {
  artikel?: ArtikelDisplayJson
  /** @format date-time */
  confirmTime?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  needsExportErp?: boolean
  protokollEintrag?: ProtokollEintragJson
  standort?: StandortJson
  status?: ExportDataToErpDetailStatus
}

export interface ExportErrorToErpVkSperreBearbeitenJson {
  exportErrors?: ExportErrorToErpVkSperreJson[]
  state?: UseCaseStateJson
}

export interface ExportErrorToErpVkSperreJson {
  artikel?: ArtikelDisplayJson
  /** @format date-time */
  confirmTime?: string
  /** @format int64 */
  id?: number
  needsExportErp?: boolean
  protokollEintrag?: ProtokollEintragJson
  standort?: StandortJson
  status?: ExportDataToErpDetailStatus
  vkSperre?: boolean
  /** @format date-time */
  vkSperreAb?: string
  /** @format date-time */
  vkSperreBis?: string
  vkSperreGrund?: string
}

export interface ExportToErpDisplayJson {
  /** @uniqueItems true */
  actions?: string[]
  /** @format date-time */
  confirmed?: string
  /** @format int32 */
  confirmedCount?: number
  /** @format date-time */
  exportEnd?: string
  /** @format date-time */
  exportStart?: string
  exportStatus?: string
  /** @format int32 */
  exportedCount?: number
  /** @format int64 */
  id?: number
  protokoll?: ProtokollJson
  /** @uniqueItems true */
  types?: ExportDataToErpStepType[]
}

export interface ExportToErpSearcherCriteriaJson {
  /** @format date */
  bisDatum?: string
  nurOffene?: boolean
  /** @uniqueItems true */
  types?: ExportDataToErpStepType[]
  /** @format date */
  vonDatum?: string
}

export interface ExportToErpSearcherJson {
  criteria?: ExportToErpSearcherCriteriaJson
  limited?: boolean
  result?: ExportToErpDisplayJson[]
}

export interface ExportToErpUebersichtJson {
  actionExportieren?: ActionInfoJson
  aktuellerExport?: ExportToErpDisplayJson
  aktuellerExportInfo?: string
  automatischerExport?: boolean
  searcher?: ExportToErpSearcherJson
  state?: UseCaseStateJson
}

export enum ExternesSystemType {
  ZART_ART = 'ZART_ART',
  ZART_LIEF = 'ZART_LIEF',
  ESB_LIEF = 'ESB_LIEF',
  PIM = 'PIM',
  EOS_IN = 'EOS_IN',
  DTLA_RP = 'DTLA_RP',
  NEON_DP = 'NEON_DP',
  ETIKETTEN_DURCK = 'ETIKETTEN_DURCK',
  STANDARD_NULL = 'STANDARD_NULL',
  STANDARD_PROHIBIS = 'STANDARD_PROHIBIS',
  STANDARD_EOS = 'STANDARD_EOS',
  STANDARD_SAP = 'STANDARD_SAP',
  ERP_EXTERN = 'ERP_EXTERN'
}

export interface FileJson {
  entries?: FileJson[]
  /** @format int64 */
  fileSize?: number
  folder?: boolean
  /** @format date-time */
  lastModified?: string
  name?: string
  path?: string
}

export interface FileSystemResource {
  description?: string
  /** @format binary */
  file?: File
  filename?: string
  inputStream?: object
  open?: boolean
  outputStream?: object
  path?: string
  readable?: boolean
  /** @format uri */
  uri?: string
  /** @format url */
  url?: string
  writable?: boolean
}

export interface GesellschafterBearbeitenJson {
  eanGruppen?: EANGruppeDisplayJson[]
  gesellschafter?: GesellschafterJson
  state?: UseCaseStateJson
}

export interface GesellschafterDisplayJson {
  allianz?: boolean
  /** @format int64 */
  allianzId?: number
  allianzMitglied?: boolean
  externesSystemType?: ExternesSystemType
  geloescht?: boolean
  gln?: string
  /** @format int64 */
  id?: number
  interCompany?: boolean
  name?: string
  nr?: string
  ohneERP?: boolean
  ohneEkPreis?: boolean
  ohneErpImport?: boolean
  ohneVkPreis?: boolean
  /** @format int64 */
  stammEbeneId?: number
  typ?: GesellschafterTyp
}

export interface GesellschafterDisplayTinyJson {
  gln?: string
  /** @format int64 */
  id?: number
  name?: string
  nr?: string
}

export interface GesellschafterFirmaBearbeitenJson {
  eanGruppen?: EANGruppeDisplayJson[]
  erpSysteme?: ErpSystemJson[]
  gesellschafterFirma?: GesellschafterFirmaJson
  letzteAnbindung?: ErpEbeneImportRequestJson
  preisgruppen?: VkPreisGruppeJson[]
  state?: UseCaseStateJson
}

export interface GesellschafterFirmaJson {
  communicationType?: ErpCommunticationType
  /** @format int64 */
  defaultEanGruppeId?: number
  /** @format int64 */
  defaultEinzelhandelPreisgruppeId?: number
  einzlhandelAusPIM?: boolean
  /** @format int64 */
  erpSystemId?: number
  /** @format int32 */
  etikettenWerktage?: number
  exportDir?: string
  gln?: string
  gsOrg?: GesellschafterDisplayJson
  /** @format int64 */
  id?: number
  initHatRegalplatz?: boolean
  land?: string
  name?: string
  nr?: string
  pimDatenmodell?: boolean
  /** @format int64 */
  stammEbeneId?: number
}

export interface GesellschafterJson {
  /** @format int64 */
  defaultEanGruppeId?: number
  einzlhandelAusPIM?: boolean
  erpSystemName?: string
  erpSysteme?: ErpSystemJson[]
  /** @format int32 */
  etikettenWerktage?: number
  firmen?: GesellschafterDisplayTinyJson[]
  gln?: string
  /** @format int64 */
  id?: number
  initHatRegalplatz?: boolean
  land?: string
  name?: string
  nr?: string
  pimDatenmodell?: boolean
  /** @format int64 */
  stammEbeneId?: number
}

export interface GesellschafterSearcherCriteriaJson {
  allianz?: boolean
  firma?: boolean
  gln?: string
  isAllianz?: boolean
  isAllianzMitglied?: boolean
  isFirma?: boolean
  isGeloeschte?: boolean
  name?: string
}

export interface GesellschafterSearcherJson {
  criteria?: GesellschafterSearcherCriteriaJson
  limited?: boolean
  result?: GesellschafterDisplayJson[]
}

export enum GesellschafterTyp {
  FIRMA = 'FIRMA',
  ORGANISATION = 'ORGANISATION'
}

export interface GesellschafterUebersichtJson {
  searcher?: GesellschafterSearcherJson
  state?: UseCaseStateJson
}

export interface GetErpDataStatusJson {
  erpEbeneImportRequest?: ErpEbeneImportRequestJson[]
  state?: UseCaseStateJson
}

export interface HKMAuditJson {
  createdBy?: string
  /** @format date-time */
  createdOn?: string
  updatedBy?: string
  /** @format date-time */
  updatedOn?: string
}

export enum HandelTyp {
  FH = 'FH',
  EH = 'EH'
}

export interface HauptlieferantBearbeitenJson {
  lieferanten?: LieferantDisplayJson[]
  pflegeListe?: HauptlieferantPflegeListeJson
  preisEbenen?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
}

export interface HauptlieferantPflegeEintragJson {
  artikel?: ArtikelDisplayJson
  lieferanten?: HauptlieferantPflegeEintragLieferantJson[]
  nichtBevorzugterHauptLieferant?: boolean
}

export interface HauptlieferantPflegeEintragLieferantJson {
  ausgelistet?: boolean
  bevorzugterHauptlieferant?: boolean
  ekFreiHaus?: number
  geloescht?: boolean
  gesperrt?: boolean
  hauptlieferant?: boolean
  /** @format int64 */
  id?: number
  industrieArtikelNummer?: string
  keinePreispflege?: boolean
  /** @format int64 */
  lieferantId?: number
  mengenEinheit?: string
  /** @format int64 */
  preisEbeneId?: number
  preismenge?: number
  /** @format int64 */
  seLieferantId?: number
  /** @format int64 */
  zeLieferantId?: number
}

export interface HauptlieferantPflegeListeJson {
  betriebstyp?: ArtikelBetriebstyp
  eintraege?: HauptlieferantPflegeEintragJson[]
  eintraegeChanges?: HauptlieferantPflegeEintragJson[]
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  name?: string
  /** @format int64 */
  ownerId?: number
  preisVorgangTyp?: PreisVorgangTyp
  typ?: ArtikelListeTyp
}

export interface HauptliefersantSaveJson {
  /** @format int64 */
  pflegeListeId?: number
  state?: UseCaseStateJson
}

export interface HelpJson {
  author?: string
  filename?: string
  key?: string
  /** @format date-time */
  lastModified?: string
  tags?: string[]
  text?: string
  title?: string
}

export interface HelpUseCaseJson {
  data?: HelpJson
  readonly?: boolean
  state?: UseCaseStateJson
}

export interface HkmEnvelopeHeaderXML {
  GLN?: string
  ReplyForRequestId?: string
  RequestId?: string
  RequestLast?: boolean
  /** @format int32 */
  RequestSeq?: number
  Timestamp?: string
}

export interface HkmEnvelopeXML {
  EnvelopeHeader?: HkmEnvelopeHeaderXML
  ExportArtikelEkListenPreis?: HkmExportArtikelEkListenPreisMessageXML[]
  ExportArtikelMessage?: HkmExportArtikelMessageXML[]
  ExportLieferantMessage?: HkmExportLieferantMessageXML[]
  ExportStandortArtikelEkPreiseMessage?: HkmExportStandortArtikelEkPreiseMessageXML[]
  ExportStandortArtikelMessage?: HkmExportStandortArtikelMessageXML[]
  ExportStandortArtikelVkPreiseMessage?: HkmExportStandortArtikelVkPreiseMessageXML[]
  ExportStandortArtikelVkSperreMessage?: HkmExportStandortArtikelVkSperreMessageXML[]
  ImportStandortRequest?: HkmImportStandortRequestXML[]
}

export interface HkmExportArtikelEkListenPreisMessageXML {
  AktionCode?: ErpAktionCode
  ArtikelEkListenPreis?: EkListenPreisXML
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  erpId?: string
  modelXML?: EkListenPreisXML
}

export interface HkmExportArtikelMessageXML {
  AktionCode?: ErpAktionCode
  Artikel?: PimArtikelXML
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  Produkt?: PimProduktXML
  erpId?: string
  modelXML?: PimArtikelXML
}

export interface HkmExportLieferantMessageXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  Hint?: string
  Lieferant?: DzLieferantXML
  MessageHeader?: MessageHeaderXML
  erpId?: string
  modelXML?: DzLieferantXML
}

export interface HkmExportStandortArtikelEkPreiseMessageXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  /** Struktur für den Austausch von StandortArtikel Ek-Preisen */
  StandortArtikelEkPreise?: StandortArtikelEkPreiseXML
  erpId?: string
  /** Struktur für den Austausch von StandortArtikel Ek-Preisen */
  modelXML?: StandortArtikelEkPreiseXML
}

export interface HkmExportStandortArtikelMessageXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  StandortArtikel?: StandortArtikelXML
  erpId?: string
  modelXML?: StandortArtikelXML
}

export interface HkmExportStandortArtikelVkPreiseMessageXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  /** Struktur für den Austausch von StandortArtikel Vk-Preisen */
  StandortArtikelVkPreise?: StandortArtikelVkPreiseXML
  erpId?: string
  /** Struktur für den Austausch von StandortArtikel Vk-Preisen */
  modelXML?: StandortArtikelVkPreiseXML
}

export interface HkmExportStandortArtikelVkSperreMessageXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  /** Struktur für den Austausch von StandortArtikel Vk-Sperre Daten */
  StandortArtikelVkSperre?: StandortArtikelVkSperreXML
  erpId?: string
  /** Struktur für den Austausch von StandortArtikel Vk-Sperre Daten */
  modelXML?: StandortArtikelVkSperreXML
}

export interface HkmImportStandortRequestXML {
  AktionCode?: ErpAktionCode
  FachSchluessel?: string
  Hint?: string
  MessageHeader?: MessageHeaderXML
  StandortRequest?: StandortRequestXML
  erpId?: string
  modelXML?: StandortRequestXML
}

export type HttpRange = object

export interface ImportErrorErpArtikelBearbeitenJson {
  importErrors?: ImportErrorErpArtikelJson[]
  state?: UseCaseStateJson
}

export interface ImportErrorErpArtikelJson {
  artikel?: ArtikelDisplayJson
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  /** @format date-time */
  lastReImportTime?: string
  protokollEintrag?: ProtokollEintragJson
  /** @format int32 */
  reImportCount?: number
}

export interface ImportErrorErpEkKonditionBearbeitenJson {
  importErrors?: ImportErrorErpEkKonditionJson[]
  state?: UseCaseStateJson
}

export interface ImportErrorErpEkKonditionJson {
  artikel?: ArtikelDisplayJson
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  gueltigstandort?: StandortJson
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  /** @format date-time */
  lastReImportTime?: string
  lieferant?: LieferantDisplayJson
  preisliste?: EkKonditionenListeDisplayJson
  /** @format int64 */
  preislisteEintragId?: number
  protokollEintrag?: ProtokollEintragJson
  /** @format int32 */
  reImportCount?: number
  standort?: StandortJson
}

export interface ImportErrorErpEkPreisBearbeitenJson {
  importErrors?: ImportErrorErpEkPreisJson[]
  state?: UseCaseStateJson
}

export interface ImportErrorErpEkPreisJson {
  artikel?: ArtikelDisplayJson
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  /** @format date-time */
  lastReImportTime?: string
  lieferant?: LieferantDisplayJson
  listenpreis?: number
  mengeneinheit?: MengeneinheitJson
  preismenge?: number
  protokollEintrag?: ProtokollEintragJson
  /** @format int32 */
  reImportCount?: number
}

export interface ImportErrorErpLieferantBearbeitenJson {
  importErrors?: ImportErrorErpLieferantJson[]
  state?: UseCaseStateJson
}

export interface ImportErrorErpLieferantJson {
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  /** @format date-time */
  lastReImportTime?: string
  lieferant?: LieferantDisplayJson
  protokollEintrag?: ProtokollEintragJson
  /** @format int32 */
  reImportCount?: number
}

export interface ImportErrorErpVkPreisBearbeitenJson {
  importErrors?: ImportErrorErpVkPreisJson[]
  state?: UseCaseStateJson
}

export interface ImportErrorErpVkPreisJson {
  artikel?: ArtikelDisplayJson
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  ignoriert?: boolean
  /** @format date-time */
  lastReImportTime?: string
  lieferant?: LieferantDisplayJson
  protokollEintrag?: ProtokollEintragJson
  /** @format int32 */
  reImportCount?: number
  standort?: StandortJson
}

export interface ImportErrorJson {
  errorMsg?: string
  importFile?: string
  status?: ExportDataToErpDetailStatus
}

export interface ImportErrorStatusJson {
  importFehlerData?: boolean
  importFehlerPreiseEk?: boolean
  importFehlerPreiseVk?: boolean
}

export interface InfoJson {
  details?: string
  title?: string
}

export enum IntercompanyEinstellungen {
  KEIN_IC = 'KEIN_IC',
  NUR_ART_LIEF = 'NUR_ART_LIEF'
}

export interface JobSchedulerInfo {
  /** @format int64 */
  delay?: number
  /** @format int64 */
  lastDuration?: number
  /** @format date-time */
  lastStart?: string
  /** @format date-time */
  nextStart?: string
  /** @format int64 */
  runCount?: number
  status?: JobSchedulerStatus
  statusInfo?: string
  /** @format int32 */
  updateInterval?: number
}

export enum JobSchedulerStatus {
  NOT_STARTED = 'NOT_STARTED',
  SLEEPING = 'SLEEPING',
  RUNNING = 'RUNNING'
}

export interface KafkaImportResultJson {
  state?: UseCaseStateJson
}

export interface KafkaImporterJobStatusJson {
  /** @format int64 */
  aktPosition?: number
  configured?: boolean
  /** @format int64 */
  endPosition?: number
  /** @format int64 */
  lastJobExecutionId?: number
  running?: boolean
  state?: UseCaseStateJson
  /** @format int32 */
  timeout?: number
  /** @format int32 */
  verabeiteteArtikelanfagen?: number
}

export interface LabelBearbeitenJson {
  data?: LabelJson
  state?: UseCaseStateJson
}

export interface LabelGruppeBearbeitenJson {
  data?: LabelGruppeJson
  state?: UseCaseStateJson
}

export interface LabelGruppeJson {
  audit?: HKMAuditJson
  elemente?: LabelJson[]
  /** @format int64 */
  id?: number
  /** @format int32 */
  version?: number
}

export interface LabelJson {
  bezeichnung?: string
  /** @format int64 */
  id?: number
  kurz?: string
  system?: boolean
}

export interface LabelZuordnungenBearbeitenAddRemoveDaten {
  artikelIds?: number[]
  labelIds?: number[]
}

export interface LabelZuordnungenBearbeitenJson {
  state?: UseCaseStateJson
}

export enum LagerKz {
  LAGER = 'LAGER',
  BESCHAFFUNG = 'BESCHAFFUNG',
  ZL = 'ZL'
}

export interface LieferantAboBearbeitenJson {
  abo?: LieferantAboJson
  kondiDefinition?: EkKonditionenDefinitionJson
  state?: UseCaseStateJson
}

export interface LieferantAboDisplayJson {
  aboName?: string
  /** @format int64 */
  anzahlAbonniert?: number
  /** @format int64 */
  anzahlGelistet?: number
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  matchingDzLieferant?: LieferantDisplayTinyJson[]
  status?: LieferantAbonniertStatus
  /** @format int64 */
  zeLieferantId?: number
}

export interface LieferantAboJson {
  aboQuelle?: AboQuelleJson
  aenderungen?: boolean
  aenderungenUebernehmen?: boolean
  audit?: HKMAuditJson
  bemerkung?: string
  changedAttributes?: CompositeAttributeChange
  changedRabattgruppen?: EkKonditionenRabattgruppeVersionChangeJson[]
  doppelfehler?: boolean
  dzLieferant?: LieferantDisplayJson
  /** @format int64 */
  id?: number
  konflikt?: boolean
  konfliktUeberschreiben?: boolean
  lieferant?: LieferantDisplayJson
  /** @format int64 */
  lieferantSyncEinstId?: number
  lieferantSyncEinstList?: SyncEinstellungDisplayJson[]
  name?: string
  quellenwechsel?: AboQuellewechselJson
  setzeStatusAbonniert?: boolean
  setzeStatusNeuanlage?: boolean
  setzeStatusUnerwuenscht?: boolean
  status?: LieferantAbonniertStatus
  verbindungLoesen?: boolean
  /** @format int32 */
  version?: number
}

export interface LieferantAboSearcherJson {
  criteria?: LieferantAboSearcherLieferantAboCriteriaJson
  limited?: boolean
  result?: LieferantAboDisplayJson[]
}

export interface LieferantAboSearcherLieferantAboCriteriaJson {
  abonniertStatus?: LieferantAbonniertStatus
  aenderungsTyp?: LieferantAenderungTyp
  lieferant?: LieferantDisplayJson
  mitStatistik?: boolean
  name?: string
}

export interface LieferantAboUebersichtJson {
  searcher?: LieferantAboSearcherJson
  state?: UseCaseStateJson
}

export enum LieferantAbonniertStatus {
  ABONNIERT = 'ABONNIERT',
  UNTERBROCHEN = 'UNTERBROCHEN',
  UNBESTIMMT = 'UNBESTIMMT',
  UNERWUENSCHT = 'UNERWUENSCHT'
}

export interface LieferantAdresseJson {
  name1?: string
  name2?: string
  name3?: string
  tel1?: string
  tel2?: string
  adrTyp?: LieferantAdresseTyp
  anrede?: string
  email?: string
  fax?: string
  gln?: string
  /** @format int64 */
  id?: number
  internet?: string
  land?: string
  ort?: string
  ortsteil?: string
  plz?: string
  postfach?: string
  postfachOrt?: string
  postfachPlz?: string
  strasse?: string
}

export enum LieferantAdresseTyp {
  HAUPT_ADR = 'HAUPT_ADR',
  ABHOL_ADR = 'ABHOL_ADR'
}

export interface LieferantAenderungAnzeigenJson {
  result?: LieferantAenderungJson[]
  state?: UseCaseStateJson
}

export interface LieferantAenderungJson {
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayJson
  typ?: LieferantAenderungTyp
}

export enum LieferantAenderungTyp {
  NEU = 'NEU',
  GEAENDERT = 'GEAENDERT',
  KONFLIKT_DZ = 'KONFLIKT_DZ'
}

export enum LieferantArt {
  WARE = 'WARE',
  KOSTEN = 'KOSTEN'
}

export interface LieferantBetriebstypJson {
  /** @format int64 */
  artikelAnzahl?: number
  betriebstyp?: ArtikelBetriebstyp
  /** @format int64 */
  id?: number
}

export interface LieferantBetriebstypListeJson {
  lieferantBetriebstypen?: LieferantBetriebstypJson[]
  state?: UseCaseStateJson
}

export interface LieferantDisplayJson {
  name1?: string
  name2?: string
  /** @format int64 */
  aboLieferantId?: number
  aboStatus?: LieferantAbonniertStatus
  /** @format int64 */
  aeLieferantId?: number
  anrede?: string
  /** @format int64 */
  anzahlAbonniert?: number
  /** @format int64 */
  anzahlGelistet?: number
  client?: string
  edi?: boolean
  eigenlistung?: boolean
  geloescht?: boolean
  geloeschtDz?: boolean
  gesperrt?: boolean
  gesperrtDz?: boolean
  gln?: string
  /** @format int64 */
  id?: number
  iln?: string
  land?: string
  mitStatistik?: boolean
  notanlage?: boolean
  nummer?: string
  ort?: string
  ortsteil?: string
  plz?: string
  postfach?: string
  postfachOrt?: string
  postfachPlz?: string
  /** @format int64 */
  seLieferantId?: number
  sprache?: string
  strasse?: string
  typ?: SuchLieferantTyp
  /** @format int64 */
  zeLieferantId?: number
}

export interface LieferantDisplayTinyJson {
  name1?: string
  name2?: string
  eigenlistung?: boolean
  geloescht?: boolean
  geloeschtDz?: boolean
  gesperrt?: boolean
  gesperrtDz?: boolean
  /** @format int64 */
  id?: number
  iln?: string
  notanlage?: boolean
  nummer?: string
  ort?: string
  plz?: string
  strasse?: string
  typ?: SuchLieferantTyp
}

export interface LieferantKonflikteJson {
  konflikte?: CompositeAttributeChange
  state?: UseCaseStateJson
}

export enum LieferantLandKategorie {
  INLAND = 'INLAND',
  EU = 'EU',
  DRITTLAND = 'DRITTLAND'
}

export interface LieferantMatchingDzJson {
  connectedLieferant?: LieferantDisplayJson
  matches?: LieferantMatchingDzMatch[]
  state?: UseCaseStateJson
}

export interface LieferantMatchingDzMatch {
  info?: string
  lieferant?: LieferantDisplayJson
}

export interface LieferantSearcherCriteriaJson {
  abonnierte?: boolean
  auchGeloeschte?: boolean
  dezentral?: boolean
  eigenlistung?: boolean
  forAbo?: boolean
  iln?: string
  /** @format int32 */
  maxResults?: number
  mitStatistik?: boolean
  name?: string
  nummer?: string
  suchbegriff?: string
  zentral?: boolean
}

export interface LieferantSearcherJson {
  criteria?: LieferantSearcherCriteriaJson
  limited?: boolean
  result?: LieferantDisplayJson[]
}

export interface LieferantUebersichtJson {
  searcher?: LieferantSearcherJson
  state?: UseCaseStateJson
}

export interface LieferantenListeBearbeitenJson {
  data?: LieferantenListeJson
  state?: UseCaseStateJson
}

export interface LieferantenListeDisplayJson {
  /** @format int64 */
  id?: number
  /** @format int32 */
  lieferantenAnzahl?: number
  name?: string
  /** @format int64 */
  ownerId?: number
  typ?: LieferantenListeTyp
}

export interface LieferantenListeJson {
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
  lieferanten?: LieferantDisplayJson[]
  name?: string
  /** @format int64 */
  ownerId?: number
  typ?: LieferantenListeTyp
  /** @format int32 */
  version?: number
}

export interface LieferantenListeSearcherCriteriaJson {
  name?: string
  typ?: LieferantenListeTyp
}

export interface LieferantenListeSearcherJson {
  criteria?: LieferantenListeSearcherCriteriaJson
  limited?: boolean
  result?: LieferantenListeDisplayJson[]
}

export enum LieferantenListeTyp {
  MANUAL = 'MANUAL',
  ARTVD = 'ARTVD'
}

export interface LieferantenListeUebersichtJson {
  searcher?: LieferantenListeSearcherJson
  state?: UseCaseStateJson
}

export interface LieferantenVerteilungDefinitionBearbeitenJson {
  data?: LieferantenVerteilungDefinitionJson
  state?: UseCaseStateJson
}

export interface LieferantenVerteilungDefinitionDisplayJson {
  aktiv?: boolean
  /** @format int64 */
  id?: number
  /** @format int32 */
  lieferantenListenCount?: number
  name?: string
  verteilungsZiele?: VerteilungsZielFlatDisplayJson[]
}

export interface LieferantenVerteilungDefinitionJson {
  aktiv?: boolean
  eintraege?: LieferantenListeDisplayJson[]
  /** @format int64 */
  id?: number
  name?: string
  /** @format int32 */
  version?: number
  verteilungsZielList?: VerteilungsZielDisplayJson[]
  verteilungsZiele?: number[]
}

export interface LieferantenVerteilungDefinitionSearcherCriteriaJson {
  name?: string
}

export interface LieferantenVerteilungDefinitionSearcherJson {
  criteria?: LieferantenVerteilungDefinitionSearcherCriteriaJson
  limited?: boolean
  result?: LieferantenVerteilungDefinitionDisplayJson[]
}

export interface LieferantenVerteilungDefinitionUebersichtJson {
  searcher?: LieferantenVerteilungDefinitionSearcherJson
  state?: UseCaseStateJson
}

export interface ListResponseJsonNummernkreisJson {
  data?: NummernkreisJson[]
  state?: UseCaseStateJson
}

export interface ListResponseJsonSparteDisplayJson {
  data?: SparteDisplayJson[]
  state?: UseCaseStateJson
}

export interface ListenpreisJson {
  ausListung?: boolean
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  listenpreis?: number
  mengeneinheit?: string
  preismenge?: number
}

export interface LogFileJson {
  lines?: LogJson[]
  state?: UseCaseStateJson
}

export interface LogJson {
  gln?: string
  level?: string
  msg?: string
  /** @format int64 */
  tid?: number
  /** @format date-time */
  ts?: string
}

export interface LpPreisDiffJson {
  alt?: PreisDiffJson
  neu?: PreisDiffJson
  zeLieferant?: LieferantDisplayJson
}

export interface MapperAllJson {
  elemente?: MapperEinstellungElementJson[]
  state?: UseCaseStateJson
}

export interface MapperBearbeitenJson {
  einstellung?: MapperEinstellungJson
  elemente?: MapperEinstellungElementJson[]
  state?: UseCaseStateJson
}

export interface MapperEinstellungElementJson {
  /** @format int32 */
  attributeId?: number
  editInGruppe?: boolean
  erpName?: string
  groupName?: string
  mapperName?: string
  name?: string
  needMap?: boolean
  optional?: boolean
  type?: DzAttributeMapperIdType
}

export interface MapperEinstellungJson {
  /** @format int64 */
  id?: number
  name?: string
  pimDatenmodell?: boolean
  /** @format int64 */
  refCount?: number
  standardEH?: boolean
  standardFH?: boolean
  /** @format int32 */
  standardStufe?: number
  typ?: DzMapperEinstellungTyp
  vorgabe?: boolean
}

export interface MapperUebersichtJson {
  searcher?: MapperUebersichtSearcherJson
  state?: UseCaseStateJson
}

export interface MapperUebersichtSearcherCriteriaJson {
  name?: string
  typ?: DzMapperEinstellungTyp
  vorgabe?: boolean
}

export interface MapperUebersichtSearcherJson {
  criteria?: MapperUebersichtSearcherCriteriaJson
  limited?: boolean
  result?: MapperEinstellungJson[]
}

export interface MediaType {
  charset?: {
    registered?: boolean
  }
  concrete?: boolean
  parameters?: Record<string, string>
  /** @format double */
  qualityValue?: number
  subtype?: string
  subtypeSuffix?: string
  type?: string
  wildcardSubtype?: boolean
  wildcardType?: boolean
}

export enum MengeneinheitArt {
  ZAEHLBAR = 'ZAEHLBAR',
  LAENGE = 'LAENGE',
  FLAECHE = 'FLAECHE',
  VOLUMEN = 'VOLUMEN',
  UNDEFINIERT = 'UNDEFINIERT'
}

export interface MengeneinheitDisplayTinyJson {
  bez?: string
  /** @format int64 */
  id?: number
  kurz?: string
}

export interface MengeneinheitJson {
  bez?: string
  faktorNenner?: number
  faktorZaehler?: number
  /** @format int64 */
  id?: number
  kurz?: string
}

export interface MengeneinheitenBearbeitenJson {
  mengeneinheit?: ArtikelMengeneinheitJson
  state?: UseCaseStateJson
}

export interface MengeneinheitenListeJson {
  mengeneinheiten?: MengeneinheitDisplayTinyJson[]
  state?: UseCaseStateJson
}

export interface MessageHeaderXML {
  MessageId?: string
  ReplyForMessageId?: string
  Timestamp?: string
}

export interface MessageID {
  format?: string
  id?: string
  technisch?: boolean
}

export interface MessageJson {
  hint?: string
  message?: string
  messageId?: MessageID
  /** @format int64 */
  objectId?: number
  severity?: MessageSeverity
  /** @format int32 */
  spalte?: number
  subMessages?: MessageJson[]
  /** @format int32 */
  zeile?: number
}

export enum MessageSeverity {
  INFO = 'INFO',
  WARN = 'WARN',
  ERR = 'ERR',
  FATAL = 'FATAL'
}

export interface MigrationArtikelAboImportArtikelJson {
  artikel?: ArtikelDisplayTinyJson
  error?: MessageJson[]
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
}

export interface MigrationArtikelAboImportJson {
  /** @format int32 */
  artikelAnzFound?: number
  /** @format int32 */
  artikelAnzNotFound?: number
  errorArtikel?: MigrationArtikelAboImportArtikelJson[]
  /** @format int64 */
  id?: number
  lieferanten?: MigrationArtikelAboImportLieferantJson[]
}

export interface MigrationArtikelAboImportLieferantBtJson {
  artikelAbo?: ArtikelAboDisplayJson
  /** @format int32 */
  artikelAnz?: number
  artikelSelektion?: ArtikelSelektionDisplayJson
  bt?: ArtikelBetriebstyp
  eanGruppe?: EANGruppeDisplayJson
  /** @format int64 */
  id?: number
}

export interface MigrationArtikelAboImportLieferantJson {
  /** @format int32 */
  artikelAnz?: number
  artikelSelection?: ArtikelSelektionMigrationDisplayJson
  bts?: MigrationArtikelAboImportLieferantBtJson[]
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  lieferantAbo?: LieferantAboDisplayJson
}

export interface MigrationArtikelAboImportResultJson {
  migrationArtikelAboImportJson?: MigrationArtikelAboImportJson
  state?: UseCaseStateJson
}

export interface MigrationArtikelEosDatenErzeugenBearbeitenJson {
  migrationArtikelEosDatenJson?: MigrationArtikelEosDatenErzeugenJson
  state?: UseCaseStateJson
}

export interface MigrationArtikelEosDatenErzeugenJson {
  /** @format int32 */
  artikelAnzFound?: number
  /** @format int32 */
  artikelAnzNotFound?: number
  /** @format int64 */
  id?: number
  lieferanten?: MigrationArtikelEosDatenErzeugenLieferantJson[]
}

export interface MigrationArtikelEosDatenErzeugenLieferantJson {
  /** @format int32 */
  artikelAnz?: number
  /** @format int32 */
  artikelAnzProhibis?: number
  artikelSelection?: ArtikelSelektionMigrationDisplayJson
  eigenlistung?: boolean
  eosLieferant?: LieferantDisplayTinyJson
  /** @format int64 */
  id?: number
  lieferantAbo?: LieferantAboDisplayJson
  phLieferant?: LieferantDisplayTinyJson
}

export interface MigrationArtikelEosDatenErzeugenResultJson {
  /** @format int32 */
  artikelRabattgruppeCountErzeugt?: number
  /** @format int32 */
  artikelRabattgruppeCountQuelle?: number
  /** @format int32 */
  ekKonditionenCountErzeugtAktuell?: number
  /** @format int32 */
  ekKonditionenCountErzeugtZukunft?: number
  /** @format int32 */
  ekKonditionenCountLieferantenartikelNichtImZiel?: number
  /** @format int32 */
  ekKonditionenCountQuelle?: number
  /** @format int32 */
  hauptlieferantenUpdates?: number
  migrationArtikelEosDatenErzeugenJson?: MigrationArtikelEosDatenErzeugenJson
  state?: UseCaseStateJson
  /** @format int32 */
  vkPreiseCountAllDeletePreis?: number
  /** @format int32 */
  vkPreiseCountAllDeletePreisDz?: number
  /** @format int32 */
  vkPreiseCountAllDeletePreisSe?: number
  /** @format int32 */
  vkPreiseCountAllDeletePreise?: number
  /** @format int32 */
  vkPreiseCountArtikelNichtImZiel?: number
  /** @format int32 */
  vkPreiseCountErzeugtAktuell?: number
  /** @format int32 */
  vkPreiseCountErzeugtZukunft?: number
  /** @format int32 */
  vkPreiseCountQuelle?: number
}

export interface MultipartFile {
  bytes?: string[]
  contentType?: string
  empty?: boolean
  inputStream?: object
  name?: string
  originalFilename?: string
  resource?: Resource
  /** @format int64 */
  size?: number
}

export enum NeonKontext {
  K100 = 'K_100',
  K110 = 'K_110',
  K111 = 'K_111',
  K120 = 'K_120',
  K150 = 'K_150',
  K190 = 'K_190',
  K200 = 'K_200',
  K210 = 'K_210',
  K211 = 'K_211',
  K212 = 'K_212',
  K213 = 'K_213',
  K215 = 'K_215',
  K220 = 'K_220',
  K221 = 'K_221',
  K225 = 'K_225',
  K230 = 'K_230',
  K400 = 'K_400',
  K401 = 'K_401',
  K402 = 'K_402',
  K403 = 'K_403',
  K404 = 'K_404',
  K405 = 'K_405',
  K406 = 'K_406',
  K407 = 'K_407',
  K408 = 'K_408',
  K409 = 'K_409',
  K410 = 'K_410',
  K411 = 'K_411',
  K500 = 'K_500',
  K510 = 'K_510',
  K520 = 'K_520',
  K530 = 'K_530',
  K900 = 'K_900',
  K910 = 'K_910',
  K920 = 'K_920',
  K930 = 'K_930',
  K940 = 'K_940'
}

export interface NeonKontextDisplayJson {
  betriebstyp?: ArtikelBetriebstyp
  betriebstypNr?: string
  default?: boolean
  handelTyp?: HandelTyp
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  kontextName?: string
  kontextNr?: string
}

export interface NeonKontextListeJson {
  kontexte?: NeonKontextDisplayJson[]
  state?: UseCaseStateJson
}

export interface NoExportReasonSeArtikelJson {
  artikel?: ArtikelDisplayTinyJson
  exportError?: ProtokollEintragJson
  importError?: ProtokollEintragJson
  /** @format int64 */
  seId?: number
}

export interface NoExportReasonSeLieferantJson {
  exportError?: ProtokollEintragJson
  importError?: ProtokollEintragJson
  lieferant?: LieferantDisplayTinyJson
  /** @format int64 */
  seId?: number
}

export interface NoExportReasonSeStandortArtikelJson {
  exportError?: ProtokollEintragJson
  importError?: ProtokollEintragJson
  /** @format int64 */
  seId?: number
  standortArtikel?: StandortArtikelJson
}

export interface NummernkreisJson {
  /** @format int64 */
  ende?: number
  /** @format int64 */
  id?: number
  lueckenFuellen?: boolean
  prefix?: string
  /** @format int64 */
  start?: number
  typ?: NummernkreisTyp
}

export enum NummernkreisTyp {
  EIGENLISTUNGS_LIEFERANT = 'EIGENLISTUNGS_LIEFERANT',
  EIGENLISTUNGS_ARTIKEL = 'EIGENLISTUNGS_ARTIKEL'
}

export interface PimArtikelAnzeigenJson {
  artikel?: PimArtikelJson
  details?: ErpArtikelDetailsJson
  display?: ArtikelDisplayTinyJson
  importErrorStatus?: ImportErrorStatusJson
  kontext?: NeonKontext
  kontexte?: PimArtikelKontextJson[]
  /** @format int64 */
  partnerArtikelId?: number
  quelle?: ArtikelQuelle
  /** @format int64 */
  revision?: number
  sprache?: PimSpracheDisplayJson
  sprachen?: PimSpracheDisplayJson[]
  state?: UseCaseStateJson
  versionen?: PimArtikelVersionInfoJson[]
  vkPreise?: PimArtikelVkPreisJson[]
}

export enum PimArtikelArt {
  EINZEL = 'EINZEL',
  DISPLAY = 'DISPLAY',
  PFAND = 'PFAND',
  LEIH = 'LEIH',
  COUPON = 'COUPON',
  PALETTE = 'PALETTE',
  DIENST = 'DIENST',
  FRACHT = 'FRACHT',
  PLANZE = 'PLANZE',
  SET = 'SET',
  MIET = 'MIET',
  WG = 'WG',
  DIENST_KEIN_CO_PA = 'DIENST_KEIN_CO_PA'
}

/**
 * Abfallschluessel
 * Die Europäischen Abfallschlüssel (EAWS).
 */
export interface PimArtikelComplianceAbfallschluesselXML {
  /**
   * Abfallschluessel
   * AS0135: Der Europäische Abfallschlüssel (EAWS).
   * @example "03 02 99"
   */
  Abfallschluessel: string
}

/** Sicherheitsdatenblatt: Gefahrenhinweise (H- / EUH-Sätze). */
export interface PimArtikelComplianceGefahrenhinweisXML {
  /**
   * AS0150: Sicherheitsdatenblatt: Gefahrenhinweis (H- / EUH-Sätze).
   * @example "H229"
   */
  Gefahrenhinweis: string
}

/**
 * Gefahrenpiktogramme
 * Codes der GHS Gefahrenpiktogramme, die für gesundheitliche, physische und umweltliche Gefahren stehen. Die Piktogramme für sicherheitsdatenblattpflichtige Produkte finden sich im Sicherheitsdatenblatt unter Abschnitt 2.2. Diese sind ebenfalls auf dem Gefahrstoff-Etikett anzubringen und im Werbemittel deutlich kenntlich zu machen.
 */
export interface PimArtikelComplianceGefahrenpiktogrammXML {
  /**
   * Gefahrenpiktogramm
   * AS0131: Code der GHS Gefahrenpiktogramme, die für gesundheitliche, physische und umweltliche Gefahren stehen. Die Piktogramme für sicherheitsdatenblattpflichtige Produkte finden sich im Sicherheitsdatenblatt unter Abschnitt 2.2. Diese sind ebenfalls auf dem Gefahrstoff-Etikett anzubringen und im Werbemittel deutlich kenntlich zu machen.
   * @example "GHS09"
   */
  Gefahrenpiktogramm: string
}

/** Die 'Ordnung über die internationale Eisenbahnbeförderung gefährlicher Güter' (RID), das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf der Straße' (ADR) und das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf Binnenwasserstraßen' (ADN), enthalten in Teil 5 sowohl die Muster der Gefahrzettel / Großzettel (Placards), als auch die orangefarbene Tafel, das Kennzeichen umweltgefährdende Stoffe, die Abbildungen der Ausrichtungspfeile, das Kennzeichen für erwärmte Stoffe und das Warnzeichen für Begasung. */
export interface PimArtikelComplianceGefahrzettelNebengefahrXML {
  /**
   * AS0170: Die 'Ordnung über die internationale Eisenbahnbeförderung gefährlicher Güter' (RID), das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf der Straße' (ADR) und das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf Binnenwasserstraßen' (ADN), enthalten in Teil 5 sowohl die Muster der Gefahrzettel / Großzettel (Placards), als auch die orangefarbene Tafel, das Kennzeichen umweltgefährdende Stoffe, die Abbildungen der Ausrichtungspfeile, das Kennzeichen für erwärmte Stoffe und das Warnzeichen für Begasung.
   * @example "5.1"
   */
  GefahrzettelNebengefahr: string
}

/** Klassifizierungscode des Gefahrgutartikels für den Transport auf Straße und Schiene (ADR/RID). Er spezifiziert die gefährlichen Eigenschaften bzw. die Unterklasse  / Verträglichkeitsgruppe des Artikels innerhalb der ihm zugewiesenen Gefahrgutklasse. Bei Gefahrgut zu füllen. */
export interface PimArtikelComplianceKlassifizierungscodeADRXML {
  /**
   * AS0165: Klassifizierungscode des Gefahrgutartikels für den Transport auf Straße und Schiene (ADR/RID). Er spezifiziert die gefährlichen Eigenschaften bzw. die Unterklasse  / Verträglichkeitsgruppe des Artikels innerhalb der ihm zugewiesenen Gefahrgutklasse. Bei Gefahrgut zu füllen.
   * @example "CF1"
   */
  KlassifizierungscodeADR: string
}

/** Konsistenz des Gefahrstoffs. */
export interface PimArtikelComplianceKonsistenzXML {
  /**
   * AS0163: Konsistenz des Gefahrstoffs.
   * @example "Granulat"
   */
  Konsistenz: string
}

export interface PimArtikelComplianceLandXML {
  /**
   * AS0341: Getränkepfand Einweg gemäß länderspezifischen Pfandsystem in EUR.
   * @example 0.25
   */
  GetraenkeEinwegPfand?: number
  /**
   * AS0340: Getränkepfand Mehrweg gemäß länderspezifischen Pfandsystem in EUR.
   * @example 0.25
   */
  GetraenkeMehrwegPfand?: number
  /**
   * Ländercode (ISO 3166-1-alpha2).
   * @example "DE"
   */
  Land: string
  /**
   * AS0344: In der österreichische 15A-Vereinbarung werden u.a. definierte Grenzwerte für Kohlenwasserstoffe und Stickoxide geregelt. Artikel, die unter diese Verordnung fallen, müssen als solche gekennzeichnet werden.
   * @example true
   */
  RelevantKaminoefenOeNorm?: boolean
}

export interface PimArtikelComplianceNetzanschlussTypXML {
  /**
   * AS0209: Gibt an über welchen Netzanschluss der Artikel verfügt.
   * @example "CEE-Drehstromstecker"
   */
  NetzanschlussTyp: string
}

/** Produktkennzeichnungen nach ProdSG. */
export interface PimArtikelComplianceProdSGProduktkennzeichnungXML {
  /** AS0194: Produktkennzeichnung nach ProdSG. */
  ProdSGProduktkennzeichnung: string
}

/** Warnhinweise gemäß Produktsicherheitsgesetz. */
export interface PimArtikelComplianceProdSGWarnhinweisXML {
  /**
   * AS0211: Warnhinweise gemäß Produktsicherheitsgesetz.
   * @example "Achtung: Mit Schutzausrüstung zu benutzen. Nicht im Straßenverkehr zu verwenden."
   */
  ProdSGWarnhinweis: string
}

/** Sicherheitsdatenblatt: Sicherheitshinweise (P-Sätze). */
export interface PimArtikelComplianceSicherheitshinweisXML {
  /**
   * AS0151: Sicherheitsdatenblatt: Sicherheitshinweis (P-Satz).
   * @example "P251"
   */
  Sicherheitshinweis: string
}

export interface PimArtikelComplianceSpracheXML {
  /**
   * Sprache ID (ISO 639-1).
   * @example "de"
   */
  Sprache: string
  /**
   * AS0172: Die Absätze 5.4.1.1.3 bis 5.4.1.1.21, 5.4.1.2.1 bis 5.4.1.2.4 und Unterabschnitt 5.5.2.1  im ADR enthalten weitere Angaben, die ggf. zusätzlich im Beförderungspapier angegeben werden müssen, Z. B. 'umweltgefährdend', 'Bergungsverpackung', 'Beförderung nach Unterabschnitt 4.1.2.2', 'Kontrolltemperatur in 0C' oder 'Sondervorschrift 640X'.
   * @example "umweltgefährdend"
   */
  ZusaetzlicheAngabenBefoerderungspapier?: string
}

/** Die Verpackungen in der der Gefahrstoff transportiert wird. */
export interface PimArtikelComplianceVerpackungsartXML {
  /** AS0161: Die Verpackungen in der der Gefahrstoff transportiert wird. */
  Verpackungsart: string
}

/**
 * WEEEKategorien
 * Kategorienummern von Elektro- oder Elektronikgeräten (WEEE - Waste Electrical and Electronic Equipment).
 */
export interface PimArtikelComplianceWEEEKategorieXML {
  /**
   * WEEEKategorie
   * AS0136: Kategorienummer von Elektro- oder Elektronikgeräten nach §6 ElektroG (WEEE - Waste Electrical and Electronic Equipment).
   * @example "01"
   */
  WEEEKategorie: string
}

/** Kategoriedaten Compliance */
export interface PimArtikelComplianceXML {
  /**
   * AS0212: Ecodesign 2022 ist nur eine von mehreren Maßnahmen der EU-Ökodesign Richtlinie, mit dem Ziel, den Co2- und Schadstoffausstoß zu senken und die Luftqualität zu verbessern. Seit dem 1. Januar 2022 ist die neue Verordnung maßgebend für Biomasse- und Festbrennstoff-Heizgeräte bis 50 kW. Ab diesem Zeitpunkt dürfen innerhalb der Europäischen Union und des Europäischen Wirtschaftsraumes nur noch Geräte in Umlauf gebracht werden, welche die Vorgaben in punkto Energieeffizienz und Emissionen erfüllen.
   * @example true
   */
  Ecodesign2022?: boolean
  /**
   * AS0338: Neben der Mineralölsteuer müssen Unternehmen, die Kraftstoffe in Österreich herstellen oder nach Österreich importieren, seit Oktober 2022 zusätzlich für jene CO2-Emissionen bezahlen, die bei der Verbrennung dieser Kraftstoffe entstehen.
   * @example true
   */
  NationaleCO2AbgabeATRelevanz?: boolean
  /**
   * AS0167: Vollständige chemisch-technische Bezeichnung des Gefahrgutartikels gemäß Stoffliste für den Transport auf Schiene und Straße (ADR/RID). Bei Gefahrgut zu füllen. Abhängig von UN-Nummer.
   * @example "Dinatriumtrioxosilikat Gemisch"
   */
  ADRKlasse?: string
  /**
   * AS0402: berechnet sich automatisch aus Gefahrgutfaktor ID AS0124 multipliziert mit Gefahrgutmenge ID AS0125 oder ID AS0126. Es gibt je nach Gefahrgut, definierte zulässige Obergrenzen von ADR Punkten pro Transporteinheit. Bleibt der Transport darunter, brauch er keine bzw. weniger Vorschriften beim Gefahrguttransport beachten.
   * @example 333
   */
  ADRPunktzahl?: number
  /**
   * Abfallschluessel
   * Die Europäischen Abfallschlüssel (EAWS).
   */
  Abfallschluessel?: PimArtikelComplianceAbfallschluesselXML[]
  /**
   * AS0347: Angabe mit Ja, wenn der Artikel erst ab FSK 18 verkauft werden darf.
   * @example true
   */
  AlterspruefungRelevanz?: boolean
  /**
   * AS0403: von UN-Nummer ableitbar, definiert den Gefahrgutfaktor ID AS0124. Jeder Beförderungskategorie ist eine Gefahrgutfaktor zugeordnet, der wiederum für die Berechnung der ADR-Punktzahl wichtig ist.
   * @example "1"
   */
  Befoerderungskategorie?: string
  /**
   * AS0276: Artikel, die gem. Explosivgrundstoffverordnung beschränkt sind, dürfen nicht an Mitglieder der Allgemeinheit verkauft werden, sondern z.B. nur mit Gewerbeschein o.ä.
   * @example true
   */
  BeschraenkterAusgangsstoff?: boolean
  /**
   * AS0159: Gibt an, ob es sich um einen besonders besorgniserregenden Stoff gemäß EU-Chemikalienverordnung REACH handelt.
   * @example true
   */
  BesondersBesorgniserregenderStoff?: boolean
  /**
   * Biozid
   * AS0133: Angabe, ob es sich um ein Biozid-Produkt handelt oder nicht. Grundlage hierfür ist die EU Verordnung 528/2012 über Biozidprodukte. Biozidprodukte sind Zubereitungen, die einen oder mehrere biozide Wirkstoffe enthalten, mit denen Schadorganismen abgeschreckt, unschädlich gemacht oder zerstört werden. Für die Kommunikation hin zum Konsumenten, ist bei jeglicher Art von Werbung der folgende Hinweis aufzu-führen: Biozide sicher verwenden. Vor Gebrauch Kennzeichnung und Produktinformation lesen.
   */
  Biozid?: boolean
  /**
   * AS0190: Produkt verfügt über eine CE-Kennzeichnung.
   * @example "EN 14683:2019"
   */
  CEKennzeichen?: string
  /**
   * AS0196: Fällt der Artikel unter die CE-Kennzeichnungspflicht gemäß ProdSG.
   * @example true
   */
  CERelevanz?: boolean
  /**
   * AS0343: Produkt unterliegt der Druckgasverordnung.
   * @example true
   */
  DruckgasverordnungRelervanz?: boolean
  /**
   * AS0342: Bauprodukt darf in der EU für Privatpersonen verbaut werden.
   * @example true
   */
  EUNormRelevanz?: boolean
  /**
   * AS0192: Modellbezeichnung EnVKV (Energieverbrauchskennzeichnungsverordnung).
   * @example "F6WV710P2S"
   */
  EnVKVModellbezeichnung?: string
  /**
   * AS0197: Relevanz EnVKV (Energieverbrauchskennzeichnungsverordnung). Die Energieeffizienzklasse (EEK) ist eine Bewertungsskala für das europäische Energielabel. Dieses soll den Absatz von besonders energiesparenden Elektrogeräten in der EU fördern. Die Bewertungsskala soll über den Energiebedarf (Strom und andere Energieträger) und über zusätzliche Gebrauchseigenschaften des Gerätes Auskunft geben. 2017 beschloss das EU-Parlament eine Rückkehr zur Klassen-Kennzeichnung von A bis G, also eine Abschaffung von A+++ usw. Zum 1. August 2017 trat dazu die neue EU-Energielabel-Verordnung (Verordnung (EU) 2017/1369) in Kraft. Als erster Schritt wird für Waschmaschinen, Kühlschränke, Geschirrspüler, TV-Geräte und die Beleuchtung die Kennzeichnung überarbeitet. Die neue Regelung soll mehr Klarheit für den Verbraucher bringen und voraussichtlich ab Anfang 2020 im Handel sichtbar werden.
   * @example true
   */
  EnVKVRelevanz?: boolean
  /**
   * AS0201: Skala Energieeffizienzklasse EnVKV (Energieverbrauchskennzeichnungsverordnung).
   * @example "A"
   */
  EnVKVSkalaEnergieeffizienzklasse?: string
  /**
   * Energieeffizienzklasse
   * AS0137: Die Energieeffizienzklasse (EEK) ist eine Bewertungsskala für das europäische Energielabel.
   * @example "A"
   */
  Energieeffizienzklasse?: string
  /**
   * Frostgefaehrdet
   * AS0292: Angabe, ob ein Artikel frostgefährdet ist.
   */
  Frostgefaehrdet?: boolean
  /** Sicherheitsdatenblatt: Gefahrenhinweise (H- / EUH-Sätze). */
  Gefahrenhinweise?: PimArtikelComplianceGefahrenhinweisXML[]
  /**
   * AS0153: Gibt an, ob auf dem Produkt ein Etikett gemäß CLP-Verordnung (Verordnung (EG) Nr. 1272/2008) angebracht ist.
   * @example true
   */
  Gefahrenkennzeichnungsetikett?: boolean
  /**
   * Gefahrenpiktogramme
   * Codes der GHS Gefahrenpiktogramme, die für gesundheitliche, physische und umweltliche Gefahren stehen. Die Piktogramme für sicherheitsdatenblattpflichtige Produkte finden sich im Sicherheitsdatenblatt unter Abschnitt 2.2. Diese sind ebenfalls auf dem Gefahrstoff-Etikett anzubringen und im Werbemittel deutlich kenntlich zu machen.
   */
  Gefahrenpiktogramme?: PimArtikelComplianceGefahrenpiktogrammXML[]
  /**
   * Gefahrgut
   * AS0123: Angabe, ob der Artikel und/oder eine seiner Verpackungseinheiten nach ADR als Gefahrgut eingestuft ist. Bei positiver Angabe werden weitere Gefahrgutangaben verpflichtend.
   */
  Gefahrgut?: boolean
  /**
   * AS0152: Gefahrgutklasse ADR (European Agreement concerning the International Carriage of Dangerous Goods by Road).
   * @example "4.2"
   */
  GefahrgutADRNummer?: string
  /**
   * GefahrgutMenge
   * AS0125: Menge des Gefahrstoffes in einer Kilogramm (kg) oder Liter (l). Diese multipliziert mit dem Gefahrgutfaktor ergibt die ADR-Punktzahl.
   * @example 21.8
   */
  GefahrgutMenge?: number
  /**
   * GefahrgutMengeVolumen
   * AS0126: Menge des Gefahrstoffes in einer Transport-Mengeneinheit. Diese multipliziert mit dem Gefahrgut-faktor ergibt die ADR-Punktzahl.
   * @example 21.8
   */
  GefahrgutMengeVolumen?: number
  /**
   * AS0149: Sagt aus, ob ein Produkt bestimmten Sondervorschriften entspricht.
   * @example "Das Produkt entspricht den Sondervorschriften 295, 304 und 598."
   */
  GefahrgutSondervorschrift?: string
  /**
   * GefahrgutUNNummer
   * AS0129: Kennnummer für alle gefährlichen Güter. Sie ist die untere Nummer auf den auf allen Gefahrguttransporten angebrachten orangen Warntafeln und beschreibt die Zusammensetzung des Gefahrgutes.
   * @example "2570"
   */
  GefahrgutUNNummer?: string
  /**
   * GefahrgutVerpackungsgruppe
   * AS0128: Minimale Verpackungsgruppe des Gefahrgutartikels für den Transport auf Straße und Schiene (ADR/RID). Sie klassifiziert das Gefahrenpotential für diesen Artikel. Eine Verpackungsgruppe gruppiert Stoffe nach ihrem Gefahrengrades während der Beförderung.
   * @example "II"
   */
  GefahrgutVerpackungsgruppe?: string
  /**
   * Gefahrgutfaktor
   * AS0124: Wenn Gefahrgüter mit unterschiedlichen Beförderungskategorien (0,1,2,3,4) zusammen transportiert werden. Muss deren Mengen mit dem Gefahrgutfaktor multipliziert werden. Das Ergebnis darf 1000 Punkte nicht überschreiten damit der Transport von den ADR Richtlinien freigestellt wird.
   * @example 50
   */
  Gefahrgutfaktor?: number
  /**
   * AS0164: Gefahrnummer für Chemikalien gemäß der CLP-Verordnung (Sie ist die obere Nummer auf den auf allen Gefahrguttransporten angebrachten orangen Warntafeln).
   * @example "X338"
   */
  Gefahrnummer?: string
  /**
   * Gefahrstoff
   * AS0121: Gibt an, ob der Artikel Gefahrstoffeigenschaften (Sicherheitsdatenblatt (SDB) relevant) aufweist, wodurch er Sicherheitsdatenblatt-pflichtig ist.
   */
  Gefahrstoff?: boolean
  /** AS0287: Beschreibt in welcher, der 28 Gefahrstoffklassen der Gefahrstoff eingestuft wurde. */
  Gefahrstoffeinstufung?: string
  /**
   * AS0169: Die 'Ordnung über die Integerernationale Eisenbahnbeförderung gefährlicher Güter' (RID), das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf der Straße' (ADR) und das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf Binnenwasserstraßen' (ADN), enthalten in Teil 5 sowohl die Muster der Gefahrzettel / Großzettel (Placards), als auch die orangefarbene Tafel, das Kennzeichen umweltgefährdende Stoffe, die Abbildungen der Ausrichtungspfeile, das Kennzeichen für erwärmte Stoffe und das Warnzeichen für Begasung.
   * @example "2.3"
   */
  Gefahrzettel?: string
  /** Die 'Ordnung über die internationale Eisenbahnbeförderung gefährlicher Güter' (RID), das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf der Straße' (ADR) und das 'Europäische Übereinkommen über die internationale Beförderung gefährlicher Güter auf Binnenwasserstraßen' (ADN), enthalten in Teil 5 sowohl die Muster der Gefahrzettel / Großzettel (Placards), als auch die orangefarbene Tafel, das Kennzeichen umweltgefährdende Stoffe, die Abbildungen der Ausrichtungspfeile, das Kennzeichen für erwärmte Stoffe und das Warnzeichen für Begasung. */
  GefahrzettelNebengefahren?: PimArtikelComplianceGefahrzettelNebengefahrXML[]
  /**
   * AS0441: gibt den Härtegrad von Styroporsteinen an, rechtliche Relevant bei Pools.
   * @example "P25"
   */
  HaertegradStyroporsteine?: string
  /**
   * AS0156: Hausnummer oder Postfachnummer des Inverkehrbringers des Gemisches gemäß Verpackungstext.
   * @example "12"
   */
  InverkehrbringerHausnummerPostfachnummer?: string
  /**
   * AS0158: Land des Inverkehrbringers des Gemisches gemäß Verpackungstext (ISO 3166-1-alpha2).
   * @example "DE"
   */
  InverkehrbringerLand?: string
  /**
   * AS0154: Name des Inverkehrbringers des Gemisches gemäß Verpackungstext.
   * @example "x GmbH"
   */
  InverkehrbringerName?: string
  /**
   * AS0157: PLZ des Inverkehrbringers des Gemisches gemäß Verpackungstext.
   * @example "12345"
   */
  InverkehrbringerPLZ?: string
  /**
   * AS0155: Straße oder Postfach des Inverkehrbringers des Gemisches gemäß Verpackungstext.
   * @example "Beispielstraße"
   */
  InverkehrbringerStrassePostfach?: string
  /** Klassifizierungscode des Gefahrgutartikels für den Transport auf Straße und Schiene (ADR/RID). Er spezifiziert die gefährlichen Eigenschaften bzw. die Unterklasse  / Verträglichkeitsgruppe des Artikels innerhalb der ihm zugewiesenen Gefahrgutklasse. Bei Gefahrgut zu füllen. */
  KlassifizierungscodesADR?: PimArtikelComplianceKlassifizierungscodeADRXML[]
  /** Konsistenz des Gefahrstoffs. */
  Konsistenzen?: PimArtikelComplianceKonsistenzXML[]
  Laender?: PimArtikelComplianceLandXML[]
  /**
   * AS0122: Jedem gelagerten Gefahrstoff ist, abhängig von seinen Gefahrenmerkmalen, eine Lagerklasse (LGK) zugeordnet. Die Definition der Lagerklassen entspricht dem 'Konzept zur Zusammenlagerung von Chemikalien' des VCI. Grundlage dieses Konzeptes sind Gesetze, Vorschriften und Technische Regeln der Bundesrepublik Deutschland.
   * @example "13"
   */
  Lagerklasse?: string
  /**
   * AS0168: Angabe, ob für den Artikel oder eine seiner Verpackungseinheiten gemäß ADR die Möglichkeit von teilweiser Befreiung von den Transportvorschriften besteht. Diese Erleichterungen für den Transport für begrenzte Mengen sind in Kapitel 3.4 ADR definiert. Bei Gefahrgut zu füllen.
   * @example true
   */
  LimitedQuantity?: boolean
  /**
   * LithiumhaltigeBatterie
   * AS0134: Gibt an, ob der Artkel lithiumhaltige Akkus enthält, wodurch gesonderte Lager-, Transport- und Sicherheitsmaßnahmen aktiviert werden.
   */
  LithiumhaltigeBatterie?: boolean
  /**
   * MHDRelevant
   * AS0052: Gibt an, ob es sich um einen verderblichen oder abnutzenden Artikel handelt, dem vom Hersteller eine Mindesthaltbarkeit zugeschrieben wurde.
   */
  MHDRelevant?: boolean
  /**
   * AS0275: Artikel, die gem. Explosivgrundstoffverordnung meldepflichtig sind, müssen bei einer bestimmten Abgabemenge aufgezeigt werden.
   * @example true
   */
  MeldepflichtigerAusgangstoff?: boolean
  /**
   * AS0333: Die maximale Abgabemenge wird durch die hagebau und nicht den Lieferanten definiert.
   * @example 8
   */
  MeldepflichtigerAusgangstoffMaximaleAbgabemenge?: number
  /**
   * AS0334: Mengeneinheit, die in der Kombination mit der maximalen Abgabemenge von Nöten ist (EANCOM Element 6411-Code).
   * @example "PCE"
   */
  MeldepflichtigerAusgangstoffMengeneinheit?: string
  /**
   * AS0166: Nettoexplosivstoffmasse des Artikels in Kilogramm, die für den Transport auf straße und Schiene gemäß ADR/RID im Beförderungspapier anzugeben ist. Bei Gefahrgut zu füllen.
   * @example 2.175
   */
  Nettoexplosivstoffmasse?: number
  NetzanschlussTypen?: PimArtikelComplianceNetzanschlussTypXML[]
  /**
   * AS0193: Netzspannung des Elektroartikels in Volt (V).
   * @format int32
   * @example 240
   */
  Netzspannung?: number
  /**
   * AS0401: Angabe, ob es sich um ein Biozid-Produkt handelt oder nicht.
   * @example true
   */
  PflanzenschutzmittelRelevanz?: boolean
  /** AS0191: Kennzeichnungspflichten: zur Identifizierung des Bereitstellers und des Produkts bei Verbraucherprodukten nach § 6 Produktsicherheitsgesetz. */
  ProdSGHerstelleradresse?: string
  /** Produktkennzeichnungen nach ProdSG. */
  ProdSGProduktkennzeichnungen?: PimArtikelComplianceProdSGProduktkennzeichnungXML[]
  /**
   * AS0198: Fällt der Artikel unter das ProdSG (Produktsicherheitsgesetz).
   * @example true
   */
  ProdSGRelevanz?: boolean
  /** Warnhinweise gemäß Produktsicherheitsgesetz. */
  ProdSGWarnhinweise?: PimArtikelComplianceProdSGWarnhinweisXML[]
  /**
   * AS0173: Produktidentifikatoren für Gemische.
   * @example "Calciumhydrid EC-Nr.: 232-189-2 CAS-Nr.: 7789-78-8"
   */
  Produktidentifikatoren?: string
  /**
   * AS0551: EUDR-Kategorie (ID).<br>Mögliche Werte:<br>10 = Rinder<br>20 = Kakao<br>30 = Kaffee<br>40 = Ölpalme<br>50 = natürliches Kautschuk<br>60 = synthetisches Kautschuk<br>70 = Soja<br>80 = Holz<br>90 = sonstiges Material - nicht von EUDR betroffen
   * @example "50"
   */
  RelevanterRohstoffNachEUDR?: '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90'
  /**
   * AS0160: SCIP Nummer (Substances of Concern In Products) gemäß EU-Chemikalienverordnung REACH.
   * @example "1234567483729580"
   */
  SCIPNummer?: string
  /** Sicherheitsdatenblatt: Sicherheitshinweise (P-Sätze). */
  Sicherheitshinweise?: PimArtikelComplianceSicherheitshinweisXML[]
  /**
   * Signalwort
   * GHS Signalwort Codes (ACHTUNG und GEFAHR), die verwendet werden, um die Gefahr und den Schweregrad der Gefahr, der einem Stoff oder Gemisch innewohnt, zum Ausdruck zu bringen.
   * @example "ACHTUNG"
   */
  Signalwort?: string
  Sprachen?: PimArtikelComplianceSpracheXML[]
  /**
   * AS0199: Die Europäische Textilkennzeichnungsverordnung (Verordnung (EU) Nr. 1007/2011, nachfolgend
   * TextilKVO genannt) regelt insbesondere die Art und Weise der Etikettierung und Kennzeichnung von
   * Textilerzeugnissen, die in den Anwendungsbereich dieser Verordnung fallen. Sie trifft ferner Vorschriften für
   * die Verwendung von Bezeichnungen von Textilfasern sowie über die Kennzeichnung nicht textiler Teile
   * tierischen Ursprungs (z. B. Fell oder Leder). Darüber hinaus enthält sie Regelungen über die Bestimmung
   * der Faserzusammensetzung durch quantitative Analyse. Die Verordnung hat zum Ziel das Funktionieren
   * des Binnenmarkts zu verbessern und dient dem Schutz des Verbrauchers, der sich vor dem Kauf eines
   * Textilerzeugnisses insbesondere über dessen Faserzusammensetzung sowie das Vorhandensein
   * nichttextiler Teile tierischen Ursprungs informieren können soll. Die Verordnung gilt unmittelbar in den
   * Mitgliedsstaaten der Europäischen Union und bedarf hinsichtlich ihrer materiellen Vorschriften keiner
   * Umsetzung in nationales Recht.
   * @example true
   */
  TextilKVORelevanz?: boolean
  /**
   * AS0214: Anteil in Prozenzt - Textilkomponente 1.
   * @example 20
   */
  TextilkomponenteAnteil?: number
  /**
   * AS0213: Material - Textilkomponente 1.
   * @example "Baumwolle"
   */
  TextilkomponenteBezeichnung?: string
  /**
   * Tunnelbeschraenkungscode
   * AS0127: Zuweisung eines Tunnelbeschränkungscode (A bis E) an das Gefahrengut. Die Einstufung und entsprechende Kennzeichnung an den Tunneln regelt die Durchfahrtserlaubnis für die entsprechend gekennzeichneten  Beförderungen (Verbotszeichen 2Nr. 261 der StVO).
   * @example "D"
   */
  Tunnelbeschraenkungscode?: string
  /**
   * AS0285: Einstufung gewässergefährdener Stoffe nach GHS bzw. CLP-Verordnung, hierbei handelt es sich um eine Gefahrgutrechtliche Einstufung.
   * @example "Aquatisch chronisch 1"
   */
  Umweltgefaehrdungsklasse?: string
  /**
   * AS0140: Die Verpackungsverordnung legt die Verantwortung für die Entsorgung von Verpackungsmüll fest. Sie nimmt die Hersteller und den Handel in die Pflicht, Verpackungen nach dem Gebrauch zurückzunehmen und sich bei der Entsorgung zu beteiligen.
   * @example true
   */
  VerpackungMehrwegkennzeichnung?: boolean
  /** Die Verpackungen in der der Gefahrstoff transportiert wird. */
  Verpackungsarten?: PimArtikelComplianceVerpackungsartXML[]
  /**
   * WEEEKategorien
   * Kategorienummern von Elektro- oder Elektronikgeräten (WEEE - Waste Electrical and Electronic Equipment).
   */
  WEEEKategorien?: PimArtikelComplianceWEEEKategorieXML[]
  /**
   * AS0200: Fällt der Artikel unter die WEEE-Richtlinie.
   * @example true
   */
  WEEERelevanz?: boolean
  /**
   * Wassergefaehrdungsklasse
   * AS0130: Einstufung eines Stoffes oder eines Gemisches zur Eignung der Verunreinigung eines Gewässers. Ergibt sich aus §39 und Anlage 1 Kap. 4 Verordnung über Anlagen zum Umgang mit wassergefährdenden Stoffen (AwSV).
   * @example "2570"
   */
  Wassergefaehrdungsklasse?: string
  /**
   * AS0171: Abhängig von Beförderungskategorie.
   * @example "unbegrenzt"
   */
  ZulaessigeGesamtmengeJeBefoerderungseinheit?: string
}

export interface PimArtikelDataExportParameterJson {
  /** @format int32 */
  erstenNArtikel?: number
  hageNrs?: string[]
  includeXML?: boolean
  kontexte?: NeonKontext[]
  liefNrs?: string[]
  offsets?: number[]
  /** @format int64 */
  pimSystemId?: number
  /** @format int32 */
  sendChunkSize?: number
}

export interface PimArtikelDisplayXML {
  /**
   * AS0280: Angabe des EK-Preises eines Stücklistenartikels auf einem Display.
   * @example 4.99
   */
  EK?: number
  /**
   * Hagebau Artikelnummer eines Artikel der Bestandteil eines Displays ist.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * AS0278: Angabe der dazugehörigen Menge / VE des untergeordneten Artikels.
   * @format int32
   * @example 24
   */
  Menge: number
}

export interface PimArtikelFlieseSpracheXML {
  /**
   * AS0353: grobe Beschreibung des Artikels (z.B. Bodenfliese, Zubehör, Mosaik).
   * @example "Bodenfliese"
   */
  Oberkategorie?: string
  /**
   * Sprache ID (ISO 639-1).
   * @example "de"
   */
  Sprache: string
}

/** Kategoriedaten Fliese */
export interface PimArtikelFlieseXML {
  /**
   * Angebotstext1
   * AS0237: Vertriebstext, der durch die Listung vorgeschlagen wird. Dienst oftmals der anonymisierten Artikelauszeichnung (ohne Lieferantenbezug) in den Ausstellungen.
   * @example "Bodenfliese rot 30x60 cm"
   */
  Angebotstext1?: string
  /**
   * Angebotstext2
   * AS0238: Vertriebstext, der durch die Listung vorgeschlagen wird. Dienst oftmals der anonymisierten Artikelauszeichnung (ohne Lieferantenbezug) in den Ausstellungen.
   * @example "R10, A, rektifiziert"
   */
  Angebotstext2?: string
  /**
   * Gewicht pro M2
   * AS0254: Angabe des Gewichtes pro M2 in kg.
   * @example 5.9
   */
  GewichtProM2?: number
  /**
   * Inhalt pro Karton in m2
   * AS0034: Angabe der Fläche in m², die pro Liefereinheit Karton verlegbar ist. Diese Angabe ist bspw. für Bodenbeläge wichtig, um von der Liefereinheit auf die damit verlegbare Fläche schließen zu können.
   * @example 6.25
   */
  InhaltProKartonM2?: number
  /**
   * Inhalt pro Palette in m2
   * AS0235: Angabe der Fläche in m², die pro Liefereinheit Palette verlegbar ist. Diese Angabe ist bspw. für Bodenbeläge wichtig, um von der Liefereinheit auf die damit verlegbare Fläche schließen zu können.  .
   * @example 35.76
   */
  InhaltProPaletteM2?: number
  /**
   * Abriebgruppe
   * AS0230: Klassifizierung der Abriebfestigkeit. Die Angabe erfolgt nach den Klassen 1 bis 5.
   * @format int32
   * @example 1
   */
  Abriebgruppe?: number
  /**
   * Design
   * AS0031: Bezeichnung der optischen Eigenschaften des Bodenbelages, z.B. Steinoptik, Holzoptik etc.
   * @example "Steinoptik"
   */
  Design?: string
  /**
   * Einsatzbereich
   * AS0030: Angabe, ob der Bodenbelag im Innen- oder Außenbereich oder in beidem verwendet werden kann.
   * @example "innen und außen"
   */
  Einsatzbereich?: string
  /**
   * Farbe
   * AS0026: Angabe der originalen Farbbezeichnung des Herstellers.
   * @example "Burgunderrot"
   */
  Farbe?: string
  /**
   * Farbfamilie
   * AS0234: Einordnung der Farbbezeichnung des Lieferanten in eine übergeordnete "grobe" Farbkennzeichnung. Wird im Rahmen der kommenden Preispflegen in diesem Feld gepflegt.
   * @example "rot"
   */
  Farbfamilie?: string
  /**
   * Formatgruppe
   * AS0239: Einordnung der Fliese in eine Formatgruppe. Hierzu werden die Breite und die Länge des Nennmaßes kombiniert.
   * @example "30x60"
   */
  Formatgruppe?: string
  /**
   * AS0354: Angabe, ob eine Fliese frostsicher ist oder nicht.
   * @example true
   */
  Frostsicher?: boolean
  /**
   * Glasierung
   * AS0028: Angabe, ob die Fliese glasiert ist oder nicht.
   */
  Glasierung?: boolean
  /**
   * AS0352: Angabe, ob eine Fliese rektifiziert/kalibiriert ist oder eine Presskante/Naturkante hat.
   * @format int32
   * @example 1
   */
  Kantenausbildung?: number
  /**
   * Kategorie
   * AS0024: Angabe, um was für eine Artikelart es sich handelt. Pflege der Daten in Form von Oberbegriffen wie bspw. Bodenfliese, Wandfliese, Sockel, etc.
   * @example "Wandfliese"
   */
  Kategorie?: string
  /**
   * Material
   * AS0025: Angabe des Grundmaterials, aus dem Fliese.
   * @example "Feinsteinzeug"
   */
  Material?: string
  /**
   * Nassbereich
   * AS0232: Angabe, inwiefern eine Fliese in Bereichen mit hoher Nässe einsetzbar ist. Die Klassifizierung erfolgt in den Klassen A bis C.
   * @example "A"
   */
  Nassbereich?: string
  /**
   * Nennmaß Breite mm
   * AS0020: Angabe der Nennmaße. Das Nennmaß dient der vertrieblichen Kommunikation. Die Fliese wird hierzu in Formaten eingeordnet. Die Produktionsmaße werden hierzu aufgerundet. Die Angabe erfolgt in cm nach Schema Breite x Länge.
   * @format int32
   * @example 30
   */
  NennBreite?: number
  /**
   * Produktionsmaß Länge mm
   * AS0021: Angabe der Nennmaße. Das Nennmaß dient der vertrieblichen Kommunikation. Die Fliese wird hierzu in Formaten eingeordnet. Die Produktionsmaße werden hierzu aufgerundet. Die Angabe erfolgt in cm nach Schema Breite x Länge.
   * @format int32
   * @example 60
   */
  NennLaenge?: number
  /**
   * Oberflaeche
   * AS0027: Angaben zum Glanzgrad (matt, Glänzend, etc.) oder weiteren Oberflächeneigenschaften des Boden-/Wandbelages.
   * @example "glänzend"
   */
  Oberflaeche?: string
  /**
   * Oberflaechenveredelung
   * AS0032: Angabe, ob spezielle Oberflächen-Veredelungsverfahren angewendet wurden, z.B. zur Erhöhung der Wasseraufnahme-Fähigkeit.
   */
  Oberflaechenveredelung?: string
  /**
   * Produktionsmaß Breite mm
   * AS0017: Das Produktionsmaß gibt die tatsächliche Größe der Fliese an. Angabe der exakten Produktionsmaße der Einzelelemente. Diese Angabe ist u.a. bei Bodenbelägen wie Fliesen relevant und beziehen sich auf die Nettomaße ohne Fugen o.Ä.
   * @format int32
   * @example 298
   */
  ProduktBreite?: number
  /**
   * Produktionsmaß Höhe mm
   * AS0019: Das Produktionsmaß gibt die tatsächliche Größe der Fliese an. Angabe der exakten Produktionsmaße der Einzelelemente. Diese Angabe ist u.a. bei Bodenbelägen wie Fliesen relevant und beziehen sich auf die Nettomaße ohne Fugen o.Ä.
   * @format int32
   * @example 10
   */
  ProduktHoehe?: number
  /**
   * Produktionsmaß Länge mm
   * AS0018: Das Produktionsmaß gibt die tatsächliche Größe der Fliese an. Angabe der exakten Produktionsmaße der Einzelelemente. Diese Angabe ist u.a. bei Bodenbelägen wie Fliesen relevant und beziehen sich auf die Nettomaße ohne Fugen o.Ä.
   * @format int32
   * @example 598
   */
  ProduktLaenge?: number
  /**
   * Rutschfestigkeit
   * AS0231: Angabe, wie rutschfest eine Fliese ist. Die möglichen Klassifizierungen sind R9 bis R13.
   * @example "R13"
   */
  Rutschfestigkeit?: string
  /**
   * Serienname
   * AS0023: Angabe der Bezeichnung der Kollektion oder Serie .
   * @example "Delight"
   */
  Serienname?: string
  Sprachen?: PimArtikelFlieseSpracheXML[]
  /**
   * Verdraengungsraum
   * AS0022: Angabe zur Oberflächen-Profiltiefe des Bodenbelages. Durch Einkerbungen in der Oberfläche entsteht ein Verdrängungsraum für schmierende Flüssig- und Feststoffe, was die Rutsch- und Trittsicherheit erhöht. Die Einstufung erfolgt in V-Gruppen, die einen Verdrängungswert repräsentieren.
   * @example "V4"
   */
  Verdraengungsraum?: string
}

/**
 * Zusatz EANs
 * AS0313: Zusatz-EANs, die auf dieser Einheit angebracht sind.
 * @format 8-. 11- oder 13-stellig (GTIN-8, GTIN-13), wenn 11-stellig kommt --> dann mit 2 führenden 0 aufgeüllt.
 */
export interface PimArtikelGtinXML {
  /**
   * AS0313: Global Trade Item Number (früher auch EAN).
   * @format 8-, 11- oder 13-stellige Zahl (GTIN-8, GTIN-13). Bitte mit führenden 0 angeben. Auffüllen auf 8 und >8 auffüllen auf 13.
   * @minLength 0
   * @maxLength 35
   * @example "4027808029305"
   */
  GTIN?: string
  /**
   * Geloescht
   * AS0326: Gibt an, ob die GTIN gelöscht wurde.
   */
  Geloescht?: boolean
}

export interface PimArtikelJson {
  hageNummer?: string
  /** @format int64 */
  id?: number
  lieferanten?: PimArtikelLieferantJson[]
  record?: PimRecordJson
  version?: PimArtikelVersionJson
  versionKontext?: PimArtikelVersionKontextJson
}

/**
 * Steuer-Attribute für den B2C iShop. Wird im PIM-Workflow auf Basis std_online_reservierfaehig_endkunde und std_online_bestellbar_endkunde sowie Validerungsergebnis gesetzt.
 * @example "sth"
 */
export interface PimArtikelKontextCrossChannelVarianteOnlineXML {
  /**
   * AS0364: Steuer-Attribut für den B2C iShop. Wird im PIM-Workflow auf Basis std_online_reservierfaehig_endkunde und std_online_bestellbar_endkunde sowie Validerungsergebnis gesetzt.
   * @example "sth"
   */
  CrossChannelVarianteOnline: string
}

export interface PimArtikelKontextJson {
  /** @format int64 */
  id?: number
  pimKontext?: NeonKontextDisplayJson
  seErgaenzung?: boolean
}

/**
 * Kategoriedaten Pflanze. Deprecated: Alle Pflanzenattribute sind auf die ArtikelPflanze Ebene umgezogen.
 * @deprecated
 */
export type PimArtikelKontextPflanzeXML = object

export interface PimArtikelKontextSpracheXML {
  /** Informationen zum Artikel zur Ausgabe an andere Systeme. */
  Artikelbeschreibung?: string
  /**
   * Sprache ID (ISO 639-1).
   * @example "de"
   */
  Sprache: string
}

export interface PimArtikelKontextXML {
  /**
   * AS0364: Benötigt, falls im Endkunden-Shop ein abweichender MwSt-Satz zur Anwendung gelangt.
   * @example "0"
   */
  AbweichendeMwStEndkundeB2C?: string
  /**
   * Bontext
   * AS0146: Kurze Artikelbezeichnung, die auf dem Kassenbon ausgewiesen wird.
   * @example "FLIESE BEDFORT 236"
   */
  Bontext?: string
  /**
   * Betriebstypen-Artikelnummer
   * AS0009: Hagebau-interne Artikelnummer, die je Vertriebssystem automatisch generiert wird.
   * @format 6- oder 8-stellig
   * @minLength 0
   * @maxLength 8
   * @example "581118"
   */
  BtArtikelNr?: string
  /**
   * AS0283: Betriebstypenspezifische Warengruppe.
   * @example "654"
   */
  BtWarengruppe?: string
  /**
   * AS0377: Nummer des Controllings-Auftrags. Definition aus Ticket.
   * @format int32
   */
  COAuftrag?: number
  /**
   * Steuer-Attribute für den B2C iShop. Wird im PIM-Workflow auf Basis std_online_reservierfaehig_endkunde und std_online_bestellbar_endkunde sowie Validerungsergebnis gesetzt.
   * @example "sth"
   */
  CrossChannelVariantenOnline?: PimArtikelKontextCrossChannelVarianteOnlineXML[]
  /**
   * AS0348: kennzeichnet Artikel hinsichtlich eines speziellen Umgang mit Preisen.
   * @example "Dauerniedrigpreis-Artikel"
   */
  Focus?: string
  /**
   * Kontext ID.
   * @example "099"
   */
  Kontext: string
  /**
   * SAS0370: teuerungskennzeichen zur Prozesserung. Gibt an, ob eine Listung für einen Kontext, lieferantenübergreifend eine aktive Gültigkeit besitzt. Diese kann zeitlich bedingt sein, oder aber auch durch mangelnde Validität oder zeitliche Sperren beeinflusst werden. Attribut wird in der HC verwendet.
   * @example true
   */
  KontextAktiv?: boolean
  /**
   * Matchcode
   * AS0145: Kurzbezeichnung oder Suchbegriff, mit dem der Artikel in den Systemen und Datenbanken gefunden werden kann.
   * @example "MST R 1 25"
   */
  Matchcode?: string
  /** AS0337: ÖNORM für Baustoffprodukte Verkauf Gewerbe Österreich. */
  OENormRelevanz?: boolean
  /**
   * Online Kennzeichen
   * AS0120: Kennzeichen, inwiefern ein Artikel auf einem Online-Kanal sichtbar ist.
   */
  Online?: boolean
  /** AS0364: Gibt an, ob ein Artikel für Ship-to-Home vorgesehen ist. */
  OnlineBestellbarEndkunde?: boolean
  /** AS0363: Gibt an, ob ein Artikel für Click & Reserve vorgesehen ist. */
  OnlineReservierfaehigEndkunde?: boolean
  /** Kategoriedaten Pflanze. Deprecated: Alle Pflanzenattribute sind auf die ArtikelPflanze Ebene umgezogen. */
  Pflanze?: PimArtikelKontextPflanzeXML
  /**
   * AS0178: Feld gibt an, ob und wenn ja wie lange ein Artikel retourniert werden kann. Aktueller Standardwert beträgt 30 Tage.
   * @example "30"
   */
  RetourenfristOnlineBestellung?: string
  Sprachen?: PimArtikelKontextSpracheXML[]
  /**
   * AS0174: Gibt die Anzahl Tage an, die benötigt werden, um einen Artikel von der Versandstelle (hagebau) zum Endkunden zu transportieren
   * @format int32
   * @example 2
   */
  Transportzeit?: number
  /**
   * AS0365: Angabe des Datums, ab die VK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  VKSperreAb?: string
  /**
   * AS0366: Angabe des Datums, bis die VK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  VKSperreBis?: string
  /**
   * AS0444: Wenn ein Artikel aus Sicherheitsgründen oder anderen gesetzlichen Auflagen nicht mehr oder vorübergehend nicht mehr verkauft werden kann und darf, steuert dieses Kennzeichen den Prozess in der WaWi und vor allem an den Kassen der Märkte.
   * @example "Höhere Gewalt"
   */
  VKSperreGrund?: string
  /**
   * VersandsystemEndkunde
   * AS0061: Je nach Größe/Sperrigkeit wird dem Artikel eine Versandkostenklasse zugeordnet. In Abhängigkeit dieser Klasse ergeben sich unterschiedliche Versandtarife/Versandkosten, die dem Kunden in Rechnung gestellt werden.
   * @example "sperrig"
   */
  Versandkostenklasse?: string
}

export interface PimArtikelLandXML {
  /**
   * Ländercode (ISO 3166-1-alpha2).
   * @format ISO 3166-1-alpha2
   * @example "DE"
   */
  Land: string
  /**
   * AS0053: Gibt den in Deutschland, Österreich und/oder Luxemburg geltenden Steuersatz des Artikels an.
   * @example "1"
   */
  Steuerindikation?: string
}

export interface PimArtikelLieferantEkPreisJson {
  mengeFreiHausStaffel1?: number
  mengeFreiHausStaffel2?: number
  mengeFreiHausStaffel3?: number
  mengeFreiHausStaffel4?: number
  mengeFreiHausStaffel5?: number
  preisFreiHausStaffel1?: number
  preisFreiHausStaffel2?: number
  preisFreiHausStaffel3?: number
  preisFreiHausStaffel4?: number
  preisFreiHausStaffel5?: number
  /** @format date */
  gueltigAb?: string
  /** @format date */
  gueltigBis?: string
  /** @format int64 */
  id?: number
  index?: string
  preis?: number
  preisBasis?: number
  preisMenge?: number
  preisMengeneinheit?: PimMengeneinheitDisplayJson
  rabattgruppe?: string
  type?: PimArtikelLieferantEkType
  waehrung?: PimWaehrungDisplayJson
}

export enum PimArtikelLieferantEkType {
  EP = 'EP',
  WL = 'WL',
  UVP = 'UVP'
}

/** Lieferantenabhängige Kategoriedaten Fliese. */
export interface PimArtikelLieferantFlieseXML {
  /**
   * Preisgruppe
   * AS0033: Bezeichnung der Preisgruppe der Lieferanten laut mitgelieferter Liste. Hat unter anderem Auswirkungen auf Rabatte und Konditionen sowie vereinzelt Preiseinstufungen.
   * @example "P3"
   */
  Preisgruppe?: string
  /**
   * Segment
   * AS0236: Manche Lieferanten ordnen ihre Artikel zusätzlich zu den Preisgruppen bestimmten Segmenten zu (z. B. Wohnkeramik). Dies hat oftmals auch Auswirkungen auf die Rabatte und Konditionen.
   * @example "Architektur"
   */
  Segment?: string
  /**
   * Werkskategorie
   * AS0240: Originale Bezeichnung des Lieferanten für den Artikel. Oftmals notwendig bei Fliesen aus Italien oder Spanien, da auf den Kartons dann keine deutschen Bezeichnungen vermerkt sind und die Ware sonst schwerer im Lager zu finden ist.
   * @example "Gradone Costa Retta"
   */
  Werkskategorie?: string
}

export interface PimArtikelLieferantJson {
  ekPreise?: PimArtikelLieferantEkPreisJson[]
  /** @format int64 */
  id?: number
  index?: string
  lieferant?: LieferantDisplayJson
  seErgaenzung?: boolean
}

export interface PimArtikelLieferantKontextXML {
  /** AS0316: Liefermengeneinheit auf Artikelebene gemäß gültiger globaler Mengeneinheit. Kleinste Einheit (EANCOM Element 6411-Code). */
  LME1?: string
  /** AS0317: Liefermengeneinheit auf Verpackungsebene gemäß gültiger globaler Mengeneinheit (EANCOM Element 6411-Code). */
  LME2?: string
  /** AS0318: Liefermengeneinheit auf Ladungsträgerebene gemäß gültiger globaler Mengeneinheit (EANCOM Element 6411-Code). */
  LME3?: string
  /** AS0319: Liefermengeneinheit LME 4 gemäß gültiger globaler Mengeneinheit (EANCOM Element 6411-Code). */
  LME4?: string
  /** AS0320: Liefermengeneinheit LME 5 gemäß gültiger globaler Mengeneinheit (EANCOM Element 6411-Code). */
  LME5?: string
  /**
   * Aktiv
   * AS0256: Gibt an, ob eine Listung eine aktive Gültigkeit besitzt. Diese kann zeitlich bedingt sein, oder aber auch durch mangelnde Validität oder zeitliche Sperren beeinflusst werden.
   * @example false
   */
  Aktiv?: boolean
  /**
   * AS0376: Feld für eine Alternative Artikel-Nummer aus Drittsystemen (z.B. Socoto).
   * @example "AX123457"
   */
  AlternativeArtikelNummer?: string
  /**
   * Einkaufs-Berechnungsmengeneinheit (EBME)
   * AS0006: Kleinste Einheit, auf die sich Einkaufs- und Verkaufspreis beziehen, z.B. bezieht sich der Einkaufspreis von 5,99€ auf ein kg Kies.
   * @example "KGM"
   */
  BME?: string
  /**
   * Bereitstellungszeit in Tagen
   * AS0179: Gibt die Anzahl Tage an, die benötigt werden, um einen Artikel vom Lieferanten zur Versandstelle zu transportieren.
   * @format int32
   * @example 6
   */
  Bereitstellungszeit?: number
  /**
   * AS0330: Artikel, die Biozid-relevant sind, müssen mit einer länderspezifischen Biozid Zulassungsnummer versehen werden.
   * @example "DE-0020663-18"
   */
  BiozidZulassungsnummer?: string
  /**
   * AS0331: länderspezifisches Datum, dass beschreibt, wie lange die Biozid-Zulassungsnummer gültig ist (ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  BiozidZulassungsnummerZulassungsende?: string
  /**
   * AS0368: Angabe des Datums, ab die EK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  EKSperreAb?: string
  /**
   * AS0369: Angabe des Datums, bis die EK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  EKSperreBis?: string
  /**
   * AS0443: Wenn ein Artikel nicht mehr oder vorübergehend nicht mehr eingekauft werden kann und darf, steuert dieses Kennzeichen den Prozess in der WaWi.
   * @example "Höhere Gewalt"
   */
  EKSperreGrund?: string
  /**
   * FreigabeAenderungsdienst
   * AS0277: Das Datum, an dem die Daten aus dem ZART Listungspool in den ZART Artikelstamm überführt und damit an die Gesellschafter ERP Systeme ausgegeben werden im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  FreigabeAenderungsdienst?: string
  /**
   * AS0393: Angabe, über welchen haupt-Lieferanten der Artikel bestellt werden kann.
   * @example true
   */
  Hauptlieferant?: boolean
  /**
   * Incoterms (International Commercial Terms)
   * AS0062: Lieferbedingungen, beschreiben die Rechte und Pflichten von Käufer und Verkäufer hinsichtlich des Verbringens von Ware.
   * @example "DDP"
   */
  Incoterms?: string
  /**
   * Incoterms Abgangshafen (seaport code)
   * AS0063: Abgangshafen, von dem der Artikel versendet/ verschifft wird.
   * @example "CNSHA"
   */
  IncotermsAbgangshafen?: string
  /**
   * Incoterms Empfangshafen (seaport code)
   * AS0064: Empfangshafen, an den der Artikel versendet/ verschifft wird.
   * @example "DEHAM"
   */
  IncotermsEmpfangshafen?: string
  /**
   * Menge der besonderen Maßeinheit (Intrastat)
   * AS0066: Angabe des Zahlenwertes entsprechend der im Warenverzeichnis für die Außenhandelsstatistik vorgegebenen Besonderen Maßeinheit für die INTRASTAT-Meldung. Erwartet wird ein Zahlenwert ohne Angabe der Einheit, da sich diese aus der vorgebenenen INTRASTAT-Einheit ergibt.
   * @example 2.5
   */
  IntrastatMenge?: number
  /**
   * AS0362: Wird von HC benutzt, um im Falle von vom Lieferanten gemeldeten Verzögerungen die automatisierte Aufforderung zur Bestandsmeldung zu unterbinden.
   * @format date-time
   * @example "2023-07-21T17:32:28Z"
   */
  KeineAnmahnungBestandsmeldungBis?: string
  /**
   * Kombityp
   * AS0110: Zahlencode für spezielle lieferantenabhängige Rabatte, die bei der Abnahme größerer Mengen gewährt werden. Die Konditionen der jeweiligen Kombitypen sind im Lieferantenstamm angelegt.
   * @format int32
   * @example 5
   */
  Kombityp?: number
  /**
   * Kontext ID.
   * @example "099"
   */
  Kontext: string
  /**
   * Lieferzeit Artikel
   * AS0359: Damit die Märkte vor Ort automatische Bestellvorschläge generieren können, wird eine Lieferzeit am Artikel benötigt. Diese kann Stand heute bereits in ZART gepflegt werden. Es sagt aus, wie lange ein Lieferant benötigt, den Artikel direkt in den Markt zu liefern. Es ist damit die komplette Zeit von der Bestellung bis zur Anlieferung an den Markt oder Endkunde. Bezieht sich nicht auf Onlinekanäle und Verteilzentren oder so, sondern auf die stationären Märkte.
   * @format int32
   * @example 5
   */
  Lieferzeit?: number
  /**
   * Listungsstatus
   * AS0255: Gibt an inwiefern ein Artikel zu einem bestimmten Lieferanten in einem bestimmten Kontext imListungsprozess, gelistet oder ausgelistet ist.
   * @example "gelistet, ausgelistet"
   */
  Listungsstatus?: string
  /**
   * AS0257: Gibt an, ab wann der dazugehörige Listungsstatus gültig ist im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  ListungsstatusGueltigAb?: string
  /**
   * AS0273: Gibt an, ab wann ein Asset genutzt werden kann im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  MAMGueltigAb?: string
  /**
   * AS0274: Gibt an, bis wann eine Lizenz gültig ist im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  MAMGueltigBis?: string
  /**
   * AS0546: Attribut zur Definition der minimalen Restlaufzeit mit der ein Artikel mit MHD angeliefert werden darf. Angabe in Tagen.
   * @format int32
   * @example 180
   */
  MHDRestlaufzeit?: number
  /**
   * AS0374: Die kleinste Bestellmenge die beim Lieferanten eingekauft wird/die der Lieferant abgibt.
   * @example 100
   */
  MindestbestellmengeLieferant?: number
  /**
   * AS0328: Artikel, die gemäß Pflanzenschutzmittelgesetz mit einer länderspezifischen Registriernummer versehen werden müssen.
   * @example "024785-64"
   */
  PflanzenschutzregistrierNummer?: string
  /**
   * AS0329: länderspezifisches Datum, dass beschreibt, wie lange die Pflanzenschutzregistrier-Nummer gültig ist (ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  PflanzenschutzregistrierNummerZulassungsende?: string
  /**
   * ReinerWerbeartikel
   * AS0321: Ein Kennzeichen, das einen Artikel als reinen Werbeartikel auszeichnet, um diesen exklusiv über die Werbeplanung zu verarbeiten.
   * @example false
   */
  ReinerWerbeartikel?: boolean
  /**
   * RetourenWiederverwertungskennzeichen
   * AS0057: Gibt an, ob die Retoure an einen Aufkäufer weiterverwertet, verschrottet oder an den Lieferanten zurückgesendet werden soll.
   * @example "Aufkäufer"
   */
  RetourenWiederverwertungskennzeichen?: string
  /**
   * Retourenziel
   * AS0056: Gibt an, ob die Retoure ans HWL-Lager oder zum Lieferanten geführt werden soll.
   * @example "zum Lager"
   */
  Retourenziel?: string
  /**
   * SortimentsKennzeichenHagebauLogistik
   * AS0284: Kennzeichnen aus welchem Sortiment (Kernsortiment, Fernost...) ein Artikel zugeordnet (ehemals 'Sort. BM').
   * @example "Kernsortiment"
   */
  SortimentsKennzeichenHagebauLogistik?: string
  /**
   * SteuerungTransportDienstleister
   * AS0055: Gibt an, wer die Transportdienstleitung im Falle einer Endkundenbestellung steuert.
   * @example "hagebau connect"
   */
  SteuerungTransportDienstleister?: string
  /**
   * VersandsystemEndkunde
   * AS0054: Gibt an, über welches Versandsystem der Artikel bei einer Endkundenbestellung an den Kunden gebracht werden soll.
   * @example "KEP"
   */
  VersandsystemEndkunde?: string
  /**
   * VersandwegEndkunde
   * AS0049: Legt fest ob der Artikel für eine Endkundenbestellung über das Lager oder im Direktversand (DV/VAL) abgewickelt wird.
   * @example "VAL"
   */
  VersandwegEndkunde?: string
  /**
   * WarenruecksendungLieferantenretoure
   * AS0060: Feld gibt an, ob der Lieferant die retournierte im HWL-Lager befindliche Ware selber abholt oder hagebau connect diese beim Lieferanten zustellt.
   * @example "Zustellung"
   */
  WarenruecksendungLieferantenretoure?: string
}

export interface PimArtikelLieferantLandXML {
  /**
   * AS0327: Kosten für den Versand vom Lieferanten an den Endkunden.
   * @example 10
   */
  Frachtkosten?: number
  /**
   * AS0262: Vorgabe des Lieferanten, für welche Länder er seine Artikel anbietet. Ländercode (ISO 3166-1-alpha2).
   * @example "DE"
   */
  Land: string
}

export interface PimArtikelLieferantSpracheXML {
  /**
   * Lieferanten Artikelbezeichnung
   * AS0005: Artikelbezeichnung, die der Lieferant intern für seine Artikel vergibt.
   * @example "Lithon Hochbord Übergangsst. li+re  grau"
   */
  LieferantArtikelBez?: string
  /**
   * Sprache ID (ISO 639-1).
   * @example "de"
   */
  Sprache: string
}

/** Vorgabe des Lieferanten, für welche Vertriebswege er seine Artikel anbietet (EH, FH, Online). */
export interface PimArtikelLieferantVertriebswegXML {
  /**
   * AS0261: Vorgabe des Lieferanten, für welche Vertriebsweg er seine Artikel anbietet (EH, FH, Online).
   * @example "Online"
   */
  Vertriebsweg: string
}

export interface PimArtikelLieferantXML {
  /**
   * AS0375: Die Artikelnummer des Herstellers (kann ungleich die des Liefernaten sein).
   * @example "AX123456"
   */
  'Herstellerartikelnummer '?: string
  /**
   * AS0058: Firmenname des Lieferanten.
   * @example "Lithonplus Gebiet Ost"
   */
  Name1?: string
  /**
   * AS0059: Firmenname des Lieferanten.
   * @example "Lithonplus Gebiet Ost"
   */
  Name2?: string
  /**
   * AS0368: Angabe des Datums, ab die EK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @deprecated
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  EKSperreAb?: string
  /**
   * AS0368: Angabe des Datums, bis die EK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @deprecated
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  EKSperreBis?: string
  /**
   * Code der Einkäufergruppe
   * AS0144: Intern verwendeter Zahlencode zur Zuordnung des Artikels zum verantwortlichen Einkauf- bzw. Category-Management-Bereich.
   * @format int32
   * @example 142
   */
  Einkaeufergruppe?: number
  /**
   * AS0227: Das FSC-Siegel des Forest Stewardship Council (FSC) gewährleistet, dass das verwendete Holz aus nachhaltiger und umweltgerechter Waldbewirtschaftung stammt. Das Label findet sich auf Holz- und auf Papierprodukten.
   * @example "FSC 100%"
   */
  FSCClaim?: string
  /** AS0260: Das FSC-Siegel des Forest Stewardship Council (FSC) gewährleistet, dass das verwendete Holz aus nachhaltiger und umweltgerechter Waldbewirtschaftung stammt. Das Label findet sich auf Holz- und auf Papierprodukten. */
  FSCZertifiziert?: boolean
  /** Lieferantenabhängige Kategoriedaten Fliese. */
  Fliese?: PimArtikelLieferantFlieseXML
  /**
   * Import-Kennzeichen
   * AS0223: Kennzeichen, das einen Artikel als Import-Artikel kennzeichnet.
   */
  ImportKennzeichen?: boolean
  Kontexte?: PimArtikelLieferantKontextXML[]
  /**
   * Lieferantenartikelnummer
   * AS0004: Artikelnummer, die der Lieferant intern für seine Artikel vergibt. Führende Nullen bitte mit angeben.
   * @minLength 0
   * @maxLength 35
   * @example "087467-A01"
   */
  LAN?: string
  Laender?: PimArtikelLieferantLandXML[]
  /**
   * Lieferanten-Nr.
   * AS0002: interne Lieferantennummer der hagebau.
   * @example "900448"
   */
  LieferantNr: string
  /**
   * Lieferanten Warengruppe
   * AS0008: Der Name der Warengruppe, welcher der Lieferant führt; Identifikation innerhalb des Datenpools.
   */
  LieferantenWarengruppe?: string
  /**
   * AS0071: Angabe der Lieferzeit in Arbeitstagen (5 Tage) zum Standort hagebau Logistik.
   * @format int32
   * @example 3
   */
  LieferzeitZurHagebauLogistik?: number
  /**
   * AS0229: Die PEFC-Zertifizierung bestätigt, dass Wälder auf nachhaltige Weise und gemäß strengen Standards bewirtschaftet werden. Sie ist ein Beweis dafür, dass Holz aus ökologisch, ökonomisch und sozial nachhaltig bewirtschafteten Wäldern stammt.
   * @example "X% PEFC-certified"
   */
  PEFCClaim?: string
  /** AS0228: Die PEFC-Zertifizierung bestätigt, dass Wälder auf nachhaltige Weise und gemäß strengen Standards bewirtschaftet werden. Sie ist ein Beweis dafür, dass Holz aus ökologisch, ökonomisch und sozial nachhaltig bewirtschafteten Wäldern stammt. */
  PEFCZertifiziert?: boolean
  /**
   * AS0462: Ländercode (ISO 3166-1-alpha2). Kennzeichnet die Lokation des Sourcing Offices von dem aus gesourced wird: Vietnam, China, Indien, Polen. Hierbei geht es um die Zuordnung des Sourcingbüro´s der ARENA, die vollständig voneinander getrennt sind aber in einem ERP (verschiedene Mandanten) zusammenlaufen. Beispielhaft kann ich einen Artikel Einschlagbodenhülse (eine hage Nr., 2 GTIN´s, 2 Lieferanten) aus 2 verschiedenen Sourcingbüro´s geliefert werden.
   * @format ISO 3166-1-alpha2
   * @example "CN"
   */
  SourcingOffice?: string
  Sprachen?: PimArtikelLieferantSpracheXML[]
  /**
   * Lieferanten-Teilsortiment
   * AS0225: Feste Werte pro Lieferant, die sein Sortiment gemäß Liefernant in Gruppen strukturiert
   * @example "A"
   */
  Teilsortiment?: string
  /**
   * Lieferantenwaren-Untergruppe (CBX Product sub cat ID)
   * AS0224: Unterwarengruppe des Lieferanten (insbes. bei Import-Artikeln)
   * @example "S142"
   */
  UnterWarengruppe?: string
  /** Vorgabe des Lieferanten, für welche Vertriebswege er seine Artikel anbietet (EH, FH, Online). */
  Vertriebswege?: PimArtikelLieferantVertriebswegXML[]
}

export interface PimArtikelMengeneinheitXML {
  /** AS0314: Einheit einer Basis-ME auf die sich diese ME bezieht. */
  BasisEinheit?: string
  /**
   * Breite in mm
   * AS0308: Breite der LME.
   * @format int32
   * @example 20
   */
  Breite?: number
  /**
   * GTIN der Mengeneinheit
   * AS0312: GTIN (EAN) Mengeneinheit.
   * @example "4008838934586"
   */
  GTIN?: string
  /**
   * AS0310: Gewicht (brutto) der LME in kg.
   * @format number(5,8)
   */
  GewichtBrutto?: number
  /**
   * AS0311: Gewicht (netto) der LME in kg.
   * @format number(5,8)
   */
  GewichtNetto?: number
  /**
   * Höhe in mm
   * AS0307: Höhe der LME.
   * @format int32
   * @example 20
   */
  Hoehe?: number
  /**
   * AS0372: Damit eine Umrechnung der Einheiten ohne Rundungsfehler eine möglich ist, wird ein Kennzeichen gesetzt, dass mit der Einheit bzw dem Umrechnungsfaktor invertiert (und damit als Bruch) gerechnet wird.
   * Inverted wird nur vom PIM empfangen. Es wird nicht an andere Systeme ausgegeben über die ONE mit diesem Datenformat spricht.
   * @example true
   */
  Invertiert?: boolean
  /**
   * Laenge in mm
   * AS0309: Laenge der LME.
   * @format int32
   * @example 20
   */
  Laenge?: number
  /** Menge in BasisEinheit dieser LME. */
  MengeBasisEinheit?: SchemaMengeBasisEinheit
  /** Menge in Einzelmengeneinheit dieses Artikels. */
  MengeEinzelmengeneinheit?: SchemaMengeEinzelmengeneinheit
  /**
   * AS0306: EANCOM Element 6411-Code der Mengeneinheit
   * @example "PCE"
   */
  Mengeneinheit: string
  /**
   * AS0050: Der Stapelfaktor gibt die maximale Anzahl der Stappellagen an, z.B. wie viele Paletten (Non-GTIN) übereinander gestapelt werden können. Der Stapelfaktor 1 bedeutet, dass die Paletten o.ä. nicht übereinander gestapelt werden dürfen.
   * @format int32
   * @example 1
   */
  Stapelfaktor?: number
  /**
   * AS0332: Volumen der LME in m3.
   * @format number(5,8)
   */
  Volumen?: number
  /**
   * Zusatz EANs
   * AS0313: Zusatz-EANs, die auf dieser Einheit angebracht sind.
   * @format 8-. 11- oder 13-stellig (GTIN-8, GTIN-13), wenn 11-stellig kommt --> dann mit 2 führenden 0 aufgeüllt.
   */
  ZusatzGTIN?: PimArtikelGtinXML[]
}

export interface PimArtikelPackstueckXML {
  /**
   * Breite in mm
   * AS0099: Breite des Packstücks in mm.
   * @format int32
   * @example 20
   */
  Breite?: number
  /**
   * AS0102: Gewicht des Packstücks in kg.
   * @format number(5,8)
   */
  Gewicht?: number
  /**
   * Höhe in mm
   * AS0100: Höhe des Packstücks in mm.
   * @format int32
   * @example 20
   */
  Hoehe?: number
  /**
   * AS0101: Inhalt Packstück.
   * @example "Tisch"
   */
  Inhalt?: string
  /**
   * Laenge in mm
   * AS0098: Laenge des Packstücks in mm.
   * @format int32
   * @example 20
   */
  Laenge?: number
  /**
   * AS0078-AS0097: Nummer des Packstückst, dadurch wir eine logische Reichenfolge bestimmt, im Datenmodell Packstueck1..20
   * @format int32
   * @example 1
   */
  Nummer: number
}

/** Kategoriedaten Pflanze */
export interface PimArtikelPflanzeXML {
  /**
   * AS0383: Ergänzende Hinweise zur Ausführung einer Pflanze.
   * @example "6er Schale"
   */
  Ausfuehrung?: string
  /**
   * AS0384: Angabe zur Blattfarbe einer Pflanze.
   * @example "grün"
   */
  Blattfarbe?: string
  /**
   * AS0385: Angabe, ob eine Pflanze Blüten aufweist.
   * @example true
   */
  Bluete?: boolean
  /**
   * AS0386: Angabe zur Blütenfarbe einer Pflanze.
   * @example "weiss"
   */
  Bluetenfarbe?: string
  /**
   * AS0387: Ergänzende Angaben zu den Blütenmerkmalen einer Pflanze.
   * @example "Einfach"
   */
  Bluetenmerkmal?: string
  /**
   * AS0388: gibt die botanische Bezeichnung der Pflanzenart an (häufig lateinische oder griechische Begriffe).
   * @example "Hybriden"
   */
  BotanischeBezeichnungArt?: string
  /**
   * AS0389: gibt die botanische Bezeichnung der Pflanzengattung an (häufig lateinische oder griechische Begriffe).
   * @example "Phalaenopsis"
   */
  BotanischeBezeichnungGattung?: string
  /**
   * AS0390: gibt die botanische Bezeichnung der Pflanzensorte an.
   * @example "Grandeur"
   */
  BotanischeBezeichnungSorte?: string
  /**
   * AS0391: gibt die deutsche Bezeichnung einer  Pflanze an.
   * @example "Orchidee"
   */
  DeutscheBezeichnung?: string
  /**
   * AS0392: Angabe, ob eine Pflanze einen Duft aufweist.
   * @example true
   */
  Duft?: boolean
  /**
   * AS0381: Angabe zur maximalen Höhe einer Pflanze in cm.
   * @example 50
   */
  HoeheMax?: number
  /**
   * AS0382: Angabe zur minimalen Höhe einer Pflanze in cm.
   * @example 30
   */
  HoeheMin?: number
  /**
   * AS0394: Angabe zur Lebensdauer einer Pflanze (i.d.R. wird zwischen "einjährigen", "zweijährigen" und "mehrjährigen" Pflanzen unterschieden).
   * @example "Mehrjährig"
   */
  Lebenszyklus?: string
  /**
   * AS0177: Gibt die Anzahl der Lagen pro Container an.
   * @format int32
   * @example 4
   */
  PflanzenLageProCC?: number
  /**
   * AS0175: Gibt die Anzahl der Pflanzen pro Tray (Pflanz-Schalen) an.
   * @format int32
   * @example 10
   */
  PflanzenProTray?: number
  /**
   * AS0176: Gibt die Anzahl der Pflanz-Trays pro Lage an.
   * @format int32
   * @example 4
   */
  PflanzenTraysProLage?: number
  /**
   * AS0396: Angabe, ob Artikel im Markt hängend platziert werden kann.
   * @example true
   */
  PlatzierungHaengend?: boolean
  /**
   * AS0397: Angabe, ob Artikel im Markt stehend platziert werden kann.
   * @example true
   */
  PlatzierungStellbar?: boolean
  /**
   * AS0398: ergänzende Angaben zu den Qualitätsmerkmalen einer Pflanzen (bspw. Bezug auf die Anzahl der Triebe).
   * @example "Jungpflanze"
   */
  Qualitaet?: string
  /**
   * AS0346: Idealer Standort für das Gedeihen der Pflanze.
   * @example "halbschattig"
   */
  Standort?: string
  /**
   * AS0345: Angabe der Topfgröße der Pflanze als Text.
   * @example "15 cm"
   */
  Topfgroesse?: string
  /**
   * AS0399: gibt an, ob eine Pflanze ganzjährig im Freien bleiben kann.
   * @example true
   */
  Winterhart?: boolean
  /**
   * AS0400: ergänzende Angaben zu den Qualitätsmerkmalen einer Pflanzen (bspw. Bezug auf die Anzahl der Triebe).
   * @example "Jungpflanze"
   */
  Wuchsform?: string
}

export interface PimArtikelRegalplatzierungXML {
  /**
   * AS0075: Angabe eines Codes für die Art der Regalplatzierung.
   * @example "1"
   */
  Regalplatzierung?: string
}

export interface PimArtikelSpracheXML {
  /**
   * Listungstext 1 - Etikett
   * AS0011: Interne Artikelbezeichnung, optimiert für 2-zeilige Regaletiketten.
   * @format max. 40 Zeichen (inkl. Leerschritte), keine Sonderzeichen
   * @minLength 0
   * @maxLength 40
   * @example "Lithon Hochbord Übergangsst. li+re  grau"
   */
  Etikett1?: string
  /**
   * Listungstext 2 - Etikett
   * AS0012: Interne Artikelbezeichnung, optimiert für 2-zeilige Regaletiketten.
   * @format max. 40 Zeichen (inkl. Leerschritte), keine Sonderzeichen
   * @minLength 0
   * @maxLength 40
   * @example "15-22/30/100 cm"
   */
  Etikett2?: string
  /**
   * AS0013: Listungstext 1 - Lieferschein
   * @minLength 0
   * @maxLength 40
   * @example "Lithon Hochbord Übergangsst. li+re grau"
   */
  Lieferschein1?: string
  /**
   * AS0014: Listungstext 2 - Lieferschein
   * @minLength 0
   * @maxLength 40
   * @example "15-22/30/100 cm"
   */
  Lieferschein2?: string
  /**
   * AS0015: Listungstext 3 - Lieferschein
   * @minLength 0
   * @maxLength 40
   */
  Lieferschein3?: string
  /**
   * AS0016: Listungstext 4 - Lieferschein
   * @minLength 0
   * @maxLength 40
   * @example "25 kg"
   */
  Lieferschein4?: string
  /**
   * AS0264: Artikelbeschreibung für Plakattexte im Markt,
   * @example "Küchenherd K176 weiss/rechts"
   */
  Plakattext1?: string
  /**
   * AS0265: Artikelbeschreibung für Plakattexte im Markt,
   * @example "5kW EEL A weiss rechts"
   */
  Plakattext2?: string
  /**
   * AS0266: Artikelbeschreibung für Plakattexte im Markt,
   * @example "H 850 B 700 T 600 mm"
   */
  Plakattext3?: string
  /**
   * AS0267: Artikelbeschreibung für Plakattexte im Markt,
   * @example "max. 5,2 kW"
   */
  Plakattext4?: string
  /**
   * AS0268: Artikelbeschreibung für Plakattexte im Markt,
   * @example "Holzbriketts / Holz / Braunkohlebriketts"
   */
  Plakattext5?: string
  /**
   * AS0269: Artikelbeschreibung für Plakattexte im Markt,
   * @example "15aB-VG"
   */
  Plakattext6?: string
  /**
   * AS0270: Artikelbeschreibung für Plakattexte im Markt,
   * @example "131 kg"
   */
  Plakattext7?: string
  /**
   * AS0271: Artikelbeschreibung für Plakattexte im Markt,
   * @example "A/weiß/Stahl"
   */
  Plakattext8?: string
  /**
   * AS0181: Attribut zur Produktbildung. Dimension 1, Ausprägung eines Artikels über das es sich innerhalb eines Produkts unterscheiden kann, ist immer Farbe.
   * @example "blau"
   */
  ProduktDimension1?: string
  /**
   * AS0182: Attribut zur Produktbildung. Dimension 2, Ausprägung eines Artikels über das es sich innerhalb eines Produkts unterscheiden kann, kann mit beliebigen Artikeleigenschaften gefüllt sein.
   * @example "mit Reissverschluss"
   */
  ProduktDimension2?: string
  /**
   * AS0183: Attribut zur Produktbildung. Dimension 3, Ausprägung eines Artikels über das es sich innerhalb eines Produkts unterscheiden kann, kann mit beliebigen Artikeleigenschaften gefüllt sein.
   * @example "wasserfest"
   */
  ProduktDimension3?: string
  /**
   * AS0184: ext, der charakterstische Merkmale des Artikels präsentiert
   * @example "Kaminofen: "Zum Heizen in Wohnräumen""
   */
  Sellingpoint1?: string
  /**
   * AS0185: ext, der charakterstische Merkmale des Artikels präsentiert
   * @example "Kaminofen: "Mit Sichtscheibe aus Glaskeramik""
   */
  Sellingpoint2?: string
  /**
   * AS0186: ext, der charakterstische Merkmale des Artikels präsentiert
   * @example "Kaminofen: "In verschiedenen Farben erhältlich""
   */
  Sellingpoint3?: string
  /**
   * AS0187: ext, der charakterstische Merkmale des Artikels präsentiert
   * @example "hohe Deckkraft"
   */
  Sellingpoint4?: string
  /**
   * AS0188: ext, der charakterstische Merkmale des Artikels präsentiert
   * @example "Küchenherd K176 weiss/rechts"
   */
  Sellingpoint5?: string
  /**
   * AS0245: Angabe der Farbbezeichnung eines Artikels, sofern für das Produkt relevant.
   * @example " feuerrot"
   */
  Farbe?: string
  /**
   * AS0249: Beschreibung des Lieferumfang eines Artikels.
   * @example "1 Unterschrank, 1 Medischrank, 1 Spiegelschrank"
   */
  Lieferumfang?: string
  /**
   * AS0250: Angabe des Materials eines Artikels.
   * @example "Akazienholz"
   */
  Material?: string
  /**
   * AS0180: Attribut zur Produktbildung, beinhaltet die Produkteigenschaften, an hand derer Artikel zu einem Produkt zusammengefasst werden können.
   * @example "A"
   */
  Produktreferenz?: string
  /**
   * Sprache ID (ISO 639-1).
   * @example "de"
   */
  Sprache: string
}

export interface PimArtikelVersionComplianceJson {
  ecodesign2022?: boolean
  nationaleCO2AbgabeATRelevanz?: boolean
  abfallschluessel?: string[]
  adrKlasse?: string
  adrPunktzahl?: number
  alterspruefungRelevanz?: boolean
  befoerderungskategorie?: string
  beschraenkterAusgangsstoff?: boolean
  besondersBesorgniserregenderStoff?: boolean
  biozid?: boolean
  ceKennzeichen?: string
  ceRelevanz?: boolean
  druckgasverordnungRelervanz?: boolean
  enVKVModellbezeichnung?: string
  enVKVSkalaEnergieeffizienzklasse?: string
  enVkvRelevanz?: boolean
  energieeffizienzklasse?: string
  euNormRelevanz?: boolean
  frostgefaehrdet?: boolean
  gefahrenhinweis?: string[]
  gefahrenkennzeichnungsetikett?: boolean
  gefahrenpiktogramm?: string[]
  gefahrgut?: boolean
  gefahrgutADRNummer?: string
  gefahrgutMenge?: number
  gefahrgutMengeVolumen?: number
  gefahrgutSondervorschrift?: string
  gefahrgutUNNummer?: string
  gefahrgutVerpackungsgruppe?: string
  gefahrgutfaktor?: number
  gefahrnummer?: string
  gefahrstoff?: boolean
  gefahrstoffeinstufung?: string
  gefahrzettel?: string
  gefahrzettelNebengefahren?: string[]
  /** @format int64 */
  id?: number
  inverkehrbringerHausnummerPostfachnummer?: string
  inverkehrbringerLand?: string
  inverkehrbringerName?: string
  inverkehrbringerPLZ?: string
  inverkehrbringerStrassePostfach?: string
  klassifizierungscodeADR?: string[]
  konsistenz?: string[]
  laender?: PimArtikelVersionComplianceLandJson[]
  lagerklasse?: string
  limitedQuantity?: boolean
  lithiumhaltigeBatterie?: boolean
  meldepflichtigerAusgangstoff?: boolean
  meldepflichtigerAusgangstoffMaximaleAbgabemenge?: number
  meldepflichtigerAusgangstoffMengeneinheit?: string
  mhdRelevant?: boolean
  nettoexplosivstoffmasse?: number
  netzanschlussTyp?: string[]
  /** @format int32 */
  netzspannung?: number
  /** @format int32 */
  nummer?: number
  pflanzenschutzmittelRelevanz?: boolean
  prodSGHerstelleradresse?: string
  prodSGProduktkennzeichnung?: string[]
  prodSGRelevanz?: boolean
  prodSGWarnhinweis?: string[]
  produktidentifikatoren?: string
  relevanterRohstoffNachEUDR?: string
  scipNummer?: string
  sicherheitshinweis?: string[]
  signalwort?: string
  textilKvoRelevanz?: boolean
  textilkomponenteAnteil?: number
  textilkomponenteBezeichnung?: string
  tunnelbeschraenkungscode?: string
  umweltgefaehrdungsklasse?: string
  verpackungMehrwegkennzeichnung?: boolean
  verpackungsart?: string[]
  wassergefaehrdungsklasse?: string
  weeeKategorie?: string[]
  weeeRelevanz?: boolean
  zulaessigeGesamtmengeJeBefoerderungseinheit?: string
  zusaetzlicheAngabenBefoerderungspapiere?: string
}

export interface PimArtikelVersionComplianceLandJson {
  getraenkeEinwegPfand?: number
  getraenkeMehrwegPfand?: number
  /** @format int64 */
  id?: number
  pimLand?: PimLandDisplayJson
  relevantKaminoefenOeNorm?: boolean
}

export interface PimArtikelVersionDisplayJson {
  artikel?: ArtikelDisplayTinyJson
  ek?: number
  hageNummer?: string
  /** @format int64 */
  id?: number
  /** @format int32 */
  menge?: number
}

export interface PimArtikelVersionFlieseJson {
  angebotstext1?: string
  angebotstext2?: string
  gewichtProM2?: number
  inhaltProKartonM2?: number
  inhaltProPaletteM2?: number
  /** @format int32 */
  abriebgruppe?: number
  design?: string
  einsatzbereich?: string
  farbe?: string
  farbfamilie?: string
  formatgruppe?: string
  frostsicher?: boolean
  glasierung?: boolean
  /** @format int64 */
  id?: number
  /** @format int32 */
  kantenausbildung?: number
  kategorie?: string
  material?: string
  nassbereich?: string
  /** @format int32 */
  nennBreite?: number
  /** @format int32 */
  nennLaenge?: number
  /** @format int32 */
  nummer?: number
  oberflaeche?: string
  oberflaechenveredelung?: string
  /** @format int32 */
  produktBreite?: number
  /** @format int32 */
  produktHoehe?: number
  /** @format int32 */
  produktLaenge?: number
  rutschfestigkeit?: string
  serienname?: string
  verdraengungsraum?: string
}

export interface PimArtikelVersionInfoJson {
  /** @format int64 */
  id?: number
  /** @format int32 */
  nr?: number
  /** @format date-time */
  zeitpunkt?: string
}

export interface PimArtikelVersionJson {
  /** @format int32 */
  anzahlPackstuecke?: number
  arenaKennzeichen?: boolean
  artikelArt?: PimArtikelArt
  artikelBreiteNetto?: number
  artikelGewichtBrutto?: number
  artikelHoeheNetto?: number
  artikelLaengeBrutto?: number
  artikelTiefeNetto?: number
  basisWarengruppe?: string
  bausteinKennzeichen?: boolean
  bdbWarengruppe?: string
  chargenpflicht?: boolean
  compliance?: PimArtikelVersionComplianceJson
  displayArtikel?: PimArtikelVersionDisplayJson[]
  eigenmarke?: boolean
  einzelMengeneinheit?: PimMengeneinheitDisplayJson
  farbgruppe?: string
  fliese?: PimArtikelVersionFlieseJson
  gfcArtikel?: boolean
  grundpreisauszeichnungspflicht?: boolean
  gtin?: string
  hageNummer?: string
  hagebauLogistikEinkaufMengeneinheit?: string
  /** @format int32 */
  hagebauLogistikFeinkommissionierung?: number
  /** @format int32 */
  hagebauLogistikLagerbereich?: number
  hagebauLogistikLagerkostenMengeneinheit?: string
  hagebauLogistikProduktmanager?: string
  /** @format int32 */
  hagebauLogistikRegalfaehigkeit?: number
  /** @format int32 */
  hagebauLogistikStapelhoehe?: number
  hagebauLogistikWarenausgangHandlingskategorie?: string
  hagebauLogistikWarenausgangMengeneinheit?: string
  hagebauLogistikWareneingangHandlingskategorie?: string
  /** @format int64 */
  id?: number
  /**
   * @minLength 0
   * @maxLength 3
   */
  intrastatMengeneinheit?: string
  intrastatRegion?: string
  laender?: PimArtikelVersionLandJson[]
  lieferanten?: PimArtikelVersionLieferantJson[]
  materialgruppe?: string
  mengeneinheiten?: PimArtikelVersionMengeneinheitJson[]
  nachfolger?: string
  ohneVerkaufspreis?: boolean
  packstuecke?: PimArtikelVersionPackstueckJson[]
  pflanze?: PimArtikelVersionPflanzeJson
  pimProdukt?: PimProduktJson
  /** @format int32 */
  plakatlayout?: number
  preisbindung?: boolean
  produkttyp?: string
  rabattfaehig?: boolean
  regalplatzierungen?: string[]
  rundungschemaRelevanz?: boolean
  skontofaehig?: boolean
  sprache?: PimArtikelVersionSpracheJson
  ursprungsland?: string
  verkaufsverpackungMengeneinheit?: PimMengeneinheitDisplayJson
  vkSperre?: boolean
  vorlaeufer?: string
}

export interface PimArtikelVersionKontextJson {
  abweichendeMwStEndkundeB2C?: string
  artikelbeschreibung?: string
  bontext?: string
  btArtikelNr?: string
  btWarengruppe?: string
  /** @format int32 */
  coAuftrag?: number
  crossChannelVariantenOnlineList?: string[]
  focus?: string
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  kontextAktiv?: boolean
  matchcode?: string
  oeNormRelevanz?: boolean
  online?: boolean
  onlineBestellbarEndkunde?: boolean
  onlineReservierfaehigEndkunde?: boolean
  pflanze?: PimArtikelVersionKontextPflanzeJson
  retourenfristOnlineBestellung?: string
  /** @format int32 */
  transportzeit?: number
  versandkostenklasse?: string
  vkSperreAb?: string
  vkSperreBis?: string
  vkSperreGrund?: string
}

export interface PimArtikelVersionKontextPflanzeJson {
  ausfuehrung?: string
  blattfarbe?: string
  bluete?: boolean
  bluetenfarbe?: string
  bluetenmerkmal?: string
  botanischeBezeichnungArt?: string
  botanischeBezeichnungGattung?: string
  botanischeBezeichnungSorte?: string
  deutscheBezeichnung?: string
  duft?: boolean
  hoeheMax?: number
  hoeheMin?: number
  /** @format int64 */
  id?: number
  lebenszyklus?: string
  /** @format int32 */
  nummer?: number
  platzierungHaengend?: boolean
  platzierungStellbar?: boolean
  qualitaet?: string
  winterhart?: boolean
  wuchsform?: string
}

export interface PimArtikelVersionLandJson {
  /** @format int64 */
  id?: number
  land?: PimLandDisplayJson
  steuerindikation?: string
}

export interface PimArtikelVersionLieferantFlieseJson {
  /** @format int64 */
  id?: number
  /** @format int32 */
  nummer?: number
  preisgruppe?: string
  segment?: string
  werkskategorie?: string
}

export interface PimArtikelVersionLieferantJson {
  name1?: string
  name2?: string
  artikelBez?: string
  /** @format int32 */
  einkaeufergruppe?: number
  fliese?: PimArtikelVersionLieferantFlieseJson
  fscClaim?: string
  fscZertifiziert?: boolean
  herstellerartikelnummer?: string
  ian?: string
  /** @format int64 */
  id?: number
  importKennzeichen?: boolean
  kontext?: PimArtikelVersionLieferantKontextJson
  laender?: PimArtikelVersionLieferantLandJson[]
  lieferant?: LieferantDisplayJson
  /** @format int32 */
  lieferzeitZurHagebauLogistik?: number
  pefcClaim?: string
  pefcZertifiziert?: boolean
  seErgaenzung?: boolean
  sourcingOffice?: string
  teilsortiment?: string
  unterWarengruppe?: string
  vertriebswege?: string[]
  warengruppe?: string
}

export interface PimArtikelVersionLieferantKontextJson {
  lme1?: PimMengeneinheitDisplayJson
  lme2?: PimMengeneinheitDisplayJson
  lme3?: PimMengeneinheitDisplayJson
  lme4?: PimMengeneinheitDisplayJson
  lme5?: PimMengeneinheitDisplayJson
  aktiv?: boolean
  alternativeArtikelNummer?: string
  ausgelistet?: boolean
  /** @format int32 */
  bereitstellungszeit?: number
  biozidZulassungsnummer?: string
  biozidZulassungsnummerZulassungsende?: string
  bme?: PimMengeneinheitDisplayJson
  ekSperreAb?: string
  ekSperreBis?: string
  ekSperreGrund?: string
  /** @format date-time */
  freigabeAenderungsdienst?: string
  hauptlieferant?: boolean
  /** @format int64 */
  id?: number
  incoterms?: string
  incotermsAbgangshafen?: string
  incotermsEmpfangshafen?: string
  intrastatMenge?: number
  /** @format date-time */
  keineAnmahnungBestandsmeldungBis?: string
  /** @format int32 */
  kombityp?: number
  kontext?: PimKontextDisplayJson
  /** @format int32 */
  lieferzeit?: number
  listungsstatus?: string
  /** @format date-time */
  listungsstatusGueltigAb?: string
  /** @format date-time */
  mamGueltigAb?: string
  /** @format date-time */
  mamGueltigBis?: string
  /** @format int32 */
  mhdRestlaufzeit?: number
  mindestbestellmengeLieferant?: number
  pflanzenschutzregistrierNummer?: string
  pflanzenschutzregistrierNummerZulassungsende?: string
  reinerWerbeartikel?: boolean
  retourenWiederverwertungskennzeichen?: string
  retourenziel?: string
  sortimentsKennzeichenHagebauLogistik?: string
  steuerungTransportDienstleister?: string
  versandsystemEndkunde?: string
  versandwegEndkunde?: string
  warenruecksendungLieferantenretoure?: string
}

export interface PimArtikelVersionLieferantLandJson {
  frachtkosten?: number
  /** @format int64 */
  id?: number
  pimLand?: PimLandDisplayJson
}

export interface PimArtikelVersionMengeneinheitGtinJson {
  geloescht?: boolean
  gtin?: string
  /** @format int64 */
  id?: number
}

export interface PimArtikelVersionMengeneinheitJson {
  /** @format int32 */
  breite?: number
  eans?: PimArtikelVersionMengeneinheitGtinJson[]
  gewichtBrutto?: number
  gewichtNetto?: number
  gtin?: string
  /** @format int32 */
  hoehe?: number
  /** @format int64 */
  id?: number
  invertiert?: boolean
  /** @format int32 */
  laenge?: number
  mengeBasismengeneinheit?: BruchJson
  pimBasisMengeneinheit?: PimMengeneinheitDisplayJson
  pimMengeneinheit?: PimMengeneinheitDisplayJson
  /** @format int32 */
  stapelfaktor?: number
  volumen?: number
}

export interface PimArtikelVersionPackstueckJson {
  /** @format int32 */
  breite?: number
  empty?: boolean
  gewicht?: number
  /** @format int32 */
  hoehe?: number
  /** @format int64 */
  id?: number
  inhalt?: string
  /** @format int32 */
  laenge?: number
  /** @format int32 */
  nummer?: number
}

export interface PimArtikelVersionPflanzeJson {
  ausfuehrung?: string
  blattfarbe?: string
  bluete?: boolean
  bluetenfarbe?: string
  bluetenmerkmal?: string
  botanischeBezeichnungArt?: string
  botanischeBezeichnungGattung?: string
  botanischeBezeichnungSorte?: string
  deutscheBezeichnung?: string
  duft?: boolean
  hoeheMax?: number
  hoeheMin?: number
  /** @format int64 */
  id?: number
  lebenszyklus?: string
  /** @format int32 */
  nummer?: number
  /** @format int32 */
  pflanzenLagenProCc?: number
  /** @format int32 */
  pflanzenProTray?: number
  /** @format int32 */
  pflanzenTraysProLage?: number
  platzierungHaengend?: boolean
  platzierungStellbar?: boolean
  qualitaet?: string
  standort?: string
  topfgroesse?: string
  winterhart?: boolean
  wuchsform?: string
}

export interface PimArtikelVersionSpracheJson {
  etikett1?: string
  etikett2?: string
  lieferschein1?: string
  lieferschein2?: string
  lieferschein3?: string
  lieferschein4?: string
  plakattext1?: string
  plakattext2?: string
  plakattext3?: string
  plakattext4?: string
  plakattext5?: string
  plakattext6?: string
  plakattext7?: string
  plakattext8?: string
  produktDimension1?: string
  produktDimension2?: string
  produktDimension3?: string
  sellingpoint1?: string
  sellingpoint2?: string
  sellingpoint3?: string
  sellingpoint4?: string
  sellingpoint5?: string
  farbe?: string
  /** @format int64 */
  id?: number
  lieferumfang?: string
  material?: string
  produktreferenz?: string
  sprache?: PimSpracheDisplayJson
}

export interface PimArtikelVkPreisJson {
  mengeStaffel1?: number
  mengeStaffel2?: number
  mengeStaffel3?: number
  mengeStaffel4?: number
  mengeStaffel5?: number
  pg1?: number
  pg2?: number
  pg3?: number
  pg4?: number
  pg5?: number
  pg6?: number
  preisPg1Staffel1?: number
  preisPg1Staffel2?: number
  preisPg1Staffel3?: number
  preisPg1Staffel4?: number
  preisPg1Staffel5?: number
  preisPg2Staffel1?: number
  preisPg2Staffel2?: number
  preisPg2Staffel3?: number
  preisPg2Staffel4?: number
  preisPg2Staffel5?: number
  preisPg3Staffel1?: number
  preisPg3Staffel2?: number
  preisPg3Staffel3?: number
  preisPg3Staffel4?: number
  preisPg3Staffel5?: number
  preisPg4Staffel1?: number
  preisPg4Staffel2?: number
  preisPg4Staffel3?: number
  preisPg4Staffel4?: number
  preisPg4Staffel5?: number
  preisPg5Staffel1?: number
  preisPg5Staffel2?: number
  preisPg5Staffel3?: number
  preisPg5Staffel4?: number
  preisPg5Staffel5?: number
  bruttoNetto?: string
  festpreis?: boolean
  grundpreisFaktor?: number
  grundpreisMasseinheit?: string
  /** @format date */
  gueltigAb?: string
  /** @format date */
  gueltigBis?: string
  handelTyp?: HandelTyp
  /** @format int64 */
  id?: number
  index?: string
  kontext?: NeonKontextDisplayJson
  /** @format int32 */
  lfnr?: number
  nettoVk?: number
  preisBasis?: number
  preisMenge?: number
  preisMengeneinheit?: PimMengeneinheitDisplayJson
  waehrung?: PimWaehrungDisplayJson
}

export interface PimArtikelXML {
  /**
   * Eine spezielle Such nummer.
   * @minLength 1
   * @maxLength 10
   * @example "996437230"
   */
  AliasNr: string
  /**
   * Anzahl der zugehörigen Packstücke.
   * @format int32
   * @example 2
   */
  AnzahlPackstuecke?: number
  /**
   * AS0291: Angabe, ob ein Artikel ein Arena Artikel ist. Damit werden Artikel gekennzeichnet, die in Internationalen Verhandlungen von ARENA verhandelt werden.
   * @example true
   */
  ArenaKennzeichen?: boolean
  /** AS0143: hagebau-intere Klassifikation des Artikels. */
  ArtikelArt?: string
  /**
   * AS0243: Artikel-Breite (netto) eines Artikels inkl. Maßeinheit. Ohne Verpackung.
   * @example 200
   */
  ArtikelBreiteNetto?: number
  /**
   * AS0246: Artikel-Gewicht (brutto) eines Artikels (in KG).
   * @example 20
   */
  ArtikelGewichtBrutto?: number
  /**
   * AS0247: Artikel-Höhe (netto) eines Artikels inkl. Maßeinheit. Ohne Verpackung.
   * @example 300
   */
  ArtikelHoeheNetto?: number
  /**
   * AS0248: Artikel-Länge (brutto) eines Artikels (in cm).
   * @example 500
   */
  ArtikelLaengeBrutto?: number
  /**
   * AS0252: Artikel-Tiefe (netto) eines Artikels inkl. Maßeinheit. Ohne Verpackung.
   * @example 20
   */
  ArtikelTiefeNetto?: number
  /**
   * AS0524: Basis-Warengruppe (Artikel)
   * Temporäres Attribut: hagebau-interne Warengruppenummer (ZART-WG). Nimmt die Basis-/ZART-WG auf den Artikel runter um beim Import nach ZART Änderungen duch abweichende WG auf Prodktebene zu vermeiden.
   * @example "1394"
   */
  BasisWarengruppe?: string
  /**
   * AS0241: Kennzeichen, ob ein Artikel in einem Baustein verplant ist oder nicht.
   * @example true
   */
  BausteinKennzeichen?: boolean
  /**
   * AS0525: Warengruppen-ID der BDB.
   * @example "123456"
   */
  BdbWarengruppe?: string
  /**
   * Chargenpflicht
   * AS0233: Gibt an, ob beim Wareneingang eine Sortierung nach Charge/Brand erfolgen muss. Bei Fliesen muss oft innerhalb eines Artikels nach Brandfarben unterschieden werden. Angabe als Wahrheitswert.
   */
  Chargenpflicht?: boolean
  /** Kategoriedaten Compliance */
  Compliance?: PimArtikelComplianceXML
  DisplayArtikel?: PimArtikelDisplayXML[]
  /**
   * Eigenmarke
   * AS0215: Kennzeichen, dass einen Artikel als Eigenmarken-Artikel identifiziert.
   */
  Eigenmarke?: boolean
  /**
   * Einzel-Mengeneinheit, entspricht Mengeneinheit für Warenausgang (EANCOM Element 6411-Code)
   * AS0360: Angabe der kleinsten Verpackungsmengeneinheit, in der der Artikel abgegeben wird als (EANCOM Element 6411-Code)
   * @example "PCE"
   */
  EinzelMengeneinheit?: string
  /**
   * AS0244: Angabe der Farbgruppe eines Artikels. Einordnung der Farbbezeichnung des Lieferanten in eine übergeordnete 'grobe' Farbkennzeichnung.
   * @example "Rosa"
   */
  Farbgruppe?: string
  /** Kategoriedaten Fliese */
  Fliese?: PimArtikelFlieseXML
  /** AS0350: kennzeichnet Gartenfachmarktcenter Artikel. */
  GFCArtikel?: boolean
  /**
   * AS0003: Global Trade Item Number (früher auch EAN).
   * @format 8-, 11- oder 13-stellige Zahl (GTIN-8, GTIN-13). Bitte mit führenden 0 angeben. Auffüllen auf 8 und >8 auffüllen auf 13.
   * @minLength 0
   * @maxLength 35
   * @example "4027808029305"
   */
  GTIN?: string
  /**  Der Faktor von Grundpreis Mengeneinheit zur Einzel Mengeneinheit.  */
  GrundpreisFaktor?: BruchStruct
  /**
   * GrundpreisMengeneinheit
   *  Die Mengeneinheit in der der Grundpreis ausgewiesen wird.
   */
  GrundpreisMengeneinheit?: string
  /**
   * Grundpreisauszeichnungspflicht
   * AS0138: Verpflichtung zur Grundpreisangabe. Auszeichnen muss derjenige, der Waren an den Letztverbraucher gewerbs- oder geschäftsmäßig oder regelmäßig in sonstiger Weise abgibt. Betroffen sind also nicht nur die typischen Einzelhändler, sondern alle Unternehmer, die an Verbraucher verkaufen (z. B. Hersteller). Unter dem Grundpreis ist der Preis zu verstehen, der sich auf eine bestimmte Mengeneinheit bezieht, und zwar einschließlich der Umsatzsteuer und sonstiger Preisbestandteile.
   */
  Grundpreisauszeichnungspflicht?: boolean
  /**
   * AS0139: Die Mengeneinheit ist jeweils 1 Kilogramm, 1 Liter, 1 Kubikmeter, 1 Meter oder 1 Quadratmeter. Beträgt das Nenngewicht der Waren oder das Nennvolumen der Waren üblicherweise nicht mehr als 250 Gramm oder 250 Milliliter, so kann als Mengeneinheit 100 Gramm, bzw. 100 Milliliter, verwendet werden. Wird die lose Ware nach Gewicht oder nach Volumen angeboten, so ist als Mengeneinheit die allgemeine Verkehrsauffassung maßgebend, in der Regel also 1 Kilogramm, 100 Gramm, 1 Liter oder 100 Milliliter.
   * @format int32
   * @example 1
   */
  Grundpreismenge?: number
  /**
   * AS0010: Systemübergreifende Hagebau Artikelnummer, welche vom ersten erfassenden System vergeben wird.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * AS0293: Systemübergreifende Hagebau Produktnummer.
   * @minLength 1
   * @maxLength 10
   * @example "P123456"
   */
  HageProduktNr: string
  /**
   * AS0323: Angabe der kleinsten Verpackungsmengeneinheit, in der der Artikel eingekauft wird (St, Kt, Pal usw.) (EANCOM Element 6411-Code).
   * @example "PCE"
   */
  HagebauLogistikEinkaufMengeneinheit?: string
  /**
   * AS0073: Angabe von Kategorien zur Feinkommissionierung, bezogen auf die in der Lagerverwaltung genutzte Artikel- bzw. Verpackungseinheit.
   * @format int32
   * @example 1
   */
  HagebauLogistikFeinkommissionierung?: number
  /**
   * AS0289: Angabe des Lagerbereichs des Artikels, bezogen auf die in der Lagerverwaltung genutzte Artikel- bzw. Verpackungseinheit.
   * @format int32
   * @example 1
   */
  HagebauLogistikLagerbereich?: number
  /**
   * AS0210: Angabe der kleinsten Mengeneinheit, in der der Artikel gelagert wird (EANCOM Element 6411-Code).
   * @example "PCE"
   */
  HagebauLogistikLagerkostenMengeneinheit?: string
  /**
   * AS0290: Bezeichnet eine Verantwortlichkeit in der hagebau Logistik, die für das Handling im Lager verantwortlich ist.
   * @example "DIY Wohnen"
   */
  HagebauLogistikProduktmanager?: string
  /**
   * AS0220: Angabe der Lagerfähigkeit des Artikels. Die Angabe bezieht sich auf die in der Lagerverwaltung genutzte Artikel- bzw. Verpackungseinheit.
   * @format int32
   * @example 1
   */
  HagebauLogistikRegalfaehigkeit?: number
  /**
   * AS0221: Angabe der Blockfähigkeit des Artikels, bezogen auf die in der Lagerverwaltung genutzte Artikel- bzw. Verpackungseinheit.
   * @format int32
   * @example 1
   */
  HagebauLogistikStapelhoehe?: number
  /**
   * AS0070: Standardeinheit von der hagebau Logistik, in der der Artikel an den Markt versendet wird. Angabe der Handlingskategorie im Warenausgang, bezogen auf die in der Lagerverwaltung genutzte Artikel- bzw. Verpackungseinheit.
   * @example "7"
   */
  HagebauLogistikWarenausgangHandlingskategorie?: string
  /**
   * AS0072: Angabe der kleinsten Verpackungsmengeneinheit, in der der Artikel abgegeben wird (EANCOM Element 6411-Code).
   * @example "PCE"
   */
  HagebauLogistikWarenausgangMengeneinheit?: string
  /**
   * AS0069: Standardeinheit vom Lieferanten in der der Artikel im Wareneingang angeliefert wird. Angabe der Handlingskategorie im Wareneingang, bezogen auf die in der Lagerverwaltung genutzte Artikel- bzw. Verpackungseinheit.
   * @example "1"
   */
  HagebauLogistikWareneingangHandlingskategorie?: string
  /**
   * AS0258: Besondere Maßeinheiten für die Aufbereitung der Außenhandelsstatistik (EANCOM Element 6411-Code).
   * @example "KG"
   */
  IntrastatMengeneinheit?: string
  Kontexte?: PimArtikelKontextXML[]
  Laender?: PimArtikelLandXML[]
  Lieferanten?: PimArtikelLieferantXML[]
  /**
   * AS0251: Materialgruppe eines Artikels.
   * @example "Baumwolle"
   */
  Materialgruppe?: string
  Mengeneinheiten?: PimArtikelMengeneinheitXML[]
  /**
   * AS0226: GTIN des Artikels, der einen Vorläufer ablöst.
   * @example "4008838934586"
   */
  Nachfolger?: string
  /**
   * AS0469: Attribut zur Kennzeichnung damit ein Null-Preis Artikel (Waren und Dienstleistungen) nicht beim SAP-Import auf Fehler läuft: SAP benötigt das Attribut zur Unterscheidung. Primär relevant für MAPO/Socoto.
   * @example true
   */
  OhneVerkaufspreis?: boolean
  Packstuecke?: PimArtikelPackstueckXML[]
  /** Kategoriedaten Pflanze */
  Pflanze?: PimArtikelPflanzeXML
  /**
   * AS0351: Nummer des Layouts frü das Verkaufsplakat. Definiert die Überschriften und deren Reigenfolge
   * @format int32
   * @example 242
   */
  Plakatlayout?: number
  /**
   * AS0105: Angabe, ob der Artikel der gesetzlichen Preisbindungsverordnung in Deutschland unterliegt (z.B. Zigaretten, Bücher)
   * @example true
   */
  Preisbindung?: boolean
  /**
   * AS0253: Kategorisiert den Artikel in eine bestimmte Warengruppe / Produktgruppe z.B. 'Markise'.
   * @example "Markise"
   */
  Produkttyp?: string
  /** AS0195: Gibt an, ob dem Endkunden ein Rabatt auf dem VK gewährleistet werden kann. */
  Rabattfaehig?: boolean
  Regalplatzierung?: PimArtikelRegalplatzierungXML[]
  /**
   * Region (Intrastat)
   * AS0068: Ursprungsregion für die Intrastat-Meldung, d.h. die Region, in der die Ware hergestellt, montiert, zusammen-gesetzt oder bearbeitet wurden. Zu Füllen mit Bundesland, wenn Ursprungsland = Deutschland, ansonsten leer
   * @example "DE"
   */
  Region?: string
  /**
   * AS0325: Angabe, inwiefern die Verkaufspreisgruppen (EH, PFM) gemäß Rundungsschema kalkuliert werden sollen.
   * @example true
   */
  RundungschemaRelevanz?: boolean
  /**
   * Skonto
   * AS0202: Angabe, ob die hagebau Skonto auf Rechnungen gewährt.
   */
  Skontofaehig?: boolean
  /**
   * Sparte, wird nur an das ERP ausgegeben und entspricht in EOS dem Geschäftsbereich.
   * @example "18"
   */
  Sparte?: string
  Sprachen?: PimArtikelSpracheXML[]
  /**
   * AS0067: Ursprungsland (Intrastat)
   * AS0067: Code des Ursprungslandes (ISO 3166-1-alpha2), in dem der Artikel produziert oder gefertigt wird. Achtung: Der Begriff "Ursprungsland" ist zollrechtlich geregelt und ist auch so zu verwenden.
   * @example "DE"
   */
  Ursprungsland?: string
  /**
   * AS0358: Wenn ein Artikel aus Sicherheitsgründen oder anderen gesetzlichen Auflagen nicht mehr oder vorübergehend nicht mehr verkauft werden kann und darf, steuert dieses Kennzeichen den Prozess in der WaWi und vor allem an den Kassen der Märkte
   * Gründe:
   * Produktrückruf
   * Produktsicherheit
   * Zulassungsende
   * Gesetzliche Vorgaben
   * Verkaufssperre sonstiges.
   */
  VKSperre?: boolean
  /**
   * AS0365: Angabe des Datums, ab die VK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @deprecated
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  VKSperreAb?: string
  /**
   * AS0366: Angabe des Datums, bis die VK-Sperre Gültigkeit ist (ISO 8601 (RFC 3339, section 5.6).
   * @deprecated
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  VKSperreBis?: string
  /**
   * EANCOM Element 6411-Code der Verkaufsverpackung
   * AS0371: Mengeneinheit der Verkaufsverpackung ist. Die Masse der definierten Verkaufsverpackung gehen in die Bausteinplanung ein. Referenziert auf die hinterlegten Mengeneinheiten am Artikel
   * @example "PCE"
   */
  VerkaufsverpackungMengeneinheit: string
  /**
   * AS0119: GTIN des Artikels, der durch diesen Artikel permanent ersetzt wird. Optionale Angabe.
   * @example "4008838934586"
   */
  Vorlaeufer?: string
  /**
   * ERP-Spezifische Warengruppe, wird nur an das ERP ausgegeben.
   * @example "223344"
   */
  Warengruppe?: string
}

export interface PimDataExportResultJson {
  /** @format int32 */
  artikelCount?: number
  error?: string[]
  /** @format int32 */
  errorCount?: number
  /** @format int32 */
  produktCount?: number
  state?: UseCaseStateJson
  /** @format int32 */
  uvpCount?: number
  /** @format int32 */
  werkslistenpreiseCount?: number
  xml?: PimExportDataXML
}

export interface PimDataValidateResultJson {
  artikelErrors?: ValidatePimArtikelResultItemJson[]
  /** @format int32 */
  errorArtikelCount?: number
  state?: UseCaseStateJson
}

export interface PimEkPreisXML {
  /**
   * Menge in der Staffel 1.
   * @example 10
   */
  MengeFreiHausStaffel1?: number
  /**
   * Menge in der Staffel 2.
   * @example 20
   */
  MengeFreiHausStaffel2?: number
  /**
   * Menge in der Staffel 3.
   * @example 30
   */
  MengeFreiHausStaffel3?: number
  /**
   * Menge in der Staffel 4.
   * @example 40
   */
  MengeFreiHausStaffel4?: number
  /**
   * Menge in der Staffel 5.
   * @example 50
   */
  MengeFreiHausStaffel5?: number
  /**
   * EK Preis für die Staffel 1.
   * @example 100
   */
  PreisFreiHausStaffel1?: number
  /**
   * EK Preis für die Staffel 2.
   * @example 90
   */
  PreisFreiHausStaffel2?: number
  /**
   * EK Preis für die Staffel 3.
   * @example 80
   */
  PreisFreiHausStaffel3?: number
  /**
   * EK Preis für die Staffel 4.
   * @example 70
   */
  PreisFreiHausStaffel4?: number
  /**
   * EK Preis für die Staffel 5.
   * @example 60
   */
  PreisFreiHausStaffel5?: number
  /**
   * AS0305: Gültig ab Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigAb: string
  /**
   * AS0304: Gültig bis Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigBis?: string
  /**
   * Systemübergreifende Hagebau Artikelnummer, welche vom ersten erfassenden System vergeben wird.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * Kontext ID.
   * @example "099"
   */
  Kontext: string
  /**
   * Lieferanten-Nr.
   * interne Lieferantennummer der hagebau.
   * @example "900448"
   */
  LieferantNr: string
  /**
   * AS0303: Angabe, auf welche Anzahl der Artikeleinheiten sich der angegebene Preis bezieht. Üblicherweise ist der Wert '1'. Es sind auch abweichende Ausprägungen wie 10, 100, 1000 möglich.
   * @example 1
   */
  PreisBasis: number
  /**
   * PreisFreiHaus.
   * @example 150
   */
  PreisFreiHaus?: number
  /**
   * AS0302: Menge, auf die sich der Preis bezieht.
   * @example 1
   */
  PreisMenge?: number
  /**
   * AS0301: EANCOM Element 6411-Code der Preis Mengeneinheit
   * @example "PCE"
   */
  PreisMengeneinheit: string
  /**
   * Rabattgruppe.
   * AS0430
   * @example "20 %"
   */
  Rabattgruppe?: string
  /**
   * ISO-Code Währung (ISO 4217)
   * @example "EUR"
   */
  Waehrung: string
}

export interface PimExportDataXML {
  Artikel?: PimArtikelXML[]
  EkPreise?: PimEkPreisXML[]
  Produkt?: PimProduktXML[]
  /** Unverbindliche Preisempfehlung des Herstellers. */
  UnverbindlichePreisempfehlung?: SchemaUnverbindlichePreisempfehlung
  VkPreise?: PimVkPreisXML[]
  /** Werkslistenpreis des Herstellers seines Artikels. */
  Werkslistenpreise?: SchemaWerkslistenpreis
}

export interface PimImportDataXML {
  Artikel?: PimArtikelXML[]
  EkPreise?: PimEkPreisXML[]
  Produkt?: PimProduktXML[]
  /** Unverbindliche Preisempfehlung des Herstellers. */
  UnverbindlichePreisempfehlung?: SchemaUnverbindlichePreisempfehlung
  VkPreise?: PimVkPreisXML[]
  /** Werkslistenpreis des Herstellers seines Artikels. */
  Werkslistenpreise?: SchemaWerkslistenpreis
  Wertemenge?: PimWertemengeXML[]
}

export interface PimKontextDisplayJson {
  beschreibung?: string
  bez?: string
  /** @format int64 */
  id?: number
  image?: string
  kurzBez?: string
  name?: string
}

export interface PimLandDisplayJson {
  beschreibung?: string
  bez?: string
  /** @format int64 */
  id?: number
  image?: string
  kurzBez?: string
  name?: string
}

export enum PimMappingZartTyp {
  ME = 'ME',
  STEUER = 'STEUER',
  DP_ME = 'DP_ME',
  DP_STEUER = 'DP_STEUER'
}

export interface PimMengeneinheitDisplayJson {
  beschreibung?: string
  bez?: string
  /** @format int64 */
  id?: number
  image?: string
  kurzBez?: string
  name?: string
}

export interface PimProduktJson {
  hageNummer?: string
  /** @format int64 */
  id?: number
  version?: PimProduktVersionJson
}

export interface PimProduktSpracheXML {
  /**
   * AS0242: Primärklassifikations-Pfad eines Artikels.
   * @example " Gardinen & Sonnenschutz > Rollos & Plissees > Rollos"
   */
  Primaerklassifikation?: string
  /**
   * Sprache ID (ISO 639-1).
   * @example "de"
   */
  Sprache: string
}

export interface PimProduktVersionJson {
  akt?: boolean
  hageProduktNummer?: string
  /** @format int64 */
  id?: number
  marke?: string
  sprache?: PimProduktVersionSpracheJson
  statistischeWarennummer?: string
  vertriebskanal?: string
  zolltarifnummer?: string
}

export interface PimProduktVersionSpracheJson {
  /** @format int64 */
  id?: number
  primaerklassifikation?: string
  sprache?: PimSpracheDisplayJson
}

export interface PimProduktXML {
  /**
   * AS0293: Systemübergreifende Hagebau Produktnummer.
   * @minLength 1
   * @maxLength 10
   * @example "P123456"
   */
  HageProduktNr: string
  /**
   * AS0142: Enthält die ID der Marke bzw. der hagebau Eigenmarke. Ausgegeben wird nur die 4-Stellige ID.
   * @example "0023"
   */
  Marke?: string
  Sprachen?: PimProduktSpracheXML[]
  /**
   * AS0065: 11-stelliger Klassifizierungscode (Import/Export) zur Bestimmung von Abgaben oder Zöllen für das Ein- und Ausführen dieser Ware. Zugleich dient der Code als Warennummer zur Nachverfolgung des Warenverkehrs innerhalb der Europäischen Union (INTRASTAT).
   * @example "73211190000"
   */
  StatistischeWarennummer?: string
  /**
   * AS0141: Gibt den Betriebstypen/ Kanal/ Vertriebsweg/ Kontext/ Verwendungszweck/ das System, ggf. länderspezifisch, an, an das der Artikel ausgeleitet wird (z.B. FH, EH, eCom).
   * @example "Einzelhandel hagebaumarkt Deutschland"
   */
  Vertriebskanal?: string
  /**
   * AS0473: 11-stelliger Klassifizierungscode (Import/Export) zur Bestimmung von Abgaben oder Zöllen für das Ein- und Ausführen dieser Ware. Zugleich dient der Code als Warennummer zur Nachverfolgung des Warenverkehrs innerhalb der Europäischen Union (INTRASTAT).
   * @example "73211190"
   */
  Zolltarifnummer?: string
}

export interface PimPropertyXML {
  /**
   * Schlüssel
   * @minLength 1
   * @maxLength 50
   * @example "Farbe"
   */
  Name: string
  /**
   * Wert
   * @minLength 0
   * @maxLength 500
   * @example "blau"
   */
  Wert?: string
}

export interface PimRecordJson {
  /** @format int64 */
  id?: number
  lastErrors?: ProtokollEintragJson[]
  /** @format date-time */
  lastImport?: string
  lastImportOk?: boolean
  /** @format int64 */
  lastProtokollId?: number
  /** @format int64 */
  offset?: number
}

export interface PimSpracheDisplayJson {
  beschreibung?: string
  bez?: string
  /** @format int64 */
  id?: number
  image?: string
  kurzBez?: string
  name?: string
}

export interface PimSpracheXML {
  /**
   * Beschreibung
   * @minLength 0
   * @maxLength 255
   * @example "Deutschland ein schönes Land."
   */
  Beschreibung?: string
  /**
   * Bezeichnung
   * @minLength 1
   * @maxLength 100
   * @example "Deutschland"
   */
  Bez: string
  /**
   * Kurzbezeichnung
   * @minLength 0
   * @maxLength 100
   * @example "DE"
   */
  Kurz?: string
  /**
   * Sprache ID (ISO 639-1).
   * @minLength 1
   * @maxLength 10
   * @example "de"
   */
  Sprache: string
}

export interface PimVkPreisXML {
  /**
   * Menge Staffel 1.
   * @example 10
   */
  MengeStaffel1?: number
  /**
   * Menge Staffel 2.
   * @example 10
   */
  MengeStaffel2?: number
  /**
   * Menge Staffel 3.
   * @example 10
   */
  MengeStaffel3?: number
  /**
   * Menge Staffel 4.
   * @example 10
   */
  MengeStaffel4?: number
  /**
   * Menge Staffel 5.
   * @example 10
   */
  MengeStaffel5?: number
  /**
   * Preis Verkaufspreisgruppe 1.
   * @example 14.95
   */
  Pg1?: number
  /**
   * Preis Verkaufspreisgruppe 2.
   * @example 15.95
   */
  Pg2?: number
  /**
   * Preis Verkaufspreisgruppe 3.
   * @example 16.95
   */
  Pg3?: number
  /**
   * Preis Verkaufspreisgruppe 4.
   * @example 17.95
   */
  Pg4?: number
  /**
   * Preis Verkaufspreisgruppe 5.
   * @example 18.95
   */
  Pg5?: number
  /**
   * Preis Verkaufspreisgruppe 6.
   * @example 18.95
   */
  Pg6?: number
  /**
   * Preis in der Verkaufspreisgruppe 1 Staffel 1.
   * @example 14.95
   */
  PreisPg1Staffel1?: number
  /**
   * Preis in der Verkaufspreisgruppe 1 Staffel 2.
   * @example 14.95
   */
  PreisPg1Staffel2?: number
  /**
   * Preis in der Verkaufspreisgruppe 1 Staffel 3.
   * @example 14.95
   */
  PreisPg1Staffel3?: number
  /**
   * Preis in der Verkaufspreisgruppe 1 Staffel 4.
   * @example 14.95
   */
  PreisPg1Staffel4?: number
  /**
   * Preis in der Verkaufspreisgruppe 1 Staffel 5.
   * @example 14.95
   */
  PreisPg1Staffel5?: number
  /**
   * Preis in der Verkaufspreisgruppe 2 Staffel 1.
   * @example 14.95
   */
  PreisPg2Staffel1?: number
  /**
   * Preis in der Verkaufspreisgruppe 2 Staffel 2.
   * @example 14.95
   */
  PreisPg2Staffel2?: number
  /**
   * Preis in der Verkaufspreisgruppe 2 Staffel 3.
   * @example 14.95
   */
  PreisPg2Staffel3?: number
  /**
   * Preis in der Verkaufspreisgruppe 2 Staffel 4.
   * @example 14.95
   */
  PreisPg2Staffel4?: number
  /**
   * Preis in der Verkaufspreisgruppe 2 Staffel 5.
   * @example 14.95
   */
  PreisPg2Staffel5?: number
  /**
   * Preis in der Verkaufspreisgruppe 3 Staffel 1.
   * @example 14.95
   */
  PreisPg3Staffel1?: number
  /**
   * Preis in der Verkaufspreisgruppe 3 Staffel 2.
   * @example 14.95
   */
  PreisPg3Staffel2?: number
  /**
   * Preis in der Verkaufspreisgruppe 3 Staffel 3.
   * @example 14.95
   */
  PreisPg3Staffel3?: number
  /**
   * Preis in der Verkaufspreisgruppe 3 Staffel 4.
   * @example 14.95
   */
  PreisPg3Staffel4?: number
  /**
   * Preis in der Verkaufspreisgruppe 3 Staffel 5.
   * @example 14.95
   */
  PreisPg3Staffel5?: number
  /**
   * Preis in der Verkaufspreisgruppe 4 Staffel 1.
   * @example 14.95
   */
  PreisPg4Staffel1?: number
  /**
   * Preis in der Verkaufspreisgruppe 4 Staffel 2.
   * @example 14.95
   */
  PreisPg4Staffel2?: number
  /**
   * Preis in der Verkaufspreisgruppe 4 Staffel 3.
   * @example 14.95
   */
  PreisPg4Staffel3?: number
  /**
   * Preis in der Verkaufspreisgruppe 4 Staffel 4.
   * @example 14.95
   */
  PreisPg4Staffel4?: number
  /**
   * Preis in der Verkaufspreisgruppe 4 Staffel 5.
   * @example 14.95
   */
  PreisPg4Staffel5?: number
  /**
   * Preis in der Verkaufspreisgruppe 5 Staffel 1.
   * @example 14.95
   */
  PreisPg5Staffel1?: number
  /**
   * Preis in der Verkaufspreisgruppe 5 Staffel 2.
   * @example 14.95
   */
  PreisPg5Staffel2?: number
  /**
   * Preis in der Verkaufspreisgruppe 5 Staffel 3.
   * @example 14.95
   */
  PreisPg5Staffel3?: number
  /**
   * Preis in der Verkaufspreisgruppe 5 Staffel 4.
   * @example 14.95
   */
  PreisPg5Staffel4?: number
  /**
   * Preis in der Verkaufspreisgruppe 5 Staffel 5.
   * @example 14.95
   */
  PreisPg5Staffel5?: number
  /**
   * Gibt an ob der VK ein Brutto oder Netto-Preis ist.
   * @format brutto/netto
   * @example "netto"
   */
  BruttoNetto?: string
  /** Gibt an, ob ein Artikel ein Festpreisartikel ist und somit nicht rabattier- oder skontierfähig ist. */
  Festpreis?: boolean
  /**
   * Hier wird der Faktor bzw. der Inhalt des Artikels angegeben mit dem der Grundpreis berechnet werden kann.
   * @example 0.25
   */
  GrundpreisFaktor?: number
  /**
   * Hier wird die Maßeinheit angegeben, auf dessen Basis sich der Grundpreis berechnet (EANCOM Element 6411-Code).
   * @example "LTR"
   */
  GrundpreisMasseinheit?: string
  /**
   * AS0305: Gültig ab Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigAb: string
  /**
   * AS0304: Gültig bis Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigBis?: string
  /**
   * Systemübergreifende Hagebau Artikelnummer, welche vom ersten erfassenden System vergeben wird.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * Kontext ID.
   * @example "099"
   */
  Kontext: string
  /**
   * Der Netto-Verkaufspreis.
   * @example 10.25
   */
  NettoVK?: number
  /**
   * AS0303: Angabe, auf welche Anzahl der Artikeleinheiten sich der angegebene Preis bezieht. Üblicherweise ist der Wert '1'. Es sind auch abweichende Ausprägungen wie 10, 100, 1000 möglich.
   * @example 1
   */
  PreisBasis: number
  /**
   * AS0302: Menge, auf die sich der Preis bezieht.
   * @example 1
   */
  PreisMenge?: number
  /**
   * AS0301: EANCOM Element 6411-Code der Preis Mengeneinheit
   * @example "PCE"
   */
  PreisMengeneinheit: string
  /**
   * ISO-Code Währung (ISO 4217)
   * @example "EUR"
   */
  Waehrung: string
}

export interface PimWaehrungDisplayJson {
  beschreibung?: string
  bez?: string
  /** @format int64 */
  id?: number
  image?: string
  kurzBez?: string
  name?: string
}

export interface PimWertemengeWertXML {
  /**
   * Identifikationscode des Wertes in der Wertemenge
   * @minLength 1
   * @maxLength 50
   * @example "DE"
   */
  Code: string
  /** Image link oder name */
  Image?: string
  /**
   * Inaktiv
   * @example false
   */
  Inaktiv?: boolean
  Properties?: PimPropertyXML[]
  Sprachen?: PimSpracheXML[]
}

export interface PimWertemengeXML {
  /**
   * Name der Wertemenge
   * @minLength 1
   * @maxLength 50
   * @example "country"
   */
  Name: string
  Werte?: PimWertemengeWertXML[]
}

export interface PreisAenderungEintragChangeJson {
  accept?: boolean
  /** @format int64 */
  id?: number
}

export interface PreisAenderungEintragJson {
  /** @format int64 */
  aboSelektionId?: number
  aboStatus?: ArtikelAbonniertStatus
  artikel?: ArtikelDisplayJson
  ekPreis?: boolean
  ekPreise?: PimArtikelLieferantEkPreisJson[]
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  preisMitEigenpflege?: boolean
  preisMitEigenpflegeBisListungsaenderung?: boolean
  preisMitNeuKalkulation?: boolean
  vkPreis?: boolean
  vkPreise?: PimArtikelVkPreisJson[]
  /** @format int64 */
  vkPreislisteId?: number
}

export interface PreisAenderungListeChangeJson {
  artikelListeRef?: string
  bt?: ArtikelBetriebstyp
  changes?: PreisAenderungEintragChangeJson[]
  kontext?: NeonKontext
}

export interface PreisAenderungenBearbeitenJson {
  artikelBearbeitungsListe?: ArtikelBearbeitungsListeJson
  liste?: PreisAenderungEintragJson[]
  state?: UseCaseStateJson
}

export interface PreisDiffJson {
  /** @format date */
  gueltigAb?: string
  konditionen?: EkKonditionenEbeneDisplayJson
  meName?: string
  pm?: number
  pmName?: string
  preis?: number
  rabattgruppe?: EkKonditionenRabattgruppeKopfJson
}

export interface PreisEbeneDisplayJson {
  alias?: string
  ausgeblendet?: boolean
  defaultStandort?: boolean
  geloescht?: boolean
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  name?: string
  nr?: string
  parent?: PreisEbeneDisplayJson
  /** @format int32 */
  reihe?: number
  stufe?: PreisEbeneStufe
}

export interface PreisEbeneJson {
  alias?: string
  ausgeblendet?: boolean
  geloescht?: boolean
  /** @format int64 */
  id?: number
  kontext?: NeonKontextDisplayJson
  name?: string
  nr?: string
  parent?: PreisEbeneJson
  /** @format int32 */
  reihe?: number
  stufe?: PreisEbeneStufe
  /** @format int32 */
  version?: number
}

export interface PreisEbeneListeJson {
  items?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
}

export interface PreisEbeneMitLieferantDisplayJson {
  defaultStandort?: boolean
  hauptlieferant?: LieferantDisplayJson
  /** @format int64 */
  id?: number
  name?: string
  nr?: string
}

export enum PreisEbeneStufe {
  STUFE0 = 'STUFE0',
  STUFEA = 'STUFEA',
  LOGISTIK = 'LOGISTIK'
}

export interface PreisEbenenBearbeitenJson {
  preisEbenen?: PreisEbenenJson
  state?: UseCaseStateJson
}

export interface PreisEbenenJson {
  gln?: string
  /** @format int64 */
  id?: number
  name?: string
  preisebenen?: PreisEbeneJson[]
  state?: UseCaseStateJson
  typ?: GesellschafterTyp
}

export enum PreisListeTyp {
  CSV = 'CSV',
  MANUELL = 'MANUELL',
  RABATTGRUPPE = 'RABATTGRUPPE',
  LISTUNG = 'LISTUNG'
}

export enum PreisVerteilungEinstellung {
  KEINE_PREISE = 'KEINE_PREISE',
  LISTEN_PREISE = 'LISTEN_PREISE',
  EKPREISE = 'EKPREISE',
  ALLE_PREISE = 'ALLE_PREISE'
}

export enum PreisVorgangTyp {
  EK_PREIS = 'EK_PREIS',
  VK_PREIS = 'VK_PREIS',
  EK_UND_VK_PREIS = 'EK_UND_VK_PREIS'
}

export interface ProtokollAnzeigenJson {
  searcher?: ProtokollAnzeigenSearcherJson
  state?: UseCaseStateJson
}

export interface ProtokollAnzeigenSearcherCriteriaJson {
  message?: string
  minSeverityType?: MessageSeverity
  /** @format int64 */
  protokollId?: number
}

export interface ProtokollAnzeigenSearcherJson {
  criteria?: ProtokollAnzeigenSearcherCriteriaJson
  limited?: boolean
  result?: ProtokollEintragJson[]
}

export interface ProtokollEintragJson {
  errors?: MessageJson[]
  fragment?: string
  /** @format int64 */
  id?: number
  message?: string
  messageId?: MessageID
  /** @format int64 */
  protokollId?: number
  reference?: string
  severity?: MessageSeverity
  technisch?: boolean
  /** @format date-time */
  timestamp?: string
}

export interface ProtokollJson {
  /** @format int32 */
  detailCount?: number
  /** @format int32 */
  detailErrorCount?: number
  /** @format int32 */
  detailFatalCount?: number
  /** @format int32 */
  detailWarningCount?: number
  /** @format date-time */
  end?: string
  /** @format int64 */
  id?: number
  info?: string
  /** @format date-time */
  start?: string
  status?: ProtokollStatus
  typ?: ProtokollTyp
}

export enum ProtokollStatus {
  STARTED = 'STARTED',
  ENDED = 'ENDED'
}

export enum ProtokollTyp {
  IMPORT_JOB = 'IMPORT_JOB',
  EXPORT_ERP_DATA = 'EXPORT_ERP_DATA',
  VERTEILUNG = 'VERTEILUNG',
  ASYNC_TASK = 'ASYNC_TASK',
  UPDATE_SEL = 'UPDATE_SEL',
  ETIKETTEN_DRUCK = 'ETIKETTEN_DRUCK',
  ALLIANZ_BEREIT = 'ALLIANZ_BEREIT'
}

export interface ProtokollUebersichtJson {
  searcher?: ProtokollUebersichtSearcherJson
  state?: UseCaseStateJson
}

export interface ProtokollUebersichtSearcherCriteriaJson {
  /** @format date */
  maxStartedAt?: string
  minSeverityType?: MessageSeverity
  /** @format date */
  minStartedAt?: string
  status?: ProtokollStatus
  typ?: ProtokollTyp
}

export interface ProtokollUebersichtSearcherJson {
  criteria?: ProtokollUebersichtSearcherCriteriaJson
  limited?: boolean
  result?: ProtokollJson[]
}

export interface PulseJson {
  exportSyncInfo?: SyncInfoJson
  importSchedulerState?: JobSchedulerStatus
  state?: UseCaseStateJson
  /** @format int64 */
  timestamp?: number
}

export interface ReImportResultJson {
  erpEbeneImportRequestIds?: number[]
  state?: UseCaseStateJson
}

export interface Resource {
  description?: string
  /** @format binary */
  file?: File
  filename?: string
  inputStream?: object
  open?: boolean
  readable?: boolean
  /** @format uri */
  uri?: string
  /** @format url */
  url?: string
}

export interface ResponseEntityObject {
  body?: object
  headers?: {
    accept?: MediaType[]
    acceptCharset?: {
      registered?: boolean
    }[]
    acceptLanguage?: {
      range?: string
      /** @format double */
      weight?: number
    }[]
    acceptLanguageAsLocales?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }[]
    acceptPatch?: MediaType[]
    accessControlAllowCredentials?: boolean
    accessControlAllowHeaders?: string[]
    accessControlAllowMethods?: (
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    )[]
    accessControlAllowOrigin?: string
    accessControlExposeHeaders?: string[]
    /** @format int64 */
    accessControlMaxAge?: number
    accessControlRequestHeaders?: string[]
    accessControlRequestMethod?:
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    all?: Record<string, string>
    /** @uniqueItems true */
    allow?: ('GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE')[]
    basicAuth?: string
    bearerAuth?: string
    cacheControl?: string
    connection?: string[]
    contentDisposition?: ContentDisposition
    contentLanguage?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }
    /** @format int64 */
    contentLength?: number
    contentType?: MediaType
    /** @format int64 */
    date?: number
    empty?: boolean
    etag?: string
    /** @format int64 */
    expires?: number
    host?: {
      address?: {
        address?: string[]
        anyLocalAddress?: boolean
        canonicalHostName?: string
        hostAddress?: string
        hostName?: string
        linkLocalAddress?: boolean
        loopbackAddress?: boolean
        mcglobal?: boolean
        mclinkLocal?: boolean
        mcnodeLocal?: boolean
        mcorgLocal?: boolean
        mcsiteLocal?: boolean
        multicastAddress?: boolean
        siteLocalAddress?: boolean
      }
      hostName?: string
      hostString?: string
      /** @format int32 */
      port?: number
      unresolved?: boolean
    }
    ifMatch?: string[]
    /** @format int64 */
    ifModifiedSince?: number
    ifNoneMatch?: string[]
    /** @format int64 */
    ifUnmodifiedSince?: number
    /** @format int64 */
    lastModified?: number
    /** @format uri */
    location?: string
    origin?: string
    pragma?: string
    range?: HttpRange[]
    upgrade?: string
    vary?: string[]
    [key: string]: any
  }
  statusCode?:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED'
  /** @format int32 */
  statusCodeValue?: number
}

export interface ResponseEntityResource {
  body?: Resource
  headers?: {
    accept?: MediaType[]
    acceptCharset?: {
      registered?: boolean
    }[]
    acceptLanguage?: {
      range?: string
      /** @format double */
      weight?: number
    }[]
    acceptLanguageAsLocales?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }[]
    acceptPatch?: MediaType[]
    accessControlAllowCredentials?: boolean
    accessControlAllowHeaders?: string[]
    accessControlAllowMethods?: (
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    )[]
    accessControlAllowOrigin?: string
    accessControlExposeHeaders?: string[]
    /** @format int64 */
    accessControlMaxAge?: number
    accessControlRequestHeaders?: string[]
    accessControlRequestMethod?:
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    all?: Record<string, string>
    /** @uniqueItems true */
    allow?: ('GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE')[]
    basicAuth?: string
    bearerAuth?: string
    cacheControl?: string
    connection?: string[]
    contentDisposition?: ContentDisposition
    contentLanguage?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }
    /** @format int64 */
    contentLength?: number
    contentType?: MediaType
    /** @format int64 */
    date?: number
    empty?: boolean
    etag?: string
    /** @format int64 */
    expires?: number
    host?: {
      address?: {
        address?: string[]
        anyLocalAddress?: boolean
        canonicalHostName?: string
        hostAddress?: string
        hostName?: string
        linkLocalAddress?: boolean
        loopbackAddress?: boolean
        mcglobal?: boolean
        mclinkLocal?: boolean
        mcnodeLocal?: boolean
        mcorgLocal?: boolean
        mcsiteLocal?: boolean
        multicastAddress?: boolean
        siteLocalAddress?: boolean
      }
      hostName?: string
      hostString?: string
      /** @format int32 */
      port?: number
      unresolved?: boolean
    }
    ifMatch?: string[]
    /** @format int64 */
    ifModifiedSince?: number
    ifNoneMatch?: string[]
    /** @format int64 */
    ifUnmodifiedSince?: number
    /** @format int64 */
    lastModified?: number
    /** @format uri */
    location?: string
    origin?: string
    pragma?: string
    range?: HttpRange[]
    upgrade?: string
    vary?: string[]
    [key: string]: any
  }
  statusCode?:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED'
  /** @format int32 */
  statusCodeValue?: number
}

export interface ResponseEntitySessionJson {
  body?: SessionJson
  headers?: {
    accept?: MediaType[]
    acceptCharset?: {
      registered?: boolean
    }[]
    acceptLanguage?: {
      range?: string
      /** @format double */
      weight?: number
    }[]
    acceptLanguageAsLocales?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }[]
    acceptPatch?: MediaType[]
    accessControlAllowCredentials?: boolean
    accessControlAllowHeaders?: string[]
    accessControlAllowMethods?: (
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    )[]
    accessControlAllowOrigin?: string
    accessControlExposeHeaders?: string[]
    /** @format int64 */
    accessControlMaxAge?: number
    accessControlRequestHeaders?: string[]
    accessControlRequestMethod?:
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    all?: Record<string, string>
    /** @uniqueItems true */
    allow?: ('GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE')[]
    basicAuth?: string
    bearerAuth?: string
    cacheControl?: string
    connection?: string[]
    contentDisposition?: ContentDisposition
    contentLanguage?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }
    /** @format int64 */
    contentLength?: number
    contentType?: MediaType
    /** @format int64 */
    date?: number
    empty?: boolean
    etag?: string
    /** @format int64 */
    expires?: number
    host?: {
      address?: {
        address?: string[]
        anyLocalAddress?: boolean
        canonicalHostName?: string
        hostAddress?: string
        hostName?: string
        linkLocalAddress?: boolean
        loopbackAddress?: boolean
        mcglobal?: boolean
        mclinkLocal?: boolean
        mcnodeLocal?: boolean
        mcorgLocal?: boolean
        mcsiteLocal?: boolean
        multicastAddress?: boolean
        siteLocalAddress?: boolean
      }
      hostName?: string
      hostString?: string
      /** @format int32 */
      port?: number
      unresolved?: boolean
    }
    ifMatch?: string[]
    /** @format int64 */
    ifModifiedSince?: number
    ifNoneMatch?: string[]
    /** @format int64 */
    ifUnmodifiedSince?: number
    /** @format int64 */
    lastModified?: number
    /** @format uri */
    location?: string
    origin?: string
    pragma?: string
    range?: HttpRange[]
    upgrade?: string
    vary?: string[]
    [key: string]: any
  }
  statusCode?:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED'
  /** @format int32 */
  statusCodeValue?: number
}

export interface ResponseEntityString {
  body?: string
  headers?: {
    accept?: MediaType[]
    acceptCharset?: {
      registered?: boolean
    }[]
    acceptLanguage?: {
      range?: string
      /** @format double */
      weight?: number
    }[]
    acceptLanguageAsLocales?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }[]
    acceptPatch?: MediaType[]
    accessControlAllowCredentials?: boolean
    accessControlAllowHeaders?: string[]
    accessControlAllowMethods?: (
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    )[]
    accessControlAllowOrigin?: string
    accessControlExposeHeaders?: string[]
    /** @format int64 */
    accessControlMaxAge?: number
    accessControlRequestHeaders?: string[]
    accessControlRequestMethod?:
      | 'GET'
      | 'HEAD'
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'OPTIONS'
      | 'TRACE'
    all?: Record<string, string>
    /** @uniqueItems true */
    allow?: ('GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE')[]
    basicAuth?: string
    bearerAuth?: string
    cacheControl?: string
    connection?: string[]
    contentDisposition?: ContentDisposition
    contentLanguage?: {
      iso3Country?: string
      iso3Language?: string
      country?: string
      displayCountry?: string
      displayLanguage?: string
      displayName?: string
      displayScript?: string
      displayVariant?: string
      /** @uniqueItems true */
      extensionKeys?: string[]
      language?: string
      script?: string
      /** @uniqueItems true */
      unicodeLocaleAttributes?: string[]
      /** @uniqueItems true */
      unicodeLocaleKeys?: string[]
      variant?: string
    }
    /** @format int64 */
    contentLength?: number
    contentType?: MediaType
    /** @format int64 */
    date?: number
    empty?: boolean
    etag?: string
    /** @format int64 */
    expires?: number
    host?: {
      address?: {
        address?: string[]
        anyLocalAddress?: boolean
        canonicalHostName?: string
        hostAddress?: string
        hostName?: string
        linkLocalAddress?: boolean
        loopbackAddress?: boolean
        mcglobal?: boolean
        mclinkLocal?: boolean
        mcnodeLocal?: boolean
        mcorgLocal?: boolean
        mcsiteLocal?: boolean
        multicastAddress?: boolean
        siteLocalAddress?: boolean
      }
      hostName?: string
      hostString?: string
      /** @format int32 */
      port?: number
      unresolved?: boolean
    }
    ifMatch?: string[]
    /** @format int64 */
    ifModifiedSince?: number
    ifNoneMatch?: string[]
    /** @format int64 */
    ifUnmodifiedSince?: number
    /** @format int64 */
    lastModified?: number
    /** @format uri */
    location?: string
    origin?: string
    pragma?: string
    range?: HttpRange[]
    upgrade?: string
    vary?: string[]
    [key: string]: any
  }
  statusCode?:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED'
  /** @format int32 */
  statusCodeValue?: number
}

export interface SapImportDataXML {
  Lieferant?: SapLieferantXML[]
}

export interface SapLieferantAdresseXML {
  Name1?: string
  Name2?: string
  Name3?: string
  Telefon1?: string
  Telefon2?: string
  Anrede?: string
  CO?: string
  EMail?: string
  Fax?: string
  Internet?: string
  /**
   * Ländercode (ISO 3166-1-alpha2).
   * @example "DE"
   */
  Land?: string
  Ort?: string
  Ortsteil?: string
  Plz?: string
  Postfach?: string
  PostfachOrt?: string
  Strasse?: string
  /**
   * Adresstyp, es muss genau eine Hauptadresse geben.
   * @format H: Hauptadresse, A: Abholadressse
   * @example "H"
   */
  Typ?: string
  postfachPlz?: string
}

export interface SapLieferantBrancheXML {
  Branche?: string
}

export interface SapLieferantEdiOrdersOutXML {
  Display?: string
  Status?: string
}

export interface SapLieferantEdiXML {
  OrdersOut?: SapLieferantEdiOrdersOutXML[]
}

export interface SapLieferantEinkaeuferXML {
  EMail?: string
  GruppeName?: string
  GruppeNr?: string
  Name?: string
  Telefon?: string
}

export interface SapLieferantXML {
  'FH-Einheit'?: string
  Adresse?: SapLieferantAdresseXML[]
  /**
   * Datum der letzen Änderung in SAP im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  Aenderungsdatum?: string
  /**
   * Datum der Anlage in SAP im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-05-01T00:00:00Z"
   */
  Anlagedatum?: string
  Branche?: SapLieferantBrancheXML[]
  Buchungssperre?: boolean
  EDI?: SapLieferantEdiXML[]
  Einkaeufer?: SapLieferantEinkaeuferXML[]
  Einkaufssperre?: boolean
  Frachtzuschlag?: number
  /** @format int32 */
  Freihausgrenze?: number
  /** @format int32 */
  Freihausmenge?: number
  Geloescht?: boolean
  Gesperrt?: boolean
  Handelscode?: string
  /**
   * Internationale Lieferantennummer.
   * @format 13-stellig.
   * @example "2970185000004"
   */
  ILN?: string
  /**
   * Kontonummer.
   * @format 6-stellig.
   * @example "970185"
   */
  Kontonummer?: string
  /**
   * Typisierung des Lieferanten nach ZKRE, ZKOS, ZPIM.
   * @format ZKRE: Warenlieferant, ZKOS: Kostenlieferant, ZPIM: Importlieferant
   * @example "ZKRE"
   */
  LieferantArt?: string
  Lieferbedingung?: string
  MindestBestellwert?: number
  /** @format int32 */
  Planzeit?: number
  Sprache?: string
  Suchbegriff?: string
  UStIDNummer?: string
  /** Vorlauf, Nummernkeis. */
  Vorlauf?: string
  /**
   * ISO-Code Währung (ISO 4217)
   * @example "EUR"
   */
  Waehrung?: string
  Zahlungsziel?: string
}

/** Menge in BasisEinheit dieser LME. */
export interface SchemaMengeBasisEinheit {
  /**
   * Represented fraction as decimal
   * @example 0.2
   */
  Faktor?: number
  /**
   * Denominator of fraction
   * @example 1
   */
  Nenner?: number
  /**
   * Numerator of fraction
   * @example 5
   */
  Zaehler?: number
}

/** Menge in Einzelmengeneinheit dieses Artikels. */
export interface SchemaMengeEinzelmengeneinheit {
  /**
   * Represented fraction as decimal
   * @example 0.2
   */
  Faktor?: number
  /**
   * Denominator of fraction
   * @example 1
   */
  Nenner?: number
  /**
   * Numerator of fraction
   * @example 5
   */
  Zaehler?: number
}

/** Unverbindliche Preisempfehlung des Herstellers. */
export interface SchemaUnverbindlichePreisempfehlung {
  /**
   * AS0305: Gültig ab Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigAb: string
  /**
   * AS0304: Gültig bis Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigBis?: string
  /**
   * Systemübergreifende Hagebau Artikelnummer, welche vom ersten erfassenden System vergeben wird.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * Kontext ID.
   * @example "099"
   */
  Kontext: string
  /**
   * Lieferanten-Nr.
   * interne Lieferantennummer der hagebau.
   * @example "900448"
   */
  LieferantNr: string
  /**
   * Preis.
   * @example 1
   */
  Preis?: number
  /**
   * AS0303: Angabe, auf welche Anzahl der Artikeleinheiten sich der angegebene Preis bezieht. Üblicherweise ist der Wert '1'. Es sind auch abweichende Ausprägungen wie 10, 100, 1000 möglich.
   * @example 1
   */
  PreisBasis: number
  /**
   * AS0302: Menge, auf die sich der Preis bezieht.
   * @example 1
   */
  PreisMenge?: number
  /**
   * AS0301: EANCOM Element 6411-Code der Preis Mengeneinheit
   * @example "PCE"
   */
  PreisMengeneinheit: string
  /**
   * Rabattgruppe.
   * AS0430
   * @example "20 %"
   */
  Rabattgruppe?: string
  /**
   * ISO-Code Währung (ISO 4217)
   * @example "EUR"
   */
  Waehrung: string
}

/** Werkslistenpreis des Herstellers seines Artikels. */
export interface SchemaWerkslistenpreis {
  /**
   * AS0305: Gültig ab Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigAb: string
  /**
   * AS0304: Gültig bis Datum des Preises im Format ISO 8601 (RFC 3339, section 5.6).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  GueltigBis?: string
  /**
   * Systemübergreifende Hagebau Artikelnummer, welche vom ersten erfassenden System vergeben wird.
   * @minLength 1
   * @maxLength 10
   * @example "6437230"
   */
  HageNr: string
  /**
   * Kontext ID.
   * @example "099"
   */
  Kontext: string
  /**
   * Lieferanten-Nr.
   * interne Lieferantennummer der hagebau.
   * @example "900448"
   */
  LieferantNr: string
  /**
   * Preis.
   * @example 1
   */
  Preis?: number
  /**
   * AS0303: Angabe, auf welche Anzahl der Artikeleinheiten sich der angegebene Preis bezieht. Üblicherweise ist der Wert '1'. Es sind auch abweichende Ausprägungen wie 10, 100, 1000 möglich.
   * @example 1
   */
  PreisBasis: number
  /**
   * AS0302: Menge, auf die sich der Preis bezieht.
   * @example 1
   */
  PreisMenge?: number
  /**
   * AS0301: EANCOM Element 6411-Code der Preis Mengeneinheit
   * @example "PCE"
   */
  PreisMengeneinheit: string
  /**
   * Rabattgruppe.
   * AS0430
   * @example "20 %"
   */
  Rabattgruppe?: string
  /**
   * ISO-Code Währung (ISO 4217)
   * @example "EUR"
   */
  Waehrung: string
}

export interface SeArtikelJson {
  eigenlistung?: boolean
  /** @format int64 */
  id?: number
  pimArtikel?: PimArtikelJson
  /** @format int32 */
  version?: number
}

export interface SeErpZufuhrMultiXML {
  AktionCode?: ErpAktionCode
  ErpId?: string
  ErpIdHerkunft?: string
  ErrorMessage?: string
  FachSchluessel?: string
  Hint?: string
  ImportRef?: string
  ZufuhrMulti?: SeZufuhrMultiXML
}

export interface SeLieferantJson {
  audit?: HKMAuditJson
  dzLieferant?: DzLieferantJson
  /** @format int64 */
  id?: number
  /** @format int32 */
  version?: number
}

export enum SeStandortArtikelStatus {
  ZUGEORDNET = 'ZUGEORDNET',
  ENTFERNT = 'ENTFERNT'
}

export interface SeZufuhrMultiPreisgruppeXML {
  Menge?: number
  PreisgruppeErpId?: string
}

export interface SeZufuhrMultiXML {
  Bezeichnung?: string
  Name?: string
  ZufuhrMultiPreisgruppen?: SeZufuhrMultiPreisgruppeXML[]
}

export interface SessionJson {
  adminMode?: boolean
  companies?: GesellschafterDisplayJson[]
  company?: string
  datapoolNeonEnabled?: boolean
  displayname?: string
  einzelhandel?: boolean
  env?: string
  fachhandel?: boolean
  firstname?: string
  gln?: string
  lastname?: string
  pimModel?: boolean
  /** @format int64 */
  ptoErr?: number
  /** @format int64 */
  ptoOk?: number
  /** @format int64 */
  ptoUser?: number
  /** @uniqueItems true */
  roles?: string[]
  sid?: string
  sortimente?: boolean
  username?: string
}

export interface SortimentBearbeitenJson {
  sortiment?: SortimentJson
  standort?: StandortJson
  standortgruppe?: StandortGruppeDisplayJson
  state?: UseCaseStateJson
}

export interface SortimentEintragJson {
  artikelListe?: ArtikelListeDisplayJson
  /** @format int64 */
  artikelListeId?: number
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
  /** @format int32 */
  version?: number
}

export interface SortimentJson {
  audit?: HKMAuditJson
  eintraege?: SortimentEintragJson[]
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  /** @format date-time */
  syncTime?: string
  /** @format int32 */
  version?: number
}

export interface SparteDisplayJson {
  /** @format int64 */
  anzahlArtikel?: number
  bezeichnung?: string
  /** @format int64 */
  id?: number
  nr?: string
}

export enum SpreadSheetType {
  CSV = 'CSV',
  XLSX = 'XLSX'
}

export interface StaffelgruppeDisplayJson {
  flex?: boolean
  /** @format int64 */
  id?: number
  name?: string
  staffelmengen?: number[]
}

export enum StammEbeneTyp {
  GESELLSCHAFTER = 'GESELLSCHAFTER',
  FIRMA = 'FIRMA',
  FIRMEN_GRUPPE = 'FIRMEN_GRUPPE'
}

/** Struktur für den Austausch von StandortArtikel Ek-Preisen */
export interface StandortArtikelEkPreiseXML {
  Artikel?: string
  EkPreise?: EkKonditionenXML[]
  /** @format int32 */
  Standort?: number
}

export interface StandortArtikelJson {
  artikel?: ArtikelDisplayTinyJson
  /** @format int64 */
  id?: number
  standort?: StandortJson
}

/** Struktur für den Austausch von Regalplätzen zum Artikel am Standort */
export interface StandortArtikelRegalplaetzeXML {
  /** Hagebau Artikelnummer (HageNr) */
  Artikel?: string
  Plaetze?: StandortArtikelRegalplatzXML[]
  /** @format int32 */
  Standort?: number
}

export interface StandortArtikelRegalplatzJson {
  /** @format int32 */
  abDatum?: number
  /** @format int32 */
  anzahl?: number
  etikettentyp?: string
  gln?: string
  hageNr?: string
  /** @format int64 */
  id?: number
  lagerbereich?: string
  lagerort?: string
  lagerplatz?: string
  lagerplatztyp?: string
  referenz?: string
  sequenzNr?: string
  standort?: StandortJson
}

/** Struktur für den Austausch eines Regalplatzes */
export interface StandortArtikelRegalplatzXML {
  /**
   * Anzahl der Etiketten des Etikettentyp an diesem Regalplatz
   * @format int32
   */
  AnzahlEtiketten?: number
  /** Etikettentyp an diesem Regalplatz */
  Etikettentyp?: string
  Regalplatz?: string
  SequenzNr?: string
}

/** Struktur für den Austausch von StandortArtikel Vk-Preisen */
export interface StandortArtikelVkPreiseXML {
  Artikel?: string
  /** @format int32 */
  Standort?: number
  VkPreise?: VkPreisBlattXML[]
}

/** Struktur für den Austausch von StandortArtikel Vk-Sperre Daten */
export interface StandortArtikelVkSperreXML {
  Artikel?: string
  /** @format int32 */
  Standort?: number
  /** Ist aktuell eine Vk-Sperre aktiv? */
  VkSperre?: boolean
  /**
   * VkSperreAb. Originaldaten aus der Listung (PIM) auf denen die Entscheidung für den Schalter VkSperre basiert. In CET.
   * @format date-time
   */
  VkSperreAb?: string
  /**
   * VkSperreBis. Originaldaten aus der Listung (PIM) auf denen die Entscheidung für den Schalter VkSperre basiert. In CET.
   * @format date-time
   */
  VkSperreBis?: string
  /** VkSperreGrund. Originaldaten aus der Listung (PIM) auf denen die Entscheidung für den Schalter VkSperre basiert. */
  VkSperreGrund?: string
  /**
   * Datum zu dem der Schalter VkSperre ermittelt wurde. In UTC (Zulu).
   * @format date-time
   * @example "2017-07-21T17:32:28Z"
   */
  VkSperreUpdate?: string
}

export interface StandortArtikelXML {
  Artikel?: string
  HatRegalplatz?: boolean
  HauptLieferant?: string
  /** @format int32 */
  Standort?: number
  Status?: SeStandortArtikelStatus
  VkPreisgruppe?: string
}

export interface StandortGruppeBearbeitenJson {
  kontexte?: NeonKontextDisplayJson[]
  standorte?: StandortJson[]
  standortgruppe?: StandortGruppeJson
  state?: UseCaseStateJson
}

export interface StandortGruppeDisplayJson {
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
  kontext?: NeonKontextDisplayJson
  name?: string
  nr?: string
  standorte?: StandortJson[]
  status?: SynchronisationStatus
}

export interface StandortGruppeJson {
  audit?: HKMAuditJson
  handelTyp?: HandelTyp
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  name?: string
  nr?: string
  standorte?: StandortJson[]
  status?: SynchronisationStatus
  /** @format int32 */
  version?: number
}

export interface StandortGruppeSearcherCriteriaJson {
  nurAktive?: boolean
}

export interface StandortGruppeSearcherJson {
  criteria?: StandortGruppeSearcherCriteriaJson
  limited?: boolean
  result?: StandortGruppeDisplayJson[]
}

export interface StandortGruppeUebersichtJson {
  searcher?: StandortGruppeSearcherJson
  state?: UseCaseStateJson
}

export interface StandortJson {
  /** @format int64 */
  eanGruppeId?: number
  handelTyp?: HandelTyp
  /** @format int64 */
  id?: number
  kontext?: NeonKontext
  kontextLock?: boolean
  name?: string
  /** @format int32 */
  nr?: number
  /** @format int64 */
  standortGruppeId?: number
  status?: SynchronisationStatus
}

export interface StandortRequestXML {
  GLN?: string
}

export interface StandortXML {
  /** Bezeichnung des Standorts. */
  Bezeichnung: string
  /** Identifikation der Gesellschafter-Firma über seine Nummer. */
  GesellschafterFirma: string
  /** Globale Identifikation des Gesellschafters. */
  GesellschafterNr?: string
  /**
   * Kontext ID.
   * @example "211"
   */
  Kontext: string
  /**
   * Eindeutige Nummer des Standorts innerhalb des Gesellschafters.
   * @format int32
   */
  Nummer: number
  /** Ist gesetzt, wenn der Standort einen Webshop betreibt. */
  Webshop: boolean
}

export interface StandorteBearbeitenJson {
  eanGruppen?: EANGruppeDisplayJson[]
  kontexte?: NeonKontextDisplayJson[]
  standortGruppen?: StandortGruppeDisplayJson[]
  standorte?: StandortJson[]
  state?: UseCaseStateJson
}

export interface SteuerJson {
  /** @format int64 */
  id?: number
  name?: string
}

export interface SuchArtikelLieferantDisplayJson {
  artikel?: ArtikelDisplayJson
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
}

export interface SuchArtikelLieferantListeJson {
  items?: SuchArtikelLieferantDisplayJson[]
  state?: UseCaseStateJson
}

export enum SuchArtikelTyp {
  ZENTRAL = 'ZENTRAL',
  PIM = 'PIM',
  DEZENTRAL = 'DEZENTRAL',
  DATENPOOL = 'DATENPOOL',
  ABO = 'ABO'
}

export enum SuchLieferantTyp {
  ZENTRAL = 'ZENTRAL',
  DEZENTRAL = 'DEZENTRAL',
  ABO = 'ABO'
}

export interface SyncEinstellungDisplayJson {
  /** @format int64 */
  id?: number
  name?: string
}

export interface SyncInfoJson {
  /** @format date-time */
  autoExport?: string
  /** @format date-time */
  end?: string
  /** @format int32 */
  progress?: number
  /** @format date-time */
  start?: string
  state?: UseCaseStateJson
  syncState?: SyncState
  todos?: boolean
}

export enum SyncState {
  OK = 'OK',
  TODOS = 'TODOS',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR'
}

export enum SynchronisationStatus {
  DEAKTIVIERT = 'DEAKTIVIERT',
  ANFORDERUNG = 'ANFORDERUNG',
  AKTIVIERT = 'AKTIVIERT',
  AKTIVIERUNG_FEHLGESCHLAGEN = 'AKTIVIERUNG_FEHLGESCHLAGEN'
}

export interface TestChangeErpDataResultXML {
  changedArtikel?: TestChangeXML[]
  changedArtikelArt?: TestChangeXML[]
  changedBetriebstyp?: TestChangeXML[]
  changedEkKondition?: TestChangeXML[]
  changedEkPreis?: TestChangeXML[]
  changedEkStaffelgruppen?: TestChangeXML[]
  changedKalkulationsvorschlaege?: TestChangeXML[]
  changedLieferanten?: TestChangeXML[]
  changedMengeneinheit?: TestChangeXML[]
  changedPreisgruppen?: TestChangeXML[]
  changedPreismengen?: TestChangeXML[]
  changedRabattgruppeVersionen?: TestChangeXML[]
  changedRabattgruppen?: TestChangeXML[]
  changedRundungsregel?: TestChangeXML[]
  changedStandorte?: TestChangeXML[]
  changedSteuern?: TestChangeXML[]
  changedVkPreis?: TestChangeXML[]
  changedWarengruppen?: TestChangeXML[]
  exchangeHeader?: DzErpExchangeHeaderXML
  header?: DzErpExchangeHeaderXML
}

export interface TestChangeErpDataXML {
  dzReferenzMapEntries?: DzReferenzMapEntryXML[]
  erpArtikel?: DzErpArtikelXML[]
  erpArtikelArten?: DzErpArtikelArtXML[]
  erpBetriebstypen?: DzErpBetriebstypXML[]
  erpEkKonditionen?: DzErpEkKonditionenXML[]
  erpEkPreise?: DzErpEkPreisXML[]
  erpEkRabattgruppeVersionen?: DzErpEkKonditionRabattgruppeVersionXML[]
  erpEkRabattgruppen?: DzErpEkKonditionRabattgruppeXML[]
  erpEkStaffelgruppen?: DzErpEkStaffelgruppeXML[]
  erpKalkulationsvorschlaege?: DzErpKalkulationsvorschlagXML[]
  erpLieferanten?: DzErpLieferantXML[]
  erpMengeneinheit?: DzErpMengenEinheitXML[]
  erpPreisgruppen?: DzErpPreisgruppeXML[]
  erpPreismengen?: DzErpPreismengeXML[]
  erpRundungsregeln?: DzErpRundungsregelXML[]
  erpSparten?: DzErpSparteXML[]
  erpStandorte?: DzErpStandortXML[]
  erpSteuern?: DzErpSteuerXML[]
  erpVkPreise?: DzErpVkPreisBlattXML[]
  erpWarengruppen?: DzErpWarengruppeXML[]
  erpZufuhrMulti?: SeErpZufuhrMultiXML[]
  header?: DzErpExchangeHeaderXML
}

export interface TestChangeXML {
  erpId?: string
  errorMessage?: string
}

export interface UmsatzsteuerBearbeitenJson {
  state?: UseCaseStateJson
  tax?: UmsatzsteuerJson
}

export interface UmsatzsteuerJson {
  datenmodell?: Datenmodell
  /** @format date */
  gueltigAb?: string
  /** @format int64 */
  id?: number
  land?: string
  steuerkey?: string
  steuersatz?: number
}

export interface UmsatzsteuerSearcherCriteriaJson {
  datenmodell?: Datenmodell
  /** @format date */
  gueltigAb?: string
  land?: string
  steuerkey?: string
  steuersatz?: number
}

export interface UmsatzsteuerSearcherJson {
  criteria?: UmsatzsteuerSearcherCriteriaJson
  limited?: boolean
  result?: UmsatzsteuerJson[]
}

export interface UmsatzsteuerUebersichtJson {
  searcher?: UmsatzsteuerSearcherJson
  state?: UseCaseStateJson
}

export interface UseCaseStateJson {
  asyncTask?: AsyncTaskJson
  mainMessage?: MessageJson
  messages?: MessageJson[]
}

export interface ValidatePimArtikelResultItemJson {
  bt?: ArtikelBetriebstyp
  errors?: CompositeAttributeChange
  hageNummer?: string
}

export enum VerteilungsModelErrorGrund {
  KEIN = 'KEIN',
  EK_PREIS = 'EK_PREIS',
  STEUER = 'STEUER',
  STANDORT = 'STANDORT',
  ARTIKEL_ART = 'ARTIKEL_ART',
  ARTIKEL = 'ARTIKEL',
  ME = 'ME',
  WG = 'WG',
  EKRG = 'EKRG',
  KV = 'KV',
  LIEF = 'LIEF',
  EAN_DUP = 'EAN_DUP',
  PREISGRUPPE = 'PREISGRUPPE',
  RUNDUNGSREGEL = 'RUNDUNGSREGEL',
  KALKREIHE = 'KALKREIHE',
  KALKVORSCHLAG = 'KALKVORSCHLAG',
  EAN_GRUPPE = 'EAN_GRUPPE',
  ZUFUHR_MULTI = 'ZUFUHR_MULTI',
  RABATTGRUPPE = 'RABATTGRUPPE'
}

export interface VerteilungsZielDisplayJson {
  defaultExpanded?: boolean
  gruppe?: boolean
  /** @format int64 */
  id?: number
  name?: string
  nr?: string
  selectable?: boolean
}

export interface VerteilungsZielFlatDisplayJson {
  /** @format int64 */
  id?: number
  name?: string
  nr?: string
}

export enum VkFrachtArt {
  PROZENT = 'PROZENT',
  FIX = 'FIX',
  GEWICHT = 'GEWICHT'
}

export interface VkFrachtkostenChangeJson {
  ekNichtSkonto?: number
  ekNichtSkonto_setnull?: boolean
  enthalteneFrachtZufuhr?: number
  enthalteneFrachtZufuhr_setnull?: boolean
  frachtAbs?: number
  frachtAbs_setnull?: boolean
  frachtArt?: VkFrachtArt
  frachtArt_setnull?: boolean
  frachtNachRabatt?: boolean
  frachtNichtSkonto?: boolean
  frachtProz?: number
  frachtProz_setnull?: boolean
  /** @format int64 */
  id?: number
  vkNichtSkonto?: number
  vkNichtSkonto_setnull?: boolean
  /** @format int64 */
  zufuhrMultiId?: number
  zufuhrMultiId_setnull?: boolean
}

export interface VkFrachtkostenJson {
  ekNichtSkonto?: number
  enthalteneFrachtZufuhr?: number
  frachtAbs?: number
  frachtArt?: VkFrachtArt
  frachtNachRabatt?: boolean
  frachtNichtSkonto?: boolean
  frachtProz?: number
  /** @format int64 */
  id?: number
  vkNichtSkonto?: number
  /** @format int64 */
  zufuhrMultiId?: number
}

export interface VkKalkulationsvorschlagJson {
  /** @format int64 */
  id?: number
  name?: string
  preise?: VkPreisJson[]
  /** @format int64 */
  rundungsregelId?: number
}

export enum VkPreisBasisTyp {
  KALK_EK = 'KALK_EK',
  LISTENPREIS = 'LISTENPREIS',
  MANUELL = 'MANUELL',
  STAFFEL = 'STAFFEL',
  PREISGRUPPE = 'PREISGRUPPE'
}

export interface VkPreisBlattXML {
  EkNichtSkonto?: number
  /** @format date */
  GueltigBis?: string
  /** @format date */
  GueltigVon?: string
  KasseReferenzPreisgruppe?: string
  Mengeneinheit?: string
  MengeneinheitI?: string
  Preismenge?: number
  VkNichtSkonto?: number
  preise?: VkPreisXML[]
}

export interface VkPreisChangeJson {
  aufschlag?: number
  basisTyp?: VkPreisBasisTyp
  preis?: number
  /** @format int64 */
  preisGruppeId?: number
  spanne?: number
  staffelmenge?: number
}

export interface VkPreisDiffJson {
  alt?: PimArtikelVkPreisJson
  neu?: PimArtikelVkPreisJson
}

export interface VkPreisGruppeJson {
  basis?: boolean
  bezeichnung?: string
  brutto?: boolean
  eh?: boolean
  fh?: boolean
  /** @format int64 */
  id?: number
  kasse?: boolean
  name?: string
  /** @format int32 */
  pimMappingNr?: number
  runden?: boolean
}

/** Struktur für den Austausch von Vk-Preisgruppen Regeln (Lieferanten Einstellungen) mit prohibis. */
export interface VkPreisGruppenRegelJSON {
  /**
   * Der prohibis Schlüssel einer Warengruppe.
   * @example "940307"
   */
  ArtikelGruppe?: string
  /** Nur zur Information? ONE ist immer in einem Stammebenen Kontext und damit einem Betriebstypen bzw. Kontext unterwegs. Eine Login Ebene die alle Stammebenen eines Gesellschafters zusammenfasst gibt es aktuell nicht (?). */
  BTyp?: string
  /**
   * Der prohibis Schlüssel eines Standorts.
   * @example "100"
   */
  Lagergruppe?: string
  /**
   * Der prohibis Schlüssel eines Lieferanten.
   * @example "970802"
   */
  Lieferant?: string
  /**
   * Der prohibis Schlüssel einer Preisgruppe / Preisliste.
   * @example "BM 3"
   */
  PreisgruppeListung?: string
}

export interface VkPreisGruppenRegelnJSON {
  VkPreisGruppenRegeln?: VkPreisGruppenRegelJSON[]
}

export interface VkPreisJson {
  aufschlag?: number
  basis?: number
  basisTyp?: VkPreisBasisTyp
  brutto?: boolean
  etikettpreis?: number
  fix?: boolean
  /** @format int64 */
  id?: number
  preis?: number
  /** @format int64 */
  preisGruppeId?: number
  spanne?: number
  staffelRabattAbs?: number
  staffelRabattProz?: number
  staffelTyp?: VkPreisStaffelTyp
  staffelmenge?: number
  /** @format int32 */
  version?: number
  /** @format int64 */
  vpeinheitId?: number
  vpmenge?: number
}

export interface VkPreisListeAbschliessenJson {
  abgeschlossen?: boolean
  eintraege?: number[]
  /** @format int64 */
  listeId?: number
  /** @format int64 */
  standortId?: number
}

export interface VkPreisListeBlattChangeJson {
  abgeschlossen?: boolean
  festpreis?: boolean
  /** @format int64 */
  kalkulationsvorschlagId?: number
  preise?: VkPreisChangeJson[]
  quelle?: VkPreisQuelle
  /** @format int64 */
  referenzPreisgruppeId?: number
  /** @format int64 */
  rundungsregelId?: number
  staffelmenge?: number
}

export interface VkPreisListeBlattJson {
  abgeschlossen?: boolean
  audit?: HKMAuditJson
  festpreis?: boolean
  frachtkosten?: VkFrachtkostenJson
  /** @format int64 */
  id?: number
  /** @format int64 */
  kalkulationsVorschlagId?: number
  preise?: VkPreisJson[]
  quelle?: VkPreisQuelle
  quelleStaffel?: VkPreisQuelle
  /** @format int64 */
  referenzPreisgruppeId?: number
  /** @format int64 */
  rundungsregelId?: number
  /** @format int64 */
  standortId?: number
  standortVk?: boolean
  /** @format int32 */
  version?: number
}

export interface VkPreisListeDisplayJson {
  abgeschlossen?: boolean
  /** @format date-time */
  angelegtUm?: string
  /** @format int64 */
  anzahlAbgeschlossen?: number
  /** @format int64 */
  anzahlArtikel?: number
  /** @format int64 */
  anzahlBlaetter?: number
  /** @format int64 */
  anzahlFreigegeben?: number
  betriebstyp?: ArtikelBetriebstyp
  forEinzelhandel?: boolean
  freigegeben?: boolean
  /** @format date-time */
  freigegebenUm?: string
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  /** @format int32 */
  kannAbschliessen?: number
  /** @format int32 */
  kannAnzeigen?: number
  /** @format int32 */
  kannBearbeiten?: number
  /** @format int32 */
  kannFreigeben?: number
  /** @format int32 */
  kannLoeschen?: number
  kontext?: NeonKontext
  /** @format date-time */
  letztePflege?: string
  lieferant?: LieferantDisplayJson
  name?: string
  /** @format int64 */
  preisEbeneId?: number
  status?: string
  typ?: VkPreisListeTyp
}

export interface VkPreisListeEintragDisplayJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  /** @format int64 */
  artikelId?: number
  btNummer?: string
  hageNummer?: string
  /** @format int64 */
  id?: number
}

export interface VkPreisListeEintragJson {
  alleEkFreiHaus?: Record<string, number>
  alleListenpreise?: Record<string, number>
  audit?: HKMAuditJson
  /** @format int64 */
  basisPreisgruppeId?: number
  blaetter?: VkPreisListeBlattJson[]
  /** @format int64 */
  defaultReferenzPreisgruppeId?: number
  ekFreiHaus?: number
  ekFreiHausEkEinheit?: number
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  /** @format int64 */
  id?: number
  listenpreis?: number
  listenpreisEkEinheit?: number
  mengenEinheit?: string
  mengenEinheitEkEinheit?: string
  /** @format int64 */
  mengenEinheitEkEinheitId?: number
  mengenEinheitEkEinheitKurz?: string
  /** @format int64 */
  mengenEinheitId?: number
  mengenEinheitKurz?: string
  mwstSatz?: number
  preismenge?: number
  preismengeEkEinheit?: number
  /** @format int32 */
  version?: number
}

export interface VkPreisListeFrachtkostenJson {
  eintraege?: number[]
  frachtkosten?: VkFrachtkostenChangeJson
  /** @format int64 */
  listeId?: number
  /** @format int64 */
  standortId?: number
}

export interface VkPreisListePreiseActionJson {
  change?: VkPreisListeBlattChangeJson
  eintraege?: number[]
  /** @format int64 */
  listeId?: number
  /** @format int64 */
  standortId?: number
}

export interface VkPreisListeSearcherCriteriaJson {
  abgeschlossene?: boolean
  /** @format date */
  gueltigBis?: string
  /** @format date */
  gueltigVon?: string
  lieferant?: LieferantDisplayJson
  name?: string
  preisListenIds?: number[]
}

export interface VkPreisListeSearcherJson {
  criteria?: VkPreisListeSearcherCriteriaJson
  limited?: boolean
  result?: VkPreisListeDisplayJson[]
}

export enum VkPreisListeTyp {
  EK_PREISLISTE = 'EK_PREISLISTE',
  MANUELL = 'MANUELL'
}

export enum VkPreisQuelle {
  LISTUNG = 'LISTUNG',
  EIGENPFLEGE = 'EIGENPFLEGE',
  NEUKALKULATION = 'NEUKALKULATION',
  TEMPORAER = 'TEMPORAER'
}

export enum VkPreisStaffelTyp {
  ABSOLUT = 'ABSOLUT',
  RABATT_ABS = 'RABATT_ABS',
  RABATT_PROZ = 'RABATT_PROZ',
  RABATT_PROZ_VERD = 'RABATT_PROZ_VERD'
}

export interface VkPreisXML {
  IsBrutto?: boolean
  Preis?: number
  Preisgruppe?: string
  StaffelTyp?: VkPreisStaffelTyp
  Staffelmenge?: number
  Vpeinheit?: string
  Vpmenge?: number
}

export interface VkPreiseActionResultJson {
  artikelListe?: VkPreiseMasseBearbeitenEintragJson[]
  /** @format int64 */
  listeId?: number
  state?: UseCaseStateJson
}

export interface VkPreiseBearbeitenJson {
  actionFreigeben?: ActionInfoJson
  altePreise?: ArtikelVkPreiseJson
  artikel?: ArtikelDisplayJson
  bemerkung?: string
  error?: InfoJson
  forEinzelhandel?: boolean
  freigegeben?: boolean
  /** @format date-time */
  freigegebenUm?: string
  lieferant?: LieferantDisplayJson
  listeDisplay?: VkPreisListeDisplayJson
  mengeneinheiten?: MengeneinheitJson[]
  name?: string
  neueKondi?: VkPreisListeEintragJson
  /** @format int64 */
  preislisteId?: number
  state?: UseCaseStateJson
  zufuhrMultis?: ZufuhrMultiDisplayJson[]
}

export interface VkPreiseBearbeitenKopfJson {
  artikelListe?: VkPreisListeEintragDisplayJson[]
  /** @format int64 */
  id?: number
  kalkulationsvorschlaege?: VkKalkulationsvorschlagJson[]
  preisgruppen?: VkPreisGruppeJson[]
  rundungsregeln?: VkRundungsregelJson[]
  standorte?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
}

export interface VkPreiseMasseBearbeitenEintragJson {
  abgeschlossen?: boolean
  artikel?: ArtikelDisplaySmallJson
  einh?: string
  einhKurz?: string
  error?: InfoJson
  frachtkosten?: VkFrachtkostenJson
  /** @format int64 */
  id?: number
  kalkEK?: number
  kalkulationsvorschlag?: string
  lp?: number
  neu?: boolean
  pgs?: VkPreiseMasseBearbeitenPreisGruppeJson[]
  /** @format int64 */
  referenzPreisgruppeId?: number
  standort?: string
  /** @format int64 */
  vkPreisListeEintragId?: number
}

export interface VkPreiseMasseBearbeitenKopfJson {
  actionAbschliessen?: ActionInfoJson
  actionFreigeben?: ActionInfoJson
  artikelListe?: VkPreiseMasseBearbeitenEintragJson[]
  /** @format int64 */
  basisPreisgruppeId?: number
  bemerkung?: string
  betriebsTyp?: ArtikelBetriebstyp
  forEinzelhandel?: boolean
  /** @format date-time */
  freigegebenUm?: string
  freigegegen?: boolean
  /** @format int64 */
  id?: number
  kalkulationsvorschlaege?: VkKalkulationsvorschlagJson[]
  kontext?: NeonKontext
  name?: string
  /** @format int64 */
  preisEbeneId?: number
  preisgruppen?: VkPreisGruppeJson[]
  rundungsregeln?: VkRundungsregelJson[]
  standorte?: PreisEbeneDisplayJson[]
  state?: UseCaseStateJson
  zufuhrMultis?: ZufuhrMultiDisplayJson[]
}

export interface VkPreiseMasseBearbeitenPreisGruppeJson {
  aufschlag?: number
  basisTyp?: VkPreisBasisTyp
  delta?: number
  /** @format int64 */
  id?: number
  oaufschlag?: number
  obasisTyp?: VkPreisBasisTyp
  opreis?: number
  ospanne?: number
  preis?: number
  spanne?: number
}

export interface VkPreisgruppeRegelJson {
  audit?: HKMAuditJson
  /** @format int64 */
  id?: number
  lieferant?: LieferantDisplayTinyJson
  /** @format int64 */
  preisgruppeId?: number
  /** @format int32 */
  version?: number
  warengruppe?: WarengruppeDisplayJson
}

export interface VkPreisgruppeRegelKopfJson {
  audit?: HKMAuditJson
  /** @format int64 */
  defaultEinzelhandelPreisgruppeId?: number
  /** @format int64 */
  id?: number
  regeln?: VkPreisgruppeRegelJson[]
  /** @format int32 */
  version?: number
}

export interface VkPreisgruppeRegelnBearbeitenJson {
  handelTyp?: HandelTyp
  kopf?: VkPreisgruppeRegelKopfJson
  preisgruppen?: VkPreisGruppeJson[]
  standort?: StandortJson
  standortgruppe?: StandortGruppeDisplayJson
  state?: UseCaseStateJson
}

export interface VkPreispflegeUebersichtJson {
  searcher?: VkPreisListeSearcherJson
  state?: UseCaseStateJson
}

export interface VkRundungsregelJson {
  /** @format int32 */
  ganzeCent?: number
  /** @format int64 */
  id?: number
  name?: string
}

export interface WarengruppeDisplayJson {
  /** @format int64 */
  anzahlArtikel?: number
  deleted?: boolean
  /** @format int64 */
  id?: number
  name?: string
  nummer?: string
  /** @format int64 */
  seId?: number
  suchbegriff?: string
  /** @format int64 */
  zeId?: number
}

export interface WarengruppeSearcherCriteriaJson {
  dezentral?: boolean
  listung?: boolean
  name?: string
  nummer?: string
}

export interface WarengruppeSearcherJson {
  criteria?: WarengruppeSearcherCriteriaJson
  limited?: boolean
  result?: WarengruppeDisplayJson[]
}

export interface WarengruppeUebersichtJson {
  searcher?: WarengruppeSearcherJson
  state?: UseCaseStateJson
}

export interface WarengruppenListeJson {
  sparten?: SparteDisplayJson[]
  state?: UseCaseStateJson
  warengruppen?: WarengruppeDisplayJson[]
}

export interface ZARTArtikel {
  artikel: ZARTArtikelARTIKEL[]
  datum?: string
  vorlauf?: string
}

export interface ZARTArtikelARTIKEL {
  ggghs01?: string
  ggghs02?: string
  ggghs03?: string
  ggghs04?: string
  ggghs05?: string
  ggghs06?: string
  ggghs07?: string
  ggghs08?: string
  ggghs09?: string
  alter?: string
  artikelart?: ZARTArtikelARTIKELArtikelart
  basisWG: ZARTArtikelARTIKELBasisWG
  bausteine?: ZARTArtikelARTIKELBausteine
  btartikelnr?: string
  btyp?: string
  defEinheit?: string
  dnp?: string
  ean?: string
  eck?: string
  eigenmarke?: string
  ggabfallschluessel?: string
  ggadrnr?: string
  ggbenennungADR?: string
  ggbiozidRelevant?: string
  ggclprelevant?: string
  ggfaktor?: string
  ggfrostgefaehrdet?: string
  gggefahrgut?: string
  gggefahrgutLQ?: string
  gggseinstufung?: string
  ggklasse?: string
  gglagerklasse?: string
  ggliIonAkku?: string
  ggmenge?: string
  ggpflSchutzmittel?: string
  ggsignalwort?: string
  ggtunnelcode?: string
  ggumweltGefKlasse?: string
  ggumweltgefaehrdend?: string
  ggunnummer?: string
  ggverpackungsart?: string
  ggvpgruppe?: string
  ggwgkklasse?: string
  gruppeARTIKEL?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
  gueltigAb?: string
  hagenr?: string
  kzrekla?: string
  lieferant: ZARTArtikelARTIKELLieferant[]
  ltexte?: ZARTArtikelARTIKELLTexte
  masse: ZARTArtikelARTIKELMASSE
  online?: string
  pfandartikel?: ZARTArtikelARTIKELPfandartikel
  plu?: string
  pricing?: string
  pricingdatum?: string
  sdbrelevant?: string
  ursprungsland?: string
  ursprungsregion?: string
  vkpreise?: ZARTArtikelARTIKELVKPreise
  vksperre?: string
  zusatzEAN?: ZARTArtikelARTIKELZusatzEAN
}

export interface ZARTArtikelARTIKELArtikelart {
  artikelartnr?: string
  artikelnr?: ZARTArtikelARTIKELArtikelartArtikelnr
  leihartikel?: ZARTArtikelARTIKELArtikelartLeihartikel[]
}

export interface ZARTArtikelARTIKELArtikelartArtikelnr {
  gruppeArtikelnr?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTArtikelARTIKELArtikelartLeihartikel {
  kaution?: string
  leiheinheit?: string
  leihstaffelmenge?: string
  leihstaffelpreis?: string
}

export interface ZARTArtikelARTIKELBasisWG {
  btwg?: string
  hagebauBWG?: string
  mhd?: string
}

export interface ZARTArtikelARTIKELBausteine {
  gruppeBausteine?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTArtikelARTIKELLTexte {
  lbez1?: string
  lbez1Ph?: string
  lbez2?: string
  lbez2Ph?: string
  lbez3Ph?: string
  lbez4Ph?: string
  bontext?: string
  matchcode?: string
  plakat: ZARTArtikelARTIKELLTextePlakat
  textart: ZARTArtikelARTIKELLTexteTextart
}

export interface ZARTArtikelARTIKELLTextePlakat {
  plakatNr1?: string
  plakatNr2?: string
  plakatNr3?: string
  plakatNr4?: string
  plakatNr5?: string
  plakatNr6?: string
  plakatNr7?: string
  plakatNr8?: string
  ptext1?: string
  ptext2?: string
  ptext3?: string
  ptext4?: string
  ptext5?: string
  ptext6?: string
  ptext7?: string
  ptext8?: string
  pzusatztext1?: string
  pzusatztext2?: string
  pzusatztext3?: string
  pzusatztext4?: string
  pzusatztext5?: string
  pzusatztext6?: string
  pzusatztext7?: string
  pzusatztext8?: string
  plakatLayout?: string
}

export interface ZARTArtikelARTIKELLTexteTextart {
  gruppeTextart?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTArtikelARTIKELLieferant {
  lief: ZARTArtikelARTIKELLieferantLief
  liefILN?: string
  liefNr?: string
}

export interface ZARTArtikelARTIKELLieferantLief {
  ausinfo2?: string
  aenderungsdatum?: string
  angebotstext?: string
  anlagedatum?: string
  basisartnr?: string
  baustein?: ZARTArtikelARTIKELLieferantLiefBaustein[]
  bilder?: ZARTArtikelARTIKELLieferantLiefBilder
  eigenmarkentyp?: string
  eigenschaften: ZARTArtikelARTIKELLieferantLiefEigenschaften
  einkaeufergruppe?: string
  ekpreis?: ZARTArtikelARTIKELLieferantLiefEKPREIS
  gefKommLink?: string
  handhabung?: ZARTArtikelARTIKELLieferantLiefHandhabung
  hl?: string
  ian?: string
  ktyp?: string
  lieferzeit?: string
  mbestellmenge?: string
  pgbez?: string
  pglief?: string
  sortbmnr?: string
  staffeln: ZARTArtikelARTIKELLieferantLiefStaffeln
  status: ZARTArtikelARTIKELLieferantLiefStatus
  texte: ZARTArtikelARTIKELLieferantLiefTexte
  wgbdb?: string
  wgccg?: string
  wglief?: string
}

export interface ZARTArtikelARTIKELLieferantLiefBaustein {
  baumarktgroesse1?: string
  baumarktgroesse2?: string
  baumarktgroesse3?: string
  baumarktgroesse4?: string
  fachmarktgroesse1?: string
  fachmarktgroesse2?: string
  floralandgroesse1?: string
  floralandgroesse2?: string
  floralandgroesse3?: string
  anzahlFrontstuecke?: string
  bausteinbezeichnung?: string
  bausteinnummer?: string
  erstmenge?: string
  etikettentyp?: string
  position?: string
  raster?: string
  regalID?: string
  regalsystem?: string
  status?: string
}

export interface ZARTArtikelARTIKELLieferantLiefBilder {
  gruppeBilder?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTArtikelARTIKELLieferantLiefEKPREIS {
  ekbrutto?: string
  ekfreiHaus?: string
  ekgueltigab?: string
  ekpreisbasis?: string
  ekpreiseinheit?: string
  ekrabatt?: string
  ekrabattgruppe?: string
}

export interface ZARTArtikelARTIKELLieferantLiefEigenschaften {
  abbriebgruppe?: string
  besonderheiten?: string
  breite?: string
  ebme?: string
  elme?: string
  farbe?: string
  fliesen?: ZARTArtikelARTIKELLieferantLiefEigenschaftenFliesen
  frostsicher?: string
  gewicht?: string
  herkunftsland?: string
  hoehe?: string
  igaartNr?: string
  kategorie?: string
  ladungstraeger?: ZARTArtikelARTIKELLieferantLiefEigenschaftenLadungstraeger
  laenge?: string
  material?: string
  nassbereich?: string
  oberflaeche?: string
  rutschfestigkeit?: string
  seriennameIGA?: string
  seriennameLieferant?: string
  verbrauchseinheiten?: string
  verdraengungsraum?: string
  verpackung?: ZARTArtikelARTIKELLieferantLiefEigenschaftenVerpackung
  verpackungsart?: string
  volumen?: string
  zmasse?: ZARTArtikelARTIKELLieferantLiefEigenschaftenZMasse
  zolltarifnummer?: string
}

export interface ZARTArtikelARTIKELLieferantLiefEigenschaftenFliesen {
  beansprgr?: string
  frost?: string
  sort?: string
}

export interface ZARTArtikelARTIKELLieferantLiefEigenschaftenLadungstraeger {
  ltbreite?: string
  ltgewicht?: string
  lthoehe?: string
  ltlaenge?: string
  ltmasseinheit?: string
  ltstapelfaktor?: string
  ltvolumen?: string
  ltvpe?: string
  ltvpeean?: string
  ltvpemenge?: string
}

export interface ZARTArtikelARTIKELLieferantLiefEigenschaftenVerpackung {
  vpbreite?: string
  vpgewicht?: string
  vphoehe?: string
  vplaenge?: string
  vpmasseinheit?: string
  vpvolumen?: string
  vpvpe?: string
  vpvpeean?: string
  vpvpemenge?: string
}

export interface ZARTArtikelARTIKELLieferantLiefEigenschaftenZMasse {
  gruppeZMasse?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTArtikelARTIKELLieferantLiefHandhabung {
  code?: string[]
}

export interface ZARTArtikelARTIKELLieferantLiefStaffeln {
  gruppeStaffeln?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTArtikelARTIKELLieferantLiefStatus {
  ausgelistet?: string
  geloescht?: string
  lieferantGesperrt?: string
}

export interface ZARTArtikelARTIKELLieferantLiefTexte {
  bezeichnung1?: string
  bezeichnung2?: string
  bezeichnung3?: string
  herstellerKuerzel?: string
  identMerkmale?: string
  markenname?: string
  produktgruppe?: string
}

export interface ZARTArtikelARTIKELMASSE {
  vlbreite?: string
  vlgewicht?: string
  vlhoehe?: string
  vllaenge?: string
  vlpaketinhalt?: string
  vlpaketinhaltEinheit?: string
  vlpaketinhaltMenge?: string
  vlpaketinhaltMengeEinheit?: string
}

export interface ZARTArtikelARTIKELPfandartikel {
  pfBTArtikelnr?: string
  pfEAN?: string
  pfPfandart?: string
}

export interface ZARTArtikelARTIKELVKPreise {
  vkpg1?: string
  vkpg2?: string
  vkpg3?: string
  vkpg4?: string
  vkpg5?: string
  vkpg6?: string
  empfVK?: string
  grundpreis?: ZARTArtikelARTIKELVKPreiseGrundpreis
  rabatt?: string
  skonto?: string
  steuer?: string
  vkpreisbasis?: string
  vkpreiseinheit?: string
  vkstaffeln?: object
  waehrung?: string
}

export interface ZARTArtikelARTIKELVKPreiseGrundpreis {
  gpfaktor?: string
  grundpreiseinheit?: string
}

export interface ZARTArtikelARTIKELZusatzEAN {
  gruppeZusatzEAN?: {
    globalScope?: boolean
    name?: {
      localPart?: string
      namespaceURI?: string
      prefix?: string
    }
    nil?: boolean
    typeSubstituted?: boolean
    value?: string
  }[]
}

export interface ZARTLieferantenstamm {
  lieferantenstammsatz: ZARTLieferantenstammLieferantenstammsatz[]
  vorlauf?: string
}

export interface ZARTLieferantenstammLieferantenstammsatz {
  abholadresse?: ZARTLieferantenstammLieferantenstammsatzAbholadresse[]
  adresse: ZARTLieferantenstammLieferantenstammsatzAdresse
  aenderungsdatum?: string
  anlagedatum?: string
  buchungssperre?: string
  edidsp?: string
  editest?: string
  editestfrom?: string
  einkaufssperre?: string
  finanzdaten: ZARTLieferantenstammLieferantenstammsatzFinanzdaten
  geloescht?: string
  iln?: string
  kombityp: ZARTLieferantenstammLieferantenstammsatzKombityp[]
  kommunikation: ZARTLieferantenstammLieferantenstammsatzKommunikation
  kontonummer?: string
  portal: ZARTLieferantenstammLieferantenstammsatzPortal[]
  sperrkennzeichen?: string
  suchbegriff?: string
  zahlungsziel?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzAbholadresse {
  adresse: ZARTLieferantenstammLieferantenstammsatzAbholadresseAdresse
  ilnaa?: string
  kommunikation: ZARTLieferantenstammLieferantenstammsatzAbholadresseKommunikation
}

export interface ZARTLieferantenstammLieferantenstammsatzAbholadresseAdresse {
  name1AA?: string
  name2AA?: string
  anredeAA?: string
  landAA?: string
  ortAA?: string
  plzaa?: string
  postLZAA?: string
  postfachAA?: string
  strasseAA?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzAbholadresseKommunikation {
  emailAA?: string
  mobiltelefonAA?: string
  telefaxAA?: string
  telefonAA?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzAdresse {
  name1?: string
  name2?: string
  name3?: string
  anrede?: string
  branchen: ZARTLieferantenstammLieferantenstammsatzAdresseBranchen
  co?: string
  land?: string
  ort?: string
  ortsteil?: string
  plz?: string
  postLZ?: string
  postfach?: string
  strasse?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzAdresseBranchen {
  branche?: string[]
}

export interface ZARTLieferantenstammLieferantenstammsatzFinanzdaten {
  ersatzkonto?: string
  fheinheit?: string
  frachtzuschlag?: string
  freihausgrenze?: string
  freihausmenge?: string
  lieferbedingung?: string
  mindestbestelleinheit?: string
  mindestbestellmenge?: string
  mindestbestellwert?: string
  mzuschlagEinheit?: string
  mzuschlagMenge?: string
  planzeit?: string
  ustIDNummer?: string
  verband?: string
  waehrung?: string
  wfreihauseinheit?: string
  wfreihausgrenze?: string
  wfreihausmenge?: string
  wlieferbedingung?: string
  wmindestbestelleinheit?: string
  wmindestbestellmenge?: string
  wmindestbestellwert?: string
  wmzuschlag?: string
  wmzuschlagEinheit?: string
  wmzuschlagMenge?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzKombityp {
  kart?: string
  kmenge?: string
  kname?: string
  krabatt?: string
  ktyp?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzKommunikation {
  email?: string
  homepage?: string
  mobiltelefon?: string
  telefax?: string
  telefon?: string
}

export interface ZARTLieferantenstammLieferantenstammsatzPortal {
  portalID?: string
  portalTXT?: string
  wert?: string
}

export interface ZartMappingBearbeitenJson {
  state?: UseCaseStateJson
  zartMapping?: ZartMappingJson
}

export interface ZartMappingItemJson {
  pim?: string
  typ?: PimMappingZartTyp
  zart?: string
}

export interface ZartMappingJson {
  mappings?: ZartMappingItemJson[]
}

export interface ZeArtikelAnzeigenJson {
  artikel?: ZeArtikelJson
  artikelLieferanten?: ArtikelLieferantJson[]
  display?: ArtikelDisplayTinyJson
  ekPreise?: PimArtikelLieferantJson[]
  importErrorStatus?: ImportErrorStatusJson
  /** @format int64 */
  partnerArtikelId?: number
  partnerArtikelQuelle?: ArtikelQuelle
  /** @format int64 */
  revision?: number
  revisions?: AuditRevisionInfoJson[]
  state?: UseCaseStateJson
  vkPreise?: PimArtikelVkPreisJson[]
}

export interface ZeArtikelJson {
  bez1?: string
  bez2?: string
  bez3?: string
  bez4?: string
  akt?: boolean
  artikelArt?: ArtikelArtDisplayJson
  berechnungsME?: MengeneinheitJson
  betriebsTyp?: ArtikelBetriebstyp
  bontext?: string
  breite?: number
  btNummer?: string
  btWarengruppe?: string
  dnp?: boolean
  eans?: ArtikelEANJson[]
  eckpreis?: boolean
  einkaufME?: MengeneinheitJson
  gewicht?: number
  /** @format date */
  gueltigAb?: string
  hageNummer?: string
  hageWarengruppe?: WarengruppeDisplayJson
  hageWarengruppeNummer?: string
  hoehe?: number
  /** @format int64 */
  id?: number
  laenge?: number
  lieferME?: MengeneinheitJson
  matchcode?: string
  mengeneinheiten?: ArtikelMengeneinheitJson[]
  mhd?: boolean
  online?: boolean
  pfandBTArtikelNr?: string
  vertriebsWeg?: ArtikelVertriebsWeg
  vlPaketinhalt?: number
  vlPaketinhaltEinheit?: string
  /** @format int32 */
  vlPaketinhaltMenge?: number
  vlPaketinhaltMengeEinheit?: string
  zartArtikelArt?: string
  zusatzEans?: string[]
}

export interface ZeLieferantAnzeigenJson {
  /** @format int64 */
  dzLieferantId?: number
  lieferant?: ZeLieferantJson
  revisions?: AuditRevisionInfoJson[]
  state?: UseCaseStateJson
}

export interface ZeLieferantJson {
  abholAdresse?: LieferantAdresseJson
  audit?: HKMAuditJson
  branche?: string
  client?: string
  edi?: boolean
  /** @format int32 */
  ediDsp?: number
  eigenlistung?: boolean
  fheinheit?: string
  frachtzuschlag?: number
  /** @format int32 */
  freihausgrenze?: number
  /** @format int32 */
  freihausmenge?: number
  geloescht?: boolean
  gesperrt?: boolean
  gruppe?: string
  handelsCode?: string
  hauptAdresse?: LieferantAdresseJson
  /** @format int64 */
  id?: number
  iln?: string
  lieferantArt?: LieferantArt
  lieferbedingung?: string
  mindestBestellwert?: number
  nummer?: string
  /** @format int32 */
  planzeit?: number
  sprache?: string
  suchbegriff?: string
  typ?: SuchLieferantTyp
  ustIDNummer?: string
  /** @format int32 */
  version?: number
  vorlauf?: string
  waehrung?: string
  zahlungsZiel?: string
}

export interface ZufuhrMultiDisplayJson {
  bez?: string
  /** @format int64 */
  id?: number
  name?: string
}
