import { api } from '@one/api'
import {
  ArtikelDisplayJson,
  ArtikelSearcherArtikelCriteriaJson,
  LieferantDisplayJson
} from '@one/typings/apiTypings'
import { useSearcherState } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { arrayFromSet, singleFromSet } from '@utils/utils'
import { useContext, useMemo, useState } from 'react'
import { useArtikelColumns } from './ArtikelColumn'
import { ArtikelUebersichtParams } from './ArtikelUebersicht/ArtikelUebersichtParams'

export interface ArtikelSearchDialogProps {
  open: boolean
  onClose: (selected?: any | any[]) => void
  multiselect?: boolean
  defaultDezentral?: boolean
  disableListung?: boolean
  disableDezentral?: boolean
  disableArchiviert?: boolean
  mitLieferant?: LieferantDisplayJson
}

const height = { minHeight: '220px', padding: 18 }

export const ArtikelSearchDialog = ({
  onClose,
  defaultDezentral = null,
  open = false,
  multiselect = false,
  disableListung = false,
  disableDezentral = false,
  disableArchiviert = false, // Für Test praktisch...
  mitLieferant = null
}: ArtikelSearchDialogProps) => {
  const { isGesellschafter, isAllianzMitglied, isPIMModel } = useContext(AppContext)

  const zentralName = isAllianzMitglied ? 'forAbo' : isPIMModel ? 'pim' : 'listung'

  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    ArtikelSearcherArtikelCriteriaJson,
    ArtikelDisplayJson
  >({
    api,
    url: '/artikel',
    initialParams: {
      lieferant: mitLieferant,
      [zentralName]: defaultDezentral == null ? null : !defaultDezentral,
      datenpool: defaultDezentral == null ? null : !defaultDezentral,
      dezentral: defaultDezentral
    }
  })

  const [selected, setSelected] = useState(new Set())

  const handleAuswahl = () => {
    onClose(multiselect ? arrayFromSet(selected) : singleFromSet(selected))
  }

  const handleCancelFilter = () => {
    onClose()
  }

  const onRowDoubleClick = (row: any, e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    onClose(row)
  }

  const columnProps = useMemo(
    () => ({
      isGesellschafter,
      addColumnToIndex: [
        {
          beforeField: 'industrieArtikelNummer',
          column: {
            field: 'matchcode',
            header: 'Suchbegriff',
            sortable: true
          }
        }
      ]
    }),
    [isGesellschafter]
  )

  const columns = useArtikelColumns(columnProps)

  const actions = (
    <>
      <Button
        label="Auswählen"
        onClick={handleAuswahl}
        color="primary"
        disabled={selected.size === 0}
      />
      <CancelButton onClick={handleCancelFilter} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={handleCancelFilter}
      maxWidth="xl"
      title="Artikel suchen"
      actions={actions}
      fullWidth
      height="900px"
    >
      <Frame space>
        <FrameRow>
          <ArtikelUebersichtParams
            value={criteria}
            onChange={onCriteriaChange}
            zentralName={zentralName}
            onSearch={search}
            disableListung={disableListung}
            disableDezentral={disableDezentral}
            disableArchiviert={disableArchiviert}
            disabledLieferant={mitLieferant != null}
            borderless
          />
        </FrameRow>
        <FrameBody style={height}>
          <DataTableCard
            name="ArtikelSearchTable"
            columns={columns}
            selectMode={multiselect ? 'multi' : 'single'}
            selectCol
            onSelect={setSelected}
            dense
            onRowDoubleClick={onRowDoubleClick}
            result={result}
            // nopadding
            // borderless
            filterMode="global"
          />
        </FrameBody>
      </Frame>
    </DialogEx>
  )
}
