import { debugLog } from '@utils/logging'
import { useImperativeHandle, useLayoutEffect, useRef } from 'react'

export type ShortCutHandlerProps = {
  shortcuts: Record<string, (e: KeyboardEvent) => void>
  children: React.ReactNode
}

type ShortCutHandlerApi = {
  onKey: (e: KeyboardEvent) => void
}

const add = (condition: boolean, prefix: string) => (condition ? prefix : '')

export const ShortCutHandler = ({ shortcuts, children }: ShortCutHandlerProps) => {
  const domRef = useRef<HTMLSpanElement>()

  const mref = useRef<ShortCutHandlerApi>()

  useImperativeHandle(
    mref,
    () => ({
      onKey: (event: KeyboardEvent) => {
        const keyCode = add(event.altKey, 'alt+') + add(event.ctrlKey, 'ctrl+') + event.key
        const shortcut = shortcuts[keyCode]
        if (shortcut) {
          debugLog('shortcut', shortcut)
          event.preventDefault()
          event.stopPropagation()
          shortcut(event)
        }
      }
    }),
    [shortcuts]
  )

  useLayoutEffect(() => {
    const handler = (event: any) => {
      if (!event.repeat && domRef.current?.contains(event.target)) {
        mref.current.onKey(event)
      }
    }

    window.document.addEventListener('keydown', handler, false)
    return () => window.document.removeEventListener('keydown', handler, false)
  }, [])

  return <span ref={domRef}>{children}</span>
}
