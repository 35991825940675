import { Grid } from '@mui/material'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { VkPreisGruppeJson, VkPreisgruppeRegelJson } from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { useCallback, useMemo } from 'react'

export interface VkPreisgruppeRegelViewDialogProps {
  onClose: (rule?: VkPreisgruppeRegelJson) => void
  value?: VkPreisgruppeRegelJson
  open?: boolean
  preisGruppen: VkPreisGruppeJson[]
}

export const VkPreisgruppeRegelViewDialog = ({
  onClose,
  value,
  open,
  preisGruppen
}: VkPreisgruppeRegelViewDialogProps) => {
  const [rule, onChangeRule] = useFormState(value || ({} as VkPreisgruppeRegelJson))

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  const actions = useMemo(
    () => (
      <>
        <ApplyButton
          onClick={() => onClose(rule)}
          disabled={!rule.preisgruppeId || !rule.lieferant}
        />
        <CancelButton onClick={handleCancel} />
      </>
    ),
    [handleCancel, onClose, rule]
  )

  return (
    <DialogEx
      title={`VK-Preisgruppen Regel ${value ? 'bearbeiten' : 'anlegen'}`}
      open={open}
      onClose={handleCancel}
      actions={actions}
      fullWidth
      maxWidth="md"
      height="400px"
    >
      <Grid container spacing={2} direction="row">
        <Grid item sm={10}>
          <LieferantField
            name="lieferant"
            label="Lieferant ERP"
            value={rule.lieferant}
            onChange={onChangeRule}
            fullWidth
            modus="dezentral"
            required
          />
        </Grid>
        <Grid item sm={10}>
          <WarengruppeField
            value={rule.warengruppe}
            label="Warengruppe"
            name="warengruppe"
            onChange={onChangeRule}
            fullWidth
            gfMitStatistik
            emptyText="Alle"
            disabled={rule.lieferant == null}
          />
        </Grid>
        <Grid item sm={10}>
          <AutocompleteEx
            fullWidth
            label="Preisgruppe"
            name="preisgruppeId"
            value={rule.preisgruppeId}
            options={preisGruppen}
            onChange={onChangeRule}
            optionValue="id"
            optionLabel={(i: VkPreisGruppeJson) => `${i.name} (${i.bezeichnung})`}
            required
          />
        </Grid>
      </Grid>
    </DialogEx>
  )
}
