/* eslint-disable no-console */
import { SetStateAction, useCallback, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { notifyObservers, useObserver } from './observer'
import { useStateEx } from './stateex'
import { dataFromEvent, isFunction } from './utils'

/**
 * Ein State, nutzbar wie useState.
 * Der State wird aber zusätzlich persistent im localStorage abgelegt
 *
 * @param storageItemName Name im localStorage, wenn null, keine Ablage im localStorage
 * @param initialValue initialer Wert
 * @param validate Optionales prüfen und anpassen der Daten aus dem localStorage. Bei Fehler ggf Exception werfen
 * @param validateCtx Optionaler Hinweis für validate, der validate auch nachträglich triggert
 * @returns State und Funktionen zum manipulieren
 */
export const useLocalState = <T extends unknown>(
  storageItemName: string | null,
  initialValue: T | null,
  validate: (data: any, validateCtx: any, init: T) => T | null = (data) => data,
  validateCtx: any = null
): [T, (state: T) => void, (event: any) => void] => {
  const initialValueRef = useRef<T>()
  initialValueRef.current = initialValue

  const init = useCallback(() => {
    const val = storageItemName == null ? null : localStorage.getItem(storageItemName)
    if (val != null) {
      try {
        let data = JSON.parse(val)
        if (data != null) {
          return validate(data, validateCtx, initialValueRef.current)
        }
      } catch (e) {
        console.error('Failed to parse local state', val, e)
      }
    }
    return initialValueRef.current
  }, [storageItemName, validate, validateCtx])

  const [state, setStateInt, getStateInt] = useStateEx<T>(init)

  useEffect(() => {
    if (validateCtx) {
      const s = getStateInt()
      const copy = validate(s, validateCtx, initialValueRef.current)
      if (!isEqual(copy, s)) {
        setStateInt(copy)
      }
    }
  }, [getStateInt, setStateInt, validate, validateCtx])

  const observerId = useObserver('--localStorage', (event) => {
    if (event.data?.name === storageItemName) {
      setStateInt(event.data.val)
    }
  })

  const setState = useCallback(
    (val: SetStateAction<T>) => {
      setStateInt((old) => {
        // @ts-ignore
        const next = isFunction(val) ? val(old) : val
        if (storageItemName) {
          const val = JSON.stringify(next)
          localStorage.setItem(storageItemName, val)
          notifyObservers({
            name: '--localStorage',
            origin: observerId,
            data: { name: storageItemName, val: next }
          })
        }
        return next
      })
    },
    [observerId, setStateInt, storageItemName]
  )

  /**
   * Change-Function für onChange. Arbeit mit und ohne "name". Ohne name wird der Wert direkt als State genutzt
   */
  const onChange = useCallback(
    (e) => {
      const { name, value } = dataFromEvent(e)
      if (name) {
        // @ts-ignore
        setState((old) => ({ ...old, [name]: value }))
      } else {
        setState(value)
      }
    },
    [setState]
  )

  return [state, setState, onChange]
}
