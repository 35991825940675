/* eslint-disable no-plusplus */
import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { VerteilungsZielSel } from '@one/components/common/VerteilungsZielSel'
import { ErrorsType } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantenVerteilerFormProps {
  model: any
  errors: ErrorsType
  onChange: (e: any) => void
  isNew?: boolean
}

export const LieferantenVerteilerForm = ({
  model,
  errors,
  onChange,
  isNew = false
}: LieferantenVerteilerFormProps) => {
  return (
    <CardEx
      backLink
      overviewLink={AppPaths.lieferantenverteiler.LieferantenVerteilerUebersicht}
      title={isNew ? 'Lieferantenverteiler erstellen' : `Lieferantenverteiler ${model.name || ''}`}
    >
      <Grid container spacing={2} direction="column">
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              name="name"
              label="Name"
              required
              error={errors.name}
              value={model.name}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Checkbox
              name="aktiv"
              // autoOk
              label="Verteiler aktivieren"
              checked={model.aktiv}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <VerteilungsZielSel
              label="Verteilung an"
              fullWidth
              required
              name="verteilungsZiele"
              error={errors.verteilungsZiele}
              value={model.verteilungsZiele}
              options={model.verteilungsZielList}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
