import { api } from '@one/api'
import {
  GesellschafterDisplayJson,
  GesellschafterSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { GesellschafterUebersichtParams } from './GesellschafterUebersichtParams'
import { GesellschafterUebersichtTable } from './GesellschafterUebersichtTable'

export const GesellschafterUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    GesellschafterSearcherCriteriaJson,
    GesellschafterDisplayJson
  >({
    api,
    url: '/gesellschafter'
  })

  return (
    <Frame space>
      <FrameRow>
        <GesellschafterUebersichtParams
          onSearch={search}
          value={criteria}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <GesellschafterUebersichtTable result={result} reload={search} />
      </FrameBody>
    </Frame>
  )
}
