import { createContext } from 'react'

export type CheckUserRoleFn = (userRole: string | string[]) => boolean
export interface AppContextState {
  checkUserRole: CheckUserRoleFn
  session: any
  appTitle: string
  appTitleShort: string
  appVersion: string
  isGesellschafter: boolean
  isInterCompany: boolean
  isAllianz: boolean
  isWithoutErp: boolean
  isWithErpImport: boolean
  isWithEkPreis: boolean
  isWithVkPreis: boolean
  isAllianzMitglied: boolean
  isDatapoolNEON: boolean
  isPIMModel: boolean
  isFachhandel: boolean
  isEinzelhandel: boolean
  isSortimente: boolean
  isExternesERP: boolean
}

export const AppContext = createContext<AppContextState>(null)
