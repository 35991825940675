import { DomainOutlined, StoreOutlined } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { AllianzAnlageDialog } from '@one/components/admin/Allianz/AllianzAnlageDialog'
import { GesellschafterBetriebstypenDialog } from '@one/components/admin/Gesellschafter/Betriebstypen/GesellschafterBetriebstypenDialog'
import { HkmEnum } from '@one/enums/HkmEnum'
import { GesellschafterDisplayJson, GesellschafterTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Permission } from '@utils/ui/Permission'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { Medal } from '@utils/ui/fields/Medal'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export interface AllianzGesellschafterTableProps {
  result: SearcherResultType<GesellschafterDisplayJson>
  reload: () => void
}

export const GesellschafterUebersichtTable = ({
  result,
  reload
}: AllianzGesellschafterTableProps) => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const { et } = useEnums()
  const navigate = useNavigate()

  const columns = useMemo<Column<GesellschafterDisplayJson>[]>(
    () => [
      {
        header: 'Gesellschafternr.',
        field: 'gln',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'ERP Firmennr.',
        field: 'nr',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'Gelöscht?',
        field: 'geloescht',
        type: 'boolean',
        hideIfEmpty: true,
        align: 'center',
        width: '1em'
      },
      {
        header: 'Typ',
        field: 'typ',
        width: '1em',
        align: 'center',
        sortable: true,
        valueGetter: (row) => et(HkmEnum.GesellschafterTyp, row.typ),
        body: (row) =>
          (row.typ === GesellschafterTyp.FIRMA && (
            <TooltipEx title="Firma">
              <StoreOutlined fontSize="small" />
            </TooltipEx>
          )) ||
          (row.typ === GesellschafterTyp.ORGANISATION && (
            <TooltipEx title="Organisation">
              <DomainOutlined fontSize="small" />
            </TooltipEx>
          )) || <></>
      },
      {
        header: 'Allianzstatus',
        field: 'allianz',
        width: '1em',
        sortable: true,
        valueGetter: (row) =>
          [row.allianz && 'Allianz', row.allianzMitglied && 'Allianzmitglied'].filter(Boolean),
        body: (row) =>
          [row.allianz && 'Allianz', row.allianzMitglied && 'Mitglied']
            .filter(Boolean)
            .map((s) => <Medal key={s} text={s} />)
      },
      {
        header: 'Name',
        field: 'name',
        sortable: true
      }
    ],
    [et]
  )

  const handleCloseBetriebstyp = useCallback(() => {}, [])

  const handleCloseAllianz = useCallback(
    (id) => {
      if (id) {
        reload()
        navigate(AppPaths.GesellschafterAllianzViewFn({ id }))
      }
    },
    [navigate, reload]
  )

  const openBetriebstypDialog = useCallback(
    (gesellschafter: GesellschafterDisplayJson) => {
      showDlg((visible, onClose) => (
        <GesellschafterBetriebstypenDialog
          open={visible}
          onClose={onClose(handleCloseBetriebstyp)}
          gesellschafter={gesellschafter}
        />
      ))
    },
    [showDlg, handleCloseBetriebstyp]
  )

  const openAllianzAnlageDialog = useCallback(
    (gesellschafter: GesellschafterDisplayJson) => {
      showDlg((visible, onClose) => (
        <AllianzAnlageDialog
          open={visible}
          onClose={onClose(handleCloseAllianz)}
          gsId={gesellschafter.id}
        />
      ))
    },
    [showDlg, handleCloseAllianz]
  )

  const topActions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.ADMIN,
        tooltip: 'Gesellschafter anlegen',
        icon: 'add',
        navlink: AppPaths.OrganisationViewFn('neu')
      }
    ],
    []
  )

  const tableActions = useMemo(
    (): DataTableAction<GesellschafterDisplayJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Öffnen',
        getLink: (data: GesellschafterDisplayJson) =>
          data.typ === GesellschafterTyp.ORGANISATION
            ? AppPaths.OrganisationViewFn(data.id)
            : AppPaths.FirmaViewFn(data.id),
        role: UserRoles.ADMIN
      },
      {
        icon: 'article',
        tooltip: 'Zugeordnete Betriebstypen',
        handler: (gesellschafter) => () => openBetriebstypDialog(gesellschafter),
        role: UserRoles.ADMIN
      },
      {
        icon: 'domain',
        tooltip: 'Allianz bearbeiten',
        getLink: (data: GesellschafterDisplayJson) =>
          AppPaths.GesellschafterAllianzViewFn({ id: data.allianzId }),
        check: (data: GesellschafterDisplayJson) =>
          data.allianz ? Permission.ENABLED : Permission.HIDDEN,
        role: UserRoles.ADMIN
      },
      {
        icon: 'domain_add',
        tooltip: 'Allianz erstellen',
        handler: (gesellschafter) => () => openAllianzAnlageDialog(gesellschafter),
        check: (data: GesellschafterDisplayJson) =>
          data.typ === GesellschafterTyp.ORGANISATION && !data.allianz && !data.allianzMitglied
            ? Permission.ENABLED
            : Permission.HIDDEN,
        role: UserRoles.ADMIN
      }
    ],
    [openBetriebstypDialog, openAllianzAnlageDialog]
  )
  return (
    <>
      <DataTableCard
        title="Suchergebnis"
        name="GesellschafterTabelle"
        columns={columns}
        topActions={topActions}
        actions={tableActions}
        dense
        selectMode="none"
        rowFiller
        result={result}
      />
      <DlgAnker />
    </>
  )
}
