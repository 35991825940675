import { Button, ButtonProps } from './Button'

export type PerformButtonProps = {
  visible?: ButtonProps['visible']
  disabled?: ButtonProps['disabled']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  size?: ButtonProps['size']
  autoFocus?: ButtonProps['autoFocus']
  label?: string
  tooltip?: ButtonProps['tooltip']
  variant?: ButtonProps['variant']
}

export const PerformButton = ({ label, variant, ...props }: PerformButtonProps) => (
  <Button
    {...props}
    label={label || 'Ausführen'}
    variant={variant || 'contained'}
    color="primary"
  />
)
