import { AllianzDatenBereitstellungCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { Action } from '@utils/ui/Action'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { useMemo } from 'react'

export interface AllianzDatenBereitstellungUebersichtParamsProps {
  value: AllianzDatenBereitstellungCriteriaJson
  onChange: ChangeFunction<AllianzDatenBereitstellungCriteriaJson>
  onSearch: () => void
}

export const AllianzDatenBereitstellungUebersichtParams = ({
  value,
  onChange,
  onSearch
}: AllianzDatenBereitstellungUebersichtParamsProps) => {
  const actions = useMemo(() => {
    return [
      {
        text: 'Suchen',
        onClick: onSearch
      }
    ] as Action[]
  }, [onSearch])

  return (
    <ShortCutHandler shortcuts={{ Enter: onSearch }}>
      <CardEx
        backLink
        title="Allianz Datenbereitstellungsprotokolle"
        collapsiable
        bottomActions={actions}
      ></CardEx>
    </ShortCutHandler>
  )
}
