import { Grid, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { StaffelgruppenField } from '@one/components/EkPreis/EKPreisPflege/fields/StaffelgruppenField'
import { PreisEbeneDisplayJson, StaffelgruppeDisplayJson } from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { DateField } from '@utils/ui/fields/DateField'
import { useEffect } from 'react'

export type NewEkRabattgruppeVersionDialogProps = {
  open: boolean
  onClose: any
  preisEbenen: PreisEbeneDisplayJson[]
  staffelgruppen: StaffelgruppeDisplayJson[]
  minDate: Date
  onNew: ({ gueltigVon, gueltigBis, preisEbeneId, staffelgruppeId }) => void
  onDupCheck: ({ gueltigVon, preisEbeneId }) => boolean
}

export const NewEkRabattgruppeVersionDialog = ({
  open,
  onClose,
  preisEbenen,
  staffelgruppen,
  minDate,
  onNew,
  onDupCheck
}: NewEkRabattgruppeVersionDialogProps) => {
  const [state, onChange] = useFormState({
    gueltigVon: minDate,
    gueltigBis: null,
    preisEbeneId: null,
    staffelgruppeId: null
  })

  const dup = onDupCheck(state)
  const actions = (
    <>
      {dup ? (
        <Typography paddingRight={4} color={red[400]}>
          Es gibt so eine Version schon
        </Typography>
      ) : null}
      <Button
        label="Anlegen"
        variant="contained"
        disabled={
          state.gueltigVon == null ||
          dup ||
          (state.gueltigBis != null &&
            state.gueltigVon >
              state.gueltigBis) /*|| preisEbenen != null && state.preisEbeneId == null*/
        }
        onClick={onClose(() => onNew(state))}
      />
      <CancelButton onClick={onClose()} />
    </>
  )

  useEffect(() => {
    if (state.preisEbeneId != null) {
      onChange((old) => ({ ...old, staffelgruppeId: null }))
    }
  }, [onChange, state.preisEbeneId])

  return (
    <DialogEx
      fullWidth
      open={open}
      onClose={onClose()}
      title="Neue Version"
      height="320px"
      actions={actions}
    >
      <Grid container padding={1} spacing={2}>
        <Grid item xs={6}>
          <DateField
            name="gueltigVon"
            value={state.gueltigVon}
            label="Gültig von"
            minDate={minDate}
            onChange={onChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DateField
            name="gueltigBis"
            value={state.gueltigBis}
            label="Gültig bis"
            placeholder="unbegrenzt"
            minDate={state.gueltigVon}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StaffelgruppenField
            label="Staffelgruppe"
            name="staffelgruppeId"
            options={staffelgruppen}
            value={state.staffelgruppeId}
            onChange={onChange}
            readonly={state.preisEbeneId != null}
          />
        </Grid>
        {preisEbenen ? (
          <Grid item xs={12}>
            <AutocompleteEx
              name="preisEbeneId"
              label="Preisebene"
              value={state.preisEbeneId}
              onChange={onChange}
              fullWidth
              options={preisEbenen}
              optionValue={(preisEbene: PreisEbeneDisplayJson) => preisEbene.id}
              optionLabel={(preisEbene: PreisEbeneDisplayJson) => preisEbene.name}
            />
          </Grid>
        ) : null}
      </Grid>
    </DialogEx>
  )
}
