import { TabContext, TabPanel } from '@mui/lab'
import { Grid, Tab, Tabs } from '@mui/material'
import { api } from '@one/api'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { DialogEx } from '@utils/ui/DialogEx'
import { StructPanel } from '@utils/ui/StructPanel'
import { TextField } from '@utils/ui/fields/TextField'
import { GlassPlane } from '@utils/ui/planes/GlassPlane'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { copyToClipboard, download } from '@utils/utils'
import { useState } from 'react'
import beautify from 'xml-beautifier'

export type ZARTXMLExportDialogProps = {
  open: boolean
  onClose: (arg?: (arg?: any) => void) => (arg?: any) => void
}

export const ZARTXMLExportDialog = ({ open, onClose }: ZARTXMLExportDialogProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const [tabIdx, setTabIdx] = useState('1')
  const [xml, setXml] = useState(null)
  const [artState, onArtChange] = useFormState({ bt: null, hageNr: '' })
  const [liefState, onLiefChange] = useFormState({ iln: '' })

  const onArtXml = () => {
    apiCall<any>({
      method: 'GET',
      rest: 'zart/exportZart',
      params: {
        ...artState,
        bt: artState?.bt?.bt
      },
      accept: 'application/xml',
      onSuccess: (data) => {
        const ext = artState?.bt?.nummer ? `.${artState.bt.nummer}` : ''
        setXml({ template: `zart${ext}.change-Vorlage.xml`, content: data })
      }
    })
  }

  const onLiefXml = () => {
    apiCall<any>({
      method: 'GET',
      rest: 'zart/exportZartLief',
      params: liefState,
      accept: 'application/xml',
      onSuccess: (data) => setXml({ template: 'zart.ZLIEF.change-Vorlage.xml', content: data })
    })
  }

  return (
    <DialogEx
      open={open}
      onClose={onClose()}
      title="ZART XML Erzeugen"
      maxWidth="md"
      fullWidth
      height="80%"
      actions={
        <>
          <Button
            label="Download"
            onClick={() => download(xml.template, beautify(xml.content))}
            disabled={xml == null}
          />
          <Button
            label="Copy Clipboard"
            onClick={() => copyToClipboard(beautify(xml.content))}
            disabled={xml == null || navigator.clipboard == null}
          />
        </>
      }
    >
      <GlassPlane show={apiBusy}>
        <WaitPlane wait={apiBusy}>
          <Grid container direction="column" height="100%" justifyContent="stretch">
            <Grid item>
              <TabContext value={tabIdx}>
                <Tabs
                  value={tabIdx}
                  onChange={(e, v) => {
                    setTabIdx(v)
                    setXml(null)
                  }}
                  aria-label="ZART Typ"
                >
                  <Tab label="Artikel" value="1" />
                  <Tab label="Lieferanten" value="2" />
                </Tabs>
                <TabPanel value="1">
                  <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                      <ArtikelBetriebstypField
                        label="Betriebstyp"
                        filter="zugeordnete"
                        required
                        value={artState.bt}
                        onChange={onArtChange}
                        name="bt"
                        asJson
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="hage-Nr. (kommasepariert)"
                        fullWidth
                        required
                        value={artState.hageNr}
                        onChange={onArtChange}
                        name="hageNr"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        label="ZART-XML Erzeugen"
                        variant="contained"
                        onClick={onArtXml}
                        disabled={
                          artState.bt == null ||
                          artState?.hageNr == null ||
                          artState?.hageNr?.length === 0
                        }
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                      <TextField
                        label="Lieferantennummer (kommasepariert)"
                        fullWidth
                        required
                        value={liefState.iln}
                        onChange={onLiefChange}
                        name="iln"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        label="ZART-XML Erzeugen"
                        variant="contained"
                        onClick={onLiefXml}
                        disabled={liefState.iln == null || liefState.iln?.length === 0}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Grid>
            {xml && (
              <Grid item flexGrow={1} marginLeft={2} marginRight={2}>
                <StructPanel value={xml.content} />
              </Grid>
            )}
          </Grid>
        </WaitPlane>
      </GlassPlane>
    </DialogEx>
  )
}
