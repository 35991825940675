import { AppRoutes } from './AppRoutes'
import { AppRouteDefinition } from './utils/ui/App/AppRouteMgr'
import { ensureArray } from './utils/utils'

export const MenuItemsView = () => {
  const root = { items: AppRoutes, menuLabel: 'Menü' }

  // const render = (item: AppRouteDefinition) => {
  //   return (
  //     <div key={item.menuLabel}>
  //       <span style={{ paddingRight: 12 }}>{item.menuLabel}</span>
  //       <span style={{ display: 'inline-flex', gap: 8, flexDirection: 'row' }}>
  //         {item.admin === 'only' && <Medal text="ADMIN-MODUS" backgroundColor={red[200]} />}
  //         {item.role &&
  //           ensureArray(item.role).map((r) => (
  //             <Medal key={r} text={r} backgroundColor={orange[200]} />
  //           ))}
  //       </span>
  //       <div style={{ paddingLeft: 42 }}>
  //         {item.items?.filter((item) => item.menuLabel != null).map(render)}
  //       </div>
  //     </div>
  //   )
  // }

  const render2 = (item: AppRouteDefinition, level: number = 0) => {
    const ident = ''.padStart(level * 4, ' ')

    let out = ident + item.label + '\t'
    if (item.admin === 'only') {
      out += ' ADMIN-MODUS '
    }
    if (item.role) {
      out += ensureArray(item.role).join(', ')
    }

    out += '\n'

    if (item.items?.length > 0) {
      out += item.items
        ?.filter((item) => item.label != null)
        .map((s) => render2(s, level + 1))
        .join('')
    }

    return out
  }

  return <pre>{render2(root)}</pre>
}
