import { ImportErrorErpLieferantJson } from '@one/typings/apiTypings'
import { Column } from '@utils/ui/DataTable/DataTable'
import { useMemo } from 'react'
import { ImportFehlerView } from './ImportFehlerView'

export const ImportLieferantFehlerUebersichtView = () => {
  const columns = useMemo<Column<ImportErrorErpLieferantJson>[]>(
    () => [
      {
        field: 'lieferant.name1',
        header: 'Name 1'
      },
      {
        field: 'lieferant.name2',
        header: 'Name 2'
      },
      {
        field: 'lieferant.nummer',
        header: 'Nummer'
      },
      {
        field: 'lieferant.iln',
        header: 'ILN'
      }
    ],
    []
  )
  return (
    <ImportFehlerView
      rest="import/lieferant"
      title="Lieferanten Importfehler"
      columns={columns}
      name="LieferantImportFehlerView"
    />
  )
}
