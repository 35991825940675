import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { LabelChipContainer } from '@one/components/Label/LabelChipContainer'
import { LabelEditResponse, useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import {
  ArtikelBetriebstypCell,
  getArtikelBetriebstypLabel
} from '@one/components/common/ArtikelBetriebstypCell'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { useArtikelLabelCache } from '@one/datacaches/useArtikelLabelCache'
import {
  HauptlieferantBearbeitenJson,
  HauptlieferantPflegeEintragJson,
  LieferantDisplayJson,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SilentUpd, SilentUpdTarget } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import {
  AdditionalFilterConfig,
  useAdditionalFilter
} from '@utils/ui/DataTable/filter/AdditionalFilterMenu'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AlternativeLieferantenCell } from './AlternativeLieferantenCell'
import { HauptLieferantenCell } from './HauptLieferantenCell'

export interface HauptlieferantPflegeTableProps {
  eintraege: HauptlieferantPflegeEintragJson[]
  eintraegeChanges: HauptlieferantPflegeEintragJson[]
  lieferanten: LieferantDisplayJson[]
  silentUpd: SilentUpd<HauptlieferantBearbeitenJson>
  preisEbenen: PreisEbeneDisplayJson[]
  setEintraegeChanges: (nextChanges: HauptlieferantPflegeEintragJson[]) => void
  hauptlieferantId?: number
  selectedPreisEbeneId?: number
  onlyFavoredLieferant?: boolean
}

export const HauptlieferantPflegeTable = ({
  eintraege,
  eintraegeChanges,
  preisEbenen,
  selectedPreisEbeneId,
  lieferanten,
  hauptlieferantId,
  setEintraegeChanges,
  silentUpd,
  onlyFavoredLieferant
}: HauptlieferantPflegeTableProps) => {
  const [selected, setSelected, getSelected] = useStateEx<Set<HauptlieferantPflegeEintragJson>>(
    new Set<HauptlieferantPflegeEintragJson>()
  )
  const [lieferantenMap, setLieferantenMap] = useState<{ [id: number]: LieferantDisplayJson }>({})
  const { et } = useEnums()
  const { isPIMModel } = useContext(AppContext)
  const { openLabelsEdit } = useArtikelLabelEdit()
  const { get: getLabels } = useArtikelLabelCache()
  const { get: getBetriebstyp } = useArtikelBetriebstypCache()

  useEffect(() => {
    if (lieferanten.length) {
      const map = lieferanten.reduce(
        (
          items: { [id: number]: LieferantDisplayJson },
          item: LieferantDisplayJson,
          index: number
        ) => {
          items[item.id] = item
          return items
        },
        {}
      )
      setLieferantenMap(map)
    }
  }, [lieferanten])

  const setNewHauptlieferant = (artikel: HauptlieferantPflegeEintragJson, lieferantId: number) => {
    artikel.lieferanten.forEach((lieferant) => {
      if (lieferant.hauptlieferant && lieferant.lieferantId === lieferantId) {
        artikel.nichtBevorzugterHauptLieferant = true
      } else if (!lieferant.preisEbeneId) {
        lieferant.hauptlieferant = false
      }
    })

    const hauptlieferant = artikel.lieferanten.find(
      (lieferant) => lieferant.lieferantId === lieferantId
    )
    if (hauptlieferant) {
      hauptlieferant.hauptlieferant = true
    }
  }

  const setAltHauptlieferant = (
    artikel: HauptlieferantPflegeEintragJson,
    lieferantId: number,
    preisEbeneId: number
  ) => {
    let altLieferant
    artikel.lieferanten.forEach((lieferant) => {
      if (lieferant.lieferantId === lieferantId && lieferant.preisEbeneId === preisEbeneId) {
        altLieferant = lieferant
      } else if (lieferant.preisEbeneId === preisEbeneId && lieferant.hauptlieferant) {
        lieferant.hauptlieferant = false
      }
    })

    if (altLieferant) {
      if (altLieferant.hauptlieferant && altLieferant.preisEbeneId) {
        altLieferant.hauptlieferant = false
      } else {
        altLieferant.preisEbeneId = preisEbeneId
        altLieferant.hauptlieferant = true
      }
    } else {
      const altHauptlieferant = artikel.lieferanten.find(
        (lieferant) => lieferant.lieferantId === lieferantId
      )
      if (altHauptlieferant) {
        artikel.lieferanten.push({ ...altHauptlieferant, preisEbeneId, hauptlieferant: true })
      }
    }
  }

  const onApplyHauptlieferant = useCallback(() => {
    const next = [...eintraegeChanges]
    getSelected().forEach((hle) => {
      if (selectedPreisEbeneId) {
        setAltHauptlieferant(hle, hauptlieferantId, selectedPreisEbeneId)
      } else {
        setNewHauptlieferant(hle, hauptlieferantId)
      }
      const indexChanged = next.findIndex(
        (changedArtikel) => changedArtikel.artikel.id === hle.artikel.id
      )
      if (indexChanged > -1) {
        next[indexChanged] = hle
      } else {
        next.push(hle)
      }
    })
    setEintraegeChanges(next)
  }, [getSelected, hauptlieferantId, eintraegeChanges, setEintraegeChanges, selectedPreisEbeneId])

  const onApplyFavoredLieferant = useCallback(() => {
    const next = [...eintraegeChanges]
    getSelected().forEach((eintrag) => {
      const favoredLieferand = eintrag.lieferanten.find(
        (lieferant) => lieferant.bevorzugterHauptlieferant
      )
      if (favoredLieferand) {
        setNewHauptlieferant(eintrag, favoredLieferand.lieferantId)
        const indexChanged = next.findIndex(
          (changedArtikel) => changedArtikel.artikel.id === eintrag.artikel.id
        )
        if (indexChanged > -1) {
          next[indexChanged] = eintrag
        } else {
          next.push(eintrag)
        }
      }
    })
    setEintraegeChanges(next)
  }, [eintraegeChanges, getSelected, setEintraegeChanges])

  const onRemoveAltHauptlieferant = useCallback(() => {
    const next = [...eintraegeChanges]

    getSelected().forEach((artikel) => {
      const altLieferant = artikel.lieferanten.find(
        (lieferant) => lieferant.preisEbeneId === selectedPreisEbeneId && lieferant.hauptlieferant
      )
      if (altLieferant) {
        altLieferant.hauptlieferant = false
      }
      const indexChanged = next.findIndex(
        (changedArtikel) => changedArtikel.artikel.id === artikel.artikel.id
      )
      if (indexChanged > -1) {
        next[indexChanged] = artikel
      } else {
        next.push(artikel)
      }
    })
    setEintraegeChanges(next)
  }, [eintraegeChanges, selectedPreisEbeneId, getSelected, setEintraegeChanges])

  const onClickAltLieferant = useCallback(
    (artikel, lieferantId, preisEbeneId) => {
      return () => {
        const next = [...eintraegeChanges]

        setAltHauptlieferant(artikel, lieferantId, preisEbeneId)
        const indexChanged = next.findIndex(
          (changedItem) => changedItem.artikel.id === artikel.artikel.id
        )
        if (indexChanged > -1) {
          next[indexChanged] = artikel
        } else {
          next.push(artikel)
        }
        setEintraegeChanges(next)
      }
    },
    [eintraegeChanges, setEintraegeChanges]
  )

  const handleLieferantClickOnArtikel = useCallback(
    (item, lieferantId) => {
      return () => {
        const next = [...eintraegeChanges]

        setNewHauptlieferant(item, lieferantId)
        const indexChanged = next.findIndex(
          (changedItem) => changedItem.artikel.id === item.artikel.id
        )
        if (indexChanged > -1) {
          next[indexChanged] = item
        } else {
          next.push(item)
        }
        setEintraegeChanges(next)
      }
    },
    [eintraegeChanges, setEintraegeChanges]
  )

  const getHauptlieferant = (item: HauptlieferantPflegeEintragJson) => {
    return item.lieferanten.find(
      (lieferant) => lieferant.hauptlieferant && lieferant.preisEbeneId === null
    )
  }

  const afc = useMemo<AdditionalFilterConfig[]>(() => {
    return [
      {
        label: 'ignorierte Artikelarten',
        name: 'ignArtArt',
        onFilter: (row) => row.artikel.artikelArt?.ignoriert
      }
    ] as AdditionalFilterConfig[]
  }, [])

  const [eintraegeFiltered, additionalFilter] = useAdditionalFilter(eintraege, afc)

  const columns = useMemo<Column<HauptlieferantPflegeEintragJson>[]>(() => {
    const getItem = (row: HauptlieferantPflegeEintragJson) => {
      return eintraegeChanges.find((item) => item.artikel.id === row.artikel.id) || row
    }
    return [
      {
        field: 'lieferanten',
        header: 'Lieferanten',
        align: 'left',
        valueGetter: (row) =>
          row.lieferanten
            .map((lieferant) => lieferantenMap[lieferant.lieferantId])
            .filter(Boolean)
            .map((l) => `(${l.nummer}) ${l.name1}`),
        body: (row) => {
          const eintrag = getItem(row)
          const hauptlieferant = getHauptlieferant(eintrag)

          return eintrag && row.lieferanten?.length > 0 ? (
            <HauptLieferantenCell
              onlyFavoredLieferant={onlyFavoredLieferant}
              eintrag={eintrag}
              hauptlieferant={hauptlieferant}
              lieferantenMap={lieferantenMap}
              onClickLieferant={handleLieferantClickOnArtikel}
              showRadioButtons={Boolean(!selectedPreisEbeneId)}
            />
          ) : (
            <small>Keine Lieferanten vorhanden!</small>
          )
        }
      },
      {
        field: 'altLieferant',
        header: 'standortspezifische Lieferanten',
        align: 'left',
        valueGetter: (row) => {
          const altLieferanten = row.lieferanten.filter((lieferant) =>
            Boolean(lieferant.preisEbeneId)
          )
          return altLieferanten
            .map((lieferant) =>
              preisEbenen.find((preisEbene) => preisEbene.id === lieferant.preisEbeneId)
            )
            .filter(Boolean)
            .map((l) => `(${l.nr}) ${l.name}`)
        },
        body: (row) => {
          const eintrag = getItem(row)
          return eintrag ? (
            <AlternativeLieferantenCell
              showRadioButtons={Boolean(selectedPreisEbeneId)}
              eintrag={eintrag}
              preisEbenen={preisEbenen}
              lieferantenMap={lieferantenMap}
              onClickAltLieferant={onClickAltLieferant}
              selectedPreisEbeneId={selectedPreisEbeneId}
            />
          ) : null
        }
      },
      {
        field: 'artikel.hageNummer',
        header: 'hage-Nummer',
        align: 'left'
      },
      {
        off: isPIMModel,
        field: 'artikel.btNummer',
        header: 'Artikel-Nr.',
        align: 'left'
      },
      {
        field: 'artikel.ean',
        header: 'Artikel EAN',
        align: 'center'
      },
      {
        field: 'hauptlieferant.industrieArtikelNummer',
        header: 'Artikel IAN',
        align: 'center',
        valueGetter: (row) => {
          const item = getItem(row)
          const hauptlieferant = getHauptlieferant(item)
          return hauptlieferant?.industrieArtikelNummer || ''
        }
      },
      {
        field: 'artikel.bez1',
        header: 'Artikel Bez1',
        align: 'left'
      },
      {
        field: 'artikel.bez2',
        header: 'Artikel Bez2',
        align: 'left'
      },
      {
        field: 'artikel.bez3',
        header: 'Artikel Bez3',
        align: 'left'
      },
      {
        field: 'artikel.bez4',
        header: 'Artikel Bez4',
        align: 'left'
      },
      {
        field: 'artikel.labels',
        header: 'Labels',
        sortable: true,
        valueGetter: (row) => row.artikel?.labels?.map((id) => getLabels(id)?.kurz),
        body: (row) => <LabelChipContainer labels={row.artikel?.labels?.map(getLabels)} />
      },
      {
        field: 'artikel.aboStatus',
        header: 'Abostatus',
        align: 'center',
        body: (row) =>
          row?.artikel && <ArtikelAbonniertStatusSymbol value={row.artikel.aboStatus} et={et} />,
        valueGetter: (row) =>
          row?.artikel && getArtikelAbonniertStatusText(et, row.artikel.aboStatus)
      },
      {
        off: isPIMModel,
        field: 'artikel.betriebsTyp',
        header: 'B-Typ',
        body: (row) => <ArtikelBetriebstypCell value={row.artikel?.betriebsTyp} />,
        valueGetter: (row) =>
          `${getArtikelBetriebstypLabel(getBetriebstyp(row.artikel?.betriebsTyp))}`
      }
    ] as Column<HauptlieferantPflegeEintragJson>[]
  }, [
    isPIMModel,
    eintraegeChanges,
    getLabels,
    et,
    lieferantenMap,
    onlyFavoredLieferant,
    handleLieferantClickOnArtikel,
    selectedPreisEbeneId,
    preisEbenen,
    onClickAltLieferant,
    getBetriebstyp
  ])

  const updateLabels = useCallback(
    ({ data, isAdded }: LabelEditResponse) => {
      silentUpd(SilentUpdTarget.EDT, (model) => {
        const eintraege = model.pflegeListe.eintraege.map((eintrag) => {
          if (data.artikelIds.includes(eintrag.artikel.id)) {
            // Set-zu-Array-Umwandlung, um Duplikate zu vermeiden
            let labels = Array.from(new Set([...(eintrag.artikel.labels ?? []), ...data.labelIds]))
            if (!isAdded) {
              labels = labels.filter((labelId: number) => !data.labelIds.includes(labelId))
            }
            const neweintrag = {
              ...eintrag,
              artikel: { ...eintrag.artikel, labels }
            }
            return neweintrag
          }
          return eintrag
        })
        return { ...model, pflegeListe: { ...model.pflegeListe, eintraege } }
      })
    },
    [silentUpd]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(getSelected()).map((select) => select.artikel),
      callback: {
        then: updateLabels
      }
    })
  }, [openLabelsEdit, getSelected, updateLabels])

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Labels bearbeiten',
        icon: 'label',
        onClick: openLabelDialog
      }
    ],
    [openLabelDialog]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: any) => AppPaths.ArtikelFn(data.artikel.id)
      }
    ],
    []
  )

  const actions = useMemo(
    () => [
      <Button
        key="applyHauptlieferant"
        label={`Übernehme ${
          selectedPreisEbeneId ? 'standortspezifischen Lieferanten' : 'Hauptlieferant'
        } aus Filter`}
        disabled={!hauptlieferantId || selected.size === 0}
        onClick={onApplyHauptlieferant}
        variant="contained"
        tooltip={`Setze gewählten Lieferanten aus dem Lieferanten-Filter als ${
          selectedPreisEbeneId ? 'standortspezifischen Lieferanten' : 'Hauptlieferant'
        } `}
      />,
      <Button
        key="keepCurrentLieferant"
        label={`Bevorzugten Lieferanten auswählen`}
        disabled={selected.size === 0}
        onClick={onApplyFavoredLieferant}
        variant="contained"
        tooltip="Aktuellen Lieferanten mit dem bevorzugten Lieferanten überschreiben"
      />,
      <Button
        key="removeAltHauptlieferant"
        label="Entferne standortspezifischen Lieferanten"
        disabled={!selectedPreisEbeneId || selected.size === 0}
        onClick={onRemoveAltHauptlieferant}
        variant="contained"
        color="inherit"
        tooltip="Entferne gewählten Standort als standortspezifischen Lieferanten"
      />
    ],
    [
      selected,
      hauptlieferantId,
      onApplyHauptlieferant,
      selectedPreisEbeneId,
      onApplyFavoredLieferant,
      onRemoveAltHauptlieferant
    ]
  )

  return (
    <DataTableCard
      name="HauptlieferantPflegeTable"
      filterMode="both"
      columns={columns}
      topActions={topActions}
      actions={tableActions}
      value={eintraegeFiltered}
      dense
      selectMode="multi"
      selectCol
      paging
      selected={selected}
      onSelect={setSelected}
      title="Artikelliste"
      bottomActions={actions}
      additionalFilter={additionalFilter}
      localStateName="HauptlieferantPflegeTable"
      stickyColumsLeft={2}
    />
  )
}
