import { SentimentVeryDissatisfied } from '@mui/icons-material'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Button } from '../Buttons/Button'

import StackTrace from 'stacktrace-js'

export type AppErrorPageProps = {
  error?: Error
  onReload?: VoidFunction
}

export const AppErrorPage = ({ error, onReload }: AppErrorPageProps) => {
  const [stackFrames, setStackFrames] = useState<string[]>(null)

  useEffect(() => {
    StackTrace.fromError(error).then((stackFrames) => {
      setStackFrames(stackFrames.map((frame) => frame.toString()))
    })
  }, [error])

  return (
    <Box padding={2}>
      <Grid item container direction="row" spacing={1}>
        <Grid item>
          <SentimentVeryDissatisfied style={{ fontSize: '7em' }} />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography whiteSpace="nowrap" fontSize="2em">
                Es ist ein Fehler aufgetreten
              </Typography>
            </Grid>
            <Grid item>
              <pre style={{ fontSize: '120%' }}>{error.message}</pre>
            </Grid>
            <Grid item maxHeight="20%">
              <Paper>
                {stackFrames ? (
                  stackFrames.map((frame) => <pre key={frame}>{frame}</pre>)
                ) : (
                  <pre>{error.stack}</pre>
                )}
              </Paper>
            </Grid>
            {onReload && (
              <Grid item paddingTop={2}>
                <Button label="Neu laden" size="large" variant="contained" onClick={onReload} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
