import { Download } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { BetriebstypKontextField } from '@one/components/common/BetriebstypKontextField'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { HkmEnum } from '@one/enums/HkmEnum'
import { EkPreisListeImportJson } from '@one/typings/apiTypings'
import { formatDate, formatDateTime } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { CardEx } from '@utils/ui/CardEx'
import { useDownloader } from '@utils/ui/downloader'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import { useNavigate } from 'react-router'
import { ImportStatusCell } from './fields/ImportStatusCell'

export interface EkPreisImportPflegeKopfProps {
  model: EkPreisListeImportJson
  onChange: (e: Event) => void
}

export const EkPreisImportPflegeKopf = ({ model, onChange }: EkPreisImportPflegeKopfProps) => {
  const [download] = useDownloader()

  const navigate = useNavigate()
  const { et } = useEnums()

  return (
    <CardEx
      title={
        <Grid container spacing={1}>
          <Grid item>Import Einkaufspreise</Grid>
          <Grid item alignSelf="center" fontSize="70%">
            <ImportStatusCell
              status={model.status}
              text={et(HkmEnum.EkPreisListeImportStatus, model.status)}
            />
          </Grid>
        </Grid>
      }
      backLink
    >
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Datei"
            value={model.dateiname}
            fullWidth
            disabled
            actions={[
              {
                icon: <Download />,
                tooltip: 'Preisliste herunterladen',
                onClick: () => download(`rest/ekpreislisteimport/download?id=${model.id}`),
                disabled: model.dateiname == null
              }
            ]}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <StaticField
            label="Dateigröße"
            value={model.dateigroesse ? `${Math.round(model.dateigroesse / 10) / 100} KB` : ''}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <BetriebstypKontextField owner={model} />
        </Grid>

        <Grid item xs={4} sm={2}>
          <StaticField label="Gültig ab" value={formatDate(model?.gueltigAb) ?? ''} />
        </Grid>

        <Grid item xs={6} lg={4}>
          <TextField
            label="Preislisten-Name"
            name="name"
            value={model.name}
            onChange={onChange}
            disabled={model.preisliste != null}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} lg={4}>
          <LieferantField
            name="lieferant"
            label="Lieferant"
            value={model.lieferant}
            fullWidth
            disabled
            disableActions
            asLink
            modus="dezentral"
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <StaticField
            label="Hochgeladen"
            value={`${formatDateTime(model?.audit?.createdOn) ?? ''} ${model.audit?.createdBy}`}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <StaticField
            label="Aktualisiert"
            value={`${formatDateTime(model?.audit?.updatedOn) ?? ''} ${model.audit?.updatedBy}`}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
