import { Grid } from '@mui/material'
import { PreisEbeneDisplayJson } from '@one/typings/apiTypings'
import { zeroPad } from '@utils/utils'

export interface PreisEbeneCellProps {
  value?: PreisEbeneDisplayJson
}

export const PreisEbeneCellField = ({ value = null }) => {
  return (
    value && (
      <Grid container spacing={1} alignItems="center">
        <Grid item>{zeroPad(value?.nr, 3)}</Grid>
        <Grid item>
          <span>{value?.name}</span>
        </Grid>
      </Grid>
    )
  )
}
