import {
  ErpArtikelDetailsJson,
  PimArtikelVersionJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StringArrayField } from '@utils/ui/fields/StringArrayField'
import { TextField } from '@utils/ui/fields/TextField'
import { resolveObjectField } from '@utils/utils'
import { StaticField } from '@utils/ui/fields/StaticField'
import Grid from '@mui/material/Grid2'
import React from 'react'

export interface PimArtikelKennzeichenProps {
  pimArtikelVersion: PimArtikelVersionJson
  details?: ErpArtikelDetailsJson | null
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelKennzeichen wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikelVersion
 * @param details
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelKennzeichen = ({
  pimArtikelVersion,
  details,
  onChange,
  errors,
  readonly
}: PimArtikelKennzeichenProps) => {
  return (
    <CardEx title="Kennzeichen">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Materialgruppe"
            value={pimArtikelVersion.materialgruppe}
            name="pimArtikel.version.materialgruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.materialgruppe')}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Basis-Warengruppe"
            value={pimArtikelVersion.basisWarengruppe}
            name="pimArtikel.version.basisWarengruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.basisWarengruppe')}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="BDB-Warengruppe"
            value={pimArtikelVersion.bdbWarengruppe}
            name="pimArtikel.version.bdbWarengruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.bdbWarengruppe')}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        {details?.warengruppe && (
          <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
            <StaticField
              label="Warengruppe"
              value={`${details.warengruppe.nummer} - ${details.warengruppe.name}`}
              name="warengruppe"
              empty
            />
          </Grid>
        )}
        {details?.sparte && (
          <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
            <StaticField
              label="Sparte"
              value={`${details.sparte.nr} - ${details.sparte.bezeichnung}`}
              name="sparte"
              empty
            />
          </Grid>
        )}
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Farbgruppe"
            value={pimArtikelVersion.farbgruppe}
            name="pimArtikel.version.farbgruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.farbgruppe')}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <NumberField
            label="Anzahl Packstuecke"
            value={pimArtikelVersion.anzahlPackstuecke}
            name="pimArtikel.version.anzahlPackstuecke"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.anzahlPackstuecke')}
            fraction={0}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        {/*<Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>*/}
        {/*  <NumberField*/}
        {/*    label="Grundpreismenge"*/}
        {/*    value={pimArtikelVersion.grundpreismenge}*/}
        {/*    name="pimArtikel.version.grundpreismenge"*/}
        {/*    onChange={onChange}*/}
        {/*    error={resolveObjectField(errors, 'pimArtikel.version.grundpreismenge')}*/}
        {/*    disabled={readonly}*/}
        {/*    fraction={0}*/}
        {/*    fullWidth*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Intrastat-Mengeneinheit"
            value={pimArtikelVersion.intrastatMengeneinheit}
            name="pimArtikel.version.intrastatMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.intrastatMengeneinheit')}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          { readonly ? (
            <StaticField
              value={pimArtikelVersion.regalplatzierungen}
              label='Regalplatzierungen'
              name='regalplatzierungen'
              empty
            />
          ):(
            <StringArrayField
              label="Regalplatzierungen"
              value={pimArtikelVersion.regalplatzierungen}
              name="pimArtikel.version.regalplatzierungen"
              onChange={onChange}
              /* error={resolveObjectField(errors, 'pimArtikel.version.regalplatzierungen')} */
              fullWidth
            />
            )
          }
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Arena-Kennzeichen"
            checked={pimArtikelVersion.arenaKennzeichen}
            name="pimArtikel.version.arenaKennzeichen"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Grundpreisauszeichnungspflicht"
            checked={pimArtikelVersion.grundpreisauszeichnungspflicht}
            name="pimArtikel.version.grundpreisauszeichnungspflicht"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Ohne Verkaufspreis"
            checked={pimArtikelVersion.ohneVerkaufspreis}
            name="pimArtikel.version.ohneVerkaufspreis"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Preisbindung"
            checked={pimArtikelVersion.preisbindung}
            name="pimArtikel.version.preisbindung"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Rabattfähig"
            checked={pimArtikelVersion.rabattfaehig}
            name="pimArtikel.version.rabattfaehig"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="RundungschemaRelevanz"
            checked={pimArtikelVersion.rundungschemaRelevanz}
            name="pimArtikel.version.rundungschemaRelevanz"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Skontofaehig"
            checked={pimArtikelVersion.skontofaehig}
            name="pimArtikel.version.skontofaehig"
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            name="ohneVerkaufspreis"
            label="ohne Verkaufspreis"
            checked={pimArtikelVersion.ohneVerkaufspreis}
            disabled={readonly}
            labelNotDisabled
            indeterminate
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            name="eigenmarke"
            label="Eigenmarke"
            checked={pimArtikelVersion.eigenmarke}
            disabled={readonly}
            labelNotDisabled
            indeterminate
          />
        </Grid>
        {/*Redundant zu PimArtikelKopf <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Baustein-Kennzeichen"
            name="bausteinKennzeichen"
            checked={pimArtikelVersion.bausteinKennzeichen}
            disabled={readonly}
            labelNotDisabled
            indeterminate
          />
        </Grid>*/}
      </Grid>
    </CardEx>
  )
}

export const PimArtikelKennzeichenMemo = React.memo(PimArtikelKennzeichen)
