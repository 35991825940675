import { FormControlLabel, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'
import React, { forwardRef } from 'react'

export interface SwitchProps {
  label?: any
  name?: string
  checked?: boolean
  onChange?: (event: React.ChangeEvent<any>, checked: boolean) => void
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  style?: any
  disabled?: boolean
  visible?: boolean
  size?: MuiSwitchProps['size']
  title?: string
  // compact?: boolean
  className?: string
  componentsProps?: any
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

export const Switch = forwardRef(
  (
    {
      label = null,
      name = null,
      checked = null,
      onChange = null,
      onClick = null,
      disabled = false,
      style = null,
      visible = true,
      size,
      title = null,
      className,
      componentsProps,
      labelPlacement
    }: // compact = false
    SwitchProps,
    ref
  ) => {
    if (!visible) {
      return null
    }

    const body = (
      <MuiSwitch
        ref={ref as any}
        className={className}
        name={name}
        disabled={disabled}
        size={size}
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        title={title}
        // style={compact ? (style && { ...style, ...marginMinus12 }) || marginMinus12 : null}
      />
    )

    if (label) {
      return (
        <FormControlLabel
          ref={ref}
          className={className}
          control={body}
          name={name}
          label={label}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          title={title}
          componentsProps={componentsProps}
          labelPlacement={labelPlacement}
          // style={compact ? (style && { ...style, ...marginMinus12 }) || marginMinus12 : null}
        />
      )
    }
    return body
  }
)

Switch.displayName = 'Switch'
