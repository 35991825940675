import { Sync } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { api } from '@one/api'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import {
  ArtikelBetriebstyp,
  BetriebstypJson,
  LieferantBetriebstypListeJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Rotate } from '@utils/ui/Rotate'
import { SelectField } from '@utils/ui/fields/SelectField'
import { ifString, zeroPad } from '@utils/utils'
import { useEffect, useRef, useState } from 'react'
import { formatBetriebsTyp } from '@one/components/common/formatters'

export interface ArtikelBetriebstypFieldProps {
  value: ArtikelBetriebstyp | null
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  name?: string
  error?: string | boolean
  label?: string
  emptyText?: string
  disabled?: boolean
  required?: boolean
  filter?: 'alle' | 'mitArtikeln' | 'zugeordnete'
  asJson?: boolean
  helperText?: string
  showArtikelzahlVonLieferantId?: number
}

interface BetriebstypJsonEx extends BetriebstypJson {
  artikelAnzahl?: number
}

export const ArtikelBetriebstypField = ({
  value = null,
  onChange = null,
  name = null,
  label = null,
  emptyText = null,
  error = null,
  disabled = false,
  required = false,
  filter = 'zugeordnete',
  asJson = false,
  helperText = '',
  showArtikelzahlVonLieferantId = null
}: ArtikelBetriebstypFieldProps) => {
  const { data } = useArtikelBetriebstypCache()
  const [apiCall, apiBusy] = useApiCaller(api)
  const lastLieferantIdRef = useRef(null)
  const [betriebstypen, setBetriebstypen] = useState<BetriebstypJsonEx[]>([])

  useEffect(() => {
    if (showArtikelzahlVonLieferantId == null) {
      setBetriebstypen(data)
    } else if (lastLieferantIdRef.current != showArtikelzahlVonLieferantId && data?.length > 0) {
      lastLieferantIdRef.current = showArtikelzahlVonLieferantId
      apiCall<LieferantBetriebstypListeJson>({
        method: 'GET',
        rest: 'lieferantbetriebstypen',
        params: { lieferantId: showArtikelzahlVonLieferantId },
        onSuccess: (loaded) => {
          setBetriebstypen(
            data.map((bt) => ({
              ...bt,
              artikelAnzahl:
                loaded.lieferantBetriebstypen.find((a) => a.betriebstyp === bt.bt)?.artikelAnzahl ||
                0
            }))
          )
        }
      })
    }
  }, [apiCall, data, showArtikelzahlVonLieferantId])

  return (
    <SelectField
      label={label}
      value={value}
      error={!!error}
      helperText={ifString(error) ?? helperText}
      options={betriebstypen}
      onChange={onChange}
      name={name}
      optionValue={asJson ? (o) => o : 'bt'}
      renderSelected={(typ: BetriebstypJsonEx) => (
        <Grid container spacing={1}>
          <Grid item>{typ.bt ? formatBetriebsTyp(typ) : <>{typ.bezeichnung}</>}</Grid>
          {apiBusy && (
            <Grid item>
              <Rotate>
                <Sync fontSize="small" />
              </Rotate>
            </Grid>
          )}
        </Grid>
      )}
      renderItem={(typ: BetriebstypJsonEx) =>
        typ.bt ? (
          <Grid container>
            <Grid item flexGrow={1}>
              {`${zeroPad(typ?.btNr, 3)}${typ?.bezeichnung ? ` - ${typ?.bezeichnung}` : ''}`}
            </Grid>
            {showArtikelzahlVonLieferantId ? (
              <Grid item>
                <small title="Anzahl Artikel des Lieferanten">
                  {' '}
                  ~{typ.artikelAnzahl || 0} Artikel
                </small>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <>
            {typ.bezeichnung}
            <small>&nbsp;--&nbsp;Nicht verfügbar</small>
          </>
        )
      }
      optionIgnored={(typ: BetriebstypJsonEx) => {
        switch (filter) {
          case 'zugeordnete':
            return !typ.zugeordnet && typ.bt != ArtikelBetriebstyp.UNBEKANNT
          case 'mitArtikeln':
            return !typ.artikelAnzahl
          case 'alle':
          default:
            return false
        }
      }}
      displayEmpty
      emptyText={emptyText}
      disabled={disabled || apiBusy}
      required={required}
      fullWidth
    />
  )
}
