export enum MessageID {
  ID_OK = 'ID_OK',
  ID_OPTLOCK = 'ID_OPTLOCK',
  ID_EXCEPTION = 'ID_EXCEPTION',
  ID_UNAUTHORIZED = 'ID_UNAUTHORIZED',
  ID_NOTFOUND = 'ID_NOTFOUND',
  ID_REF_NOTFOUND = 'ID_REF_NOTFOUND',
  ID_VALIDATION_FAILDED = 'ID_VALIDATION_FAILDED',
  ID_FIELD_MANDATORY = 'ID_FIELD_MANDATORY',
  ID_DUPLICATE = 'ID_DUPLICATE',
  ID_SERVICE_UNREACHABLE = 'ID_SERVICE_UNREACHABLE',
  ID_DELTA = 'ID_DELTA',
  ID_NEED_CREATE = 'ID_NEED_CREATE',

  IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN = 'IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN',
  IDE_ABO_SELEKTION_DELETE_HAUPT_SELEKTION_DIFFERENT = 'IDE_ABO_SELEKTION_DELETE_HAUPT_SELEKTION_DIFFERENT',

  IDE_DZ_MAPPING = 'IDE_DZ_MAPPING',
  IDE_DZ_MULTIPLETARGETMAPPING = 'IDE_DZ_MULTIPLETARGETMAPPING',
  IDE_DZ_ABGLEICH_MEHRDEUTIG = 'IDE_DZ_ABGLEICH_MEHRDEUTIG',
  IDE_ZE_ABGLEICH_MEHRDEUTIG = 'IDE_ZE_ABGLEICH_MEHRDEUTIG',
  IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE = 'IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE',
  IDE_DZ_EAN_KONFLIKT = 'IDE_DZ_EAN_KONFLIKT',
  IDE_DZ_BT_NICHT_ERMITTELT = 'IDE_DZ_BT_NICHT_ERMITTELT',
  IDE_DZ_BT_ERMITTELT = 'IDE_DZ_BT_ERMITTELT',
  IDE_DZ_BT_ANDERER_ERMITTELT = 'IDE_DZ_BT_ANDERER_ERMITTELT',
  IDE_DZ_BTNUMMER_KONFLIKT = 'IDE_DZ_BTNUMMER_KONFLIKT',
  IDE_DZ_INCONSISTEN_MES = 'IDE_DZ_INCONSISTEN_MES',
  IDE_DZ_ARTIKEL_ARCHIVIERT = 'IDE_DZ_ARTIKEL_ARCHIVIERT',
  IDE_DZ_EKPREISE_NOCHNICHTGEHOLT = 'IDE_DZ_EKPREISE_NOCHNICHTGEHOLT',
  IDE_DZ_MEHRERE_WURZEL_MES = 'IDE_DZ_MEHRERE_WURZEL_MES',
  IDE_DZ_FEHLENDE_ME = 'IDE_DZ_FEHLENDE_ME',
  IDE_KEIN_DZ_ARTIKEL_BEI_MEHRD_ZE_ARTIKEL_ZUGEORDNET = 'IDE_KEIN_DZ_ARTIKEL_BEI_MEHRD_ZE_ARTIKEL_ZUGEORDNET',
  IDE_DZ_BDB_WG_NICHT_ANGEGEBEN = 'IDE_DZ_BDB_WG_NICHT_ANGEGEBEN',
  IDE_DZ_BDB_WG_NICHT_ERMITTELT = 'IDE_DZ_BDB_WG_NICHT_ERMITTELT',
  IDE_RABATTGRP_IN_LIEFERANT_NICHT_GEFUNDEN = 'IDE_RABATTGRP_IN_LIEFERANT_NICHT_GEFUNDEN'
}

/**
 * Gibt den Titel einer Fehler-ID zurück, soweit bekannt und wenn Fehler
 * @param id oder null
 * @returns string oder null
 */
export const getMessageIDTitle = (id: string | undefined): string => {
  switch (id) {
    case MessageID.ID_OK:
      return null

    case MessageID.IDE_DZ_MAPPING:
      return 'Zielwert für fehlt'
    case MessageID.IDE_DZ_MULTIPLETARGETMAPPING:
      return 'Zielwert mehrdeutig'
    case MessageID.IDE_DZ_ABGLEICH_MEHRDEUTIG:
    case MessageID.IDE_ZE_ABGLEICH_MEHRDEUTIG:
      return 'Abgleich mehrdeutig'
    case MessageID.IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE:
      return 'Abgleich andere EAN-Gruppe'
    case MessageID.IDE_DZ_EAN_KONFLIKT:
      return 'EAN-Konflikt'
    case MessageID.IDE_DZ_BT_NICHT_ERMITTELT:
      return 'Betriebstyp nicht ermittelbar'
    case MessageID.IDE_DZ_BT_ERMITTELT:
      return 'Betriebstyp zu prüfen'
    case MessageID.IDE_DZ_BT_ANDERER_ERMITTELT:
      return 'Anderer Betriebstyp ermittelt'
    case MessageID.IDE_DZ_BTNUMMER_KONFLIKT:
      return 'BtNummer bereits vergeben'
    case MessageID.IDE_DZ_INCONSISTEN_MES:
      return 'Mengeneinheit inkonsistent'
    case MessageID.IDE_DZ_ARTIKEL_ARCHIVIERT:
      return 'Archivierter Artikel'
    case MessageID.IDE_DZ_EKPREISE_NOCHNICHTGEHOLT:
      return 'Ek-Preise noch nicht geholt'
    case MessageID.IDE_DZ_MEHRERE_WURZEL_MES:
      return 'Mehrere Wurzel-MES'
    case MessageID.IDE_DZ_FEHLENDE_ME:
      return 'Mengeneinheit unbekannt'
    case MessageID.IDE_KEIN_DZ_ARTIKEL_BEI_MEHRD_ZE_ARTIKEL_ZUGEORDNET:
      return 'Listungsartikel bereits anderen Artikel zugeordnet'
    case MessageID.IDE_DZ_BDB_WG_NICHT_ANGEGEBEN:
      return 'Listungswarengruppe fehlt'
    case MessageID.IDE_DZ_BDB_WG_NICHT_ERMITTELT:
      return 'Listungswarengruppe nicht in ERp übertragbar'
    case MessageID.IDE_RABATTGRP_IN_LIEFERANT_NICHT_GEFUNDEN:
      return 'Rabattgruppe fehlt in Lieferanten'
    default:
      return id
  }
}
