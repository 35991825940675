import { Button, ButtonProps } from './Button'

export type ApplyButtonProps = {
  disabled?: ButtonProps['disabled']
  visible?: ButtonProps['visible']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  size?: ButtonProps['size']
}

export const ApplyButton = (props: ApplyButtonProps) => (
  <Button {...props} label="Übernehmen" variant="contained" color="primary" />
)
