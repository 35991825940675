import { safeExecute } from '@utils/utils'
import { useCallback, useEffect, useRef } from 'react'
import { SnackbarType, useSnackbarEx } from './snackbarex'

export const useDownloader = () => {
  const { enqueMsg } = useSnackbarEx()

  const iframesRef = useRef([])

  const download = useCallback(
    (src: string) => {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      iframesRef.current.push(iframe)

      iframe.onload = (ev) => {
        enqueMsg('Download war nicht erfolgreich', SnackbarType.error)
      }

      iframe.src = src

      enqueMsg(
        <span>
          <span>Download ist in Vorbereitung...</span>
          <br />
          <span>Beachten Sie Hinweis in Ihrem Browser</span>
        </span>,
        SnackbarType.info
      )
    },
    [enqueMsg]
  )

  useEffect(() => {
    const todelete = iframesRef.current
    return () => {
      todelete.forEach((iframe) => {
        safeExecute(() => document.body.removeChild(iframe))
      })
    }
  }, [])

  return [download]
}
