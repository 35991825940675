import { Grid } from '@mui/material'
import { LieferantAboField } from '@one/components/LieferantAbo/LieferantAboField'
import { EkKonditionenListeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import { TextField } from '@utils/ui/fields/TextField'

export type EkPreisUeberichtParamsProps = {
  value: EkKonditionenListeSearcherCriteriaJson
  onChange: (e: any) => void
  onSearch: VoidFunction
}

export const EkPreisUebersichtParams = ({
  value,
  onChange,
  onSearch
}: EkPreisUeberichtParamsProps) => {
  return (
    <ShortCutHandler shortcuts={{ Enter: onSearch }}>
      <CardEx
        title="Einkaufspreispflege"
        backLink
        collapsiable
        bottomActions={<SearchButton onClickVoid={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <LieferantAboField
              value={value.lieferant}
              label="Lieferant"
              name="lieferant"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField fullWidth name="name" label="Name" value={value.name} onChange={onChange} />
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <DateField
              // autoOk
              label="Gültig von"
              disableToolbar
              value={value.gueltigVon}
              name="gueltigVon"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <DateField
              // autoOk
              label="Gültig bis"
              disableToolbar
              value={value.gueltigBis}
              name="gueltigBis"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Checkbox
              label="Auch freigegebene"
              size="small"
              checked={value.abgeschlossene}
              name="abgeschlossene"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
