/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material'
import { StandortField } from '@one/components/common/StandortField'
import { HkmEnum } from '@one/enums/HkmEnum'
import { VkPreisQuelle } from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { dataFromEvent, singleFromSet } from '@utils/utils'
import { useCallback, useEffect, useMemo } from 'react'
import { VkPreisPflegeFrachtUndSkonto } from './VkPreisPflegeFrachtUndSkonto'
import { VkPreisPflegeTable } from './VkPreisPflegeTable'
import { VkPreisStaffel } from './VkPreisStaffel'
import { emptyVkPreisBlatt, VkPreiseBearbeitenEdit, VkPreisEdit } from './model/VkPreisTypes'
import { VKPreisUsecase } from './model/VkPreisUsecase'
import { formatDateRange } from '@utils/dateutils'
import Grid from '@mui/material/Grid2'

export interface VkPreisPflegeBodyProps {
  model: VkPreiseBearbeitenEdit
  dispatch: (action: ModelAction) => void
  onSelect?: (next?: any) => void
  selected?: Set<VkPreisEdit>
  setSelectedStandortId: (id: number) => void
  selectedStandortId: number
}

export const VkPreisPflegeBody = ({
  model,
  dispatch,
  selected,
  onSelect,
  setSelectedStandortId,
  selectedStandortId
}: VkPreisPflegeBodyProps) => {
  const {
    neueKondi,
    standorte,
    standort0Id,
    kalkulationsvorschlaege,
    rundungsregeln,
    preisgruppen,
    forEinzelhandel,
    altePreise
  } = model

  const standortBlatt =
    neueKondi.blaetter.find((b) => b.standortId === selectedStandortId) || emptyVkPreisBlatt

  const standortBlattVorg = altePreise?.preisblaetter?.find(
    (a) => a.standortId === selectedStandortId
  )

  const standortPreise = standortBlatt.preise.filter(
    (p) => p.staffelmenge === 0 && (model.forEinzelhandel ? p.brutto && !p.kasse : true)
  )

  console.log('SEEE', selectedStandortId, standortBlattVorg, altePreise?.preisblaetter)

  const hasError = model.error != null

  const readonly =
    hasError ||
    model.freigegeben ||
    (standortBlatt.warAbgeschlossen && standortBlatt.abgeschlossen) ||
    !standortBlatt.standortVk

  const selectedPreis = singleFromSet(selected)

  const standortListe = useMemo(
    () =>
      standorte
        ? standorte.map((s) => ({
            ...s,
            hatPreis: !!(neueKondi.blaetter.find((b) => b.standortId === s.id) || {}).standortVk
          }))
        : [],
    [standorte, neueKondi.blaetter]
  )

  const usedStandortIds = useMemo(
    () => new Set(neueKondi.blaetter.map((b) => b.standortId)),
    [neueKondi.blaetter]
  )

  const ekFreiHaus = standortBlatt.effektiverEkFreiHaus
  const listenpreis = standortBlatt.effektiverListenpreis

  const setPreisValue = useCallback(
    (m, name, value) => {
      dispatch({
        type: VKPreisUsecase.UPDATEPREISVALUE,
        standortId: selectedStandortId,
        preis: m,
        name,
        value
      })
    },
    [selectedStandortId, dispatch]
  )

  const setBlattValue = useCallback(
    (e) => {
      const { name, value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.UPDATEBLATTVALUE,
        standortId: selectedStandortId,
        name,
        value
      })
    },
    [selectedStandortId, dispatch]
  )

  const setFrachtkosten = useCallback(
    (e) => {
      const { name, value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.UPDATEFRACHTKOSTEN,
        standortId: selectedStandortId,
        name,
        value
      })
    },
    [dispatch, selectedStandortId]
  )

  const setKalkulationsvorschlag = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.SETPREISBLATTKALKULATIONSVORSCHLAG,
        standortId: selectedStandortId,
        value
      })
    },
    [dispatch, selectedStandortId]
  )

  const setQuelle = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.SETQUELLE,
        standortId: selectedStandortId,
        value
      })
    },
    [dispatch, selectedStandortId]
  )

  const setFestpreis = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.SETFESTPREIS,
        standortId: selectedStandortId,
        value
      })
    },
    [dispatch, selectedStandortId]
  )

  const setRundungsregel = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.UPDATERUNDUNGSREGEL,
        standortId: selectedStandortId,
        value
      })
    },
    [dispatch, selectedStandortId]
  )

  const setReferenzPreisGruppeId = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      dispatch({
        type: VKPreisUsecase.UPDATEREFERENZPREIS,
        standortId: selectedStandortId,
        value
      })
    },
    [dispatch, selectedStandortId]
  )

  const alleAbgeschlossen =
    neueKondi.blaetter.filter((b) => b.abgeschlossen).length ===
    neueKondi.blaetter.filter((b) => b.standortVk).length

  const abgeschlossenText = useMemo(
    () =>
      `Abgeschlossene Standorte: ${neueKondi.blaetter.filter((b) => b.abgeschlossen).length}/${
        neueKondi.blaetter.filter((b) => b.standortVk).length
      }`,
    [neueKondi.blaetter]
  )

  useEffect(() => {
    setSelectedStandortId(standort0Id)
  }, [setSelectedStandortId, standort0Id])

  const header = useMemo(() => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid container spacing={1} paddingX={1} alignItems="center" size={{ xs:12 }}>
          <Grid flexGrow={1}>
            <Typography variant="h6">Verkaufspreise</Typography>
          </Grid>
          {standortBlattVorg?.gueltigVon && (
            <Grid flexGrow={1} alignContent="end" textAlign="right">
              <Typography variant="body2">
                Gültigkeit alter Preis:&nbsp;
                {formatDateRange(standortBlattVorg?.gueltigVon, standortBlattVorg?.gueltigBis)}
              </Typography>
            </Grid>
          )}
          <Grid>
            <Typography
              key="abgeschlossen"
              variant="subtitle2"
              color={alleAbgeschlossen ? 'primary' : 'error'}
            >
              {abgeschlossenText}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} paddingX={1} marginTop={-3}>
          <Grid size={{ md:12, lg:4 }}>
            <StandortField
              label="Standort"
              value={selectedStandortId}
              options={standortListe}
              usedStandordIds={usedStandortIds}
              onChange={(e) => setSelectedStandortId(e.value)}
              withButtons
              required
              fullWidth
            />
          </Grid>
          <Grid size={{ md:12, lg:4 }}>
            <AutocompleteEx
              label="Rundungsregel"
              name="rundungsregelId"
              value={standortBlatt.rundungsregelId}
              options={rundungsregeln}
              onChange={setRundungsregel}
              optionLabel="name"
              optionValue="id"
              disabled={readonly || !standortBlatt.standortVk}
              fullWidth
              required
            />
          </Grid>
          {model.forEinzelhandel ? (
            <Grid size={{ md:12, lg:4 }}>
              <SelectEnumField
                label="Quelle"
                name="quelle"
                value={standortBlatt.quelle}
                onChange={setQuelle}
                disabled={readonly || !standortBlatt.standortVk}
                fullWidth
                noClear
                notNull
                enumType={HkmEnum.VkPreisQuelle}
              />
            </Grid>
          ) : (
            <Grid size={{ md:12, lg:4 }}>
              <AutocompleteEx
                name="kalkulationsvorschlagId"
                label="Kalkulationsvorschlag"
                value={standortBlatt.kalkulationsVorschlagId}
                options={kalkulationsvorschlaege}
                onChange={setKalkulationsvorschlag}
                emptyText="Keiner"
                optionLabel="name"
                optionValue="id"
                disabled={readonly || !standortBlatt.standortVk}
                fullWidth
              />
            </Grid>
          )}
        </Grid>

        <Grid container direction="row" spacing={2} marginTop={-5} alignItems="center">
          <Grid>
            <Checkbox
              size="small"
              label="Standort VK"
              name="standortVk"
              checked={!!standortBlatt.standortVk}
              onChange={setBlattValue}
              disabled={hasError || model.freigegeben || standortBlatt.defaultStandort}
            />
          </Grid>
          <Grid>
            <Checkbox
              label="Abgeschlossen"
              size="small"
              disabled={hasError || model.freigegeben}
              checked={!!standortBlatt.abgeschlossen}
              name="abgeschlossen"
              onChange={setBlattValue}
            />
          </Grid>

          {model.forEinzelhandel && (
            <Grid>
              <Checkbox
                label="Festpreis"
                name="festpreis"
                checked={standortBlatt.festpreis}
                onChange={setFestpreis}
                disabled={readonly || standortBlatt.quelle === VkPreisQuelle.LISTUNG}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }, [
    alleAbgeschlossen,
    abgeschlossenText,
    selectedStandortId,
    standortListe,
    standortBlatt.rundungsregelId,
    standortBlatt.standortVk,
    standortBlatt.kalkulationsVorschlagId,
    standortBlatt.defaultStandort,
    standortBlatt.abgeschlossen,
    standortBlatt.quelle,
    standortBlatt.festpreis,
    rundungsregeln,
    setRundungsregel,
    readonly,
    kalkulationsvorschlaege,
    setKalkulationsvorschlag,
    setBlattValue,
    hasError,
    model.freigegeben,
    model.forEinzelhandel,
    setQuelle,
    setFestpreis,
    setSelectedStandortId
  ])

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      height="100%"
      wrap="nowrap"
      sx={{ height: 'max-content', minHeight: '100%' }}
    >
      <Grid style={{ flexGrow: '1' }}>
        <CardEx header={header} height="100%" minHeight="500px">
          <ScrollPanel>
            <VkPreisPflegeTable
              preise={standortPreise}
              setPreisValue={setPreisValue}
              referenzPreisgruppeId={standortBlatt.referenzPreisgruppeId}
              setReferenzPreisGruppeId={setReferenzPreisGruppeId}
              preisgruppen={preisgruppen || []}
              basisPreisgruppe={neueKondi.basisPreisgruppe}
              readonly={readonly}
              kalkulationsvorschlagAktiv={standortBlatt.kalkulationsVorschlagId != null}
              selected={selected}
              onSelect={onSelect}
              ekFreiHaus={ekFreiHaus}
              listenpreis={listenpreis}
              forEinzelhandel={model.forEinzelhandel}
              quelle={standortBlatt.quelle}
              festpreis={standortBlatt.festpreis}
            />
          </ScrollPanel>
        </CardEx>
      </Grid>
      <Grid minWidth={'40%'} marginBottom={-1}>
        <Grid
          container
          direction="column"
          height="100%"
          spacing={1}
          alignContent="start"
          justifyContent="stretch"
        >
          {!forEinzelhandel && (
            <Grid width="100%" flexShrink={1}>
              <VkPreisPflegeFrachtUndSkonto
                frachtkosten={standortBlatt.frachtkosten || {}}
                setFrachtkosten={setFrachtkosten}
                readonly={readonly}
                zufuhrMultis={model.zufuhrMultis}
                currency={model.currency}
              />
            </Grid>
          )}
          <Grid flexGrow={1} width={forEinzelhandel ? '100%' : undefined}>
            <VkPreisStaffel
              fullHeight
              preisblatt={standortBlatt}
              selectedPreis={selectedPreis}
              forEinzelhandel={model.forEinzelhandel}
              readonly={
                readonly ||
                standortBlatt.kalkulationsVorschlagId != null ||
                selectedPreis == null ||
                (selectedPreis && selectedPreis.kasse)
              }
              dispatch={dispatch}
              mengeneinheiten={model.mengeneinheiten || []}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
