import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Tooltip
} from '@mui/material'
import { marginMinus12 } from '@utils/ui/styles'
import { stopPropagation } from '@utils/ui/uiutils'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ labelNotDisabled: boolean }>()((theme, { labelNotDisabled }) => ({
  label: {
    wordBreak: 'break-word',
    hyphens: 'auto',
    '.Mui-disabled.MuiFormControlLabel-label': labelNotDisabled
      ? {
          color: theme.palette.text.primary
        }
      : {}
  }
}))

export type CheckboxProps = {
  id?: string
  label?: FormControlLabelProps['label']
  labelPlacement?: FormControlLabelProps['labelPlacement']
  name?: string
  checked?: boolean
  onChange?: (e?: any) => void
  color?: MuiCheckboxProps['color']
  style?: Partial<React.CSSProperties>
  title?: string
  /** 3. Wert für Checkbox, wenn checked=null wird die Checkbox alternativ dargestellt */
  indeterminate?: boolean
  disabled?: boolean
  size?: MuiCheckboxProps['size']
  className?: string
  compact?: boolean
  /** setzt die normale Schriftfarbe, auch wenn die Checkbox sonst deaktiviert ist */
  labelNotDisabled?: boolean
  icon?: React.ReactNode
  checkedIcon?: React.ReactNode
  indeterminateIcon?: React.ReactNode
  paddingTop?: string | number | null
  inputProps?: any
  backgroundColor?: string
  visible?: boolean
}

export const Checkbox = ({
  id,
  label,
  labelPlacement,
  name,
  checked,
  onChange,
  color,
  style,
  title,
  indeterminate,
  disabled,
  size,
  className,
  compact,
  icon,
  inputProps,
  checkedIcon,
  indeterminateIcon,
  labelNotDisabled = false,
  paddingTop = '10px',
  backgroundColor,
  visible = true
}: CheckboxProps) => {
  const { classes } = useStyles({ labelNotDisabled })

  if (!visible) {
    return null
  }

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      e.stopPropagation()
      onChange({ name, value: !checked })
    }
  }

  const onToggle = indeterminate
    ? (e: any) => {
        if (checked === false) {
          stopPropagation(e)
          onChange({ name, value: null })
        }
      }
    : undefined

  const cs = label == null ? style : null
  const frame = backgroundColor
    ? {
        backgroundColor,
        paddingRight: 12,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 4
      }
    : null

  let checkBox = (
    <MuiCheckbox
      id={id}
      checked={!!checked}
      onChange={onChange}
      onClick={onToggle}
      name={name}
      color={color}
      indeterminate={indeterminate && checked == null}
      icon={icon}
      checkedIcon={checkedIcon}
      indeterminateIcon={indeterminateIcon}
      disabled={disabled}
      size={size}
      style={{ ...cs, margin: compact && label == null ? -12 : null }}
      onKeyDown={onKeyDown}
      inputProps={inputProps}
    />
  )

  if (label != null) {
    checkBox = (
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        disabled={disabled}
        className={`${className} ${classes.label}`}
        style={{
          paddingBottom: compact && 4,
          ...(compact && marginMinus12),
          height: '100%',
          paddingTop: paddingTop,
          ...frame,
          ...style
        }}
        control={checkBox}
      />
    )
  }

  if (title) {
    checkBox = (
      <Tooltip title={title} arrow>
        {checkBox}
      </Tooltip>
    )
  }

  return checkBox
}
