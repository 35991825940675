import { AuditRevisionInfoJson } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { asNumber, safeArray } from '@utils/utils'
import { SelectField } from './fields/SelectField'

export interface HistoricDataPagerProps {
  revisions: AuditRevisionInfoJson[]
  to: (data: string) => void
  currentRevision: number | string
}

export const HistoricDataPager = ({ currentRevision, to, revisions }: HistoricDataPagerProps) => {
  const revisionsSafe = safeArray(revisions)
  const revnr = asNumber(currentRevision)
  const value = revisionsSafe.find((revision) => revision.id == revnr)

  const renderValue = (v: AuditRevisionInfoJson) => {
    if (revisionsSafe.length <= 1 || v == null) {
      return (
        <span>
          Aktueller Stand: <b>{formatDateTime(v.von)}</b> bis <b>{formatDateTime(v.bis, true)}</b>
        </span>
      )
    }
    return (
      <span>
        Revision {v.nr}: <b>{formatDateTime(v.von)}</b> bis <b>{formatDateTime(v.bis, true)}</b>
      </span>
    )
  }

  const onSelect = (e) => {
    const value = e.target.value
    if (value) {
      to(value)
    }
  }

  return (
    <SelectField<AuditRevisionInfoJson>
      name="revision"
      value={value}
      onChange={onSelect}
      optionValue={(v) => v}
      options={revisions}
      renderItem={renderValue}
      renderSelected={renderValue}
      notNull
      withButtons="reverse"
    />
  )
}
