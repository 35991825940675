import { ExportErrorToErpVkSperreJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { useContext, useMemo } from 'react'
import { ExportFehlerView } from './ExportFehlerView'

export const ExportVkSperreFehlerUebersichtView = () => {
  const { et } = useEnums()
  const { isPIMModel } = useContext(AppContext)

  const columns = useMemo(
    () =>
      [
        {
          field: 'standort.nr',
          header: 'Standort-Nr.'
        },
        {
          field: 'artikel.hageNummer',
          header: 'hage-Nr.'
        },
        {
          field: 'vkSperre',
          header: 'Sperre',
          type: 'boolean'
        },
        {
          field: 'vkSperreAb',
          header: 'Sperre ab',
          type: 'date'
        },
        {
          field: 'vkSperreBis',
          header: 'Sperre bis',
          type: 'date'
        },
        {
          field: 'vkSperreBis',
          header: 'Begründung'
        },
        {
          off: isPIMModel,
          field: 'artikel.btNummer',
          header: 'Artikel-Nr.'
        },
        {
          field: 'artikel.bez1',
          header: 'Bez1'
        },
        {
          field: 'artikel.bez2',
          header: 'Bez2'
        },
        {
          field: 'artikel.bez3',
          header: 'Bez3'
        },
        {
          field: 'artikel.bez4',
          header: 'Bez4'
        }
      ] as Column<ExportErrorToErpVkSperreJson>[],
    [et, isPIMModel]
  )

  return (
    <ExportFehlerView
      columns={columns}
      title="Vk-Sperre Exportfehler"
      rest="export/vksperre"
      name="VKSperreExportFehlerTable"
    />
  )
}
