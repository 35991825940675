import { api } from '@one/api'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { toDate } from '@utils/dateutils'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useCallback, useEffect, useState } from 'react'
import { ProtokolleUebersichtParams } from './ProtokolleUebersichtParams'
import { ProtokolleUebersichtTable } from './ProtokolleUebersichtTable'

export const ProtokolleUebersichtView = () => {
  const [result, setResult] = useState([])
  const [initialSearchValues, setInitialSearchValues] = useState()
  const [limited, setLimited] = useState(true)

  const [apiCall, apiBusy] = useApiCaller(api)

  const getInitialData = useCallback(() => {
    apiCall<any>({
      method: 'GET',
      rest: `/protokoll/uebersicht/open`,
      exclusive: ApiExclusive.BLOCK,
      onSuccess: (data) => {
        if (data.searcher) {
          const minStartedAt = data.searcher.criteria.minStartedAt
            ? toDate(new Date(data.searcher.criteria.minStartedAt))
            : null
          const maxStartedAt = data.searcher.criteria.maxStartedAt
            ? toDate(new Date(data.searcher.criteria.maxStartedAt))
            : null
          setInitialSearchValues({ ...data.searcher.criteria, minStartedAt, maxStartedAt }) // default-criteria vom server
          setResult(data.searcher.result) // default-ergebnismenge
        }
      }
    })
  }, [apiCall])

  const handleSearch = useCallback(
    (sv) => {
      apiCall<any>({
        method: 'POST',
        rest: `/protokoll/uebersicht/search`,
        data: sv,
        onErrorMsg: 'Protokoll konnten nicht geladen werden.',
        exclusive: ApiExclusive.BLOCK,
        onSuccess: (data) => {
          setResult(data.searcher.result)
          setLimited(data.searcher.limited)
        },
        onError: () => setResult([])
      })
    },
    [apiCall]
  )

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Frame space>
      <FrameRow>
        <ProtokolleUebersichtParams
          initialSearchValues={initialSearchValues}
          handleSearch={handleSearch}
        />
      </FrameRow>
      <FrameBody>
        <ProtokolleUebersichtTable values={result} loading={apiBusy} limited={limited} />
      </FrameBody>
    </Frame>
  )
}
