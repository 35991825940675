import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CreateIcon from '@mui/icons-material/Create'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StaffelwerteDialog } from '@one/components/EkPreis/EKPreisPflege/StaffelwerteDialog'
import { Button } from '@utils/ui/Buttons/Button'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { FormField } from '@utils/ui/fields/FormField'
import { makeStyles } from 'tss-react/mui'

export interface StaffelFieldProps {
  setWert?: (wert: number | { name: string; value: number }) => void
  onWerteChange?: (staffel: any) => void
  checkStaffelwerte?: () => object
  name?: string
  wert?: number
  werte?: number[]
  readonly?: boolean
  buttonMode?: boolean
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  navigate: {
    // padding: '12px',
    '&>span': {
      verticalAlign: 'middle'
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  buttons: {
    marginTop: 16,
    '&>button': {
      padding: '2px 7px',
      fontSize: '0.9rem'
    }
  },
  edit: {
    fontSize: '1rem'
  },
  chevron: {
    fontSize: '1.3rem'
  },
  label: {
    paddingRight: 12
  }
}))

export const StaffelField = ({
  werte,
  onWerteChange,
  name,
  wert,
  setWert,
  checkStaffelwerte,
  readonly = false,
  buttonMode = false
}: StaffelFieldProps) => {
  const { classes } = useStyles()

  const [DlgAnker, showDlg] = useDialogAnker()

  if (werte == null) {
    return null
  }

  let pos = werte.indexOf(wert)
  if (pos === -1) {
    pos = 0
  }

  const onPrev = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setWert(name ? { name, value: werte[pos - 1] } : werte[pos - 1])
  }

  const onNext = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setWert(name ? { name, value: werte[pos + 1] } : werte[pos + 1])
  }

  const onChange = (e, v) => {
    if (v != null && v >= 0) {
      setWert(name ? { name, value: v } : v)
    }
  }

  const onShow = () => {
    showDlg((visible, onClose) => (
      <StaffelwerteDialog
        visible={visible}
        onClose={onClose}
        value={werte}
        onChange={onWerteChange}
        checkStaffelwerte={checkStaffelwerte}
        readonly={readonly}
      />
    ))
  }
  if (buttonMode) {
    return (
      <>
        <Button
          label="Staffelwerte"
          variant="outlined"
          size="small"
          startIcon={<CreateIcon htmlColor="gray" />}
          onClick={onShow}
          disabled={werte == null || readonly}
        />
        <DlgAnker />
      </>
    )
  }

  return (
    <FormField label="Staffel (ab Menge)">
      <ToggleButtonGroup
        size="small"
        exclusive
        value={wert}
        className={classes.buttons}
        onChange={onChange}
      >
        <ToggleButton key={-97} value={-97} size="small" onClick={onPrev} disabled={pos <= 0}>
          <ChevronLeftIcon className={classes.chevron} />
        </ToggleButton>
        {werte.map((w) => (
          <ToggleButton key={w} value={w}>
            {w}
          </ToggleButton>
        ))}
        {!readonly && (
          <ToggleButton key={-98} value={-98} title="Staffel ändern" onClick={onShow}>
            <CreateIcon className={classes.edit} fontSize="small" />
          </ToggleButton>
        )}
        <ToggleButton
          key={-99}
          value={-99}
          size="small"
          onClick={onNext}
          disabled={pos + 1 >= werte.length}
        >
          <ChevronRightIcon className={classes.chevron} />
        </ToggleButton>
      </ToggleButtonGroup>
      <DlgAnker />
    </FormField>
  )
}
