import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import { EkKonditionenListeDisplayJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Permission } from '@utils/ui/Permission'
import { useMemo } from 'react'

export interface EkPreisUebersichtTableProps {
  result: SearcherResultType<EkKonditionenListeDisplayJson>
  onFreigeben: (row: EkKonditionenListeDisplayJson) => () => void
  onAbschliessen: (row: EkKonditionenListeDisplayJson) => () => void
  onDelete: (row: EkKonditionenListeDisplayJson) => () => void
}

export const EkPreisUebersichtTable = ({
  result,
  onFreigeben,
  onAbschliessen,
  onDelete
}: EkPreisUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: ['lieferant.name1', 'lieferant.ort', 'lieferant.nummer'],
        header: 'Lieferant',
        valueGetter: (row) => formatLieferant(row.lieferant),
        body: (row: any) => <LieferantCell lieferant={row.lieferant} asLink />,
        sortable: true
      },
      {
        field: 'gueltigVon',
        header: 'Gültig ab',
        type: 'date',
        sortable: true
      },
      {
        field: 'typ',
        header: 'Typ',
        sortable: true,
        valueGetter: (row: any) => et(HkmEnum.PreisListeTyp, row.typ)
      },
      {
        field: 'angelegtUm',
        header: 'Angelegt am',
        type: 'datetime',
        sortable: true
      },
      {
        field: 'letztePflege',
        header: 'Letzte Pflege am',
        type: 'datetime',
        sortable: true
      },
      {
        field: 'anzArtikel',
        header: 'Artikel',
        type: 'number',
        sortable: true
      },
      {
        field: 'anzArtikelAbgeschlossen',
        header: 'Abgeschlossene',
        type: 'number',
        sortable: true
      },
      {
        field: 'freigegebenUm',
        header: 'Freigegeben am',
        type: 'datetime',
        sortable: true
      },
      {
        field: 'status',
        header: 'Status',
        sortable: true
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    (): DataTableAction<EkKonditionenListeDisplayJson>[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (data) => AppPaths.EkPreisPflegeFn(data.id),
        check: (row) => (!row.freigegeben ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'table_rows',
        tooltip: 'Massenbearbeitung',
        getLink: (data) => AppPaths.EkPreisMassenPflegeFn(data.id)
      },
      {
        icon: 'visibility',
        tooltip: 'Anzeigen',
        getLink: (data) => AppPaths.EkPreisPflegeFn(data.id),
        check: (row) => (row.freigegeben ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: onDelete,
        check: (row) => (!row.freigegeben ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'done_all',
        tooltip: 'Alle Abschließen',
        handler: onAbschliessen,
        check: (row) => row.kannAbschliessen
      },
      {
        icon: 'check_circle',
        tooltip: 'Freigeben',
        handler: onFreigeben,
        check: (row) => row.kannFreigeben
      }
    ],
    [onFreigeben, onAbschliessen, onDelete]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Einkaufspreisliste manuell erstellen',
        navlink: AppPaths.EkPreisAnlageFn({}),
        icon: 'add'
      },
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Einkaufspreisliste importieren',
        navlink: AppPaths.EkPreisImportUbersicht,
        icon: 'backup'
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="EkPreisUebersichtTable"
      title="Preislistenübersicht"
      filterMode="both"
      localStateName="EkPreisUebersichtTable"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      dense
      initialOrderBy="name"
      result={result}
    />
  )
}
