import { Divider, Grid } from '@mui/material'
import { ErpSystemJson, GesellschafterJson } from '@one/typings/apiTypings'
import { UpdModelFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { useCallback, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ErpSystemForm } from './ErpSystemForm'

const useStyles = makeStyles()({
  divider: {
    margin: '40px 0'
  },
  newButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: '40px'
  }
})

interface OrganisationErpDetailProps {
  erpSysteme: ErpSystemJson[]
  updModel: UpdModelFn<GesellschafterJson>
}

export const OrganisationErpDetail = ({ erpSysteme, updModel }: OrganisationErpDetailProps) => {
  const { classes } = useStyles()
  const [addNewErp, setAddNewErp] = useState(false)

  const handleSaveErp = useCallback(
    (erpSystem?: ErpSystemJson) => {
      if (erpSystem) {
        updModel((oldModel) => {
          const index = oldModel.erpSysteme.findIndex((value) => value.id === erpSystem.id)
          const erpSysteme = [...oldModel.erpSysteme]
          if (index === -1) {
            erpSysteme.unshift(erpSystem)
            return {
              ...oldModel,
              erpSysteme
            }
          } else {
            erpSysteme.splice(index, 1, erpSystem)
            return { ...oldModel, erpSysteme }
          }
        })
        setAddNewErp(false)
      }
    },
    [updModel]
  )

  const onClickAddErp = useCallback(() => {
    setAddNewErp(true)
  }, [])

  const onCancel = useCallback(() => {
    setAddNewErp(false)
  }, [])

  return (
    <CardEx title="ERP-System Verbindungen" height="100%">
      <ScrollPanel>
        <Grid container direction="row" spacing={1} marginBottom={-1}>
          <Grid item xs={12} className={classes.newButton}>
            <Button
              onClick={onClickAddErp}
              variant="outlined"
              tooltip="Neue Verbindung zu einem ERP-System anlegen"
              label="Neue Verbindung"
            />
          </Grid>
          {addNewErp && (
            <Grid item xs={12}>
              <ErpSystemForm onSaveErp={handleSaveErp} onCancel={onCancel} />
              <Divider className={classes.divider} light />
            </Grid>
          )}
          {erpSysteme.map((erpSystem) => (
            <Grid item xs={12} key={`erp-${erpSystem.id}`}>
              <ErpSystemForm erpSystem={erpSystem} onSaveErp={handleSaveErp} />
              <Divider className={classes.divider} light />
            </Grid>
          ))}
        </Grid>
      </ScrollPanel>
    </CardEx>
  )
}
