import { Grid } from '@mui/material'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelListeDisplayJson,
  ArtikelListeSearcherCriteriaJson,
  NeonKontext
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useSearcherState } from '@utils/searcher'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { SelectButton } from '@utils/ui/Buttons/SelectButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { DialogEx } from '@utils/ui/DialogEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { arrayFromSet, nameOf, singleFromSet } from '@utils/utils'
import { useMemo, useState } from 'react'
import { ArtikelListeSuchForm } from './ArtikelListeSuchForm'

export interface ArtikelListeSuchDialogProps {
  onClose: (selected?: any | any[]) => void
  multiple?: boolean
  open?: boolean
  filter?: (row: ArtikelListeDisplayJson) => boolean
  kontext?: NeonKontext
}

export const ArtikelListeSuchDialog = ({
  onClose,
  open = false,
  multiple = false,
  filter,
  kontext
}: ArtikelListeSuchDialogProps) => {
  const { et } = useEnums()

  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    ArtikelListeSearcherCriteriaJson,
    ArtikelListeDisplayJson
  >({
    api,
    url: '/artikelliste',
    initialParams: {
      name: undefined,
      kontext
    },
    valueDecorator: (path, value) => {
      return value && path === 'name' ? value.toUpperCase() : value
    },
    filter
  })

  const [selected, setSelected] = useState(new Set())

  const handleAuswahl = () => {
    onClose(multiple ? arrayFromSet(selected) : singleFromSet(selected))
  }

  const handleCancelFilter = () => {
    onClose()
  }

  const shortcuts = {
    Enter: search
  }

  const onRowDoubleClick = (row, e) => {
    e.preventDefault()
    e.stopPropagation()
    onClose(multiple ? [row] : row)
  }

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          header: 'Name',
          sortable: true
        },
        {
          field: 'typ',
          header: 'Typ',
          sortable: true,
          body: (row: any) => et(HkmEnum.ArtikelListeTyp, row.typ)
        },
        {
          field: nameOf<ArtikelListeDisplayJson>('artikelAnzahl'),
          header: 'Anzahl Artikel',
          type: 'number',
          sortable: true
        }
      ] as Column<ArtikelListeDisplayJson>[],
    [et]
  )

  const actions = (
    <>
      <SelectButton onClick={handleAuswahl} disabled={selected.size === 0} />
      <CancelButton onClick={handleCancelFilter} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={handleCancelFilter}
      maxWidth="md"
      fullWidth
      title="Artikelliste auswählen"
      actions={actions}
      height="400px"
    >
      <ShortCutHandler shortcuts={shortcuts}>
        <ArtikelListeSuchForm searchValues={criteria} onChange={onCriteriaChange} />
        <Grid container justifyContent="end" spacing={2}>
          <Grid item>
            <SearchButton onClick={search} />
          </Grid>
        </Grid>
      </ShortCutHandler>
      <DataTableCard
        borderless
        title="Suchergebnis"
        name="ArtikelListeTable"
        columns={columns}
        selectMode={multiple ? 'multi' : 'single'}
        selectCol
        onSelect={setSelected}
        dense
        onRowDoubleClick={onRowDoubleClick}
        result={result}
      />
    </DialogEx>
  )
}
