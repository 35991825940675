import { AttributeChange, CompositeAttributeChange } from '@one/typings/apiTypings'
import {
  AttributeTreeItem,
  ChangeTreeItem,
  ChangesTreeView,
  CompositeTreeItem
} from './ChangesTreeView'

const names = ['String', 'Long', 'Date', 'DateTime', 'BigDecimal']

const formatValue = (attr: AttributeChange, old: boolean) => {
  const prefix = old ? 'old' : 'new'
  const value = names.map((name) => attr[prefix + name]).find((v) => v != null)
  return value
}

const generateCompositeAttributeChangeItems =
  (composite: CompositeAttributeChange, labelAlt: string, labelNeu: string, path: string = '/') =>
  (idgen, expandedIds, treeClasses, itemClasses) => {
    const items = []

    if (composite?.composites?.length > 0) {
      composite.composites.forEach((comp) => {
        const id = idgen()
        expandedIds.push(id)
        items.push(
          <CompositeTreeItem
            key={id}
            nodeId={id}
            path={path}
            labelText={comp.label}
            labelIcon="article"
            // labelInfo={
            //   ((comp.composites && comp.composites.length) || 0) +
            //   ((comp.attributes && comp.attributes.length) || 0)
            // }
            itemClasses={itemClasses}
            treeClasses={treeClasses}
          >
            {generateCompositeAttributeChangeItems(
              comp,
              labelAlt,
              labelNeu,
              `${path}${comp.label}/`
            )(idgen, expandedIds, treeClasses, itemClasses)}
          </CompositeTreeItem>
        )
      })
    }

    if (composite?.attributes?.length > 0) {
      composite.attributes.forEach((attr) => {
        const id1 = idgen()
        const id2 = idgen()
        const id3 = idgen()
        expandedIds.push(id1)
        const valuen = formatValue(attr, false)
        const valueo = formatValue(attr, true)
        const subpath = `${path}${attr.label}/changed/`
        items.push(
          <AttributeTreeItem
            key={id1}
            nodeId={id1}
            labelText={attr.label}
            labelIcon="toc"
            labelInfo=""
            treeClasses={treeClasses}
            itemClasses={itemClasses}
          >
            <ChangeTreeItem
              key={id2}
              nodeId={id2}
              labelText={labelAlt}
              value={valueo}
              flag={valuen == null ? false : null}
              treeClasses={treeClasses}
              itemClasses={itemClasses}
              path={subpath}
            />
            <ChangeTreeItem
              key={id3}
              nodeId={id3}
              labelText={labelNeu}
              value={valuen}
              flag={valueo == null ? true : null}
              treeClasses={treeClasses}
              itemClasses={itemClasses}
              path={subpath}
            />
          </AttributeTreeItem>
        )
      })
    }

    return items
  }

export type CompositeAttributeChangeTreeViewProps = {
  composite: CompositeAttributeChange
  labelAlt?: string
  labelNeu?: string
  zusatz?: boolean
}

export const CompositeAttributeChangeTreeView = ({
  composite,
  labelAlt,
  labelNeu
}: CompositeAttributeChangeTreeViewProps) =>
  composite ? (
    <ChangesTreeView
      generateItems={generateCompositeAttributeChangeItems(composite, labelAlt, labelNeu)}
    />
  ) : null
