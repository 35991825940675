import Grid from '@mui/material/Grid2';
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant, formatMengeneinheit } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelDatenpoolUebernahmeJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Medal } from '@utils/ui/fields/Medal'
import { singleFromSet } from '@utils/utils'
import { useContext, useMemo } from 'react'
import { UebernahmeEigenlistungDetails } from './UebernahmeEigenlistungDetails'
import { WerteCell } from "@one/components/common/ArtikelDpMengeneinheitenWerteCell";
import { ThemeContext } from "@utils/ui/Theme";

export interface UebernahmeEigenlistungTableProps {
  eintraege?: ArtikelDatenpoolUebernahmeJson[]
  selected: Set<ArtikelDatenpoolUebernahmeJson>
  setSelected: React.Dispatch<React.SetStateAction<Set<ArtikelDatenpoolUebernahmeJson>>>
}

export const UebernahmeEigenlistungTable = ({
  eintraege,
  selected,
  setSelected
}: UebernahmeEigenlistungTableProps) => {
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)

    console.log(eintraege)


  const columns = useMemo<Column<ArtikelDatenpoolUebernahmeJson>[]>(
    () => [
      {
        field: 'status',
        header: 'Status',
        valueGetter: (row) => et(HkmEnum.ArtikelDatenpoolUebernahmeStatus, row.status),
        body: (row) => <Medal text={et(HkmEnum.ArtikelDatenpoolUebernahmeStatus, row.status)} />
      },
      {
        field: 'dpArtikel.hageNummer',
        header: 'hage-Nr.'
        // align: 'right'
      },
      {
        field: 'dpArtikel.gtin',
        header: 'EAN'
        // align: 'right'
      },
      {
        field: 'dpArtikel.lan',
        header: 'IAN'
        // align: 'right'
      },
      {
        field: 'dpArtikel.lieferantArtikelBez',
        header: 'Bezeichung'
      },
      {
        field: 'dpArtikel.werkslistenPreis',
        header: 'Werkslistenpreis',
        type: 'money2'
      },
      {
        field: 'dpArtikel.einkaufMeName',
        header: 'Einkauf-ME (DP)',
        headerTip: 'Einkaufsmengeneinheit Datenpool'
      },
      {
        field: 'dpArtikel.einkaufME',
        header: 'Einkauf-ME (ERP)',
        headerTip: 'Einkaufsmengeneinheit ERP',
        valueGetter: (row) => formatMengeneinheit(row.einkaufME)
      },
      {
        field: 'dpArtikel.lieferantWarenGruppe',
        header: 'Warengruppe'
      },
      {
        field: 'dpArtikel.lieferant',
        header: 'Lieferant',
        body: (row) => <LieferantCell lieferant={row.lieferant} asLink />,
        valueGetter: (row) => formatLieferant(row.lieferant)
      },
        {
            field: 'dpArtikel.supplierDistributionChannel',
            header: 'Vertriebsweg Lieferant',
            headerTip: 'Vertriebsweg Lieferant'
        },
        {
            field: 'dpArtikel.supplierCountryOfOrigin',
            header: 'Land Lieferant',
            headerTip: 'Land des Lieferanten'
        },
        {
            field: 'dpArtikel.gln',
            header: 'GLN',
            headerTip: 'Gesellschafter Nummer'
        },
        {
            field: 'dpArtikel.additionalGtin',
            header: 'Zusatz EAN',
            headerTip: 'Zusatz EAN x zur Haupt-EAN'
        },
        {
            field: 'dpArtikel.ebmeSpecialist',
            header: 'EBME - FH',
            headerTip: 'Einkaufs-Berechnungsmengeneinheit (EBME) - Fachhandel'
        },
        {
            field: 'dpArtikel.factoryPriceInternational',
            header: 'Werkslistenpreis (international)',
            headerTip: 'Werkslistenpreis (international)'
        },
        {
            field: 'dpArtikel.factoryPriceDE',
            header: 'Werkslistenpreis - DE',
            headerTip: 'Werkslistenpreis - DE'
        },
        {
            field: 'dpArtikel.factoryPriceAT',
            header: 'Werkslistenpreis - AT',
            headerTip: 'Werkslistenpreis - AT'
        },
        {
            field: 'dpArtikel.factoryPriceLUX',
            header: 'Werkslistenpreis - LUX',
            headerTip: 'Werkslistenpreis - LUX'
        },
        {
            field: 'dpArtikel.definitionBaseUnitOfMeasure',
            header: 'Basismengeneinheit',
            headerTip: 'Definition der Basismengeneinheit'
        },
        {
            field: 'dpArtikel.taxIndicatorDE',
            header: 'Steuerindikator - DE',
            headerTip: 'Steuerindikator - DE'
        },
        {
            field: 'dpArtikel.taxIndicatorAT',
            header: 'Steuerindikator - AT',
            headerTip: 'Steuerindikator - AT'
        },
        {
            field: 'dpArtikel.taxIndicatorLUX',
            header: 'Steuerindikator - LUX',
            headerTip: 'Steuerindikator - LUX'
        },
        {
            field: 'dpArtikel.statisticalCode',
            header: 'Statistische Warennummer',
            headerTip: 'Statistische Warennummer'
        },
        {
            field: 'dpArtikel.minimumOrderQuantityFH',
            header: 'Mindestbestellmenge - FH',
            headerTip: 'Mindestbestellmenge (VE) - Fachhandel'
        },
        {
            field: 'dpArtikel.definitionOfStandardDeliveryUnit1',
            header: 'Liefermengeneinheit 1 - FH',
            headerTip: 'Definition Standard-Liefermengeneinheit 1 - Fachhandel'
        },
        {
          field:'dpArtikel.artikelDpMengeneinheiten',
          header:'Mengeneinheiten',
          headerTip:'Tabelle (B) ist die Basismengeneinheit',
          cellPadding:0,
          valueGetter:(row)=>
            Object.entries(row.dpArtikel?.artikelDpMengeneinheiten).map((r,v:any)=>r[0]),
          body:(row) => (
            <WerteCell
              werte={row.dpArtikel.artikelDpMengeneinheiten}
              darkMode={darkMode}
            />
          )
        },
        {
            field: 'dpArtikel.priceValidityFactoryPrice',
            header: 'Preisgültigkeit ab',
            headerTip: 'Preisgültigkeit ab (Werkslistenpreis)'
        },
        {
            field: 'dpArtikel.priceBasisFH',
            header: 'Preisbasis - FH',
            headerTip: 'Preisbasis - Fachhandel'
        },
        {
            field: 'dpArtikel.articleType',
            header: 'Artikelart',
            headerTip: 'Artikelart'
        }
    ],
    [et]
  )

  const single = singleFromSet(selected)
  return (
    <Grid container direction="row" height="100%" spacing={1}>
      <Grid flexGrow={1}>
        <DataTableCard
          name="EigenlistungUebersichtTable"
          height="100%"
          title="Eigenlistung"
          value={eintraege}
          columns={columns}
          selectCol
          selectMode="multi"
          selected={selected}
          onSelect={setSelected}
          dense
          initialOrderBy="name"
          filterMode="both"
          hover
          localStateName="EigenlistungUebersichtTable"
          emptyMessage={eintraege?.length === 0 ? 'Keine Artikel gefunden' : null}
          borderless
        />
      </Grid>
      {single && single.fehler ? (
        <Grid size={{ lg:4, md:12, sm:12, xs:12 }}>
          <UebernahmeEigenlistungDetails artikel={single} />
        </Grid>
      ) : null}
    </Grid>
  )
}
