import { ExitToApp, HelpCenterOutlined, Menu } from '@mui/icons-material'
import {AppBar, Avatar, Box, Toolbar, Typography} from '@mui/material'
import { green, red } from '@mui/material/colors'
import { AppContext } from '@utils/ui/App/AppContext'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { ThemeContext } from '@utils/ui/Theme'
import clsx from 'clsx'
import { useContext, ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    height: 48
  },
  adminMode: {
    backgroundColor: red[400] + ' !important'
  },
  toolbar: {
    paddingLeft: '8px !important',
    paddingRight: '8px !important'
  },
  title: {
    display: 'flex',
    marginRight: 8,
    flexGrow: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&>img': {
      marginRight: 8,
      height: 32
    }
  },
  item: {
    marginRight: 8
  },
  h: {
    lineHeight: '1.2em !important',
    paddingTop: 8
  },
  s: {
    verticalAlign: 'top',
    lineHeight: '1em !important'
  },
  menuButton: {
    marginRight: 12,
    '& svg': { color: 'white' }
  },
  company: {
    marginRight: 12,
    marginTop: 2
  },
  left: {
    marginLeft: 12,
    marginRight: 12
  },
  companySelect: {
    '& label': {
      color: 'rgba(255, 255, 255, 0.83)  !important'
    },
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.43) !important'
    },
    '&>div': {
      color: 'white',
      // fontSize: '1.25rem',
      fontWeight: 500,
      '&>div': {
        paddingTop: 6,
        paddingBottom: 6
      },
      '&>svg': {
        color: 'white'
      }
    },
    minWidth: '4em',
    '&:before': {
      borderBottom: 'none !important'
    }
  },
  avatardiv: {
    // marginLeft: 6,
    marginRight: 6
  },
  avatar: {
    '&.MuiAvatar-root': {
      fontSize: '90%',
      backgroundColor: green[800]
    }
  }
}))

export interface AppHeaderProps {
  api: any
  onDrawerToggle: () => void
  helpToggle?: () => void
  keepMenuButton?: boolean
  userAvatar?: boolean
  left?: React.ReactNode
  children?: React.ReactNode
}

/**
 * Anzeige der App-Überschrift mit Benutzernamens
 *
 * @param {function} onDrawerToggle Handler function that is called when the menu button is clicked
 * @param helpToggle
 * @param api
 * @param keepMenuButton
 * @param userAvatar
 * @param left
 * @param children
 */
export const AppHeader = ({
  onDrawerToggle,
  helpToggle,
  api,
  keepMenuButton = false,
  userAvatar,
  left = null,
  children
}: AppHeaderProps) => {
  const { classes } = useStyles()

  const { session, appVersion, appTitle, appTitleShort } = useContext(AppContext) as any
  const { displayname, firstname, lastname, env, adminMode } = session || {}
  const { darkMode } = useContext(ThemeContext)

  const handleLogout = () => {
    // Aktuelle seite verlassen, um bei Anmeldung auf der Hauptseite zu laden.
    // Vermeidet, das ggf. wg. Rechte/Contexct die vorheriege nicht erlaubt ist
    history.replaceState(null, null, '#')
    api
      .post('/session/logout')
      .then(() => window.location.reload())
      .catch(() => window.location.reload())
  }
  return (
    <AppBar position="relative" className={clsx(classes.root, adminMode && classes.adminMode)}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Box sx={{ display: keepMenuButton?"block":{ xs: "block", md: "none" } }}>
          <div className={classes.menuButton}>
            <IconButton icon={<Menu />} onClick={onDrawerToggle} aria-label="Menu" />
          </div>
        </Box>

        <div className={classes.title}>
          {/* <img src="logo.png" alt="logo" /> */}
          <img src={`hagebau_${darkMode ? 'dark' : 'light'}.png`} alt="hagebau neon" />
          <div>
            <Typography variant="h6" className={classes.h}>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                {appTitleShort}
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                {appTitle}
              </Box>
            </Typography>
            <Typography variant="caption" className={classes.s}>
              {`V${appVersion}`}
            </Typography>
          </div>
          {left && <div className={classes.left}>{left}</div>}
        </div>

        {children && <div className={classes.item}>{children}</div>}

        {userAvatar ? (
          <div className={classes.avatardiv}>
            <Avatar className={classes.avatar} alt="user avatar">
              {' '}
              {`${(firstname || '').charAt(0)}${(lastname || '').charAt(0)}`}
            </Avatar>
          </div>
        ) : null}

        <div>
          <Typography variant="h6" className={classes.h}>
            {displayname}
          </Typography>
          <Typography variant="caption" className={classes.s}>
            {env}
          </Typography>
        </div>

        <IconButton
          icon={<ExitToApp />}
          color="inherit"
          aria-label="Logout"
          onClick={handleLogout}
          title="Abmelden"
        />

        {helpToggle ? (
          <IconButton
            icon={<HelpCenterOutlined />}
            color="inherit"
            aria-label="Hilfe"
            onClick={helpToggle}
            title="Hilfe"
          />
        ) : null}
      </Toolbar>
    </AppBar>
  )
}
