import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface ArtikelDetailsTabelleProps {
  name: string
  values: any[]
  loading?: boolean
  title: string
  columns: Column[]
  tableActions: any[]
  normal?: boolean
}

export const ArtikelDetailsTabelle = ({
  name,
  values,
  loading,
  title,
  columns,
  tableActions,
  normal = false
}: ArtikelDetailsTabelleProps) => {
  return (
    <CardEx
      title={title}
      titleSize={!normal && '1rem'}
      titleAdd={values.length && `(${values.length})`}
      // height="100%"
      borderless={!normal}
      nopadding={!normal}
    >
      <ScrollPanel autoHeight height={200}>
        <DataTable
          name={name}
          columns={columns}
          value={values}
          loading={loading}
          actions={tableActions}
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}
