import { AsyncTaskJson, AsyncTaskPollInfoJson, AsyncTaskStatus } from '@one/typings/apiTypings'
import { useCallback, useEffect, useRef } from 'react'
import { ApiCallType, apiCreateAbort } from './apicaller'
import { UILockMode, UILockType } from './uilock'
import { clearTimer, restartTimer } from './utils'

export const isAsyncTaskStatusActive = (status: AsyncTaskStatus) =>
  status === AsyncTaskStatus.PREPARED || status === AsyncTaskStatus.STARTED

export const isAsyncTaskActive = (asyncTask: AsyncTaskJson) =>
  asyncTask != null && isAsyncTaskStatusActive(asyncTask.status)

export type SetUiLockFnc = (uiLock: UILockType | ((old: UILockType) => UILockType)) => void
export type SetUiLockFnc2 = (uiLock: UILockType | ((old: UILockType) => UILockType)) => boolean

export type AsyncPollerProps = {
  asyncTask: AsyncTaskJson
  title?: string
  setUiLock?: SetUiLockFnc
  apiCall: ApiCallType
  onReady?: (asyncTask: AsyncTaskJson) => void
  onPoll?: (asyncTask: AsyncTaskJson) => void
  onError?: (error: any) => void
}

export const useAsyncTaskPoller = () => {
  const pollTimerRef = useRef<any>()
  const cancelRef = useRef<AbortController>()

  const asyncPoller = useCallback(
    ({ asyncTask, title, setUiLock, apiCall, onReady, onError, onPoll }: AsyncPollerProps) => {
      const maySetUiLock: SetUiLockFnc2 = (args) => {
        if (setUiLock) {
          setUiLock(args)
          return true
        }
        return false
      }

      const poller = () => {
        cancelRef.current = apiCreateAbort()
        apiCall({
          method: 'GET',
          signal: cancelRef.current.signal,
          rest: '/asyncTask/poll',
          params: { id: asyncTask?.id },
          onSuccess: (data: AsyncTaskPollInfoJson) => {
            const at = data.state.asyncTask
            at['trigger'] = Date.now() // Trigger repaint wg. anzeige der Dauer
            if (!isAsyncTaskActive(at)) {
              if (at.error) {
                if (
                  !maySetUiLock((old) => ({
                    ...old,
                    mode: UILockMode.ERROR,
                    title,
                    error: {
                      mainMessage: {
                        message:
                          at.messages?.length === 1
                            ? at.messages[0].message
                            : 'Der Vorgang hat Fehler gemeldet'
                      },
                      messages: at.messages?.length > 1 ? at.messages.slice(1) : null
                    },
                    onClose: onReady ? () => onReady(at) : null
                  })) &&
                  onReady
                ) {
                  onReady(at)
                }
              } else {
                if (setUiLock) {
                  setUiLock({} as UILockType)
                }
                if (onReady) {
                  onReady(at)
                }
              }
            } else {
              restartTimer(pollTimerRef, poller, 2000)
              if (onPoll) {
                onPoll(at)
              }
              maySetUiLock((old) => ({
                ...old,
                asyncTask: at
              }))
            }
          },
          onError: (error) => {
            maySetUiLock({
              error,
              title,
              mode: UILockMode.ERROR
            })
            if (onError) {
              onError(error)
            }
          }
        })
      }

      if (isAsyncTaskActive(asyncTask)) {
        maySetUiLock({
          mode: UILockMode.ASYNC_WAIT,
          title,
          asyncTask
        })
        restartTimer(pollTimerRef, poller, 2000)
      } else {
        clearTimer(pollTimerRef)
        if (onReady) {
          onReady(asyncTask)
        }
      }
    },
    []
  )

  useEffect(() => {
    return () => {
      clearTimer(pollTimerRef)
      if (cancelRef.current) {
        cancelRef.current.abort()
      }
    }
  }, [])

  return [asyncPoller]
}
