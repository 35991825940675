import { NumberCell } from '@one/components/common/NumberCell'
import { EkKonditionenDefinitionEx } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseTypes'
import { EkKonditionenRabattgruppeWertJson } from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useContext, useMemo } from 'react'

export interface EkRabattgruppeVersionWerteTableProps {
  versionInfo: string
  values: EkKonditionenRabattgruppeWertJson[]
  kondiDefinition: EkKonditionenDefinitionEx
  onValueChange: (staffel: any, field: string, value: number) => void
  readonly: boolean
}

export const EkRabattgruppeVersionWerteTable = ({
  versionInfo,
  values,
  kondiDefinition,
  onValueChange,
  readonly
}: EkRabattgruppeVersionWerteTableProps) => {
  const { isAllianz } = useContext(AppContext)

  const columns = useMemo<Column[]>(
    () => [
      {
        header: 'Staffelmenge',
        field: 'staffelmenge',
        type: 'number',
        sortable: false
      },
      {
        field: 'zuschlAbs1',
        header: kondiDefinition.zuschlAbs1Label,
        headerTip: kondiDefinition.zuschlAbs1Tip,
        type: 'money2',
        body: (row) => (
          <NumberCell
            model={row}
            field="zuschlAbs1"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false,
        hidden: isAllianz
      },
      {
        field: 'zuschlAbs2',
        header: kondiDefinition.zuschlAbs2Label,
        headerTip: kondiDefinition.zuschlAbs2Tip,
        type: 'money2',
        body: (row) => (
          <NumberCell
            model={row}
            field="zuschlAbs2"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false,
        hidden: isAllianz
      },
      {
        field: 'rabattProz1',
        header: kondiDefinition.rabattProz1Label,
        headerTip: kondiDefinition.rabattProz1Tip,
        type: 'number',
        body: (row) => (
          <NumberCell
            model={row}
            field="rabattProz1"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false
      },
      {
        field: 'rabattProz2',
        header: kondiDefinition.rabattProz2Label,
        headerTip: kondiDefinition.rabattProz2Tip,
        type: 'number',
        body: (row) => (
          <NumberCell
            model={row}
            field="rabattProz2"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false
      },
      {
        field: 'rabattProz3',
        header: kondiDefinition.rabattProz3Label,
        headerTip: kondiDefinition.rabattProz3Tip,
        type: 'number',
        body: (row) => (
          <NumberCell
            model={row}
            field="rabattProz3"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false
      },
      {
        field: 'rabattProz4',
        header: kondiDefinition.rabattProz4Label,
        headerTip: kondiDefinition.rabattProz4Tip,
        type: 'number',
        body: (row) => (
          <NumberCell
            model={row}
            field="rabattProz4"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        )
      },
      {
        field: 'rabattProz5',
        header: kondiDefinition.rabattProz5Label,
        headerTip: kondiDefinition.rabattProz5Tip,
        type: 'number',
        body: (row) => (
          <NumberCell
            model={row}
            field="rabattProz5"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false
      },
      {
        field: 'zuschlAbs3',
        header: kondiDefinition.zuschlAbs3Label,
        headerTip: kondiDefinition.zuschlAbs3Tip,
        type: 'money2',
        body: (row) => (
          <NumberCell
            model={row}
            field="zuschlAbs3"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false,
        hidden: isAllianz
      },
      {
        field: 'zuschlAbs4',
        header: kondiDefinition.zuschlAbs4Label,
        headerTip: kondiDefinition.zuschlAbs4Tip,
        type: 'money2',
        body: (row) => (
          <NumberCell
            model={row}
            field="zuschlAbs4"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false,
        hidden: isAllianz
      },
      {
        field: 'zuschlAbs5',
        header: kondiDefinition.zuschlAbs5Label,
        headerTip: kondiDefinition.zuschlAbs5Tip,
        type: 'money2',
        body: (row) => (
          <NumberCell
            model={row}
            field="zuschlAbs5"
            onChange={onValueChange}
            allowNegative
            readonly={readonly}
            noDefault
          />
        ),
        sortable: false,
        hidden: isAllianz
      }
    ],
    [isAllianz, kondiDefinition, onValueChange, readonly]
  )

  return (
    <DataTableCard
      name="EkRabattgruppenTable"
      title={`Staffelwerte ${versionInfo}`}
      columns={columns}
      value={values}
      selectMode="single"
      dense
    />
  )
}
