/* eslint-disable react/jsx-props-no-spreading */
import { css } from '@emotion/css'
import React, { useMemo } from 'react'
import { TooltipEx } from './TooltipWrapper'
import { stopPropagation } from './uiutils'

export type LetterIconProps = {
  letter: string
  title?: string
  size?: 'small' | 'medium'
  // backgroundColor?: string
  onClick?: (e: React.UIEvent<HTMLElement>) => void
}

export const LetterIcon = React.forwardRef<any, LetterIconProps>(
  ({ letter, title, size = 'small', onClick }, ref) => {
    const classStyle = useMemo(() => {
      const s = size === 'small' ? 14 : 20
      const fs = size === 'small' ? 9 : 12
      return css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.16);
        border-color: transparent;
        padding: 0;
        border-radius: 22px;
        width: ${s}px;
        height: ${s}px;
        overflow: hidden;
        & > div {
          vertical-align: middle;
          font-weight: 700;
          font-size: ${fs}px;
          text-transform: capitalize;
        }
        ${onClick &&
        `&:active {
          border-color: rgba(0, 0, 0, 0.78);
        }`}
        ${onClick &&
        `&:hover {
      background-color: rgba(22, 0, 0, 0.48);
      cursor: pointer;
      }`}
      `
    }, [onClick, size])

    const body = onClick ? (
      <button
        ref={ref}
        className={classStyle}
        onClick={onClick}
        onKeyDown={
          onClick &&
          ((e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === 'Enter' || e.key === ' ') {
              stopPropagation(e)
              onClick(e)
            }
          })
        }
        tabIndex={onClick ? 0 : -1}
      >
        <div>{letter.length > 0 ? letter.charAt(0) : '?'}</div>
      </button>
    ) : (
      <div ref={ref} className={classStyle}>
        <div>{letter.length > 0 ? letter.charAt(0) : '?'}</div>
      </div>
    )

    if (title) {
      return <TooltipEx title={title}>{body}</TooltipEx>
    }

    return body
  }
)

LetterIcon.displayName = 'LetterIcon'
