import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { AllianzBearbeitenJson, AllianzJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { TextField } from '@utils/ui/fields/TextField'
import { StatePlane } from '@utils/ui/planes/StatePlane'

const validate = (model) => {
  const errors = {} as any

  if (!model.name) {
    errors.name = 'Name ist ein Pflichtfeld'
  }
  return errors
}

export interface AllianzAnlageDialogProps {
  gsId: number
  open: boolean
  onClose: (selected?: any | any[]) => void
}

export const AllianzAnlageDialog = ({ gsId, open, onClose }: AllianzAnlageDialogProps) => {
  const { model, isNew, isChanged, uiLock, onValueChange, save, errors } = useModelMgr<
    AllianzBearbeitenJson,
    AllianzJson
  >({
    id: 'neu',
    api,
    validate,
    restps: {
      gsId
    },
    onSave: (allianz) => {
      onClose(allianz.id)
    },
    unwrapField: 'allianz',
    title: 'Allianz',
    rest: 'allianz'
  })

  const handleCancel = () => {
    onClose()
  }

  const actions = (
    <ButtonRow>
      <SaveButton
        onClickVoid={save}
        isNew={isNew}
        isChanged={isChanged}
        tooltip="Gesellschafter in Allianz umwandel"
      />
      <CancelButton onClick={handleCancel} />
    </ButtonRow>
  )

  return (
    <DialogEx
      open={open}
      title="Allianz Anlage"
      onClose={handleCancel}
      fullWidth
      maxWidth="md"
      actions={actions}
      height="250px"
    >
      <StatePlane uiLock={uiLock} altLink={AppPaths.Gesellschafter}>
        <Frame>
          <FrameBody style={{ paddingTop: '10px' }}>
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  label="Name"
                  value={model.name}
                  onChange={onValueChange}
                  fullWidth
                  error={errors.name}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="bemerkung"
                  label="Bemerkung"
                  value={model.bemerkung}
                  onChange={onValueChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </FrameBody>
        </Frame>
      </StatePlane>
    </DialogEx>
  )
}
