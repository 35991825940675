import { ArtikelAbonniertStatusEx } from '@one/components/ArtikelSelektion/model/ArtikelSelektionTypes'
import {
  ArtikelAbonniertStatus,
  ArtikelAenderungTyp,
  ArtikelDatenpoolUebernahmeStatus,
  ArtikelListeTyp,
  ArtikelSelektionType,
  ArtikelStammdatenExportEintragExportTyp,
  AsyncTaskStatus,
  AuswahlStatus,
  DashboardVorgangTyp,
  Datenmodell,
  DzMapperEinstellungTyp,
  EanZuordnung,
  EkKonditionenRabattVerwendung,
  EkPreisListImportStepId,
  EkPreisListeImportDefinitionMappingType,
  EkPreisListeImportDefinitionStepLabelsType,
  EkPreisListeImportDefinitionStepSchrittfreigabe,
  EkPreisListeImportDefinitionStepStatus,
  EkPreisListeImportStatus,
  ErpCommunticationType,
  ErpSystemEinstellungen,
  ExportDataToErpStepType,
  GesellschafterTyp,
  IntercompanyEinstellungen,
  JobSchedulerStatus,
  LieferantAbonniertStatus,
  LieferantAenderungTyp,
  LieferantArt,
  LieferantLandKategorie,
  LieferantenListeTyp,
  MessageSeverity,
  PimArtikelArt,
  PimArtikelLieferantEkType,
  PreisEbeneStufe,
  PreisListeTyp,
  PreisVerteilungEinstellung,
  PreisVorgangTyp,
  ProtokollStatus,
  ProtokollTyp,
  SpreadSheetType,
  SuchArtikelTyp,
  SuchLieferantTyp,
  SynchronisationStatus,
  VkFrachtArt,
  VkPreisBasisTyp,
  VkPreisListeTyp,
  VkPreisQuelle,
  VkPreisStaffelTyp
} from '@one/typings/apiTypings'
import { HkmEnumType } from '@utils/enums'

export const HkmEnum: HkmEnumType = {
  ArtikelAbonniertStatus: {
    name: 'ArtikelAbonniertStatus',
    type: ArtikelAbonniertStatus
  },
  ArtikelAenderungTyp: {
    name: 'ArtikelAenderungTyp',
    type: ArtikelAenderungTyp
  },
  ArtikelListeTyp: {
    name: 'ArtikelListeTyp',
    type: ArtikelListeTyp
  },
  ArtikelSelektionType: {
    name: 'ArtikelSelektionType',
    type: ArtikelSelektionType
  },
  ArtikelStammdatenExportEintragExportTyp: {
    name: 'ArtikelStammdatenExportEintragExportTyp',
    type: ArtikelStammdatenExportEintragExportTyp
  },
  AuswahlStatus: {
    name: 'AuswahlStatus',
    type: AuswahlStatus
  },
  DashboardVorgangTyp: {
    name: 'DashboardVorgangTyp',
    type: DashboardVorgangTyp
  },
  DzMapperEinstellungTyp: {
    name: 'DzMapperEinstellungTyp',
    type: DzMapperEinstellungTyp
  },
  EkPreisListeImportStatus: {
    name: 'EkPreisListeImportStatus',
    type: EkPreisListeImportStatus
  },
  LieferantAbonniertStatus: {
    name: 'LieferantAbonniertStatus',
    type: LieferantAbonniertStatus
  },
  LieferantAenderungTyp: {
    name: 'LieferantAenderungTyp',
    type: LieferantAenderungTyp
  },
  LieferantenListeTyp: {
    name: 'LieferantenListeTyp',
    type: LieferantenListeTyp
  },
  MessageSeverity: {
    name: 'MessageSeverity',
    type: MessageSeverity
  },
  PreisListeTyp: {
    name: 'PreisListeTyp',
    type: PreisListeTyp
  },
  PreisVerteilungEinstellung: {
    name: 'PreisVerteilungEinstellung',
    type: PreisVerteilungEinstellung
  },
  PreisVorgangTyp: {
    name: 'PreisVorgangTyp',
    type: PreisVorgangTyp
  },
  ProtokollStatus: {
    name: 'ProtokollStatus',
    type: ProtokollStatus
  },
  ProtokollTyp: {
    name: 'ProtokollTyp',
    type: ProtokollTyp
  },
  SuchArtikelTyp: {
    name: 'SuchArtikelTyp',
    type: SuchArtikelTyp
  },
  SuchLieferantTyp: {
    name: 'SuchLieferantTyp',
    type: SuchLieferantTyp
  },
  VkPreisBasisTyp: {
    name: 'VkPreisBasisTyp',
    type: VkPreisBasisTyp
  },
  VkPreisListeTyp: {
    name: 'VkPreisListeTyp',
    type: VkPreisListeTyp
  },
  VkPreisStaffelTyp: {
    name: 'VkPreisStaffelTyp',
    type: VkPreisStaffelTyp
  },
  VkFrachtArt: {
    name: 'VkFrachtArt',
    type: VkFrachtArt
  },
  EkPreisListeImportDefinitionStepStatus: {
    name: 'EkPreisListeImportDefinitionStepStatus',
    type: EkPreisListeImportDefinitionStepStatus
  },
  EkPreisListeImportDefinitionStepSchrittfreigabe: {
    name: 'EkPreisListeImportDefinitionStepSchrittfreigabe',
    type: EkPreisListeImportDefinitionStepSchrittfreigabe
  },
  EkPreisListeImportDefinitionMappingType: {
    name: 'EkPreisListeImportDefinitionMappingType',
    type: EkPreisListeImportDefinitionMappingType
  },
  EkPreisListImportStepId: {
    name: 'EkPreisListImportStepId',
    type: EkPreisListImportStepId
  },
  PreisEbeneStufe: {
    name: 'PreisEbeneStufe',
    type: PreisEbeneStufe
  },
  GesellschafterTyp: {
    name: 'GesellschafterTyp',
    type: GesellschafterTyp
  },
  JobSchedulerStatus: {
    name: 'JobSchedulerStatus',
    type: JobSchedulerStatus
  },
  ErpSystemEinstellungen: {
    name: 'ErpSystemEinstellungen',
    type: ErpSystemEinstellungen
  },
  IntercompanyEinstellungen: {
    name: 'IntercompanyEinstellungen',
    type: IntercompanyEinstellungen
  },
  ErpCommunticationType: {
    name: 'ErpCommunticationType',
    type: ErpCommunticationType
  },
  EkPreisListeImportDefinitionStepLabelsType: {
    name: 'EkPreisListeImportDefinitionStepLabelsType',
    type: EkPreisListeImportDefinitionStepLabelsType
  },
  ArtikelDatenpoolUebernahmeStatus: {
    name: 'ArtikelDatenpoolUebernahmeStatus',
    type: ArtikelDatenpoolUebernahmeStatus
  },
  SynchronisationStatus: {
    name: 'SynchronisationStatus',
    type: SynchronisationStatus
  },
  SpreadSheetType: {
    name: 'SpreadSheetType',
    type: SpreadSheetType
  },
  ExportDataToErpStepType: {
    name: 'ExportDataToErpStepType',
    type: ExportDataToErpStepType
  },
  ArtikelAbonniertStatusEx: {
    name: 'ArtikelAbonniertStatusEx',
    type: ArtikelAbonniertStatusEx
  },
  EanZuordnung: {
    name: 'EanZuordnung',
    type: EanZuordnung
  },
  EkKonditionenRabattVerwendung: {
    name: 'EkKonditionenRabattVerwendung',
    type: EkKonditionenRabattVerwendung
  },
  AsyncTaskStatus: {
    name: 'AsyncTaskStatus',
    type: AsyncTaskStatus
  },
  LieferantArt: {
    name: 'LieferantArt',
    type: LieferantArt
  },
  LieferantLandKategorie: {
    name: 'LieferantLandKategorie',
    type: LieferantLandKategorie
  },
  VkPreisQuelle: {
    name: 'VkPreisQuelle',
    type: VkPreisQuelle
  },
  PimArtikelLieferantEkType: {
    name: 'PimArtikelLieferantEkType',
    type: PimArtikelLieferantEkType
  },
  PimArtikelArt: {
    name: 'PimArtikelArt',
    type: PimArtikelArt
  },
  Datenmodell: {
    name: 'Datenmodell',
    type: Datenmodell
  }
  // ArtikelBetriebstyp: {
  //   name: 'ArtikelBetriebstyp',
  //   type: ArtikelBetriebstyp
  // }
}
