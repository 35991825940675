import { api } from '@one/api'
import { AppPaths } from '@one/AppPaths'
import { ChangeBetriebstypDialog } from '@one/components/Artikel/ChangeBetriebstypDialog'
import { EventNames } from '@one/EventNames'
import { ArtikelDisplayJson, ArtikelSearcherArtikelCriteriaJson } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { ArtikelUebersichtParams } from './ArtikelUebersichtParams'
import { ArtikelUebersichtTable } from './ArtikelUebersichtTable'

export const ArtikelUebersichtView = () => {
  const { isAllianzMitglied, isPIMModel, checkUserRole } = useContext(AppContext)
  const navigate = useNavigate()
  const [DlgAnker, showDialog] = useDialogAnker()

  const zentralName = isAllianzMitglied ? 'forAbo' : isPIMModel ? 'pim' : 'listung'

  const eigenpflegeAllowed = isPIMModel && checkUserRole(UserRoles.EIGENPFLEGE_EDITOR)

  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    ArtikelSearcherArtikelCriteriaJson,
    ArtikelDisplayJson
  >({
    api,
    url: '/artikel',
    initialParams: {
      lieferant: undefined,
      [zentralName]: false,
      dezentral: true,
      pim: false,
      archiviert: false,
      ignoriert: false
    },
    stateInterceptor: (name, state) => {
      switch (name) {
        case 'forAbo':
        case 'listung':
        case 'dezentral':
        case 'pim':
          return { ...state, lieferant: null }
      }
      return state
    }
  })

  const updatSelection = (artikel: ArtikelDisplayJson[]) => {
    setResult((rows) => {
      return rows.map((i) => {
        const found = artikel.find((a) => a.id === i.id)
        return found ? found : i
      })
    })
  }

  const onChangeBT = (selected: ArtikelDisplayJson[]) => {
    showDialog((open, onClose) => (
      <ChangeBetriebstypDialog
        open={open}
        onClose={onClose()}
        selected={selected}
        updatSelection={updatSelection}
      />
    ))
  }
  const onCreateEigenlistungsArtikel = () => {
    navigate(AppPaths.EigenlistungsArtikelPflegeFn('neu'))
  }

  useObserver(EventNames.EIGENLISTUNGSARTIKEL, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <ArtikelUebersichtParams
          zentralName={zentralName}
          value={criteria}
          onChange={onCriteriaChange}
          onSearch={search}
          onCreateEigenlistungsArtikel={
            eigenpflegeAllowed ? onCreateEigenlistungsArtikel : undefined
          }
        />
      </FrameRow>
      <FrameBody>
        <ArtikelUebersichtTable
          result={result}
          reload={search}
          onChangeBT={isPIMModel ? undefined : onChangeBT}
        />
      </FrameBody>
      <DlgAnker />
    </Frame>
  )
}
