import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelSelektionJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { ErrorsType } from '@utils/modelmgr'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import { useContext, useMemo } from 'react'
import { ArsstSelectField } from './ArsstSelectField'

export interface ArtikelAboSelektionFormProps {
  model: ArtikelSelektionJson
  errors: ErrorsType
  onChange: (e: any) => void
  isNew: boolean
}

export const ArtikelAboSelektionForm = ({
  model,
  errors,
  onChange,
  isNew
}: ArtikelAboSelektionFormProps) => {
  const { et } = useEnums()
  const { isAllianz, isPIMModel } = useContext(AppContext)

  const [DlgAnker, showDlg] = useDialogAnker()

  const typeField = useMemo(() => {
    switch (model.typ) {
      case 'WARENGRUPPE':
        return (
          <WarengruppeField
            label="Warengruppe"
            // placeholder="Bitte festlegen"
            value={model.warengruppe}
            error={errors.wg}
            onChange={onChange}
            disabled
            lieferantId={model.lieferant && model.lieferant.id}
            fullWidth
          />
        )
      default:
        return null
    }
  }, [errors, model.lieferant, model.typ, model.warengruppe, onChange])

  const actions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Hauptlieferanten Pflege',
        navlink: AppPaths.HauptlieferantPflegeFn(model.artikelListeId),
        text: 'Hauptlieferanten-Pflege',
        variant: 'outlined',
        visible: !isAllianz
      }
    ],
    [model, isAllianz]
  )

  const title = useMemo(
    () =>
      isNew
        ? 'Artikelselektion erstellen'
        : `Artikelselektion Typ '${et(HkmEnum.ArtikelSelektionType, model.typ)}'`,
    [et, isNew, model]
  )

  return (
    <CardEx backLink collapsiable topActions={actions} title={title}>
      <Grid container direction="row" spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              label="Name"
              error={errors.name}
              name="name"
              value={model.name}
              onChange={onChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ArsstSelectField
              arsstId={model.arsstId}
              errors={errors}
              onChange={onChange}
              disabled={model.forEinzelhandel}
              required
            />
          </Grid>
          {typeField && (
            <Grid item xs={12} md={6} lg={2}>
              {typeField}
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={2}>
            <Checkbox
              label="Ausgelistete Artikel anbieten"
              title="Wenn aktiviert, werden auch ausgelistete Artikel zum abonnieren angeboten."
              name="ausgelisteteArtikelAnbieten"
              checked={model.ausgelisteteArtikelAnbieten}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField label="Abo-Name" name="aboName" value={model.aboName} fullWidth disabled />
          </Grid>
          {!isPIMModel && (
            <Grid item xs={12} md={6} lg={2}>
              <StaticField
                label="Abo-EAN-Gruppe"
                fullWidth
                value={
                  model.aboEanGruppe &&
                  model.aboEanGruppe.name + ' - ' + model.aboEanGruppe.bezeichnung
                }
              />
            </Grid>
          )}
          {!isPIMModel && (
            <Grid item xs={12} md={6} lg={2}>
              <ArtikelBetriebstypField
                label="Abo-Betriebstyp"
                name="aboBetriebsTyp"
                value={model.aboBetriebsTyp}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4}>
            <LieferantField
              value={model.lieferant}
              label="Lieferant"
              disabled={!isNew}
              fullWidth
              modus="dezentral"
            />
          </Grid>
        </Grid>
      </Grid>
      <DlgAnker />
    </CardEx>
  )
}
