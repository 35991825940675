import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { ExportToErpDisplayJson, ExportToErpSearcherCriteriaJson } from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { ExportToErpUebersichtParams } from './ExportToErpUebersichtParams'
import { ExportToErpUebersichtTable } from './ExportToErpUebersichtTable'

export const ErpExportsUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    ExportToErpSearcherCriteriaJson,
    ExportToErpDisplayJson
  >({
    api,
    url: '/export/artikel',
    initialParams: {}
  })

  useObserver(EventNames.EXPORTINFO, () => search())

  return (
    <Frame space>
      <FrameRow>
        <ExportToErpUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <ExportToErpUebersichtTable result={result} />
      </FrameBody>
    </Frame>
  )
}
