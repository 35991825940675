import { cloneElement } from 'react'
import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'

// Styling definitions
const useStyles = makeStyles()({
  left: {
    animation: `${keyframes`
    0% {
      transform: rotate(0); 
    }
    100% {     
      transform: rotate(360deg); 
    }
    `} 4s linear 0s infinite reverse none!important`
  },
  right: {
    animation: `${keyframes`
    0% {       
      transform: rotate(0); 
    }
    100% {     
      transform: rotate(360deg); 
    }
    `} 4s linear 0s infinite none!important`
  }
})

export type RotateProps = {
  right?: boolean
  children: any
}

export const Rotate = ({ right, children }: RotateProps) => {
  const { classes } = useStyles()
  return cloneElement(children, { className: right ? classes.right : classes.left })
}
