/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
import {Chip, ChipProps, InputBase, Stack} from '@mui/material'
import {ensureArray, oidOf} from '@utils/utils'
import {makeStyles} from 'tss-react/mui'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  root: {
    paddingBottom: 0,
    border: 'none'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },
  footSpace: {
    marginBottom: 0
  },
  fullWidth: {
    width: '100%'
  },
  compact: {
    height: '20px!important'
  }
}))

export type ChipContainerProps = {
  value?: any
  getValueLabel?: (opt: any) => any
  getChipColor?: (opt: any) => ChipProps['color']
  getValueIcon?: (opt: any) => any
  getValueKey?: (opt: any) => any
  getValueTip?: (opt: any) => any
  onDelete?: (e: any, opt: any) => void
  disabled?: boolean
  placeholder?: string
  footSpace?: any
  fullWidth?: boolean
  stackDirection?: 'column' | 'row'
  anchorRef?: any
  compact?: boolean
}

export const ChipContainer = ({
  value = null,
  getValueLabel = null,
  getValueIcon = null,
  getChipColor = null,
  getValueKey = null,
  getValueTip = null,
  onDelete = null,
  disabled = false,
  placeholder = null,
  footSpace = null,
  fullWidth = false,
  stackDirection = 'row',
  anchorRef = null,
  compact
}: ChipContainerProps) => {
  const { classes } = useStyles()
  const chips = ensureArray(value)
    .filter((opt) => opt != null)
    .map((opt) => {
      const tip = getValueTip && getValueTip(opt)
      return (
        <Chip
          key={`chip-${
            (getValueKey && getValueKey(opt)) || (typeof opt === 'object' ? oidOf(opt) : opt)
          }`}
          label={getValueLabel ? getValueLabel(opt) : `${opt}`}
          icon={getValueIcon && getValueIcon(opt)}
          color={getChipColor ? getChipColor(opt) : 'default'}
          title={tip}
          style={tip ? { cursor: 'help' } : undefined}
          size="small"
          onDelete={onDelete && ((e) => onDelete(e, opt))}
          disabled={disabled}
          tabIndex={0}
          className={compact ? classes.compact : null}
        />
      )
    })
  return (
    <span ref={anchorRef} className={fullWidth ? classes.fullWidth : null}>
      {chips.length > 0 ? (
        <Stack
          className={fullWidth ? classes.fullWidth : null}
          spacing={1}
          direction={stackDirection}
          marginBottom={footSpace ? '5px' : undefined}
          // tabIndex=chips{.length > 0 ? 0 : -1}
        >
          {chips}
        </Stack>
      ) : compact ? null : (
        <InputBase value="" placeholder={placeholder} fullWidth={fullWidth} />
      )}
    </span>
  )
}
