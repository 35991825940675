import { Grid } from '@mui/material'
import { api } from '@one/api'
import { MessageJson, ProtokollAnzeigenJson, ProtokollEintragJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StaticField } from '@utils/ui/fields/StaticField'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ProtokollDetailstTable } from './ProtokollDetailsTable'

interface ProtokollEintragEx extends ProtokollEintragJson {
  error?: MessageJson
}

export const ProtokollDetailsView = () => {
  const { id } = useContext(RouteContext) as any

  const [params] = useState({
    protokollId: id,
    minSeverityType: null,
    message: null
  })
  const [result, setResult] = useState<ProtokollEintragEx[]>()
  const [processes, setProcesses] = useState(0)
  const [warningCounts, setWarningCounts] = useState(0)
  const [errorCounts, setErrorCounts] = useState(0)
  const [fatalCounts, setFatalCounts] = useState(0)
  const [apiCall, apiBusy] = useApiCaller(api)

  const resultCounter = (severityType, list) => {
    return list.filter((r) => r.severity === severityType).length
  }

  const handleSearch = useCallback(
    (searchParams) => {
      apiCall<ProtokollAnzeigenJson>({
        method: 'POST',
        rest: '/protokoll/details/search',
        data: searchParams,
        onErrorMsg: `Protokoll mit ID "${id}" konnte nicht geladen werden.`,
        onSuccess: (data) => {
          if (data.searcher) {
            const rr = [] as ProtokollEintragEx[]
            data.searcher.result.forEach((r) => {
              if (r.errors?.length > 0) {
                r.errors.forEach((error, idx) => {
                  if (r.errors.indexOf(error) === idx) {
                    rr.push({
                      ...r,
                      error
                    })
                  }
                })
              } else {
                rr.push(r)
              }
            })
            setResult(rr)
            setProcesses(data.searcher.result.length)
            setWarningCounts(resultCounter('WARN', data.searcher.result))
            setErrorCounts(resultCounter('ERR', data.searcher.result))
            setFatalCounts(resultCounter('FATAL', data.searcher.result))
            // if (data.searcher.result.length > 0) {
            // setResultHeadline(data.searcher.result[0].fileName)
            // }
          }
        }
      })
    },
    [apiCall, id]
  )

  useEffect(() => {
    handleSearch(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Frame space>
      <WaitPlane wait={apiBusy} />
      <FrameRow>
        <CardEx title="Protokolldetails" backLink>
          {result && (
            <Grid container spacing={2}>
              <Grid item xs={6} lg={2}>
                <StaticField label="Anzahl Prozesse" value={processes} />
              </Grid>
              <Grid item xs={6} lg={2}>
                <StaticField label="Anzahl Warnungen" value={warningCounts} />
              </Grid>
              <Grid item xs={6} lg={2}>
                <StaticField label="Anzahl Fehler" value={errorCounts} />
              </Grid>
              <Grid item xs={6} lg={2}>
                <StaticField label="Anzahl fatale Fehler" value={fatalCounts} />
              </Grid>
            </Grid>
          )}
        </CardEx>
      </FrameRow>
      <FrameBody>
        <ProtokollDetailstTable values={result} loading={apiBusy} />
      </FrameBody>
    </Frame>
  )
}
