import { Grid } from '@mui/material'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  DzLieferantJson,
  LieferantSearcherCriteriaJson,
  LieferantUebersichtJson,
  SeLieferantJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import { restartTimer, trimStringToNull } from '@utils/utils'
import React, { useEffect, useRef, useState } from 'react'

export type LieferantBezeichnungProps = {
  dzLieferant: DzLieferantJson
  onValueChange: ValueChangeFn<SeLieferantJson>
  errors: any
  seLieferantId?: number
}

export const LieferantBezeichnung = ({
  dzLieferant,
  onValueChange,
  errors,
  seLieferantId
}: LieferantBezeichnungProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [ilnError, setIlnError] = useState<string | null>(null)

  const timerRef = useRef<number | null>(null)

  const onChangeIln = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(e)
    const iln = trimStringToNull(e.target.value)
    if (iln != null) {
      restartTimer(
        timerRef,
        () => {
          const data: LieferantSearcherCriteriaJson = {
            dezentral: true,
            // eigenlistung: true,
            iln
          }
          apiCall<LieferantUebersichtJson>({
            rest: '/lieferant/uebersichtAnzeigen/search',
            method: 'POST',
            exclusive: ApiExclusive.CANCEL,
            data,
            onSuccess: (data) => {
              const length = data.searcher.result?.length ?? 0
              if (
                length > 1 ||
                (length == 1 && data.searcher.result[0].seLieferantId != seLieferantId)
              ) {
                setIlnError('ILN wird bereits verwendet')
              } else {
                setIlnError(null)
              }
            }
          })
        },
        250
      )
    }
  }

  useEffect(() => {
    setIlnError(null)
  }, [dzLieferant.version])

  return (
    <CardEx title="Lieferant">
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={6} lg={3} xl={2}>
            <StaticField label="Nummer" value={dzLieferant.nummer} empty />
          </Grid>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="ILN"
              name="dzLieferant.iln"
              value={dzLieferant.iln}
              error={ilnError || errors['dzLieferant.iln']}
              onChange={onChangeIln}
              maxLength={15}
              fullWidth
              loading={apiBusy}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Name 1"
              name="dzLieferant.hauptAdresse.name1"
              value={dzLieferant.hauptAdresse?.name1}
              error={errors['dzLieferant.hauptAdresse.name1']}
              onChange={onValueChange}
              maxLength={100}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Name 2"
              name="dzLieferant.hauptAdresse.name2"
              value={dzLieferant.hauptAdresse?.name2}
              error={errors['dzLieferant.hauptAdresse.name2']}
              onChange={onValueChange}
              maxLength={100}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Name 3"
              value={dzLieferant.hauptAdresse?.name3}
              name="dzLieferant.hauptAdresse.name3"
              error={errors['dzLieferant.hauptAdresse.name3']}
              onChange={onValueChange}
              maxLength={100}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Unsere Kundennummer"
              value={dzLieferant.unsereKundennummer}
              name="dzLieferant.unsereKundennummer"
              error={errors['dzLieferant.unsereKundennummer']}
              onChange={onValueChange}
              maxLength={20}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Ust ID Nummer"
              value={dzLieferant.ustIDNummer}
              name="dzLieferant.ustIDNummer"
              error={errors['dzLieferant.ustIDNummer']}
              onChange={onValueChange}
              maxLength={20}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Steuernummer"
              value={dzLieferant.steuernummer}
              name="dzLieferant.steuernummer"
              error={errors['dzLieferant.steuernummer']}
              onChange={onValueChange}
              maxLength={20}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Branche"
              value={dzLieferant.branche}
              name="dzLieferant.branche"
              error={errors['dzLieferant.branche']}
              onChange={onValueChange}
              maxLength={200}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3} xl={2}>
            <TextField
              label="Suchbegriff"
              value={dzLieferant.suchbegriff}
              name="dzLieferant.suchbegriff"
              error={errors['dzLieferant.suchbegriff']}
              onChange={onValueChange}
              maxLength={50}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} lg={3} xl={2}>
            <SelectEnumField
              label="Lieferantenart"
              value={dzLieferant.lieferantArt}
              enumType={HkmEnum.LieferantArt}
              name="dzLieferant.lieferantArt"
              error={errors['dzLieferant.lieferantArt']}
              onChange={onValueChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6} lg={3} xl={2}>
            <SelectEnumField
              label="Länderkategorie"
              value={dzLieferant.landKategorie}
              enumType={HkmEnum.LieferantLandKategorie}
              name="dzLieferant.landKategorie"
              error={errors['dzLieferant.landKategorie']}
              onChange={onValueChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const LieferantBezeichnungMemo = React.memo(LieferantBezeichnung)
