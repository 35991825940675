import Grid from '@mui/material/Grid2'
import { HkmEnum } from '@one/enums/HkmEnum'
import { VkFrachtArt, VkFrachtkostenJson, ZufuhrMultiDisplayJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'

export interface VkPreisPflegeFrachtUndSkontoProps {
  frachtkosten?: VkFrachtkostenJson
  readonly?: boolean
  setFrachtkosten: any
  currency?: string
  zufuhrMultis?: ZufuhrMultiDisplayJson[]
}

export const VkPreisPflegeFrachtUndSkonto = ({
  frachtkosten,
  readonly,
  setFrachtkosten,
  currency,
  zufuhrMultis
}: VkPreisPflegeFrachtUndSkontoProps) => {
  const frachtArt = frachtkosten?.frachtArt
  return (
    <CardEx title="Frachtkosten und Skonto" height="100%">
      <Grid container direction="column" spacing={2}>
        <Grid container direction="row" spacing={2}>
          <Grid size={{ sm:4 }}>
            <SelectEnumField
              label="Frachtart"
              enumType={HkmEnum.VkFrachtArt}
              fullWidth
              name="frachtArt"
              value={frachtkosten.frachtArt}
              onChange={setFrachtkosten}
              disabled={readonly}
            />
          </Grid>
          {frachtkosten.frachtArt === VkFrachtArt.PROZENT ? (
            <Grid size={{ sm:4 }}>
              <NumberField
                label="Fracht Prozent"
                fullWidth
                fraction={2}
                min={0}
                thousandSeparator
                name="frachtProz"
                value={frachtkosten.frachtProz}
                suffix="%"
                onChange={setFrachtkosten}
                disabled={readonly || frachtArt != VkFrachtArt.PROZENT}
              />
            </Grid>
          ) : null}
          {frachtkosten.frachtArt === VkFrachtArt.FIX ? (
            <Grid size={{ sm:4 }}>
              <NumberField
                label="Fracht Absolut"
                fullWidth
                fraction={2}
                min={0}
                thousandSeparator
                suffix={currency}
                name="frachtAbs"
                value={frachtkosten.frachtAbs}
                onChange={setFrachtkosten}
                disabled={readonly || frachtArt != VkFrachtArt.FIX}
              />
            </Grid>
          ) : null}
          <Grid size={{ sm:4 }}>
            <NumberField
              label="Enthaltene Frachtzufuhr"
              fullWidth
              fraction={2}
              thousandSeparator
              suffix={currency}
              name="enthalteneFrachtZufuhr"
              value={frachtkosten.enthalteneFrachtZufuhr}
              onChange={setFrachtkosten}
              disabled={readonly}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} flexWrap="nowrap">
          <Grid size={{ sm:8 }}>
            <AutocompleteEx
              label="Multifracht"
              name="zufuhrMultiId"
              value={frachtkosten.zufuhrMultiId}
              onChange={setFrachtkosten}
              options={zufuhrMultis}
              optionLabel={(opt: ZufuhrMultiDisplayJson) => opt.name + '-' + opt.bez}
              optionValue="id"
              fullWidth
              disabled={readonly}
            />
          </Grid>
          <Grid size={{ sm:4 }}>
            <Checkbox
              label="Fracht nach Rabatt"
              name="frachtNachRabatt"
              checked={frachtkosten.frachtNachRabatt}
              onChange={setFrachtkosten}
              size="small"
              disabled={readonly || frachtArt == null}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} flexWrap="nowrap">
          <Grid size={{ sm:4 }}>
            <NumberField
              label="EK nicht Skonto"
              fullWidth
              fraction={2}
              thousandSeparator
              suffix={currency}
              name="ekNichtSkonto"
              value={frachtkosten.ekNichtSkonto}
              onChange={setFrachtkosten}
              disabled={readonly}
            />
          </Grid>
          <Grid size={{ sm:4 }}>
            <NumberField
              label="VK nicht Skonto"
              fullWidth
              fraction={2}
              thousandSeparator
              suffix={currency}
              name="vkNichtSkonto"
              value={frachtkosten.vkNichtSkonto}
              onChange={setFrachtkosten}
              disabled={readonly}
            />
          </Grid>
          <Grid size={{ sm:4 }}>
            <Checkbox
              label="Fracht nicht skontierfähig"
              name="frachtNichtSkonto"
              checked={frachtkosten.frachtNichtSkonto}
              onChange={setFrachtkosten}
              size="small"
              disabled={readonly || frachtArt == null}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
