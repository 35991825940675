import { AppPaths } from '@one/AppPaths'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantAboDisplayJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { useMemo } from 'react'

export interface LieferantDetailsAboProps {
  abo: LieferantAboDisplayJson
  loading: boolean
}

export const LieferantDetailsAbo = ({ abo, loading }: LieferantDetailsAboProps) => {
  const { et } = useEnums()

  const columns: Column[] = useMemo(
    () => [
      {
        field: 'aboName',
        header: 'Aboname',
        sortable: true
      },
      {
        field: 'anzahlAbonniert',
        header: 'Anzahl abonnierter Artikel',
        sortable: true
      },
      {
        field: 'status',
        header: 'Status',
        body: (row: any) => et(HkmEnum.LieferantAbonniertStatus, row.status)
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'local_shipping',
        tooltip: 'Lieferant öffnen',
        getLink: (data: any) => AppPaths.LieferantAboPflegeFn(data.id)
      }
    ],
    []
  )

  return (
    <CardEx title="Lieferantenabos">
      <ScrollPanel autoHeight={true}>
        <DataTable
          name="LieferantDetailsAboTable"
          columns={columns}
          value={[abo]}
          loading={loading}
          actions={tableActions}
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}
