import { green, lightGreen } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import {
  ArtikelBetriebstypCell,
  getArtikelBetriebstypLabel
} from '@one/components/common/ArtikelBetriebstypCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { PreisEbeneCellField } from '@one/components/common/PreisEbeneCell'
import {
  formatLieferant,
  formatNeonKontext,
  formatPreisEbene
} from '@one/components/common/formatters'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { useNeonKontextCache } from '@one/datacaches/useNeonKontextCache'
import { usePreisEbenenCache } from '@one/datacaches/usePreisEbenenCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import { VkPreisListeDisplayJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Medal } from '@utils/ui/fields/Medal'
import { nameOf } from '@utils/utils'
import { useContext, useMemo } from 'react'

export interface VkPreisUebersichtTableProps {
  result: SearcherResultType<VkPreisListeDisplayJson>
  onAktivieren: (data: VkPreisListeDisplayJson) => void
  onAbschliessen: (data: VkPreisListeDisplayJson) => void
  onDelete: (data: VkPreisListeDisplayJson) => void
}

export const VkPreisUebersichtTable = ({ result, onFreigeben, onAbschliessen, onDelete }) => {
  const { et } = useEnums()
  const { get: getBetriebstyp } = useArtikelBetriebstypCache()
  const { isEinzelhandel, isFachhandel, isPIMModel } = useContext(AppContext)
  const { get: getPreisEbenen } = usePreisEbenenCache()
  const { get: getNeonKontext } = useNeonKontextCache()

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          header: 'Name',
          sortable: true
        },
        {
          field: ['lieferant.name1', 'lieferant.ort', 'lieferant.nummer'],
          header: 'Lieferant',
          valueGetter: (row) => formatLieferant(row.lieferant),
          body: (row: any) => <LieferantCell lieferant={row.lieferant} asLink />,
          sortable: true
        },
        {
          field: nameOf<VkPreisListeDisplayJson>('betriebstyp'),
          header: 'B-Typ',
          sortable: true,
          off: isPIMModel,
          body: (row) => <ArtikelBetriebstypCell value={row.betriebstyp} />,
          valueGetter: (row) => `${getArtikelBetriebstypLabel(getBetriebstyp(row.betriebstyp))}`
        },
        {
          field: nameOf<VkPreisListeDisplayJson>('kontext'),
          header: 'Kontext',
          sortable: true,
          off: !isPIMModel,
          valueGetter: (row) => formatNeonKontext(getNeonKontext(row.kontext))
        },
        {
          field: nameOf<VkPreisListeDisplayJson>('preisEbeneId'),
          header: 'Preisebene',
          sortable: true,
          off: !isPIMModel,
          body: (row) => <PreisEbeneCellField value={getPreisEbenen(row.preisEbeneId)} />,
          valueGetter: (row) => formatPreisEbene(getPreisEbenen(row.preisEbeneId))
        },
        {
          field: 'gueltigVon',
          header: 'Gültig ab',
          type: 'date',
          sortable: true
        },
        {
          field: 'typ',
          header: 'Typ',
          sortable: true,
          body: (row: any) => {
            return et(HkmEnum.VkPreisListeTyp, row.typ)
          }
        },
        {
          off: isFachhandel !== isEinzelhandel,
          header: 'Handel',
          align: 'center',
          valueGetter: (row) => (row.forEinzelhandel ? 'Einzelhandel' : 'Fachhandel'),
          body: (row) => (
            <Medal
              text={row.forEinzelhandel ? 'Einzelhandel' : 'Fachhandel'}
              backgroundColor={row.forEinzelhandel ? green[400] : lightGreen[200]}
              fullWidth
            />
          )
        },
        {
          field: 'angelegtUm',
          header: 'Angelegt am',
          type: 'datetime',
          sortable: true
        },
        {
          field: 'letztePflege',
          header: 'Letzte Pflege am',
          type: 'datetime',
          sortable: true
        },
        {
          field: 'anzahlArtikel',
          header: 'Artikel',
          sortable: true,
          type: 'number'
        },
        {
          field: 'anzahlBlaetter',
          header: 'Preisblätter',
          sortable: true,
          type: 'number'
        },
        {
          field: 'anzahlAbgeschlossen',
          header: 'Abgeschlossene',
          sortable: true,
          type: 'number'
        },
        {
          field: 'anzahlFreigegeben',
          header: 'Freigegebene',
          sortable: true,
          type: 'number'
        },
        {
          field: 'freigegebenUm',
          header: 'Freigegeben am',
          type: 'datetime',
          sortable: true
        },
        {
          field: 'status',
          header: 'Status',
          sortable: true
          // body: (row: any) => <Medal text={row.status} backgroundColor=''/>
        }
      ] as Column<VkPreisListeDisplayJson>[],
    [et, getBetriebstyp, getNeonKontext, getPreisEbenen, isEinzelhandel, isFachhandel, isPIMModel]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: (data) => AppPaths.VkPreisPflegeFn(data.id),
        check: (row: any) => row.kannBearbeiten
      },
      {
        icon: 'table_rows',
        tooltip: 'Massenbearbeitung',
        getLink: (data) => AppPaths.VkPreisMassenPflegeFn(data.id)
      },
      {
        icon: 'library_add',
        tooltip: 'Kopieren',
        getLink: (data) => AppPaths.VkPreisAnlageCloneFn(data.id)
      },
      {
        icon: 'visibility',
        tooltip: 'Anzeigen',
        getLink: (data) => AppPaths.VkPreisPflegeFn(data.id),
        check: (row: any) => row.kannAnzeigen
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: onDelete,
        check: (row: any) => row.kannLoeschen
      },
      {
        icon: 'done_all',
        tooltip: 'Alle Abschließen',
        handler: onAbschliessen,
        check: (row: any) => row.kannAbschliessen
      },
      {
        icon: 'check_circle',
        tooltip: 'Freigeben',
        handler: onFreigeben,
        check: (row: any) => row.kannFreigeben
      }
    ],
    [onFreigeben, onAbschliessen, onDelete]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Verkaufspreisliste erstellen',
        navlink: AppPaths.VkPreisAnlageFn({}),
        icon: 'add'
      }
      // {
      //   role: UserRoles.STAMMDATEN_EDITOR,
      //   tooltip: 'Einkaufspreisliste (CSV) hochladen',
      //   component: (
      //     <UploadButton
      //       api={api}
      //       multiple
      //       path="import/vkpreisliste"
      //       accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      //       onComplete={onUpload}
      //     />
      //   )
      // }
    ],
    []
  )

  return (
    <DataTableCard
      name="VkPreisUebersichtTable"
      title="Preislistenübersicht"
      filterMode="both"
      localStateName="VkPreisUebersichtTable"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      dense
      initialOrderBy="name"
      result={result}
    />
  )
}
