import { Icon } from '@mui/material'
import { HauptlieferantPflegeEintragJson } from '@one/typings/apiTypings'
import { asNumber, formatMoney2 } from '@utils/utils'
import { useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface AlternativeLieferantenCellProps {
  eintrag: HauptlieferantPflegeEintragJson
  preisEbenen: any[]
  lieferantenMap: Record<string, any>
  onClickAltLieferant: (eintrag: any, lieferantId: string, preisEbeneId: string) => () => void
  selectedPreisEbeneId?: number
  showRadioButtons?: boolean
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  icon: {
    marginRight: '5px',
    transform: 'scale(0.8)'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconUnchecked: {
    cursor: 'pointer'
  }
}))

export const AlternativeLieferantenCell = ({
  eintrag,
  preisEbenen,
  lieferantenMap,
  onClickAltLieferant,
  selectedPreisEbeneId,
  showRadioButtons = false
}: AlternativeLieferantenCellProps) => {
  const { classes } = useStyles()

  const alternativeLieferanten = eintrag.lieferanten.filter(
    (lieferant) => lieferant.preisEbeneId !== null && lieferant.hauptlieferant
  )
  // Liste von allen Lieferanten, die keine alternativen Hauptlieferanten sind
  const nebenLieferantenByPreisEbene = eintrag.lieferanten.filter((lieferant) => {
    return lieferant.preisEbeneId === null
  })

  const currentPreisEbene = preisEbenen.find((preisEbene) => preisEbene.id === selectedPreisEbeneId)

  const renderPreisEbeneLieferantEntry = useCallback(
    (artikelLieferant, preisEbene) => {
      if (!artikelLieferant || !preisEbene) {
        return null
      }
      const lieferant = lieferantenMap[artikelLieferant.lieferantId]
      if (!lieferant) {
        return null
      }
      let res = `(${preisEbene.nr}) ${preisEbene.name}`

      if (lieferant && lieferant.name1) {
        res += ` -> (${lieferant.nummer}) ${lieferant.name1}`
      }

      if (artikelLieferant.ekFreiHaus) {
        res += `: ${formatMoney2(artikelLieferant.ekFreiHaus)}€ pro ${
          artikelLieferant.preismenge
        } ${artikelLieferant.mengenEinheit}`
      }
      return res
    },
    [lieferantenMap]
  )

  // gibt alle Lieferanten für die aktuelle Preisebene zurück,
  // die mögliche alternative Hauptlieferanten sind, aber noch nicht worden gewählt sind
  const preisEbenenLieferantenList = nebenLieferantenByPreisEbene
    .map((lieferant) => {
      const altHauptlieferant = eintrag.lieferanten.find(
        (artikelLieferant) =>
          artikelLieferant.hauptlieferant &&
          artikelLieferant.lieferantId === lieferant.lieferantId &&
          artikelLieferant.preisEbeneId === selectedPreisEbeneId
      )
      if (!altHauptlieferant) {
        return lieferant
      }

      return null
    })
    .filter(Boolean)

  const findPreisEbene = useCallback(
    (preisEbeneId) => preisEbenen.find((_preisEbene) => _preisEbene.id === preisEbeneId),
    [preisEbenen]
  )

  const renderPreisebene = useCallback(
    (artikelLieferant) => {
      const preisEbene = findPreisEbene(artikelLieferant.preisEbeneId)
      return (
        <div key={`standort-${preisEbene.id}`}>
          {renderPreisEbeneLieferantEntry(artikelLieferant, preisEbene)}
        </div>
      )
    },
    [renderPreisEbeneLieferantEntry, findPreisEbene]
  )

  const renderAvailableLieferant = useCallback(
    (lieferant) => {
      const peId = lieferant.preisEbeneId || currentPreisEbene?.id
      const preisEbene = peId == null ? null : findPreisEbene(peId)
      return (
        <div key={`standort-lieferant-${lieferant.id}-${preisEbene?.id}`} className={classes.cell}>
          <Icon
            className={`${classes.icon} ${classes.iconUnchecked}`}
            color="inherit"
            onClick={onClickAltLieferant(eintrag, lieferant.lieferantId, preisEbene?.id)}
          >
            {lieferant.hauptlieferant && lieferant.preisEbeneId
              ? 'check_box'
              : 'check_box_outline_blank'}
          </Icon>
          {renderPreisEbeneLieferantEntry(lieferant, preisEbene)}
        </div>
      )
    },
    [
      classes,
      onClickAltLieferant,
      eintrag,
      currentPreisEbene,
      renderPreisEbeneLieferantEntry,
      findPreisEbene
    ]
  )

  const sortPreisebenen = (a: any, b: any) => {
    if (a.ekFreiHaus && !b.ekFreiHaus) {
      return -1
    }
    if (b.ekFreiHaus && !a.ekFreiHaus) {
      return 1
    }
    if (a.ekFreiHaus === b.ekFreiHaus) {
      const idA = asNumber(lieferantenMap[a.lieferantId]?.nummer)
      const idB = asNumber(lieferantenMap[b.lieferantId]?.nummer)
      if (idA != null && idB != null) {
        return idA - idB
      }
    }
    return a.ekFreiHaus - b.ekFreiHaus
  }

  return (
    <div>
      {showRadioButtons && nebenLieferantenByPreisEbene.length
        ? [...alternativeLieferanten, ...preisEbenenLieferantenList]
            // sortieren sorgt für gleiche Positionen beim wählen der Checkbox
            .sort(sortPreisebenen)
            .map(renderAvailableLieferant)
        : alternativeLieferanten.sort(sortPreisebenen).map(renderPreisebene)}
    </div>
  )
}
