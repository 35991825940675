import { Grid } from '@mui/material'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import { UmsatzsteuerBearbeitenJson, UmsatzsteuerJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { DateField } from '@utils/ui/fields/DateField'
import { NumberField } from '@utils/ui/fields/NumberField'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'
import { StatePlane } from '@utils/ui/planes/StatePlane'


export interface UmsatzsteuerViewProps {
  onSave?: () => void
}

export const UmsatzsteuerView = ({ onSave }: UmsatzsteuerViewProps) => {
  const { id} = useContext(RouteContext) as any

  const { model, uiLock, onValueChange, isNew, isChanged, save, remove } =
    useModelMgr<
      UmsatzsteuerBearbeitenJson,
      UmsatzsteuerJson,
      UmsatzsteuerBearbeitenJson,
      UmsatzsteuerJson
    >({
      id: id,
      api,
      onSave,
      title: 'Umsatzsteuer anlage',
      unwrapField: 'tax',
      rest: 'tax',
      eventName: EventNames.TAX
    })

  const navigate = useNavigate()

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          navigate(AppPaths.Umsatzsteuer.UmsatzsteuerOverview)
        }
      }),
    [navigate, remove]
  )

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <CardEx
            backLink
            overviewLink={AppPaths.Umsatzsteuer.UmsatzsteuerOverview}
            title={`Umsatzsteuer`}
          >
            <Grid container spacing={2} direction="column">
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    name="land"
                    label="Land"
                    value={model.land}
                    onChange={onValueChange}
                    disabled={!isNew}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    name="steuerkey"
                    label="Steuerkey"
                    value={model.steuerkey}
                    onChange={onValueChange}
                    disabled={!isNew}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <NumberField
                    name="steuersatz"
                    label="Steuersatz"
                    fraction={2}
                    value={model.steuersatz}
                    onChange={onValueChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <SelectEnumField
                    label="Datenmodell"
                    name="datenmodell"
                    value={model.datenmodell}
                    onChange={onValueChange}
                    disabled={!isNew}
                    fullWidth
                    enumType={HkmEnum.Datenmodell}
                    emptyText="Alle"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <DateField
                    label="gültig ab"
                    disableToolbar
                    name="gueltigAb"
                    value={model.gueltigAb}
                    onChange={onValueChange}
                    disabled={!isNew}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardEx>
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton isNew={isNew} onDelete={onDeleteClick}/>
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
