import { Add } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import { NeonKontextField } from '@one/components/common/NeonKontextField'
import { ArtikelSearcherArtikelCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'
import { useContext } from 'react'

export interface ArtikelUebersichtParamsProps {
  value: ArtikelSearcherArtikelCriteriaJson
  zentralName: string
  onSearch: VoidFunction
  onChange: ChangeFunction<ArtikelSearcherArtikelCriteriaJson>
  onCreateEigenlistungsArtikel?: VoidFunction
  borderless?: boolean
  disableListung?: boolean
  disableDezentral?: boolean
  disableArchiviert?: boolean
  disabledLieferant?: boolean
}

export const ArtikelUebersichtParams = ({
  value,
  zentralName,
  onSearch,
  onChange,
  onCreateEigenlistungsArtikel,
  disableListung = false,
  disableDezentral = false,
  disableArchiviert = false,
  borderless = false,
  disabledLieferant = false
}: ArtikelUebersichtParamsProps) => {
  const { isPIMModel } = useContext(AppContext)

  const shortcuts = {
    Enter: onSearch
  }

  const multipleQuellen =
    (value.dezentral && 1) + (value[zentralName] && 1) + (value.datenpool && 1) > 1

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        collapsiable={!borderless}
        title={borderless ? null : 'Artikelrecherche'}
        backLink={!borderless}
        bottomActions={
          <>
            <Button
              label="Eigenlistungsartikel anlegen"
              StartIcon={Add}
              onClick={onCreateEigenlistungsArtikel}
              visible={!!onCreateEigenlistungsArtikel}
              color="info"
            />
            <SearchButton onClick={onSearch} />
          </>
        }
        borderless={borderless}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Checkbox
              label="Listung"
              size="small"
              name={zentralName}
              checked={value[zentralName]}
              onChange={onChange}
              disabled={disableListung}
              color="default"
            />
            <Checkbox
              label="ERP"
              size="small"
              name="dezentral"
              checked={value.dezentral}
              onChange={onChange}
              disabled={disableDezentral}
              color="default"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="flex-end">
              <Checkbox
                label="Eigenlistung"
                size="small"
                name="eigenlistung"
                checked={value.eigenlistung}
                onChange={onChange}
                indeterminate
                visible={!!onCreateEigenlistungsArtikel}
              />
              <Checkbox
                label="Archivierte Artikel"
                size="small"
                name="archiviert"
                disabled={disableArchiviert}
                checked={value.archiviert}
                onChange={onChange}
              />
              <Checkbox
                label="Ignorierte Artikelarten"
                size="small"
                name="ignoriert"
                checked={value.ignoriert}
                onChange={onChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="hage-Nr."
              name="hageNummer"
              value={value.hageNummer}
              onChange={onChange}
              actions={[
                      <Checkbox
                          key="match"
                          name="matchHagenummer"
                          size="small"
                          title="match"
                          checked={value.matchHagenummer}
                          onChange={onChange}
                      />
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Artikel-Nr."
              name="btNummer"
              value={value.btNummer}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField fullWidth label="EAN" name="ean" value={value.ean} onChange={onChange} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="industrieArtikelNummer"
              label="IAN"
              value={value.industrieArtikelNummer}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="mcode"
              label="Suchbegriff"
              value={value.mcode}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="bez"
              label="Bezeichung"
              value={value.bez}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={16} sm={6}>
            <LieferantField
              fullWidth
              label="Lieferant"
              name="lieferant"
              value={value.lieferant}
              onChange={onChange}
              modus={
                (value.dezentral && !value[zentralName] && 'dezentral') ||
                (value[zentralName] && !value.dezentral && 'zentral') ||
                'alle'
              }
              disabled={multipleQuellen || disabledLieferant}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isPIMModel ? (
              <NeonKontextField
                label="Kontext (Betriebstyp)"
                value={value.kontext}
                onChange={onChange}
                name="kontext"
                emptyText="Alle"
              />
            ) : (
              <ArtikelBetriebstypField
                label="Betriebstyp"
                value={value.betriebstyp}
                onChange={onChange}
                name="betriebstyp"
                emptyText="Alle"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WarengruppeField
              value={value.warengruppe}
              label="Zentrale-Warengruppe"
              name="warengruppe"
              onChange={onChange}
              zentraleMitStatistik
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TooltipEx
              title={
                value.listung && !value.dezentral
                  ? 'Nach der Gesellschafter-Warengruppe kann nur in ERP-Daten gesucht werden'
                  : ''
              }
            >
              <span>
                <WarengruppeField
                  value={value.gfWarengruppe}
                  label="Gesellschafter-Warengruppe"
                  name="gfWarengruppe"
                  onChange={onChange}
                  disabled={value.listung && !value.dezentral}
                  gfMitStatistik
                  fullWidth
                />
              </span>
            </TooltipEx>
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
