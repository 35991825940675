import { Button, ButtonProps } from './Button'

export type SelectButtonProps = {
  visible?: ButtonProps['visible']
  disabled?: ButtonProps['disabled']
  onClick?: ButtonProps['onClick']
  onClickVoid?: ButtonProps['onClickVoid']
  size?: ButtonProps['size']
  autoFocus?: ButtonProps['autoFocus']
}

export const SelectButton = ({ ...props }: SelectButtonProps) => (
  <Button {...props} label="Auswählen" variant="contained" color="primary" />
)
