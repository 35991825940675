import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { VerteilungsZieleField } from '@one/components/common/VerteilungsZielSel'
import { LieferantenVerteilungDefinitionDisplayJson } from '@one/typings/apiTypings'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface LieferantenVerteilerUebersichtTableProps {
  result: SearcherResultType<LieferantenVerteilungDefinitionDisplayJson>
  onDelete: (data: LieferantenVerteilungDefinitionDisplayJson) => void
}

export const LieferantenVerteilerUebersichtTable = ({
  result,
  onDelete
}: LieferantenVerteilerUebersichtTableProps) => {
  const columns = useMemo<Column<LieferantenVerteilungDefinitionDisplayJson>[]>(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: 'lieferantenListenCount',
        header: 'Anzahl der Lieferanten'
      },
      {
        field: 'verteilungsZiele',
        header: 'Verteilungsziele',
        body: (row: any) => <VerteilungsZieleField value={row.verteilungsZiele} />
      },
      {
        field: 'aktiv',
        header: 'Aktiviert',
        type: 'boolean'
      }
    ],
    []
  )

  // Button actions for each table row
  const tableActions = useMemo(
    () =>
      [
        {
          icon: 'edit',
          tooltip: 'Bearbeiten',
          getLink: (data) => AppPaths.lieferantenverteiler.LieferantenVerteilerPflegeFn(data.id)
        },
        {
          icon: 'delete',
          tooltip: 'Löschen',
          handler: onDelete,
          role: UserRoles.STAMMDATEN_EDITOR
        }
      ] as DataTableAction<LieferantenVerteilungDefinitionDisplayJson>[],
    [onDelete]
  )

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Lieferantenverteiler hinzufügen',
          navlink: AppPaths.lieferantenverteiler.LieferantenVerteilerPflegeFn('neu'),
          icon: 'add'
        }
      ] as Action[],
    []
  )

  return (
    <DataTableCard
      name="LieferantenVerteilerUebersichtTable"
      title="Suchergebnis"
      localStateName="LieferantenVerteilerUebersichtTable"
      filterMode="both"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      dense
      initialOrderBy="name"
      result={result}
    />
  )
}
