/* eslint-disable react/jsx-props-no-spreading */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper, { PopperProps } from '@mui/material/Popper'
import { Permission, PermissionUtil } from '@utils/ui/Permission'
import { useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { ButtonProps } from './Button'

export type SplitButtonAction = {
  text: string
  onClick: () => void
  permission?: Permission
}

export type SplitButtonProps = {
  actions: SplitButtonAction[]
  disabled?: boolean
  placement?: PopperProps['placement']
  disablePortal?: PopperProps['disablePortal']
  size?: ButtonProps['size']
  variant?: ButtonGroupProps['variant']
}

export const SplitButton = ({
  actions,
  placement,
  disabled,
  disablePortal,
  size,
  variant
}: SplitButtonProps) => {
  const [lastActions, setLastActions] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!isEqual(actions, lastActions)) {
      setLastActions(actions)
      if (actions.length !== lastActions.length || selectedIndex === -1) {
        setSelectedIndex(actions.findIndex((a) => PermissionUtil.isEnabled(a.permission)))
      }
    }
  }, [actions, lastActions, selectedIndex])

  const selectedAction = (selectedIndex >= 0 &&
    selectedIndex < lastActions.length &&
    lastActions[selectedIndex]) || {
    permission: Permission.DISABLED,
    text: 'Keine Aktion verfügbar'
  }

  const handleClick = () => {
    if (selectedAction && selectedAction.onClick) {
      selectedAction.onClick()
    }
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    const action = lastActions[index]
    if (action.onClick) {
      action.onClick()
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const menu = ({ TransitionProps = {}, placement = '' }) => (
    <Grow
      {...TransitionProps}
      style={{
        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>
            {actions.map((action, index) => (
              <MenuItem
                key={action.text}
                disabled={disabled || PermissionUtil.isDisabled(action.permission)}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {action.text}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>
  )

  return (
    <div>
      <ButtonGroup
        variant={variant}
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        size={size}
        disabled={disabled}
      >
        <Button
          onClick={handleClick}
          disabled={PermissionUtil.isDisabled(selectedAction.permission)}
        >
          {selectedAction.text}
        </Button>
        <Button
          color="primary"
          size={size}
          style={{ paddingRight: 2, paddingLeft: 2, minWidth: 12 }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={disablePortal}
        placement={placement}
        style={{ zIndex: 2000 }}
      >
        {menu}
      </Popper>
    </div>
  )
}
