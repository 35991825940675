import { Grid, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { api } from '@one/api'
import { AllianzPreisEbenenAnzeigenJson, PreisEbeneDisplayJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useStateEx } from '@utils/stateex'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { SelectField } from '@utils/ui/fields/SelectField'
import { compareStrings } from '@utils/utils'
import { useCallback, useEffect, useState } from 'react'

export interface AllianzPreisEbeneDialogProps {
  visible: boolean
  onClose: (preiskategorieId?: number) => void
  preiskategorieId?: number
}

export const AllianzPreisEbeneDialog = ({
  visible,
  onClose,
  preiskategorieId = null
}: AllianzPreisEbeneDialogProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const [preiskategorien, setPreiskategorien] = useState<PreisEbeneDisplayJson[]>([])
  const [selectedPreiskategorieId, setSelectedPreiskategorieId, getSelectedPreiskategorieId] =
    useStateEx(preiskategorieId ?? null)

  useEffect(() => {
    apiCall<AllianzPreisEbenenAnzeigenJson>({
      method: 'GET',
      rest: '/allianz/findPreisEbenen',
      onSuccess: (data) => {
        data.preisEbenen.sort((p1, p2) => {
          const rc = p1.reihe != null && p2.reihe != null ? p1.reihe - p2.reihe : 0
          return rc || compareStrings(p1.name, p2.name)
        })
        setPreiskategorien(data.preisEbenen)
        if (!getSelectedPreiskategorieId() == null) {
          const defaultPk = data.preisEbenen.find((pk) => pk.defaultStandort)
          setSelectedPreiskategorieId(defaultPk?.id ?? null)
        }
      }
    })
  }, [apiCall, getSelectedPreiskategorieId, setSelectedPreiskategorieId])

  const onCancel = () => {
    onClose()
  }

  const onSave = () => {
    if (selectedPreiskategorieId) {
      onClose(selectedPreiskategorieId)
    }
  }

  const renderPreiskategorie = useCallback(
    (preisebene: PreisEbeneDisplayJson) =>
      preisebene && (
        <Grid container flexWrap="nowrap" direction="row" spacing={1}>
          <Grid item xs={1} textAlign="right">
            {preisebene.nr} -
          </Grid>
          <Grid item flexGrow={1}>
            {preisebene.alias || preisebene.name}
          </Grid>
        </Grid>
      ),
    []
  )

  const onSelect = useCallback(
    (e) => {
      const id = e.target?.value
      setSelectedPreiskategorieId(id)
    },
    [setSelectedPreiskategorieId]
  )

  const actions = (
    <>
      <Button
        label="Allianz-Preisebene Bereitstellung starten"
        onClick={onSave}
        variant="contained"
        disabled={!selectedPreiskategorieId}
      />
      <CancelButton onClick={onCancel} />
    </>
  )

  return (
    <DialogEx
      title="Allianz-Preisebene für ERP Export bereitstellen"
      open={visible}
      onClose={onCancel}
      actions={actions}
      height="300px"
      fullWidth
      maxWidth="md"
    >
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Typography variant="subtitle1">
            Alle spezifischen Zuordnungen werden für die gewählte Preisebene für den Export an das
            ERP vorbereitet.
          </Typography>
          <Typography variant="subtitle2" color={red[200]}>
            Die Daten werden nur vorbereitet, nach Abschluss müssen Änderungen in das ERP exportiert
            werden!
          </Typography>
        </Grid>
        <Grid item>
          <SelectField
            name="id"
            label="Preisebene für ERP Export"
            emptyText={apiBusy ? 'Lade...' : null}
            options={preiskategorien}
            value={
              apiBusy || preiskategorien == null || preiskategorien.length === 0
                ? null
                : selectedPreiskategorieId
            }
            onChange={onSelect}
            renderItem={renderPreiskategorie}
            optionIgnored={(opt: PreisEbeneDisplayJson) => opt.ausgeblendet}
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </DialogEx>
  )
}
