import { AppPaths } from '@one/AppPaths'
import { EkPreisMassenPflegeTable } from '@one/components/EkPreis/EkPreisMassenPflege/EkPreisMassenPflegeTable'
import { useEkPreisMassenPflegeModel } from '@one/components/EkPreis/EkPreisMassenPflege/model/EkPreisMassenPflegeModel'
import { EkPreisListeDisplayJson } from '@one/typings/apiTypings'
import { FrameBody } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useState } from 'react'

export interface EkPreisImportPflegeStep6EkSimulationProps {
  preisliste?: EkPreisListeDisplayJson
  reloadHook: any
}

export const EkPreisImportPflegeStep6EkSimulation = ({
  preisliste,
  reloadHook
}: EkPreisImportPflegeStep6EkSimulationProps) => {
  const [selected, setSelected] = useState(new Set())

  const { model, reload, apiBusy, uiLock } = useEkPreisMassenPflegeModel(preisliste.id)

  reloadHook.current = reload

  return (
    <FrameBody>
      <StatePlane uiLock={uiLock} altLink={AppPaths.EkPreisImportUbersicht}>
        <EkPreisMassenPflegeTable
          title="Simulation zu importierender EK-Preise"
          selected={selected}
          onSelect={setSelected}
          value={model.artikelListe}
          preislisteId={preisliste.id}
          loading={apiBusy}
          kondiDefinition={model.kondiDefinition}
          emptyMessage="Es wurden keine Preise importiert!"
        />
      </StatePlane>
    </FrameBody>
  )
}
