import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantenListeDisplayJson, LieferantenListeTyp } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { PermissionUtil } from '@utils/ui/Permission'
import { useMemo } from 'react'

export type LieferantenListeUebersichtTableProps = {
  result: SearcherResultType<LieferantenListeDisplayJson>
  onDelete: (row: any) => void
}

export const LieferantenListeUebersichtTable = ({
  result,
  onDelete
}: LieferantenListeUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo(
    () => [
      {
        field: 'name',
        header: 'Name',
        sortable: true
      },
      {
        field: 'typ',
        header: 'Typ',
        body: (row: any) => et(HkmEnum.LieferantenListeTyp, row.typ)
      },
      {
        field: 'lieferantenAnzahl',
        header: 'Anzahl der Lieferanten'
      }
    ],
    [et]
  )

  // Button actions for each table row
  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        check: (data) => PermissionUtil.show(data.typ !== LieferantenListeTyp.ARTVD),
        getLink: (data) => AppPaths.lieferanteliste.LieferantenListePflegeFn(data.id)
      },
      {
        icon: 'visibility',
        tooltip: 'Anzeigen',
        check: (data) => PermissionUtil.show(data.typ === LieferantenListeTyp.ARTVD),
        getLink: (data) => AppPaths.lieferanteliste.LieferantenListePflegeFn(data.id)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        check: (data) => PermissionUtil.show(data.typ !== LieferantenListeTyp.ARTVD),
        handler: onDelete,
        role: UserRoles.STAMMDATEN_EDITOR
      }
    ],
    [onDelete]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Lieferantenliste erstellen',
        navlink: AppPaths.lieferanteliste.LieferantenListePflegeFn('neu'),
        icon: 'add'
      }
    ],
    []
  )

  return (
    <DataTableCard
      name="LieferantenListeUebersichtTable"
      title="Suchergebnis"
      filterMode="both"
      localStateName="LieferantenListeUebersichtTable"
      topActions={actions}
      columns={columns}
      actions={tableActions}
      dense
      initialOrderBy="name"
      result={result}
    />
  )
}
