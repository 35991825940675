import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { ExportErrorToErpStandortArtikelJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useMemo } from 'react'
import { ExportFehlerView } from './ExportFehlerView'

export const ExportStandortArtikelFehlerUebersichtView = () => {
  const { et } = useEnums()

  const columnsProps = useMemo<ArtikelDefaultColumnsProps<ExportErrorToErpStandortArtikelJson>>(
    () => ({
      fieldWrapper: {
        wrapper: 'artikel'
      },
      removeDefaultsByField: ['minEinLager', 'lagerME', 'hauptlieferant', 'warengruppe.nummer'],
      addColumnToIndex: [
        {
          beforeField: true,
          column: {
            field: 'standort',
            header: 'Standort',
            align: 'center',
            valueGetter: (row) =>
              row.standort ? row.standort?.nr + ' - ' + row.standort?.name : null
          }
        }
      ]
    }),
    []
  )

  const columns = useArtikelColumns(columnsProps)

  return (
    <ExportFehlerView
      columns={columns}
      title="ERP-Artikel Exportfehler"
      rest="export/standortartikel"
      name="StandortArtikelExportFehlerTable"
    />
  )
}
