import { Avatar, CardMedia, Divider, Grid } from '@mui/material'
import { AppContext } from '@utils/ui/App/AppContext'
import { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  cardMedia: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 4px',
    paddingBottom: 0,
    backgroundSize: theme.darkMode ? 'auto' : undefined,
    backgroundPosition: theme.darkMode ? 'left' : 'right'
  },
  avatar: {
    '&.MuiAvatar-root': {
      backgroundColor: '#4856a0',
      width: theme.spacing(7),
      height: theme.spacing(7)
    }
  },
  h4: {
    margin: 0,
    marginTop: 8,
    // marginBottom: 4,
    color: '#333'
  },
  h5: {
    margin: 0
  }
}))

/**
 * Component that displays a header for a navigation drawer.
 */
export const NavHeader = ({ settingsNode = null }) => {
  const { classes } = useStyles()

  //@ts-ignore
  const { session } = useContext(AppContext)
  const { firstname, lastname } = session || {}

  return (
    <CardMedia className={classes.cardMedia} image="greenbg3.jpg">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Avatar variant="circular" className={classes.avatar}>
            {`${(firstname || '').charAt(0)}${(lastname || '').charAt(0)}`}
          </Avatar>
        </Grid>
        <Grid item>
          <h4 className={classes.h4}>{`${firstname} ${lastname}`}</h4>
        </Grid>
        {settingsNode && <Grid item>{settingsNode}</Grid>}
      </Grid>
      <Divider dir="horizontal" />
    </CardMedia>
  )
}
