import { Alert, Grid } from '@mui/material'
import { api } from '@one/api'
import {
  MigrationArtikelEosDatenErzeugenBearbeitenJson,
  MigrationArtikelEosDatenErzeugenJson,
  SpreadSheetType,
  UseCaseStateJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameRow } from '@utils/ui/Frame'
import { useDownloader } from '@utils/ui/downloader'
import { StaticField } from '@utils/ui/fields/StaticField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useEffect, useState } from 'react'

export const EosMigrationTargetView = () => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const [data, setData] = useState<MigrationArtikelEosDatenErzeugenJson | null>(null)
  const [error, setError] = useState<UseCaseStateJson | null>(null)
  const [type, setType] = useState<SpreadSheetType>(SpreadSheetType.XLSX)
  const [apiCall, apiBusy] = useApiCaller(api)
  const [download] = useDownloader()

  const load = useCallback(() => {
    apiCall<MigrationArtikelEosDatenErzeugenBearbeitenJson>({
      rest: '/eos/migration/eosExportDaten/editAktuelle',
      method: 'GET',
      onSuccess: (data) => {
        setData(data.migrationArtikelEosDatenJson)
      },
      onError: (error) => {
        setError(error)
        return true
      }
    })
  }, [apiCall])

  const handleErmitteln = () => {
    download('rest/eos/migration//eosExportDaten/?id=' + data?.id)
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttons = [
    {
      label: 'Aktualisieren',
      icon: 'refresh',
      onClick: () => {
        load()
      }
    }
  ]

  return (
    <StatePlane wait={apiBusy}>
      <Frame space>
        <FrameRow>
          <CardEx
            title="EOS Migration"
            subheader="Steuerung der Datenübernahme nach EOS"
            backLink
            topActions={buttons}
          >
            {data?.id == null ? (
              <Alert severity="error">Keine Migration angelegt</Alert>
            ) : (
              <Grid container spacing={2} direction="column">
                <Grid item container spacing={1}>
                  <Grid item>
                    <StaticField label="Anzahl Artikel gefunden" value={data.artikelAnzFound} />
                  </Grid>
                  <Grid item>
                    <StaticField
                      label="Anzahl Artikel nicht gefunden"
                      value={data.artikelAnzNotFound}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2} alignItems="end">
                  {/* <Grid item>
                    <ExportTypField
                      value={type}
                      onChange={(e) => setType(e.target.value as SpreadSheetType)}
                      required
                    />
                  </Grid> */}
                  <Grid item>
                    <Button
                      label="Exportdaten herunterladen"
                      disabled={apiBusy}
                      onClick={handleErmitteln}
                      variant="contained"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </CardEx>
        </FrameRow>
        <DlgAnker />
      </Frame>
    </StatePlane>
  )
}
