import { PriceChange } from '@mui/icons-material'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import {
  EkKonditionenDefinitionJson,
  EkKonditionenRabattgruppeKopfJson,
  EkKonditionenRabattgruppeVersionChangeJson,
  EkKonditionenRabattgruppeWertJson
} from '@one/typings/apiTypings'
import { formatDate } from '@utils/dateutils'
import { AppContext } from '@utils/ui/App/AppContext'
import { formatMoney2 } from '@utils/utils'
import { useContext } from 'react'
import { AttributeTreeItem, ChangesTreeView, DiffTreeItem } from './ChangesTreeView'

interface EkKonditionenRabattgruppeWertJsonDiff {
  alt?: EkKonditionenRabattgruppeWertJson
  neu?: EkKonditionenRabattgruppeWertJson
}

interface EkKonditionenRabattgruppeVersionChangeJsonDiff
  extends EkKonditionenRabattgruppeVersionChangeJson {
  kopf: EkKonditionenRabattgruppeKopfJson
  staffelwerte: EkKonditionenRabattgruppeWertJsonDiff[]
}

// interface EkKonditionenRabattgruppeKopfChangeJsonDiff {
//   kopf: EkKonditionenRabattgruppeKopfJson
//   versions: EkKonditionenRabattgruppeVersionChangeJsonDiff[]
// }

const buildWerteDiff = (
  alt: EkKonditionenRabattgruppeWertJson[] | null,
  neu: EkKonditionenRabattgruppeWertJson[] | null
): EkKonditionenRabattgruppeWertJsonDiff[] => {
  const altEx = alt || []
  const neuEx = [...(neu || [])]

  const idx = altEx
    .map((a) => {
      const idx = neuEx.findIndex((n) => n.staffelmenge === a.staffelmenge)
      if (idx > -1) {
        const n = neuEx[idx]
        neu.splice(idx, 1)
        return {
          alt: a,
          neu: n
        }
      }
      return { alt: a, neu: null }
    })
    .concat(neuEx.map((n) => ({ neu: n, alt: null })))

  idx.sort((a, b) => {
    const a1 = (a.alt || a.neu).staffelmenge || 0
    const b1 = (b.alt || b.neu).staffelmenge || 0
    if (a1 < b1) {
      return -1
    } else if (a1 > b1) {
      return 1
    }
    return 0
  })

  return idx
}

const buildIndex = (
  rabattgruppen: EkKonditionenRabattgruppeVersionChangeJson[]
): EkKonditionenRabattgruppeVersionChangeJsonDiff[] => {
  const idx = rabattgruppen.map(
    (rabattgruppe) =>
      ({
        ...rabattgruppe,
        kopf: rabattgruppe.neu?.kopf || rabattgruppe.alt?.kopf,
        staffelwerte: buildWerteDiff(rabattgruppe.alt?.staffelwerte, rabattgruppe.neu?.staffelwerte)
      }) as EkKonditionenRabattgruppeVersionChangeJsonDiff
  )

  idx.sort((a, b) => {
    const a1 = (a.alt || a.neu).gueltigVon
    const b1 = (b.alt || b.neu).gueltigVon
    if (a1 < b1) {
      return -1
    } else if (a1 > b1) {
      return 1
    }
    return 0
  })

  return idx

  // const nodes = new Map<number, EkKonditionenRabattgruppeKopfChangeJsonDiff>()
  // idx.forEach((rg) => {
  //   let node = nodes.get(rg.kopf.id)
  //   if (node == null) {
  //     node = { kopf: rg.kopf, versions: [] }
  //     nodes.set(rg.kopf.id, node)
  //   }
  //   node.versions.push(rg)
  // })

  // return Array.from(nodes.values())
}

const formatPreis = (preis) => {
  if (preis == null) {
    return null
  }
  const gueltigAb = formatDate(preis.gueltigAb)
  const betrag = formatMoney2(preis.preis)
  const waehrung = '€'
  return `Ab ${gueltigAb} ${betrag}${waehrung} pro ${preis.pm}${preis.meName}`
}

const generateEkRabattgruppeChangeItems =
  (
    rabattgruppen: EkKonditionenRabattgruppeVersionChangeJson[],
    konditionDefinition: EkKonditionenDefinitionJson,
    allianz: boolean
  ) =>
  (idgen, expandedIds, treeClasses, itemClasses) => {
    if (!(rabattgruppen?.length > 0)) {
      return []
    }

    const nodes = buildIndex(rabattgruppen)
    const kd = enrichKondiDefinition(konditionDefinition, allianz)

    const items = []
    nodes.forEach((node) => {
      const id1 = idgen()
      expandedIds.push(id1)

      const { alt, neu, staffelwerte } = node
      const infoText = (alt == null && 'Neu') || (neu == null && 'Gelöscht') || 'Geändert'
      const path = `${infoText}/`

      items.push(
        <AttributeTreeItem
          key={id1}
          nodeId={id1}
          labelText={node.kopf.name}
          labelIcon={<PriceChange />}
          labelInfo={infoText}
          treeClasses={treeClasses}
          itemClasses={itemClasses}
        >
          <DiffTreeItem
            idgen={idgen}
            labelText="Gültig ab"
            name="gueltigVon"
            type="date"
            path={path}
            alt={alt}
            neu={neu}
          />
          <DiffTreeItem
            idgen={idgen}
            labelText="Staffelgruppe"
            name="staffelgruppe.name"
            type="string"
            path={path}
            alt={alt}
            neu={neu}
          />
          {staffelwerte.map((sw) => {
            const id2 = idgen()
            expandedIds.push(id2)
            const obj = sw.neu || sw.alt
            return (
              <AttributeTreeItem
                key={id2}
                nodeId={id2}
                path={path}
                labelText={`Staffelmenge ${obj.staffelmenge}`}
                labelIcon={<PriceChange />}
                labelInfo=""
                treeClasses={treeClasses}
                itemClasses={itemClasses}
                simple
              >
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.zuschlAbs1Label}
                  name="zuschlAbs1"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.zuschlAbs2Label}
                  name="zuschlAbs2"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.rabattProz1Label}
                  name="rabattProz1"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  labelText={kd.rabattProz2Label}
                  path={path}
                  name="rabattProz2"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.rabattProz3Label}
                  name="rabattProz3"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.rabattProz4Label}
                  name="rabattProz4"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.rabattProz5Label}
                  name="rabattProz5"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.zuschlAbs3Label}
                  name="zuschlAbs3"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.zuschlAbs4Label}
                  name="zuschlAbs4"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
                <DiffTreeItem
                  idgen={idgen}
                  path={path}
                  labelText={kd.zuschlAbs5Label}
                  name="zuschlAbs5"
                  type="money2"
                  alt={sw.alt}
                  neu={sw.neu}
                />
              </AttributeTreeItem>
            )
          })}
        </AttributeTreeItem>
      )
    })

    return items
  }

export type ChangedRabattgruppenTreeViewProps = {
  konditionDefinition?: EkKonditionenDefinitionJson
  changedRabattgruppen?: EkKonditionenRabattgruppeVersionChangeJson[]
}

export const ChangedRabattgruppenTreeView = ({
  konditionDefinition,
  changedRabattgruppen
}: ChangedRabattgruppenTreeViewProps) => {
  const { isAllianz } = useContext(AppContext)
  return changedRabattgruppen ? (
    <ChangesTreeView
      generateItems={generateEkRabattgruppeChangeItems(
        changedRabattgruppen,
        konditionDefinition,
        isAllianz
      )}
    />
  ) : null
}
