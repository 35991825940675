import { Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantenListeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantenListeUebersichtParamsProps {
  value: LieferantenListeSearcherCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<LieferantenListeSearcherCriteriaJson>
}

export const LieferantenListeUebersichtParams = ({
  value,
  onSearch,
  onChange
}: LieferantenListeUebersichtParamsProps) => {
  const shortcuts = {
    Enter: onSearch
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        backLink
        collapsiable
        title="Lieferantenliste"
        bottomActions={<SearchButton onClick={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <TextField fullWidth label="Name" name="name" value={value.name} onChange={onChange} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <SelectEnumField
              label="Typ"
              name="typ"
              value={value.typ}
              onChange={onChange}
              displayEmpty
              fullWidth
              enumType={HkmEnum.LieferantenListeTyp}
              emptyText="Alle"
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
