import { Add } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  HKMAuditJson,
  HandelTyp,
  NeonKontextDisplayJson,
  StandortGruppeDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { PermissionUtil } from '@utils/ui/Permission'
import { ChipContainer } from '@utils/ui/fields/ChipContainer'
import { nameOf, pathOf } from '@utils/utils'
import { useMemo } from 'react'
import { SynchronisationStatusCell } from './SynchronisationStatusCell'

export interface StandortGruppeUebersichtTableProps {
  result: SearcherResultType<StandortGruppeDisplayJson>
  onNewStandortGruppe: VoidFunction
}

export const StandortGruppeUebersichtTable = ({
  result,
  onNewStandortGruppe
}: StandortGruppeUebersichtTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column<StandortGruppeDisplayJson>[]>(
    () => [
      {
        field: nameOf<StandortGruppeDisplayJson>('nr'),
        header: 'Nummer',
        align: 'right',
        type: 'string'
      },
      {
        field: nameOf<StandortGruppeDisplayJson>('name'),
        header: 'Name',
        type: 'string'
      },
      {
        field: nameOf<StandortGruppeDisplayJson>('status'),
        header: 'Status',
        valueGetter: (row) => et(HkmEnum.SynchronisationStatus, row.status),
        body: (row) => <SynchronisationStatusCell value={row.status} />
      },
      {
        field: pathOf<StandortGruppeDisplayJson, NeonKontextDisplayJson>('kontext', 'kontextNr'),
        header: 'Kontextnr.',
        align: 'right'
      },
      {
        field: pathOf<StandortGruppeDisplayJson, NeonKontextDisplayJson>('kontext', 'kontextName'),
        header: 'Kontextname'
      },
      {
        field: pathOf<StandortGruppeDisplayJson, NeonKontextDisplayJson>(
          'kontext',
          'betriebstypNr'
        ),
        header: 'Betriebstypnr.'
      },
      {
        field: nameOf<StandortGruppeDisplayJson>('standorte'),
        header: 'Standorte',
        valueGetter: (row) => row.standorte?.map((s) => `${s.nr} ${s.name}`),
        body: (row) => (
          <ChipContainer value={row.standorte} getValueLabel={(s) => `${s.nr} ${s.name}`} />
        )
      },
      {
        field: pathOf<StandortGruppeDisplayJson, HKMAuditJson>('audit', 'createdOn'),
        header: 'Erstellt am',
        type: 'datetime'
      },
      {
        field: pathOf<StandortGruppeDisplayJson, HKMAuditJson>('audit', 'updatedOn'),
        header: 'Geändert am',
        type: 'datetime'
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    () =>
      [
        {
          icon: 'edit',
          tooltip: 'Öffnen',
          getLink: (row) => {
            return AppPaths.StandortgruppePflegeFn(row.id)
          }
        },
        {
          icon: 'category',
          tooltip: 'Sortiment',
          getLink: (row) => AppPaths.SortimentPflegeByStandortgruppeFn(row.id)
        },
        {
          icon: 'checklist',
          tooltip: 'VK-Preispflege Regeln',
          getLink: (row) => AppPaths.VkStandardPreisgruppenByStandortgruppeFn(row.id),
          check: (row) => PermissionUtil.show(row.kontext?.handelTyp === HandelTyp.EH)
        }
      ] as DataTableAction<StandortGruppeDisplayJson>[],
    []
  )

  const actions = useMemo(
    (): Action[] => [
      {
        role: UserRoles.STANDORT_EDITOR,
        tooltip: 'Neue Standortgruppe',
        name: 'Standortgruppe-neu',
        icon: <Add />,
        onClick: onNewStandortGruppe
      }
    ],
    [onNewStandortGruppe]
  )

  return (
    <DataTableCard
      name="StandortGruppeUebersichtTable"
      localStateName="StandortGruppeUebersichtTable"
      title="Standortgruppen"
      filterMode="both"
      columns={columns}
      topActions={actions}
      actions={tableActions}
      dense
      result={result}
      // initialOrderBy={[{ key: 'erstelltAm', dir: 'desc' }]}
    />
  )
}
