import { Close, CloseFullscreen, HelpCenterOutlined, OpenInFull } from '@mui/icons-material'
import { Breakpoint, Checkbox, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IconButton } from './Buttons/IconButton'
import { ScrollPanel } from './ScrollPanel'
import { HelpContext } from './help/HelpWrapper'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  fullscreen: {
    marginTop: '48px !important',
    height: 'calc(100% - 48px) !important'
  },
  titleText: {
    fontSize: '1.4rem',
    flexGrow: 1
  },
  content: {
    position: 'relative',
    padding: '8px 12px 0 12px !important'
  },
  header: {
    padding: '12px 12px 12px 16px'
  },
  smallHeader: {
    padding: '8px 8px 8px 16px'
  }
})

export type DialogExProps = {
  open: boolean
  onClose: any
  disableEscapeKeyDown?: boolean
  disabledFullscreen?: boolean
  disabledCloseButton?: boolean
  onKeyDown?: (e: KeyboardEvent) => void
  maxWidth?: false | Breakpoint
  fullWidth?: boolean
  height?: any
  title?: any
  actions?: ReactNode
  children: ReactNode
  smallHeader?: boolean
  lockClose?: boolean
  helpKey?: string
  topContent?: ReactNode
}

export const DialogEx = ({
  open,
  onClose,
  disableEscapeKeyDown,
  onKeyDown,
  disabledFullscreen,
  disabledCloseButton,
  maxWidth,
  fullWidth,
  height = '300px',
  title,
  topContent,
  actions,
  smallHeader,
  children,
  helpKey,
  lockClose = false
}: DialogExProps) => {
  const { classes } = useStyles()
  const [fullScreen, setFullScreen] = useState<boolean>()
  const helpContext = useContext(HelpContext)

  useEffect(() => {
    if (helpKey) {
      if (helpContext) {
        const prev = helpContext.getHelpLocation()
        helpContext.setHelpLocation(helpKey || `${title}_Dialog`, title)
        return () => {
          if (prev) {
            helpContext.setHelpLocation(prev.key, prev.title)
          }
        }
      } else {
        console.error('helpContext not available for dialog: ', title, ' helpKey: ', helpKey)
      }
    }
    return () => {}
  }, [helpContext, helpKey, title])

  const paperProps = {
    className: fullScreen ? classes.fullscreen : undefined,
    style: { height }
  }

  useEffect(() => {
    if (onKeyDown) {
      window.document.addEventListener('keydown', onKeyDown)
      return () => {
        window.document.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [onKeyDown])

  const dialogClose = useCallback(
    (e, reason) => {
      if (lockClose && reason === 'backdropClick') {
        return
      }
      onClose(e)
    },
    [lockClose, onClose]
  )

  const openHelp = () => helpContext.setHelpVisible(true, true)

  const grow = { flexGrow: 1 }
  return (
    <Dialog
      keepMounted
      open={open}
      onClose={dialogClose}
      fullScreen={fullScreen}
      disableEscapeKeyDown={disableEscapeKeyDown || lockClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={paperProps}
      // disableEnforceFocus
    >
      <div className={smallHeader ? classes.smallHeader : classes.header}>
        <Grid container alignItems="center" flexWrap="nowrap">
          <Grid item style={grow}>
            <div className={classes.titleText}>{title}</div>
          </Grid>
          {helpKey && helpContext ? (
            <Grid item>
              <IconButton
                icon={<HelpCenterOutlined />}
                size="small"
                aria-label="close"
                // className={classes.closeButton}
                onClick={openHelp}
              />
            </Grid>
          ) : null}
          {!disabledFullscreen ? (
            <Grid item>
              <Checkbox
                size="small"
                icon={<OpenInFull fontSize="small" />}
                checkedIcon={<CloseFullscreen fontSize="small" />}
                aria-label="fullscreen"
                onClick={() => setFullScreen(!fullScreen)}
                name="fullScreen"
                color="default"
                title={fullScreen ? 'Verkleinern' : 'Maximieren'}
              />
            </Grid>
          ) : null}
          {!disabledCloseButton ? (
            <Grid item>
              <IconButton
                icon={<Close />}
                size="small"
                aria-label="close"
                // className={classes.closeButton}
                onClick={onClose}
              />
            </Grid>
          ) : null}
        </Grid>
        {topContent}
      </div>
      <DialogContent
        dividers
        className={classes.content}
        style={{ height: height ? '100%' : undefined, overflowY: 'hidden' }}
      >
        <ScrollPanel>{children}</ScrollPanel>
      </DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  )
}
